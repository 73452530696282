import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import SocialConsultant from "./SocialConsultant";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function SocialHome() {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedHead, setSelectedHead] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const { t } = useTranslation();
  console.log("location", location);

  useEffect(() => {
    if (location.pathname == "/call/3") {
      setSelectedHead("TXT.CAL.LO");
      setSelectedService("Vasthu Consultation");
    } else if (location.pathname == "/call/4") {
      setSelectedHead("TXT.CAL.LO");
      setSelectedService("Astrology Consultation");
    } else if (location.pathname == "/call/5") {
      setSelectedHead("TXT.CAL.LO");
      setSelectedService("Building Consultation");
    } else if (location.pathname == "/book/3") {
      setSelectedHead("USR.BOOK.BTN");
      setSelectedService("Vasthu Consultation");
    } else if (location.pathname == "/book/4") {
      setSelectedHead("USR.BOOK.BTN");
      setSelectedService("Astrology Consultation");
    } else if (location.pathname == "/book/5") {
      setSelectedHead("USR.BOOK.BTN");
      setSelectedService("Building Consultation");
    } else {
      navigate('/')
    }
  }, []);

  return (
    <React.Fragment>
      <center>
        <h1>
          {t("TXT.CON.LO")}&nbsp;-&nbsp;{t(selectedHead)}
        </h1>
      </center>
      <div className="social_consultant">
        <SocialConsultant details={{ selectedHead, selectedService }} />
      </div>
      <div className="social_footer">
        <Button
          className="all_btn"
          onClick={() => {
            navigate("/");
          }}
        >
          {t("USR.HOME.BTN")}
        </Button>
        <Button
          className={selectedHead == "TXT.CAL.LO" ? "all_btn1" : "all_btn"}
          onClick={() => {
            setSelectedHead("TXT.CAL.LO");
            navigate("/call");
          }}
        >
          {t("TXT.CAL.LO")}
        </Button>
        <Button
          className={selectedHead == "USR.BOOK.BTN" ? "all_btn1" : "all_btn"}
          onClick={() => {
            setSelectedHead("USR.BOOK.BTN");
            navigate("/book");
          }}
        >
          {t("USR.BOOK.BTN")}
        </Button>
      </div>
    </React.Fragment>
  );
}

export default SocialHome;
