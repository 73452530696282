import React from "react";

const NoMatch = () => {
  return (
    <center>
      <h1>404 - No page Found</h1>
    </center>
  );
};
export default NoMatch;
