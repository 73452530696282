import React, { useState, useEffect } from "react";
import {
  Form,
  Card,
  Button,
  Spinner,
  Alert,
  InputGroup,
} from "react-bootstrap";
import { UseAdminContext } from "../context/AdminContext";
import validator from "validator";

const AdminAddCrmForm = () => {
  // const { token} = UseAuth();
  const { handleCrmRegister, crmLoading, crmError } = UseAdminContext();
  const [crmName, setCrmName] = useState("");
  const [crmEmail, setCrmEmail] = useState("");
  const [crmPhone, setCrmPhone] = useState("");
  const [crmNewPassword, setCrmNewPassword] = useState("");
  const [crmConfirmPassword, setCrmConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [countryCode, setCountryCode] = useState("0");

  useEffect(() => {
    alert(crmError);
  }, [crmError]);

  const alert = (msg) => {
    setMessage(msg);

    setTimeout(() => {
      setMessage("");
    }, 4000);
  };

  const onSubmit = (event) => {
    const phone_with_code = countryCode.concat(crmPhone);

    event.preventDefault();
    if (
      !validator.isEmpty(crmPhone) &&
      !validator.isEmpty(crmNewPassword) &&
      !validator.isEmpty(crmName) &&
      !validator.isEmpty(crmConfirmPassword)
    ) {
      if (
        validator.isMobilePhone(crmPhone, "en-IN") &&
        validator.isEmail(crmEmail) &&
        validator.equals(crmNewPassword, crmConfirmPassword)
      ) {
        const credentials = {
          user: crmName,
          phone: phone_with_code,
          email: crmEmail,
          pass: crmConfirmPassword,
        };
        console.log(" submit", credentials);
        handleCrmRegister(credentials, (res) => {
          if (res === "success") {
            alert("Sucessfully Registered admin");
            setCrmName("");
            setCrmEmail("");
            setCrmPhone("");
            setCrmNewPassword("");
            setCrmConfirmPassword("");
          } else {
            alert("Admin Registration Fails");
          }
        });
      } else {
        alert("CREDENTIALS GIVEN IS INVALID");
      }
    } else {
      alert("CREDENTIALS IS EMPTY");
    }
  };

  return (
    <Card className="p-3 all_border">
      <Form>
        {message && (
          <Alert variant="info" className="text-center">
            {message}
          </Alert>
        )}
        <Form.Group className="mb-3" controlId="adminAddadminName">
          <Form.Label>CRM Name* :</Form.Label>
          <Form.Control
            className="form_feild"
            type="text"
            placeholder="Enter Unique User Name"
            value={crmName}
            onChange={(e) => {
              console.log(e.target.value);
              setCrmName(e.target.value);
            }}
            autoComplete="new-off"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="adminAddadminEmail">
          <Form.Label>Email address :</Form.Label>
          <Form.Control
            className="form_feild"
            type="email"
            placeholder="name@example.com"
            value={crmEmail}
            onChange={(e) => {
              console.log(e.target.value);
              setCrmEmail(e.target.value);
            }}
            autoComplete="new-off"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="adminAddadminPhone">
          <Form.Label>Phone No* :</Form.Label>
          <InputGroup>
            <Form.Select
              aria-label="Country Code"
              style={{ maxWidth: "10%" }}
              value={countryCode}
              className="form_feild countrywidth"
              onChange={(e) => {
                setCountryCode(e.target.value);
              }}
            >
              <option defaultChecked key={1} value="0">
                IND
              </option>
              <option key={2} value="+65">
                SGP
              </option>
              <option key={3} value="+60">
                MYS
              </option>
              <option key={4} value="+1">
                USA
              </option>
            </Form.Select>
            <Form.Control
              className="form_feild"
              type="phone"
              placeholder="Enter Mobile No"
              value={crmPhone}
              onChange={(e) => {
                console.log(e.target.value);
                setCrmPhone(e.target.value);
              }}
              autoComplete="new-off"
            />
          </InputGroup>
        </Form.Group>
        <Form.Group className="mb-3" controlId="adminAddadminNewPassword">
          <Form.Label>New Password* :</Form.Label>
          <Form.Control
            className="form_feild"
            type="password"
            placeholder="Enter New Password"
            value={crmNewPassword}
            onChange={(e) => {
              console.log(e.target.value);
              setCrmNewPassword(e.target.value);
            }}
            autoComplete="new-password"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="adminAddadminConfirmPassword">
          <Form.Label>Confirm Password* :</Form.Label>
          <Form.Control
            className="form_feild"
            type="password"
            placeholder="Enter Confirm Password"
            value={crmConfirmPassword}
            onChange={(e) => {
              console.log(e.target.value);
              setCrmConfirmPassword(e.target.value);
            }}
            autoComplete="new-password"
          />
        </Form.Group>
      </Form>
      <Card.Footer className="text-center  all_border">
        {!crmLoading ? (
          <Button
            style={{ borderRadius: 50 }}
            className="all_btn"
            type="submit"
            onClick={onSubmit}
          >
            Register CRM
          </Button>
        ) : (
          <Button className="all_btn" style={{ borderRadius: 50 }} disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Loading...
          </Button>
        )}
      </Card.Footer>
    </Card>
  );
};
export default AdminAddCrmForm;
