import React, { useEffect, useState } from "react";
import { Alert, Container, Snackbar } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Grid } from "@mui/material";
// import { Link, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import Title from "./Title";

// import clients from '../data/userList';
import UpdateAction from "./UpdateAction";
import { useContext } from "react";
import { CrmUserListContext } from "../../pages/crm/UsersList";
import { Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SearchBar from "material-ui-search-bar";
import { vasthuUrl } from "../Api";
import HomeConsultant from "../HomeConsultant";
import { HeaderUserContext } from "../../pages/crm/Layout";

const UsersListTable = (props) => {
  const navigate = useNavigate();

  const { userList, message, setMessage } = useContext(CrmUserListContext);
  const { setCrmUser } = useContext(HeaderUserContext);
  const [consultantList, setConsultantList] = useState([]);
  const token = sessionStorage.getItem("token");
  window.sessionStorage.removeItem("crmSelectedUser");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    try {
      vasthuUrl
        .get(`info/professional/home`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setConsultantList(res.data);
          setLoading(false);
          console.log("consultant_list_data", res.data, token);
        });
    } catch (e) {
      console.log("server error");
      setLoading(false);
    }
  }, []);

  const columns = [
    { field: "name", headerName: "Name", width: 200, editable: true },
    // { field: "phoneNumber", headerName: "Phone", width: 200, editable: true }, // editable updated
    { field: "email", headerName: "Email", width: 200, editable: true },
    // {
    //   field: "actions",
    //   headerName: "Actions",
    //   type: "actions",
    //   renderCell: (params) => <UpdateAction {...{ params }} />,
    // },
    {
      field: "boolslot",
      headerName: "Book Slot",
      type: "actions",
      renderCell: (params) => (
        <Button
          className="all_btn1"
          size="sm"
          {...{ params }}
          onClick={() => {
            console.log("navigate to book slot for ", params.row.name);
            console.log("navigate to book slot for ", params.row.uuid);
            window.sessionStorage.setItem("crmSelectedUser", params.row.uuid);
            window.sessionStorage.setItem("LoggedInUser", params.row.name);
            setCrmUser(params.row.name);
            navigate("/homeconsultant");
          }}
        >
          Book
        </Button>
      ),
      width: 200,
    },
    {
      field: "scheduledbooking",
      headerName: "Scheduled Booking",
      type: "actions",
      renderCell: (params) => (
        <Button
          size="sm"
          className="all_btn1"
          {...{ params }}
          onClick={() => {
            console.log("navigate to List user bookig ", params.row.name);
            window.sessionStorage.setItem("crmSelectedUser", params.row.uuid);
            setCrmUser(params.row.name);
            navigate("/consultantbooklist");
          }}
        >
          SCHEDULED
        </Button>
      ),
      width: 200,
    },
  ];

  return (
    <Container>
      <center>
        <Title>{props.title}</Title>
      </center>
      <Snackbar
        open={message}
        autoHideDuration={6000}
        onClose={() => {
          setMessage("");
        }}
        message={message}
        // action={action}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      {userList.length > 0 ? (
        <>
        <center className="datagrid">
              <Grid container className="deskTable datagrid">
                <DataGrid
                  className="all_border - text-center me-3 data_grid"
                  // rows={props.rows}
                  rows={userList}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                    },
                  }}
                  pageSizeOptions={[5, 10]}
                  // checkboxSelection
                  getRowId={(row) => row.id}
                />
              </Grid>
            </center>

          <div className="justify-content-center mobileCard">
            {userList.map((params, j) => (
              <Card
                key={j}
                className="mobileCard all_border"
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  backgroundColor: "antiquewhite",
                }}
              >
                <Card.Body>
                  <Card.Subtitle className="mb-2">
                    <b>Name:</b> {params.name}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2">
                    <b> Phone:</b> {params.phoneNumber}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2">
                    <b>Email:</b> {params.email}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2 d-flex justify-content-evenly">
                    <Button
                      className="all_btn mb-1"
                      {...{ params }}
                      onClick={() => {
                        console.log("navigate to book slot for ", params.name);
                        console.log("navigate to book slot for ", params.uuid);
                        window.sessionStorage.setItem(
                          "crmSelectedUser",
                          params.uuid
                        );
                        window.sessionStorage.setItem(
                          "LoggedInUser",
                          params.name
                        );
                        setCrmUser(params.name);
                        navigate("/homeconsultant");
                      }}
                      size="sm"
                    >
                      Book
                    </Button>
                    <Button
                      className="all_btn"
                      {...{ params }}
                      onClick={() => {
                        console.log(
                          "navigate to List user bookig ",
                          params.name
                        );
                        window.sessionStorage.setItem(
                          "crmSelectedUser",
                          params.uuid
                        );
                        setCrmUser(params.name);
                        navigate("/consultantbooklist");
                      }}
                      size="sm"
                    >
                      SCHEDULED
                    </Button>
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            ))}
          </div>
        </>
      ) : (
        <>
          <Title>No UserList</Title>
        </>
      )}
    </Container>
  );
};
export default UsersListTable;
