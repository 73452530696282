import React, { useEffect, useState } from "react";
import {
  Button,
  Offcanvas,
  Modal,
  Form,
  Container,
  Col,
  Row,
  Card,
  CardText,
  ListGroup,
  Accordion,
  FloatingLabel,
  Table,
  Alert,
  Spinner,
} from "react-bootstrap";
import { useNavigate, Link, Outlet } from "react-router-dom";
import { vasthuUrl } from "../component/Api";
import { v4 as uuidv4 } from "uuid";
import { UseVasthu } from "../context/vasthu";
import { useTranslation } from "react-i18next";

const HomeFormScreen = ({ name, ...props }) => {
  const navigate = useNavigate();
  const { homeRec } = UseVasthu();
  const {t} = useTranslation();
  const token = window.sessionStorage.getItem("token");
  const [showFloor, setShowFloor] = useState(false);
  const [showElem, setShowElem] = useState(false);
  const [showUpdateElem, setShowUpdateElem] = useState(false);
  const [floorList, setFloorList] = useState([]);
  const [floor, setFloor] = useState([]);
  const [fvalue, setFValue] = useState(null);
  const [facingList, setFacingList] = useState([]);
  const [floorName, setfloorName] = useState("");
  const [elementId, setelementId] = useState("");
  const [elementDirectionId, setelementDirectionId] = useState("");
  const [elementDirectionName, setelementDirectionName] = useState("");
  const [message, setMessage] = useState("");
  const [length, setLength] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [elementUpdateId, setelementUpdateId] = useState("");
  const [elementUpdateType, setelementUpdateType] = useState("");
  const [elementUpdateName, setelementUpdateName] = useState("");
  const [elementUpdateFacingName, setelementUpdateFacingName] = useState("");
  const [elementUpdateFacing, setelementUpdateFacing] = useState("");
  const [elementUpdateLength, setelementUpdateLength] = useState("");
  const [elementUpdateWidth, setelementUpdateWidth] = useState("");
  const [elementUpdateHeight, setelementUpdateHeight] = useState("");
  const [alertShow, setAlertShow] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [temp, setTemp] = useState("");
  const [alertTemp, setAlertTemp] = useState({});
  const [elementUpdateDetails, setelementUpdateDetails] = useState({});
  const [floorUpdateId, setFloorUpdateId] = useState("");
  const [loading, setLoading] = useState(false);
  const [elementList, setelementList] = useState([]);

  console.log("floor details", homeRec);
  const handleClose = () => {
    setShowFloor(false);
  };
  useEffect(() => {
    vasthuUrl.get(`vasthu/getelements`).then((res) => setelementList(res.data));
    vasthuUrl.get(`vasthu/getdirections`).then((direction) => {
      setFacingList(direction.data);
      vasthuUrl.get(`vasthu/getfloors`).then((res) => {
        setFloorList(res.data);
      });
    });
  }, []);

  const alert = (msg) => {
    setErrMsg(msg);
    setTimeout(() => {
      setErrMsg("");
    }, 3000);
  };

  const handleShow = () => setShowFloor(true);
  const handleSubmit = (val) => {
    if (val) {
      var temp = floorList.find((i) => i.name == val);
      const { id, name } = temp;
      var floorId = floor.map((i) => i.floor_id);
      if (!floorId.includes(id)) {
        const newObj = { floor_id: id, floorName: name, elementDetails: [] };
        setFloor([...floor, newObj]);
        setShowFloor(false);
      } else {
        alert("Floor Already Exist");
      }
    } else {
      alert("Please Choose Right Option");
    }
  };
  const handleElemClose = () => {
    setShowElem(false);
    setelementId("");
    setelementDirectionId("");
    setLength("");
    setWidth("");
    setHeight("");
  };
  const handleElemUpdateClose = () => setShowUpdateElem(false);
  const handleElemUpdateSubmit = (floorId, elementUpdateId) => {
    const floorValue = floor.find((i) => i.floor_id === floorId);
    let elementValue = floorValue.elementDetails.find(
      (i) => i.elementUuid == elementUpdateId
    );
    let elementObj = elementList.find((i) => i.id == elementUpdateType);
    let elementDirectionObj = facingList.find(
      (i) => i.id == elementUpdateFacing
    );
    console.log(elementValue);
    elementValue.elementId = elementUpdateType;
    elementValue.elementName = elementObj.name;
    elementValue.elementDirectionName = elementDirectionObj.name;
    elementValue.elementDirectionId = elementUpdateFacing;
    elementValue.width = elementUpdateWidth;
    elementValue.length = elementUpdateLength;
    elementValue.height = elementUpdateHeight;
    setFloor([...floor]);
    setFloorUpdateId("");
    setelementUpdateId("");
    setelementUpdateType("");
    setelementUpdateLength("");
    setelementUpdateWidth("");
    setelementUpdateHeight("");
    setShowUpdateElem(false);
  };
  const handleElemEdit = (floorId, elementObj) => {
    const {
      elementUuid,
      elementId,
      elementDirectionId,
      height,
      length,
      width,
    } = elementObj;
    setFloorUpdateId(floorId);
    setelementUpdateId(elementUuid);
    setelementUpdateType(elementId);
    setelementUpdateFacing(elementDirectionId);
    setelementUpdateLength(length);
    setelementUpdateWidth(width);
    setelementUpdateHeight(height);
    setShowUpdateElem(true);
  };

  const handleElemDelete = (floorId, elementId) => {
    const floorValue = floor.find((i) => i.floor_id == floorId);
    let elementArr = floorValue.elementDetails.filter(
      (i) => i.elementUuid !== elementId
    );
    floorValue.elementDetails = elementArr;
    setFloor([...floor]);
  };
  const handleElemShow = (val) => {
    setShowElem(true);
    const value = floor.find((i) => i.floor_id === val);
    setTemp(value.floor_id);
  };
  const handleElemSubmit = (val) => {
    if (val) {
      const data = floor.find((i) => i.floor_id === val);
      if (elementId && elementDirectionId && height && width && length) {
        let elementObj = elementList.find((i) => i.id == elementId);
        let elementDirectionObj = facingList.find(
          (i) => i.id == elementDirectionId
        );
        // let index = data.elementDetails.length
        data.elementDetails.push({
          elementUuid: uuidv4(),
          elementName: elementObj.name,
          elementDirectionName: elementDirectionObj.name,
          elementId,
          elementDirectionId,
          height,
          width,
          length,
        });
        setFloor([...floor]);
        setelementId("");
        setelementDirectionId("");
        setLength("");
        setWidth("");
        setHeight("");
        setShowElem(false);
      } else {
        alert("Please Enter Valid details");
      }
    } else {
      alert("Please Enter Valid details");
    }
  };

  const handleFloorDelete = (id) => {
    let value = floor.filter((i) => i.floor_id !== id);
    setFloor(value);
  };

  const homeAllSubmit = async () => {
    setLoading(true);
    try {
      const res = await vasthuUrl.post(
        `saveelementdetails`,
        {
          houseId: homeRec,
          floorDetails: floor,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert(res.data.message);
      console.log("save", res.data.message, {
        houseId: homeRec,
        floorDetails: floor,
      });
      setLoading(false);
      navigate("/user/homemiscellaneous");
    } catch (e) {
      alert("SERVER ERROR");
      setLoading(false);
    }
  };
  return (
    <Container>
      <center>
        <Button
          variant="outline-primary"
          style={{ borderRadius: 50 }}
          onClick={handleShow}
          className="mt-2"
        >
          ADD FLOOR
        </Button>
      </center>

      <Alert show={alertShow} variant="danger">
        <Alert.Heading>
          Are you sure, want to delete {alertTemp.floorName} ?!
        </Alert.Heading>
        <p>Floor related elements will also be deleted</p>
        <hr />
        <div className="justify-content-end">
          <Button
            onClick={() => setAlertShow(false)}
            variant="outline-danger"
            style={{ borderRadius: 50 }}
          >
            {t("TXT.BTN.CAN.LO")}
          </Button>
          {"   "}
          <Button
            variant="outline-success"
            style={{ borderRadius: 50 }}
            onClick={() => {
              handleFloorDelete(alertTemp.floor_id);
              setAlertShow(false);
            }}
          >
            Confirm
          </Button>
        </div>
      </Alert>
      {floor.length > 0 ? (
        floor.map((i, j) => (
          // <React.Fragment key={j}>
          <Accordion key={j} className="mt-2" defaultActiveKey={j}>
            <Accordion.Item eventKey={j}>
              <Accordion.Header>
                <h6>{i.floorName}</h6>
              </Accordion.Header>
              <Accordion.Body>
                <div className="my-2">
                  <center>
                    <Button
                      variant="outline-primary"
                      style={{ borderRadius: 50 }}
                      onClick={() => handleElemShow(i.floor_id)}
                    >
                      Add Room
                    </Button>
                    {"  "}
                    <Button
                      variant="outline-primary"
                      style={{ borderRadius: 50 }}
                      onClick={() => {
                        setAlertShow(true);
                        setAlertTemp(i);
                      }}
                    >
                      Delete
                    </Button>
                  </center>
                </div>
                {i.elementDetails.length > 0 ? (
                  <Table key={j} striped bordered hover responsive>
                    <thead>
                      <tr>
                        <th>Room Name</th>
                        <th>Location</th>
                        <th>Width</th>
                        <th>Length</th>
                        <th>Height</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {i.elementDetails.map((x, y) => (
                        <tr key={y}>
                          <td>{x.elementName}</td>
                          <td>{x.elementDirectionName}</td>
                          <td>{x.width}</td>
                          <td>{x.length}</td>
                          <td>{x.height}</td>
                          <td>
                            <Button
                              variant="outline-primary"
                              style={{ borderRadius: 50 }}
                              onClick={() => {
                                handleElemEdit(i.floor_id, x);
                              }}
                              className="mt-2"
                            >
                              EDIT
                            </Button>
                            {"  "}
                            <Button
                              variant="outline-primary"
                              style={{ borderRadius: 50 }}
                              onClick={() =>
                                handleElemDelete(i.floor_id, x.elementUuid)
                              }
                              className="mt-2"
                            >
                              DELETE
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ) : (
                  <div className="my-4">
                    <center>
                      <h6>No Rooms Added</h6>
                    </center>
                  </div>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          // </React.Fragment>
        ))
      ) : (
        <div className="my-4">
          <center>
            <h4>Please Add Home Floor</h4>
          </center>
        </div>
      )}

      {floor.length > 0 && (
        <div className="my-4">
          <center>
            {!loading ? (
              <Button
                variant="outline-warning"
                style={{ borderRadius: 50 }}
                onClick={homeAllSubmit}
              >
                SAVE & CONTINUE
              </Button>
            ) : (
              <Button
                variant="outline-warning"
                style={{ borderRadius: 50 }}
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Loading...
              </Button>
            )}
          </center>
        </div>
      )}

      <Modal show={showFloor} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>ADD FLOOR</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errMsg && (
            <Alert variant="info" className="text-center">
              {errMsg}
            </Alert>
          )}
          <Form>
            <Form.Group className="mb-2" controlId="floor">
              <Form.Select
                aria-label="floor"
                size="lg"
                onChange={(e) => {
                  if (e.target.value) {
                    let floorObj = floorList.find(
                      (i) => i.id == e.target.value
                    );
                    setFValue(floorObj.name);
                  }
                }}
              >
                <option key={0} value="">
                  Select Floor Type
                </option>
                {floorList.map((x, y) => (
                  <option key={y} value={x.id}>
                    {x.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Form>
          <div className="text-center mt-3">
            <Button
              variant="outline-primary"
              style={{ borderRadius: 50 }}
              onClick={handleClose}
            >
              CANCEL
            </Button>
            {"  "}
            <Button
              variant="outline-primary"
              style={{ borderRadius: 50 }}
              onClick={() => {
                handleSubmit(fvalue);
                setFValue(null);
              }}
            >
              SUBMIT
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showElem} onHide={handleElemClose}>
        <Modal.Header className="bg-warning" closeButton>
          <Modal.Title>ADD Room</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errMsg && (
            <Alert variant="info" className="text-center">
              {errMsg}
            </Alert>
          )}
          <center>
            <h5>{temp.floorName}</h5>
          </center>
          <Form>
            <Form.Group className="mb-1" controlId="rooms">
              <Form.Select
                aria-label="rooms"
                size="lg"
                value={elementId}
                onChange={(e) => {
                  if (e.target.value) {
                    const obj = floorList.find((i) => i.id == e.target.value);
                    setelementId(e.target.value);
                    setfloorName(obj.name);
                  }
                }}
              >
                <option key={0} value="">
                  Select Room Type
                </option>
                {elementList.map((a, b) => (
                  <option key={b} value={a.id}>
                    {a.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-1" controlId="Room Location">
              <Form.Select
                aria-label="Room Location"
                size="lg"
                value={elementDirectionId}
                onChange={(e) => {
                  if (e.target.value) {
                    let obj = facingList.find((i) => i.id == e.target.value);
                    setelementDirectionId(e.target.value);
                    setelementDirectionName(obj.name);
                  }
                }}
              >
                <option key={0} value="">
                  Select Room Location
                </option>
                {facingList.map((a, b) => (
                  <option key={b} value={a.id}>
                    {a.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-1">
              <FloatingLabel
                className="mb-1"
                controlId="width"
                label="Room Width"
              >
                <Form.Control
                  type="number"
                  placeholder="Room Width"
                  value={width}
                  onChange={(e) => {
                    setWidth(e.target.value);
                  }}
                />
              </FloatingLabel>
              <FloatingLabel
                className="mb-1"
                controlId="length"
                label="Room Length"
              >
                <Form.Control
                  type="number"
                  placeholder="Room Length"
                  value={length}
                  onChange={(e) => {
                    setLength(e.target.value);
                  }}
                />
              </FloatingLabel>
              <FloatingLabel
                className="mb-1"
                controlId="height"
                label="Room Height"
              >
                <Form.Control
                  type="number"
                  placeholder="Room Height"
                  value={height}
                  onChange={(e) => {
                    setHeight(e.target.value);
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </Form>
          <div className="text-center mt-3">
            <Button
              variant="outline-primary"
              style={{ borderRadius: 50 }}
              onClick={() => handleElemSubmit(temp)}
            >
              SUBMIT
            </Button>
            {"  "}
            <Button
              variant="outline-primary"
              style={{ borderRadius: 50 }}
              onClick={handleElemClose}
            >
              CANCEL
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={showUpdateElem} onHide={handleElemUpdateClose}>
        <Modal.Header closeButton>
          <Modal.Title>UPDATE Room</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errMsg && (
            <Alert variant="info" className="text-center">
              {errMsg}
            </Alert>
          )}
          <center>
            <h5>{elementUpdateDetails.floorName}</h5>
          </center>
          <Form>
            <Form.Group className="mb-1" controlId="roomUpdate">
              <Form.Select
                aria-label="roomUpdate"
                size="lg"
                value={elementUpdateType}
                onChange={(e) => {
                  if (e.target.value) {
                    let obj = elementList.find((i) => i.id == e.target.value);
                    setelementUpdateType(e.target.value);
                    setelementUpdateName(obj.name);
                  }
                }}
              >
                <option key={0} value="">
                  Select Room Type
                </option>
                {elementList.map((a, b) => (
                  <option key={b} value={a.id}>
                    {a.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-1" controlId="roomLocationUpdate">
              <Form.Select
                aria-label="roomLocationUpdate"
                size="lg"
                value={elementUpdateFacing}
                onChange={(e) => {
                  if (e.target.value) {
                    let obj = facingList.find((i) => i.id == e.target.value);
                    setelementUpdateFacing(e.target.value);
                    setelementUpdateFacingName(obj.name);
                  }
                }}
              >
                <option key={0} value="">
                  Select Room Location
                </option>
                {facingList.map((a, b) => (
                  <option key={b} value={a.id}>
                    {a.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
            <Form.Group className="mb-1">
              <FloatingLabel
                className="mb-1"
                controlId="roomwidthUpdate"
                label="room Width"
              >
                <Form.Control
                  type="number"
                  placeholder="room Width"
                  value={elementUpdateWidth}
                  onChange={(e) => {
                    setelementUpdateWidth(e.target.value);
                  }}
                />
              </FloatingLabel>
              <FloatingLabel
                className="mb-1"
                controlId="roomlengthUpdate"
                label="element Length"
              >
                <Form.Control
                  type="number"
                  placeholder="room Length"
                  value={elementUpdateLength}
                  onChange={(e) => {
                    setelementUpdateLength(e.target.value);
                  }}
                />
              </FloatingLabel>
              <FloatingLabel
                className="mb-1"
                controlId="roomheightUpdate"
                label="room Height"
              >
                <Form.Control
                  type="number"
                  placeholder="room Height"
                  value={elementUpdateHeight}
                  onChange={(e) => {
                    setelementUpdateHeight(e.target.value);
                  }}
                />
              </FloatingLabel>
            </Form.Group>
          </Form>
          <div className="text-center mt-3">
            <Button
              variant="outline-primary"
              style={{ borderRadius: 50 }}
              onClick={() =>
                handleElemUpdateSubmit(floorUpdateId, elementUpdateId)
              }
            >
              UPDATE
            </Button>
            {"  "}
            <Button
              variant="outline-primary"
              style={{ borderRadius: 50 }}
              onClick={handleElemUpdateClose}
            >
              CANCEL
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default HomeFormScreen;
