import React from "react";
import { Card } from "react-bootstrap";
import PropsType from "prop-types";

const MobileCardPay = (props) => {
  return (
    <React.Fragment>
      {props.data.map((i, j) => (
        <div className="mobileCard">
          <Card key={j} className="m-1 all_border" style={{ width: "100%" }}>
            <Card.Body>
              <Card.Title>Name: {i.name}</Card.Title>
              <Card.Subtitle className="mb-2 text-muted">
                Tid: {i.tid}
              </Card.Subtitle>
              <Card.Subtitle className="mb-2 text-muted">
                Amount: {i.amount}
              </Card.Subtitle>
              <Card.Subtitle className="mb-2 text-muted">
                Type: {i.ptype}
              </Card.Subtitle>
            </Card.Body>
          </Card>
        </div>
      ))}
    </React.Fragment>
  );
};
MobileCardPay.propsType = {
  data: PropsType.arrayOf(PropsType.object).isRequired,
};
export default MobileCardPay;
