const CONFIG = {
  style: {
    bodyBackgroundColor: "#fafafb",
    bodyColor: "",
    themeBackgroundColor: "#dfa231",
    themeColor: "#ffffff",
    headerBackgroundColor: "#284055",
    headerColor: "#ffffff",
    errorColor: "",
    successColor: "",
    card: {
      padding: "",
      backgroundColor: "",
    },
  },
  jsFile: "",
  data: {
    orderId: "3366",
    amount: "2000",
    token: "9bd058581fa14aa0924bbd1539e6225f1667810248639",
    tokenType: "TXN_TOKEN",
    userDetail: {
      mobileNumber: "9876543210",
      name: "user",
    },
  },
  merchant: {
    mid: "tEGKGO01160299011768",
    name: "vasthuParam",
    logo: "",
    redirect: false,
  },
  mapClientMessage: {},
  labels: {},
  payMode: {
    labels: {},
    filter: {
      exclude: [],
    },
    order: ["NB", "CARD", "LOGIN", "UPI"],
  },
  flow: "DEFAULT",
};

export default CONFIG;
