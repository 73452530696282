import React from 'react'
import OurCallServices from './OurCallServices'

function ScheduleBooking() {
  return (
    <div>
      <OurCallServices details={{ selectedHead:"USR.BOOK.BTN"}} />
    </div>
  )
}

export default ScheduleBooking