import React, { useEffect, useState } from "react";
import { vasthuUrl } from "../component/Api";
import { Alert, Button, Spinner, Form } from "react-bootstrap";
import { Table, Card } from "react-bootstrap";
import { Modal } from "react-bootstrap";

const DeactivatedProfessionalList = () => {
  const token = window.sessionStorage.getItem("token");
  const [initiateData, setInitiateData] = useState([]);
  console.log("initiateData", initiateData);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    try {
      vasthuUrl
        .get(`/info/deactivatedProfessional`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("users", res.data);
          setInitiateData(res.data);
          setLoading(false);
        });
    } catch (e) {
      alert("SERVER ERROR");
      setLoading(false);
    }
  }, []);

  return (
    <React.Fragment>
      <center>
        <h4>
          <u>Deactivated Consultant List</u>
        </h4>
      </center>
      {!loading ? (
        <>
          <Table
            striped
            bordered
            hover
            responsive
            className="deskTable all_border text-center"
            // style={{
            //   textAlign:'center'
            // }}
          >
            <thead>
              <tr>
                <th>User Name</th>
                <th>Email id</th>
                <th>PhoneNumber</th>
                <th>ProfileAccountStatus</th>
              </tr>
            </thead>
            <tbody>
              {initiateData.map((i, j) => (
                <tr key={j}>
                  <td>{i.userName}</td>
                  <td>{i.emailId}</td>
                  <td>{i.phoneNumber}</td>
                  <td>{i.profileAccountStatus}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      ) : (
        <center>
          <Spinner animation="border" variant="warning" />
        </center>
      )}
    </React.Fragment>
  );
};
export default DeactivatedProfessionalList;
