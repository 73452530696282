import React, { useState, useMemo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Form, FloatingLabel } from "react-bootstrap";
import Axios from "axios";
import { vasthuUrl } from "./Api";

const isDisabled = false;

const LangChager = () => {
  const { i18n } = useTranslation();
  const iniLang = localStorage.getItem("i18nextLng");
  const [allLang, setAllLang] = useState([]);
  const langMemo = useMemo(() => {
    return iniLang;
  }, [iniLang]);
  const [lang, setLang] = useState(langMemo);
  useEffect(() => {
    vasthuUrl.get("openapi/lang/allCode").then((res) => {
      console.log("available lang", res.data);
      setAllLang(res.data);
    });
  }, []);
  return (
    <Form controlId="lang">
      <Form.Select
        aria-label="lang"
        // disabled={isDisabled}
        size="md"
        value={lang}
        className="form_feild mt-2"
        onChange={(e) => {
          localStorage.setItem("i18nextLng", e.target.value);
          i18n.changeLanguage(e.target.value);
          setLang(e.target.value);
        }}
      >
        {allLang.map((i) => (
          <option key={i.id} value={i.langCode}>
            {i.langText}
          </option>
        ))}
      </Form.Select>
    </Form>
  );
};
export default LangChager;
