import React, { useEffect, useMemo, useState, Suspense } from "react";
import Axios from "axios";
import i18n from "i18next";
import { I18nextProvider, initReactI18next } from "react-i18next";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import resourcesToBackend from "i18next-resources-to-backend";
import Spinner from "react-bootstrap/Spinner";

import LangResource from "../lang1.json";
// import LangResource from "../../public/translation.json";

import fetchLang from "../component/LangJson";
import { vasthuUrl } from "../component/Api";

const LangProvider = ({ children }) => {
  const [allLangDatas, setLangDatas] = useState({});
  useEffect(() => {
    vasthuUrl
      .get(`lang/getAllLangData`)
      .then((res) => {
        console.log("en lang out from context", res.data);
        setLangDatas(res.data);
      })
      .catch((err) => console.log("lang fetch fails"));
  }, []);

  i18n
    // .use(HttpBackend)
    .use(LanguageDetector)
    .use(ChainedBackend)
    .use(initReactI18next)
    .init({
      partialBundledLanguages: true,
      lng: "en",
      fallbackLng: "en",
      debug: true,
      load: "languageOnly",
      preload: ["en", "ta", "hi"],
      ns: ["translation"],
      defaultNS: "translation",
      keySeparator: false,
      resources: allLangDatas,
      // backend: {
      //   loadPath: 'http://localhost:3000/locales/{{lng}}/{{ns}}.json'
      // },
      backend: {
        backends: [
          HttpBackend, // if a namespace can't be loaded via normal http-backend loadPath, then the inMemoryLocalBackend will try to return the correct resources
          resourcesToBackend(LangResource),
        ],
        backendOptions: [
          {
            loadPath: `${process.env.REACT_APP_LOCALES_JSON_LANG}`,
          },
        ],
      },
      detection: {
        order: ["localStorage"],
        initImmediate: true,
        react: {
          wait: true,
          bindI18n: "languageChanged loaded",
          bindStore: "added removed",
          nsMode: "default",
        },
      },
    });

  const memoValue = useMemo(() => {
    return i18n;
    // }, [allLangDatas]);
  }, [i18n]);

  return (
    // <Suspense fallback={<h1>Loading..</h1>}>
    <Suspense
      fallback={
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20%",
          }}
        >
          <Spinner animation="border" variant="warning" />
        </div>
      }
    >
      <I18nextProvider value={memoValue}>{children}</I18nextProvider>
    </Suspense>
  );
};

export default LangProvider;
