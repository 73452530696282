import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import { Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { MdEdit } from "react-icons/md";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stacks from "@mui/material/Stack";
import { vasthuUrl } from "../../component/Api";

function Translation() {
  const token = window.sessionStorage.getItem("token");
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [transType, setTransType] = useState("");
  const [transList, setTransList] = useState([]);
  //toast aleart
  const [openSnack, setOpenSnack] = React.useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("error");
  //add or edit translate
  const [keyCode, setKeyCode] = useState("");
  const [screenName, setScreenName] = useState("");
  const [taValue, setTaValue] = useState("");
  const [enValue, setEnValue] = useState("");
  const [hiValue, setHiValue] = useState("");
  const [type, setType] = useState("");
  const [saveData, setSaveData] = useState();
  console.log("saveData", saveData);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const alert = (msg) => {
    setMessage(msg);
    setOpenSnack(true);
    setTimeout(() => {
      setMessage("");
    }, 4000);
  };

  const handleSeachTrans = () => {
    setLoading(true);
    try {
      vasthuUrl
        .get(`/getAllLanguageTranslations/${transType}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("service", res.data);
          setLoading(false);

          setTransList(res.data);
        })
        .catch((e) => {
          console.log("serviceeeee", e);
          setLoading(false);
          setTransList("");
        });
    } catch (e) {
      console.log("service", e);
      setLoading(false);
      setTransList("");
    }
  };

  const handleAddProduct = async () => {
    try {
      const res = await vasthuUrl
        .post(
          `/saveLanguageTranslationForKeycode`,
          {
            keyCode: keyCode,
            screenName: screenName,
            taValue: taValue,
            enValue: enValue,
            hiValue: hiValue,
            type: type,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log("after save", res.data);
          alert("Add Translation Successfully!!!");
          setMessageType("success");
          addTransModalClose();
        });
    } catch (e) {
      alert(e);
      setMessageType("error");
      addTransModalClose();
    }
  };

  const handleEditTrans = () => {
    try {
      vasthuUrl
        .put(
          `/updateLanguageTranslationForKeycode`,
          {
            keyCode: saveData.keyCode,
            screenName: saveData.screenName,
            taValue: saveData.taValue,
            enValue: saveData.enValue,
            hiValue: saveData.hiValue,
            type: saveData.type,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log("service", res.data);
          alert("Update Product Successfully!!!");
          setMessageType("success");
          addTransModalClose();
        });
    } catch (e) {
      console.log("service", e);
      alert(e);
      setMessageType("error");
      addTransModalClose();
    }
  };

  const handleSyncTrans = () => {
    setLoading(true);
    try {
      vasthuUrl
        .get(`/lang/syncAllLangData/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("service", res.data);
          setLoading(false);
        })
        .catch((e) => {
          console.log("serviceeeee", e);
          setLoading(false);
        });
    } catch (e) {
      console.log("service", e);
      setLoading(false);
    }
  };

  const listtemp = transList.map((obj, index) => {
    return {
      ...obj,
      id: index + 1,
    };
  });
  console.log("listtemp", listtemp);

  const columns = [
    { field: "keyCode", headerName: "KEY CODE", width: 150, editable: true },
    {
      field: "screenName",
      headerName: "SCREEN NAME",
      width: 150,
      editable: true,
    },
    { field: "taValue", headerName: "TAMIL", width: 150, editable: true },
    { field: "enValue", headerName: "ENGLISH", width: 150, editable: true },
    { field: "hiValue", headerName: "HINDI", width: 150, editable: true },
    { field: "type", headerName: "TYPE", width: 100, editable: true },
    {
      field: "action",
      headerName: "ACTION",
      type: "actions",
      renderCell: (i) => (
        <Button
          className="all_btn"
          onClick={() => {
            handleEdit(i.row);
          }}
        >
          EDIT <MdEdit size={20} />
        </Button>
      ),
      width: 150,
    },
  ];

  const [addTransModal, setAddTransModal] = useState(false);

  const addTransModalOpen = () => {
    setAddTransModal(true);
  };

  const addTransModalClose = () => {
    setAddTransModal(false);
    setSaveData();
    setKeyCode("");
    setScreenName("");
    setTaValue("");
    setEnValue("");
    setHiValue("");
    setType("");
  };
  const handleEdit = (i) => {
    console.log("i", i);
    addTransModalOpen();
    setSaveData(i);
  };

  return (
    <Container>
      <div className="mt-3">
        <div>
          <Row className="justify-content-evenly add_cat_btn">
            <Col className="mt-3 all_border" sm={12} xs={12} md={8} lg={8}>
              <Form.Select
                aria-label="transType"
                size="lg"
                value={transType}
                className="form_feild"
                onChange={(e) => {
                  setTransType(e.target.value);
                }}
              >
                <option key="" value="">
                  Select Type
                </option>
                <option key="0" value="U">
                  User
                </option>
                <option key="1" value="P">
                  Consultant
                </option>
                <option key="2" value="C">
                  Common
                </option>
              </Form.Select>
            </Col>
            <Col sm={12} xs={12} md={4} lg={4} className="mt-3">
              <Button
                className="all_btn"
                onClick={handleSeachTrans}
                disabled={transType ? false : true}
              >
                SEARCH
              </Button>
            </Col>
          </Row>
        </div>
        <div
          className="my-3"
          style={{ display: "flex", justifyContent: "flex-end", gap: "1rem" }}
        >
          <Button
            className="all_btn"
            onClick={() => {
              addTransModalOpen();
            }}
          >
            ADD TRANSLATE <MdEdit size={20} />
          </Button>
          <Button className="all_btn" onClick={handleSyncTrans}>
            SYNC TRANSLATION
          </Button>
        </div>
        <div className="mt-3">
          {!loading ? (
            listtemp.length > 0 ? (
              <>
                <center className="datagrid mt-2">
                  <Grid container className="deskTable datagrid">
                    <DataGrid
                      className="all_border - text-center me-3 data_grid"
                      rows={listtemp}
                      columns={columns}
                      initialState={{
                        pagination: {
                          paginationModel: { page: 0, pageSize: 5 },
                        },
                      }}
                      pageSizeOptions={[5, 10]}
                      // checkboxSelection
                      getRowId={(row) => row.keyCode}
                    />
                  </Grid>
                </center>

                <div className="justify-content-center mobileCard">
                  {transList &&
                    transList.map((i, j) => (
                      <Card
                        key={j}
                        className="mobileCard all_border"
                        style={{
                          width: "100%",
                          marginBottom: "10px",
                          backgroundColor: "antiquewhite",
                        }}
                      >
                        <Card.Body>
                          <Card.Subtitle className="mb-2">
                            <b>KEY CODE:</b> {i.keyCode}
                          </Card.Subtitle>
                          <Card.Subtitle className="mb-2">
                            <b>SCREEN NAME:</b> {i.screenName}
                          </Card.Subtitle>
                          <Card.Subtitle className="mb-2">
                            <b>TAMIL:</b> {i.taValue}
                          </Card.Subtitle>
                          <Card.Subtitle className="mb-2">
                            <b>ENGLISH:</b> {i.enValue}
                          </Card.Subtitle>
                          <Card.Subtitle className="mb-2">
                            <b>HINDI:</b> {i.hiValue}
                          </Card.Subtitle>
                          <Button
                            className="all_btn"
                            onClick={() => {
                              handleEdit(i);
                            }}
                          >
                            EDIT <MdEdit size={20} />
                          </Button>
                        </Card.Body>
                      </Card>
                    ))}
                </div>
              </>
            ) : (
              <center>
                <h5>{t("TXT.NO.REC.FUD.LO")}</h5>
              </center>
            )
          ) : (
            <center className="mt-5">
              <Spinner animation="border" variant="warning" />
            </center>
          )}
        </div>
      </div>

      <Modal
        show={addTransModal}
        onHide={addTransModalClose}
        size="lg"
        centered
        fullscreen={true}
      >
        <Card className="p-2">
          <Card.Header className="all_background">
            <center>
              <h2>ADD TRANSLATE</h2>
            </center>
          </Card.Header>
          <Card.Body>
            <Form>
              <Form.Group className="mb-3" controlId="keyCode">
                <Form.Label style={{ fontSize: 16, fontWeight: "bold" }}>
                  Key Code
                </Form.Label>
                <Form.Control
                  type="text"
                  value={saveData ? saveData.keyCode : keyCode}
                  className="form_feild"
                  placeholder="Enter Key Code"
                  onChange={(e) =>
                    setSaveData(
                      { ...saveData, keyCode: e.target.value },
                      setKeyCode(e.target.value)
                    )
                  }
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="screenName">
                <Form.Label style={{ fontSize: 16, fontWeight: "bold" }}>
                  Screen Name
                </Form.Label>
                <Form.Select
                  type="text"
                  value={saveData ? saveData.screenName : screenName}
                  className="form_feild"
                  placeholder="Enter Screen Name"
                  onChange={(e) =>
                    setSaveData(
                      { ...saveData, screenName: e.target.value },
                      setScreenName(e.target.value)
                    )
                  }
                >
                  <option key="" value="">
                    Select Screen
                  </option>
                  <option key="0" value="HOME SCREEN">
                    HOME SCREEN
                  </option>
                  <option key="1" value="PLOT SCREEN">
                    PLOT SCREEN
                  </option>
                  <option key="2" value="PRODUCT SCREEN">
                    PRODUCT SCREEN
                  </option>
                  <option key="3" value="OFFER SCREEN">
                    OFFER SCREEN
                  </option>
                  <option key="4" value="HOME RESULT SCREEN / REPORT SCREEN">
                    HOME RESULT SCREEN / REPORT SCREEN
                  </option>
                  <option key="5" value="BOOKING SCREEN">
                    BOOKING SCREEN
                  </option>
                  <option key="6" value="AUDIO CALL HISTORY SCREEN">
                    AUDIO CALL HISTORY SCREEN
                  </option>
                  <option key="7" value="PAYMENT DETAILS SCREEN">
                    PAYMENT DETAILS SCREEN
                  </option>
                  <option key="8" value="MYACCOUNT SCREEN">
                    MYACCOUNT SCREEN
                  </option>
                  <option key="9" value="APPOINMENT SCREEN">
                    APPOINMENT SCREEN
                  </option>
                  <option key="10" value="MEETING SCREEN">
                    MEETING SCREEN
                  </option>
                  <option key="11" value="REVIEW SCREEN">
                    REVIEW SCREEN
                  </option>
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3" controlId="enValue">
                <Form.Label style={{ fontSize: 16, fontWeight: "bold" }}>
                  English Name
                </Form.Label>
                <Form.Control
                  type="text"
                  value={saveData ? saveData.enValue : enValue}
                  className="form_feild"
                  placeholder="Enter English Name"
                  onChange={(e) =>
                    setSaveData(
                      { ...saveData, enValue: e.target.value },
                      setEnValue(e.target.value)
                    )
                  }
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="taValue">
                <Form.Label style={{ fontSize: 16, fontWeight: "bold" }}>
                  Tamil Name
                </Form.Label>
                <Form.Control
                  type="text"
                  value={saveData ? saveData.taValue : taValue}
                  className="form_feild"
                  placeholder="Enter Tamil Name"
                  onChange={(e) =>
                    setSaveData(
                      { ...saveData, taValue: e.target.value },
                      setTaValue(e.target.value)
                    )
                  }
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="hiValue">
                <Form.Label style={{ fontSize: 16, fontWeight: "bold" }}>
                  Hindi Name
                </Form.Label>
                <Form.Control
                  type="text"
                  value={saveData ? saveData.hiValue : hiValue}
                  className="form_feild"
                  placeholder="Enter Hindi Name"
                  onChange={(e) =>
                    setSaveData(
                      { ...saveData, hiValue: e.target.value },
                      setHiValue(e.target.value)
                    )
                  }
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="type">
                <Form.Label style={{ fontSize: 16, fontWeight: "bold" }}>
                  Type
                </Form.Label>
                <Form.Select
                  aria-label="type"
                  size="lg"
                  value={saveData ? saveData.type : type}
                  className="form_feild"
                  onChange={(e) => {
                    setSaveData(
                      { ...saveData, type: e.target.value },
                      setType(e.target.value)
                    );
                  }}
                >
                  <option key="" value="">
                    Select Type
                  </option>
                  <option key="0" value="U">
                    User
                  </option>
                  <option key="1" value="P">
                    Consultant
                  </option>
                  <option key="2" value="C">
                    Common
                  </option>
                </Form.Select>
              </Form.Group>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "2rem",
                }}
              >
                <Button
                  className="mt-3 all_btn"
                  style={{
                    borderRadius: 50,
                  }}
                  onClick={() => {
                    addTransModalClose();
                  }}
                >
                  {t("TXT.BTN.CAN.LO")}
                </Button>
                <div className="d-flex g-2">
                  {saveData && saveData.id ? (
                    <Button
                      className="mt-3 all_btn"
                      style={{
                        borderRadius: 50,
                      }}
                      onClick={() => handleEditTrans()}
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      className="mt-3 all_btn"
                      style={{
                        borderRadius: 50,
                      }}
                      onClick={() => handleAddProduct()}
                    >
                      Upload
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Modal>

      <Stacks spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleCloseSnack}
        >
          <MuiAlert
            variant="filled"
            onClose={handleCloseSnack}
            severity={messageType}
            sx={{
              width: "100%",
            }}
          >
            {message}
          </MuiAlert>
        </Snackbar>
      </Stacks>
    </Container>
  );
}

export default Translation;
