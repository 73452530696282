import React from "react";
import { UseAuth } from "../context/Auth";
import { Navigate, useLocation } from "react-router-dom";

const UserProtectedRoute = ({ children }) => {
  // const {role} = UseAuth();
  const token = window.sessionStorage.getItem("token");
  const role = window.sessionStorage.getItem("role");
  const location = useLocation();
  window.sessionStorage.setItem("rerender", location.pathname);

  console.log("user protected route", location);
  console.log("user protected", token, role);

  if (!token && role !== "ROLE_USER") {
    return <Navigate to="/login" replace state={{ from: location }} />;
  }

  return children;
};

export default UserProtectedRoute;
