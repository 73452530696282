import React from "react";
import { Card, Button } from "react-bootstrap";
import PropsType from "prop-types";
import { vasthuUrl } from "../component/Api";
import { useState } from "react";


const MobileCard = (props) => {

  const [key, setKey] = useState("userpaymentlist");
  const token = window.sessionStorage.getItem("token");
  const [clientData, setClientData] = useState([]);
  const [message, setMessage] = useState("");
  // const userId = window.sessionStorage.getItem("userId");
  const [loading, setLoading] = useState(false);
  const alert = (msg) => {
    setMessage(msg);
    setTimeout(() => {
      setMessage("");
    }, 4000);
  };

  const [show, setShow] = useState(false);
  const [userPaymentList, setUserPaymentList] = useState([]);
  const [userAppoinmentList, setUserAppoinmentList] = useState([]);
  const [userAudiocallList, setUserAudiocallList] = useState([]);
  const [userRefundList, setUserRefundList] = useState([]);

  const handleShow = (id) => {
    setShow(true);
    try {
      vasthuUrl
        .get(`vasthu/getUserTransactionHistory/${id.uuid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          setUserPaymentList(res.data);
        });
    } catch (e) {
      console.log("SERVER ERROR");
      setLoading(false);
    }

    try {
      vasthuUrl
        .get(`/refund/getParticularUserRefundIntiatedList/${id.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("users", res.data);
          setUserRefundList(res.data);
          setLoading(false);
        });
    } catch (e) {
      alert("SERVER ERROR");
      setLoading(false);
    }

    try {
      vasthuUrl
        .get(`getoveralluserbookinghistory/${id.uuid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setUserAppoinmentList(res.data);
          console.log(res.data);
          // setShow(true);
        });
    } catch (e) {
      console.log("SERVER ERROR");
      setLoading(false);
    }

    try {
      vasthuUrl
        .get(`vasthu/user/getAudioCallUserHistory/${id.uuid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setUserAudiocallList(res.data);
          console.log(res.data);
        });
    } catch (e) {
      console.log("SERVER ERROR");
      setLoading(false);
    }
  };

  return (
    <div className="justify-content-center mobileCard">
      {props.data.map((i, j) => (
        <Card key={j} className="mobileCard all_border" style={{ width: "100%", marginBottom: '10px', backgroundColor: 'antiquewhite' }}>
          <Card.Body>
            <Card.Title>Name: {i.name}</Card.Title>
            <Card.Subtitle className="mb-2 text-muted">
              Phone: {i.phoneNumber}
            </Card.Subtitle>
            <Card.Subtitle className="mb-2 text-muted">
              Email: {i.email}
            </Card.Subtitle>
            <Card.Subtitle className="mb-2 text-muted">
              Category: {i.consultantLevel}
            </Card.Subtitle>
            <Card.Subtitle className="mb-2 text-muted">
              Created Date: {"14/02/2024 3:10"}
            </Card.Subtitle>
            <Card.Subtitle className="mb-2 text-muted">
              Details: <Button size="sm" className="all_btn"
                onClick={() => {
                  handleShow(i.row);
                  console.log("i", i);
                  console.log("i.row", i.row);
                }}>Details</Button>
            </Card.Subtitle>
          </Card.Body>
          {/* <Card.Footer className="d-flex text-center">
          <Col xs={4}>
            <Button variant="primary">UPDATE</Button>
          </Col>
          <Col xs={4}>
            <Button variant="primary">BLOCK</Button>
          </Col>
          <Col xs={4}>
            <Button variant="primary">SMS</Button>
          </Col>
        </Card.Footer> */}
        </Card>
      ))}
    </div>
  );
};
MobileCard.propsType = {
  data: PropsType.arrayOf(PropsType.object).isRequired,
};
export default MobileCard;
