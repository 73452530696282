import React, { useState } from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
} from "react-bootstrap";
import { vasthuUrl } from "../component/Api";
import StarRating from "../component/StarRating";
import { UseAuth } from "../context/Auth";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import VasthuParamReview from "./VasthuParamReview";

const UserAppReview = () => {
  // const { userId } = UseAuth();
  const userId = window.sessionStorage.getItem("userId");
  const token = window.sessionStorage.getItem("token");
  const [rating, setRating] = useState(3);
  const [reviewDesc, setReviewDesc] = useState("");
  const [msg, setMsg] = useState("");
  const [show, setShow] = useState(false);
  const {t} = useTranslation();
  const Navigate = useNavigate();

  const alert = (msg) => {
    setMsg(msg);
    setShow(true);
    setTimeout(() => {
      setShow(false);
      setMsg("");
    }, 3000);
  };

  const saveAppReview = async () => {
    if (rating == 0) {
      return alert(t("USR.REV.JPM.ALT"));
    }

    try {
      const res = await vasthuUrl
        .post(
          "saveappreview ",
          {
            user_uuid: userId,
            user_rating: rating,
            review_description: reviewDesc,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          Navigate("/");
        });
      console.log(res.data);
      alert(res.data.message);
    } catch (error) {
      return alert("SERVER ERROR");
    }

    setRating(0);
    setReviewDesc("");
  };

  return (
    <Container
      className="rounded p-1 my-2"
      style={{ border: "1px solid #ffb33e" }}
    >
      {/* <Row className="mb-3">
        <Col sm={2} md={3} lg={3} />
        <Col xs={12} sm={8} md={6} lg={6} style={{border:'1px solid  #ffc107'}}>
        </Col>
        <Col sm={2} md={3} lg={3} />
      </Row> */}
      <Card
        className="p-4"
        style={{ margin: 20, border: "1px solid  #ffc107" }}
      >
        <Alert show={show} className="mb-3" variant="info">
          {msg}
        </Alert>
        <Card.Img
          variant="top"
          src="https://www.elegantthemes.com/blog/wp-content/uploads/2018/07/yelp-reviews-wordpress.png"
        />
        <Card.Body className="text-center">
          <Card.Title style={{ color: "#ffbb33" }}>
            {t("USR.REV.JPM.HD.TL")}
          </Card.Title>
        </Card.Body>

        <Form>
          {/* <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="name@example.com" />
      </Form.Group> */}

          <h6>{t("USR.REV.JPM.TL1")}</h6>

          <div
            className="star-rating"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {[...Array(5)].map((star, index) => {
              index += 1;
              return (
                <button
                  type="button"
                  style={{
                    backgroundColor: "transparent",
                    outline: "none",
                    cursor: "pointer",
                    border: "none",
                    position: "relative",
                  }}
                  key={index}
                  className={index <= rating ? "on" : "off"}
                  onClick={() => {
                    console.log("STAR RATING", index);
                    setRating(index);
                  }}
                >
                  <span
                    className="star"
                    style={{ fontSize: "30px", textAlign: "center" }}
                  >
                    &#9733;
                  </span>
                </button>
              );
            })}
          </div>

          <Form.Group controlId="exampleForm.ControlTextarea1">
            <h6>{t("USR.REV.JPM.IN.NM")}</h6>
            <Form.Control
              className="all_border"
              as="textarea"
              rows={5}
              size="sm"
              maxLength={250}
              onChange={(e) => {
                setReviewDesc(e.target.value);
              }}
              placeholder={t("USR.REV.JPM.IN.PL")}
              value={reviewDesc}
            />
          </Form.Group>
        </Form>

        <Card.Body className="text-center">
          <Button
            variant="outline-warning"
            style={{ color: "black", fontWeight: "bold" }}
            onClick={saveAppReview}
          >
            {t("USR.HOM.BTN.SUB")}
          </Button>
        </Card.Body>
      </Card>
      <VasthuParamReview />
    </Container>
  );
};

export default UserAppReview;
