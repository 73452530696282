import React, { createContext, useState } from "react";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";

//custom - Vasthuparam
import theme from "../../component/crm/theme";
import MyHeader from "../../component/crm/MyHeader";
import { Outlet } from "react-router-dom";

export const HeaderUserContext = createContext({});

export default function Layout() {
  const [crmUser, setCrmUser] = useState(null);
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles
        styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
      />
      <CssBaseline />
      <HeaderUserContext.Provider value={{ crmUser, setCrmUser }}>
        <MyHeader />
        <Outlet />
      </HeaderUserContext.Provider>
    </ThemeProvider>
  );
}
