// import { format } from "date-fns";
// import React, { Suspense, useEffect, useState } from "react";
// import { Alert, Badge, Button, Modal, Spinner } from "react-bootstrap";
// import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";
// import Billdesk from "../component/BillDeskPay";
// import { UseAuth } from "../context/Auth";
// import { vasthuUrl } from "./Api";

// const ServiceList = (props) => {
//   const navigate = useNavigate();
//   const { t } = useTranslation();
//   const lang = localStorage.getItem("i18nextLng");
//   const { userId } = UseAuth();
//   const token = window.sessionStorage.getItem("token");
//   const [show, setShow] = useState(false);
//   const [details, setDetails] = useState([]);
//   const [offerplan, setOfferplan] = useState([]);
//   const [showPaytm, setShowPaytm] = useState(false);
//   const [model, setModel] = useState(false);
//   const [paymentDetails, setPaymentDetails] = useState([]);
//   const [timeStampFormat, setTimeStampFormat] = useState("");
//   const [paymentCredentials, setPaymentCredentials] = useState({
//     txnToken: "",
//     orderId: "",
//     amount: 0,
//     offerId: 0,
//     userId: "",
//   });

//   const handleClose = () => {
//     setShow(false);
//   };

//   const handleOpen = (id) => {
//     setShow(true);
//     setDetails(id);
//     setOfferplan(id.offerDetails);
//   };
//   console.log("details", details);
//   console.log("offerplan", offerplan);

//   const formatDate = (date) => {
//     try {
//       return format(date, "d MMM yyyy, HH:mm a"); // Example format string
//     } catch (error) {
//       console.error("Error formatting date:", error.message);
//       return ""; // or return a default value
//     }
//   };
//   const ValidDate = (date) => {
//     try {
//       return format(date, "d MMM yyyy"); // Example format string
//     } catch (error) {
//       console.error("Error formatting date:", error.message);
//       return ""; // or return a default value
//     }
//   };

//   const dateStr =
//     paymentDetails && paymentDetails.timestamp ? paymentDetails.timestamp : "";
//   console.log("dateStr", dateStr);

//   useEffect(() => {
//     if (dateStr) {
//       const [datePart, timePart] = dateStr.split(" "); // Split date and time parts
//       const [day, month, year] = datePart.split("-").map(Number);
//       const [hours, minutes, seconds] = timePart.split(":").map(Number);
//       const dateObj = new Date(year, month - 1, day, hours, minutes, seconds);
//       if (!isNaN(dateObj.getTime())) {
//         const options = {
//           year: "numeric",
//           month: "2-digit",
//           day: "2-digit",
//           hour: "2-digit",
//           minute: "2-digit",
//           second: "2-digit",
//           hour12: true, // Use 12-hour clock with AM/PM
//         };
//         const formattedDate = dateObj.toLocaleString("en-US", options);
//         console.log(formattedDate); // Output: "04/18/2024, 12:03:59 PM"
//         setTimeStampFormat(formattedDate);
//       } else {
//         console.error("Invalid date format:", dateStr);
//       }
//     } else {
//       console.error("Date string is undefined or null.");
//     }
//   }, [dateStr]);

//   const BillDeskPayHandler = (txn) => {
//     console.log(txn);
//     vasthuUrl
//       .post(
//         "vasthu/user/bdpaymentresponseui",
//         { txn: txn.txnResponse.transaction_response },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       )
//       .then((res) => {
//         console.log("txnres", res.data);
//         // if (res.data.transaction_error_type == "success") {
//         //   navigate("/customerpricing");
//         // }

//         if (res.data.transaction_error_type === "success") {
//           console.log("Saving payment_response ....", txn);

//           vasthuUrl
//             .post("vasthu/user/bdpaymentresponsehandler", txn, {
//               headers: {
//                 Authorization: `Bearer ${token}`,
//               },
//             })
//             .then((res1) => console.log("after txnsave", res1.data))
//             .catch((err) => console.log("after txnsave try", err));

//           // vasthuUrl.post("vasthu/user/bdpaymentresponsehandler", txn,
//           //   {
//           //     headers: {
//           //       Authorization: `Bearer ${token}`,
//           //     },
//           //   }
//           // )
//           //   .then(res => console.log("after txnsave", res.data))
//           //   .catch(err => console.log("after txnsave try", err))

//           console.log("buying .. subsc");
//           setTimeout(() => {
//             vasthuUrl
//               .post(
//                 "subscription/buyVasthuOffer",
//                 {
//                   orderId: res.data.orderid,
//                   userUuid: userId,
//                   offerId: parseInt(res.data.additional_info.additional_info2),
//                   paymentStatus: true,
//                 },
//                 {
//                   headers: {
//                     Authorization: `Bearer ${token}`,
//                   },
//                 }
//               )
//               .then((res) => {
//                 console.log("Payment status changed to true");
//                 console.log("afterbuysubscr", res.data);
//                 setPaymentDetails(res.data);
//                 modelOpen();
//                 // navigate("/customerpricing");
//                 //  this.props.navigate(this.props.screenName,{state:{offerId:res.data.id}})
//               });
//           }, 2000); //5000
//         } else {
//           vasthuUrl
//             .post(
//               "subscription/buyVasthuOffer",
//               {
//                 orderId: res.data.orderid,
//                 userUuid: userId,
//                 offerId: parseInt(res.data.additional_info.additional_info2),
//                 paymentStatus: false,
//               },
//               {
//                 headers: {
//                   Authorization: `Bearer ${token}`,
//                 },
//               }
//             )
//             .then((res) => {
//               console.log("Payment status changed to false");
//             });
//         }
//       });
//   };

//   const handleBuy = async (val, price) => {
//     try {
//       await vasthuUrl
//         .post(
//           `vasthu/openapi/createorderapi`,
//           {
//             userUuid: userId,
//             offerId: val,
//             price: price,
//             currency: "356",
//             returnUrl: "customerpricing",
//             lng:lang
//           },
//           {
//             headers: {
//               Authorization: `Bearer ${token}`,
//             },
//           }
//         )
//         .then((res) => {
//           console.log(res.data);
//           handleClose();
//           Billdesk(res.data, BillDeskPayHandler)
//             .then((config) => {
//               console.log("payout", config);
//               window.loadBillDeskSdk(config);
//             })
//             .catch((err) => console.log("payment initialization fails", err));
//         });
//     } catch (err) {
//       console.log(err);
//       Alert("Paytm server Error" + err);
//     }
//   };

//   const handleFreeOffer = (id) => {
//     vasthuUrl
//       .post(
//         "subscription/buyVasthuOffer",
//         {
//           orderId: "FREE",
//           userUuid: userId,
//           offerId: id,
//           paymentStatus: true, // test
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         }
//       )
//       .then((res) => {
//         console.log("Payment status changed to trueFREE");
//         console.log("afterbuysubscrFREE", res.data);
//         // setPaymentDetails(res.data);
//         // modelOpen();
//         navigate("/customeraccount");
//         //  this.props.navigate(this.props.screenName,{state:{offerId:res.data.id}})
//       });
//   };

//   const modelClose = () => {
//     setModel(false);
//     navigate("/customeraccount");
//   };
//   const modelOpen = (id) => {
//     setModel(true);
//   };

//   return (
//     <div>
//       <Suspense fallback={<Spinner />}>
//         <div className="row text-center">
//           {props.data.length > 0 ? (
//             props.data.map((i, j) => (
//               <div className="col-sm-12 col-md-4 col-lg-3 g-1" key={j}>
//                 <div className="card all_border">
//                   <div className="card-body">
//                     <div
//                       className="scroll_bar"
//                       style={{
//                         height: "150px",
//                       }}
//                     >
//                       <h4 className="card-title">
//                         {lang == "en"
//                           ? i.offerName
//                           : lang == "ta"
//                           ? i.offerNameTamil
//                           : i.offerNameHindi}
//                         {/* {i.offerNameTamil} */}
//                       </h4>
//                       <p className="card-title">{i.offerFrequency}</p>
//                       <h4 className="card-text">
//                         ₹{i.offerPrice === 0 ? "Free" : i.offerPrice}₹
//                         <s>{i.totalBasePrice}</s>
//                       </h4>
//                       <h4 className="card-text">
//                        {i.duration}{t("TXT.MIN.LO")}
//                       </h4>
//                       {i.offerEndDate ? (
//                         <p className="card-title mt-1">
//                           {t("USR.HOM.OFR.VA.TIL")}
//                           {ValidDate(new Date(i.offerEndDate))}
//                         </p>
//                       ) : // {i.offerEndDate.replace(
//                       //   /^(\d{4})-(\d{2})-(\d{2}).*/,
//                       //   "$3-$2-$1"
//                       // )}
//                       null}
//                       {i.endDate ? (
//                         <p className="card-title mt-1">
//                           {t("USR.HOM.OFR.VA.TIL")} {i.endDate}
//                         </p>
//                       ) : null}
//                       {i.offerDetails
//                         ? i.offerDetails.map((x, y) => (
//                             <li
//                               className="list-group-item d-flex justify-content-between align-items-center"
//                               key={y}
//                             >
//                               {lang == "en"
//                                 ? x.serviceName
//                                 : lang == "ta"
//                                 ? x.serviceNameTamil
//                                 : x.serviceNameHindi}
//                               {x.serviceCostType == "FREE"
//                                 ? " " + "-" + " " + x.serviceCostType
//                                 : null}
//                               {/* ({x.serviceCostType}) */}
//                               <span
//                                 className="badge bg-warning rounded-pill"
//                                 style={{ color: "black" }}
//                               >
//                                 {x.count} {x.count > 1 ? "times" : "time"}
//                               </span>
//                             </li>
//                           ))
//                         : null}
//                       <li className="list-group-item d-flex justify-content-between align-items-center">
//                         {i.serviceName ? (
//                           <p className="p-0 m-0">
//                             {lang == "en"
//                               ? i.serviceName
//                               : lang == "ta"
//                               ? i.serviceNameTamil
//                               : i.serviceNameHindi}
//                           </p>
//                         ) : null}
//                         <span
//                           className="badge bg-warning rounded-pill"
//                           style={{ color: "black" }}
//                         >
//                           {i.count ? (
//                             <p className="p-0 m-0">{i.count}time</p>
//                           ) : null}
//                         </span>
//                       </li>
//                     </div>
//                     <div>
//                       <Button
//                         className="all_btn"
//                         style={{
//                           marginTop: "5px",
//                         }}
//                         // onClick={() => handleBuy(i.id, i.offerPrice)}
//                         onClick={() => handleOpen(i)}
//                       >
//                         {i.offerPrice === 0
//                           ? t("BTN.USE.NOW.LO")
//                           : t("USR.ACC.PEN.BUY.BTN")}
//                       </Button>
//                     </div>
//                     {/* <Button>view</Button> */}
//                   </div>
//                 </div>
//               </div>
//             ))
//           ) : (
//             <h4>{t("USR.PR.PRTAB.HTXT")}</h4>
//           )}
//         </div>
//       </Suspense>

//       <Modal show={show} onHide={handleClose}>
//         <Modal.Header className="all_background" closeButton>
//           <Modal.Title>{t("USR.HOM.PLN.DTL")}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body>
//           <p>
//             {t("TXT.OFF.NM.LO")} : {details.offerName}
//           </p>
//           <p>
//             {t("USR.HOM.PLN.DTL.OT")} : {details.offerFrequency}
//           </p>
//           <p>
//             {t("USR.HOM.PLN.DTL.OP")} :{" "}
//             {details.offerPrice === 0 ? "Free" : details.offerPrice}
//           </p>
//           <p>
//             {t("USR.HOM.PLN.DTL.OBP")}: <s>{details.totalBasePrice}</s>
//           </p>
//           <p>
//             {details.offerStartDate ? (
//               <p>
//                 {" "}
//                 {t("USR.HOM.PD.OSD")}:{" "}
//                 {formatDate(new Date(details.offerStartDate))}
//               </p>
//             ) : (
//               <></>
//             )}
//           </p>
//           <p>
//             {details.offerEndDate ? (
//               <p>
//                 {" "}
//                 {t("USR.HOM.PD.OED")}:{" "}
//                 {formatDate(new Date(details.offerEndDate))}
//               </p>
//             ) : (
//               <></>
//             )}
//           </p>
//           {offerplan.map((service) => (
//             <p className="d-flex">
//               <div style={{ width: "170px" }}>{t("USR.HOM.SPL.SN")}:&nbsp;</div>
//               <div className="w-100">
//                 <div
//                   style={{ display: "flex", justifyContent: "space-between" }}
//                 >
//                   {lang == "en"
//                     ? service.serviceName
//                     : lang == "ta"
//                     ? service.serviceNameTamil
//                     : service.serviceNameHindi}{" "}
//                   {service.serviceCostType == "FREE"
//                     ? ":" + " " + service.serviceCostType
//                     : null}
//                   {/* (30{t("TXT.MIN.LO")}) */}
//                   (15{t("TXT.MIN.LO")})
//                   <Badge
//                     className="bg-warning"
//                     style={{
//                       color: "black",
//                       display: "flex",
//                       justifyContent: "center",
//                       alignItems: "center",
//                     }}
//                   >
//                     {service.count} {t("USR.OFF.VAD.TIME")}
//                   </Badge>
//                 </div>
//               </div>
//             </p>
//           ))}
//           {details.description === "null" ? null : (
//             <p>
//               {t("TXT.DES.LO")} : {details.description}
//             </p>
//           )}
//           <div
//             style={{
//               display: "flex",
//               justifyContent: "space-around",
//             }}
//           >
//             <Button className="all_btn" onClick={handleClose}>
//               {t("TXT.CLO.LO")}
//             </Button>
//             {/* <Button
//                   active
//                   size="md"
//                   className="all_btn"
//                   onClick={() => {
//                     console.log(
//                       "selected offerid, price",
//                       details.id,
//                       details.offerPrice
//                     );
//                     handleBuy(details.id, details.offerPrice);
//                   }}
//                 >
//                   {t("USR.ACC.PEN.BUY.BTN")}
//                 </Button> */}
//             {details.offerPrice === 0 ? (
//               <Button
//                 size="md"
//                 className="all_btn"
//                 onClick={() => handleFreeOffer(details.id)}
//               >
//                 {t("BTN.USE.NOW.LO")}
//               </Button>
//             ) : (
//               <Button
//                 size="md"
//                 className="all_btn"
//                 onClick={() => handleBuy(details.id, details.offerPrice)}
//               >
//                 {t("USR.ACC.PEN.BUY.BTN")}
//               </Button>
//             )}
//           </div>
//         </Modal.Body>
//       </Modal>

//       <Modal show={model} onHide={modelClose} className="all_border">
//         <Modal.Header closeButton className="all_background">
//           <Modal.Title>{t("USR.HOM.PLN.PDT")}</Modal.Title>
//         </Modal.Header>
//         <Modal.Body className="all_border">
//           <>
//             {paymentDetails ? (
//               <>
//                 <p>
//                   <b>{t("TXT.OFF.NM.LO")}</b> : {details.offerName}
//                 </p>
//                 <p>
//                   <b>{t("USR.HOM.PLN.DTL.OP")}</b> : {details.offerPrice}
//                 </p>
//                 {offerplan.map((service) => (
//                   <div>
//                     <p>
//                       <b>{t("USR.HOM.SPL.SN")}</b>{" "}
//                       <li>
//                         {lang == "en"
//                           ? service.serviceName
//                           : lang == "ta"
//                           ? service.serviceNameTamil
//                           : service.serviceNameHindi}
//                       </li>
//                     </p>
//                   </div>
//                 ))}
//                 <p>
//                   <b>{t("USR.HOM.PLN.PS")} :</b> {paymentDetails.message}
//                 </p>
//                 <p>
//                   <b>{t("USR.HOM.PLN.OF.PD")} :</b> {timeStampFormat}
//                 </p>
//                 <p>
//                   {details.offerStart ? (
//                     <p>
//                       <b>{t("USR.HOM.PD.OSD")}: </b>
//                       {formatDate(new Date(details.offerStart))}
//                     </p>
//                   ) : null}
//                 </p>
//                 <p>
//                   {details.offerEndDate ? (
//                     <p>
//                       <b>{t("USR.HOM.PD.OED")}:</b>{" "}
//                       {formatDate(new Date(details.offerEndDate))}
//                     </p>
//                   ) : null}
//                 </p>
//               </>
//             ) : null}

//             <Button
//               class="btn btn-default"
//               className="all_btn"
//               size="md"
//               style={{
//                 float: "right",
//               }}
//               onClick={() => modelClose()}
//               active
//             >
//               {t("BTN.USE.NOW.LO")}
//             </Button>
//           </>
//         </Modal.Body>
//       </Modal>
//     </div>
//   );
// };

// export default ServiceList;

/**change flow for click to book  **/
import { format } from "date-fns";
import React, { Suspense, useEffect, useState } from "react";
import { Alert, Badge, Button, Modal, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Billdesk from "../component/BillDeskPay";
import { UseAuth } from "../context/Auth";
import { vasthuUrl } from "./Api";

const ServiceList = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = localStorage.getItem("i18nextLng");
  const { userId } = UseAuth();
  const token = window.sessionStorage.getItem("token");
  const [details, setDetails] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [timeStampFormat, setTimeStampFormat] = useState("");
  const [paymentCredentials, setPaymentCredentials] = useState({
    txnToken: "",
    orderId: "",
    amount: 0,
    offerId: 0,
    userId: "",
  });

  // Offer Detail Modal
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };

  const handleOpen = (id) => {
    setShow(true);
    setDetails(id);
  };

  // Payment Response Modal
  const [model, setModel] = useState(false);
  const modelClose = () => {
    setModel(false);
    // navigate("/customeraccount");
  };
  const modelOpen = (id) => {
    setModel(true);
  };

  const formatDate = (date) => {
    try {
      return format(date, "d MMM yyyy, HH:mm a"); // Example format string
    } catch (error) {
      console.error("Error formatting date:", error.message);
      return ""; // or return a default value
    }
  };
  const ValidDate = (date) => {
    try {
      return format(date, "d MMM yyyy"); // Example format string
    } catch (error) {
      console.error("Error formatting date:", error.message);
      return ""; // or return a default value
    }
  };

  const dateStr =
    paymentDetails && paymentDetails.timestamp ? paymentDetails.timestamp : "";
  console.log("dateStr", dateStr);

  useEffect(() => {
    if (dateStr) {
      const [datePart, timePart] = dateStr.split(" "); // Split date and time parts
      const [day, month, year] = datePart.split("-").map(Number);
      const [hours, minutes, seconds] = timePart.split(":").map(Number);
      const dateObj = new Date(year, month - 1, day, hours, minutes, seconds);
      if (!isNaN(dateObj.getTime())) {
        const options = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true, // Use 12-hour clock with AM/PM
        };
        const formattedDate = dateObj.toLocaleString("en-US", options);
        console.log(formattedDate); // Output: "04/18/2024, 12:03:59 PM"
        setTimeStampFormat(formattedDate);
      } else {
        console.error("Invalid date format:", dateStr);
      }
    } else {
      console.error("Date string is undefined or null.");
    }
  }, [dateStr]);

  const handleUse = (i, buy) => {
    const value = window.sessionStorage.getItem("consultantDetail");
    const parsedData = JSON.parse(value);
    console.log("parsedData", parsedData);
    if (parsedData) {
      window.sessionStorage.setItem(
        "checkcon",
        JSON.stringify({
          value: parsedData.value,
          selectedService: i.serviceId,
          selectSubscriptionId: buy ? buy.id : i.userSubscriptionId,
          selectofferId: i.id,
          selectDuration: i.duration,
        })
      );
      navigate("/checkconsultant");
      window.sessionStorage.removeItem("consultantDetail");
    } else {
      navigate("/consultantbooking", {
        state: {
          p: {
            serviceId: i.serviceId,
            subscriptionId: buy ? buy.id : i.userSubscriptionId,
            duration: i.duration,
          },
          q: i.id,
        },
      });
    }
  };

  const BillDeskPayHandler = (txn) => {
    console.log(txn);
    vasthuUrl
      .post(
        "vasthu/user/bdpaymentresponseui",
        { txn: txn.txnResponse.transaction_response },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("txnres", res.data);
        // if (res.data.transaction_error_type == "success") {
        //   navigate("/customerpricing");
        // }

        if (res.data.transaction_error_type === "success") {
          console.log("Saving payment_response ....", txn);

          vasthuUrl
            .post("vasthu/user/bdpaymentresponsehandler", txn, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res1) => console.log("after txnsave", res1.data))
            .catch((err) => console.log("after txnsave try", err));

          // vasthuUrl.post("vasthu/user/bdpaymentresponsehandler", txn,
          //   {
          //     headers: {
          //       Authorization: `Bearer ${token}`,
          //     },
          //   }
          // )
          //   .then(res => console.log("after txnsave", res.data))
          //   .catch(err => console.log("after txnsave try", err))

          console.log("buying .. subsc");
          setTimeout(() => {
            vasthuUrl
              .post(
                "subscription/buyVasthuOffer",
                {
                  orderId: res.data.orderid,
                  userUuid: userId,
                  offerId: parseInt(res.data.additional_info.additional_info2),
                  paymentStatus: true,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((res) => {
                console.log("Payment status changed to true");
                console.log("afterbuysubscr", res.data);
                setPaymentDetails(res.data);
                modelOpen();
                // navigate("/customerpricing");
                //  this.props.navigate(this.props.screenName,{state:{offerId:res.data.id}})
              });
          }, 2000); //5000
        } else {
          vasthuUrl
            .post(
              "subscription/buyVasthuOffer",
              {
                orderId: res.data.orderid,
                userUuid: userId,
                offerId: parseInt(res.data.additional_info.additional_info2),
                paymentStatus: false,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {
              console.log("Payment status changed to false");
            });
        }
      });
  };

  const handleBuy = async (val, price) => {
    try {
      await vasthuUrl
        .post(
          `vasthu/openapi/createorderapi`,
          {
            userUuid: userId,
            offerId: val,
            price: price,
            currency: "356",
            returnUrl: "customerpricing",
            lng: lang,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          handleClose();
          Billdesk(res.data, BillDeskPayHandler)
            .then((config) => {
              console.log("payout", config);
              window.loadBillDeskSdk(config);
            })
            .catch((err) => console.log("payment initialization fails", err));
        });
    } catch (err) {
      console.log(err);
      Alert("Paytm server Error" + err);
    }
  };

  const handleFreeOffer = (id) => {
    vasthuUrl
      .post(
        "subscription/buyVasthuOffer",
        {
          orderId: "FREE",
          userUuid: userId,
          offerId: id,
          paymentStatus: true, // test
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("Payment status changed to trueFREE");
        console.log("afterbuysubscrFREE", res.data);
        // setPaymentDetails(res.data);
        // modelOpen();
        // navigate("/customeraccount");
        //  this.props.navigate(this.props.screenName,{state:{offerId:res.data.id}})
        handleUse(details, res.data);
      });
  };

  return (
    <div>
      <Suspense fallback={<Spinner />}>
        <div className="row text-center">
          {props.data.length > 0 ? (
            props.data.map((i, j) => (
              <div className="col-sm-12 col-md-4 col-lg-3 g-1" key={j}>
                <div
                  className="card"
                  style={{
                    border:
                      i.userSubscriptionId > 0
                        ? "2px solid #0af14b"
                        : "#ffb33e",
                  }}
                >
                  <div className="card-body">
                    <div
                      className="scroll_bar"
                      style={{
                        height: "150px",
                      }}
                    >
                      {i.userSubscriptionId > 0 ? (
                        <h4 className="card-title">Your Exsisting Offer !!!</h4>
                      ) : null}

                      <h4 className="card-title">
                        {lang == "en"
                          ? i.offerName
                          : lang == "ta"
                          ? i.offerNameTamil
                          : i.offerNameHindi}
                      </h4>
                      <h4 className="card-text">
                        ₹{i.offerPrice === 0 ? "Free" : i.offerPrice}₹
                        <s>{i.basePrice}</s>
                      </h4>
                      <h4 className="card-text">
                        {i.duration}
                        {t("TXT.MIN.LO")}
                      </h4>
                      {i.offerEndDate ? (
                        <p className="card-title mt-1">
                          {t("USR.HOM.OFR.VA.TIL")}
                          {ValidDate(new Date(i.offerEndDate))}
                        </p>
                      ) : // {i.offerEndDate.replace(
                      //   /^(\d{4})-(\d{2})-(\d{2}).*/,
                      //   "$3-$2-$1"
                      // )}
                      null}
                      {i.endDate ? (
                        <p className="card-title mt-1">
                          {t("USR.HOM.OFR.VA.TIL")} {i.endDate}
                        </p>
                      ) : null}
                      {i.offerDetails
                        ? i.offerDetails.map((x, y) => (
                            <li
                              className="list-group-item d-flex justify-content-between align-items-center"
                              key={y}
                            >
                              {lang == "en"
                                ? x.serviceName
                                : lang == "ta"
                                ? x.serviceNameTamil
                                : x.serviceNameHindi}
                              {x.serviceCostType == "FREE"
                                ? " " + "-" + " " + x.serviceCostType
                                : null}
                              {/* ({x.serviceCostType}) */}
                              <span
                                className="badge bg-warning rounded-pill"
                                style={{ color: "black" }}
                              >
                                {x.count} {x.count > 1 ? "times" : "time"}
                              </span>
                            </li>
                          ))
                        : null}
                      <li className="list-group-item d-flex justify-content-between align-items-center">
                        {i.serviceName ? (
                          <p className="p-0 m-0">
                            {lang == "en"
                              ? i.serviceName
                              : lang == "ta"
                              ? i.serviceNameTamil
                              : i.serviceNameHindi}
                          </p>
                        ) : null}
                        <span
                          className="badge bg-warning rounded-pill"
                          style={{ color: "black" }}
                        >
                          {i.count ? (
                            <p className="p-0 m-0">{i.count}time</p>
                          ) : null}
                        </span>
                      </li>
                    </div>
                    <div>
                      {i.userSubscriptionId > 0 ? (
                        <Button
                          className="all_btn"
                          style={{
                            marginTop: "5px",
                          }}
                          onClick={() => handleUse(i)}
                        >
                          {t("BTN.USE.NOW.LO")}
                        </Button>
                      ) : (
                        <Button
                          className="all_btn"
                          style={{
                            marginTop: "5px",
                          }}
                          onClick={() => handleOpen(i)}
                        >
                          {i.offerPrice === 0
                            ? t("BTN.USE.NOW.LO")
                            : t("USR.ACC.PEN.BUY.BTN")}
                        </Button>
                      )}
                    </div>
                    {/* <Button>view</Button> */}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <h4>{t("USR.PR.PRTAB.HTXT")}</h4>
          )}
        </div>
      </Suspense>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="all_background" closeButton>
          <Modal.Title>{t("USR.HOM.PLN.DTL")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            {t("TXT.OFF.NM.LO")} : {details.offerName}
          </p>
          <p>
            {t("USR.HOM.PLN.DTL.OT")} : {details.offerFrequency}
          </p>
          <p>
            {t("USR.HOM.PLN.DTL.OP")} :{" "}
            {details.offerPrice === 0 ? "Free" : details.offerPrice}
          </p>
          <p>
            {t("USR.HOM.PLN.DTL.OBP")}: <s>{details.basePrice}</s>
          </p>
          <p>
            {details.startDate ? (
              <p>
                {" "}
                {t("USR.HOM.PD.OSD")}: {formatDate(new Date(details.startDate))}
              </p>
            ) : (
              <></>
            )}
          </p>
          <p>
            {details.endDate ? (
              <p>
                {" "}
                {t("USR.HOM.PD.OED")}: {formatDate(new Date(details.endDate))}
              </p>
            ) : (
              <></>
            )}
          </p>
          <p className="d-flex">
            <div style={{ width: "170px" }}>{t("USR.HOM.SPL.SN")}:&nbsp;</div>
            <div className="w-100">
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                {lang == "en"
                  ? details.serviceName
                  : lang == "ta"
                  ? details.serviceNameTamil
                  : details.serviceNameHindi}{" "}
                {details.serviceCostType == "FREE"
                  ? ":" + " " + details.serviceCostType
                  : null}
                ({details.duration} {t("TXT.MIN.LO")})
                <Badge
                  className="bg-warning"
                  style={{
                    color: "black",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {details.count} {t("USR.OFF.VAD.TIME")}
                </Badge>
              </div>
            </div>
          </p>
          {details.description === "null" ? null : (
            <p>
              {t("TXT.DES.LO")} : {details.description}
            </p>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <Button className="all_btn" onClick={handleClose}>
              {t("TXT.CLO.LO")}
            </Button>
            {details.offerPrice === 0 ? (
              <Button
                size="md"
                className="all_btn"
                onClick={() => handleFreeOffer(details.id)}
              >
                {t("BTN.USE.NOW.LO")}
              </Button>
            ) : (
              <Button
                size="md"
                className="all_btn"
                onClick={() => handleBuy(details.id, details.offerPrice)}
              >
                {t("USR.ACC.PEN.BUY.BTN")}
              </Button>
            )}
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={model} onHide={modelClose} className="all_border">
        <Modal.Header closeButton className="all_background">
          <Modal.Title>{t("USR.HOM.PLN.PDT")}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="all_border">
          <>
            {paymentDetails ? (
              <>
                <p>
                  <b>{t("TXT.OFF.NM.LO")}</b> : {details.offerName}
                </p>
                <p>
                  <b>{t("USR.HOM.PLN.DTL.OP")}</b> : {details.offerPrice}
                </p>
                <div>
                  <p>
                    <b>{t("USR.HOM.SPL.SN")}</b>{" "}
                    <li>
                      {lang == "en"
                        ? details.serviceName
                        : lang == "ta"
                        ? details.serviceNameTamil
                        : details.serviceNameHindi}
                    </li>
                  </p>
                </div>
                <p>
                  <b>{t("USR.HOM.PLN.PS")} :</b> {paymentDetails.message}
                </p>
                <p>
                  <b>{t("USR.HOM.PLN.OF.PD")} :</b> {timeStampFormat}
                </p>
                <p>
                  {details.startDate ? (
                    <p>
                      <b>{t("USR.HOM.PD.OSD")}: </b>
                      {formatDate(new Date(details.startDate))}
                    </p>
                  ) : null}
                </p>
                <p>
                  {details.endDate ? (
                    <p>
                      <b>{t("USR.HOM.PD.OED")}:</b>{" "}
                      {formatDate(new Date(details.endDate))}
                    </p>
                  ) : null}
                </p>
              </>
            ) : null}

            <Button
              class="btn btn-default"
              className="all_btn"
              size="md"
              style={{
                float: "right",
              }}
              onClick={() => {
                modelClose();
                handleUse(details, paymentDetails);
              }}
              active
            >
              {t("BTN.USE.NOW.LO")}
            </Button>
          </>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ServiceList;
