import React from "react";
import {
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import theme from "./theme";
import crmServices from "../../pages/crm/data/crmServices";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const DashItems = () => {
  const Navigate = useNavigate();
  return (
    <Container sx={{ py: 8 }} maxWidth="md">
      <Grid className="all_border" container spacing={4}>
        {crmServices.map((card) => (
          <Grid className="all_border" item key={card.id} xs={12} sm={6} md={4}>
            <Card
              className="all_border product_card"
              sx={{ height: "100%", display: "flex", flexDirection: "column" }}
              onClick={() => Navigate(card.href)}
            >
              <CardMedia
                component="div"
                sx={{
                  // 16:9
                  pt: "56.25%",
                }}
                image={card.image}
              />
              <CardContent sx={{ flexGrow: 1, bgcolor: "#ffc33b" }}>
                <Typography
                  gutterBottom
                  variant="h5"
                  component="h2"
                  color={"black"}
                >
                  <center>
                    <u>{card.heading}</u>
                  </center>
                </Typography>
                <Typography color={"black"}>{card.description}</Typography>
              </CardContent>
              {card.actions.length > 0 ? (
                <CardActions
                  style={{ border: "1px solid #ffc33b" }}
                  className="d-flex justify-content-evenly all_border gap-1"
                >
                  {card.actions.map((action) => (
                    <Button
                      className="all_btn"
                      size="sm"
                      href={action.href}
                      // sx={{ my: 1, mx: 1.5 }}
                    >
                      {action.name}
                    </Button>
                  ))}
                </CardActions>
              ) : null}
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
export default DashItems;
