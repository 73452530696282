import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Container,
  Form,
  Button,
  Modal,
  Image,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { vasthuUrl } from "../component/Api";
import { UseAuth } from "../context/Auth";
import { FcAddImage } from "react-icons/fc";

const ProfessionalProfileForm = () => {
  const { t } = useTranslation();
  const { onLogout } = UseAuth();
  const userId = window.sessionStorage.getItem("userId");
  const token = window.sessionStorage.getItem("token");
  const [phoneNumber, setphoneNumber] = useState("9876543210");
  const [email, setEmail] = useState("abc@xyz.com");
  const [userName, setUserName] = useState("userName");
  const [languageKnown, setLanguageKnown] = useState("languages");
  const [skills, setSkills] = useState("skills");
  const [role, setRole] = useState();
  const [isImage, setIsImage] = useState(false);
  const [imgUrl, setImgUrl] = useState();
  const [uploadedImage, setUploadedImage] = useState();

  const uploadedImg = React.useRef(null);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(() => {
    try {
      vasthuUrl
        .get(`getuserprofiledetail/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("user profile details", res.data);
          setUserName(res.data.name);
          setphoneNumber(res.data.phoneNumber);
          setEmail(res.data.email);
          setLanguageKnown(res.data.languagesKnown);
          setIsImage(res.data.imageUploaded);
          setImgUrl(res.data.profileUrl);
          setSkills(res.data.skills);
        });
    } catch (error) {
      console.log("ERROE" + error);
    }
  }, [show]);

  const handleImageUpload = async (e) => {
    // const reader = new FileReader();
    // const { current } = uploadedImg;
    // current.file = file;
    // reader.onload = e => {
    //   current.src = e.target.result;
    // };
    // reader.readAsDataURL(file);

    const res = await vasthuUrl.post(
      `image/uploadImage`,

      { file: uploadedImage, userId: userId },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          // contentType: "application/json",
        },
      }
    );

    console.log("response for Image Upload Api", res.data);
    // setImgUrl()
    setShow(false);
  };

  return (
    <Container>
      <Row className="mt-3" style={{ backgroundColor: "#eee" }}>
        <div className="col-lg-4 mt-3">
          <div className="card all_border mb-4">
            <div className="card-body text-center">
              <Row className="justify-content-center">
                {isImage ? (
                  <Image
                    fluid
                    thumbnail
                    roundedCircle
                    src={imgUrl}
                    alt="avatar"
                    className="rounded-circle img-fluid"
                    style={{ width: 100, height: 100, objectFit: "cover" }}
                  />
                ) : (
                  <Image
                    fluid
                    thumbnail
                    roundedCircle
                    style={{ width: 100, height: 100, objectFit: "cover" }}
                    src={require("../images/noimage.jpg")}
                  />
                )}
                <div className="d-flex justify-content-center m-2">
                  <button
                    type="button"
                    className="btn all_btn ms-1"
                    style={{
                      borderRadius: 50,
                    }}
                    onClick={handleShow}
                  >
                    {isImage ? t("CM.SU.IN1.CHIMG") : t("CM.SU.IN1.ADIMG")}
                  </button>
                </div>
                {/* <Button className='m-3' onClick={handleShow} variant='outline-warning'   style={{
                    borderRadius: 50,
                    color: "black",
                    fontWeight: "bold",
                  }}>Change Profile</Button> */}
                {/* <FcAddImage
                  size={32}
                /> */}
              </Row>

              <h5 className="my-3">{userName}</h5>

              <div className="d-flex justify-content-center mb-2">
                <button
                  type="button"
                  className="btn all_btn ms-1"
                  style={{
                    borderRadius: 50,
                  }}
                  onClick={onLogout}
                >
                  {t("PR.AC.PFLTAB.BTN.SOUT")}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-8 mt-3">
          <div className="card all_border mb-4">
            <div className="card-body">
              <div className="row">
                <div className="col-sm-3">
                  <p style={{ whiteSpace: 'nowrap' }} className="mb-0">{t("CM.SU.IN1.NM")}</p>
                </div>
                <div className="col-sm-9">
                  <p className="text-muted mb-0">{userName}</p>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-3">
                  <p className="mb-0">{t("CM.SU.IN3.NM")}</p>
                </div>
                <div className="col-sm-9">
                  <p className="text-muted mb-0">{email}</p>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-3">
                  <p className="mb-0">{t("CM.SI.IN1.NM")}</p>
                </div>
                <div className="col-sm-9">
                  <p className="text-muted mb-0">{phoneNumber}</p>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-3">
                  <p className="mb-0">{t("CM.CNSL.ON.SER2")}</p>
                </div>
                <div className="col-sm-9">
                  <p className="text-muted mb-0">{languageKnown}</p>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-sm-3">
                  <p className="mb-0">{t("CM.CNSL.ON.SER1")}</p>
                </div>
                <div className="col-sm-9">
                  <p className="text-muted mb-0">{skills}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="all_background" closeButton>
          <Modal.Title>{t("USR.ACC.PRO.IMG.TL")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>{t("USR.ACC.PRO.IMG.UPLD")}</Form.Label>
            <Form.Control
              type="file"
              onChange={(e) => setUploadedImage(e.target.files[0])}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-evenly">
          <Button className="all_btn" onClick={handleClose}>
            {t("TXT.CLO.UP")}
          </Button>
          <Button className="all_btn" onClick={handleImageUpload}>
            {t("USR.HOM.BTN.SAV")}
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default ProfessionalProfileForm;
