import React, { Suspense, useEffect, useState } from "react";
import { Card, Button, Container, Spinner, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { vasthuUrl } from "../component/Api";
import ServiceList from "../component/serviceList";

const CustomerPeriodicPricing = () => {
  const token = window.sessionStorage.getItem("token");
  const {t} = useTranslation();
  const [periodicCombo, setPeriodicCombo] = useState([]);
  const [periodicIndividual, setPeriodicIndividual] = useState([]);
  console.log('periodicIndividual', periodicIndividual)
  console.log('periodicCombo', periodicCombo)
  useEffect(() => {
    try {
      vasthuUrl
        .get("offer/getcomboperiodicoffer", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setPeriodicCombo(res.data);
          console.log(res.data);
        });
    } catch (e) {
      console.log("SERVER ERROR");
      setPeriodicCombo([]);
    }
    try {
      vasthuUrl
        .get("offer/getindividualperiodicoffer", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setPeriodicIndividual(res.data);
          console.log(res.data);
        });
    } catch (e) {
      console.log("SERVER ERROR");
      setPeriodicIndividual([]);
    }
  }, []);
  return (
    <Suspense fallback={<Spinner />}>
      <Container
        fluid
        style={{
          padding: 20,
          background: "linear-gradient(90deg, #ffbb33 0%, #ffbb33 100%)",
        }}
      >
        <div className=" my-1 g-1">
          <center>
            <h2>{t("USR.PR.PRTAB.TL1")}</h2>
          </center>
          <Suspense fallback={<Spinner />}>
            <ServiceList data={periodicIndividual} />
          </Suspense>
        </div>
        <div className="mt-2 g-1">
          <center>
            <h2>{t("USR.PR.PRTAB.TL2")}</h2>
          </center>
          <Suspense fallback={<Spinner />}>
            <ServiceList data={periodicCombo} />
          </Suspense>
        </div>
      </Container>
    </Suspense>
  );
};
export default CustomerPeriodicPricing;
