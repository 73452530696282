import React from 'react'
import OurCallServices from './OurCallServices'

function WalletBooking() {
    return (
        <div>
            <OurCallServices details={{ selectedHead:"WALLET"}}/>
        </div>
    )
}
export default WalletBooking