import React, { useEffect, useState } from "react";
import { Table, Spinner, Button } from "react-bootstrap";
import PropsType from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const PlotTable = (props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setLoading(true);
    }, 1000);
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div>
      {!loading ? (
        <div className="text-center mt-3 mb-3 d-flex align-item-center justify-content-center">
          <Spinner animation="border" variant="warning" />{" "}
        </div>
      ) : (
        <>
          <div className="deskTable">
            <Table striped bordered hover responsive size="lg">
              <thead>
                <tr>
                  <th>{t("USR.VFPR.TBL.CLMN.OWN")}</th>
                  <th>{t("USR.VFP.DRP.LBL.LG")}</th>
                  <th>{t("USR.VFPR.TBL.CLMN.AR")}</th>
                  <th>{t("USR.VFPR.TBL.CLMN.FC")}</th>
                  <th>{t("USR.VFPR.TBL.CLMN.RS")}</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{props.data["ownerName"]}</td>
                  <td>{props.data["lagnaName"]}</td>
                  <td>{props.data["plotArea"]}</td>
                  <td>{props.data["directionName"]}</td>
                  <td>{props.data["plotResultValue"]}</td>
                </tr>
              </tbody>
            </Table>
            <div>
              <center>
                <Button
                  className="all_btn"
                  style={{ borderRadius: 50 }}
                  onClick={() => navigate("/")}
                >
                 {t("BTN.BACK.TO.HOME.LO")}
                </Button>
              </center>
            </div>
          </div>
        </>
      )
      }

    </div>

  );
}

PlotTable.propsType = {
  data: PropsType.object.isRequired,
};
export default PlotTable;
