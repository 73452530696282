import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";

import ProfessionalProfileForm from "./ProfessionalProfileForm";
import ProfPayment from "./ProfPayment";
import ProfReview from "./ProfReview";
import { useTranslation, Trans } from "react-i18next";

const ProfProfile = () => {
  const { t } = useTranslation();
  const [key, setKey] = useState("profile");
  return (
    // <Tabs
    //   id="controlled-tab-example"
    //   activeKey={key}
    //   onSelect={(k) => setKey(k)}
    //   className="justify-content-center"
    // >
    // <Tab eventKey="profile" title={t("PR.AC.PFLTAB")}>
    <center>
      <h4 className="m-1">
        <u>{t("PR.AC.PFLTAB.TL")}</u>
      </h4>
      <ProfessionalProfileForm />
    </center>
    /* </Tab> */
    /* <Tab eventKey="paymentdetails" title={t("PR.AC.PMTTAB")}>
        <center>
          <h4 className="m-1">
            <u>{t("PR.AC.PMTTAB.TL")}</u>
          </h4>
        </center>
        <ProfPayment />
      </Tab> */
    /* <Tab eventKey="review" title={t("PR.AC.RVWTAB")}>
        {/* <center>
          <h4 className="m-1">
            <u>REVIEW</u>
          </h4>
        </center> */
    /* <ProfReview /> */
    /* </Tab> */
    // </Tabs>
  );
};

export default ProfProfile;
