import React, { useEffect, useState } from "react";
import { vasthuUrl } from "../component/Api";

import { useLocation, useNavigate } from "react-router-dom";

import { format, isToday } from "date-fns";
import { Alert, Button, Modal, Spinner, Table } from "react-bootstrap";
import Toast from "react-bootstrap/Modal";
import NewBootCard from "../component/NewBootCard";

import { useTranslation } from "react-i18next";
import FeedBack from "../component/FeedBack";
const AudioCall = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [audiocallHistoryList, setAudiocallHistoryList] = useState([
    // {
    //   callFrom: "",
    //   callTo: "",
    //   duration: "",
    //   offerId: "",
    //   offerName:"",
    //   status: "",
    //   proposedCallDuration:""
    // }
  ]);
  const token = window.sessionStorage.getItem("token");
  const uuid = window.sessionStorage.getItem("userId");
  const consultantId = window.sessionStorage.getItem("consultantId");
  // const duration = window.sessionStorage.getItem("AudioCallDuration");
  const location = useLocation();
  const [callStatus, setCallStatus] = useState("Audiocall List");
  const [profDetails, setProfDetails] = useState();

  const originalDate = new Date();
  const formattedDate = format(originalDate, "MMM d, yyyy HH:mm");

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [callSummary, setCallSummary] = useState(false);
  const handleCloseSummary = () => setCallSummary(false);
  const handleShowSummary = () => setCallSummary(true);

  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const handleShow1 = () => setShow1(true);

  const [callStatusPro, setCallStatusPro] = useState();
  const [waitingTime, setWaitingTime] = useState();
  console.log("waitingTime", waitingTime);
  const [consulName, setConsulName] = useState();
  const [duration, setDuration] = useState();
  const [subscription, setSubscription] = useState();
  const [detail, setDetail] = useState();
  console.log("callStatusPro", callStatusPro);
  console.log("detail", detail);
  console.log("duration", duration);
  console.log("subscription", subscription);
  const [toast, setToast] = useState(false);
  const [message, setMessage] = useState("");

  const [callHistoryList, setCallHistoryList] = useState();
  const [openModal, setOpenModal] = useState(false);
  console.log("callHistoryList", callHistoryList);

  const closeTosastBtn = () => {
    setToast(false);
  };

  const alert = (msg) => {
    setMessage(msg);
    setTimeout(() => {
      setMessage("");
    }, 4000);
  };

  const closeModal = () => {
    setOpenModal(false);
    setProfDetails(null); // Reset modal details when closed
  };

  useEffect(() => {
    setLoading(true);
    console.log("screen: Audiocall.js");
    vasthuUrl
      .get(`vasthu/user/getAudioCallUserHistory/${uuid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((rs) => {
        console.log("achistory", rs.data);
        setAudiocallHistoryList(rs.data);
        setLoading(false);
      })
      .catch((er) => {
        console.log("call history err", er);
        setLoading(false);
      });
  }, []);

  const handleCallSummary = (id) => {
    setLoading(true);
    console.log("audiocallSummary", id);
    vasthuUrl
      .get(`vasthu/getaudiocallsummarylist/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((rs) => {
        console.log("audiocallSummary", rs.data);
        setCallHistoryList(rs.data);
        setLoading(false);
        setCallSummary(true);
      })
      .catch((er) => {
        console.log("audiocallSummary err", er);
        setLoading(false);
      });
  };

  const GetConsultantCallStatus = (id) => {
    setDetail(id);
    setDuration(id.proposedCallDuration);
    setSubscription(id.subscriptionId);
    setConsulName(id.callTo);
    // setLoading(true);
    try {
      vasthuUrl
        .post(`vasthu/user/getprostatusavalaibility`, {
          pro_uuid: id.call_to_id,
          user_uuid: id.call_from_id,
        })
        .then((res) => {
          setCallStatusPro(res.data);
          console.log("res.data", res.data);
          // setLoading(false);
          if (res.data.proCurrentCallStatus == "in-progress") {
            setToast(true);
            // alert(`Consultant Already on a call please wait ${(Math.round(res.data.waitingTime / 60))} mins!!!`);
            // setWaitingTime(Math.round(res.data.waitingTime / 60));
            setWaitingTime(Math.round(res.data.waitingTime));
            console.log(res.data);
          } else {
            openModelCall(id);
          }
        });
    } catch (err) {
      console.log("SERVER ERROR", err);
    }
  };
  const openModelCall = (id) => {
    const res = vasthuUrl
      .post(
        `vasthu/user/clicktocall`,
        {
          from: id.call_from_id, //"09790580304",
          to: id.call_to_id, //"07092758264",
          duration: id.proposedCallDuration - id.duration, //30min
          subscriptionId: id.subscriptionId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("postresponse", res);
        if (res.status == "200") {
          setCallStatus(t("USR.CAL.PROCED.BTN"));
          navigate("/audiocallstatus", {
            state: { callStatus: "USR.CAL.PROCED.BTN" },
          });

          // setTimeout(() => {
          //   navigate("/audiocall", { replace: true });
          // }, 30000);
        } else {
          setCallStatus(t("USR.CAL.FAILD.BTN"));
          console.log(t("USR.CAL.FAILD.BTN"));
        }
      })
      .catch((err) => {
        setCallStatus(t("USR.CAL.FAILD.BTN"));

        navigate("/audiocallstatus", {
          state: { callStatus: "USR.CAL.FAILD.BTN" },
        });
      });
  };

  const showModal = (i) => {
    setOpenModal(true);
  };

  return (
    // <div>
    // {/* <div>{callStatus}</div> */}
    // {/* <div>{audiocallHistoryList}</div> */}
    <React.Fragment>
      <div>
        <center>
          <h2>{t("USR.AUDCH.TEXT1")}</h2>
        </center>
      </div>
      {message && (
        <Alert variant="info" className="text-center">
          {message}
        </Alert>
      )}
      {!loading ? (
        audiocallHistoryList.length > 0 ? (
          <>
            <Table
              striped
              bordered
              hover
              responsive
              className="deskTable all_border text-center"
            >
              <thead>
                <tr>
                  <th>{t("TXT.NM.LO")}</th>
                  <th>{t("TXT.CON.LO")}</th>
                  <th>{t("TXT.CAL.DAT.LO")}</th>
                  <th>
                    {t("TXT.DUR.SPOK.LO")}
                    {t("TXT.MIN().LO")}
                  </th>
                  <th>
                    {t("TXT.TOT.CAL.DUR.LO")}
                    {t("TXT.MIN().LO")}
                  </th>
                  <th>{t("TXT.OFF.NM.LO")}</th>
                  <th>{t("TXT.CAL.STS.LO")}</th>
                  <th>{t("TXT.DETS.LO")}</th>
                  <th>{t("TXT.CAL.LO")}</th>
                  <th>{t("USR.FD.BCK.BTN")}</th>
                </tr>
              </thead>
              <tbody>
                {audiocallHistoryList.reverse().map((i, j) => (
                  <>
                    <tr key={j}>
                      <td>{i.callTo}</td>
                      <td>{i.callFrom}</td>
                      <td>
                        {format(new Date(i.start_time), "d MMM yyyy, HH:mm")}
                        {/* {i.start_time} */}
                      </td>
                      <td>{Math.round(i.duration / 60)}</td>
                      <td>{Math.round(i.proposedCallDuration / 60)}</td>
                      <td>{i.offerName ? i.offerName : "Wallet Call"}</td>

                      <td>{i.status}</td>
                      {/* <td><Button disabled={!(parseInt(i.proposedCallDuration)>parseInt(i.duration))|| i.status == 'completed'} 
              onClick={() => clicktocall(i.call_from_id,i.call_to_id,i.subscriptionId,i.proposedCallDuration)
                }>proceed</Button></td> */}
                      {i.offerName == null ? (
                        "-"
                      ) : (
                        <td>
                          <Button
                            className="all_btn"
                            onClick={() => handleCallSummary(i.id)}
                          >
                            {t("TXT.VIEW.LO")}{" "}
                          </Button>
                        </td>
                      )}

                      <td>
                        {i.status === "completed" ||
                        i.status === "no-answer" ||
                        i.subscriptionId == null ? (
                          "-"
                        ) : (
                          <Button
                            className="all_btn"
                            disabled={
                              !isToday(new Date(i.start_time)) || // Disable if not today's date
                              !(
                                parseInt(i.proposedCallDuration) >
                                parseInt(i.duration)
                              ) ||
                              i.status === "cancelled" ||
                              i.status === "in-progress" ||
                              i.status === "onhold"
                            }
                            onClick={async () => {
                              GetConsultantCallStatus(i);
                              window.sessionStorage.setItem(
                                "consultantId",
                                i.call_to_id
                              );
                            }}
                          >
                            {t("TXT.JO.AGN.LO")}
                          </Button>
                        )}
                      </td>
                      {i.status === "completed" && i.offerName === null ? (
                        <td>
                          <Button
                            variant="outline-warning"
                            onClick={() => {
                              console.log("II", i);
                              showModal();
                              setProfDetails(i);
                            }}
                          >
                            {t("USR.HOM.REV.TEXT4")}
                          </Button>
                        </td>
                      ) : (
                        "-"
                      )}
                    </tr>

                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header className="all_background" closeButton>
                        <Modal.Title>{t("TXT.CAL.PRO.ALRT.LO")}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body class="h5 mt-3 mb-3 text-center">
                        {t("TXT.REA.WAT.CONTU.LO")}
                      </Modal.Body>
                      <Modal.Footer className="d-flex justify-content-evenly">
                        <Button onClick={handleClose} className="all_btn">
                          {t("TXT.CLO.LO")}
                        </Button>
                        <Button
                          className="all_btn"
                          // onClick={() => {
                          //   handleClose();
                          //   clicktocall(
                          //     i.call_from_id,
                          //     i.call_to_id,
                          //     i.subscriptionId,
                          //     i.proposedCallDuration
                          //   );
                          // }}
                          onClick={async () => {
                            GetConsultantCallStatus(i);
                            window.sessionStorage.setItem(
                              "consultantId",
                              i.call_to_id
                            );
                          }}
                        >
                          {t("TXT.JO.AGN.LO")}
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </>
                ))}
              </tbody>
            </Table>

            <Modal
              size="lg"
              show={callSummary}
              // fullscreen={true}
              onHide={handleCloseSummary}
            >
              <Modal.Header className="all_background" closeButton>
                <Modal.Title>{t("TXT.AUD.CAL.SUM.REPO.LO")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Table
                  striped
                  bordered
                  hover
                  responsive
                  className=" all_border text-center"
                >
                  <thead>
                    <tr>
                      <th>{t("TXT.DUR.SPOK.LO")}</th>
                      <th>{t("TXT.STA.TIM.LO")}</th>
                      <th>{t("TXT.END.TIM.LO")}</th>
                      <th>{t("TXT.STS.LO")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {callHistoryList &&
                      callHistoryList.map((i, j) => (
                        <tr key={j}>
                          <td>{i.durationSpoke}&nbsp;Sec</td>
                          <td>
                            {format(
                              new Date(i.startTime),
                              "d MMM yyyy, (HH:mm)"
                            )}
                          </td>
                          <td>
                            {i.endTime == null
                              ? "-"
                              : format(
                                  new Date(i.endTime),
                                  "d MMM yyyy, (HH:mm)"
                                )}
                          </td>
                          <td>{i.status}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </Modal.Body>
              <Modal.Footer className="all_border d-flex justify-content-evenly">
                <Button onClick={handleCloseSummary} className="all_btn">
                  {t("TXT.CLO.LO")}
                </Button>
              </Modal.Footer>
            </Modal>

            <div className=" mobileCard justify-content-center mx-1">
              {audiocallHistoryList.map((i, j) => (
                <NewBootCard key={j}>
                  <h4 className="text-center">
                    {t("TXT.NM.LO")}: {i.callTo}
                  </h4>
                  <h6>
                    {t("TXT.CON.LO")}: {i.callFrom}
                  </h6>
                  <h6>
                    {t("TXT.CAL.DAT.LO")}:{" "}
                    {format(new Date(i.start_time), "d MMM yyyy, HH:mm")}
                  </h6>
                  <h6>
                    {t("TXT.DUR.SPOK.LO")}
                    {t("TXT.MIN().LO")}:{Math.round(i.duration / 60)}
                  </h6>
                  <h6>
                    {t("TXT.TOT.CAL.DUR.LO")}
                    {t("TXT.MIN().LO")}:
                    {Math.round(i.proposedCallDuration / 60)}
                  </h6>
                  <h6>
                    {i.offerName
                      ? `${t("TXT.OFF.NM.LO")}: ${i.offerName}`
                      : `${t("TXT.OFF.NM.LO")}: Wallet Call`}
                  </h6>
                  <h6>
                    {t("TXT.CAL.STS.LO")}: {i.status}
                  </h6>
                  {/* <Button disabled={!(parseInt(i.proposedCallDuration)>parseInt(i.duration))} onClick={() => clicktocall(i.call_from_id,i.call_to_id,i.subscriptionId,i.proposedCallDuration)}>proceed</Button> */}
                  {i.offerName == null ? null : (
                    <td className="d-grid mb-2">
                      <Button
                        size="sm"
                        className="all_btn"
                        onClick={() => handleCallSummary(i.id)}
                      >
                        {t("TXT.VIEW.LO")}
                      </Button>
                    </td>
                  )}
                  <div class="d-grid gap-2 d-md-block">
                    {i.status === "completed" ||
                    i.status === "no-answer" ||
                    i.subscriptionId == null ? null : (
                      <Button
                        className="all_btn"
                        disabled={
                          !isToday(new Date(i.start_time)) || // Disable if not today's date
                          !(
                            parseInt(i.proposedCallDuration) >
                            parseInt(i.duration)
                          ) ||
                          i.status === "cancelled" ||
                          i.status === "in-progress" ||
                          i.status === "onhold"
                        }
                        onClick={async () => {
                          GetConsultantCallStatus(i);
                          window.sessionStorage.setItem(
                            "consultantId",
                            i.call_to_id
                          );
                        }}
                      >
                        {t("TXT.JO.AGN.LO")}
                      </Button>
                    )}
                    {i.status === "completed" && i.offerName === null ? (
                      <td>
                        <Button
                          variant="outline-warning"
                          onClick={() => {
                            console.log("II", i);
                            showModal();
                            setProfDetails(i);
                          }}
                        >
                          {t("USR.HOM.REV.TEXT4")}
                        </Button>
                      </td>
                    ) : null}
                  </div>

                  <Modal show={show1} onHide={handleClose1}>
                    <Modal.Header className="all_background" closeButton>
                      <Modal.Title>{t("TXT.CAL.PRO.ALRT.LO")}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>{t("TXT.REA.WAT.CONTU.LO")}</Modal.Body>
                    <Modal.Footer className="d-flex justify-content-evenly">
                      <Button className="all_btn" onClick={handleClose1}>
                        {t("TXT.CLO.LO")}
                      </Button>
                      {i.status === "completed" &&
                      i.offerName === null ? null : (
                        <Button
                          className="all_btn"
                          // onClick={() => {
                          //   handleClose1();
                          //   clicktocall(
                          //     i.call_from_id,
                          //     i.call_to_id,
                          //     i.subscriptionId,
                          //     i.proposedCallDuration
                          //   );
                          // }}
                          onClick={async () => {
                            GetConsultantCallStatus(i);
                            window.sessionStorage.setItem(
                              "consultantId",
                              i.call_to_id
                            );
                          }}
                        >
                          {t("TXT.JO.AGN.LO")}
                        </Button>
                      )}

                      {i.status === "completed" && i.offerName === null ? (
                        <td>
                          <Button
                            variant="outline-warning"
                            onClick={() => {
                              console.log("II", i);
                              showModal();
                              setProfDetails(i);
                            }}
                          >
                            {t("USR.HOM.REV.TEXT4")}
                          </Button>
                        </td>
                      ) : null}
                    </Modal.Footer>
                  </Modal>
                </NewBootCard>
              ))}
            </div>
          </>
        ) : (
          <div>
            <center>
              <h5>{t("TXT.NO.REC.FUD.LO")}</h5>
            </center>
          </div>
        )
      ) : (
        <div className="text-center">
          <Spinner animation="border" variant="warning" />
        </div>
      )}

      {openModal ? (
        <FeedBack details={profDetails} params={true} onClose={closeModal} />
      ) : null}

      <Toast onHide={() => setToast(false)} show={toast} className="all_border">
        <Toast.Header closeButton className="all_background">
          {t("TXT.CON.BSY.LO")}!!!
        </Toast.Header>
        <Toast.Body>
          {t("TXT.HELO.LO")}! <b>{consulName}</b> {t("TXT.CON.BSY.ANT.PLS.LO")}
          {waitingTime} {t("TXT.MIN.LO")}
        </Toast.Body>
        <Toast.Footer>
          <Button className="all_btn" onClick={closeTosastBtn}>
            {t("TXT.CLO.LO")}
          </Button>
        </Toast.Footer>
      </Toast>
    </React.Fragment>
    // </div>
  );
};

export default AudioCall;
