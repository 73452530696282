import React from "react";
import Title from "../component/Title";
import { Container, Row } from "react-bootstrap";

const TermConditions = () => {
  return (
  <Container>
  <Title title="Term & Conditions" />
  <Row
  className="m-1 p-2 border"
  style={{ height: "auto", minHeight: 200 }}
  >
  <p>
  Please read these terms carefully before using the online payment
  facility. Using the online payment facility on this website indicates
  that you accept these terms. If you do not accept these terms do not
  use this facility. All payments are subject to the following
  conditions:- The description of services of match making are specific
  to your need, when you log in with your unique password. Normally
  payment is required in advance (i.e. before you commence your
  activity). All Fees quoted are in Indian Rupees. The vasthuparam.com reserves
  the right to change the fees at any time.
  </p>{" "}
  <p>
  • Your payment will normally reach the vasthuparam.com account to which you
  are making a payment within two working days.
  </p>{" "}
  <p>
  {" "}
  • We cannot accept liability for a payment not reaching the correct
  vasthuparam.com account due to you quoting an incorrect account number or
  incorrect personal details. Neither can we accept liability if payment
  is refused or declined by the credit/debit card supplier for any
  reason.
  </p>
  <p>
  {" "}
  • If the card supplier declines payment the vasthuparam.com is under no
  obligation to bring this fact to your attention. You should check with
  your bank/credit/debit card supplier that payment has been deducted
  from your account.
  </p>
  <p>
  • In no event will the vasthuparam.com be liable for any damages whatsoever
  arising out of the use, inability to use, or the results of use of
  this site, any websites linked to this site, or the materials or
  information contained at any or all such sites, whether based on
  warranty, contract, tort or any other legal theory and whether or not
  advised of the possibility of such damages.
  </p>{" "}
  <br />
  <h3>Online Payments:</h3>
  <p>
  This online payment system is provided by vasthuparam.com.The vasthuparam.com may
  update these terms from time to time and any changes will be effective
  immediately on being set out here. Please ensure you are aware of the
  current terms. The country of domicile for vasthuparam.com is India.
  </p>
  <h3>Refund Policy:</h3>
  <p>
  • If the Customer leaves the vasthuparam.com before they complete their
  service period, there shall be no entitlement to a refund of paid
  service fees.{" "}
  </p>
  <p>
  • Refunds, if applicable, at the discretion of the Management, will
  only be made to the debit/credit card used for the original
  transaction. For the avoidance of doubt nothing in this Policy shall
  require the vasthuparam.com to refund the Fees (or part thereof) unless such
  Fees (or part thereof) have previously been paid.
  </p>
  <h3>Privacy Policy:</h3>
  <p>
  This Privacy Policy applies to all of the products, services and
  websites offered by vasthuparam.com. Sometimes, we may post product specific
  privacy notices or Help Centre materials to explain our products in
  more detail.
  </p>
  <p>
  If you have any questions about this Privacy Policy, please feel free
  to contact us through our website or write to us at contactus@vasthuparam.com.
  </p>
  {/* <p>Information we collect and how we use it for our vasthuparam.com.</p> */}
  <h3>Changes to our Privacy Policy:</h3>
  <p>
  vasthuparam.com reserves the entire right to modify/amend/remove this privacy
  statement anytime and without any reason. Nothing contained herein
  creates or is intended to create a contract/agreement between vasthuparam.com
  and any user visiting the vasthuparam.com website or providing identifying
  information of any kind.
  </p>
  <h3>DND Policy: </h3>
  <p>
  If you wish to stop any further sms/email alerts/contacts from our
  side, all you need to do is to send an email:- contactus@vasthuparam.com with your
  mobile numbers and you will be excluded from the alerts list.{" "}
  </p>
  <h3>Contact Details:</h3>
  <p>Email:contactus@vasthuparam.com</p>
  </Row>
  </Container>
  );
  };
  export default TermConditions;
