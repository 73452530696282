import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Card } from "react-bootstrap";
import { isEmpty } from "validator";
import { useTranslation } from "react-i18next";

function UserAppoinmentList(params) {
  console.log("params", params);
  const userAppoinmentList = params.userAppoinmentList;
  console.log("userAppoinmentList", userAppoinmentList);
  const {t} = useTranslation();

  const listtemp = userAppoinmentList.map((obj, index) => {
    return {
      ...obj,
      id: index + 1,
    };
  });
  const columns = [
    { field: "id", headerName: "S.NO", width: 100 },
    { field: "slotId", headerName: "SLOT ID", width: 130 },
    { field: "name", headerName: "CONSULTANT NAME", width: 200 },
    { field: "callDate", headerName: t("TXT.CAL.DAT.UP"), width: 150 },
    { field: "callTime", headerName: "CALL TIME", width: 150 },
    { field: "serviceStatus", headerName: " STATUS", width: 150 },
    {
      field: "cancelReason",
      headerName: "Cancellation Reason",
      width: 200,
      valueGetter: ({ row }) => {
        if (row.cancelReason !== null) {
          return row.cancelReason;
        } else {
          return "NA";
        }
      },
    },
  ];

  // const listtemp = userAppoinmentList.map((obj) => {
  //   const cancelReason = obj.cancelReason;
  //   console.log('cancelReason', cancelReason)
  //   if (cancelReason !== null) {
  //     return {
  //       ...obj,
  //     };
  //   } else {
  //     return {
  //       ...obj,
  //       cancelReason: "Not Applicable",
  //     };
  //   }
  // });

  return (
    <div>
      <center>
        <h4>
          <u>Slot Details</u>
        </h4>
      </center>
      {userAppoinmentList.length > 0 ? (
        <>
          <center className="datagrid">
            <Grid container className="deskTable">
              <DataGrid
                className="all_border - text-center me-3 data_grid"
                rows={listtemp}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                getRowId={(row) => row.id}
              />
            </Grid>
          </center>

          <div className="mobileCard">
            {userAppoinmentList.map((i, j) => (
              <Card
                className="mobileCard all_border"
                key={j}
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  backgroundColor: "antiquewhite",
                }}
              >
                <Card.Body>
                  <Card.Subtitle className="mb-2">
                    <b>Slot Id:</b> {i.slotId}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2">
                    <b>Consultant Name:</b> {i.name}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2">
                    <b>{t("TXT.CAL.DAT.LO")}:</b> {i.callDate}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2">
                    <b> Call Time:</b> {i.callTime}
                  </Card.Subtitle>

                  <Card.Subtitle className="mb-2">
                    <b>Status:</b> {i.serviceStatus}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2">
                    <b>Cancellation Reason:</b>{" "}
                    {i.cancelReason !== null ? i.cancelReason : "NA"}
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            ))}
          </div>
        </>
      ) : (
        <center>
          <h5>{t("TXT.NO.REC.FUD.LO")}</h5>
        </center>
      )}
    </div>
  );
}

export default UserAppoinmentList;
