import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import ProfileForm from "./ProfileForm";
import AdminPayment from "./AdminPayment"
import AdminRefund from "./AdminRefund";

const AdminProfile = () => {
  const [key, setKey] = useState("profile");
  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="justify-content-center"
    >
      <Tab eventKey="profile" title="PROFILE">
        <center>
          <h4 className="m-1">
            <u>Profile Details</u>
          </h4>
        </center>
        <ProfileForm />
      </Tab>
      <Tab eventKey="payment" title="PAYMENTS">
        <center>
          <h4 className="m-1">
            <u>Payment Details</u>
          </h4>
        </center>
        <AdminRefund />
      </Tab>
      <Tab eventKey="refund" title="REFUNDS">
        <center>
          <h4 className="m-1">
            <u>Refund Details</u>
          </h4>
        </center>
      </Tab>
      {/* <Tab eventKey="content" title="CONTENT">
        <center>
          <h4 className="m-1">
            <u>Content Details</u>
          </h4>
        </center>
      </Tab> */}
    </Tabs>
  );
};

export default AdminProfile;
