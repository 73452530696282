import React, { useEffect, useState } from "react";
import {
  Accordion,
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  InputGroup,
  Row,
  Table,
} from "react-bootstrap";
import { BsCalendarDate } from "react-icons/bs";
import DatePicker from "react-datepicker";

import TimePicker from "react-bootstrap-time-picker";
import { MdRefresh } from "react-icons/md";
import { vasthuUrl } from "../component/Api";
import { UseAuth } from "../context/Auth";
import { useTranslation, Trans } from "react-i18next";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stacks from "@mui/material/Stack";

const CreateSlot = () => {
  const { t } = useTranslation();
  const userId = window.sessionStorage.getItem("userId");
  const token = window.sessionStorage.getItem("token");
  // const { userId } = UseAuth();
  var todaysDate = new Date();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [startDateTimeStamp, setStartDateTimeStamp] = useState();
  const [endDateTimeStamp, setEndDateTimeStamp] = useState();
  const [fromTimeLimit, setFromTimeLimit] = useState("09:00");
  console.log("fromTimeLimit", fromTimeLimit);
  const [toTimeLimit, setToTimeLimit] = useState("18:00");
  console.log("toTimeLimit", toTimeLimit);
  const [initialStartTimeValue, setInitialStartTimeValue] = useState("");
  console.log("initialStartTimeValue", initialStartTimeValue);
  const [initialEndTimeValue, setInitialEndTimeValue] = useState("");
  const [startTime, setStartTime] = useState(0);
  const [endTime, setEndTime] = useState(3600);
  const [convertStartTime, setConvertStartTime] = useState("09:00:00");
  const [convertEndTime, setConvertEndTime] = useState("09:00:00 ");
  const [datelimit, setdatelimit] = useState(" ");
  const [meetingDuration, setMeetingDuration] = useState("15");
  const [maxDateLimit, setMaxDateLimit] = useState(" ");
  const [yourScheduledMeetings, setYourScheduledMeetings] = useState([]);
  const [keyValue, setKeyValue] = useState(false);
  const [serviceData, setServiceData] = useState([]);
  const [selectedService, setSelectedService] = useState();
  const [consultationModes, setConsultationModes] = useState([]);
  const [selectedConsultationMode, setSelectedConsultationMode] = useState(
    "Audio"
  );

  let changingHours = todaysDate.getTime() + 1 * 60 * 60 * 1000;

  let changedHours = new Date(changingHours).getHours();

  let changingMin = todaysDate.getTime() + 10 * 60 * 1000;

  let changedMin = new Date(changingMin).getMinutes();

  //toast aleart
  const [openSnack, setOpenSnack] = React.useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("error");

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const alert = (msg) => {
    setMessage(msg);
    setOpenSnack(true);
    setTimeout(() => {
      setMessage("");
    }, 4000);
  };

  useEffect(() => {
    // for audio consultation - inclution
    try {
      vasthuUrl
        // .get(`/vasthu/getvasthuservices`, {
        .get(`vasthu/getvasthuservicesbyconsultant/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("services_list" + res.data);
          // let consultations = res.data.filter( obj =>  obj.serviceType == "CONSULTATION" && obj.serviceName !== "Audio Consultation");
          // console.log("services_list_consultaions"+consultations[0].serviceType + consultations[0].id+consultations[0].serviceName)
          let consultations = res.data;
          setServiceData(consultations);
          if (res.data.length > 0) {
            if (res.data[0].audio && res.data[0].video) {
              console.log("AudioVideo");
              setConsultationModes([
                { id: 1, mode: "Audio" },
                { id: 2, mode: "Video" },
              ]);
              setSelectedConsultationMode("Audio");
            } else if (res.data[0].audio && !res.data[0].video) {
              console.log("Audio");
              setConsultationModes([{ id: 1, mode: "Audio" }]);
              setSelectedConsultationMode("Audio");
            } else if (!res.data[0].audio && res.data[0].video) {
              console.log("Video");
              setConsultationModes([{ id: 1, mode: "Video" }]);
              setSelectedConsultationMode("Video");
            } else {
              console.log("empty");
              setConsultationModes([]);
            }
          }
        });
    } catch (e) {
      console.log("service", e);
    }
    // for audio consultation - end
    var today = new Date();

    let currentHours = today.getHours();
    let currentMin = today.getMinutes();
    let currentSec = today.getSeconds();

    var currentdate = today.getTime();
    console.log(currentdate);
    setdatelimit(currentdate);

    // let date = "2022-08-04";
    // let ts = new Date(date).getTime();
    //  console.log("DATE TS" + ts);

    var maxAllowedWeek = 8;
    var daysCountinWeek = 7;
    var allowedDate = today.setDate(
      today.getDate() + maxAllowedWeek * daysCountinWeek
    );
    console.log("ALLOWED DATE" + allowedDate);
    setMaxDateLimit(allowedDate);

    getUpcomingServiceSchedules();
  }, [keyValue]);

  const getHoursMinutesSec = (duration) => {
    var hrs = ~~(duration / 3600);
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;

    var ret = "";

    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    console.log(ret);
    return ret;
  };

  const createBooking = async () => {
    console.log(
      "CONVERTED TIMES" +
        convertStartTime +
        convertEndTime +
        " S :" +
        startTime +
        " E :" +
        endTime
    );

    // let timeDifference = endTime - startTime;
    let timeDifference = (endTime - startTime) / 60; // newly added for fix duration mismatch-05-06-2023

    console.log("TIME DIFF" + timeDifference);

    try {
      if (startDate == "" || endDate == "" || meetingDuration == "") {
        alert(" PLEASE CHECK ALL INPUTS");
        setMessageType("error");
      }

      if (timeDifference % meetingDuration !== 0) {
        alert("PLEASE CHOOSE A VALID DURATION");
        setMessageType("error");
      }

      if (startDateTimeStamp > endDateTimeStamp) {
        alert("Scheduled From Date and To Date is Invalid");
        setMessageType("error");
      }

      if (convertStartTime == convertEndTime || startTime > endTime) {
        alert("TIME IS INVALID");
        setMessageType("error");
      }

      // if (startDate == endDate && timeDifference < meetingDuration) {  // start date , end date not validated properly because of the date format
      if (timeDifference < meetingDuration) {
        alert("Service Time not meet your CallDuration"); // return missing so not properly check the durations and timedifference - 05-06-2023
        setMessageType("error");
      }

      let postingObj = {
        consultantUuid: userId,
        consultationMode: selectedConsultationMode,
        scheduleFromDate: startDate,
        scheduleToDate: endDate,
        scheduleStartTime: convertStartTime,
        scheduleEndTime: convertEndTime,
        scheduleDuration: 15,
        selectedService: null,
        serviceStartDate: startDate,
        serviceEndDate: endDate,
        serviceStartTime: convertStartTime,
        serviceEndTime: convertEndTime,
      };

      console.log("POSTIn obj" + JSON.stringify(postingObj));

      const res = await vasthuUrl.post(
        "saveserviceschedule",
        {
          consultantUuid: userId,
          consultationMode: selectedConsultationMode,
          scheduleFromDate: startDate,
          scheduleToDate: endDate,
          scheduleStartTime: convertStartTime,
          scheduleEndTime: convertEndTime,
          scheduleDuration: 15,
          // selectedService: null,
          serviceStartDate: startDate,
          serviceEndDate: endDate,
          serviceStartTime: convertStartTime,
          serviceEndTime: convertEndTime,
          // consultantUuid: userId,
          // scheduleFromDate: startDate,
          // scheduleToDate: endDate,
          // scheduleStartTime: convertStartTime,
          // scheduleEndTime: convertEndTime,
          // scheduleDuration: meetingDuration,
          // selectedService: selectedService, // added for audio
          // consultationMode: selectedConsultationMode,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (res.data === "ALREADY SCHEDULED EXISTS FOR THIS GIVEN TIME ") {
        alert(res.data);
        setMessageType("error");
      } else {
        alert(res.data);
        setMessageType("success");
      }
      setStartDate("");
      setEndDate("");
      setStartTime();
      setEndTime();
      setKeyValue(1);
      console.log(res);
      getUpcomingServiceSchedules();
    } catch (error) {
      alert("SERVER ERROR");
      setMessageType("error");
    }
  };

  const getUpcomingServiceSchedules = async () => {
    try {
      const res = await vasthuUrl.get(`getupcomingserviceschedule/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setYourScheduledMeetings(res.data);
      console.log("upcoming_schedule_meeting", res.data);
    } catch (error) {
      console.log("ERROE" + error);
    }
  };

  const addOneHour = (timeString) => {
    // Convert the time string to a Date object
    const [hours, minutes] = timeString.split(":").map(Number);
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);

    // Add one hour
    date.setHours(date.getHours() + 1);

    // Format the new time back to "HH:MM"
    const newHours = date
      .getHours()
      .toString()
      .padStart(2, "0");
    const newMinutes = date
      .getMinutes()
      .toString()
      .padStart(2, "0");

    return `${newHours}:${newMinutes}`;
  };

  return (
    <Container fluid>
      <Row className="justify-content-center">
        <Col sm={1} md={1} lg={3} />
        <Col sm={10} md={10} lg={6} xs={12}>
          <Card className="m-2 border border-warning rounded text-center">
            <Card.Body>
              <Row className="my-2 mx-2 p-1 pb-1  border-warning border border-rounded">
                {/* <Col sm={6}> */}
                <h6>
                  {t("CON.APMT.NA.FDATE")}
                  {/* {t("PR.APMNT.NATAB.LB.SF")} */}
                </h6>

                <DatePicker
                  selected={startDate}
                  className="create_slot_datepic form_feild"
                  onChange={(date) => {
                    console.log(date.getDate(), todaysDate.getDate());

                    let selectedDate = date.getDate() + "-" + date.getMonth();

                    if (
                      selectedDate ==
                      todaysDate.getDate() + "-" + todaysDate.getMonth()
                    ) {
                      let currentHours = todaysDate.getHours();
                      let currentMin = todaysDate.getMinutes();
                      let currentSec = todaysDate.getSeconds();

                      //let timeCurrent = currentHours + ":" + changedMin;

                      let timeCurrent;

                      //let convertStartTime = timeCurrent + ":" + currentSec;

                      let convertStartTime;

                      // setFromTimeLimit(timeCurrent);
                      // setConvertStartTime(convertStartTime);

                      if (currentMin < 30) {
                        timeCurrent = currentHours + ":" + "30";
                        setFromTimeLimit(timeCurrent);
                        convertStartTime = timeCurrent + ":" + "00";
                        setConvertStartTime(convertStartTime);
                      }

                      if (currentMin > 30) {
                        timeCurrent = currentHours + 1 + ":" + "00";
                        setFromTimeLimit(timeCurrent);
                        convertStartTime = timeCurrent + ":" + "00";
                        setConvertStartTime(convertStartTime);
                      }
                    }

                    if (
                      selectedDate !=
                      todaysDate.getDate() + "-" + todaysDate.getMonth()
                    ) {
                      setFromTimeLimit("00:00");
                      setInitialStartTimeValue("00:00");
                      setConvertStartTime("00:00:00");
                    }

                    setStartDateTimeStamp(date.getTime());

                    setStartDate(date);
                  }}
                  dateFormat="yyyy-MM-dd"
                  placeholderText={t("PR.APMNT.NATAB.LB.SF.TA")}
                  minDate={datelimit}
                  maxDate={maxDateLimit}
                />

                <h6>
                  {t("CON.APMT.NA.TDATE")}
                  {/* <BsCalendarDate size={26} color="black" />
                  {t("PR.APMNT.NATAB.LB.ST")} */}
                </h6>

                <DatePicker
                  selected={endDate}
                  className="create_slot_datepic form_feild"
                  onChange={(date) => {
                    let selectedDate = date.getDate() + "-" + date.getMonth();

                    if (
                      selectedDate ==
                      todaysDate.getDate() + "-" + todaysDate.getMonth()
                    ) {
                      let currentHours = todaysDate.getHours();
                      let currentMin = todaysDate.getMinutes();
                      let currentSec = todaysDate.getSeconds();

                      let timeCurrent;

                      let convertEndTime;

                      //setToTimeLimit(timeCurrent);
                      // setConvertEndTime(convertEndTime);

                      //before changes

                      // workouts

                      //  console.log("MINUTES" + currentMin + "TIME CURR" + timeCurrent +"End time" + convertEndTime);

                      if (currentMin < 30) {
                        timeCurrent = changedHours + ":" + "30";
                        setToTimeLimit(timeCurrent);
                        convertEndTime = timeCurrent + ":" + "00";
                        setConvertEndTime(convertEndTime);
                      }

                      if (currentMin > 30) {
                        timeCurrent = changedHours + 1 + ":" + "00";
                        setToTimeLimit(timeCurrent);
                        convertEndTime = timeCurrent + ":" + "00";
                        setConvertEndTime(convertEndTime);
                      }
                    }

                    if (
                      selectedDate !=
                      todaysDate.getDate() + "-" + todaysDate.getMonth()
                    ) {
                      setToTimeLimit("00:00");
                      setInitialEndTimeValue("01:00");
                      setConvertEndTime("01:00:00");
                    }

                    setEndDateTimeStamp(date.getTime());
                    setEndDate(date);
                  }}
                  dateFormat="yyyy-MM-dd"
                  minDate={datelimit}
                  placeholderText={t("PR.APMNT.NATAB.LB.ST.TA")}
                  maxDate={maxDateLimit}
                />
              </Row>

              <Row className="my-1 mb-1 mx-2 p-1 pb-1  border-warning border border-rounded">
                <Col sm={6} className=" border-warning">
                  <h6>{t("CON.APMT.NA.ST")}</h6>
                  <TimePicker
                    step={30}
                    format={24}
                    className="form_feild"
                    initialValue={initialStartTimeValue}
                    start={fromTimeLimit}
                    value={startTime}
                    onChange={(t) => {
                      console.log("TEST", t);

                      let startTime = getHoursMinutesSec(t);
                      setStartTime(t);
                      setConvertStartTime(startTime);
                      const newTime = addOneHour(startTime); // This will be "08:00"
                      setToTimeLimit(newTime);
                      setInitialEndTimeValue(newTime);
                    }}
                  />
                </Col>
                <Col sm={6} className=" border-warning">
                  <h6>{t("CON.APMT.NA.ET")}</h6>
                  <TimePicker
                    step={30}
                    initialValue={initialEndTimeValue}
                    start={toTimeLimit}
                    format={24}
                    value={endTime}
                    className="form_feild"
                    onChange={(t) => {
                      console.log("TEST", t);

                      if (t == 0) {
                        console.log("o time");
                        setEndTime(t);
                        setConvertEndTime("00:00:00");
                      }

                      if (t == 1800) {
                        console.log("30 time");
                        setEndTime(t);
                        setConvertEndTime("00:30:00");
                      }

                      if (t > 1800) {
                        let endTime = getHoursMinutesSec(t);
                        console.log("end time", endTime);
                        setEndTime(t);
                        setConvertEndTime(endTime);
                      }
                    }}
                  />
                </Col>
              </Row>
              {/* <div style={{ justifyContent: "center", marginBottom: 20 }}> */}
              {/* <FloatingLabel
                  controlId="min"
                  label={t("PR.APMNT.NATAB.LB.CD")}
                > */}
              {/* <Form.Label className="d-flex">
                  {t("PR.APMNT.NATAB.LB.CD")}
                </Form.Label> */}
              {/* <Form.Select
                  aria-label="min"
                  size="sm"
                  onChange={(e) => {
                    console.log("MINS", e.target.value);
                    setMeetingDuration(e.target.value);
                  }}
                >
                  <option key={0} value={""}>
                    {t("PR.APMNT.NATAB.LB.CD.TA")}
                  </option>
                  <option key={1} value="15">
                    15 Mins
                  </option>
                </Form.Select> */}
              {/* </FloatingLabel> */}
              {/* </div> */}

              <Row className="my-1 mb-1 mx-2 p-1 pb-1  border-warning border border-rounded">
                <Col className=" border-warning">
                  <Form.Label className="d-flex">
                    {t("CON.APMT.NA.SLT.MD.CONS")}
                  </Form.Label>
                  <Form.Select
                    aria-label="min"
                    size="sm"
                    className="form_feild"
                    onChange={(e) => {
                      console.log("SelectedConsultationMode", e.target.value);
                      setSelectedConsultationMode(e.target.value);
                    }}
                  >
                    {consultationModes.map((mode, index) => (
                      <option key={index} value={mode.mode}>
                        {mode.mode}
                      </option>
                    ))}
                  </Form.Select>
                </Col>
              </Row>

              {/* <div style={{ justifyContent: "center", marginBottom: 20 }}> */}
              {/* <FloatingLabel
                  controlId="min"
                  label={"Select Mode of Consultation"}
                > */}
              {/* <Form.Label className="d-flex">
                  {t("CON.APMT.NA.SLT.MD.CONS")}
                </Form.Label>
                <Form.Select
                  aria-label="min"
                  size="sm"
                  onChange={(e) => {
                    console.log("SelectedConsultationMode", e.target.value);
                    setSelectedConsultationMode(e.target.value);
                  }}
                >
                  {consultationModes.map((mode, index) => (
                    <option key={index} value={mode.mode}>
                      {mode.mode}
                    </option>
                  ))} */}
              {/* <option key={1} value="Audio">
                      Audio
                    </option>

                    <option key={2} value="Video">
                      Video
                    </option> */}
              {/* </Form.Select> */}
              {/* </FloatingLabel> */}
              {/* </div> */}

              <center>
                <Button
                  variant="outline-warning"
                  style={{ borderRadius: 50, color: "black" }}
                  size="sm"
                  onClick={createBooking}
                >
                  {t("PR.APMNT.NATAB.BTN")}
                </Button>
              </center>
            </Card.Body>
          </Card>
        </Col>

        <Col sm={1} md={1} lg={3} />
      </Row>

      <Accordion>
        <Accordion.Item className="accordian_style" eventKey="1">
          <Accordion.Header className="accordian_style">
            {/* Your Current Week's Schedules &nbsp;&nbsp;&nbsp;{" "} */}
            {t("PR.APMNT.NATAB.ACR.LB")}
          </Accordion.Header>

          <Accordion.Body>
            <Table striped bordered hover responsive>
              <thead className="border-warning">
                <tr>
                  <th>{t("PR.APMNT.NATAB.LB.SF")}</th>
                  <th>{t("PR.APMNT.NATAB.LB.ST")}</th>
                  <th>{t("PR.APMNT.NATAB.LB.CD")}</th>
                  <th>{t("TXT.STA.TIM.LO")}</th>
                  <th>{t("TXT.END.TIM.LO")}</th>
                  <th>{t("CON.APMT.NA.CONS.MOD")}</th>
                </tr>
              </thead>

              <tbody className="border-warning" key={0}>
                {yourScheduledMeetings.map((d, i) => (
                  <tr key={i}>
                    <td>
                      {d.schedule_from_date
                        .split("-")
                        .reverse()
                        .join("/")}
                    </td>
                    <td>
                      {d.schedule_to_date
                        .split("-")
                        .reverse()
                        .join("/")}
                    </td>
                    <td>{d.schedule_duration_in_minutes}</td>
                    <td>{d.schedule_start_time}</td>
                    <td>{d.schedule_end_time}</td>
                    {/* <td>{d.service.serviceName}/{d.consultation_mode}</td> */}
                    <td>{d.consultation_mode}</td>{" "}
                    {/* Any service - No serrvice is bind */}
                  </tr>
                ))}
              </tbody>
            </Table>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Stacks spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleCloseSnack}
        >
          <MuiAlert
            variant="filled"
            onClose={handleCloseSnack}
            severity={messageType}
            sx={{
              width: "100%",
            }}
          >
            {message}
          </MuiAlert>
        </Snackbar>
      </Stacks>
    </Container>
  );
};

export default CreateSlot;
