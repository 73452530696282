// import React, { useEffect, useState } from "react";
// import { Button, Card, Col, Container, Image, Row } from "react-bootstrap";
// import { vasthuUrl } from "../component/Api";
// import { useNavigate } from "react-router-dom";
// import { useTranslation } from "react-i18next";

// const OurProducts = () => {
//   const navigate = useNavigate();
//   const { t } = useTranslation();
//   const [productData, setProductData] = useState([]);
//   const token = window.sessionStorage.getItem("token");
//   const lang1 = localStorage.getItem("i18nextLng");

//   useEffect(() => {
//     try {
//       vasthuUrl
//         .get(`/vasthu/getallproductswithtype`, {
//           headers: {
//             Authorization: `Bearer ${token}`,
//           },
//         })
//         .then((res) => {
//           setProductData(res.data);
//           console.log("service", res.data);
//         });
//     } catch (e) {
//       console.log("service", e);
//     }
//   }, []);

//   return (
//     <Container>
//       <div className="p-3">
//         <center>
//           <h1>{t("TXT.OUR.PRO.LO")}</h1>
//         </center>
//         <Row style={{ border: "2px solid #ffb33c" }} className="p-2">
//           <>
//             {productData && productData.length > 0 ? (
//               productData.map((i, j) => (
//                 <Col sm={12} md={6} lg={4} className="p-2">
//                   <div style={{ border: "1px solid green" }}>
//                     <center>
//                       <h3>
//                         {/* <u>{i.productType}</u> */}
//                         {lang1 == "en"
//                           ? i.productType
//                           : lang1 == "ta"
//                           ? i.products[0].name_ta
//                           : i.products[0].name_hi}
//                       </h3>
//                     </center>
//                     <div
//                       style={{
//                         display: "flex",
//                         flexWrap: "wrap",
//                         justifyContent: "center",
//                       }}
//                     >
//                       {i.products.slice(0, 4).map((item, index) => (
//                         <div key={index} className="">
//                           <div className="p-2">
//                             <Image
//                               className="ourproduct_img"
//                               alt="no img"
//                               src={item.productimgUrl1} // Assuming each item has an `images` array with at least one element
//                               onClick={() => {
//                                 if (token) {
//                                   navigate("/productdetails", {
//                                     state: item.productId,
//                                   });
//                                 } else {
//                                   navigate("/product");
//                                 }
//                               }}
//                             />
//                           </div>
//                           <center>
//                             <b>{item.productName}</b>
//                           </center>
//                         </div>
//                       ))}
//                     </div>
//                   </div>
//                 </Col>
//               ))
//             ) : (
//               <center>
//                 <h5>{t("TXT.COM.SOON.LO")}...</h5>
//               </center>
//             )}
//             <div>
//               <Button
//                 className="all_btn"
//                 style={{ float: "right" }}
//                 onClick={() => navigate("/product")}
//               >
//                 {t("CM.HD.KM.MEN")}
//               </Button>
//             </div>
//           </>
//         </Row>
//       </div>
//     </Container>
//   );
// };

// export default OurProducts;

import React from "react";
import UserProduct from "./User/UserProduct";
import { Container } from "react-bootstrap";

const OurProducts = () => {
  const homePage = "done";
  return (
    <Container>
      <div
        className="p-3"
        style={{ backgroundColor: "#ffb33c", borderRadius: "5px" }}
      >
        <UserProduct data={homePage}/>
      </div>
    </Container>
  );
};

export default OurProducts;
