import React from "react";
import { Container } from "react-bootstrap";
import Title from "../component/Title";
// import Table from "react-bootstrap";
// import { Table } from "@mui/material";
import Table from 'react-bootstrap/Table';
import { useTranslation } from "react-i18next";



const VasthuBooks = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Title title={t('CM.HD.KM.MEN.BK')} />
      <p>
        Vastu Shastra, is an ancient Indian
        architectural science that focuses on the design and construction of
        buildings in harmony with natural and cosmic energies. It provides
        guidelines for creating living and working spaces that promote
        well-being, prosperity, and spiritual growth.
      </p>
      <p>
        There are several books available on Vasthu Sastra that delve into its
        principles, theories, and practical applications. Here are a few notable
        books on the subject:
      </p>
      <p>
        <Table striped className="all_border" bordered >
          <thead style={{ textAlign: 'center' }}>
            <tr>
              <th>
                Author_Name
              </th>
              <th>Book Name</th>
              <th>Published</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Durai, R.P.</td> <td> Mayan Ariviyal Thozhilnutpam <br />(Tamil version)</td><td>2004, Meyappan Pathippagam,
                Chidambaram, Tamil Nadu.</td>
            </tr>
            <tr>
              <td>Mudaliar, V.</td> <td> Sarvartha Sirpachinthamani <br />(Tamil version)</td> <td>1995, B. Rathina Nayakan and sons
                publications Chennai, Tamil Nadu.</td>
            </tr>
            <tr>
              <td> Raman, B.V.</td> <td>pp. 6-13, 89-293.</td> <td>1998, Astrology in Veda 3rd vol in
                Astrological Magazine,</td>
            </tr>
            <tr>
              <td>Sthapathi, V.G.</td> <td>May, pp 490.</td>
              <td>2001, Building Architecture of Sthapathi Veda,
                Dakshina Publishing Housing, Chennai, Tamil Nadu.</td>
            </tr>
          </tbody>
        </Table>
      </p>
      {/* <p>
        1. "Vastu Shastra: The Indian Science of Architecture and Design" by Dr.
        Bhojraj Dwivedi - This book provides a comprehensive introduction to
        Vastu Shastra, covering its history, principles, and practical
        implementation. It explores various aspects of Vastu, including site
        selection, building design, room placement, and interior decoration.
      </p>
      <p>
        {" "}
        2. "Vastu: Breathing Life into Space" by Robert E. Svoboda - Written by
        a renowned author and expert in Vastu Shastra, this book offers a modern
        perspective on the ancient science. It explains the fundamental
        principles of Vastu and provides practical tips for applying them in
        contemporary living spaces.
      </p>
      <p>
        3. "Vastu: Astrology and Architecture" by Gayatri Devi Vasudev - This
        book explores the relationship between Vastu Shastra and astrology. It
        discusses how the alignment of buildings with cosmic energies can
        influence human lives and provides insights into the astrological
        aspects of Vastu.
      </p>
      <p>
        4. "Vastu Architecture: Design Theory and Application for Everyday Life"
        by Michael Borden - This book combines Vastu Shastra principles with
        modern architectural practices. It offers practical guidance on
        incorporating Vastu principles into the design and construction of
        homes, offices, and other structures.
      </p>
      <p>
        5. "Vastu Vidya Handbook: Indian Feng Shui" by Juliet Pegrum - This book
        presents Vastu Shastra as the Indian equivalent of Feng Shui, the
        Chinese art of harmonizing spaces. It explores the similarities and
        differences between the two systems and provides practical tips for
        applying Vastu principles in everyday life.
      </p>
      <p>
        These books serve as valuable resources for anyone interested in
        understanding and implementing Vastu Sastra principles in their living
        or working spaces. They offer insights, guidelines, and practical
        examples to help create harmonious and balanced environments.
      </p> */}
    </Container>
  );
};
export default VasthuBooks;
