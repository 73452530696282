import React from "react";
import { useState } from "react";
import {
  Badge,
  Button,
  Card,
  Container,
  Form,
  Stack,
  Alert,
  Spinner,
} from "react-bootstrap";
import { vasthuUrl } from "../component/Api";
import { UseVasthu } from "../context/vasthu";
import { useNavigate } from "react-router-dom";

const HomeLevel = () => {
  const { homeRec } = UseVasthu();
  const navigate = useNavigate();
  const token = window.sessionStorage.getItem("token");
  const [southLevel, setSouthLevel] = useState("");
  const [northLevel, setNorthLevel] = useState("");
  const [southWestLevel, setSouthWestLevel] = useState("");
  const [northEastLevel, setNorthEastLevel] = useState("");
  const [westLevel, setWestLevel] = useState("");
  const [eastLevel, setEastLevel] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState(false);
  const alert = (msg) => {
    setErrMsg(msg);
    setTimeout(() => {
      setErrMsg("");
    }, 3000);
  };

  const levelAllSubmit = async () => {

    if(southLevel == northLevel || southWestLevel == northEastLevel || westLevel == eastLevel){
      return alert("Same Levels is not Valid")
    }

    if (
      southLevel &&
      northLevel &&
      southWestLevel &&
      northEastLevel &&
      westLevel &&
      eastLevel != ""
    ) {
      try {
        setLoading(true);
        const res = await vasthuUrl.post(
          "saveleveldetails",
          {
            houseUuid: homeRec,
            SouthLevel: southLevel,
            NorthLevel: northLevel,
            SouthWestLevel: southWestLevel,
            NorthEastLevel: northEastLevel,
            WestLevel: westLevel,
            EastLevel: eastLevel,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log(res.data);
        alert(res.data.message);
        navigate("/homevalidation");
        setLoading(false);
        setNorthLevel("");
        setSouthLevel("");
        setNorthEastLevel("");
        setSouthWestLevel("");
        setEastLevel("");
        setWestLevel("");
      } catch (error) {
        alert("SERVER ERROR");
        setLoading(false);
      }
    } else {
      alert("PLEASE SELECT ALL DIRECTION LEVEL");
    }
  };

  return (
    <Container>
      <center>
        <h4>CHECK HOUSE LEVELING</h4>
      </center>

      {errMsg && (
        <Alert variant="info" className="text-center">
          {errMsg}
        </Alert>
      )}

      <Stack gap={3}>
        <Card>
          <Card.Header as="h6">
            <center>SOUTH vs NORTH</center>
          </Card.Header>

          <Stack direction="horizontal" gap={1}>
            <Card.Body>
              <Form.Group className="mb-2" controlId="formSouthLevel">
                <Form.Label style={{ fontSize: 14, fontWeight: "bold" }}>
                  CHECK SOUTH LEVEL
                </Form.Label>
                <Form.Select
                  aria-label="south"
                  size="lg"
                  value={southLevel}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setSouthLevel(e.target.value);
                  }}
                >
                  <option key={0} value={""}>
                    Choose South Leveling
                  </option>

                  <option key={1} value={0}>
                    LOW
                  </option>
                  <option key={2} value={1}>
                    HIGH
                  </option>
                </Form.Select>
              </Form.Group>
            </Card.Body>

            {"  "}
            <Badge pill bg="secondary">
              <h6>VS</h6>
            </Badge>
            {"  "}
            <Card.Body>
              <Form.Group className="mb-2" controlId="formNorthLevel">
                <Form.Label style={{ fontSize: 14, fontWeight: "bold" }}>
                  CHECK NORTH LEVEL
                </Form.Label>
                <Form.Select
                  aria-label="north"
                  size="lg"
                  value={northLevel}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setNorthLevel(e.target.value);
                  }}
                >
                  <option key={0} value={""}>
                    Choose North Leveling
                  </option>

                  <option key={1} value={0}>
                    LOW
                  </option>
                  <option key={2} value={1}>
                    HIGH
                  </option>
                </Form.Select>
              </Form.Group>
            </Card.Body>
          </Stack>
        </Card>

        <Card>
          <Card.Header as="h6">
            <center>SOUTH-WEST vs NORTH-EAST</center>
          </Card.Header>

          <Stack direction="horizontal" gap={1}>
            <Card.Body>
              <Form.Group className="mb-2" controlId="formSouthWestLevel">
                <Form.Label style={{ fontSize: 14, fontWeight: "bold" }}>
                  CHECK SOUTH-WEST LEVEL
                </Form.Label>
                <Form.Select
                  aria-label="southwest"
                  size="lg"
                  value={southWestLevel}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setSouthWestLevel(e.target.value);
                  }}
                >
                  <option key={0} value={""}>
                    Choose South-West Leveling
                  </option>

                  <option key={1} value={0}>
                    LOW
                  </option>
                  <option key={2} value={1}>
                    HIGH
                  </option>
                </Form.Select>
              </Form.Group>
            </Card.Body>

            {"  "}
            <Badge pill bg="secondary">
              <h6>VS</h6>
            </Badge>
            {"  "}
            <Card.Body>
              <Form.Group className="mb-2" controlId="formNorthEastLevel">
                <Form.Label style={{ fontSize: 14, fontWeight: "bold" }}>
                  CHECK NORTH-EAST LEVEL
                </Form.Label>
                <Form.Select
                  aria-label="northeast"
                  size="lg"
                  value={northEastLevel}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setNorthEastLevel(e.target.value);
                  }}
                >
                  <option key={0} value={""}>
                    North-East Leveling
                  </option>

                  <option key={1} value={0}>
                    LOW
                  </option>
                  <option key={2} value={1}>
                    HIGH
                  </option>
                </Form.Select>
              </Form.Group>
            </Card.Body>
          </Stack>
        </Card>

        <Card>
          <Card.Header as="h6">
            <center>WEST vs EAST</center>
          </Card.Header>

          <Stack direction="horizontal" gap={1}>
            <Card.Body>
              <Form.Group className="mb-2" controlId="formWestLevel">
                <Form.Label style={{ fontSize: 14, fontWeight: "bold" }}>
                  CHECK WEST LEVEL
                </Form.Label>
                <Form.Select
                  aria-label="west"
                  size="lg"
                  value={westLevel}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setWestLevel(e.target.value);
                  }}
                >
                  <option key={0} value={""}>
                    West Leveling
                  </option>

                  <option key={1} value={0}>
                    LOW
                  </option>
                  <option key={2} value={1}>
                    HIGH
                  </option>
                </Form.Select>
              </Form.Group>
            </Card.Body>

            {"  "}
            <Badge pill bg="secondary">
              <h6>VS</h6>
            </Badge>
            {"  "}
            <Card.Body>
              <Form.Group className="mb-2" controlId="formEastLevel">
                <Form.Label style={{ fontSize: 14, fontWeight: "bold" }}>
                  CHECK EAST LEVEL
                </Form.Label>
                <Form.Select
                  aria-label="east"
                  size="lg"
                  value={eastLevel}
                  onChange={(e) => {
                    console.log(e.target.value);
                    setEastLevel(e.target.value);
                  }}
                >
                  <option key={0} value={""}>
                    East Leveling
                  </option>

                  <option key={1} value={0}>
                    LOW
                  </option>
                  <option key={2} value={1}>
                    HIGH
                  </option>
                </Form.Select>
              </Form.Group>
            </Card.Body>
          </Stack>
        </Card>
      </Stack>

      <div className="my-4">
        <center>
          {!loading ? (
            <Button variant="outline-warning" style={{borderRadius:50}} onClick={levelAllSubmit}>
              SUBMIT
            </Button>
          ) : (
            <Button variant="outline-warning" style={{borderRadius:50}} disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Loading...
            </Button>
          )}
        </center>
      </div>
    </Container>
  );
};

export default HomeLevel;
