import React from "react";
import { Routes, Route } from "react-router-dom";
import NoMatch from "../pages/Nopage";
import Admin from "../pages/Admin";
import AdminProtectedRoute from "../component/AdminProtectedRoute";
import AdminDash from "../pages/AdminDash";
import AdminAddRole from "../pages/AdminAddRole";
import AudioCallAdmin from "../pages/AudioCallAdmin";

const AdminRoute = () => {
  return (
    <Routes>
      <Route exact index element={<Admin />} />
      <Route exact path="admindash" element={<AdminDash />} />
      <Route exact path="adminaddrole" element={<AdminAddRole />} />
      <Route exact path="adminprofile" element={<AdminDash />} />
      <Route exact path="adminaudiocall" element={<AudioCallAdmin />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  );
};

export default AdminRoute;
