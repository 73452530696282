const ObjectEmpty = (objectName) => {
  if (
    objectName === null ||
    objectName === undefined
  ) {
    return true;
  } else if (Object.keys(objectName).length === 0) {
    return true;
  } else {
    return false;
  }
};
export default ObjectEmpty;
