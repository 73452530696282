import React, { useEffect } from "react";
import {
  Container,
  Card,
  Row,
  Col,
  ListGroup,
  Badge,
  Button,
} from "react-bootstrap";

const paymentresponse = () => {
  const cardBody = { overflowY: "scroll", height: 250, };
  return (
    <Container>
      <Row className=" text-center g-1">
        <Col sm={12} lg={6}>
          <Card>
            <Card.Header as={"h4"}>INDIVIDUALS</Card.Header>
            <Card.Body>
              <Row className="g-1">
                <Col sm={6} lg={6}>
                  <Card>
                    <Card.Header as={"h5"}>ONE TIME PLAN</Card.Header>
                    <Card.Body style={cardBody}>
                      <ListGroup>
                        <ListGroup.Item
                          as="li"
                          className="d-flex justify-content-between align-items-center"
                        >
                          <div>
                            <div className="fw-bold">Vasthu For Home</div>
                            <h6 className="card-text">
                              ₹144 <s>400</s>
                            </h6>
                          </div>
                          <Button
                            className="ml-auto"
                            variant="primary"
                            size="md"
                          >
                            Buy
                          </Button>
                        </ListGroup.Item>
                        <ListGroup.Item
                          as="li"
                          className="d-flex justify-content-between align-items-center"
                        >
                          <div>
                            <div className="fw-bold">Vasthu For Plot</div>
                            <h6 className="card-text">
                              ₹144 <s>400</s>
                            </h6>
                          </div>
                          <Button
                            className="ml-auto"
                            variant="primary"
                            size="md"
                          >
                            Buy
                          </Button>
                        </ListGroup.Item>
                        <ListGroup.Item
                          as="li"
                          className="d-flex justify-content-between align-items-start"
                        >
                          <div className="text-center align-items-center">
                            <div className="fw-bold">Consultant</div>
                            <h6 className="card-text">
                              ₹144 <s>400</s>
                            </h6>
                          </div>
                          <Button
                            className="ml-auto"
                            variant="primary"
                            size="md"
                          >
                            Buy
                          </Button>
                        </ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={6} lg={6}>
                  <Card>
                    <Card.Header as={"h5"}>COMBOS PLAN</Card.Header>
                    <Card.Body style={cardBody}>
                      <ListGroup>
                        <ListGroup.Item
                          as="li"
                          className="d-flex justify-content-between align-items-center"
                        >
                          <div>
                            <div className="fw-bold">Vasthu For Home</div>
                            <h6 className="card-text">
                              ₹144 <s>400</s>
                            </h6>
                          </div>
                          <Button
                            className="ml-auto"
                            variant="primary"
                            size="md"
                          >
                            Buy
                          </Button>
                        </ListGroup.Item>
                        <ListGroup.Item
                          as="li"
                          className="d-flex justify-content-between align-items-center"
                        >
                          <div>
                            <div className="fw-bold">Vasthu For Plot</div>
                            <h6 className="card-text">
                              ₹144 <s>400</s>
                            </h6>
                          </div>
                          <Button
                            className="ml-auto"
                            variant="primary"
                            size="md"
                          >
                            Buy
                          </Button>
                        </ListGroup.Item>
                        <ListGroup.Item
                          as="li"
                          className="d-flex justify-content-between align-items-start"
                        >
                          <div className="text-center align-items-center">
                            <div className="fw-bold">Consultant</div>
                            <h6 className="card-text">
                              ₹144 <s>400</s>
                            </h6>
                          </div>
                          <Button
                            className="ml-auto"
                            variant="primary"
                            size="md"
                          >
                            Buy
                          </Button>
                        </ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <Button variant="outline-warning">Know More</Button>
            </Card.Footer>
          </Card>
        </Col>
        <Col sm={12} lg={6}>
          <Card>
            <Card.Header as={"h4"}>PERIODIC</Card.Header>
            <Card.Body>
              <Row className="g-1">
                <Col sm={6} lg={6}>
                  <Card>
                    <Card.Header as={"h5"}>ONE TIME PLAN</Card.Header>
                    <Card.Body style={cardBody}>
                      <ListGroup>
                        <ListGroup.Item
                          as="li"
                          className="d-flex justify-content-between align-items-center"
                        >
                          <div>
                            <div className="fw-bold">Vasthu For Home</div>
                            <h6 className="card-text">
                              ₹144 <s>400</s>
                            </h6>
                          </div>
                          <Button
                            className="ml-auto"
                            variant="primary"
                            size="md"
                          >
                            Buy
                          </Button>
                        </ListGroup.Item>
                        <ListGroup.Item
                          as="li"
                          className="d-flex justify-content-between align-items-center"
                        >
                          <div>
                            <div className="fw-bold">Vasthu For Plot</div>
                            <h6 className="card-text">
                              ₹144 <s>400</s>
                            </h6>
                          </div>
                          <Button
                            className="ml-auto"
                            variant="primary"
                            size="md"
                          >
                            Buy
                          </Button>
                        </ListGroup.Item>
                        <ListGroup.Item
                          as="li"
                          className="d-flex justify-content-between align-items-start"
                        >
                          <div className="text-center align-items-center">
                            <div className="fw-bold">Consultant</div>
                            <h6 className="card-text">
                              ₹144 <s>400</s>
                            </h6>
                          </div>
                          <Button
                            className="ml-auto"
                            variant="primary"
                            size="md"
                          >
                            Buy
                          </Button>
                        </ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={6} lg={6}>
                  <Card>
                    <Card.Header as={"h5"}>COMBOS PLAN</Card.Header>
                    <Card.Body style={cardBody}>
                      <ListGroup>
                        <ListGroup.Item
                          as="li"
                          className="d-flex justify-content-between align-items-center"
                        >
                          <div>
                            <div className="fw-bold">Vasthu For Home</div>
                            <h6 className="card-text">
                              ₹144 <s>400</s>
                            </h6>
                          </div>
                          <Button
                            className="ml-auto"
                            variant="primary"
                            size="md"
                          >
                            Buy
                          </Button>
                        </ListGroup.Item>
                        <ListGroup.Item
                          as="li"
                          className="d-flex justify-content-between align-items-center"
                        >
                          <div>
                            <div className="fw-bold">Vasthu For Plot</div>
                            <h6 className="card-text">
                              ₹144 <s>400</s>
                            </h6>
                          </div>
                          <Button
                            className="ml-auto"
                            variant="primary"
                            size="md"
                          >
                            Buy
                          </Button>
                        </ListGroup.Item>
                        <ListGroup.Item
                          as="li"
                          className="d-flex justify-content-between align-items-start"
                        >
                          <div className="text-center align-items-center">
                            <div className="fw-bold">Consultant</div>
                            <h6 className="card-text">
                              ₹144 <s>400</s>
                            </h6>
                          </div>
                          <Button
                            className="ml-auto"
                            variant="primary"
                            size="md"
                          >
                            Buy
                          </Button>
                        </ListGroup.Item>
                      </ListGroup>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer>
              <Button variant="light">Know More</Button>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default paymentresponse;
