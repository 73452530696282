import React, { useContext } from "react";
import { Box, CircularProgress, Fab } from "@mui/material";
import { useEffect, useState } from "react";
import { Check, Save } from "@mui/icons-material";
import theme from "./theme";
import { CrmAccountContext } from "../../pages/crm/Accounts";
import { vasthuUrl } from "../Api";

const UpdateActions = ({ params }) => {
  const { setUserUpdateList, setMessageType, alert } = useContext(
    CrmAccountContext
  );

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [rowId, setRowId] = useState(null);

  const handleSubmit = async () => {
    setLoading(true);
    const updatingUser = params.row;
    setRowId(updatingUser.id);
    console.log("selected User for Update", updatingUser);
    const res = await vasthuUrl.post(`vasthu/user/updateuser`, {
      body: JSON.stringify({
        id: updatingUser.id,
        userName: updatingUser.name,
        phone_no: updatingUser.phoneNumber,
        email: updatingUser.email,
        uuid: updatingUser.uuid,
      }),
    });
    console.log("POST res", res);
    if (res.status == 200) {
      setSuccess(true);
      alert("User Updated successfully");
      setMessageType("success");
      setRowId(null);
      setLoading(false);
      setUserUpdateList(true);
    }
  };

  return (
    <Box
      sx={{
        m: 1,
        position: "relative",
      }}
    >
      {success ? (
        <Fab
          color="primary"
          sx={{
            width: 40,
            height: 40,
            bgcolor: theme.palette.primary,
            "&:hover": { bgcolor: theme.palette.primary.dark },
          }}
        >
          <Check
            sx={{
              color: "linen",
            }}
          />
        </Fab>
      ) : (
        <Fab
          color="primary"
          sx={{
            width: 40,
            height: 40,
            bgcolor: theme.palette.primary,
            "&:hover": { bgcolor: theme.palette.primary.dark },
          }}
          onClick={handleSubmit}
        >
          <Save
            sx={{
              color: "black",
            }}
          />
        </Fab>
      )}
      {loading && (
        <CircularProgress
          size={52}
          sx={{
            color: theme.palette.secondary.main,
            position: "absolute",
            top: -6,
            left: -6,
            zIndex: 1,
          }}
        />
      )}
    </Box>
  );
};

export default UpdateActions;
