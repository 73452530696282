import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import ProfAppointHistory from "./ProfAppointHistory";
import ProfCreateSlot from "./ProfCreateSlot";
import ProfViewSlot from "./ProfViewSlot";
import ProfessionalEarningReport from "./ProfessionalEarningReport";
import { useTranslation, Trans } from "react-i18next";

const ProfAppointment = () => {
  const { t } = useTranslation();
  const [key, setKey] = useState("appointlist");
  return (
    <React.Fragment>
      <Tabs
        id="controlled-tab-example profAppointment"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="justify-content-center"
        variant="pills"
      >
        <Tab eventKey="appointlist" title={t("PR.APMNT.NATAB")}>
          <center>
            <h4 className="m-1">
              <u>{t("PR.APMNT.NATAB.TL")}</u>
            </h4>
          </center>
          <ProfCreateSlot />
        </Tab>
        <Tab eventKey="appointslotstatus" title={t("PR.APMNT.ASTAB")}>
          <center>
            <h4 className="m-1">
              <u>{t("PR.APMNT.ASTAB.TL")}</u>
            </h4>
          </center>
          <ProfViewSlot />
        </Tab>
        <Tab eventKey="yourmeetings" title={t("PR.APMNT.MTNGTAB")}>
          <center>
            <h4 className="m-1">
              <u>{t("PR.APMNT.MTNGTAB.TL")}</u>
            </h4>
          </center>
          <ProfAppointHistory />
        </Tab>
        {/* <Tab eventKey="earningreport" title={t("PR.APMNT.ERNTAB.CMM")}>
          <center>
            <h4 className="m-1">
              <u>{t("PR.APMNT.ERNTAB.TL")}</u>
            </h4>
          </center>
          <ProfessionalEarningReport />
        </Tab> */}
      </Tabs>
    </React.Fragment>
  );
};

export default ProfAppointment;
