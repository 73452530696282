import React, { useState, useEffect, Suspense } from "react";
import { vasthuUrl } from "../component/Api";
import { BiSolidPhoneCall } from "react-icons/bi";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { Navigate, useNavigate, useLocation } from "react-router-dom";

const AudiocallStart = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const token = window.sessionStorage.getItem("token");
  const uuid = window.sessionStorage.getItem("userId");
  const consultantId = window.sessionStorage.getItem("consultantId");
  const duration = window.sessionStorage.getItem("AudioCallDuration");

  const location = useLocation();
  console.log("location", location);
  const [callStatus, setCallStatus] = useState(
    "Payment Successfull... Call going to proceed"
  );

  const tryAudiocall = async () => {
    try {
      console.log("screen: AudiocallStart.js");
      const res = await vasthuUrl.post(
        `vasthu/user/clicktocall`,
        {
          from: consultantId, //"09790580304",
          to: uuid, //"07092758264",
          duration: parseInt(duration), //30min
          subscriptionId: location.state.id,
          // subscriptionId: parseInt(location.state.additional_info.additional_info2)
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("postresponsefrom audio call", res.data);
      if (res.data == 200) {
        setCallStatus(t("USR.CAL.PROCED.BTN"));
        // navigate("/audiocallstatus", {
        //   state: {
        //     callStatus: "call Proceeding",
        //   },
        // }
        // );
        // vasthuUrl.get(`/subscription/useVasthuOffer/${location.state.offerId}`,{
        vasthuUrl
          .get(`/subscription/useVasthuOffer/${location.state.id}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((rs) =>
            console.log("offer subscription redeem count used", rs.data.message)
          )
          .catch((er) =>
            console.log("offer subscription redeem count not reduced", er)
          );

        // setTimeout(() => {

        //   navigate("/",{replace : true})

        // }, 30000);
      } else {
        setCallStatus(t("USR.CAL.FAILD.BTN"));
        // navigate("/audiocallstatus", {
        //   state: { callStatus: "call Failed" },
        // });
      }
    } catch (err) {
      console.log("SERVER ERROR", err);
    }
  };

  return (
    // <div>{callStatus}</div>

    <>
      <div className="contnent">
        <div className="hlo">
          <h3>{callStatus}...</h3>{" "}
        </div>
        {callStatus == t("USR.CAL.PROCED.BTN") ? (
          <>
            <div class="pluse">
              <BiSolidPhoneCall className="i" size={50} />
            </div>
            <Button variant="outline-warning" onClick={() => navigate(-1)}>
              Close
            </Button>
          </>
        ) : (
          <div>
            <Button
              variant="outline-warning"
              onClick={() => {
                tryAudiocall();
              }}
            >
              Click to Call
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default AudiocallStart;
