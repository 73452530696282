import React from "react";
import Title from "../component/Title";
import { Row, Container } from "react-bootstrap";

const CopyRights = () => {
  return (
    <Container>
      <Title title="Copyrights" />
      <Row
        className="m-1 p-2 border"
        style={{ height: "auto", minHeight: 200 }}
      >
        All articles and programming concept of sites are belonging to us based
        on copy Right act.
      </Row>
    </Container>
  );
};
export default CopyRights;
