import React, { useState } from "react";

const MyAccordian = (props) => {
  const [visibility, setVisibility] = useState(true);
  console.log('props', props)

  return (
    <React.Fragment key={props.key}>


      <div
        className="accordian_base"
        style={{ margin: "2px", padding: "2px", width: "100%" }}
        onClick={() => {
          setVisibility(!visibility);

          document.getElementById(
            props.label + "_content"
          ).style.display = visibility ? "inline-block" : "none";
          document.getElementById(props.label + "_span").style.rotate = "90deg";

          if (visibility === false) {
            document.getElementById(props.label + "_content").style.display =
              "none";
            document.getElementById(props.label + "_span").style.rotate =
              "0deg";
          }
        }}
      >
        <span
          className="accordian_arrow"
          id={props.label + "_span"}
          style={{
            fontSize: 24,
            margin: "2px",
            padding: "2px",
            display: "inline-block",
          }}
        >
          &rsaquo;
        </span>
        <label
          className="accordian_label"
          style={{ margin: "2px", padding: "2px" }}
        >
          {props.label}
        </label>
      </div>
      <div
        className="accordian_content"
        id={props.label + "_content"}
        style={{ display: "none", width: "100%" }}
      >
        {props.content}
      </div>
    </React.Fragment>
  );
};

export default MyAccordian;
