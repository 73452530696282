import { HeartFilled } from "@ant-design/icons";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Stacks from "@mui/material/Stack";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IoCall } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { vasthuUrl } from "../../component/Api";

function UserLikedProduct() {
  const [data, setData] = useState([]);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const token = window.sessionStorage.getItem("token");
  const userId = window.sessionStorage.getItem("userId");
  const [loading, setLoading] = useState(false);
  const lang = localStorage.getItem("i18nextLng");

  //toast aleart
  const [openSnack, setOpenSnack] = React.useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("error");

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const alert = (msg) => {
    setMessage(msg);
    setOpenSnack(true);
    setTimeout(() => {
      setMessage("");
    }, 4000);
  };

  // BOOK PRODUCT MODAL
  const [bookModal, setBookModel] = useState(false);
  const [productId, setProductId] = useState("error");

  const bookModalClose = () => {
    setBookModel(false);
    // setDeleteId("");
  };

  const bookModalOpen = (i) => {
    setBookModel(true);
    setProductId(i);
  };

  useEffect(() => {
    setLoading(true);
    try {
      vasthuUrl
        .get(`vasthu/getAllUserLikedProductsbyUserId/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setData(res.data);
          setLoading(false);
        });
    } catch (error) {
      console.log("error2", error);
      setLoading(false);
    }
  }, []);

  const handleBook = async () => {
    try {
      await vasthuUrl
        .post(
          `vasthu/user/product/saveUserBookedProduct`,
          {
            productId: productId,
            userId: userId,
            isproductbooked: true,
            lng: lang,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          alert("Booked Product Successfully!!!");
          setMessageType("success");
          // setTimeout(() => {
          //   navigate("/bookedproduct");
          // }, 2000);
          bookModalClose();
        });
    } catch (err) {
      alert(err);
      setMessageType("error");
    }
  };

  const handleContact = async () => {
    try {
      await vasthuUrl
        .post(
          `vasthu/callFromUserToAdminForProductBooking`,
          {
            from: userId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          alert("Booked Product Successfully!!!");
          setMessageType("success");
          if (res.status == 200) {
            navigate("/audiocallstatus", {
              state: { callStatus: "USR.CAL.PROCED.BTN" },
            });
          } else {
            navigate("/audiocallstatus", {
              state: { callStatus: "USR.CAL.FAILD.BTN" },
            });
          }
        });
    } catch (err) {
      alert(err);
      setMessageType("error");
    }
  };

  return (
    <React.Fragment>
      <center>
        <h2>
          <u>{t("USR.PRO.LIK.TL")}</u>
        </h2>
      </center>
      {!loading ? (
        data.map((i, j) => (
          <Card key={j} className="m-2 p-2 all_border">
            <Row style={{ alignItems: "center" }}>
              <Col
                sm={12}
                md={3}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Card.Img
                  id="mainimg"
                  src={i.productimgUrl1}
                  style={{
                    height: "100px",
                    width: "150px",
                    objectFit: "contain",
                    margin: "5px",
                  }}
                />
              </Col>
              <Col sm={12} md={7}>
                <Row style={{ display: "flex" }}>
                  <Col xs={6} md={4} lg={3}>
                    <Card.Text style={{ fontWeight: "bold" }}>
                      {t("USR.LIK.PRO")}
                    </Card.Text>
                    <Card.Text style={{ fontWeight: "bold" }}>
                      {t("USR.PRO.BOK.TYP")}
                    </Card.Text>
                    <Card.Text style={{ fontWeight: "bold" }}>
                      {t("TXT.DES.LO")}
                    </Card.Text>
                  </Col>
                  <Col xs={6} md={8} lg={9}>
                    <Card.Text>
                      <b>:</b>&nbsp;&nbsp;{i.productName}
                    </Card.Text>
                    <Card.Text>
                      <b>:</b>&nbsp;&nbsp;{i.productType}
                    </Card.Text>
                    <Card.Text>
                      <b>:</b>&nbsp;&nbsp;{i.productShortDescription}
                    </Card.Text>
                  </Col>
                </Row>
                <div
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                >
                  <Button
                    className="all_btn"
                    // onClick={() => {
                    //   handleBook(i.productId);
                    // }}
                    onClick={() => {
                      bookModalOpen(i.productId);
                    }}
                  >
                    {t("USR.HOM.CL.ACTION2")}
                  </Button>
                  <OverlayTrigger
                    key="top"
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-top`}>+91 9363919113</Tooltip>
                    }
                  >
                    {/* <Button className="all_btn">
                      <a
                        style={{ textDecoration: "none", color: "black" }}
                        href="tel:+9363919113"
                      >
                        {t("TXT.CONCT.US.LO")} <IoCall size={20} />
                      </a>
                    </Button> */}
                    <Button
                      className="all_btn"
                      onClick={() => {
                        handleContact();
                      }}
                    >
                      {t("TXT.CONCT.US.LO")} <IoCall size={20} />
                    </Button>
                  </OverlayTrigger>
                </div>
              </Col>
              <Col
                sm={12}
                md={2}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <HeartFilled style={{ color: "red" }} />
              </Col>
            </Row>
          </Card>
        ))
      ) : (
        <center>
          <Spinner animation="border" variant="warning" />
        </center>
      )}

      <Modal className="all_border" show={bookModal} onHide={bookModalClose}>
        <Modal.Header className="all_background">
          <center>
            <b>{t("USR.PRO.BK.MDL.HD")}</b>
          </center>
        </Modal.Header>
        <Modal.Body>{t("USR.PRO.BK.MDL.MSG")}</Modal.Body>
        <Modal.Footer className="d-flex justify-content-evenly all_border">
          <Button
            className="all_btn mb-2"
            onClick={() => {
              bookModalClose();
            }}
          >
            {t("USR.VSH.HFTAB.MDL.CNF.BTN.CNC")}
          </Button>
          <Button
            className="all_btn mb-2"
            onClick={() => {
              handleBook();
            }}
          >
            {t("USR.HOM.CL.ACTION2")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Stacks spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleCloseSnack}
        >
          <MuiAlert
            variant="filled"
            onClose={handleCloseSnack}
            severity={messageType}
            sx={{
              width: "100%",
            }}
          >
            {message}
          </MuiAlert>
        </Snackbar>
      </Stacks>
    </React.Fragment>
  );
}

export default UserLikedProduct;
