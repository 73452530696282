// import React from "react";
// // import { NavLink } from "react-router-dom";
// import { UseAuth } from "../context/Auth";
// import Header from "../component/Header";
// import Carousel from "../component/Carousel";

// const Home = () => {
//   const { token } = UseAuth();
//   console.log(token);
//   return (
//     <React.Fragment>
//       <Header title='VASTHU SASTRA' breakpionts='lg' navList={[{link:'login',pageName:'LOGIN'}, {link:'register', pageName:'REGISTER'}]} />
//       <Carousel />
//     </React.Fragment>
//   );
// };

// export default Home;

import React, { useState, useEffect } from "react";
// import { NavLink } from "react-router-dom";
import { UseAuth } from "../context/Auth";
import Header from "../component/Header";
import Carousel from "../component/Carousel";
import HomeAccordian from "../component/HomeAccordian";
import HomeFooterInfo from "../component/HomeFooterInfo";

import HomeConsultant from "../component/HomeConsultant";

import HomeTestimonials from "../component/HomeTestimonials";
import { Card, Button } from "react-bootstrap";
import JothiButton from "../component/JothiButton";
import HomeMarquee from "../component/HomeMarquee";
import HomeServices from "../component/HomeServices";
import HomePageOffers from "../component/HomePageOffers";
import HomeUserReview from "../component/HomeUserReview";
import Axios from "axios";
import HomeServicePlans from "../component/HomeServicePlan";

import { vasthuUrl } from "../component/Api";
import ScrollButton from "./ScrollButton";
import BasicSpeedDial from "./BasicSpeedDial";
import OurProducts from "./OurProducts";
import OurServicesConsultant from "./OurServicesConsultant";
import WRTCHomePage from "./WRTCHomePage";

const Home = () => {
  return (
    <React.Fragment>
      {/* <Header breakpionts="lg" /> */}
      <HomeMarquee />
      {/* <HomeMarquee /> */}
      <Carousel />
      {/* <WRTCHomePage /> */}
      <HomeServices />
      <HomePageOffers />
      <OurServicesConsultant />
      <HomeConsultant />
      {/* <HomeServicePlans /> */}
      <OurProducts />
      
      <HomeTestimonials />
      {/* <HomeUserReview /> */}
      {/* <HomeTestimonials />
    
      <HomeAccordian /> */}
      <HomeFooterInfo />
      <div
        style={{
          position: "fixed",
          top: "75%",
          width: "100%",
          height: "100%",
        }}
      >
        <div
          style={{
            // backgroundColor: 'aquamarine',
            float: "right",
            padding: "7px",
            borderRadius: "7px",
            marginRight: "5px",
          }}
        >
          {/* <FiPhoneCall />+919025028213 */}
          <BasicSpeedDial />
          <ScrollButton />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;
