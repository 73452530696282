import React, { useEffect, useState } from "react";
import TableDesk from "../component/DeskTable";
import MobileCard from "../component/MobileCard";
import { vasthuUrl } from "../component/Api";
import { UseAuth } from "../context/Auth";
import { Alert, Spinner } from "react-bootstrap";

const AdminListDetails = () => {
  // const { token } = UseAuth();
  const token = window.sessionStorage.getItem("token");
  const [adminData, setAdminData] = useState([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const alert = (msg) => {
    setMessage(msg);
    setTimeout(() => {
      setMessage("");
    }, 4000);
  };

  useEffect(() => {
    setLoading(true);
    try {
      vasthuUrl
        .get(`/data/getalladmins`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("admin", res.data.message);
          setAdminData(res.data);
          setLoading(false);
        });
    } catch (e) {
      alert("SERVER ERROR");
      setLoading(false);
    }
  }, []);
  return (
    <React.Fragment>
      <center>
        <h4>
          <u>ADMIN DETAILS</u>
        </h4>
      </center>
      {message && (
        <Alert variant="info" className="text-center">
          {message}
        </Alert>
      )}
      {!loading ? (
        adminData.length > 0 ? (
          <>
            <TableDesk data={adminData} />
            <MobileCard data={adminData} />
          </>
        ) : (
          <center>
            <h5>No Admin Found</h5>
          </center>
        )
      ) : (
        <center>
          <Spinner animation="border" variant="warning" />
        </center>
      )}
    </React.Fragment>
  );
};
export default AdminListDetails;
