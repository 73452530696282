import React, {
  createContext,
  useContext,
  useState,
  useMemo,
  useEffect,
} from "react";
import { useLocation, useNavigate, Redirect } from "react-router-dom";
import { vasthuUrl } from "../component/Api";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const iniLang = localStorage.getItem("i18nextLng");
  const savedToken = window.sessionStorage.getItem("token") || "";
  const savedRole = window.sessionStorage.getItem("role") || "";
  const savedUserId = window.sessionStorage.getItem("userId") || "";
  const [token, setToken] = useState(savedToken);
  const [authname, setAuthName] = useState("");
  const [error, setError] = useState("");
  const [userId, setUserId] = useState(savedUserId);
  const [role, setRole] = useState(savedRole);
  const [isAuthenticated, setIsauthenticated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingInitial, setLodingInitial] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const rerender = window.sessionStorage.getItem("rerender");
  console.log("rerender", rerender);
  console.log("iniLang", iniLang);

  const alert = (msg) => {
    setError(msg);

    setTimeout(() => {
      setError("");
    }, 4000);
  };

  const handleLogin = async (user, callback) => {
    console.log("logging in", location);
    setLoading(true);
    try {
      const res = await vasthuUrl.post(`api/auth/signin`, {
        phoneNumber: user.phone,
        password: user.password,
      });
      if (res.data.accessToken && res.data.authorities[0].authority) {
        callback("success");
        setToken(res.data.accessToken);
        setIsauthenticated(true);
        console.log(res.data.accessToken);
        setAuthName(res.data.username);
        setUserId(res.data.uuid);
        setRole(res.data.authorities[0].authority);
        setLoading(false);
        setError(null);
        window.sessionStorage.setItem("token", res.data.accessToken);
        window.sessionStorage.setItem("authname", res.data.username);
        window.sessionStorage.setItem(
          "role",
          res.data.authorities[0].authority
        );
        window.sessionStorage.setItem("userId", res.data.uuid);
        switch (res.data.authorities[0].authority) {
          case `${process.env.REACT_APP_VASTHU_USER}`:
            if (rerender) {
              navigate(rerender, { replace: true });
            } else {
              navigate(user.path, { replace: true });
            }
            return;
          case `${process.env.REACT_APP_VASTHU_PROFESSIONAL}`:
            return navigate("/", { replace: true });
          case `${process.env.REACT_APP_VASTHU_ADMIN}`:
            return navigate("/", { replace: true });
          case `${process.env.REACT_APP_VASTHU_CRM}`:
            return navigate("/", { replace: true });
        }
      } else {
        callback("fail");
        alert(res.data.message);
        setLoading(false);
      }
    } catch (e) {
      callback("fail");
      alert("SERVER ERROR");
      console.log("CHECK INTERNET CONNECTION", e);
      setLoading(false);
    }
  };
  const handleSocialLogin = async (user, callback) => {
    console.log("logging in", location);
    setLoading(true);
    try {
      const res = await vasthuUrl.post(`api/auth/socialotp`, {
        phone_no: user.phone,
        otp: user.loginOTP,
      });
      if (res.data.accessToken && res.data.authorities[0].authority) {
        callback("success");
        setToken(res.data.accessToken);
        setIsauthenticated(true);
        console.log(res.data.accessToken);
        setAuthName(res.data.username);
        setUserId(res.data.uuid);
        setRole(res.data.authorities[0].authority);
        setLoading(false);
        setError(null);
        window.sessionStorage.setItem("token", res.data.accessToken);
        window.sessionStorage.setItem("authname", res.data.username);
        window.sessionStorage.setItem(
          "role",
          res.data.authorities[0].authority
        );
        window.sessionStorage.setItem("userId", res.data.uuid);
        switch (res.data.authorities[0].authority) {
          case `${process.env.REACT_APP_VASTHU_USER}`:
            if (rerender) {
              navigate(rerender, { replace: true });
            } else {
              navigate(user.path, { replace: true });
            }
            return;
          case `${process.env.REACT_APP_VASTHU_PROFESSIONAL}`:
            return navigate("/", { replace: true });
          case `${process.env.REACT_APP_VASTHU_ADMIN}`:
            return navigate("/", { replace: true });
          case `${process.env.REACT_APP_VASTHU_CRM}`:
            return navigate("/", { replace: true });
        }
      } else {
        callback("fail");
        alert(res.data.message);
        setLoading(false);
      }
    } catch (e) {
      callback("fail");
      alert("SERVER ERROR");
      console.log("CHECK INTERNET CONNECTION", e);
      setLoading(false);
    }
  };

  const handleRegister = async (data, callback) => {
    setLoading(true);
    try {
      const res = await vasthuUrl.post(`vasthu/user/register`, {
        userName: data.user,
        phone_no: data.phone,
        email: data.email,
        password: data.pass,
        lng: data.lng,
      });
      console.log(
        "regiser",
        res.data.message === process.env.REACT_APP_SUCESS_REGISTER
      );
      if (res.data.message === process.env.REACT_APP_SUCESS_REGISTER) {
        callback("success");
        // setError(null);
        setIsauthenticated(true);
        setTimeout(() => {
          navigate("/login", { replace: true });
        }, 1000);
        setLoading(false);
      } else {
        callback("fail");
        setError(res.data.message);
        setLoading(false);
      }
    } catch (err) {
      callback("fail");
      console.log("SERVER ERROR", err);
      setError("CHECK INTERNET CONNECTION");
      setLoading(false);
    }
  };

  const handleLogout = () => {
    sessionStorage.clear(); // Clearing the session storage
    // window.sessionStorage.removeItem("token");
    // window.sessionStorage.removeItem("role");
    // window.sessionStorage.removeItem("userId");
    // window.sessionStorage.removeItem("rerender");
    setToken(null);
    setAuthName(null);
    setRole(null);
    setUserId(null);
    setIsauthenticated(false);
    setLoading(false);
    navigate("/", { replace: true });
  };

  const memoedValue = useMemo(
    () => ({
      token,
      isAuthenticated,
      role,
      userId,
      error,
      handleLogin,
      handleSocialLogin,
      handleRegister,
      loading,
      loadingInitial,
      onLogout: handleLogout,
      authname,
      setToken,
    }),
    [token, role, userId, loading, error, authname, setToken]
  );

  return (
    <AuthContext.Provider value={memoedValue}>{children}</AuthContext.Provider>
  );
};

export const UseAuth = () => {
  return useContext(AuthContext);
};
