import React, { useEffect, useState } from "react";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Stacks from "@mui/material/Stack";

function Toast({ msg, type, trigger }) {
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [openSnack, setOpenSnack] = React.useState(false);

  useEffect(() => {
    if (msg && type) {
      setMessage(msg);
      setMessageType(type);
      setOpenSnack(true);
      setTimeout(() => {
        setMessage("");
        setMessageType("");
      }, 4000);
      console.log("Toast Trigger");
    }
  }, [msg, type, trigger]);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  return (
    <div>
      <Stacks spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleCloseSnack}
        >
          <MuiAlert
            variant="filled"
            onClose={handleCloseSnack}
            severity={messageType}
            sx={{
              width: "100%",
            }}
          >
            {message}
          </MuiAlert>
        </Snackbar>
      </Stacks>
    </div>
  );
}

export default Toast;
