// import React, { useEffect, useState } from "react";
// import { Button, Card, Container, Spinner, TabContainer } from "react-bootstrap";
// import Box from "@mui/material/Box";
// import { DataGrid } from "@mui/x-data-grid";
// import { Grid } from "@mui/material";
// import { Navigate, useNavigate, useLocation } from "react-router-dom";
// import UsersList from "./UsersList";

// function Payments() {
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const [data, setData] = useState([]);
//   const navigate = useNavigate();

//   useEffect(() => {
//     // Simulate fetching data
//     setTimeout(() => {
//       try {
//         const rows = [
//           {
//             id: 1,
//             OfferName: "Vasthu Audio Consultation",
//             firstName: "Jon",
//             age: 14,
//             transaction: "T1234-5678-9012-3456",
//             amount: "5500",
//             date: "2024-04-03 15:53",
//             email: "jo1@gmail.com",
//             status: "success",
//           },
//           {
//             id: 2,
//             OfferName: "Vasthu Audio Consultation",
//             firstName: "Cersei",
//             transaction: "T1234-5678-9012-3456",
//             amount: "1500",
//             date: "2024-04-03 15:53",
//             email: "jo2@gmail.com",
//             status: "success",
//           },
//           {
//             id: 3,
//             OfferName: "Direct-Call",
//             firstName: "Jaime",
//             transaction: "T1234-5678-9012-3456",
//             amount: "2500",
//             date: "2024-04-03 15:53",
//             email: "jo3@gmail.com",
//             status: "success",
//           },
//           {
//             id: 4,
//             OfferName: "Direct-Call",
//             firstName: "Arya",
//             transaction: "T1234-5678-9012-3456",
//             amount: "3500",
//             date: "2024-04-03 15:53",
//             email: "jo4@gmail.com",
//             status: "success",
//           },
//           {
//             id: 5,
//             OfferName: "Direct-Call",
//             firstName: "Daenerys",
//             transaction: "T1234-5678-9012-3456",
//             amount: "2500",
//             date: "2024-04-03 15:53",
//             email: "jo5@gmail.com",
//             status: "success",
//           },
//           {
//             id: 6,
//             OfferName: "Astrology AudioConsultation",
//             firstName: null,
//             transaction: "T1234-5678-9012-3456",
//             amount: "3200",
//             date: "2024-04-03 15:53",
//             email: "jo6@gmail.com",
//             status: "success",
//           },
//           {
//             id: 7,
//             OfferName: "Direct-Call",
//             firstName: "Ferrara",
//             transaction: "T1234-5678-9012-3456",
//             amount: "6200",
//             date: "2024-04-03 15:53",
//             email: "jo7@gmail.com",
//             status: "success",
//           },
//           {
//             id: 8,
//             OfferName: "Vasthu Audio Consultation",
//             firstName: "Rossini",
//             transaction: "T1234-5678-9012-3456",
//             amount: "1050",
//             date: "2024-04-03 15:53",
//             email: "jo8@gmail.com",
//             status: "success",
//           },
//           {
//             id: 9,
//             OfferName: "Vasthu Audio Consultation",
//             firstName: "Harvey",
//             transaction: "T1234-5678-9012-3456",
//             amount: "2300",
//             date: "2024-04-03 15:53",
//             email: "jo9@gmail.com",
//             status: "success",
//           },
//         ];
//         setData(rows);
//         setLoading(false);
//       } catch (error) {
//         setError("Error fetching data");
//         setLoading(false);
//       }
//     }, 1000); // Simulate 1 second delay
//   }, []);

//   if (loading) {
//     return (
//       <Container className="d-flex justify-content-center align-items-center vh-100 mt-3">
//         <Spinner variant="warning" animation="border" role="status">
//           <span className="visually-hidden">Loading...</span>
//         </Spinner>
//       </Container>
//     );
//   }

//   if (error) {
//     return <div>Error: {error}</div>;
//   }

//   const columns = [
//     { field: "id", headerName: "ID", width: 60 },
//     {
//       field: "OfferName",
//       headerName: "OfferName",
//       width: 200,
//       editable: true,
//     },
//     { field: "email", headerName: "Email", width: 150, editable: true },
//     {
//       field: "amount",
//       headerName: "Amount",
//       type: "number",
//       width: 60,
//       editable: true,
//       amount: "2500",
//     },
//     {
//       field: "date",
//       headerName: "Purchased Date",
//       type: "number",
//       width: 200,
//       editable: true,
//       amount: "1500",
//     },
//     {
//       field: "transaction",
//       headerName: "Order Id",
//       type: "number",
//       width: 210,
//       editable: true,
//       amount: "1200",
//     },
//     {
//       field: "status",
//       headerName: "Transaction status",
//       type: "number",
//       width: 150,
//       editable: true,
//       amount: "1200",
//     },
//   ];

//   return (
//     <Container>
//       <center className="mt-2">
//         <h4>
//           <u>Payment Details</u>
//         </h4>
//       </center>
//       {/* <UsersList/> */}
//       <Grid container className="deskTable datagrid">
//         <DataGrid
//           rows={data}
//           columns={columns}
//           pageSize={5}
//           initialState={{
//             pagination: {
//               paginationModel: { page: 0, pageSize: 5 },
//             },
//           }}
//           // checkboxSelection
//           disableSelectionOnClick
//         />
//       </Grid>
//       <div className="d-flex justify-content-end mr-3">
//         <Button onClick={() => navigate(-1)} className="all_btn">
//           Back
//         </Button>
//       </div>

//       <div className="justify-content-center mobileCard">
//         <Card
//           className="mobileCard all_border"
//           style={{
//             width: "100%",
//             marginBottom: "10px",
//             backgroundColor: "antiquewhite",
//           }}
//         >
//           <Card.Body>
//             <Card.Subtitle className="mb-2">
//               <b>Id:</b>11
//             </Card.Subtitle>
//             <Card.Subtitle className="mb-2">
//               <b> OfferName:</b>John
//             </Card.Subtitle>
//             <Card.Subtitle className="mb-2">
//               <b>Email:</b>john@gmail.com
//             </Card.Subtitle>
//             <Card.Subtitle className="mb-2">
//               <b>Amount:</b>1200
//             </Card.Subtitle>
//             <Card.Subtitle className="mb-2">
//               <b>Purchased Date:</b>2024-04-03 15:53
//             </Card.Subtitle>
//             <Card.Subtitle className="mb-2">
//               <b>Order Id:</b>T1234-5678-9012-3456
//             </Card.Subtitle>
//             <Card.Subtitle className="mb-2">
//               <b>Status:</b>success
//             </Card.Subtitle>
//           </Card.Body>
//         </Card>
//       </div>
//     </Container>
//   );
// }

// export default Payments;

import React, { createContext, useState } from "react";

//custom - Vasthuparam

import { Grid } from "@mui/material";
import { vasthuUrl } from "../../component/Api";
import PaymentListTable from "./PaymentListTable";

export const CrmUserListContext = createContext({});

export default function Payments() {
  const token = window.sessionStorage.getItem("token");

  const [userList, setUserList] = useState([]);
  const [message, setMessage] = useState("");

  const fetchInfo = async () => {
    vasthuUrl
      .get("/data/getallusers", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("RESULT LIST OF USERS", res.data);
        setUserList(res.data);
      })
      .catch((err) => {
        // setMessage(err)
        setMessage("Network error - UserList Not fetched properly");
      });
  };

  React.useEffect(() => {
    fetchInfo();
  }, [setUserList]);

  return (
    <CrmUserListContext.Provider
      value={{ userList, message, setUserList, setMessage, token }}
    >
      <PaymentListTable title="PAYMENTS LIST OF USER" />
    </CrmUserListContext.Provider>
  );
}
