import { format, setHours, setMinutes, setSeconds } from "date-fns";
import Multiselect from "multiselect-react-dropdown";
import React, { useContext, useEffect, useState } from "react";
import {
  Accordion,
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Modal,
  Row,
} from "react-bootstrap";
import DatePicker from "react-date-picker";
import { useTranslation } from "react-i18next";
import { vasthuUrl } from "../component/Api";
import { Context } from "./Service";

function AddService() {
  const userId = window.sessionStorage.getItem("userId");
  const token = window.sessionStorage.getItem("token");
  const { t } = useTranslation();
  const [serviceList, setServiceList] = useState([]);
  const [comboList, setComboList] = useState([]);
  const [serviceData, setServiceData] = useState([]);
  const [validated, setValidated] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  // const [startDate, endDate] = dateRange;
  const [offerModal, setOfferModal] = useState(false);
  const [newData, setNewData] = useState([]);
  const [sumValues, setSumValues] = useState([]);
  const [errMsg, setErrMsg] = useState("");
  const [special, setSpecial] = useState("NORMAL");
  const [frequency, setfrequency] = useState("ONETIME");
  const [validity, setValidity] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [uploadedImage, setUploadedImage] = useState();

  const { setOfferList } = useContext(Context);
  const today = new Date();

  const [callMin, setCallMin] = useState([]);

  useEffect(() => {
    vasthuUrl.get("offer/getAllActiveMinutesOffer").then((res) => {
      console.log("available times", res.data);
      setCallMin(res.data);
    });
  }, []);

  useEffect(() => {
    try {
      vasthuUrl
        .get(`/vasthu/getvasthuservices`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setServiceData(res.data);
          console.log("service", res.data);
        });
    } catch (e) {
      console.log("service", e);
    }
  }, []);

  const handleClose = () => {
    setOfferModal(false);
  };

  const handleCatalogue = () => {
    if (serviceList.length === 1) {
      const serviceObj = serviceData.find((i) => i.id === serviceList[0].id);
      serviceObj.serviceId = serviceObj.id;
      serviceObj.count = 1;
      serviceObj.serviceCostType = "PAID";
      console.log({ serviceObj });
      const obj = {
        offerName: "",
        offerNameTamil: "",
        offerNameHindi: "",
        offerType: "INDIVIDUAL",
        status: "ACTIVE",
        validity: "",
        offerStartDate: "",
        offerEndDate: "",
        offerspecialStartDate: "",
        offerspecialEndDate: "",
        offerFrequency: "",
        userUuid: userId,
        offerPrice: "",
        offerDetails: [serviceObj],
      };
      setComboList(Array(obj));
      console.log(obj);
      setOfferModal(true);
      setSumValues(obj.offerDetails);
    } else if (serviceList.length > 1) {
      serviceList.map(
        (i, j) => (
          (i.count = ""),
          (i.serviceCostType = ""),
          (i.serviceId = i.id),
          (i.discountPercentage = ""),
          (i.discountedPrice = 0),
          (i.actualPrice = i.basePrice),
          (i.duration = i.duration)
        )
      );
      console.log({ serviceList });
      const obj1 = {
        offerName: "",
        offerNameTamil: "",
        offerNameHindi: "",
        offerType: "COMBO",
        status: "ACTIVE",
        validity: "",
        offerStartDate: "",
        offerEndDate: "",
        offerspecialStartDate: "",
        offerspecialEndDate: "",
        offerFrequency: "",
        userUuid: userId,
        offerPrice: "",
        offerDetails: serviceList,
      };
      const updateValue = Array(obj1);
      setComboList(Array(obj1));
      console.log(updateValue);
      setOfferModal(true);
      setSumValues(obj1.offerDetails);
    } else {
      setComboList([]);
      serviceAlert("Please Select Any Offer");
    }
  };

  const serviceAlert = (msg) => {
    setErrMsg(msg);
    setTimeout(() => {
      setErrMsg("");
    }, 3000);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        setValidated(true);
      } else {
        const {
          offerName,
          offerNameTamil,
          offerNameHindi,
          offerType,
          offerDetails,
          offerFrequency,
          offerStartDate,
          offerEndDate,
          offerspecialStartDate,
          offerspecialEndDate,
          offerPrice,
          offerDiscount,
          validity,
          userUuid,
          status,
          otype = "NORMAL",
          description,
          uploadedImage,
        } = comboList[0];
        console.log("before save", comboList[0]);
        const res = await vasthuUrl.post(
          "offer/savevasthuoffer",
          {
            offerName,
            offerNameTamil,
            offerNameHindi,
            offerType,
            offerDetails,
            offerFrequency,
            offerStartDate,
            offerEndDate,
            // offerspecialStartDate,
            // offerspecialEndDate,
            offerPrice,
            offerDiscount,
            validity,
            userUuid,
            status,
            otype,
            description,
            uploadedImage,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("res.data", res.data);
        serviceAlert(res.data.message);
        setOfferModal(false);
        setValidated(false);
        setComboList([]);
        setUploadedImage();
        setStartDate("");
        setEndDate("");
        setServiceList([]);
        setDateRange([null, null]);
        handleImageUpload();
        try {
          vasthuUrl
            .get(`/offer/getVasthuAllCreatedOffersList`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => {
              if (res.data.length > 0) {
                res.data.map((i, j) => (i.edit = true));
                setOfferList(res.data);
                console.log("created offers", res.data);
              } else {
                setOfferList([]);
                console.log("created offers", []);
              }
            });
        } catch (e) {
          console.log("getAllCreatedOffers", e);
        }
      }
    } catch (err) {
      serviceAlert("Server Error");
      console.log("handleService Save", err);
    }
  };

  const handleOnChange = (i) => {
    const newData = [...serviceList];
    setNewData(
      newData.map((value, j) => {
        if (j === i) {
          return value;
        }
        return {
          newData,
        };
      })
    );
  };

  let sumdis = 0;
  let sumprice = 0;
  for (let i = 0; i < sumValues.length; i++) {
    sumdis += sumValues[i].discountedPrice;
    sumprice += sumValues[i].actualPrice;
  }
  let totdis = sumdis.toFixed();
  let totprice = sumprice.toFixed();

  useEffect(() => {
    const interval = setInterval(() => setNewData());
    return () => {
      clearInterval(interval);
    };
  }, []);

  const handleImageUpload = async (e) => {
    const res = await vasthuUrl.post(
      `image/uploadImageSpecial`,
      {
        file: uploadedImage,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          // contentType: "application/json",
        },
      }
    );
    console.log("response for Image Upload Api", res.data);
    alert(res.data);
  };

  useEffect(() => {
    try {
      const res = vasthuUrl
        .get(`getuserprofiledetail/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setUploadedImage(res.data.uploadedImage);
        });
    } catch (error) {
      console.log("ERROE" + error);
    }
  }, []);

  return (
    <Container>
      <Row className="justify-content-evenly add_cat_btn">
        {errMsg && (
          <Alert variant="info" className="text-center">
            {errMsg}
          </Alert>
        )}
        <Col className="mt-3 mb-3 all_border" sm={12} xs={12} md={8} lg={8}>
          <Multiselect
            className="add_cat"
            selectedValues={serviceList}
            displayValue="serviceName"
            placeholder={t("TXT.SEL.SER.LO")}
            onRemove={(list, value) => {
              setServiceList([...list]);
            }}
            onSearch={function noRefCheck() {}}
            onSelect={(list, value) => {
              console.log("multi select", [...list]);
              setServiceList([...list]);
            }}
            options={serviceData}
            showCheckbox
          />
        </Col>
        <Col lg={4} className="mt-3 mb-3">
          <Button className="all_btn" onClick={handleCatalogue}>
            ADD CATALOGUE
          </Button>
        </Col>
      </Row>

      <Modal
        show={offerModal}
        onHide={handleClose}
        size="lg"
        centered
        fullscreen={true}
      >
        <div className="mt-1">
          {errMsg && (
            <Alert variant="info" className="text-center">
              {errMsg}
            </Alert>
          )}
          {comboList &&
            comboList.map((x, y) => (
              <Card key={y}>
                <Card.Header className="all_background">
                  <center>
                    {x.offerDetails.length === 1
                      ? "INDIVIDUAL PACK"
                      : "COMBO PACK"}
                  </center>
                </Card.Header>
                <Card.Body>
                  <Form
                    noValidate
                    validated={validated}
                    onSubmit={handleSubmit}
                  >
                    <Form.Group className="mb-1" controlId="serviceName">
                      <Form.Label>{t("TXT.OFF.NM.LO")}</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="service name"
                        required
                        onChange={(e) => {
                          x.offerName = e.target.value;
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="mb-1" controlId="serviceName">
                      <Form.Label>Offer Name Tamil </Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="service name"
                        required
                        onChange={(e) => {
                          x.offerNameTamil = e.target.value;
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="mb-1" controlId="serviceName">
                      <Form.Label>Offer Name Hindi</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="service name"
                        required
                        onChange={(e) => {
                          x.offerNameHindi = e.target.value;
                          console.log("x", x);
                        }}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="OfferType">
                      <Form.Label>Offer Type</Form.Label>
                      <Form.Control
                        required
                        as="select"
                        onChange={(e) => {
                          x.otype = e.target.value;
                          setSpecial(e.target.value);
                        }}
                      >
                        <option key={"1"} value={"NORMAL"}>
                          NORMAL
                        </option>
                        <option key={"2"} value={"SPECIAL"}>
                          SPECIAL
                        </option>
                      </Form.Control>
                    </Form.Group>

                    {special === "SPECIAL" ? (
                      <>
                        <Form.Group className="mb-3" controlId="validitydate">
                          <Form.Label>START/END DATE</Form.Label>
                          {!startDate && !endDate ? (
                            <h6 style={{ color: "red" }}>Please Select Date</h6>
                          ) : null}
                          <div
                            style={{ display: "flex", alignItems: "baseline" }}
                          >
                            <DatePicker
                              value={startDate}
                              placeholder="Select date"
                              format="dd-MM-yyyy"
                              required
                              onChange={(e) => {
                                const now = new Date();
                                const currentHour = now.getHours();
                                const currentMinute = now.getMinutes();
                                const currentSecond = now.getSeconds();
                                const updatedDate = setSeconds(
                                  setMinutes(
                                    setHours(e, currentHour),
                                    currentMinute
                                  ),
                                  currentSecond
                                );

                                const isoDate = format(
                                  updatedDate,
                                  "yyyy-MM-dd'T'HH:mm:ss"
                                );
                                x.offerStartDate = isoDate;
                                setStartDate(e);
                              }}
                              // onChange={(date) => {
                              //   x.offerStartDate = date;
                              //   setStartDate(date);
                              // }}
                              minDate={today}
                            />
                            <p>-</p>
                            <DatePicker
                              value={endDate}
                              placeholder="Select date"
                              format="dd-MM-yyyy"
                              required
                              // onChange={(e) => {
                              //   const updatedDate = setSeconds(
                              //     setMinutes(setHours(e, 23), 59),
                              //     59
                              //   );
                              //   const isoDate = formatISO(updatedDate, {
                              //     representation: "complete",
                              //   });
                              //   x.offerEndDate = isoDate;
                              //   setEndDate(e);
                              // }}
                              onChange={(e) => {
                                const updatedDate = setSeconds(
                                  setMinutes(setHours(e, 23), 59),
                                  59
                                );
                                // const isoDate = formatISO(updatedDate, {
                                //   representation: "complete",
                                // });
                                const isoDate = format(
                                  updatedDate,
                                  "yyyy-MM-dd'T'HH:mm:ss"
                                );
                                x.offerEndDate = isoDate;
                                setEndDate(e);
                              }}
                              // onChange={(date) => {
                              //   x.offerEndDate = date;
                              //   setEndDate(date);
                              // }}
                              minDate={startDate}
                            />
                          </div>
                        </Form.Group>
                        <Form.Group controlId="formFile" className="mb-1">
                          <Form.Label>Image Upload</Form.Label>
                          <Form.Control
                            type="file"
                            onChange={(e) =>
                              setUploadedImage(e.target.files[0])
                            }
                          />
                        </Form.Group>
                        {/* <Button
                          className="all_btn"
                          onClick={handleImageUpload}
                        >upload</Button> */}
                        {uploadedImage && uploadedImage.type ? (
                          <Image
                            src={URL.createObjectURL(uploadedImage)}
                            fluid
                            thumbnail
                            alt="Uploaded Image"
                            style={{
                              width: "150px",
                              height: "108px",
                              objectFit: "cover",
                            }}
                          />
                        ) : null}
                      </>
                    ) : null}

                    <Form.Group className="mb-3" controlId="frequency">
                      <Form.Label>Frequency</Form.Label>
                      {special === "SPECIAL" ? (
                        <Form.Control
                          required
                          as="select"
                          disabled
                          value={(x.offerFrequency = "ONETIME")}
                        >
                          <option key={""} value="ONETIME">
                            ONE TIME
                          </option>
                        </Form.Control>
                      ) : (
                        <Form.Control
                          required
                          as="select"
                          onChange={(e) => {
                            if (e.target.value === "PERIOD") {
                              x.offerFrequency = e.target.value;
                              serviceList.map((i, j) => (i.count = "1"));
                              setComboList([...comboList]);
                            } else {
                              x.offerFrequency = e.target.value;
                              serviceList.map((i, j) => (i.count = "1"));
                              setComboList([...comboList]);
                            }
                          }}
                        >
                          <option key={"empty"} value="">
                            Select Frequency
                          </option>
                          <option key={"1"} value="PERIOD">
                            PERIOD
                          </option>
                          <option key={"2"} value="ONETIME">
                            ONE TIME
                          </option>
                        </Form.Control>
                      )}
                    </Form.Group>

                    {x.offerFrequency === "PERIOD" && special === "NORMAL" ? (
                      <>
                        <Form.Group className="mb-3" controlId="validitydate">
                          <Form.Label>START/END DATE :&nbsp;</Form.Label>
                          {!startDate && !endDate ? (
                            <h6 style={{ color: "red" }}>Please Select Date</h6>
                          ) : null}
                          <div
                            style={{ display: "flex", alignItems: "baseline" }}
                          >
                            <DatePicker
                              value={startDate}
                              placeholder="Select date"
                              format="dd-MM-yyyy"
                              required
                              onChange={(e) => {
                                const now = new Date();
                                const currentHour = now.getHours();
                                const currentMinute = now.getMinutes();
                                const currentSecond = now.getSeconds();
                                const updatedDate = setSeconds(
                                  setMinutes(
                                    setHours(e, currentHour),
                                    currentMinute
                                  ),
                                  currentSecond
                                );

                                const isoDate = format(
                                  updatedDate,
                                  "yyyy-MM-dd'T'HH:mm:ss"
                                );
                                x.offerStartDate = isoDate;
                                setStartDate(e);
                              }}
                              // onChange={(date) => {
                              //   x.offerStartDate = date;
                              //   setStartDate(date);
                              // }}
                              minDate={today}
                            />
                            <p>-</p>
                            <DatePicker
                              value={endDate}
                              placeholder="Select date"
                              format="dd-MM-yyyy"
                              required
                              onChange={(e) => {
                                const updatedDate = setSeconds(
                                  setMinutes(setHours(e, 23), 59),
                                  59
                                );
                                // const isoDate = formatISO(updatedDate, {
                                //   representation: "complete",
                                // });
                                const isoDate = format(
                                  updatedDate,
                                  "yyyy-MM-dd'T'HH:mm:ss"
                                );
                                x.offerEndDate = isoDate;
                                setEndDate(e);
                              }}
                              // onChange={(date) => {
                              //   x.offerEndDate = date;
                              //   setEndDate(date);
                              // }}
                              minDate={startDate}
                            />
                          </div>
                        </Form.Group>
                      </>
                    ) : null}

                    {/* {x.offerType === "INDIVIDUAL" &&
                      x.offerFrequency === "PERIOD" ? (
                      <Form.Group
                        className="mb-3"
                        controlId="individualCountService"
                      >
                        <Form.Label>Individual Count</Form.Label>
                        <Form.Control
                          placeholder="offer count"
                          type="number"
                          required
                          onChange={(e) => {
                            x.offerDetails[0].count = e.target.value;
                          }}
                        />
                      </Form.Group>
                    ) : null} */}
                    {x.offerFrequency !== "PERIOD" ? (
                      <Form.Group className="mb-3" controlId="validity">
                        <Form.Label>Validity</Form.Label>
                        <Form.Control
                          required
                          as="select"
                          onChange={(e) => {
                            x.validity = e.target.value;
                            setValidity(e.target.value);
                          }}
                        >
                          <option key={"empty"} value={""}>
                            Select Validity
                          </option>
                          <option key={"1"} value={"7"}>
                            7 Days
                          </option>
                          <option key={"2"} value={"30"}>
                            30 Days
                          </option>
                          <option key={"3"} value={"365"}>
                            365 Days
                          </option>
                          <option value={"Custom"}>Other</option>
                        </Form.Control>
                      </Form.Group>
                    ) : null}

                    {validity === "Custom" ? (
                      <Form.Group className="mb-1" controlId="">
                        <Form.Label>Validity In Days </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Custom Validity In Days Only"
                          required
                          onChange={(e) => {
                            x.validity = e.target.value;
                          }}
                        />
                      </Form.Group>
                    ) : null}

                    <Form.Group className="mb-3" controlId="Description">
                      <Form.Label>{t("TXT.DES.LO")}</Form.Label>
                      <Form.Control
                        placeholder={t("TXT.DES.LO")}
                        type="text"
                        value={x.description}
                        maxLength={200}
                        onChange={(e) => {
                          x.description = e.target.value;
                        }}
                      />
                    </Form.Group>
                    {/* <div>
                      {// x.offerType === "COMBO" &&
                      x.offerDetails.map((p, q) => (
                        <div>
                          <Row key={q}>
                            <Col>
                              <Row>
                                <div className="offerDetail_Input">
                                  <Form.Label>Service Name</Form.Label>
                                  <h5 className="pt-1">{p.serviceName}</h5>
                                </div>
                              </Row>
                            </Col>
                            <Col>
                              <Form.Group
                                className="mb-3 offerDetail_Input"
                                controlId="addOnServiceCount"
                              >
                                <Form.Label>Count</Form.Label>
                                <h5>1 Time</h5>
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group
                                className="mb-3 offerDetail_Input"
                                controlId="addOnPaymentType"
                              >
                                <Form.Label>Payment</Form.Label>
                                <Form.Control
                                  required
                                  as="select"
                                  onChange={(e) => {
                                    p.serviceCostType = e.target.value;
                                    handleOnChange();
                                  }}
                                >
                                  <option key={"empty"} value={""}>
                                    Select Payment
                                  </option>
                                  <option key={"1"} value={"FREE"}>
                                    FREE
                                  </option>
                                  <option key={"2"} value={"PAID"}>
                                    PAID
                                  </option>
                                </Form.Control>
                              </Form.Group>
                            </Col>

                            <Col>
                              <Form.Group
                                className="mb-3 offerDetail_Input"
                                controlId="addOnbasePrice"
                              >
                                <Form.Label>Base Price</Form.Label>
                                {/* <Form.Control
                                  placeholder="Base Price"
                                  type="number"
                                  required
                                  defaultValue={p.basePrice}
                                  onChange={(e) => {
                                    p.basePrice =  e.target.value;
                                  }}
                                /> */}
                    {/* <h5>{p.basePrice}</h5>
                              </Form.Group>
                            </Col>

                            <Col>
                              <Form.Group
                                className="mb-3 offerDetail_Input"
                                controlId="addOnServiceDiscount"
                              >
                                <Form.Label>Discount(%)</Form.Label>
                                {p.serviceCostType === "FREE" ? (
                                  <Form.Control
                                    placeholder="Discount"
                                    type="number"
                                    disabled
                                    defaultValue={0}
                                    value={(p.discountPercentage = 100)}
                                    onChange={(e) => {
                                      p.discountPercentage = Number(
                                        e.target.value
                                      );
                                      handleOnChange();
                                    }}
                                  />
                                ) : (
                                  <Form.Control
                                    placeholder="Discount"
                                    type="number"
                                    required
                                    defaultValue={0}
                                    value={p.discountPercentage}
                                    onChange={(e) => {
                                      p.discountPercentage = Number(
                                        e.target.value
                                      );
                                      handleOnChange();
                                    }}
                                  />
                                )}
                              </Form.Group>
                            </Col>

                            <Col>
                              <Form.Group
                                className="mb-3 offerDetail_Input"
                                controlId="addOnServiceDiscountedPrice"
                              >
                                <Form.Label>DiscountPrice</Form.Label>
                                <h5
                                  value={
                                    (p.discountedPrice = Number(
                                      p.discountPercentage
                                    )
                                      ? (Number(p.basePrice) *
                                          Number(p.discountPercentage)) /
                                        100
                                      : 0)
                                  }
                                  onChange={(e) => {}}
                                >
                                  {Number(p.discountPercentage)
                                    ? (Number(p.basePrice) *
                                        Number(p.discountPercentage)) /
                                      100
                                    : 0}
                                </h5>
                              </Form.Group>
                            </Col>

                            <Col>
                              <Form.Group
                                className="mb-3 offerDetail_Input"
                                controlId="addOnServicePrice"
                              >
                                <Form.Label>Actual Price</Form.Label>
                                <h5
                                  value={
                                    (p.actualPrice =
                                      Number(p.basePrice) -
                                      (Number(p.discountPercentage)
                                        ? (Number(p.basePrice) *
                                            Number(p.discountPercentage)) /
                                          100
                                        : 0
                                      ).toFixed())
                                  }
                                  onChange={(e) => {}}
                                >
                                  {Number(p.basePrice) -
                                    (Number(p.discountPercentage)
                                      ? (Number(p.basePrice) *
                                          Number(p.discountPercentage)) /
                                        100
                                      : 0
                                    ).toFixed()}
                                </h5>
                              </Form.Group>
                            </Col>
                          </Row>
                        </div> */}
                    {/* ))} */}
                    {/* </div> */}
                    <Accordion
                      className="accordianpanel addservice_accordian "
                      defaultActiveKey="0"
                    >
                      <Accordion.Item className="accordian_style" eventKey="0">
                        <Accordion.Header>
                          <h5>Offer Details</h5>
                        </Accordion.Header>
                        <Accordion.Body>
                          <table className="addservice_table">
                            <thead>
                              <tr>
                                <th>Service Name</th>
                                <th>Count</th>
                                <th>Duration</th>
                                <th>Payment</th>
                                <th>Base Price</th>
                                <th>Discount(%)</th>
                                <th>Discount Price</th>
                                <th>Actual Price</th>
                              </tr>
                            </thead>
                            <tbody>
                              {x.offerDetails.map((p, q) => (
                                <tr style={{ marginBottom: "5px" }}>
                                  <td>
                                    <h5 className="pt-1">{p.serviceName}</h5>
                                  </td>
                                  <td>
                                    {/* <h5>1 Time</h5> */}
                                    <Form.Control
                                      placeholder="Count"
                                      type="number"
                                      required
                                      // value={p.count}
                                      defaultValue={1}
                                      onChange={(e) => {
                                        p.count = e.target.value;
                                      }}
                                      style={{ width: "100px" }}
                                    />
                                  </td>
                                  <td>
                                    <Form.Control
                                      required
                                      as="select"
                                      value={p.duration}
                                      onChange={(e) => {
                                        p.duration = e.target.value;
                                      }}
                                      defaultValue={"30"}
                                    >
                                      {/* <option key={"empty"} value={""}></option>
                                      <option key={"1"} value={"15"}>
                                        15 Min
                                      </option>
                                      <option key={"2"} value={"30"}>
                                        30 Min
                                      </option>
                                      <option key={"3"} value={"45"}>
                                        45 Min
                                      </option> */}

                                      {callMin.map((i) => (
                                        <option key={i.id}>
                                          {i.offerCallMins}
                                        </option>
                                      ))}
                                    </Form.Control>
                                  </td>
                                  <td>
                                    <Form.Control
                                      required
                                      as="select"
                                      value={
                                        p.discountPercentage < 100
                                          ? "PAID"
                                          : p.discountPercentage === 100
                                          ? "FREE"
                                          : ""
                                      }
                                      onChange={(e) => {
                                        p.serviceCostType = e.target.value;
                                        handleOnChange();
                                      }}
                                      disabled
                                    >
                                      <option key={"empty"} value={""}></option>
                                      <option key={"1"} value={"FREE"}>
                                        FREE
                                      </option>
                                      <option key={"2"} value={"PAID"}>
                                        PAID
                                      </option>
                                    </Form.Control>
                                  </td>
                                  <td>
                                    {/* <h5>{p.basePrice}</h5> */}
                                    <Form.Control
                                      placeholder="Base Price"
                                      type="number"
                                      required
                                      // value={p.basePrice}
                                      defaultValue={p.basePrice}
                                      onChange={(e) => {
                                        p.basePrice = e.target.value;
                                      }}
                                      style={{ width: "100px" }}
                                    />
                                  </td>
                                  <td>
                                    <Form.Group
                                      className="mb-3 offerDetail_Input"
                                      controlId="addOnServiceDiscount"
                                    >
                                      {p.serviceCostType === "FREE" ? (
                                        <Form.Control
                                          placeholder="Discount"
                                          type="number"
                                          disabled
                                          defaultValue={0}
                                          value={(p.discountPercentage = 100)}
                                          onChange={(e) => {
                                            p.discountPercentage = Number(
                                              e.target.value
                                            );
                                            handleOnChange();
                                          }}
                                          style={{ width: "100px" }}
                                        />
                                      ) : (
                                        <Form.Control
                                          placeholder="Discount"
                                          type="number"
                                          required
                                          defaultValue={0}
                                          max={100}
                                          value={p.discountPercentage}
                                          onChange={(e) => {
                                            p.discountPercentage = Number(
                                              e.target.value
                                            );
                                            handleOnChange();
                                          }}
                                          style={{ width: "100px" }}
                                        />
                                      )}
                                    </Form.Group>
                                  </td>
                                  <td>
                                    <h5
                                      value={
                                        (p.discountedPrice = Number(
                                          p.discountPercentage
                                        )
                                          ? (Number(p.basePrice) *
                                              Number(p.discountPercentage)) /
                                            100
                                          : 0)
                                      }
                                      onChange={(e) => {}}
                                    >
                                      {Number(p.discountPercentage)
                                        ? (Number(p.basePrice) *
                                            Number(p.discountPercentage)) /
                                          100
                                        : 0}
                                    </h5>
                                  </td>
                                  <td>
                                    <h5
                                      value={
                                        (p.actualPrice =
                                          Number(p.basePrice) -
                                          (Number(p.discountPercentage)
                                            ? (Number(p.basePrice) *
                                                Number(p.discountPercentage)) /
                                              100
                                            : 0
                                          ).toFixed())
                                      }
                                      onChange={(e) => {}}
                                    >
                                      {Number(p.basePrice) -
                                        (Number(p.discountPercentage)
                                          ? (Number(p.basePrice) *
                                              Number(p.discountPercentage)) /
                                            100
                                          : 0
                                        ).toFixed()}
                                    </h5>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>

                    {x.offerType === "INDIVIDUAL" ? (
                      <Row style={{ display: "none" }}>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                        <Col>
                          <h5>Total</h5>
                        </Col>
                        <Col>
                          <h5
                            defaultValue={0}
                            value={(x.offerDiscount = totdis)}
                          >
                            {totdis}
                          </h5>
                        </Col>
                        <Col>
                          <h5
                            defaultValue={0}
                            value={(x.offerPrice = totprice)}
                          >
                            {totprice}
                          </h5>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                        <Col></Col>
                        <Col>
                          <h5>Total</h5>
                        </Col>
                        <Col>
                          <h5
                            defaultValue={0}
                            value={(x.offerDiscount = totdis)}
                          >
                            {totdis}
                          </h5>
                        </Col>
                        <Col>
                          <h5 value={(x.offerPrice = totprice)}>{totprice}</h5>
                        </Col>
                      </Row>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "2rem",
                      }}
                    >
                      <Button
                        className="mt-3"
                        variant="outline-warning"
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          borderRadius: 50,
                        }}
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        {t("TXT.BTN.CAN.LO")}
                      </Button>
                      <Button
                        className="mt-3"
                        variant="outline-warning"
                        style={{
                          color: "black",
                          fontWeight: "bold",
                          borderRadius: 50,
                        }}
                        type="submit"
                      >
                        Add Service
                      </Button>
                    </div>
                  </Form>
                </Card.Body>
              </Card>
            ))}
        </div>
      </Modal>
    </Container>
  );
}

export default AddService;
