import React, { useState } from 'react'
import { Tabs, Tab } from "react-bootstrap";
import VasthuParamReview from './VasthuParamReview'; 
import VasthuProfessionalReview from './VasthuProfessionalReview';

const Review = () => {
    const [key, setKey] = useState("appreview");
  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="justify-content-center"
    >
      <Tab eventKey="appreview" title="VASTHUPARAM">
        <center>
          <h4 className="m-1 p-3">
            <u>App Review Details</u>
          </h4>
        </center>
        <VasthuParamReview/>
      </Tab>
      <Tab eventKey="professionalreview" title="CONSULTANT">
        <center>
          <h4 className="m-1">
            <u>Consultant Review Details</u>
          </h4>
        </center>
        <VasthuProfessionalReview />
      </Tab>
    </Tabs>
  )
}

export default Review