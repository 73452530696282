import React, { createContext, useState } from "react";
import { vasthuUrl } from "../../component/Api";
import ReportListTable from "./ReportListTable";

export const CrmUserListContext = createContext({});

export default function Report() {
  const token = window.sessionStorage.getItem("token");

  const [userList, setUserList] = useState([]);
  const [message, setMessage] = useState("");

  const fetchInfo = async () => {
    vasthuUrl
      .get("/data/getallusers", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("RESULT LIST OF USERS", res.data);
        setUserList(res.data);
      })
      .catch((err) => {
        // setMessage(err)
        setMessage("Network error - UserList Not fetched properly");
      });
  };

  React.useEffect(() => {
    fetchInfo();
  }, [setUserList]);

  return (
    <CrmUserListContext.Provider
      value={{ userList, message, setUserList, setMessage, token }}
    >
      <ReportListTable title="REPORTS LIST OF USER" />
    </CrmUserListContext.Provider>
  );
}
