import React from "react";
import Title from "../component/Title";
import { Container, Row, ListGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const AstroContent = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Title title={t("CM.HD.CLT.MEN.AST")} />
      <Row className="m-1 " style={{ border: "1px solid #ffb33e" }}>
        <p>
          Astrology is the part of the eye of the Vedas because it Provides
          light like as guidance and confidences to human life. The sage
          Parashara is the author Astorlogy and Brihat Parashara Hora Shastra is
          written by him. sage Parasara (one of those grand eighteen sages like
          Narada, Vashishta, Jaimini etc.) authored this encyclopedia text in
          the principals of Vedic Astrology designated as Brihat Parasara Hora
          Shastra (BPHS in brief).
        </p>
        <h6>Various Approach of Astrological System,</h6>
        <ul>
          <span>1. Parasra System</span>
          <br />
          <span>2. Jaimani System</span>
          <br />
          <span>3. Nadi System</span>
          <br />
          <span>4. Tajaka System </span>
          <br />
          <span>5. KP System. </span>
          <br />
          <span>6. Horary Astrology. </span>
          <br />
          <span>7. Western Astrology</span>
          <br />
        </ul>
        <h6>Various Branches in Astrology,</h6>
        <ul>
          <span>1. Natal Astrology.</span> <br />
          <span>2. Medical astrology.</span> <br />
          <span>3. Electional astrology.</span> <br />
          <span>4. Relationship Astrology.</span> <br />
          <span>5. Mundane Astrology.</span> <br />
        </ul>
        <h6>Usefulness of Astrology</h6>
        <p>
          Finding the bad period through dasa ,bukthi and anthara sothat we can
          reduce the effort and investment the huge money.
        </p>
        <p>
          Spiritual Remedies gives peace to mind and confidence to face the
          problem.
        </p>
      </Row>
    </Container>
  );
};
export default AstroContent;
