import React from "react";
import { Image } from "react-bootstrap";

function ErrorPage() {
  return (
    <React.Fragment>
      <div
        className="p-2"
        style={{
          height: "100vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <center
          style={{
            width: "500px",
          }}
        >
          <h1
            style={{
              fontWeight: "bolder",
            }}
          >
            We'll be back soon!
          </h1>
          {/* <Image
            src="logonewtr.png"
            class="img-fluid"
            object="fill"
            width={100}
            height={100}
          /> */}
          <Image
            src="VasthuparamFinal1.png"
            class="img-fluid"
            object="fill"
            // width={100}
            // height={100}
            style={{
              height: "240px",
              width: "240px",
              objectFit: "contain",
            }}
          />
          {/* <Image
            src="n1.png"
            class="img-fluid"
            object="fill"
            // width={100}
            // height={100}
            style={{
              height: "240px",
              width: "240px",
              objectFit: "contain",
            }}
          /> */}
          <h4>
            <i style={{ color: "#000000" }}>
              {" "}
              We are currently working for the enhancement to the site, we will
              be right back shortly. Please bear with us for sometime!{" "}
            </i>
          </h4>
        </center>
      </div>
    </React.Fragment>
  );
}

export default ErrorPage;
