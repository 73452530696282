import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Title from "../component/Title";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Aboutus = () => {
  const location = useLocation();
  const { t } = useTranslation();
  useEffect(() => {
    console.log("locationstate", location.state);
  });
  return (
    <Container>
      <Title title={t("CM.HD.ABT")} />
      <Row className="m-1 g-1" style={{ border: "1px solid #ffb33e" }}>
        <Col className=" p-2" style={{ border: "1px solid #ffb33e" }} lg={6}>
          <center>
            <h5>VISION</h5>
          </center>
          <p>
            Jothiparam is a organization which aim to Enrich the Human life .
            Problem will be solved with help of guidelines of Spiritual aspects
            & Vedic science blend with scientific manner. Providing Building
            plan based on Astro Vedic Architecture which is astrological based
            Architectural Plans blend with Vastu Guidelines building designs for
            all aspects. Astro Vedic Architecture provides increased fortune,
            reputation and Prosperity in abundance through house Environment.
          </p>
        </Col>
        <Col className=" p-2 " style={{ border: "1px solid #ffb33e" }} lg={6}>
          <center>
            <h5>MISSION</h5>
            <p>
              Providing Vastu Based Architecture house Plan by digital
              Deliverables with engineering information. Providing Consultation
              for Architecture and engineering of building Designs and plans.
              Providing Astrological Consultation through the online. Conducting
              the workshop for spiritual concepts. Publish the digital Articles
              and Videos for Vastu, Building designs , Astrology as well as
              spiritual Providing Support for Charity works MOTO ENRICH LIFE AND
              MINGLE WITH ALMIGHTY.
            </p>
          </center>
        </Col>
      </Row>
      <Title title={t("CM.HD.FOUNDER")} />
      <Row
        className="m-1 g-1"
        style={{ height: "auto", minHeight: 200, border: "1px solid #ffc33e" }}
      >
        <Col
          className="text-center  p-2"
          style={{ border: "1px solid #ffc33e" }}
          lg={4}
        >
          <img
            src={require("../images/founderBala.jpg")}
            alt="founder_img"
            // width="100%"
            // height="100%"
            height="auto"
            width="intial"
          />
        </Col>
        <Col className="border p-2  all_border" lg={8}>
          Mr.AR.R.BALACHANDAR creator of Astro Vedic Architecture which is
          astrological based Architectural building designs for all aspects. He
          has got Copy Right of Astro Vedic Architecture. Astro Vedic
          Architecture provides increased fortune, reputation and Prosperity in
          abundance. After graduating Architecture from the University of Madras
          he has worked in the Multinational companies which enable extensive
          knowledge and experience in handling large scale Architectural
          projects in the commercial, Hospitality, residential, interior and
          Master planning.
          <h5 className="mt-1">
            <u>EDUCATION</u>
          </h5>
          <ul>
            <li>
              <b>Bachelor of Architecture</b>,University of Madras, Chennai,
              India.
            </li>
            <li>
              <b>Master of Town Planning</b>,Institute of Town and Country
              Planning, New Delhi, India.
            </li>
            <li>
              <b>MA ASTROLOOGY</b>,Yoga Samaskrutham University,Florida,USA.
            </li>
            <li>
              <b>Registered Architect under Council of Architecture</b>,New
              Delhi - India.
            </li>
            <li>
              <b>Associate Member</b>,The Institution of Town Planners, New
              Delhi,India.
            </li>
            <li>
              <b>Associate Member</b>,The Institution of Valuer’s, New Delhi –
              India.
            </li>
            <li>
              CopyRights and coin the name of “<b>Astro Vedic Architecture</b>”.
            </li>
          </ul>
          <Row className="px-1">
            <Col lg={3}>
              <img
                src={require("../images/CAADRIA.jpg")}
                alt="caadria"
                width="100"
                height="60"
              />
            </Col>
            <Col lg={3}>
              <img
                src={require("../images/MA_DEGREE.jpg")}
                alt="caadria"
                width="100"
                height="60"
              />
            </Col>
            <Col lg={3}>
              <img
                src={require("../images/itpi.gif")}
                alt="caadria"
                width="100"
                height="60"
              />
            </Col>
            <Col lg={3}>
              <img
                src={require("../images/COA.jpg")}
                alt="caadria"
                width="100"
                height="60"
              />
            </Col>
          </Row>
        </Col>
      </Row>

      <Row
        className="m-1  g-1"
        style={{ minHeight: 150, border: "1px solid #ffc33e" }}
      >
        <Col className=" p-2 " style={{ border: "1px solid #ffc33e" }} lg={4}>
          <img
            width="100%"
            height="auto"
            // style={{border:'2px solid red'}}
            src={require("../images/135with.jpg")}
            alt="founder img"
          />
        </Col>
        <Col className=" p-2  " style={{ border: "1px solid #ffc33e" }} lg={8}>
          Mrs.D.Kavitha She is educational background of chemistry and follower
          of Arutperagsa vallar Spirtual path. She is the cause root of
          JOTHIPARAM organization with blessing of Arutperagasa vallar.
          Supporting for digital and online programming services
          <h5 className="mt-1">
            <u>EDUCATION</u>
          </h5>
          <ul>
            <li>
              <b>Bachelor of Chemistry</b> in Kanda swamy Naidu College
              ,cuddalore ,Tamilnadu,India.
            </li>
            <li>
              <b>Master in Chemistry </b>in Anna malai University ,chindambaram
              ,Tamilnadu,India.
            </li>
            <li>
              <b>Master of philosophy in chemistry </b>in Anna malai University
              ,chindambaram ,Tamilnadu,India.
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};
export default Aboutus;
