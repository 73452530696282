import React, { useState, useEffect, Suspense } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BiSolidPhoneCall } from "react-icons/bi";
import { Button } from "react-bootstrap";
import "../App.css";
import { useTranslation } from "react-i18next";

const AudioCallStatus = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const [callStatus, setCallStatus] = useState("");

  useEffect(() => {
    console.log("props", location.state);
    setCallStatus(location.state.callStatus);
  }, [callStatus]);

  const navigate = useNavigate();

  return (
    <div>
      <div className="contnent">
        <div className="hlo">
          <h3>{t(callStatus)}...</h3>{" "}
        </div>
        {location.state && location.state.callStatus === "USR.CAL.PROCED.BTN" ? (
          <div className="call_proceed">
            <BiSolidPhoneCall size={50} />
          </div>
        ) : (
          <div className="call_failed">
            <BiSolidPhoneCall size={50} />
          </div>
        )}
        <div>
          <Button variant="outline-warning" onClick={() => navigate(-1)}>
            {t("TXT.CLO.LO")}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default AudioCallStatus;
