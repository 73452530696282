import React from 'react';


//custom - Vasthuparam

import DashItems from '../../component/crm/DashItems';
import { Container } from '@mui/material';
// import Accounts from './Accounts';


// TODO remove, this demo shouldn't need to reset the theme.
// const defaultTheme = createTheme();

export default function DashBoard() {
  window.sessionStorage.removeItem("crmSelectedUser");
  window.sessionStorage.removeItem("LoggedInUser");
  return (
    <Container component="main" maxWidth="lg">
      <DashItems />
      {/* <Accounts /> */}
    </Container>

  );
}