// import React from "react";
// import Title from "../component/Title";
// import { Card, Container, Row, Col } from "react-bootstrap";

// const VasthuArticle = () => {
//   const articleData = [
//     // {
//     //   id: "caadria2009_161",
//     //   authors: "Mr.AR.R.BALACHANDAR",
//     //   year: "2009",
//     //   title: "Digital Design Engine",
//     //   doi: "https://doi.org/10.52842/conf.caadria.2009.645",
//     // },
//   ];
//   return (
//     // <Container>
//     //   <Title title="ARTICLE" />
//     //   <Row className='d-flex justify-content-center'>
//     //   {articleData.length > 0 ? (
//     //     articleData.map((i, j) => (
//     //       <Card
//     //         className="m-3 all_border"
//     //         style={{ width: "18rem" }}
//     //       >
//     //         <Card.Body>
//     //           <Card.Title>{i.title}</Card.Title>
//     //           <Card.Subtitle className="mb-2 text-muted">
//     //             {i.authors}
//     //           </Card.Subtitle>
//     //           <Card.Text>Published on {i.year}</Card.Text>
//     //           <Card.Link href={i.doi} target="_blank">
//     //             View Docs
//     //           </Card.Link>
//     //         </Card.Body>
//     //       </Card>
//     //     ))
//     //   ) : (
//     //     <center>
//     //       <h6>No Articles</h6>
//     //     </center>
//     //   )}
//     //   </Row>
//     // </Container>

//     <Container>
//       <Title title="Astrology" />
//       <Row className="m-1 " style={{ border: '1px solid #ffb33e' }}>

//       </Row>
//     </Container>

//   );
// };
// export default VasthuArticle;

import React from "react";
import Title from "../component/Title";
import { Card, Container, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import VasthuArticalTamil from "./VasthuArticalTamil";

const VasthuArticle = () => {
  const { t } = useTranslation();
  const lang = localStorage.getItem("i18nextLng");
  const articleData = {
    title: "DIGITAL DESIGN ENGINE",
    subtitle: "Based on Vedic sciences",
    authors: "R.BALACHANDAR",
    affiliations: [
      "RSP design consultants India pvt ltd.",
      // "Zachariah Consultants",
    ],
    emails: ["balacr3@gmail.com"],
    abstract:
      "Application of Vedic science for generating digital design engine, for a personalized dwelling unit is explored. The user data are fed into existing ‘jyotisha’ software, which is based on Vedic sciences, yields resultant direction, and ‘Ayadi’ values for further design. Using these values, digitally dwelling unit designs are generated.",
    content: [
      {
        heading: "1. Introduction",
        text: `This project aims on developing a design engine, which generates the dwelling unit plan for a particular person; principles of Vedic science have been applied to the design engine.
        ‘OM’ sound prevails everywhere in nature source of Vedic science. Vedic science is a broad-spectrum, which has a lot of scope in the field of architectural designing, jyotisha Shastra, yoga, varma, Vastu, vedic mathematics. Vastu is a part of Vedic science, which is applied in architecture with cosmic and electromagnetic forces. Combined with vastu jyotisha calculations and the directions of dwelling unit for a given individual person can be suitably planned.`,
      },
      {
        heading: "2. Methodology",
        text: `Methodologies followed in this study are descriptive and scientific methods, which are suited, this study better than any other methods culling of various materials from different sources have been very carefully done. A coherent presentation of the relevant points has been made after critical analysis of the views expressed from different sources.`,
      },
      {
        heading: "3. Vedic Science",
        text: `Vedic science originated from Vedas. The Vedic science contains four Vedas such as Rig, Yajur, Sama and Atharva. Vasthu also includes the Jyotisha calculation, which is based on Rig, Yajur and Atharva Vedas (Raman, 1998).
        Rig-veda is the first among the four Vedas. Jyotisha calculation helps in vasthu Shastra to find out the direction of the main door of dwelling unit (Kadalangudi, 2001) and matching the nakshathara of individuals with ‘Ayadi’ calculation, etc. Pranava Veda, Maya-matam and Sirpa-ma-chennol are the main source of vasthu Shastra which has been authored by mayan (sthapathi, 2001; Mudaliar, 1995; Durai, 2004). Jaimini Sutra, Brigu Sutra, Lomasa Sutra and Parasari Sutra are extracted from Rig, Yajur and Atharava Veda, which are the basis of Jyothida (Raman, 1998).`,
      },
      {
        heading: "4. Vastu Shastra",
        text: `Vastu Shastra is an Ancient Science of India, which is designated as Mayonic Science and Technology not only to make it distinct from the contemporary material sciences, but to restore and revalidate the unique spiritual science of a great scientist and technologist ‘Mayan’, who lived in the land, south of the present Kanyakumari, Tamilnadu.
        Mayan, Brahmarishi and Vishwakarma, are the author of the Vastu Shastra (Sthapathi, 2001). The primary roots of the scientific culture is, according to Mayan, ‘Aadi moolam’, meaning primary root which was called later as Brahman in Vedas, ‘Paramporul’ (substance of the universe) in Tamil culture, Vastu in Indian science and technology and energy in modern science and so on and so forth. Main Contents of vastu rules (Durai, 2004; Mudaliar, 1995; Sthapathi, 2001):
        1. ’Ayadi’ calculation, auspicious width of the dwelling unit.
        2. Location of the main door and direction of the dwelling unit.
        3. Planning the requirements of the dwelling unit as per vastu shastra.
        4. Auspicious dimension of the rooms of the dwelling unit.
        5. Location of the staircase, water tank, bore well etc.
        6. Setback and levels as per the vastu rules.
        7. Openings as per vastu rules.`,
      },
      {
        heading: "5. Structure of the Project",
        text: `Data, Place, and Time of Birth are fed in to the jyothida software, which manipulates and gives ‘The auspicious direction’of the dwelling unit of the individuals as output, which is based on ‘Astavargam calculation’ in jyothida shastra. Ayadhi calculation gives the auspicious plinth area of the dwelling unit, which is applied from ‘The Principles of Myonic science’.
        Soon after the ‘basic data’ and area & requirement is fed in to the design engine, the tool manipulates the possible dimensions of the biggest required space, compiles the rest of the spaces around it, and produces design patterns. These patterns are checked back with Vastu rules and design with higher fitness ratio is evolved as ‘option’ and added to the interface screen.`,
      },
      {
        heading: "6. Concept of Design Engine",
        text: `The workspace contains a matrix of blank pixels and has the point of origin as (a0, a0) at the center of the canvas. The ‘user input’ in terms of areas is converted in to matrix of tagged colored pixels in various sizes. The sizes of the activities are fixed as per the ‘Ayadhi’ internal dimension. The combination of all these size of activity is then composed as per rules and tested for efficiency. This is done by crossover. When a design combination scores medium high degree of Vastu, minor transition spaces could added to fill in the transition activity surrounding it, there by mutating the bounded area of an activity.
        Each space has four boundaries’ of which minimum one of the boundary should face exterior. The windows and ventilators are added only to the exterior face of the spaces. The doors that are been added to the exterior boundary are exterior doors. The internal boundary faces the other spaces. Primarily these boundaries bear the internal connective opening (programmed to open to activity-‘hall’).
        Once a design combination passes efficiency check the design is generated as option. These options are again tested for its higher degree Vastu. Once a design passes through these values, the option is tabulated as additional ‘layout’ view in the bottom of the interface. The calculation of the ‘auspicious direction’ based on ‘astavargam’ has never been attempted with the help of Digital tools.`,
      },
      {
        heading: "7. Interface",
        text: `The interface is tile-based and the user data of ‘auspicious direction’ and ‘Ayadhi’ values are fed into the tool through .csv or .txt format. These data are generally derived from available Jyothida software. The user then provides the area requirement in terms of various activites. Once the data fed, the user activates the program by clicking ‘function’ button in menu bar.
        The program generates the first set of options, which are again checked back with the Vastu rules. The second generation of options, which passes the Vastu tests, are listed in pages in the interface. Additionally, the user can run the individual component functionality to the generated designs.`,
      },
      {
        heading: "8. Algorithm",
        text: `The design engine is designed and developed in flash and it is based on action script 2.0. The logic behind the scripting is genetic algorithm, where the combination of the spaces behaves like crossover and the higher degree offsprings are produced. The mutation factor is a fine tune of the generated design that is applicable to architectural principles and Vastu.`,
      },
      {
        heading: "9. Demonstration",
        text: `The working of the tool could be well explained by solving a sample case. Consider the below case, where in the Site area: 1675 Sqft, Plinth area: 800 sqft. The number of spaces accounted for design generation is: Living, Bedroom, Kitchen, Toilet.
        9.1 PROCESS 1: ACQUIRING PERSON DATA AND ASTAVARGAM CALCULATIONS
        Person’s Date of Birth: 24.04.2009 (1)
        Time of Birth: 10.30 AM (2)
        Place of Birth: Yung, Taiwan. (3)
        Result of Auspicious direction arrived below:
        East 24+22+29 = 75
        South 28+30+3 = 92
        West 28+34+34 = 96 (greater value result) (4)
        North 26+27+21 = 74
        Among the four values, the highest value is taken for consideration. Astavargam values obtained from a jyothida software, used below is the short version of ‘Jagganatha Hora’ (www.vedicastrologer.org/jh/index.htm: 2008)
        9.2 PROCESS 2: AYADI CALCULATION
        Result of ayadi plinth area arrived below:
        891 × 12 = 10692 Square inch
        10692 / 1156 = 9 ayam.
        34”×34” = 1156 (34 inches is equal to one ayam.)
        9ayam is auspicious for person.
        (Taking conclusion from the primary source of vastu ayam table that has 15 matching points to individuals.)
        Auspicious Building width: 26’, 27’, 28’ (5)
        Building dimension: width-27’, length -33’ (6)
        Final building dimensions: (Building dimension obtained from ayadi plinth area respect to Auspicious Building width)
        26’ × 34.26’ = 891sqft.
        27’ × 33’ = 891 sqft.
        28’ × 31.82’ = 891 sqft.
        9.3 PROCESS 3: NORMS
        TABLE 1. Vastu rules and Planning Requirement
        Should be Shouldn’t be
        Living Northeast, East.
        Bed Room Northwest, West, North, Northeast, East. Southeast
        Kitchen Southeast, Northwest. Northeast, East, Southwest.
        Toilet Northwest, West, East. Northeast, Southeast.
        Staircase Northwest, South, Southwest, West. Northeast, East
        Auspicious Room dimention: 8’, 11’
        Levels: lower level in North, East, Northeast, Northwest.
        Openings: more openings North, East, Northeast, Northwestern side walls.
        Divided the western side wall (auspicious direction) width into 9 parts and then Main Door should be placed in the 4thand 5th part from counting left to right direction.
        9.4 PROCESS 4: DESIGN OPTIONS
        For dimension 27’ x 33’
        The sample of 2 designs is shown here. The design engine will generate much more options, with respect to 26’ and 28’ as width of the building dimensions. Some more options may depend on the interior planning.`,
      },
      {
        heading: "10. Conclusion and Further Research",
        text: `The design engine translates the ‘astavargam’ manipulations and vastu rules, into design principles and digitally generates the designs for dwelling unit conforming to architectural functioning.
        Further, the research can be extended to the Town-planning (Nahara-vidhana) (Durai, 2004; Sthapathi, 2001) and develop a generic urban design engine based on vedic sciences. Adding a new direction to the research, the ‘Thantra’s of Temple architecture’ (Aagama-vidhana) can also be applied into the above said methodology.`,
      },
      {
        heading: "Acknowledgements",
        text: `The authors wish to thank Prof.Dr.Rajarathinam, historian for his valuable inputs in the areas of Mayan science and Vedas. Also, Prof.Dr.R.Shanmugam, for his valuable input in field of Vedic sciences.`,
      },
      {
        heading: "References",
        text: `Durai, R.P.: 2004, Mayan Ariviyal Thozhilnutpam(Tamil version), Meyappan Pathippagam, Chidambaram, Tamil Nadu.
        Kadalangudi, N.: 2001, Brihit Samhita(Tamil version), Kadalangudi Publications Chennai, Tamil Nadu.
        Mudaliar, V.: 1995, Sarvartha Sirpachinthamani(Tamil version), B. Rathina Nayakan and sons publications Chennai, Tamil Nadu , pp. 6-13, 89-293.
        Raman, B.V.: 1998, Astrology in Veda 3rd vol in Astrological Magazine, May, pp 490.
        Sthapathi, V.G.: 2001, Building Architecture of Sthapathi Veda, Dakshina Publishing Housing, Chennai, Tamil Nadu, pp 55-57, 259, 325.`,
      },
    ],
  };

  return (
    <Container>
      {lang == "en" ? (
        <>
          <Title title={t("CM.HD.CLT.MEN.AST")} />

          <Row className="m-1 p-3" style={{ border: "1px solid #ffb33e" }}>
            <Col>
              <h1>{articleData.title}</h1>
              <h3>{articleData.subtitle}</h3>
              <h5>{articleData.authors}</h5>
              {articleData.affiliations.map((affiliation, index) => (
                <p key={index}>{affiliation}</p>
              ))}
              {articleData.emails.map((email, index) => (
                <p key={index}>{email}</p>
              ))}
              <h4>Abstract</h4>
              <p>{articleData.abstract}</p>
              {articleData.content.map((section, index) => (
                <div key={index}>
                  <h5>{section.heading}</h5>
                  <p>{section.text}</p>
                </div>
              ))}
            </Col>
          </Row>
        </>
      ) : (
        <VasthuArticalTamil />
      )}
    </Container>
  );
};

export default VasthuArticle;
