import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import React, { useEffect, useState } from "react";
// import Button from "@mui/material/Button";
import {
  Accordion,
  Alert,
  Badge,
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  InputGroup,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Stack,
  Tab,
  Table,
  Tabs,
  Tooltip
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
// import CustomerHomeForm from "./CustomerHomeForm";
import Modal2 from "@material-ui/core/Modal";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Stacks from "@mui/material/Stack";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { AiOutlineExclamationCircle, AiTwotoneBuild } from "react-icons/ai";
import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";
import {
  GiBrickWall,
  GiLevelEndFlag,
  GiWindow
} from "react-icons/gi";
import { SiHomeadvisor } from "react-icons/si";
import validator from "validator";
import { vasthuUrl } from "../component/Api";
import BillDeskPay from "../component/BillDeskPay";
import { UseVasthu } from "../context/vasthu";

const steps = ["Home", "Floor", "Window", "Miscellaneous", "Level"];

const StepperUI = () => {
  const [houseTempData, setHouseTempData] = useState([]);
  console.log("houseTempData", houseTempData);
  useEffect(() => {
    vasthuUrl
      .get(`offer/defaultIndividualOffer/home`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setDefaultHomeOffer({
          offerId: res.data.id,
          offerPrice: res.data.offerPrice,
        });
      });
    vasthuUrl
      .get(`/getTempHouseDetailsForUser/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setHouseTempData(res.data);
        console.log("res.data", res.data);
        if (res.data.length > 1) {
          setShowModal(true);
        } else if (res.data.length === 1) {
          houseDetailsWithUuid(res.data[0].id);
          setHOwner(res.data[0].ownerName);
          setHConstruct(res.data[0].constructed.toString());
          setPerimeterFt(res.data[0].perimeter.toString());
          setHfacingWidth(res.data[0].building_front_width.toString());
          setHMainDoorWidth(res.data[0].maindoor_size.toString());
          setHMainDoorGap(res.data[0].maindoor_placement_gap.toString());
          setHlagna(res.data[0].lagnaId.toString());
          setHFacing(res.data[0].directionId.toString());
          setHArea(res.data[0].building_area.toString());
        }
      });
  }, []);

  //toast aleart
  const [openSnack, setOpenSnack] = React.useState(false);
  const [messageType, setMessageType] = useState("");

  const handleClosess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const alert = (msg) => {
    setMessage(msg);
    setOpenSnack(true);
    setTimeout(() => {
      setMessage("");
      setMessageType("");
    }, 4000);
  };

  const navigate = useNavigate();
  const { t } = useTranslation();
  const lang = localStorage.getItem("i18nextLng");
  const {
    lagnaList,
    facingList,
    floorList,
    elementList,
    miscList,
  } = UseVasthu();
  console.log("floorList", floorList);
  console.log("lagnaList", lagnaList);
  console.log("facingList", facingList);
  const userId = window.sessionStorage.getItem("userId");
  const { addHomeRecId, homeRec } = UseVasthu();
  console.log("addHomeRecId", addHomeRecId);
  console.log("homeRec", homeRec);
  const token = window.sessionStorage.getItem("token");
  const [progressValue, setProgressValue] = useState(0);
  const [activeStep, setActiveStep] = React.useState(0);
  const [activeServiceList, setActiveServiceList] = useState([]);
  const [showPaytm, setShowPaytm] = useState(false);
  const [paymentCredentials, setPaymentCredentials] = useState({
    txnToken: "",
    orderId: "",
    amount: 0,
    offerId: 0,
    userId: "",
  });

  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [defaultHomeOffer, setDefaultHomeOffer] = useState({
    offerId: 0,
    offerPrice: 0,
  });

  function openModal() {
    setIsOpen(true);
  }

  async function afterOpenModal() {
    subtitle.style.color = "#f00";
    setLoading(false);
  }

  function closeModal() {
    setIsOpen(false);
  }

  const customStyles = {
    content: {
      top: "20%",
      left: "20%",
      right: "20%",
      bottom: "20%",
    },
  };

  // newly added - alert modal
  const [open, setOpen] = useState(false);
  const handleClosealert = () => setIsOpen(false);

  //
  const BillDeskHandler = (txn) => {
    console.log(txn);
    // if (
    //   txn.transaction_error_desc.toUpperCase() !== "TRANSACTION NOT AUTHORIZED" && tx.transaction
    // ) {
    try {
      vasthuUrl
        .post(
          "vasthu/user/bdpaymentresponseui",
          { txn: txn.txnResponse.transaction_response },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log("txnres", res.data);
          // if (res.data.transaction_error_type == "success") {
          //   console.log("home validation");
          //   navigate("/homevalidation", {
          //     state: { offerId: res.data.id },
          //   });
          // }

          if (res.data.transaction_error_type === "success") {
            console.log("Saving payment_response ....", txn);

            vasthuUrl
              .post("vasthu/user/bdpaymentresponsehandler", txn, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((res) => console.log("after txnsave", res.data))
              .catch((err) => console.log("after txnsave try", err));

            console.log("buying .. subscription");
            vasthuUrl
              .post(
                "subscription/buyVasthuOffer",
                {
                  orderId: res.data.orderid,
                  userUuid: res.data.additional_info.additional_info1,
                  offerId: parseInt(res.data.additional_info.additional_info2),
                  paymentStatus: true,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((res) => {
                console.log("Payment status changed to true");
                console.log("afterbuysubscr", res.data);
                navigate("/homevalidation", {
                  state: { offerId: res.data.id },
                });
                //  this.props.navigate(this.props.screenName,{state:{offerId:res.data.id}})
              });
          } else {
            vasthuUrl
              .post(
                "subscription/buyVasthuOffer",
                {
                  orderId: res.data.orderid,
                  userUuid: res.data.additional_info.additional_info1,
                  offerId: parseInt(res.data.additional_info.additional_info2),
                  paymentStatus: false,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((res) => {
                console.log("Payment status changed to false");
                setOpen(true);
              });
          }
        });
    } catch (err) {
      console.log("home form pay handler", err);
    }
    // }
  };

  const handleBuy = async (val, price) => {
    console.log("home form", val);
    try {
      await vasthuUrl
        .post(
          `vasthu/openapi/createorderapi`,
          {
            userUuid: userId,
            offerId: val,
            price: price,
            currency: "356",
            returnUrl: "homevalidation",
            lng:lang
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          BillDeskPay(res.data, BillDeskHandler)
            .then((config) => {
              console.log("home form bill pay", config);
              window.loadBillDeskSdk(config);
            })
            .catch((err) => console.log("home form bill err", err));
        });
    } catch (err) {
      console.log(err);
    }
  };

  // Home step1 -----> Home details state
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [hArea, setHArea] = useState("");
  const [hOwner, setHOwner] = useState("");
  const [hConstruct, setHConstruct] = useState("");
  const [hMainDoorGap, setHMainDoorGap] = useState("");
  const [hFacingWidth, setHfacingWidth] = useState(0);
  const [hMainDoorWidth, setHMainDoorWidth] = useState("");
  const [mainDoorInch, setMainDoorInch] = useState("");
  console.log("hArea", hArea);
  console.log("hOwner", hOwner);

  // main door distance inche
  const [mainDoorDistanceInch, setMainDoorDistanceInch] = useState("");

  const [hFacing, setHFacing] = useState("");
  const [hlagna, setHlagna] = useState("");
  const [tab, setTab] = useState("homeform");
  const [alertShow, setAlertShow] = useState(false);
  const [hErrMsg, setHErrMsg] = useState("");
  //
  const [perimeterFt, setPerimeterFt] = useState("");
  const [perimeterInch, setPerimeterInch] = useState("");

  //Home step2 floor and elements state

  const [showFloor, setShowFloor] = useState(false);
  const [alertFloorDelete, setAlertFloorDelete] = useState(false);
  const [showElem, setShowElem] = useState(false);
  const [showUpdateElem, setShowUpdateElem] = useState(false);
  const [floor, setFloor] = useState([]);
  console.log("floor", floor);
  const [fvalue, setFValue] = useState(null);
  const [floorName, setfloorName] = useState("");
  const [elementId, setelementId] = useState("");
  const [elementDirectionId, setelementDirectionId] = useState("");
  const [elementDirectionName, setelementDirectionName] = useState("");
  const [length, setLength] = useState("");
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [elementUpdateId, setelementUpdateId] = useState("");
  const [elementUpdateType, setelementUpdateType] = useState("");
  const [elementUpdateName, setelementUpdateName] = useState("");
  const [elementUpdateFacingName, setelementUpdateFacingName] = useState("");
  const [elementUpdateFacing, setelementUpdateFacing] = useState("");
  const [elementUpdateLength, setelementUpdateLength] = useState("");
  const [elementUpdateWidth, setelementUpdateWidth] = useState("");
  const [elementUpdateHeight, setelementUpdateHeight] = useState("");
  const [ferrMsg, setFErrMsg] = useState("");
  const [fuperrMsg, setFUPErrMsg] = useState("");
  const [rerrMsg, setRErrMsg] = useState(false);
  const [ruperrMsg, setRUPErrMsg] = useState(false);
  const [floading, setFLoading] = useState(false);
  const [temp, setTemp] = useState("");
  const [alertTemp, setAlertTemp] = useState({});
  const [elementUpdateDetails, setelementUpdateDetails] = useState({});
  const [floorUpdateId, setFloorUpdateId] = useState("");

  // home step-3 windows state values

  const [showWindowForm, setshowWindowForm] = useState(false);
  const [windowDirectionId, setwindowDirectionId] = useState();
  const [windowDirectionName, setwindowDirectionName] = useState("");
  const [werrMsg, setWErrMsg] = useState("");
  const [wuperrMsg, setWUErrMsg] = useState("");
  const [windowName, setWindowName] = useState("");
  const [wloading, setWLoading] = useState(false);
  const [windowArray, setWindowArray] = useState([]);
  console.log("windowArray", windowArray);

  // home step-4 miscellaneous state

  const [showmiscForm, setshowmiscForm] = useState(false);
  const [miscDirectionId, setmiscDirectionId] = useState();
  const [miscDirectionName, setmiscDirectionName] = useState("");
  const [miscNameId, setmiscNameId] = useState();
  const [miscName, setmiscName] = useState("");
  const [merrMsg, setMErrMsg] = useState("");
  const [muperrMsg, setMUErrMsg] = useState("");
  const [mmessage, setMMessage] = useState("");
  const [mloading, setMLoading] = useState(false);
  const [miscArray, setmiscArray] = useState([]);
  console.log("miscArray", miscArray);

  //home step-5 level state

  const [southLevel, setSouthLevel] = useState("");
  const [northLevel, setNorthLevel] = useState("");
  const [southWestLevel, setSouthWestLevel] = useState("");
  const [northEastLevel, setNorthEastLevel] = useState("");
  const [westLevel, setWestLevel] = useState("");
  const [eastLevel, setEastLevel] = useState("");
  const [level, setLevel] = useState({});
  const [lerrMsg, setLErrMsg] = useState("");
  const [lloading, setLLoading] = useState(false);
  const [finishAlert, setFinishAlert] = useState(false);
  console.log("southLevel", southLevel);
  console.log("northLevel", northLevel);
  console.log("southWestLevel", southWestLevel);
  console.log("northEastLevel", northEastLevel);
  console.log("westLevel", westLevel);
  console.log("eastLevel", eastLevel);

  // home step2 functions
  const handleClose = () => {
    setFValue("");
    setShowFloor(false);
  };

  const handleShow = () => setShowFloor(true);
  const handleSubmit = (val) => {
    if (val) {
      var temp = floorList.find((i) => i.name == val.name);
      console.log("temp", temp);
      const { id, name, ta_name, hi_name } = temp;
      var floorId = floor.map((i) => i.floor_id);
      if (!floorId.includes(id)) {
        const newObj = {
          floor_id: id,
          floorName: name,
          floorName_ta: ta_name,
          floorName_hi: hi_name,
          elementDetails: [],
        };
        setFloor([...floor, newObj]);
        setShowFloor(false);
        console.log("...floor, newObj", ...floor, newObj);
      } else {
        alert("Floor Already Exist");
        setMessageType("error");
      }
    } else {
      alert("Please Add Floor");
      setMessageType("error");
    }
  };

  const handleElemClose = () => {
    setShowElem(false);
    setelementId("");
    setelementDirectionId("");
    setLength("");
    setWidth("");
    setHeight("");
  };
  const handleElemUpdateClose = () => setShowUpdateElem(false);
  const handleElemUpdateSubmit = (floorId, elementUpdateId) => {
    const floorValue = floor.find((i) => i.floor_id === floorId);
    let elementValue = floorValue.elementDetails.find(
      (i) => i.elementUuid == elementUpdateId
    );
    let elementObj = elementList.find((i) => i.id == elementUpdateType);
    let elementDirectionObj = facingList.find(
      (i) => i.id == elementUpdateFacing
    );
    elementValue.elementId = elementUpdateType;
    elementValue.elementName = elementObj.name;
    elementValue.elementDirectionName = elementDirectionObj.name;
    elementValue.elementDirectionId = elementUpdateFacing;
    elementValue.width = elementUpdateWidth;
    elementValue.length = elementUpdateLength;
    elementValue.height = elementUpdateHeight;
    setFloor([...floor]);
    alert("Succesfully Updated");
    setMessageType("success");
    setFloorUpdateId("");
    setelementUpdateId("");
    setelementUpdateType("");
    setelementUpdateLength("");
    setelementUpdateWidth("");
    setelementUpdateHeight("");
    setShowUpdateElem(false);
  };
  const handleElemEdit = (floorId, elementObj) => {
    const {
      elementUuid,
      elementId,
      elementDirectionId,
      height,
      length,
      width,
    } = elementObj;
    setFloorUpdateId(floorId);
    setelementUpdateId(elementUuid);
    setelementUpdateType(elementId);
    setelementUpdateFacing(elementDirectionId);
    setelementUpdateLength(length);
    setelementUpdateWidth(width);
    setelementUpdateHeight(height);
    setShowUpdateElem(true);
  };

  const handleElemDelete = (floorId, elementId) => {
    const floorValue = floor.find((i) => i.floor_id == floorId);
    let elementArr = floorValue.elementDetails.filter(
      (i) => i.elementUuid !== elementId
    );
    floorValue.elementDetails = elementArr;
    setFloor([...floor]);
  };
  const handleElemShow = (val) => {
    setShowElem(true);
    const value = floor.find((i) => i.floor_id === val);
    setTemp(value.floor_id);
  };
  const handleElemSubmit = (val) => {
    if (val) {
      const data = floor.find((i) => i.floor_id === val);
      if (elementId && elementDirectionId && height && width && length) {
        let elementObj = elementList.find((i) => i.id == elementId);
        let elementDirectionObj = facingList.find(
          (i) => i.id == elementDirectionId
        );
        // let index = data.elementDetails.length
        data.elementDetails.push({
          elementUuid: uuidv4(),
          elementName: elementObj.name,
          elementNameTamil: elementObj.ta_name,
          elementNameHindi: elementObj.hi_name,
          elementDirectionName: elementDirectionObj.name,
          elementDirectionNameTamil: elementDirectionObj.ta_name,
          elementDirectionNameHindi: elementDirectionObj.hi_name,
          elementId,
          elementDirectionId,
          height,
          width,
          length,
        });
        setFloor([...floor]);
        setelementId("");
        setelementDirectionId("");
        setLength("");
        setWidth("");
        setHeight("");
        setShowElem(false);
      } else {
        alert("Please Enter Valid details");
        setMessageType("error");
      }
    } else {
      alert("Please Enter Valid details");
      setMessageType("error");
    }
  };

  const handleFloorDelete = (id) => {
    let value = floor.filter((i) => i.floor_id !== id);
    setFloor(value);
  };

  //Added for temp element details save
  const tempElementAllSubmit = async () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setProgressValue((prev) => prev + 20);
    setTab("window");
    try {
      const floorRes = await vasthuUrl.post(
        `saveelementdetailstemp`,
        {
          houseId: homeRec,
          floorDetails: floor,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLLoading(false);
    } catch (e) {
      alert("SERVER ERROR");
    }
    console.log("inside element ");
  };

  //saving home -- try

  const saveHouseDetails = async () => {
    vasthuUrl
      .post(
        `savehousedetails`,
        {
          userUUId: userId,
          is_constructed: false,
          ownerName: hOwner,
          lagnaId: hlagna,
          directionId: hFacing,
          building_area: hArea,
          building_front_width: hFacingWidth,
          maindoor_size: Number(hMainDoorWidth) + Number(mainDoorInch), // need to be verified [ also perimeter]
          maindoor_placement_gap: hMainDoorGap,
          perimeter: Number(perimeterFt) + Number(perimeterInch), // newly added for perimeter
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("form submit service response - original", res.data);
        // if (res.data.savedStatus) {
        //   alert(res.data.message);
        //   setMessageType("success");
        //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
        //   setProgressValue((prev) => prev + 20);
        //   addHomeRecId(res.data.homeUuid);
        //   setAlertShow(false);
        //   setLoading(false);
        //   setTab("floor");
        // } else {
        //   alert(res.data.message);
        //   setMessageType("error");
        //   setAlertShow(true);
        //   setLoading(true);
        // }
      });
  };

  //saving for home temp

  const savetemphouseDetails = async () => {
    vasthuUrl
      .post(
        `savetemporaryhousedetails`,
        {
          userUUId: userId,
          is_constructed: false,
          ownerName: hOwner,
          lagnaId: hlagna,
          directionId: hFacing,
          building_area: hArea,
          building_front_width: hFacingWidth,
          maindoor_size: Number(hMainDoorWidth) + Number(mainDoorInch), // need to be verified [ also perimeter]
          maindoor_placement_gap: hMainDoorGap,
          perimeter: Number(perimeterFt) + Number(perimeterInch), // newly added for perimeter
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("form submit service response", res.data);
        if (res.data.savedStatus) {
          alert(res.data.message);
          setMessageType("success");
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          setProgressValue((prev) => prev + 20);
          addHomeRecId(res.data.homeUuid);
          setAlertShow(false);
          setLoading(false);
          setTab("floor");
        } else {
          alert(res.data.message);
          setMessageType("error");
          setAlertShow(true);
          setLoading(true);
        }
      });
  };

  // home step1 functions
  const handleHsubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    console.log("hArea", hArea);
    console.log("hOwner", hOwner);
    try {
      console.log("hArea", hArea);
      console.log("hOwner", hOwner);
      if (
        !validator.isEmpty(hArea) &&
        !validator.isEmpty(hOwner) &&
        !validator.isEmpty(hFacing) &&
        !validator.isEmpty(hlagna) &&
        !validator.isEmpty(hMainDoorWidth) &&
        !validator.isEmpty(hFacingWidth) &&
        !(hFacingWidth > 120 || hFacingWidth <= 0) &&
        !validator.isEmpty(hConstruct) &&
        !validator.isEmpty(hMainDoorGap) &&
        (validator.isNumeric(hMainDoorGap) ||
          validator.isFloat(hMainDoorGap)) &&
        (validator.isNumeric(hFacingWidth) ||
          validator.isFloat(hFacingWidth)) &&
        (validator.isNumeric(hMainDoorWidth) ||
          validator.isFloat(hMainDoorWidth)) &&
        !validator.isEmpty(perimeterFt) &&
        (validator.isNumeric(perimeterFt) || validator.isFloat(perimeterFt))
      ) {
        setAlertShow(true);
      } else {
        throw new Error("Please fill all the fields correctly.");
      }
    } catch (error) {
      alert(error.message);
      setMessageType("error");
      setLoading(false);
    }
  };

  //home step-3 windows function
  const handleWindowShow = () => {
    setshowWindowForm(true);
  };

  const handleWindowClose = () => {
    setshowWindowForm(false);
    setwindowDirectionId("");
    console.log("WINDOW OBH", windowArray);
  };

  const handleWindowSubmit = () => {
    let windowCheckArr = windowArray.map((i) => i.windowName);
    console.log(windowName);
    console.log(
      windowCheckArr,
      windowName,
      !windowCheckArr.includes(windowName)
    );
    if (windowDirectionId && windowDirectionName != " " && windowName !== "") {
      console.log(windowName);
      if (!windowCheckArr.includes(windowName)) {
        let windowDirectionObj = facingList.find(
          (i) => i.id == windowDirectionId
        );
        windowArray.push({
          windowUuid: uuidv4(),
          windowDirectionName: windowDirectionObj.name,
          windowDirectionNameTamil: windowDirectionObj.ta_name,
          windowDirectionNameHindi: windowDirectionObj.hi_name,
          windowName,
          windowDirectionId,
        });
        setWindowArray([...windowArray]);
        setwindowDirectionId("");
        setWindowName("");
        setshowWindowForm(false);
      } else {
        alert("Window Name Already Exists");
        setMessageType("error");
      }
    } else {
      alert("PLEASE FILL THE DETAILS ");
      setMessageType("error");
    }
  };

  const handleWinDelete = (wUuid) => {
    let windowArr = windowArray.filter((i) => i.windowUuid !== wUuid);

    console.log("DELETED ARR", windowArr);
    setWindowArray(windowArr);
  };

  const windowAllSubmit = async () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setProgressValue((prev) => prev + 20);
    console.log("inside--window");
    setTab("misc");
    console.log("windowArray" + windowArray.length + "   " + windowArray);
    try {
      console.log("windowArray" + windowArray);
      const windowRes = await vasthuUrl.post(
        `savewindowdetailstemp`,
        {
          homeUuid: homeRec,
          windows: windowArray,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLLoading(false);
    } catch (e) {
      alert("SERVER ERROR");
    }
  };

  //home step-4 miscellaneous functions
  const handlemiscShow = () => {
    setshowmiscForm(true);
  };

  const handlemiscClose = () => {
    setmiscDirectionId("");
    setmiscNameId("");
    setmiscDirectionName("");
    setmiscName("");
    setshowmiscForm(false);
    console.log("misc OBH", miscArray);
  };

  const handlemiscSubmit = () => {
    let miscCheckArr = miscArray.map((i) => i.miscName);
    console.log(miscName);
    console.log(miscCheckArr, miscName, !miscCheckArr.includes(miscName));
    if (miscDirectionId && miscDirectionName != " " && miscName && miscNameId) {
      // if (!miscCheckArr.includes(miscName)) {
      let miscDirectionObj = facingList.find((i) => i.id == miscDirectionId);
      let miscNameObj = miscList.find((i) => i.id == miscNameId);
      miscArray.push({
        miscellaneousUuid: uuidv4(),
        miscDirectionName: miscDirectionObj.name,
        miscDirectionNameTamil: miscDirectionObj.ta_name,
        miscDirectionNameHindi: miscDirectionObj.hi_name,
        miscName: miscNameObj.name,
        miscNameTamil: miscNameObj.ta_name,
        miscNameHindi: miscNameObj.hi_name,
        miscellaneousLocationId: miscDirectionId,
        miscellaneousId: miscNameId,
      });
      setmiscArray([...miscArray]);
      setmiscDirectionId("");
      setmiscNameId("");
      setmiscDirectionName("");
      setmiscName("");
      setshowmiscForm(false);
      console.log("miscellaneous array", miscArray);
    } else {
      alert("PLEASE FILL THE DETAILS");
      setMessageType("error");
    }
  };

  const handleMiscDelete = (mUuid) => {
    let miscArr = miscArray.filter((i) => i.miscUuid !== mUuid);

    console.log("DELETED ARR", miscArr);
    setmiscArray(miscArr);
  };

  const miscAllSubmit = async () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setProgressValue((prev) => prev + 20);
    console.log("inside miscRes ");
    setTab("level");
    try {
      const miscRes = await vasthuUrl.post(
        `savemiscellaneousdetailstemp`,
        {
          homeUuid: homeRec,
          miscellaneous: miscArray,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLLoading(false);
    } catch (e) {
      alert("SERVER ERROR");
    }
  };

  //home step-5 leveling
  const levelAllSubmit = async () => {
    if (
      southLevel === northLevel ||
      southWestLevel === northEastLevel ||
      westLevel === eastLevel
    ) {
      alert("Same Levels is not Valid");
      setMessageType("error");
      return; // Exit the function here
    }

    if (
      southLevel &&
      northLevel &&
      southWestLevel &&
      northEastLevel &&
      westLevel &&
      eastLevel != ""
    ) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setProgressValue((prev) => prev + 20);
      setFinishAlert(true);
    } else {
      alert("PLEASE SELECT ALL DIRECTION LEVEL");
      setMessageType("error");
    }

    try {
      const levelRes = await vasthuUrl.post(
        "saveleveldetailstemp",
        {
          houseUuid: homeRec,
          southLevel: southLevel,
          northLevel: northLevel,
          southWestLevel: southWestLevel,
          northEastLevel: northEastLevel,
          westLevel: westLevel,
          eastLevel: eastLevel,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLLoading(false);
    } catch (e) {
      alert("SERVER ERROR");
    }
  };

  // save & Pay Later Function for Home Validation
  const SavePayLater = async () => {
    setLLoading(true);

    try {
      //

      // const floorRes = await vasthuUrl.post(
      //   `saveelementdetails`,
      //   {
      //     houseId: homeRec,
      //     floorDetails: floor,
      //   },
      //   {
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //     },
      //   }
      // );
      // const windowRes = await vasthuUrl.post(
      //   `savewindowdetails`,
      //   {
      //     homeUuid: homeRec,
      //     windows: windowArray,
      //   },
      //   {
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //     },
      //   }
      // );

      // const miscRes = await vasthuUrl.post(
      //   `savemiscellaneousdetails`,
      //   {
      //     homeUuid: homeRec,
      //     miscellaneous: miscArray,
      //   },
      //   {
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //     },
      //   }
      // );

      // const levelRes = await vasthuUrl.post(
      //   "saveleveldetails",
      //   {
      //     houseUuid: homeRec,
      //     southLevel: southLevel,
      //     northLevel: northLevel,
      //     southWestLevel: southWestLevel,
      //     northEastLevel: northEastLevel,
      //     westLevel: westLevel,
      //     eastLevel: eastLevel,
      //   },
      //   {
      //     headers: {
      //       Authorization: `Bearer ${token}`,
      //     },
      //   }
      // );
      ///

      ///
      ///
      const updated_pay_status = vasthuUrl.post(
        `updatepaystatus`,
        {
          houseId: homeRec,
          payStatus: "PAY_LATER",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log("AFTER_update_pay_status" + updated_pay_status);

      setLLoading(false);
      navigate("/buildingdetails", {
        state: { key: "payLaterRecords" },
      });
    } catch (e) {
      alert("SERVER ERROR");
      setMessageType("error");
      setLLoading(false);
    }
  };

  //paylater new as handle finish

  const handlePayLaterNew = async () => {
    setLLoading(true);

    vasthuUrl
      .get(`getTempHouseDetails/${homeRec}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("temphousedetails " + res.data);

        console.log("temphousedetails  " + res);

        console.log(
          "temphousedetails  ownerName" +
            res.data.ownerName +
            " " +
            res.data.uuid
        );

        //

        vasthuUrl
          .post(
            `savehousedetails`,
            {
              userUUId: userId,
              is_constructed: res.data._constructed,
              ownerName: res.data.ownerName,
              lagnaId: res.data.lagna.id,
              directionId: res.data.direction.id,
              building_area: res.data.building_area,
              building_front_width: res.data.building_front_width,
              maindoor_size: res.data.maindoor_size,
              maindoor_placement_gap: res.data.maindoor_placement_gap,
              perimeter: res.data.perimeter,
              uuid: res.data.uuid,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(async (res1) => {
            console.log("temp saved to housebuilding success" + res1.data);

            console.log("handle finish", {
              homeRec,
              floor,
              token,
              windowArray,
              miscArray,
              southLevel,
              northLevel,
              southWestLevel,
              northEastLevel,
              westLevel,
              eastLevel,
            });
            if (
              homeRec &&
              floor &&
              token &&
              windowArray &&
              miscArray &&
              southLevel &&
              northLevel &&
              southWestLevel &&
              northEastLevel &&
              westLevel &&
              eastLevel
            ) {
              try {
                setTimeout(() => {
                  const floorRes = vasthuUrl.post(
                    `saveelementdetails`,
                    {
                      houseId: homeRec,
                      floorDetails: floor,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  );
                  const windowRes = vasthuUrl.post(
                    `savewindowdetails`,
                    {
                      homeUuid: homeRec,
                      windows: windowArray,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  );

                  const miscRes = vasthuUrl.post(
                    `savemiscellaneousdetails`,
                    {
                      homeUuid: homeRec,
                      miscellaneous: miscArray,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  );

                  const levelRes = vasthuUrl.post(
                    "saveleveldetails",
                    {
                      houseUuid: homeRec,
                      southLevel: southLevel,
                      northLevel: northLevel,
                      southWestLevel: southWestLevel,
                      northEastLevel: northEastLevel,
                      westLevel: westLevel,
                      eastLevel: eastLevel,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  );
                  // setLLoading(false);
                  // navigate("/homevalidation");

                  const updated_pay_status = vasthuUrl.post(
                    `updatepaystatus`,
                    {
                      houseId: homeRec,
                      payStatus: "PAY_LATER",
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  );

                  console.log("AFTER_update_pay_status" + updated_pay_status);

                  setLLoading(false);
                  navigate("/buildingdetails", {
                    state: { key: "payLaterRecords" },
                  });
                }, 5000);
              } catch (e) {
                alert("SERVER ERROR");
                setMessageType("error");
                setLLoading(false);
              }
            } else {
              alert("Data missing");
              setMessageType("error");
              setLLoading(false);
            }
          })
          .catch((err) =>
            console.log("temp saved to housebuilding failed" + err)
          );
      })
      .catch((er) => console.log(" getting temp housebuilding failed" + er));
  };

  //home complete submit func
  const handleFinish = async () => {
    setLLoading(true);

    vasthuUrl
      .get(`getTempHouseDetails/${homeRec}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("temphousedetails " + res.data);

        console.log("temphousedetails  " + res);

        console.log(
          "temphousedetails  ownerName" +
            res.data.ownerName +
            " " +
            res.data.uuid
        );

        //

        vasthuUrl
          .post(
            `savehousedetails`,
            {
              userUUId: userId,
              is_constructed: res.data._constructed,
              ownerName: res.data.ownerName,
              lagnaId: res.data.lagna.id,
              directionId: res.data.direction.id,
              building_area: res.data.building_area,
              building_front_width: res.data.building_front_width,
              maindoor_size: res.data.maindoor_size,
              maindoor_placement_gap: res.data.maindoor_placement_gap,
              perimeter: res.data.perimeter,
              uuid: res.data.uuid,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(async (res1) => {
            console.log("temp saved to housebuilding success" + res1.data);

            console.log("handle finish", {
              homeRec,
              floor,
              token,
              windowArray,
              miscArray,
              southLevel,
              northLevel,
              southWestLevel,
              northEastLevel,
              westLevel,
              eastLevel,
            });
            if (
              homeRec &&
              floor &&
              token &&
              windowArray &&
              miscArray &&
              southLevel &&
              northLevel &&
              southWestLevel &&
              northEastLevel &&
              westLevel &&
              eastLevel
            ) {
              try {
                setTimeout(() => {
                  const floorRes = vasthuUrl.post(
                    `saveelementdetails`,
                    {
                      houseId: homeRec,
                      floorDetails: floor,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  );
                  const windowRes = vasthuUrl.post(
                    `savewindowdetails`,
                    {
                      homeUuid: homeRec,
                      windows: windowArray,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  );

                  const miscRes = vasthuUrl.post(
                    `savemiscellaneousdetails`,
                    {
                      homeUuid: homeRec,
                      miscellaneous: miscArray,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  );

                  const levelRes = vasthuUrl.post(
                    "saveleveldetails",
                    {
                      houseUuid: homeRec,
                      southLevel: southLevel,
                      northLevel: northLevel,
                      southWestLevel: southWestLevel,
                      northEastLevel: northEastLevel,
                      westLevel: westLevel,
                      eastLevel: eastLevel,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  );
                  try {
                    //
                    vasthuUrl
                      .get(`/subscription/userActiveOffers/${userId}/2`, {
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      })
                      .then((list) => {
                        console.log(
                          "homeservice-list-if available " +
                            JSON.stringify(list.data) +
                            "length " +
                            list.data.length
                        );
                        setActiveServiceList(list.data);

                        if (list.data.length > 0) {
                          // if (JSON.stringify(list.data) !== "[]") {
                          openModal();
                        } else {
                          console.log(
                            "no subscription - please proceed with pay"
                          );
                          setLoading(false);
                          handleBuy(
                            defaultHomeOffer.offerId,
                            defaultHomeOffer.offerPrice
                          );
                        }
                        //
                      });
                  } catch (error) {}
                  setLLoading(false);
                  // navigate("/homevalidation");
                }, 5000);
              } catch (e) {
                alert("SERVER ERROR");
                setMessageType("error");
                setLLoading(false);
              }
            } else {
              alert("Data missing");
              setMessageType("error");
              setLLoading(false);
            }
          })
          .catch((err) =>
            console.log("temp saved to housebuilding failed" + err)
          );
      })
      .catch((er) => console.log(" getting temp housebuilding failed" + er));
  };

  const [showModal, setShowModal] = useState(false); // State to manage modal visibility

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const useHouseData = (id) => {
    setShowModal(false);
    houseDetailsWithUuid(id.id);
    setHOwner(id.ownerName);
    setHConstruct(id.constructed.toString());
    setPerimeterFt(id.perimeter.toString());
    setHfacingWidth(id.building_front_width.toString());
    setHMainDoorWidth(id.maindoor_size.toString());
    setHMainDoorGap(id.maindoor_placement_gap.toString());
    setHlagna(id.lagnaId.toString());
    setHFacing(id.directionId.toString());
    setHArea(id.building_area.toString());
  };

  const houseDetailsWithUuid = (id) => {
    vasthuUrl
      .get(`/getTempElementDetails/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("res.data", res.data);
        setFloor(res.data.floorDetails);
      });
    vasthuUrl
      .get(`/getTempWinowDetails/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("res.data", res.data);
        // res.data.map(win => {
        //   win.directionid = win.directionid.toString()

        // })
        setWindowArray(res.data);
        console.log("winupdate" + res.data + "win" + windowArray);
      });
    vasthuUrl
      .get(`/getTempMiscDetails/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("res.data", res.data);
        setmiscArray(res.data);
      });
    vasthuUrl
      .get(`/getTempLevelDetails/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("res.data", res.data[0]);
        if (res.data[0]) {
          setSouthLevel(res.data[0].southLevel.toString());
          setNorthLevel(res.data[0].northLevel.toString());
          setWestLevel(res.data[0].westLevel.toString());
          setEastLevel(res.data[0].eastLevel.toString());
          setSouthWestLevel(res.data[0].southWestLevel.toString());
          setNorthEastLevel(res.data[0].northEastLevel.toString());
        }
      });
  };

  // home step1 home details form jsx
  const CustomerHomeForm = () => {
    return (
      <React.Fragment>
        <Modal
          show={alertShow}
          onHide={() => {
            setAlertShow(false);
            setLoading(false);
          }}
        >
          <Modal.Header className="all_background" closeButton>
            <Modal.Title>{t("USR.VSH.HFTAB.MDL.CNF")}</Modal.Title>{" "}
          </Modal.Header>

          <Modal.Body className="text-center" as="h3">
            {t("USR.VSH.HFTAB.MDL.CNF.ALRT1")}
          </Modal.Body>
          <Modal.Body className="text-center" as="h5">
            {t("USR.VSH.HFTAB.MDL.CNF.WARN")}
          </Modal.Body>
          <Modal.Footer
            style={{ display: "flex", justifyContent: "space-evenly" }}
          >
            <Button
              className="all_btn"
              style={{
                borderRadius: 50,
              }}
              onClick={() => {
                setAlertShow(false);
                setLoading(false);
              }}
              variant="outline-warning"
            >
              {t("USR.VSH.HFTAB.MDL.CNF.BTN.CNC")}
            </Button>
            <Button
              className="all_btn"
              style={{
                borderRadius: 50,
              }}
              onClick={() => {
                savetemphouseDetails();
                // setTimeout(() => {
                //   saveHouseDetails(); ////saving home -- try
                // }, 1000);
              }}
            >
              {t("USR.VSH.HFTAB.MDL.CNF.BTN.CNF")}
            </Button>
          </Modal.Footer>
        </Modal>

        <Row className="d-flex justify-content-center">
          <Col sm={0} md={3}>
            {" "}
          </Col>
          <Col sm={12} md={6}>
            <Card className="d-flex justify-content-center all_border">
              <Card.Header
                className="all_background"
                as="h4"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div style={{ width: "90%" }}>
                  <center>{t("USR.VSH.HFTAB.TL")}</center>
                </div>
                {houseTempData.length > 0 ? (
                  <OverlayTrigger
                    key="top"
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-top`}>
                        {t("USR.VSH.HFTAB.INP.LST")}
                      </Tooltip>
                    }
                  >
                    <Button
                      style={{
                        backgroundColor: "transparent",
                        border: "none",
                        padding: 0,
                        width: "max-content",
                        height: "max-content",
                      }}
                    >
                      <AiOutlineExclamationCircle
                        className="all_btn"
                        style={{
                          borderRadius: 50,
                          float: "right",
                        }}
                        size={25}
                        onClick={() => {
                          setShowModal(true);
                        }}
                      />
                    </Button>
                  </OverlayTrigger>
                ) : // <Button
                //   className="all_btn"
                // style={{
                //   borderRadius: 50,
                //   float: "right",
                // }}

                // >
                //   Previous Records
                // </Button>
                null}
              </Card.Header>
              <Card.Body>
                <Form className="mt-2">
                  <Form.Group className="mb-2" controlId="formHomeOwner">
                    <Form.Label style={{ fontSize: 20, fontWeight: "bold" }}>
                      {t("USR.VSH.HFTAB.LBL.HO")}
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        value={hOwner}
                        className="form_feild"
                        placeholder={t("USR.VSH.HFTAB.HO.PH")}
                        onChange={(e) => setHOwner(e.target.value)}
                      />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="mb-2" controlId="formHomeConstruct">
                    <Form.Label style={{ fontSize: 20, fontWeight: "bold" }}>
                      {t("USR.VSH.HFTAB.LBL.BT")}
                    </Form.Label>
                    <Form.Select
                      aria-label="New or Existing Building"
                      size="lg"
                      value={hConstruct}
                      className="form_feild"
                      onChange={(e) => {
                        setHConstruct(e.target.value);
                      }}
                    >
                      <option value="">{t("USR.VSH.HFTAB.BT.PH")}</option>
                      <option key="0" value="true">
                        {t("USR.VSH.HFTAB.BT.OP1")}
                      </option>
                      <option key="1" value="false">
                        {t("USR.VSH.HFTAB.BT.OP2")}
                      </option>
                    </Form.Select>
                  </Form.Group>

                  {/* perimeter */}

                  <Form.Group className="mb-2" controlId="formHomeDoorWidth">
                    <Form.Label style={{ fontSize: 20, fontWeight: "bold" }}>
                      {/* {t("USR.VSH.HFTAB.LBL.MDW")} */}
                      {t("USR.VFPR.TBL.CLMN.PR")}
                      {/* Perimeter */}
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        style={{ width: "50%" }}
                        type="number"
                        value={perimeterFt}
                        className="form_feild"
                        // placeholder={t("USR.VSH.HFTAB.FFW.PH")}
                        placeholder={t("USR.VFPR.TBL.CLMN.PR")}
                        onChange={(e) => setPerimeterFt(e.target.value)}
                      />
                      <InputGroup.Text className="form_feild">
                        ft
                      </InputGroup.Text>

                      <Form.Select
                        aria-label="inch"
                        size="sm"
                        value={perimeterInch}
                        className="form_feild"
                        onChange={(e) => {
                          setPerimeterInch(e.target.value);
                        }}
                      >
                        <option value={0}>{t("USR.VSH.HFTAB.MDW.PH2")}</option>

                        <option key={0} value={0.08}>
                          1 {t("USR.VSH.HFTAB.MDW.PH2")}
                        </option>
                        <option key={1} value={0.16}>
                          2 {t("USR.VSH.HFTAB.MDW.PH2")}
                        </option>
                        <option key={2} value={0.25}>
                          3 {t("USR.VSH.HFTAB.MDW.PH2")}
                        </option>
                        <option key={3} value={0.33}>
                          4 {t("USR.VSH.HFTAB.MDW.PH2")}
                        </option>
                        <option key={4} value={0.41}>
                          5 {t("USR.VSH.HFTAB.MDW.PH2")}
                        </option>
                        <option key={5} value={0.5}>
                          6 {t("USR.VSH.HFTAB.MDW.PH2")}
                        </option>
                        <option key={6} value={0.58}>
                          7 {t("USR.VSH.HFTAB.MDW.PH2")}
                        </option>
                        <option key={7} value={0.66}>
                          8 {t("USR.VSH.HFTAB.MDW.PH2")}
                        </option>
                        <option key={8} value={0.75}>
                          9 {t("USR.VSH.HFTAB.MDW.PH2")}
                        </option>
                        <option key={9} value={0.83}>
                          10 {t("USR.VSH.HFTAB.MDW.PH2")}
                        </option>
                        <option key={10} value={0.91}>
                          11 {t("USR.VSH.HFTAB.MDW.PH2")}
                        </option>
                      </Form.Select>
                      <InputGroup.Text className="form_feild">
                        in
                      </InputGroup.Text>
                    </InputGroup>
                    {/* <Form.Text>{t("USR.VSH.HFTAB.LBL.MDW")}</Form.Text> */}
                    {/* <Form.Text>{t("USR.VFPR.TBL.CLMN.PR")}</Form.Text> */}
                    <Form.Text>{t("USR.HV.HOMTAB.PFH")}</Form.Text>
                  </Form.Group>

                  {/* Perimeter end */}
                  <Form.Group className="mb-2" controlId="formHomeWidth">
                    <Form.Label style={{ fontSize: 20, fontWeight: "bold" }}>
                      {t("USR.VSH.HFTAB.LBL.FFW")}
                    </Form.Label>
                    <InputGroup hasValidation>
                      <Form.Control
                        type="number"
                        value={hFacingWidth}
                        className="form_feild"
                        isInvalid={hFacingWidth > 120 ? true : false}
                        max={120}
                        min={0}
                        placeholder={t("USR.VSH.HFTAB.FFW.PH")}
                        onChange={(e) => {
                          setHfacingWidth(e.target.value);
                        }}
                      />
                      <InputGroup.Text className="form_feild">
                        ft
                      </InputGroup.Text>
                    </InputGroup>
                    <Form.Text>
                      {t("USR.HF.HTXT1")}, {t("USR.HOM.VSH.FOM.HTXT2")}
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-2" controlId="formHomeDoorWidth">
                    <Form.Label style={{ fontSize: 20, fontWeight: "bold" }}>
                      {t("USR.VSH.HFTAB.LBL.MDW")}
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        style={{ width: "50%" }}
                        type="number"
                        value={hMainDoorWidth}
                        className="form_feild"
                        placeholder={t("USR.VSH.HFTAB.FFW.PH")}
                        onChange={(e) => setHMainDoorWidth(e.target.value)}
                      />
                      <InputGroup.Text className="form_feild">
                        ft
                      </InputGroup.Text>

                      <Form.Select
                        aria-label="inch"
                        size="sm"
                        value={mainDoorInch}
                        className="form_feild"
                        onChange={(e) => {
                          setMainDoorInch(e.target.value);
                        }}
                      >
                        <option value={0}>{t("USR.VSH.HFTAB.MDW.PH2")}</option>

                        <option key={0} value={0.08}>
                          1 {t("USR.VSH.HFTAB.MDW.PH2")}
                        </option>
                        <option key={1} value={0.16}>
                          2 {t("USR.VSH.HFTAB.MDW.PH2")}
                        </option>
                        <option key={2} value={0.25}>
                          3 {t("USR.VSH.HFTAB.MDW.PH2")}
                        </option>
                        <option key={3} value={0.33}>
                          4 {t("USR.VSH.HFTAB.MDW.PH2")}
                        </option>
                        <option key={4} value={0.41}>
                          5 {t("USR.VSH.HFTAB.MDW.PH2")}
                        </option>
                        <option key={5} value={0.5}>
                          6 {t("USR.VSH.HFTAB.MDW.PH2")}
                        </option>
                        <option key={6} value={0.58}>
                          7 {t("USR.VSH.HFTAB.MDW.PH2")}
                        </option>
                        <option key={7} value={0.66}>
                          8 {t("USR.VSH.HFTAB.MDW.PH2")}
                        </option>
                        <option key={8} value={0.75}>
                          9 {t("USR.VSH.HFTAB.MDW.PH2")}
                        </option>
                        <option key={9} value={0.83}>
                          10 {t("USR.VSH.HFTAB.MDW.PH2")}
                        </option>
                        <option key={10} value={0.91}>
                          11 {t("USR.VSH.HFTAB.MDW.PH2")}
                        </option>
                      </Form.Select>
                      <InputGroup.Text className="form_feild">
                        in
                      </InputGroup.Text>
                    </InputGroup>
                    <Form.Text>{t("USR.VSH.HFTAB.LBL.MDW")}</Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-2" controlId="formHomeDoorGap">
                    <Form.Label style={{ fontSize: 20, fontWeight: "bold" }}>
                      {t("USR.VSH.HFTAB.LBL.MDD")}
                    </Form.Label>
                    {/* <InputGroup>
                      <Form.Control
                        type="number"
                        value={hMainDoorGap}
                        placeholder={t("USR.VFPR.TBL.CLMN.MDG")}
                        onChange={(e) => hMainDoorGap(e.target.value)}
                      />
                      <InputGroup.Text>ft</InputGroup.Text>
                    </InputGroup> */}
                    <InputGroup>
                      <Form.Control
                        style={{ width: "50%" }}
                        type="number"
                        value={hMainDoorGap}
                        className="form_feild"
                        placeholder={t("USR.VSH.HFTAB.MDD.PH")}
                        onChange={(e) => setHMainDoorGap(e.target.value)}
                      />
                      <InputGroup.Text className="form_feild">
                        ft
                      </InputGroup.Text>

                      <Form.Select
                        aria-label="inch"
                        size="sm"
                        value={mainDoorDistanceInch}
                        className="form_feild"
                        onChange={(e) => {
                          setMainDoorDistanceInch(e.target.value);
                        }}
                      >
                        <option value={0}>{t("USR.VSH.HFTAB.MDW.PH2")}</option>

                        <option key={0} value={0.08}>
                          1 {t("USR.VSH.HFTAB.MDW.PH2")}
                        </option>
                        <option key={1} value={0.16}>
                          2 {t("USR.VSH.HFTAB.MDW.PH2")}
                        </option>
                        <option key={2} value={0.25}>
                          3 {t("USR.VSH.HFTAB.MDW.PH2")}
                        </option>
                        <option key={3} value={0.33}>
                          4 {t("USR.VSH.HFTAB.MDW.PH2")}
                        </option>
                        <option key={4} value={0.41}>
                          5 {t("USR.VSH.HFTAB.MDW.PH2")}
                        </option>
                        <option key={5} value={0.5}>
                          6 {t("USR.VSH.HFTAB.MDW.PH2")}
                        </option>
                        <option key={6} value={0.58}>
                          7 {t("USR.VSH.HFTAB.MDW.PH2")}
                        </option>
                        <option key={7} value={0.66}>
                          8 {t("USR.VSH.HFTAB.MDW.PH2")}
                        </option>
                        <option key={8} value={0.75}>
                          9 {t("USR.VSH.HFTAB.MDW.PH2")}
                        </option>
                        <option key={9} value={0.83}>
                          10 {t("USR.VSH.HFTAB.MDW.PH2")}
                        </option>
                        <option key={10} value={0.91}>
                          11 {t("USR.VSH.HFTAB.MDW.PH2")}
                        </option>
                      </Form.Select>
                      <InputGroup.Text className="form_feild">
                        in
                      </InputGroup.Text>
                    </InputGroup>
                    <Form.Text>{t("USR.VSH.HFTAB.MDD.HTXT")}</Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-2" controlId="formHomeLagna">
                    <Form.Label style={{ fontSize: 20, fontWeight: "bold" }}>
                      {t("USR.VSH.HFTAB.LG.PH")}
                    </Form.Label>
                    <Form.Select
                      aria-label={t("USR.VSH.HFTAB.LG.PH")}
                      size="lg"
                      value={hlagna}
                      className="form_feild"
                      onChange={(e) => {
                        setHlagna(e.target.value);
                      }}
                    >
                      <option>{t("USR.VSH.HFTAB.LG.PH")}</option>
                      {lagnaList.map((i, j) => (
                        <option key={j} value={i.id}>
                          {lang === "ta" ? (
                            <p>{i.ta_name}</p>
                          ) : lang === "hi" ? (
                            <p>{i.hi_name}</p>
                          ) : (
                            <p>{i.name}</p>
                          )}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formHomeFacing">
                    <Form.Label style={{ fontSize: 20, fontWeight: "bold" }}>
                      {t("USR.VSH.HFTAB.LBL.HF")}
                    </Form.Label>
                    <Form.Select
                      aria-label={t("USR.VSH.HFTAB.LBL.HF")}
                      size="lg"
                      value={hFacing}
                      className="form_feild"
                      onChange={(e) => {
                        setHFacing(e.target.value);
                      }}
                    >
                      <option value="">{t("USR.VSH.HFTAB.HF.PH")}</option>
                      {facingList.map((i, j) => (
                        <option key={j} value={i.id}>
                          {lang === "ta" ? (
                            <p>{i.ta_name}</p>
                          ) : lang === "hi" ? (
                            <p>{i.hi_name}</p>
                          ) : (
                            <p>{i.name}</p>
                          )}
                        </option>
                      ))}
                    </Form.Select>
                    <Form.Text className="text-muted">
                      {t("USR.VSH.HFTAB.HF.HTXT")}
                    </Form.Text>
                  </Form.Group>

                  <Form.Group className="mb-2" controlId="formHomeArea">
                    <Form.Label style={{ fontSize: 20, fontWeight: "bold" }}>
                      {t("USR.VSH.HFTAB.LBL.BA")}
                    </Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="number"
                        value={hArea}
                        className="form_feild"
                        placeholder={t("USR.VSH.HFTAB.BA.PH")}
                        onChange={(e) => setHArea(e.target.value)}
                      />
                      <InputGroup.Text className="form_feild">
                        Sq/Ft
                      </InputGroup.Text>
                    </InputGroup>
                    <Form.Text className="text-muted">
                      {t("USR.VSH.HFTAB.BA.HTXT")}
                    </Form.Text>
                  </Form.Group>
                  <Card.Body className="text-center">
                    {!loading ? (
                      <Button
                        className="all_btn"
                        style={{
                          borderRadius: 50,
                        }}
                        type="submit"
                        onClick={handleHsubmit}
                      >
                        {t("USR.VSH.HFTAB.BTN.SC")} <BsArrowRightCircleFill />
                      </Button>
                    ) : (
                      <Button className="all_btn" disabled>
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                        {t("USR.HOM.BTN.LOD")}
                      </Button>
                    )}
                  </Card.Body>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={0} md={3}>
            {" "}
          </Col>
        </Row>

        <Modal show={showModal} onHide={handleCloseModal} size="md">
          <Modal.Header className="all_background" closeButton>
            <h5 style={{ width: "90%", textAlign: "center" }}>
              {t("USR.HOM.VSH.TEMP.MOD")}
            </h5>
          </Modal.Header>
          <Modal.Body>
            <p style={{ color: "red", fontWeight: "bold" }}>
              {t("USR.HOM.VSH.TEMP.MOD.TXT")}
            </p>
            <Row>
              {houseTempData.map((i, j) => (
                <Col sm={12} md={6}>
                  <Card
                    className="all_border"
                    style={{
                      width: "100%",
                      marginBottom: "10px",
                      backgroundColor: "antiquewhite",
                    }}
                  >
                    <Card.Body>
                      <Card.Subtitle className="mb-2">
                        <b>{t("USR.VSH.HFTAB.LBL.HO")}:</b> {i.ownerName}
                      </Card.Subtitle>
                      <Card.Subtitle className="mb-2">
                        <b> {t("USR.VSH.HFTAB.LBL.BT")}:</b>{" "}
                        {i.constructed.toString()}
                      </Card.Subtitle>
                      <Card.Subtitle className="mb-2">
                        <b> {t("USR.VFPR.TBL.CLMN.PR")}:</b> {i.perimeter}
                      </Card.Subtitle>
                      <Card.Subtitle className="mb-2">
                        <b>{t("USR.VSH.HFTAB.LBL.FFW")}:</b>{" "}
                        {i.building_front_width}
                      </Card.Subtitle>
                      <Card.Subtitle className="mb-2">
                        <b>{t("USR.VSH.HFTAB.LBL.MDW")}:</b> {i.maindoor_size}
                      </Card.Subtitle>
                      <Card.Subtitle className="mb-2">
                        <b>{t("USR.VSH.HFTAB.LBL.MDD")}:</b>{" "}
                        {i.maindoor_placement_gap}
                      </Card.Subtitle>
                      <Card.Subtitle className="mb-2">
                        <b>{t("USR.VSH.HFTAB.LG.PH")}:</b> {i.lagnaId}
                      </Card.Subtitle>
                      <Card.Subtitle className="mb-2">
                        <b>{t("USR.VSH.HFTAB.LBL.HF")}:</b> {i.directionId}
                      </Card.Subtitle>
                      <Card.Subtitle className="mb-2">
                        <b>{t("USR.VSH.HFTAB.LBL.BA")}:</b> {i.building_area}
                      </Card.Subtitle>
                    </Card.Body>
                    <Button
                      className="m-2 all_btn"
                      onClick={() => {
                        useHouseData(i);
                      }}
                    >
                      {t("BTN.USE.NOW.LO")}
                    </Button>
                  </Card>
                </Col>
              ))}
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button className="all_btn" onClick={handleCloseModal}>
              {t("TXT.CLO.LO")}
            </Button>
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    );
  };

  // home step2 ----> floor & elements
  const HomeFormScreen = () => {
    return (
      <Container>
        <div className="d-flex justify-content-around">
          <Button
            className="mb-2 all_btn"
            style={{
              borderRadius: 50,
            }}
            onClick={handleShow}
          >
            {t("USR.VSH.FLTAB.BTN.AF")}
          </Button>
          {floor.filter((i) => i.floor_id !== 14).length > 0 &&
          floor.filter((i) => i.floor_id !== 14)[0].elementDetails.length >
            0 ? (
            <Button
              className="mb-2 all_btn"
              style={{
                borderRadius: 50,
              }}
              onClick={tempElementAllSubmit}
            >
              {t("TXT.NXT.UP")}
              <BsArrowRightCircleFill />
            </Button>
          ) : (
            // <Button
            //   variant="outline-warning"
            //   style={{
            //     borderRadius: 50,
            //     color: "black",
            //     fontWeight: "bold",
            //   }}
            //   onClick={() => {
            //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
            //     setProgressValue((prev) => prev + 20);
            //     setTab("window");
            //   }}
            // >
            //   {t("USR.VSH.FLTAB.BTN.SKP")}
            // </Button>
            <></>
          )}
        </div>

        <Alert show={alertFloorDelete} variant="danger">
          <Alert.Heading style={{ display: "flex" }}>
            {t("USR.VFP.HV.DL.INFO")} &nbsp;
            {lang === "ta" ? (
              <p>{alertTemp.floorName_ta}</p>
            ) : lang === "hi" ? (
              <p>{alertTemp.floorName_hi}</p>
            ) : (
              <p>{alertTemp.floorName}</p>
            )}
            &nbsp;?{/* {alertTemp.floorName}? */}
          </Alert.Heading>
          <p>{t("USR.VFP.HOM.VD.DF")}</p>
          <hr />
          <div className="justify-content-evenly d-flex">
            <Button
              className="all_btn"
              onClick={() => setAlertFloorDelete(false)}
              style={{
                borderRadius: 50,
              }}
            >
              {t("USR.VSH.HFTAB.MDL.CNF.BTN.CNC")}
            </Button>
            {"   "}
            <Button
              className="all_btn"
              style={{
                borderRadius: 50,
              }}
              onClick={() => {
                handleFloorDelete(alertTemp.floor_id);
                setAlertFloorDelete(false);
              }}
            >
              {t("USR.VSH.HFTAB.MDL.CNF.BTN.CNF")}
            </Button>
          </div>
        </Alert>

        {floor.length > 0 ? (
          floor.map((i, j) => (
            // <React.Fragment key={j}>
            <Accordion key={j} className="mt-2" defaultActiveKey={j}>
              <Accordion.Item eventKey={j} className="accordian_style">
                <Accordion.Header className="all_background">
                  {
                    <h6>
                      {lang === "ta" ? (
                        <p>{i.floorName_ta}</p>
                      ) : lang === "hi" ? (
                        <p>{i.floorName_hi}</p>
                      ) : (
                        <p>{i.floorName}</p>
                      )}
                      {/* {i.floorName} */}
                    </h6>
                  }
                  {/* need to verify */}
                  {/* {floorList.map((i, j) => (
                    <option key={j} value={i.id}>
                      {lang === "ta" ? (
                        <p>{i.ta_name}</p>
                      ) : lang === "hi" ? (
                        <p>{i.hi_name}</p>
                      ) : (
                        <p>{i.name}</p>
                      )}
                    </option>
                  ))} */}
                </Accordion.Header>
                <Accordion.Body>
                  <div className="my-2 d-flex justify-content-evenly">
                    <Button
                      className="mb-2 all_btn"
                      style={{
                        borderRadius: 50,
                      }}
                      onClick={() => handleElemShow(i.floor_id)}
                    >
                      {t("USR.VSH.FLTAB.ACR_BTN.AR")}
                    </Button>
                    {"  "}
                    <Button
                      className="mb-2 all_btn"
                      style={{
                        borderRadius: 50,
                      }}
                      onClick={() => {
                        setAlertFloorDelete(true);
                        setAlertTemp(i);
                      }}
                    >
                      {t("USR.VSH.FLTAB.ACR_BTN.DEL")}
                    </Button>
                    {/* <Button
                      className="mb-2 all_btn"
                      style={{
                        borderRadius: 50,
                      }}
                      onClick={() => handleElemShow(i.floor_id)}
                    >
                      +
                    </Button> */}
                  </div>
                  {i.elementDetails.length > 0 ? (
                    <Table
                      key={j}
                      striped
                      bordered
                      hover
                      responsive
                      style={{ borderColor: "#ffb33e" }}
                    >
                      <thead className="text-center">
                        <tr>
                          <th>{t("USR.VSH.FLTAB.ACR.TBL.CLMN.RN")}</th>
                          <th>{t("USR.VSH.FLTAB.ACR.TBL.CLMN.LN")}</th>
                          <th>{t("USR.VSH.FLTAB.ACR.TBL.CLMN.WD")}</th>
                          <th>{t("USR.VSH.FLTAB.ACR.TBL.CLMN.LT")}</th>
                          <th>{t("USR.VSH.FLTAB.ACR.TBL.CLMN.HG")}</th>
                          <th>{t("USR.VSH.FLTAB.ACR.TBL.CLMN.ACT")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {i.elementDetails.map((x, y) => (
                          <tr key={y}>
                            <td>
                              {lang == "en"
                                ? x.elementName
                                : lang == "ta"
                                ? x.elementNameTamil
                                : x.elementNameHindi}
                            </td>
                            <td>
                              {lang == "en"
                                ? x.elementDirectionName
                                : lang == "ta"
                                ? x.elementDirectionNameTamil
                                : x.elementDirectionNameHindi}
                            </td>
                            <td>{x.width}</td>
                            <td>{x.length}</td>
                            <td>{x.height}</td>

                            <td className="d-flex  align-items-baseline justify-content-evenly">
                              <Button
                                size="sm"
                                style={{
                                  borderRadius: 50,
                                }}
                                onClick={() => {
                                  handleElemEdit(i.floor_id, x);
                                }}
                                className="mt-2 all_btn"
                              >
                                {t("USR.VSH.FLTAB.BTN.EDT")}
                              </Button>
                              {"  "}
                              <Button
                                size="sm"
                                style={{
                                  borderRadius: 50,
                                }}
                                onClick={() =>
                                  handleElemDelete(i.floor_id, x.elementUuid)
                                }
                                className="mt-2 all_btn"
                              >
                                {t("USR.VSH.FLTAB.ACR_BTN.DEL")}
                              </Button>
                              <Button
                                className="mb-2 all_btn"
                                size="sm"
                                style={{
                                  borderRadius: 50,
                                }}
                                onClick={() => handleElemShow(i.floor_id)}
                              >
                                {t("USR.VSH.FLTAB.ACR_BTN.AR")}
                              </Button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    <div className="my-4">
                      <center>
                        <h6>{t("USR.VSH.FLTAB.ACR.HTXT")}</h6>
                      </center>
                    </div>
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))
        ) : (
          <div className="my-4">
            <center>
              <h4>{t("USR.VSH.FLTAB.INF")}</h4>
            </center>
          </div>
        )}

        {floor.filter((i) => i.floor_id !== 14).length > 0 &&
          floor.filter((i) => i.floor_id !== 14)[0].elementDetails.length >
            0 && (
            <div className="my-4">
              <center>
                {!loading ? (
                  <Button
                    className="all_btn"
                    style={{
                      borderRadius: 50,
                    }}
                    onClick={tempElementAllSubmit}
                  >
                    {t("USR.VSH.FLTAB.BTN.SC")} <BsArrowRightCircleFill />
                  </Button>
                ) : (
                  <Button
                    className="all_btn"
                    style={{
                      borderRadius: 50,
                    }}
                    disabled
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    {t("USR.HOM.BTN.LOD")}
                  </Button>
                )}
              </center>
            </div>
          )}

        <Modal show={showFloor} onHide={handleClose}>
          <Modal.Header className="all_background" closeButton>
            <Modal.Title>{t("USR.VSH.FLTAB.INF")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-2" controlId="floor">
                <Form.Select
                  aria-label="floor"
                  size="lg"
                  className="form_feild"
                  onChange={(e) => {
                    if (e.target.value) {
                      let floorObj = floorList.find(
                        (i) => i.id == e.target.value
                      );
                      console.log("floorObj", floorObj);
                      setFValue(floorObj);
                    } else {
                      setFValue("");
                    }
                  }}
                >
                  <option key={0} value="">
                    {t("USR.VSH.FLTAB.MDL.DD.PH")}
                  </option>
                  {floorList.map((x, y) => (
                    <option key={y} value={x.id}>
                      {lang === "ta" ? (
                        <p>{x.ta_name}</p>
                      ) : lang === "hi" ? (
                        <p>{x.hi_name}</p>
                      ) : (
                        <p>{x.name}</p>
                      )}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Form>
            <div className="modelbutton text-center  d-flex  justify-content-evenly">
              <Button
                className="mt-3 all_btn"
                style={{
                  borderRadius: 50,
                }}
                onClick={handleClose}
              >
                {t("USR.VSH.HFTAB.MDL.CNF.BTN.CNC")}
              </Button>
              {"  "}
              <Button
                className="mt-3 all_btn"
                style={{
                  borderRadius: 50,
                }}
                onClick={() => {
                  handleSubmit(fvalue);
                  setFValue(null);
                }}
              >
                {t("USR.VFP.DRP.BTN.SMT")}
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={showElem} onHide={handleElemClose}>
          <Modal.Header className="all_background" closeButton>
            <Modal.Title>{t("USR.VSH.FLTAB.ACR_BTN.AR")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <center>
              <h5>{temp.floorName}</h5>
            </center>
            <Form>
              <Form.Group className="mb-1" controlId="rooms">
                <Form.Select
                  aria-label="rooms"
                  size="lg"
                  value={elementId}
                  className="form_feild"
                  onChange={(e) => {
                    if (e.target.value) {
                      const obj = floorList.find((i) => i.id == e.target.value);
                      setelementId(e.target.value);
                      setfloorName(obj.name);
                    } else {
                      setelementId("");
                      setfloorName("");
                    }
                  }}
                >
                  <option key={0} value="">
                    {t("USR.VSH.FLTAB.MDL.AR.DD1.PH")}
                  </option>
                  {elementList.map((a, b) => (
                    <option key={b} value={a.id}>
                      {lang === "ta" ? (
                        <p>{a.ta_name}</p>
                      ) : lang === "hi" ? (
                        <p>{a.hi_name}</p>
                      ) : (
                        <p>{a.name}</p>
                      )}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-1" controlId="Room Location">
                <Form.Select
                  aria-label="Room Location"
                  size="lg"
                  value={elementDirectionId}
                  className="form_feild"
                  onChange={(e) => {
                    if (e.target.value) {
                      let obj = facingList.find((i) => i.id == e.target.value);
                      setelementDirectionId(e.target.value);
                      setelementDirectionName(obj.name);
                    } else {
                      setelementDirectionId("");
                      setelementDirectionName("");
                    }
                  }}
                >
                  <option key={0} value="">
                    {t("USR.VSH.FLTAB.MDL.AR.DD2.PH")}
                  </option>
                  {facingList.map((a, b) => (
                    <option key={b} value={a.id}>
                      {lang === "ta" ? (
                        <p>{a.ta_name}</p>
                      ) : lang === "hi" ? (
                        <p>{a.hi_name}</p>
                      ) : (
                        <p>{a.name}</p>
                      )}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-1">
                <FloatingLabel
                  className="mb-1"
                  controlId="width"
                  label={t("USR.VSH.FLTAB.MDL.AR.RW.PH")}
                >
                  <Form.Control
                    type="number"
                    placeholder={t("USR.VSH.FLTAB.MDL.AR.RW.PH")}
                    value={width}
                    className="form_feild"
                    onChange={(e) => {
                      setWidth(e.target.value);
                    }}
                  />
                </FloatingLabel>
                <FloatingLabel
                  className="mb-1"
                  controlId="length"
                  label={t("USR.VSH.FLTAB.MDL.AR.RL.PH")}
                >
                  <Form.Control
                    type="number"
                    placeholder={t("USR.VSH.FLTAB.MDL.AR.RL.PH")}
                    value={length}
                    className="form_feild"
                    onChange={(e) => {
                      setLength(e.target.value);
                    }}
                  />
                </FloatingLabel>
                <FloatingLabel
                  className="mb-1"
                  controlId="height"
                  label={t("USR.VSH.FLTAB.MDL.AR.RH.PH")}
                >
                  <Form.Control
                    type="number"
                    placeholder={t("USR.VSH.FLTAB.MDL.AR.RH.PH")}
                    value={height}
                    className="form_feild"
                    onChange={(e) => {
                      setHeight(e.target.value);
                    }}
                  />
                </FloatingLabel>
              </Form.Group>
            </Form>
            <div className="modelbutton text-center d-flex justify-content-evenly">
              <Button
                style={{
                  borderRadius: 50,
                }}
                className="mt-3 all_btn"
                onClick={handleElemClose}
              >
                {t("USR.VSH.HFTAB.MDL.CNF.BTN.CNC")}
              </Button>
              {"  "}
              <Button
                style={{
                  borderRadius: 50,
                }}
                className="mt-3 all_btn"
                onClick={() => handleElemSubmit(temp)}
              >
                {t("USR.VFP.DRP.BTN.SMT")}
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={showUpdateElem} onHide={handleElemUpdateClose}>
          <Modal.Header className="all_background" closeButton>
            <Modal.Title>{t("USR.VSH.FLTAB.MDL.UR.TL")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <center>
              <h5>{elementUpdateDetails.floorName}</h5>
            </center>
            <Form>
              <Form.Group className="mb-1" controlId="roomUpdate">
                <Form.Select
                  aria-label="roomUpdate"
                  size="lg"
                  value={elementUpdateType}
                  className="form_feild"
                  onChange={(e) => {
                    if (e.target.value) {
                      let obj = elementList.find((i) => i.id == e.target.value);
                      setelementUpdateType(e.target.value);
                      setelementUpdateName(obj.name);
                    } else {
                      setelementUpdateType("");
                      setelementUpdateName("");
                    }
                  }}
                >
                  <option key={0} value="">
                    {t("USR.VSH.FLTAB.MDL.AR.DD1.PH")}
                  </option>
                  {elementList.map((a, b) => (
                    <option key={b} value={a.id}>
                      {lang === "ta" ? (
                        <p>{a.ta_name}</p>
                      ) : lang === "hi" ? (
                        <p>{a.hi_name}</p>
                      ) : (
                        <p>{a.name}</p>
                      )}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-1" controlId="roomLocationUpdate">
                <Form.Select
                  aria-label="roomLocationUpdate"
                  size="lg"
                  value={elementUpdateFacing}
                  className="form_feild"
                  onChange={(e) => {
                    if (e.target.value) {
                      let obj = facingList.find((i) => i.id == e.target.value);
                      setelementUpdateFacing(e.target.value);
                      setelementUpdateFacingName(obj.name);
                    } else {
                      setelementUpdateFacing("");
                      setelementUpdateFacingName("");
                    }
                  }}
                >
                  <option key={0} value="">
                    {t("USR.VSH.FLTAB.MDL.AR.DD2.PH")}
                  </option>
                  {facingList.map((a, b) => (
                    <option key={b} value={a.id}>
                      {lang === "ta" ? (
                        <p>{a.ta_name}</p>
                      ) : lang === "hi" ? (
                        <p>{a.hi_name}</p>
                      ) : (
                        <p>{a.name}</p>
                      )}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-1">
                <FloatingLabel
                  className="mb-1"
                  controlId="roomwidthUpdate"
                  label={t("USR.VSH.FLTAB.MDL.AR.RW.PH")}
                >
                  <Form.Control
                    type="number"
                    placeholder={t("USR.VSH.FLTAB.MDL.AR.RW.PH")}
                    value={elementUpdateWidth}
                    className="form_feild"
                    onChange={(e) => {
                      setelementUpdateWidth(e.target.value);
                    }}
                  />
                </FloatingLabel>
                <FloatingLabel
                  className="mb-1"
                  controlId="roomlengthUpdate"
                  label={t("USR.VSH.FLTAB.MDL.AR.RL.PH")}
                >
                  <Form.Control
                    type="number"
                    placeholder={t("USR.VSH.FLTAB.MDL.AR.RL.PH")}
                    value={elementUpdateLength}
                    className="form_feild"
                    onChange={(e) => {
                      setelementUpdateLength(e.target.value);
                    }}
                  />
                </FloatingLabel>
                <FloatingLabel
                  className="mb-1"
                  controlId="roomheightUpdate"
                  label={t("USR.VSH.FLTAB.MDL.AR.RH.PH")}
                >
                  <Form.Control
                    type="number"
                    placeholder={t("USR.VSH.FLTAB.MDL.AR.RH.PH")}
                    value={elementUpdateHeight}
                    className="form_feild"
                    onChange={(e) => {
                      setelementUpdateHeight(e.target.value);
                    }}
                  />
                </FloatingLabel>
              </Form.Group>
            </Form>
            <div className="text-center  d-flex justify-content-evenly">
              <Button
                className="mt-3 all_btn"
                style={{
                  borderRadius: 50,
                }}
                onClick={handleElemUpdateClose}
              >
                {t("USR.VSH.HFTAB.MDL.CNF.BTN.CNC")}
              </Button>

              {"  "}
              <Button
                className="mt-3 all_btn"
                style={{
                  borderRadius: 50,
                }}
                onClick={() =>
                  handleElemUpdateSubmit(floorUpdateId, elementUpdateId)
                }
              >
                {t("USR.HOME.UPD.BTN")}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
      </Container>
    );
  };

  //HomeStep3 ----> windows
  const HomeWindow = () => {
    return (
      <Container>
        <div className="d-flex justify-content-around">
          <Button
            className=" mb-2 all_btn"
            style={{
              borderRadius: 50,
            }}
            onClick={() => {
              setActiveStep((prevActiveStep) => prevActiveStep - 1);
              setProgressValue((prev) => prev - 20);
              setTab("floor");
            }}
          >
            <BsArrowLeftCircleFill /> {t("USR.VSH.WINTAB.BTN.PRE")}
          </Button>
          <Button
            className=" mb-2 all_btn"
            style={{
              borderRadius: 50,
            }}
            onClick={handleWindowShow}
            // className="mt-2"
          >
            {t("USR.VSH.WINTAB.BTN.AW")}
          </Button>
          {windowArray.length > 0 ? (
            !loading ? (
              <Button
                className=" mb-2 all_btn"
                style={{
                  borderRadius: 50,
                }}
                onClick={windowAllSubmit}
              >
                {t("TXT.NXT.UP")} <BsArrowRightCircleFill />
              </Button>
            ) : (
              <Button
                className=" mb-2 all_btn"
                style={{
                  borderRadius: 50,
                }}
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                {t("USR.HOM.BTN.LOD")}
                <BsArrowRightCircleFill />
              </Button>
            )
          ) : (
            <Button
              className=" mb-2 all_btn"
              style={{
                borderRadius: 50,
              }}
              onClick={() => {
                setActiveStep((prevActiveStep) => prevActiveStep + 1);
                setProgressValue((prev) => prev + 20);
                setTab("misc");
              }}
            >
              {t("USR.VSH.FLTAB.BTN.SKP")} <BsArrowRightCircleFill />
            </Button>
          )}
        </div>
        <Modal show={showWindowForm} onHide={handleWindowClose}>
          <Modal.Header className="all_background" closeButton>
            <Modal.Title>{t("USR.VSH.WINTAB.BTN.AW")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <FloatingLabel
                className="mb-1"
                controlId="window"
                label={t("USR.VSH.WINTAB.MDL.AW.PH")}
              >
                <Form.Control
                  type="text"
                  placeholder={t("USR.VSH.WINTAB.MDL.AW.PH")}
                  value={windowName}
                  className="form_feild"
                  onChange={(e) => {
                    setWindowName(e.target.value.trim());
                  }}
                />
              </FloatingLabel>

              <Form.Group className="mb-1" controlId="element facing">
                <Form.Select
                  aria-label="window facing"
                  size="lg"
                  value={windowDirectionId}
                  className="form_feild"
                  onChange={(e) => {
                    console.log(e.target.value);
                    if (e.target.value) {
                      let obj = facingList.find((i) => i.id == e.target.value);
                      console.log("VALUE NAME" + e.target.value);
                      setwindowDirectionId(e.target.value);
                      setwindowDirectionName(obj.name);
                    } else {
                      setwindowDirectionId("");
                      setwindowDirectionName("");
                    }
                  }}
                >
                  <option key={0} value="">
                    {t("USR.VSH.WINTAB.MDL.DD.PH")}
                  </option>
                  {facingList.map((a, b) => (
                    <option key={b} value={a.id}>
                      {lang === "ta" ? (
                        <p>{a.ta_name}</p>
                      ) : lang === "hi" ? (
                        <p>{a.hi_name}</p>
                      ) : (
                        <p>{a.name}</p>
                      )}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Form>
            <div className="modelbutton text-center d-flex justify-content-evenly ">
              <Button
                className="mt-3 all_btn"
                style={{
                  borderRadius: 50,
                }}
                onClick={handleWindowClose}
              >
                {t("USR.VSH.HFTAB.MDL.CNF.BTN.CNC")}
              </Button>
              {"  "}
              <Button
                className="mt-3 all_btn"
                style={{
                  borderRadius: 50,
                }}
                onClick={handleWindowSubmit}
              >
                {t("USR.VFP.DRP.BTN.SMT")}
              </Button>
            </div>
          </Modal.Body>
        </Modal>

        {windowArray.length > 0 ? (
          <Table
            striped
            bordered
            hover
            responsive
            style={{ borderColor: "#ffb33e" }}
          >
            <thead>
              <tr>
                <th>{t("USR.VSH.WINTAB.MDL.AW.PH")}</th>
                <th>{t("USR.VSH.RES.ACR.WIN.TBL.CLMN.FS")}</th>
                <th>{t("USR.VSH.WINTAB.TBL.CLMN.ACT")}</th>
              </tr>
            </thead>

            {windowArray.map((i, j) => (
              <tbody key={j}>
                <tr key={j}>
                  <td>{i.windowName}</td>
                  <td>
                    {/* {t(i.windowDirectionName)} */}
                    {lang == "en"
                      ? i.windowDirectionName
                      : lang == "ta"
                      ? i.windowDirectionNameTamil
                      : i.windowDirectionNameHindi}
                  </td>
                  <td>
                    <Button
                      style={{
                        borderRadius: 50,
                      }}
                      onClick={() => handleWinDelete(i.windowUuid)}
                      className="mt-2 all_btn"
                    >
                      {t("USR.VSH.FLTAB.ACR_BTN.DEL")}
                    </Button>
                  </td>
                </tr>
              </tbody>
            ))}
          </Table>
        ) : (
          <div className="my-4">
            <center>
              <h4>{t("USR.VSH.WINTAB.HTXT")}</h4>
            </center>
          </div>
        )}
      </Container>
    );
  };

  //home step4 ---->  miscellaneous
  const HomeMiscellaneous = () => {
    return (
      <Container>
        <center>
          <div className="d-flex justify-content-around ">
            <Button
              className="mb-2 all_btn"
              style={{
                borderRadius: 50,
              }}
              onClick={() => {
                setActiveStep((prevActiveStep) => prevActiveStep - 1);
                setProgressValue((prev) => prev - 20);
                setTab("window");
              }}
            >
              <BsArrowLeftCircleFill /> {t("USR.VSH.WINTAB.BTN.PRE")}
            </Button>
            <Button
              className="mb-2 all_btn"
              style={{
                borderRadius: 50,
              }}
              onClick={handlemiscShow}
            >
              {t("USR.VSH.MISCTAB.BTN.AM")}
            </Button>
            {miscArray.length > 0 ? (
              !mloading ? (
                <Button
                  className="mb-2 all_btn"
                  style={{
                    borderRadius: 50,
                  }}
                  onClick={miscAllSubmit}
                >
                  {t("TXT.NXT.UP")}
                  <BsArrowRightCircleFill />
                </Button>
              ) : (
                <Button
                  className="mb-2 all_btn"
                  style={{
                    borderRadius: 50,
                  }}
                  disabled
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  {t("USR.HOM.BTN.LOD")}
                  <BsArrowRightCircleFill />
                </Button>
              )
            ) : (
              <Button
                className="mb-2 all_btn"
                style={{
                  borderRadius: 50,
                }}
                onClick={() => {
                  setActiveStep((prevActiveStep) => prevActiveStep + 1);
                  setProgressValue((prev) => prev + 20);
                  setTab("level");
                }}
              >
                {t("USR.VSH.FLTAB.BTN.SKP")} <BsArrowRightCircleFill />
              </Button>
            )}
          </div>
        </center>

        <Modal show={showmiscForm} onHide={handlemiscClose}>
          <Modal.Header className="all_background" closeButton>
            <Modal.Title>{t("USR.VSH.MISCTAB.BTN.AM")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-1" controlId="miscellaneous name">
                <Form.Select
                  aria-label="miscellaneous name"
                  size="lg"
                  value={miscNameId}
                  className="form_feild"
                  onChange={(e) => {
                    if (e.target.value) {
                      let obj = miscList.find((i) => i.id == e.target.value);
                      console.log("VALUE NAME" + e.target.value);
                      setmiscNameId(e.target.value);
                      setmiscName(obj.name);
                    } else {
                      setmiscNameId("");
                      setmiscName("");
                    }
                  }}
                >
                  <option key={0} value="">
                    {t("USR.VSH.MISCTAB.MDL.DD1.PH")}
                  </option>
                  {miscList.map((a, b) => (
                    <option key={b} value={a.id}>
                      {lang === "ta" ? (
                        <p>{a.ta_name}</p>
                      ) : lang === "hi" ? (
                        <p>{a.hi_name}</p>
                      ) : (
                        <p>{a.name}</p>
                      )}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-1" controlId="element facing">
                <Form.Select
                  aria-label="miscellaneous facing"
                  size="lg"
                  value={miscDirectionId}
                  className="form_feild"
                  onChange={(e) => {
                    console.log(e.target.value);
                    if (e.target.value) {
                      let obj = facingList.find((i) => i.id == e.target.value);
                      console.log("VALUE NAME" + e.target.value);
                      setmiscDirectionId(e.target.value);
                      setmiscDirectionName(obj.name);
                    } else {
                      setmiscDirectionId("");
                      setmiscDirectionName("");
                    }
                  }}
                >
                  <option key={0} value="">
                    {t("USR.VSH.MISCTAB.MDL.DD2.PH")}
                  </option>
                  {facingList.map((a, b) => (
                    <option key={b} value={a.id}>
                      {lang === "ta" ? (
                        <p>{a.ta_name}</p>
                      ) : lang === "hi" ? (
                        <p>{a.hi_name}</p>
                      ) : (
                        <p>{a.name}</p>
                      )}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Form>
            <div className="modelbutton text-center d-flex  justify-content-evenly">
              <Button
                className="mt-3 all_btn"
                style={{
                  borderRadius: 50,
                }}
                onClick={handlemiscClose}
              >
                {t("USR.VSH.HFTAB.MDL.CNF.BTN.CNC")}
              </Button>

              {"  "}
              <Button
                className="mt-3 all_btn"
                style={{
                  borderRadius: 50,
                }}
                onClick={handlemiscSubmit}
              >
                {t("USR.VFP.DRP.BTN.SMT")}
              </Button>
            </div>
          </Modal.Body>
        </Modal>
        {miscArray.length > 0 ? (
          <Table
            className="mt-2"
            striped
            bordered
            hover
            responsive
            style={{ borderColor: "#ffb33e" }}
          >
            <thead>
              <tr>
                <th>{t("USR.VSH.MISCTAB.TBL.CLMN.MN")}</th>
                <th>{t("USR.VSH.RES.ACR.WIN.TBL.CLMN.FS")}</th>
                <th>{t("USR.VSH.WINTAB.TBL.CLMN.ACT")}</th>
              </tr>
            </thead>
            {miscArray.map((i, j) => (
              <tbody key={j}>
                <tr key={j}>
                  <td>
                    {lang == "en"
                      ? i.miscName
                      : lang == "ta"
                      ? i.miscNameTamil
                      : i.miscNameHindi}
                  </td>
                  <td>
                    {lang == "en"
                      ? i.miscDirectionName
                      : lang == "ta"
                      ? i.miscDirectionNameTamil
                      : i.miscDirectionNameHindi}
                  </td>
                  <td>
                    <Button
                      style={{
                        borderRadius: 50,
                      }}
                      onClick={() => handleMiscDelete(i.miscUuid)}
                      className="mt-2 all_btn"
                    >
                      {t("USR.VSH.FLTAB.ACR_BTN.DEL")}
                    </Button>
                  </td>
                </tr>
              </tbody>
            ))}
          </Table>
        ) : (
          <div className="my-4">
            <center>
              <h4>{t("USR.VSH.MISCTAB.HTXT")}</h4>
            </center>
          </div>
        )}
      </Container>
    );
  };

  //Home Step5 ----> levels
  const HomeLevel = () => {
    return (
      <Container>
        <center>
          <h4>{t("USR.VSH.LVLTAB.TL")}</h4>
        </center>

        {/* alert newly added */}
        <Modal2
          onClose={handleClosealert}
          open={open}
          style={{
            position: "absolute",
            border: "2px solid #000",
            backgroundColor: "gray",
            boxShadow: "2px solid black",
            height: 80,
            width: 240,
            margin: "auto",
          }}
        >
          <h2>TXN FAILURE</h2>
        </Modal2>
        {/* alert newly added end*/}

        <Modal
          show={finishAlert}
          onHide={() => {
            setFinishAlert(false);
          }}
        >
          <Modal.Header className="all_background" closeButton>
            <Modal.Title>{t("USR.VSH.HFTAB.MDL.CNF")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* {t("USR.VSH.HFTAB.MDL.CNF")} */}
            <p>{t("USR.HOM.VSH.SAV.PLA.MOD")}</p>
            {lloading && (
              <center>
                <Spinner animation="border" size="sm" />
              </center>
            )}
          </Modal.Body>
          <Modal.Footer className="homevasthubtn">
            <Button
              className="mb-2 all_btn homevasthubutton"
              onClick={() => {
                setFinishAlert(false);
              }}
            >
              {t("TXT.CLO.UP")}
            </Button>
            <Button
              className="mb-2 all_btn homevasthubutton"
              onClick={() => {
                setFinishAlert(false);
                // SavePayLater();
                handlePayLaterNew();
              }}
            >
              {t("USR.HOM.BTN.SPL")}
            </Button>
            <Button
              className="mb-2 all_btn homevasthubutton"
              onClick={() => {
                setFinishAlert(false);
                handleFinish();
              }}
            >
              {t("USR.VFP.DRP.BTN.SMT")}
            </Button>
          </Modal.Footer>
        </Modal>
        <div>
          <Modal
            show={modalIsOpen}
            onHide={() => {
              setIsOpen(false);
            }}
          >
            <Modal.Header className="all_background" closeButton>
              <Modal.Title>{t("USR.BOK.UR.PLS.TL")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form>
                <p>
                  {/* Already you have some offer palns. If you want to proceed or
                  Buy new offer to click Buy Offer. */}
                  {t("USR.BOK.CHK.UR.PLN.TXT")}
                </p>
                {/* {JSON.stringify(activeServiceList)} */}
                <div className="row text-center">
                  {activeServiceList.length > 0 ? (
                    activeServiceList.map((i, j) => (
                      <div className="col-sm-12 col-md-4 col-lg-3 g-1" key={j}>
                        <div className="card all_border">
                          <div className="card-body">
                            <h4 className="card-title">{i.offerName}</h4>
                            <p className="card-title">{i.offerFrequency}</p>
                            <h3 className="card-text">
                              ₹{i.offerPrice} <s>400</s>
                            </h3>
                            <h3 className="card-text">
                              {t("USR.ACC.ACT.REM.TL")} {i.availableQty}
                            </h3>
                            {i.offerEndDate ? (
                              <p className="card-title mt-1">
                                {t("USR.ACC.ACT.VAL.TL")} {i.offerEndDate}
                              </p>
                            ) : null}
                            {i.offerDetails
                              ? i.offerDetails.map((x, y) => (
                                  <li
                                    className="list-group-item d-flex justify-content-between align-items-center"
                                    key={y}
                                  >
                                    {x.serviceName}({x.serviceCostType})
                                    <span className="badge bg-primary rounded-pill">
                                      {" "}
                                      {x.count} {x.count > 1 ? "times" : "time"}
                                    </span>
                                  </li>
                                ))
                              : null}
                            <div className="mt-3">
                              <Button
                                className="all_btn"
                                onClick={() => {
                                  // navigate("/plotvalidate",{state:{offerId:i.id}})
                                  // setLoading(false)
                                  navigate("/homevalidation", {
                                    state: { offerId: i.id },
                                  });
                                  setLLoading(false);
                                  closeModal();
                                }}
                              >
                                {/* {t("USR.BTN.PROC")} */}
                                {t("USR.BOK.UR.PLN.PRCED")}
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <h4>{t("USR.PR.PRTAB.HTXT")}</h4>
                  )}
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer className="homevasthubtn">
              <Button className="mt-3 all_btn" onClick={closeModal}>
                {/* {t("USR.VSH.MISCTAB.MDL.DD.BTN.CAN")} */}
                {t("USR.VSH.HFTAB.MDL.CNF.BTN.CNC")}
              </Button>
              <Button
                className="mt-3 all_btn"
                onClick={() =>
                  handleBuy(
                    defaultHomeOffer.offerId,
                    defaultHomeOffer.offerPrice
                  )
                }
              >
                {t("USR.BOK.UR.PLN.BUY")}
              </Button>
            </Modal.Footer>
          </Modal>
        </div>

        <Stack gap={3}>
          <Card>
            <Card.Header className="all_background" as="h6">
              <center>{t("USR.VSH.LVLTAB.CHK.SXN.TL")}</center>
            </Card.Header>

            <Stack direction="horizontal" gap={1}>
              <Card.Body>
                <Form.Group className="mb-2" controlId="formSouthLevel">
                  <Form.Label style={{ fontSize: 14, fontWeight: "bold" }}>
                    {t("USR.VSH.LVLTAB.SXN.LB.ST")}
                  </Form.Label>
                  <Form.Select
                    aria-label="south"
                    size="lg"
                    value={southLevel}
                    className="form_feild"
                    onChange={(e) => {
                      if (e) {
                        setSouthLevel(e.target.value);
                      } else {
                        setSouthLevel("");
                      }
                    }}
                  >
                    <option key={0} value={""}>
                      {t("USR.VSH.LVLTAB.SXN.ST.DD.PH")}
                    </option>

                    <option key={1} value={0}>
                      {t("USR.VSH.LVLTAB.DD.ITM.LW")}
                    </option>
                    <option key={2} value={1}>
                      {t("USR.VSH.LVLTAB.DD.ITM.HG")}
                    </option>
                  </Form.Select>
                </Form.Group>
              </Card.Body>

              {"  "}
              <Badge pill bg="secondary">
                {/* <h6>{t("USR.VSH.LVLTAB.CHK.SXN.TL")}</h6> */}
                <h6>VS</h6>
              </Badge>
              {"  "}
              <Card.Body>
                <Form.Group className="mb-2" controlId="formNorthLevel">
                  <Form.Label style={{ fontSize: 14, fontWeight: "bold" }}>
                    {t("USR.VSH.LVLTAB.SXN.LB.NR")}
                  </Form.Label>
                  <Form.Select
                    aria-label="north"
                    size="lg"
                    value={northLevel}
                    className="form_feild"
                    onChange={(e) => {
                      if (e) {
                        setNorthLevel(e.target.value);
                      } else {
                        setNorthLevel("");
                      }
                    }}
                  >
                    <option key={0} value={""}>
                      {t("USR.VSH.LVLTAB.SXN.NR.DD.PH")}
                    </option>

                    <option key={1} value={0}>
                      {t("USR.VSH.LVLTAB.DD.ITM.LW")}
                    </option>
                    <option key={2} value={1}>
                      {t("USR.VSH.LVLTAB.DD.ITM.HG")}
                    </option>
                  </Form.Select>
                </Form.Group>
              </Card.Body>
            </Stack>
          </Card>

          <Card>
            <Card.Header className="all_background" as="h6">
              <center>{t("USR.VSH.LVLTAB.CHK.SWXNE.TL")}</center>
            </Card.Header>

            <Stack direction="horizontal" gap={1}>
              <Card.Body>
                <Form.Group className="mb-2" controlId="formSouthWestLevel">
                  <Form.Label style={{ fontSize: 14, fontWeight: "bold" }}>
                    {t("USR.VSH.LVLTAB.SWXNE.LB.ST")}
                  </Form.Label>
                  <Form.Select
                    aria-label="southwest"
                    size="lg"
                    value={southWestLevel}
                    className="form_feild"
                    onChange={(e) => {
                      if (e) {
                        setSouthWestLevel(e.target.value);
                      } else {
                        setSouthWestLevel("");
                      }
                    }}
                  >
                    <option key={0} value={""}>
                      {t("USR.VSH.LVLTAB.SWXNE.ST.DD.PH")}
                    </option>

                    <option key={1} value={0}>
                      {t("USR.VSH.LVLTAB.DD.ITM.LW")}
                    </option>
                    <option key={2} value={1}>
                      {t("USR.VSH.LVLTAB.DD.ITM.HG")}
                    </option>
                  </Form.Select>
                </Form.Group>
              </Card.Body>

              {"  "}
              <Badge pill bg="secondary">
                <h6>VS</h6>
              </Badge>
              {"  "}
              <Card.Body>
                <Form.Group className="mb-2" controlId="formNorthEastLevel">
                  <Form.Label style={{ fontSize: 14, fontWeight: "bold" }}>
                    {t("USR.VSH.LVLTAB.SWXNE.LB.NR")}
                  </Form.Label>
                  <Form.Select
                    aria-label="northeast"
                    size="lg"
                    value={northEastLevel}
                    className="form_feild"
                    onChange={(e) => {
                      if (e) {
                        setNorthEastLevel(e.target.value);
                      } else {
                        setNorthEastLevel("");
                      }
                    }}
                  >
                    <option key={0} value={""}>
                      {t("USR.VSH.LVLTAB.SWXNE.NR.DD.PH")}
                    </option>

                    <option key={1} value={0}>
                      {t("USR.VSH.LVLTAB.DD.ITM.LW")}
                    </option>
                    <option key={2} value={1}>
                      {t("USR.VSH.LVLTAB.DD.ITM.HG")}
                    </option>
                  </Form.Select>
                </Form.Group>
              </Card.Body>
            </Stack>
          </Card>

          <Card>
            <Card.Header className="all_background" as="h6">
              <center>{t("USR.VSH.LVLTAB.CHK.WXE.TL")}</center>
            </Card.Header>

            <Stack direction="horizontal" gap={1}>
              <Card.Body>
                <Form.Group className="mb-2" controlId="formWestLevel">
                  <Form.Label style={{ fontSize: 14, fontWeight: "bold" }}>
                    {t("USR.VSH.LVLTAB.WXE.LB.ST")}
                  </Form.Label>
                  <Form.Select
                    aria-label="west"
                    size="lg"
                    value={westLevel}
                    className="form_feild"
                    onChange={(e) => {
                      if (e) {
                        setWestLevel(e.target.value);
                      } else {
                        setWestLevel("");
                      }
                    }}
                  >
                    <option key={0} value={""}>
                      {t("USR.VSH.LVLTAB.WXE.ST.DD.PH")}
                    </option>

                    <option key={1} value={0}>
                      {t("USR.VSH.LVLTAB.DD.ITM.LW")}
                    </option>
                    <option key={2} value={1}>
                      {t("USR.VSH.LVLTAB.DD.ITM.HG")}
                    </option>
                  </Form.Select>
                </Form.Group>
              </Card.Body>

              {"  "}
              <Badge pill bg="secondary">
                <h6>VS</h6>
              </Badge>
              {"  "}
              <Card.Body>
                <Form.Group className="mb-2" controlId="formEastLevel">
                  <Form.Label style={{ fontSize: 14, fontWeight: "bold" }}>
                    {t("USR.VSH.LVLTAB.WXE.LB.NR")}
                  </Form.Label>
                  <Form.Select
                    aria-label="east"
                    size="lg"
                    value={eastLevel}
                    className="form_feild"
                    onChange={(e) => {
                      if (e) {
                        setEastLevel(e.target.value);
                      } else {
                        setEastLevel("");
                      }
                    }}
                  >
                    <option key={0} value={""}>
                      {t("USR.VSH.LVLTAB.WXE.NR.DD.PH")}
                    </option>

                    <option key={1} value={0}>
                      {t("USR.VSH.LVLTAB.DD.ITM.LW")}
                    </option>
                    <option key={2} value={1}>
                      {t("USR.VSH.LVLTAB.DD.ITM.HG")}
                    </option>
                  </Form.Select>
                </Form.Group>
              </Card.Body>
            </Stack>
          </Card>
        </Stack>
      </Container>
    );
  };

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#784af4",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#784af4",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));
  return (
    <React.Fragment>
      <Stepper
        activeStep={activeStep}
        alternativeLabel
        connector={<QontoConnector />}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const labelProps = {};

          return (
            <Step key={label} {...stepProps}>
              <StepLabel {...labelProps}>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <Tabs
        activeKey={tab}
        onSelect={(k) => setTab(k)}
        id="justify-tab-example"
        className="mb-3"
        justify
      >
        <Tab
          eventKey="homeform"
          title={
            <>
              <SiHomeadvisor
                style={tab === "homeform" && { color: "#f0ad4e" }}
                size={30}
              />
              <h6
                style={
                  tab === "homeform"
                    ? { fontWeight: "bold", color: "#f0ad4e", fontSize: 10 }
                    : { fontWeight: "bold", fontSize: 10 }
                }
              >
                {t("USR.VSH.STP.1")}
              </h6>
            </>
          }
          disabled={true}
        >
          {CustomerHomeForm()}
        </Tab>
        <Tab
          eventKey="floor"
          title={
            <>
              <GiBrickWall
                style={tab === "floor" && { color: "#f0ad4e" }}
                size={30}
              />
              <h6
                style={
                  tab === "floor"
                    ? { fontWeight: "bold", color: "#f0ad4e", fontSize: 10 }
                    : { fontWeight: "bold", fontSize: 10 }
                }
              >
                {t("USR.VSH.STP.2")}
              </h6>
            </>
          }
          disabled={true}
        >
          {HomeFormScreen()}
        </Tab>
        <Tab
          eventKey="window"
          title={
            <>
              <GiWindow
                style={tab === "window" && { color: "#f0ad4e" }}
                size={30}
              />
              <h6
                style={
                  tab === "window"
                    ? { fontWeight: "bold", color: "#f0ad4e", fontSize: 10 }
                    : { fontWeight: "bold", fontSize: 10 }
                }
              >
                {t("USR.VSH.STP.3")}
              </h6>
            </>
          }
          disabled={true}
        >
          {HomeWindow()}
        </Tab>
        <Tab
          eventKey="misc"
          title={
            <>
              <AiTwotoneBuild
                style={tab === "misc" && { color: "#f0ad4e" }}
                size={30}
              />
              <h6
                style={
                  tab === "misc"
                    ? { fontWeight: "bold", color: "#f0ad4e", fontSize: 10 }
                    : { fontWeight: "bold", fontSize: 10 }
                }
              >
                {t("USR.VSH.STP.4")}
              </h6>
            </>
          }
          disabled={true}
        >
          {HomeMiscellaneous()}
        </Tab>
        <Tab
          eventKey="level"
          title={
            <>
              <GiLevelEndFlag
                style={tab === "level" && { color: "#f0ad4e" }}
                size={30}
              />
              <h6
                style={
                  tab === "level"
                    ? { fontWeight: "bold", color: "#f0ad4e", fontSize: 10 }
                    : { fontWeight: "bold", fontSize: 10 }
                }
              >
                {t("USR.VSH.STP.5")}
              </h6>
            </>
          }
          disabled={true}
        >
          {/* <div className="d-flex justify-content-around ">
            <Button
              className="mb-2 all_btn"
              style={{
                borderRadius: 50,
              }}
              onClick={() => {
                setActiveStep((prevActiveStep) => prevActiveStep - 1);
                setProgressValue((prev) => prev - 20);
                setTab("misc");
              }}
            >
              <BsArrowLeftCircleFill /> {t("USR.VSH.WINTAB.BTN.PRE")}
            </Button>
            {!lloading ? (
              <Button
                className="mb-2 all_btn"
                style={{
                  borderRadius: 50,
                }}
                onClick={levelAllSubmit}
              >
                {t("USR.VFP.DRP.BTN.SMT")}
              </Button>
            ) : (
              <Button
                className="all_btn"
                style={{
                  borderRadius: 50,
                }}
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                {t("USR.HOM.BTN.LOD")}
              </Button>
            )}
          </div> */}

          <div className="d-flex justify-content-around mt-2 ">
            <Button
              className="mb-2 all_btn"
              style={{
                borderRadius: 50,
              }}
              onClick={() => {
                setActiveStep((prevActiveStep) => prevActiveStep - 1);
                setProgressValue((prev) => prev - 20);
                setTab("misc");
              }}
            >
              <BsArrowLeftCircleFill /> {t("USR.VSH.WINTAB.BTN.PRE")}
            </Button>
            {!lloading ? (
              <Button
                className="mb-2 all_btn"
                style={{
                  borderRadius: 50,
                }}
                onClick={levelAllSubmit}
              >
                {t("USR.VFP.DRP.BTN.SMT")}
              </Button>
            ) : (
              <Button
                className="all_btn"
                style={{
                  borderRadius: 50,
                }}
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                {t("USR.HOM.BTN.LOD")}
              </Button>
            )}
          </div>
          {HomeLevel()}
          {!lloading ? (
            <center>
              <Button
                className="mb-2 all_btn mt-3"
                style={{
                  borderRadius: 50,
                }}
                onClick={levelAllSubmit}
              >
                {t("USR.VFP.DRP.BTN.SMT")}
              </Button>
            </center>
          ) : (
            <center>
              <Button
                className=" mt-3 all_btn"
                style={{
                  borderRadius: 50,
                }}
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                {t("USR.HOM.BTN.LOD")}
              </Button>
            </center>
          )}
        </Tab>
      </Tabs>

      <Stacks spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleClosess}
        >
          <MuiAlert
            variant="filled"
            onClose={handleClosess}
            severity={messageType}
            sx={{
              width: "100%",
            }}
          >
            {message}
          </MuiAlert>
        </Snackbar>
      </Stacks>
    </React.Fragment>
  );
};

export default StepperUI;
