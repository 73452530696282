// import React, { useEffect, useState, Suspense } from "react";
// import { Navigate, NavLink, useLocation, useNavigate } from "react-router-dom";
// import { UseAuth } from "../context/Auth";
// import { vasthuUrl } from "../component/Api";

// //eye pwd
// import { FaEye } from "react-icons/fa";
// import { FaEyeSlash } from "react-icons/fa";

// import {
//   Form,
//   Button,
//   Container,
//   Row,
//   Col,
//   Card,
//   Spinner,
//   Alert,
//   InputGroup,
//   Modal,
// } from "react-bootstrap";
// import validator from "validator";
// import { InputAdornment, Button as MyButton } from "@material-ui/core";
// import { Label, Send } from "@material-ui/icons";
// import { useTranslation, Trans } from "react-i18next";
// import LangChager from "../component/LangChanger";
// import TextField from "@material-ui/core/TextField";
// import { FormControl } from "@mui/material";
// import Snackbar from "@mui/material/Snackbar";
// import MuiAlert from "@mui/material/Alert";
// import Stack from "@mui/material/Stack";

// import IconButton from "@mui/material/IconButton";
// import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// import ReactGA from "react-ga4";
// import { HorizontalRule } from "@mui/icons-material";

// const Login = () => {
//   const iniLang = localStorage.getItem("i18nextLng");
//   //eye pwd
//   const [showPassword, setShowPassword] = useState(false);
//   const [showcPassword, setShowcPassword] = useState(false);

//   //toast aleart
//   const [open, setOpen] = React.useState(false);
//   const [messageType, setMessageType] = useState("");
//   const [loginType, setLoginType] = useState("2");
//   const [userType, setUserType] = useState("");
//   console.log('loginType', loginType)
//   console.log('userType', userType)

//   const { handleLogin, loading, error, handleSocialLogin } = UseAuth();
//   const [phone, setPhone] = useState("");
//   const [password, setPass] = useState("");
//   const [message, setMessage] = useState("");

//   const { t } = useTranslation();
//   const location = useLocation();

//   const [show, setShow] = useState(false);
//   const handleShow = () => setShow(true);
//   const handleClose = () => {
//     setShow(false);
//     setOtp([]);
//     setDisabled(false);
//     setSeconds(0);
//     setMinutes(0);
//   };

//   const [shows, setShows] = useState(false);
//   const handleShows = () => setShows(true);
//   const handleCloses = () => {
//     setShows(false);
//     setOtp([]);
//     setPhoneNo([]);
//     setNPassword([]);
//     setCPassword([]);
//     setDisabled(false);
//     setSeconds(0);
//     setMinutes(0);
//   };

//   const [loginOtp, setLoginOtp] = useState(["", "", "", ""]);
//   const [user, setUser] = useState("");

//   const handleChange = (element, index) => {
//     if (isNaN(element.value)) return false;

//     setLoginOtp([
//       ...loginOtp.map((d, idx) => (idx === index ? element.value : d)),
//     ]);

//     if (element.nextSibling) {
//       element.nextSibling.focus();
//     }
//   };

//   const handleKeyUp = (element, index) => {
//     if (element.key === "Backspace" && element.target.previousSibling) {
//       element.target.previousSibling.focus();
//     }
//   };
//   const loginOTP = loginOtp.join("");

//   const [otp, setOtp] = useState("");
//   const [verifyOtp, setVerifyOtp] = useState("");
//   const [otpmsg, setOtpMsg] = useState("");
//   const [phoneNo, setPhoneNo] = useState("");
//   const [npassword, setNPassword] = useState("");
//   const [cpassword, setCPassword] = useState("");
//   const [minutes, setMinutes] = useState(0);
//   const [seconds, setSeconds] = useState(0);
//   const [disabled, setDisabled] = useState(false);
//   const [userdisabled, setUserDisabled] = useState(true);
//   const [countryCode, setCountryCode] = useState("0");

//   const referPath = () => {
//     if (location.otp) {
//       return location.otp.from.pathname;
//     } else {
//       return "/";
//     }
//   };

//   const alert = (msg) => {
//     setMessage(msg);
//     setOpen(true);
//     setTimeout(() => {
//       setMessage("");
//       setMessageType("");
//       setOpen(false);
//     }, 4000);
//   };

//   const alertType = (msg) => {
//     setMessageType(msg);
//   };

//   const onSubmit = (e) => {
//     e.preventDefault();
//     const path = referPath();
//     const phone_with_code = countryCode.concat(phone);
//     if (loginType == "1") {
//       if (
//         !validator.isEmpty(phone) &&
//         !validator.isEmpty(password) &&
//         validator.isMobilePhone(phone_with_code, [
//           "en-IN",
//           "en-SG",
//           "ms-MY",
//           "en-US",
//         ])
//       ) {
//         handleLogin({ phone: phone_with_code, password, path }, (res) => {
//           if (res === "success") {
//             setPhone("");
//             setPass("");
//             // GA test -- login success

//             ReactGA.event({
//               category: "User",
//               action: `Successfully Logged Event`,
//             });

//             window.dataLayer.push({ event: "my-custom-login" });

//             //
//           } else {
//             alert(t("USR.HOM.ERR.MSG1"));
//             alertType("error");
//           }
//         });
//       } else {
//         alert(t("USR.HOM.ERR.MSG1"));
//         alertType("error");
//       }
//     } else if (loginType == "2") {
//       if (!validator.isEmpty(phone) && !validator.isEmpty(loginOTP)) {
//         handleSocialLogin(
//           { user, phone: phone_with_code, loginOTP, path },
//           (res) => {
//             if (res === "success") {
//               setPhone("");
//               setUser("");
//               setLoginOtp("");
//               // GA test -- login success

//               ReactGA.event({
//                 category: "User",
//                 action: `Successfully Logged Event`,
//               });

//               window.dataLayer.push({ event: "my-custom-login" });

//               //
//             } else {
//               alert(t("USR.HOM.ERR.MSG1"));
//               alertType("error");
//             }
//           }
//         );
//       } else {
//         alert(t("USR.HOM.ERR.MSG1"));
//         alertType("error");
//       }
//     }
//   };

//   useEffect(() => {
//     const interval = setInterval(() => {
//       if (seconds > 0) {
//         setSeconds(seconds - 1);
//       }

//       if (seconds === 0) {
//         if (minutes === 0) {
//           clearInterval(interval);
//         } else {
//           setSeconds(59);
//           setMinutes(minutes - 1);
//         }
//       }
//     }, 1000);

//     return () => {
//       clearInterval(interval);
//     };
//   });

//   const sendOTP = async () => {
//     if (loginType == "1") {
//       if (!validator.isEmpty(phoneNo)) {
//         try {
//           const phone_code = countryCode.concat(phoneNo);
//           await vasthuUrl
//             // .post(`vasthu/user/requestotp`, {
//             .post(`vasthu/user/pwdresetotpsender`, {
//               phone_no: phone_code,
//               url: `${process.env.REACT_APP_SMS_DEL}`,
//               lng: iniLang,
//             })
//             .then(async (res) => {
//               setMinutes(0);
//               setSeconds(59);
//               console.log("res", res);
//               setVerifyOtp(res.data.otp_secret);
//               setOtpMsg(res.data.message);
//               alert(res.data.message);
//               setDisabled(true);
//               if (res.data.message === "Success") {
//                 alertType("success");
//               } else {
//                 alertType("error");
//               }
//             });
//         } catch (err) {
//           console.log(err);
//         }
//       } else {
//         alert("Please Enter Your Valid Phone Number!");
//         alertType("error");
//       }
//     } else if (loginType == "2") {
//       if (!validator.isEmpty(phone)) {
//         try {
//           const phone_code = countryCode.concat(phone);
//           await vasthuUrl
//             .post(`vasthu/user/requestotpnonuser`, {
//               name: user,
//               phone_no: phone_code,
//               url: `${process.env.REACT_APP_SMS_DEL}`,
//               lng: iniLang,
//             })
//             .then(async (res) => {
//               setMinutes(0);
//               setSeconds(59);
//               setDisabled(true);
//               setUserDisabled(true);
//               console.log("res", res);
//               // setVerifyOtp(res.data.otp_secret);
//               // setOtpMsg(res.data.message);
//               // alert(res.data.message);
//               if (res.data.message === "Success") {
//                 alert(res.data.message);
//                 alertType("success");
//               } else {
//                 alert(res.data.message);
//                 alertType("error");
//               }
//             });
//         } catch (err) {
//           console.log(err);
//         }
//       } else {
//         alert("Please Enter Your Valid Phone Number!");
//         alertType("error");
//       }
//     }
//   };

//   const Secmodal = async () => {
//     if (!validator.isEmpty(otp)) {
//       try {
//         const phone_code = countryCode.concat(phoneNo);
//         await vasthuUrl
//           .post(`vasthu/user/verifyotp`, {
//             otp: otp,
//             phone_no: phone_code,
//           })
//           .then(async (res) => {
//             console.log("res", res);
//             setOtp([]);
//             setDisabled(false);
//             setSeconds(0);
//             setMinutes(0);
//           });
//       } catch (err) {
//         console.log(err);
//       }
//     } else {
//       alert("Please Enter Your OTP!");
//       alertType("error");
//     }
//     if (otp === verifyOtp && otpmsg === "Success") {
//       handleClose();
//       handleShows();
//     } else {
//       alert("Please Enter Your Valid OTP!");
//       alertType("error");
//     }
//   };

//   const handleUpdate = async () => {
//     const phone_code = countryCode.concat(phoneNo);
//     if (
//       !validator.isEmpty(phoneNo) &&
//       !validator.isEmpty(npassword) &&
//       !validator.isEmpty(cpassword) &&
//       validator.isMobilePhone(phone_code, ["en-IN", "en-SG", "ms-MY", "en-US"])
//     ) {
//       if (validator.equals(npassword, cpassword)) {
//         try {
//           await vasthuUrl
//             .post(`vasthu/user/updatepassword`, {
//               phone_no: phone_code,
//               password: npassword,
//             })
//             .then(async (res) => {
//               console.log("res", res);
//               handleCloses();
//               alert(t("USR.VSH.UDR.SUCMSG"));
//               alertType("success");
//             });
//         } catch (err) {
//           console.log(err);
//         }
//       } else {
//         alert("Please Enter Same Password!");
//         alertType("error");
//       }
//     } else {
//       alert("Please Enter Same Password!");
//       alertType("error");
//     }
//   };

//   //eye pwd
//   const handleTogglePassword = () => {
//     setShowPassword(!showPassword);
//   };
//   const handleTogglecPassword = () => {
//     setShowcPassword(!showcPassword);
//   };

//   const [showPass, setShowPass] = useState(false);
//   const clickHandler = () => {
//     setShowPass((prev) => !prev);
//   };

//   //toster
//   const handleClosess = (event, reason) => {
//     if (reason === "clickaway") {
//       return;
//     }
//     setOpen(false);
//   };

//   return (
//     <>
//       <Suspense fallBack={<Spinner animation="border" />}>
//         <Container fluid>
//           <Row className="row2">
//             <Col sm={2} md={3} lg={4} />
//             <Col xs={12} sm={8} md={6} lg={4}>
//               <Card className=" p-4 all_border">
//                 <Card.Body className="text-center">
//                   <Card.Title name="TEST1" style={{ color: "#ffbb33" }}>
//                     {t("USR.HOM.HD.TL")}
//                   </Card.Title>
//                 </Card.Body>
//                 <Form>
//                   <Form.Group className="mb-3">
//                     <Form.Check
//                       inline
//                       label="Password"
//                       name="group1"
//                       type="radio"
//                       id={`inline-1`}
//                       className="slot_check"
//                       checked={loginType === "1"}
//                       onChange={() => {
//                         setLoginType("1");
//                       }}
//                     />
//                     <Form.Check
//                       inline
//                       label="OTP"
//                       name="group1"
//                       type="radio"
//                       id={`inline-2`}
//                       className="slot_check"
//                       checked={loginType === "2"}
//                       onChange={() => {
//                         setLoginType("2");
//                       }}
//                     />
//                   </Form.Group>
//                   {loginType == "2" ? (
//                     <Form.Group className="mb-3">
//                       <Form.Check
//                         inline
//                         label="Existing User"
//                         name="group2"
//                         type="radio"
//                         id={`inline-1`}
//                         className="slot_check"
//                         checked={userType === "1"}
//                         onChange={() => {
//                           setUserType("1");
//                           setUserDisabled(false);
//                         }}
//                       />
//                       <Form.Check
//                         inline
//                         label="New User"
//                         name="group2"
//                         type="radio"
//                         id={`inline-2`}
//                         className="slot_check"
//                         checked={userType === "2"}
//                         onChange={() => {
//                           setUserType("2");
//                           setUserDisabled(false);
//                         }}
//                       />
//                     </Form.Group>
//                   ) : null}
//                   {loginType == "2" && userType == null ? (
//                     <Form.Label>
//                       Please Select Existing User or New User
//                     </Form.Label>
//                   ) : null}

//                   {loginType == "2" && userType == "2" ? (
//                     <Form.Group className="mb-3" controlId="formBasicUser">
//                       <Form.Label>{t("USR.HOM.HD.UN")}</Form.Label>
//                       <Form.Control
//                         type="text"
//                         value={user}
//                         className="form_feild"
//                         placeholder={t("USR.HOM.HD.UN")}
//                         onChange={(e) => {
//                           setUser(e.target.value);
//                         }}
//                         autoComplete="new-off"
//                       />
//                     </Form.Group>
//                   ) : null}
//                   <Form.Group className="mb-3" controlId="formBasicEmail">
//                     <Form.Label>{t("USR.HOM.HD.P")}</Form.Label>
//                     <InputGroup className="mb-3">
//                       <Form.Select
//                         aria-label="Country Code"
//                         value={countryCode}
//                         className="form_feild countrywidth"
//                         onChange={(e) => {
//                           setCountryCode(e.target.value);
//                         }}
//                       >
//                         <option defaultChecked key={1} value="0">
//                           IND
//                         </option>
//                         <option key={2} value="+65">
//                           SGP
//                         </option>
//                         <option key={3} value="+60">
//                           MYS
//                         </option>
//                         <option key={4} value="+1">
//                           USA
//                         </option>
//                       </Form.Select>
//                       <Form.Control
//                         type="phone"
//                         value={phone}
//                         className="form_feild"
//                         placeholder={t("USR.HOM.HD.P")}
//                         onChange={(e) => setPhone(e.target.value)}
//                       />
//                     </InputGroup>
//                     {/* <Form.Text>
//                       (Prefix 0 before your 10 digit phone number - 0xxxxxxxxxx
//                       format){" "}
//                     </Form.Text> */}
//                   </Form.Group>

//                   {loginType == "2" ? (
//                     <>
//                       <Form.Group className="mb-3 d-flex justify-content-end">
//                         <Button
//                           className="all_btn"
//                           disabled={userdisabled}
//                           onClick={() => sendOTP()}
//                         >
//                           SEND OTP
//                         </Button>
//                       </Form.Group>
//                       <div
//                         className="countdown-text"
//                         style={{ display: "flex", alignItems: "flex-start" }}
//                       >
//                         {seconds > 0 || minutes > 0 ? (
//                           <p>
//                             {t("FGP.TIREM")}{" "}
//                             {minutes < 10 ? `0${minutes}` : minutes}:
//                             {seconds < 10 ? `0${seconds}` : seconds}
//                           </p>
//                         ) : (
//                           <p>{t("FGP.DNT.OTP")}</p>
//                         )}
//                         &nbsp;&nbsp;&nbsp;
//                         <button
//                           disabled={seconds > 0 || minutes > 0 || !disabled}
//                           style={{
//                             color:
//                               seconds > 0 || minutes > 0 || !disabled
//                                 ? "#DFE3E8"
//                                 : "#FF5630",
//                             border: "none",
//                           }}
//                           onClick={() => sendOTP()}
//                         >
//                           {t("FGP.RESD.OTP")}
//                         </button>
//                       </div>
//                     </>
//                   ) : null}

//                   {loginType == "1" ? (
//                     <>
//                       <Form.Group
//                         className="mb-3"
//                         controlId="formBasicPassword"
//                       >
//                         <Form.Label>{t("USR.HOM.HD.PWD")}</Form.Label>

//                         <InputGroup className="mb-3 ">
//                           <Form.Control
//                             type={showPass ? "text" : "password"}
//                             placeholder={t("USR.HOM.HD.PWD")}
//                             // aria-label="password"
//                             value={password}
//                             className="form_feild"
//                             // aria-describedby="password-input"
//                             onChange={(e) => setPass(e.target.value)}
//                             required
//                           />
//                           <InputGroup.Text
//                             onClick={clickHandler}
//                             className="form_feild"
//                           >
//                             {showPass ? <FaEye /> : <FaEyeSlash />}
//                           </InputGroup.Text>
//                         </InputGroup>
//                       </Form.Group>

//                       <NavLink onClick={() => handleShow()}>
//                         {/* <NavLink to="/sentOtp"> */}
//                         {t("USR.PWD")}
//                       </NavLink>
//                     </>
//                   ) : loginType == "2" ? (
//                     <>
//                       <Form.Group
//                         className="mb-3"
//                         controlId="formBasicPassword"
//                       >
//                         <Form.Label>Enter OTP</Form.Label>
//                         {/* <Form.Control
//                         type="number"
//                         value={password}
//                         className="form_feild"
//                         onChange={(e) => setPass(e.target.value)}
//                         required
//                       /> */}
//                         <div
//                           className="d-flex justify-content-center"
//                           style={{ gap: "1rem" }}
//                         >
//                           {loginOtp.map((data, index) => {
//                             return (
//                               <Form.Control
//                                 key={index}
//                                 type="text"
//                                 maxLength="1"
//                                 value={data}
//                                 disabled={
//                                   seconds > 0 || minutes > 0 ? false : true
//                                 }
//                                 className="form_feild"
//                                 style={{ width: "35px" }}
//                                 onChange={(e) => handleChange(e.target, index)}
//                                 onKeyUp={(e) => handleKeyUp(e, index)}
//                               />
//                             );
//                           })}
//                         </div>
//                       </Form.Group>
//                     </>
//                   ) : null}

//                   {!loading ? (
//                     <MyButton
//                       style={{
//                         backgroundColor: "#FFBB33",
//                         color: "white",
//                         marginTop: "10px",
//                       }}
//                       variant="contained"
//                       fullWidth="true"
//                       onClick={onSubmit}
//                       endIcon={<Send />}
//                     >
//                       {t("USR.HOM.BTN.SUB")}
//                     </MyButton>
//                   ) : (
//                     <>
//                       <MyButton
//                         style={{ backgroundColor: "#FFBB33", color: "white" }}
//                         fullWidth="true"
//                         disabled
//                       >
//                         <Spinner
//                           as="span"
//                           animation="border"
//                           size="sm"
//                           role="status"
//                           aria-hidden="true"
//                         />
//                         {t("USR.HOM.BTN.LOD")}
//                       </MyButton>
//                     </>
//                   )}
//                 </Form>

//                 <Card.Body className="text-center">
//                   <Form.Label>{t("USR.HOM.HD.NU")}</Form.Label> <br />
//                   <NavLink to="/register">{t("USR.HOM.HD.CA")}</NavLink>
//                   <br />
//                   <NavLink to="/">{t("USR.HOM.BTN.GB")}</NavLink>
//                 </Card.Body>
//                 {/* <LangChager /> */}
//               </Card>
//             </Col>
//             <Col sm={2} md={3} lg={4} />
//           </Row>
//         </Container>

//         <Modal show={show} onHide={handleClose}>
//           <Modal.Header className="all_background" closeButton>
//             <h3>{t("USR.PWD")}</h3>
//           </Modal.Header>
//           <Modal.Body>
//             <Form.Group className="mb-3" controlId="formBasicPhone">
//               <Form.Label>
//                 <b>{t("USR.HOM.HD.P")}</b>
//               </Form.Label>
//               <div className="forget_form">
//                 <InputGroup>
//                   <Form.Select
//                     aria-label="Country Code"
//                     style={{ maxWidth: "30%" }}
//                     value={countryCode}
//                     className="form_feild"
//                     onChange={(e) => {
//                       setCountryCode(e.target.value);
//                     }}
//                   >
//                     <option defaultChecked key={1} value="0">
//                       IND
//                     </option>
//                     <option key={2} value="+65">
//                       SGP
//                     </option>
//                     <option key={3} value="+60">
//                       MYS
//                     </option>
//                     <option key={4} value="+1">
//                       USA
//                     </option>
//                   </Form.Select>
//                   <Form.Control
//                     type="phone"
//                     // style={{maxWidth:'60%'}}
//                     value={phoneNo}
//                     className="form_feild select_feild"
//                     placeholder={t("USR.HOM.HD.P")}
//                     onChange={(e) => setPhoneNo(e.target.value)}
//                     autoComplete="off"
//                   />
//                 </InputGroup>
//                 {/* <Form.Control
//                   value={phoneNo}
//                   className="form_feild forget_feild"
//                   placeholder="Phone Number"
//                   onChange={(e) => setPhoneNo(e.target.value)}
//                   autoComplete="off"
//                 /> */}
//                 <Button
//                   className="forget_btn all_btn"
//                   disabled={disabled}
//                   onClick={sendOTP}
//                 >
//                   {t("FGP.GEN.OTP")}
//                 </Button>
//               </div>
//             </Form.Group>

//             <div
//               className="countdown-text"
//               style={{ display: "flex", alignItems: "flex-start" }}
//             >
//               {seconds > 0 || minutes > 0 ? (
//                 <p>
//                   {t("FGP.TIREM")} {minutes < 10 ? `0${minutes}` : minutes}:
//                   {seconds < 10 ? `0${seconds}` : seconds}
//                 </p>
//               ) : (
//                 <p>{t("FGP.DNT.OTP")}</p>
//               )}
//               &nbsp;&nbsp;&nbsp;
//               <button
//                 disabled={seconds > 0 || minutes > 0 || !disabled}
//                 style={{
//                   color:
//                     seconds > 0 || minutes > 0 || !disabled
//                       ? "#DFE3E8"
//                       : "#FF5630",
//                   border: "none",
//                 }}
//                 onClick={sendOTP}
//               >
//                 {t("FGP.RESD.OTP")}
//               </button>
//             </div>

//             {/* {verifyOtp && seconds > 0 ? ( */}
//             {/* // <p
//               //   className="ps-2"
//               //   style={{
//               //     backgroundColor: "#958c8b",
//               //     width: "48px",
//               //     borderRadius: "3px",
//               //   }}
//               // >
//               //   {otpmsg}
//               // </p> */}
//             {/* // ) : null} */}

//             <Form.Group className="mb-3" controlId="formBasicPhone">
//               <Form.Label>
//                 <b>OTP</b>
//               </Form.Label>
//               <div className="forget_form">
//                 <Form.Control
//                   value={otp}
//                   className="form_feild forget_feild"
//                   autoComplete="off"
//                   placeholder={t("FGP.ENT.OTP")}
//                   onChange={(e) => setOtp(e.target.value)}
//                   disabled={seconds > 0 || minutes > 0 ? false : true}
//                 />
//                 <Button
//                   className="forget_btn all_btn"
//                   onClick={Secmodal}
//                   disabled={!disabled}
//                 >
//                   {t("FGP.VER.OTP")}
//                 </Button>
//               </div>
//             </Form.Group>
//           </Modal.Body>
//           <Modal.Footer>
//             <Button className="all_btn" onClick={() => handleClose()}>
//               {t("TXT.BTN.CAN.LO")}
//             </Button>
//           </Modal.Footer>
//         </Modal>

//         <Modal show={shows} onHide={handleCloses}>
//           <Modal.Header className="all_background" closeButton>
//             <Modal.Title>{t("FGP.RES.PSW.TL")}</Modal.Title>
//           </Modal.Header>
//           <Modal.Body>
//             <Form.Group className="mb-3" controlId="formBasicPhone">
//               <Form.Label>{t("USR.HOM.HD.P")}</Form.Label>
//               <Form.Control
//                 value={countryCode.concat(phoneNo)}
//                 className="form_feild"
//                 disabled
//               />
//             </Form.Group>

//             <Form.Group className="mb-3" controlId="formBasicSPassword">
//               <Form.Label>{t("USR.HOM.HD.CPWD")}</Form.Label>
//               <InputGroup className="mb-3">
//                 <Form.Control
//                   type={showPassword ? "text" : "password"}
//                   value={npassword}
//                   className="form_feild"
//                   placeholder={t("USR.HOM.HD.CPWD")}
//                   onChange={(e) => setNPassword(e.target.value)}
//                   autoComplete="off"
//                 />
//                 <InputGroup.Text
//                   onClick={handleTogglePassword}
//                   className="form_feild"
//                 >
//                   {showPassword ? <FaEye /> : <FaEyeSlash />}
//                 </InputGroup.Text>
//               </InputGroup>
//             </Form.Group>

//             <Form.Group className="mb-3" controlId="formBasicCPassword">
//               <Form.Label>{t("USR.HOM.HD.CFPWD")}</Form.Label>
//               <InputGroup className="mb-3">
//                 <Form.Control
//                   type={showcPassword ? "text" : "password"}
//                   value={cpassword}
//                   className="form_feild"
//                   placeholder={t("USR.HOM.HD.CFPWD")}
//                   onChange={(e) => setCPassword(e.target.value)}
//                   autoComplete="off"
//                 />
//                 <InputGroup.Text
//                   onClick={handleTogglecPassword}
//                   className="form_feild"
//                 >
//                   {showcPassword ? <FaEye /> : <FaEyeSlash />}
//                 </InputGroup.Text>
//               </InputGroup>
//             </Form.Group>
//           </Modal.Body>

//           <Modal.Footer className="d-flex flex-row justify-content-evenly">
//             <Button className="all_btn" onClick={handleCloses}>
//               {t("TXT.CLO.LO")}
//             </Button>
//             <Button active className="all_btn" onClick={handleUpdate}>
//               {t("USR.PWD.UPT.BTN")}
//             </Button>
//           </Modal.Footer>
//         </Modal>

//         <Stack spacing={2} sx={{ width: "100%" }}>
//           <Snackbar
//             anchorOrigin={{
//               vertical: "top",
//               horizontal: "center",
//             }}
//             open={open}
//             autoHideDuration={3000}
//             onClose={handleClosess}
//           >
//             <MuiAlert
//               variant="filled"
//               onClose={handleClosess}
//               severity={messageType}
//               sx={{
//                 width: "100%",
//               }}
//             >
//               {message}
//             </MuiAlert>
//           </Snackbar>
//         </Stack>
//       </Suspense>
//     </>
//   );
// };

// export default Login;

import React, { Suspense, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { vasthuUrl } from "../component/Api";
import { UseAuth } from "../context/Auth";

//eye pwd
import { FaEye, FaEyeSlash } from "react-icons/fa";

import { Button as MyButton } from "@material-ui/core";
import { Send } from "@material-ui/icons";
import {
  Button,
  Card,
  Form,
  InputGroup,
  Modal,
  Spinner
} from "react-bootstrap";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import validator from "validator";

const Login = ({ setActiveKey, alertType, alert }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const iniLang = localStorage.getItem("i18nextLng");
  const [showPassword, setShowPassword] = useState(false);
  const [showcPassword, setShowcPassword] = useState(false);
  const { handleLogin, loading, error, handleSocialLogin } = UseAuth();
  const [phone, setPhone] = useState("");
  const [password, setPass] = useState("");
  const [message, setMessage] = useState("");
  const [otp, setOtp] = useState("");
  const [verifyOtp, setVerifyOtp] = useState("");
  const [otpmsg, setOtpMsg] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const [npassword, setNPassword] = useState("");
  const [cpassword, setCPassword] = useState("");
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [disabled, setDisabled] = useState(false);
  const [countryCode, setCountryCode] = useState("0");

  //FPWD 1ST MODAL
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);

  const handleClose = () => {
    setShow(false);
    setOtp([]);
    setDisabled(false);
    setSeconds(0);
    setMinutes(0);
  };

  //FPWD 2nd MODAL
  const [shows, setShows] = useState(false);
  const handleShows = () => setShows(true);

  const handleCloses = () => {
    setShows(false);
    setOtp([]);
    setPhoneNo([]);
    setNPassword([]);
    setCPassword([]);
    setDisabled(false);
    setSeconds(0);
    setMinutes(0);
  };

  const referPath = () => {
    if (location.otp) {
      return location.otp.from.pathname;
    } else {
      return "/";
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const path = referPath();
    const phone_with_code = countryCode.concat(phone);
    if (
      !validator.isEmpty(phone) &&
      !validator.isEmpty(password) &&
      validator.isMobilePhone(phone_with_code, [
        "en-IN",
        "en-SG",
        "ms-MY",
        "en-US",
      ])
    ) {
      handleLogin({ phone: phone_with_code, password, path }, (res) => {
        if (res === "success") {
          setPhone("");
          setPass("");
          // GA test -- login success

          ReactGA.event({
            category: "User",
            action: `Successfully Logged Event`,
          });

          window.dataLayer.push({ event: "my-custom-login" });

          //
        } else {
          alert(t("USR.HOM.ERR.MSG1"));
          alertType("error");
          setActiveKey("register");
        }
      });
    } else {
      alert(t("USR.HOM.ERR.MSG1"));
      alertType("error");
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const sendOTP = async () => {
    if (!validator.isEmpty(phoneNo)) {
      try {
        const phone_code = countryCode.concat(phoneNo);
        await vasthuUrl
          // .post(`vasthu/user/requestotp`, {
          .post(`vasthu/user/pwdresetotpsender`, {
            phone_no: phone_code,
            url: `${process.env.REACT_APP_SMS_DEL}`,
            lng: iniLang,
          })
          .then(async (res) => {
            setMinutes(0);
            setSeconds(59);
            console.log("res", res);
            setVerifyOtp(res.data.otp_secret);
            setOtpMsg(res.data.message);
            alert(res.data.message);
            setDisabled(true);
            if (res.data.message === "Success") {
              alertType("success");
            } else {
              alertType("error");
            }
          });
      } catch (err) {
        console.log(err);
      }
    } else {
      alert("Please Enter Your Valid Phone Number!");
      alertType("error");
    }
  };

  const Secmodal = async () => {
    if (!validator.isEmpty(otp)) {
      try {
        const phone_code = countryCode.concat(phoneNo);
        await vasthuUrl
          .post(`vasthu/user/verifyotp`, {
            otp: otp,
            phone_no: phone_code,
          })
          .then(async (res) => {
            console.log("res", res);
            setOtp([]);
            setDisabled(false);
            setSeconds(0);
            setMinutes(0);
          });
      } catch (err) {
        console.log(err);
      }
    } else {
      alert("Please Enter Your OTP!");
      alertType("error");
    }
    if (otp === verifyOtp && otpmsg === "Success") {
      handleClose();
      handleShows();
    } else {
      alert("Please Enter Your Valid OTP!");
      alertType("error");
    }
  };

  const handleUpdate = async () => {
    const phone_code = countryCode.concat(phoneNo);
    if (
      !validator.isEmpty(phoneNo) &&
      !validator.isEmpty(npassword) &&
      !validator.isEmpty(cpassword) &&
      validator.isMobilePhone(phone_code, ["en-IN", "en-SG", "ms-MY", "en-US"])
    ) {
      if (validator.equals(npassword, cpassword)) {
        try {
          await vasthuUrl
            .post(`vasthu/user/updatepassword`, {
              phone_no: phone_code,
              password: npassword,
            })
            .then(async (res) => {
              console.log("res", res);
              handleCloses();
              alert(t("USR.VSH.UDR.SUCMSG"));
              alertType("success");
            });
        } catch (err) {
          console.log(err);
        }
      } else {
        alert("Please Enter Same Password!");
        alertType("error");
      }
    } else {
      alert("Please Enter Same Password!");
      alertType("error");
    }
  };

  //eye pwd
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleTogglecPassword = () => {
    setShowcPassword(!showcPassword);
  };

  const [showPass, setShowPass] = useState(false);
  const clickHandler = () => {
    setShowPass((prev) => !prev);
  };

  return (
    <>
      <Suspense fallBack={<Spinner animation="border" />}>
        <React.Fragment>
          <Card className="p-4 all_border">
            <Card.Body className="text-center">
              <Card.Title name="TEST1" style={{ color: "#ffbb33" }}>
                <u>{t("USR.LOG.PWD.LOG.HED")}</u>
              </Card.Title>
            </Card.Body>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>{t("USR.HOM.HD.P")}</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Select
                    aria-label="Country Code"
                    value={countryCode}
                    className="form_feild countrywidth"
                    onChange={(e) => {
                      setCountryCode(e.target.value);
                    }}
                  >
                    <option defaultChecked key={1} value="0">
                      IND
                    </option>
                    <option key={2} value="+65">
                      SGP
                    </option>
                    <option key={3} value="+60">
                      MYS
                    </option>
                    <option key={4} value="+1">
                      USA
                    </option>
                  </Form.Select>
                  <Form.Control
                    type="tel"
                    value={phone}
                    className="form_feild"
                    placeholder={t("USR.HOM.HD.P")}
                    onChange={(e) => {
                      const value = e.target.value.replace(/\D/g, "");
                      setPhone(value)
                    }}
                  />
                </InputGroup>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>{t("USR.HOM.HD.PWD")}</Form.Label>

                <InputGroup className="mb-3 ">
                  <Form.Control
                    type={showPass ? "text" : "password"}
                    placeholder={t("USR.HOM.HD.PWD")}
                    // aria-label="password"
                    value={password}
                    className="form_feild"
                    // aria-describedby="password-input"
                    onChange={(e) => setPass(e.target.value)}
                    required
                  />
                  <InputGroup.Text
                    onClick={clickHandler}
                    className="form_feild"
                  >
                    {showPass ? <FaEye /> : <FaEyeSlash />}
                  </InputGroup.Text>
                </InputGroup>
              </Form.Group>

              <NavLink onClick={() => handleShow()}>
                {/* <NavLink to="/sentOtp"> */}
                {t("USR.PWD")}
              </NavLink>

              {!loading ? (
                <MyButton
                  style={{
                    backgroundColor: "#FFBB33",
                    color: "white",
                    marginTop: "10px",
                  }}
                  variant="contained"
                  fullWidth="true"
                  onClick={onSubmit}
                  endIcon={<Send />}
                >
                  {t("USR.HOM.BTN.SUB")}
                </MyButton>
              ) : (
                <>
                  <MyButton
                    style={{ backgroundColor: "#FFBB33", color: "white" }}
                    fullWidth="true"
                    disabled
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    {t("USR.HOM.BTN.LOD")}
                  </MyButton>
                </>
              )}
            </Form>
          </Card>
        </React.Fragment>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header className="all_background" closeButton>
            <h3>{t("USR.PWD")}</h3>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="formBasicPhone">
              <Form.Label>
                <b>{t("USR.HOM.HD.P")}</b>
              </Form.Label>
              <div className="forget_form">
                <InputGroup>
                  <Form.Select
                    aria-label="Country Code"
                    style={{ maxWidth: "30%" }}
                    value={countryCode}
                    className="form_feild"
                    onChange={(e) => {
                      setCountryCode(e.target.value);
                    }}
                  >
                    <option defaultChecked key={1} value="0">
                      IND
                    </option>
                    <option key={2} value="+65">
                      SGP
                    </option>
                    <option key={3} value="+60">
                      MYS
                    </option>
                    <option key={4} value="+1">
                      USA
                    </option>
                  </Form.Select>
                  <Form.Control
                    type="phone"
                    // style={{maxWidth:'60%'}}
                    value={phoneNo}
                    className="form_feild select_feild"
                    placeholder={t("USR.HOM.HD.P")}
                    onChange={(e) => setPhoneNo(e.target.value)}
                    autoComplete="off"
                  />
                </InputGroup>
                {/* <Form.Control
                  value={phoneNo}
                  className="form_feild forget_feild"
                  placeholder="Phone Number"
                  onChange={(e) => setPhoneNo(e.target.value)}
                  autoComplete="off"
                /> */}
                <Button
                  className="forget_btn all_btn"
                  disabled={disabled}
                  onClick={sendOTP}
                >
                  {t("FGP.GEN.OTP")}
                </Button>
              </div>
            </Form.Group>

            <div
              className="countdown-text"
              style={{ display: "flex", alignItems: "flex-start" }}
            >
              {seconds > 0 || minutes > 0 ? (
                <p>
                  {t("FGP.TIREM")} {minutes < 10 ? `0${minutes}` : minutes}:
                  {seconds < 10 ? `0${seconds}` : seconds}
                </p>
              ) : (
                <p>{t("FGP.DNT.OTP")}</p>
              )}
              &nbsp;&nbsp;&nbsp;
              <button
                disabled={seconds > 0 || minutes > 0 || !disabled}
                style={{
                  color:
                    seconds > 0 || minutes > 0 || !disabled
                      ? "#DFE3E8"
                      : "#FF5630",
                  border: "none",
                }}
                onClick={sendOTP}
              >
                {t("FGP.RESD.OTP")}
              </button>
            </div>

            {/* {verifyOtp && seconds > 0 ? ( */}
            {/* // <p
              //   className="ps-2"
              //   style={{
              //     backgroundColor: "#958c8b",
              //     width: "48px",
              //     borderRadius: "3px",
              //   }}
              // >
              //   {otpmsg}
              // </p> */}
            {/* // ) : null} */}

            <Form.Group className="mb-3" controlId="formBasicPhone">
              <Form.Label>
                <b>OTP</b>
              </Form.Label>
              <div className="forget_form">
                <Form.Control
                  value={otp}
                  className="form_feild forget_feild"
                  autoComplete="off"
                  placeholder={t("FGP.ENT.OTP")}
                  onChange={(e) => setOtp(e.target.value)}
                  disabled={seconds > 0 || minutes > 0 ? false : true}
                />
                <Button
                  className="forget_btn all_btn"
                  onClick={Secmodal}
                  disabled={!disabled}
                >
                  {t("FGP.VER.OTP")}
                </Button>
              </div>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button className="all_btn" onClick={() => handleClose()}>
              {t("TXT.BTN.CAN.LO")}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={shows} onHide={handleCloses}>
          <Modal.Header className="all_background" closeButton>
            <Modal.Title>{t("FGP.RES.PSW.TL")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="formBasicPhone">
              <Form.Label>{t("USR.HOM.HD.P")}</Form.Label>
              <Form.Control
                value={countryCode.concat(phoneNo)}
                className="form_feild"
                disabled
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicSPassword">
              <Form.Label>{t("USR.HOM.HD.CPWD")}</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  value={npassword}
                  className="form_feild"
                  placeholder={t("USR.HOM.HD.CPWD")}
                  onChange={(e) => setNPassword(e.target.value)}
                  autoComplete="off"
                />
                <InputGroup.Text
                  onClick={handleTogglePassword}
                  className="form_feild"
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicCPassword">
              <Form.Label>{t("USR.HOM.HD.CFPWD")}</Form.Label>
              <InputGroup className="mb-3">
                <Form.Control
                  type={showcPassword ? "text" : "password"}
                  value={cpassword}
                  className="form_feild"
                  placeholder={t("USR.HOM.HD.CFPWD")}
                  onChange={(e) => setCPassword(e.target.value)}
                  autoComplete="off"
                />
                <InputGroup.Text
                  onClick={handleTogglecPassword}
                  className="form_feild"
                >
                  {showcPassword ? <FaEye /> : <FaEyeSlash />}
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
          </Modal.Body>

          <Modal.Footer className="d-flex flex-row justify-content-evenly">
            <Button className="all_btn" onClick={handleCloses}>
              {t("TXT.CLO.LO")}
            </Button>
            <Button active className="all_btn" onClick={handleUpdate}>
              {t("USR.PWD.UPT.BTN")}
            </Button>
          </Modal.Footer>
        </Modal>
      </Suspense>
    </>
  );
};

export default Login;
