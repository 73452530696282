const BillDeskPay = (payResponse, payHandler) => {
  const payOut = new Promise((resolve, reject) => {
    const flow_config = {
      merchantId: payResponse.mercid,
      bdOrderId: payResponse.bdOrderId,
      authToken: payResponse.authToken,
      childWindow: true,
      crossButtonHandling: "Y",
      // retryCount: 0,
      retryCount: 3,
      //  returnUrl:"customeraccount"
    };
    const config = {
      responseHandler: payHandler,
      flowConfig: flow_config,
      flowType: "payments",
    };

    setTimeout(() => {
      payResponse.mercid && payResponse.bdOrderId && payResponse.authToken
        ? resolve(config)
        : reject(payResponse);
    }, 500);
  });
  return payOut;
};
export default BillDeskPay;
