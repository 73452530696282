import React, { useEffect, useState } from "react";
import {
  MainDoorValidation,
  ElementValidation,
  WindowValidation,
  LevelingValidation,
  MiscellaneousValidation,
} from "../component/MainDoor";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { Container, Table, Spinner, Button } from "react-bootstrap";
import { vasthuUrl } from "../component/Api";
import { UseAuth } from "../context/Auth";
import MyAccordian from "../component/MyAccordian";
import { UseVasthu } from "../context/vasthu";
import { useTranslation } from "react-i18next";

const HomeValidation = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [offerId, setOfferId] = useState();
  const location = useLocation();
  // const { token } = UseAuth();
  const token = window.sessionStorage.getItem("token");
  const { homeRec } = UseVasthu();
  const myAccordian = [
    { label: t("USR.HOM.RES.MAI.DOR"), content: MainDoorValidation },
    { label: t("USR.VSH.RES.ACR.ELM"), content: ElementValidation },
    { label: t("USR.VSH.STP.4"), content: MiscellaneousValidation },
    { label: t("USR.VSH.STP.3"), content: WindowValidation },
    { label: t("USR.HOM.RES.LEV"), content: LevelingValidation },
  ];

  console.log("MyAccordian", MyAccordian);

  useEffect(() => {
    vasthuUrl
      .get(`/subscription/useVasthuOffer/${location.state.offerId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((rs) =>
        console.log("offer subscription redeem count used", rs.data.message)
      )
      .catch((er) =>
        console.log("offer subscription redeem count not reduced", er)
      );
  });

  return (
    <React.Fragment>
      {myAccordian.map((item) => {
        return (
          <MyAccordian
            className="myAccordian accordian_style"
            label={item.label}
            content={<item.content data={homeRec} />}
          />
        );
      })}
      <center>
        <Button
          variant="outline-warning"
          style={{ borderRadius: 50 }}
          onClick={() => navigate("/buildingdetails")}
          className="mt-2"
        >
          {t("BTN.FIN.UP")}
        </Button>
      </center>
    </React.Fragment>
  );
};
export default HomeValidation;
