import client from "../resource/images/clients.jpg";
import pro from "../resource/images/pro.jpg";
import admin from "../resource/images/admins.jpg";
import payments from "../resource/images/payments.jpg";
import registration from "../resource/images/registration.jpg";
import lang_trans from "../resource/images/lang_trans.jpg";

const crmServices = [
  {
    id: 1,
    heading: "ACCOUNTS",
    description: "Add and Update user on behalf of Users",
    image: registration,
    href: "accounts",
    actions: [
      // { name: "ADD", href: "accounts" },
      // { name: "EDIT/DELETE", href: "accounts" },
    ],
  },
  {
    id: 2,
    heading: "OFFERS",
    description: "View and Buy Offers",
    image: pro,
    href: "offerlist",
    actions: [
      // { name: "ADD", href: "offerlist" },
      // { name: "EDIT/DELETE", href: "offerlist" },
    ],
  },
  {
    id: 3,
    heading: "PAYMENTS",
    description: "View User Payments Lists",
    image: payments,
    href: "payments",
    actions: [],
  },
  {
    id: 4,
    heading: "REPORTS",
    description: "View Plot/Home Reports",
    image: admin,
    href: "reports",
    actions: [
      // { name: "VIEW", href: "reports" }
    ],
  },
  {
    id: 5,
    heading: "BOOKING",
    description:
      "Book and View Scheduled Bookings Details related to consultaions",
    image: client,
    href: "userslist",
    actions: [
      // { name: "BOOK", href: "userslist" },
      // { name: "SCHEDULED BOOKINGS", href: "userslist" },
    ],
  },
  {
    id: 5,
    heading: "TRANSLATION",
    description:
      "View, Edit and Add  Language Translation",
    image: lang_trans,
    href: "translationlist",
    actions: [
      // { name: "BOOK", href: "userslist" },
      // { name: "SCHEDULED BOOKINGS", href: "userslist" },
    ],
  },
];

export default crmServices;
