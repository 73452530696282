import React, { createContext, useContext, useState, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { vasthuUrl } from "../component/Api";

const ProfessionalContext = createContext();

export const ProfessionalProvider = ({ children }) => {
  const [ProfessionalError, setProfessionalError] = useState();
  const [ProfessionalLoading, setProfessionalLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleClientRegister = async (data) => {
    setProfessionalLoading(true);
    try {
      const res = await vasthuUrl.post(`vasthu/user/register`, {
        userName: data.user,
        phone_no: data.phone,
        email: data.email,
        password: data.pass,
      });
      if (res.data.message === process.env.REACT_APP_SUCESS_REGISTER) {
        setProfessionalLoading(false);
        setProfessionalError("Client Successfully Registered");
      } else {
        setProfessionalError(res.data.message);
        setProfessionalLoading(false);
      }
    } catch (err) {
      console.log("SERVER ERROR", err);
      setProfessionalError("CHECK INTERNET CONNECTION");
      setProfessionalLoading(false);
    }
  };

  const handleProfRegister = async (data) => {
    setProfessionalLoading(true);
    try {
      const res = await vasthuUrl.post(`vasthu/user/register`, {
        userName: data.user,
        phone_no: data.phone,
        email: data.email,
        password: data.pass,
        role: process.env.REACT_APP_VASTHU_PROFESSIONAL,
      });
      if (res.data.message === process.env.REACT_APP_SUCESS_REGISTER) {
        setProfessionalLoading(false);
        setProfessionalError("Professional Sucessfully Registered");
      } else {
        setProfessionalError(res.data.message);
        setProfessionalLoading(false);
      }
    } catch (err) {
      console.log("SERVER ERROR", err);
      setProfessionalError("CHECK INTERNET CONNECTION");
      setProfessionalLoading(false);
    }
  };

  const handleProfessionalRegister = async (data) => {
    setProfessionalLoading(true);
    try {
      const res = await vasthuUrl.post(`vasthu/user/register`, {
        userName: data.user,
        phone_no: data.phone,
        email: data.email,
        password: data.pass,
        role: process.env.REACT_APP_VASTHU_Professional,
      });
      if (res.data.message === process.env.REACT_APP_SUCESS_REGISTER) {
        setProfessionalLoading(false);
        setProfessionalError("Professional Sucessfully Registered");
      } else {
        setProfessionalError(res.data.message);
        setProfessionalLoading(false);
      }
    } catch (err) {
      console.log("SERVER ERROR", err);
      setProfessionalError("CHECK INTERNET CONNECTION");
      setProfessionalLoading(false);
    }
  };

  const memoedValue = useMemo(
    () => ({
      ProfessionalError,
      handleClientRegister,
      handleProfRegister,
      handleProfessionalRegister,
      ProfessionalLoading,
    }),
    [ProfessionalLoading, ProfessionalError]
  );

  return (
    <ProfessionalContext.Provider value={memoedValue}>
      {children}
    </ProfessionalContext.Provider>
  );
};

export const UseProfessionalContext = () => {
  return useContext(ProfessionalContext);
};
