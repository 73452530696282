import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Container,
  Modal,
  Spinner,
  Table,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { vasthuUrl } from "../component/Api";
import PaymentProcess from "../component/PaymentProcess";
import { UseAuth } from "../context/Auth";

const MyRefunds = () => {
  // const { userId  } = UseAuth();
  const userId = window.sessionStorage.getItem("userId");
  const token = window.sessionStorage.getItem("token");
  const [transactionDetails, setTransactionDetails] = useState([]);
  const [paymentOrderDetails, setPaymentOrderDetails] = useState({});
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const {t} = useTranslation();
  useEffect(() => {
    try {
      vasthuUrl
        .get(`vasthu/getUserTransactionHistory/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          setTransactionDetails(res.data);
        });
    } catch (e) {
      console.log("SERVER ERROR");
    }
  }, []);

  const showModal = (obj) => {
    console.log(obj);
    // vasthuUrl
    //   .get(`vasthu/fetchPaymentOrderCurrentStatus/${orderId}`, {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   })
    //   .then((res) => {
    //console.log(res.data);
    setPaymentOrderDetails({
      offer: obj.offerName,
      bankName: obj.bankName,
      bankTxnId: obj.bankTxnId,
      txnAmount: obj.txnAmount,
      refundAmount: obj.refundAmt,
      txnId: obj.txnId,
    });

    setShowReviewModal(true);
  };

  return (
    <div>
      {transactionDetails.length > 0 ? (
        <Table
          striped
          bordered
          hover
          responsive
          // className="deskTable border border-warning"
          className="deskTable1 all_border"
        >
          <thead>
            <tr>
              <th>{t("TXT.OFF.NM.LO")}</th>
              <th>{t("USR.PY.HS.TB.COL2")}</th>
              <th>{t("USR.PY.HS.TB.COL3")}</th>
              <th>{t("USR.PY.HS.TB.COL4")}</th>
              <th>{t("USR.PY.HS.TB.COL5")}</th>
              <th>{t("TXT.VIEW.LO")}</th>
            </tr>
          </thead>
          <tbody>
            {transactionDetails.map((i, j) => (
              <tr key={j}>
                <td>{i.offerName}</td>
                <td>{i.txnAmount}</td>
                <td>{i.txnDate}</td>
                <td>{i.orderId}</td>
                <td>{i.status}</td>
                <td
                  className="text-center d-flex"
                  style={{ flexDirection: "column" }}
                >
                  <Button
                    onClick={() => showModal(i)}
                    style={{
                      borderRadius: 50,
                    }}
                    className="mt-2 all_btn"
                  >
                    {t("BTN.VIEW.DET.UP")}
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <center>
          <h3>{t("USR.PY.HS.NIL.DAT")}</h3>
        </center>
      )}

      <Modal
        show={showReviewModal}
        onHide={() => {
          setShowReviewModal(false);
        }}
      >
        <Modal.Header className="all_background" closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("USR.PY.HS.MOD.TL")}
          </Modal.Title>
        </Modal.Header>

        {loading ? (
          <center>
            <Spinner animation="border" variant="warning" />
          </center>
        ) : (
          <Modal.Body>
            <p>
              {t("USR.PY.HS.MOD.ROW2")} : {paymentOrderDetails.bankName}
            </p>
            <p>
              {t("USR.PY.HS.MOD.ROW3")} : {paymentOrderDetails.bankTxnId}
            </p>
            <p>
              {t("USR.PY.HS.MOD.ROW4")} : {paymentOrderDetails.txnAmount}
            </p>
            <p>
              {t("USR.PY.HS.MOD.ROW5")} : {paymentOrderDetails.refundAmount}
            </p>
            <p>
              {t("USR.PY.HS.MOD.ROW6")} : {paymentOrderDetails.txnId}
            </p>
          </Modal.Body>
        )}

        <Modal.Footer className="d-flex justify-content-center" >
          <Button
            className="all_btn"
            style={{width:"100%"}}
            onClick={() => setShowReviewModal(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default MyRefunds;
