import React, { useState, createContext } from "react";
import { Container } from "react-bootstrap";
import AddService from "./AddService";
import ServiceList from "./ServiceList";

export const Context = createContext({});

const customerpayment = () => {
  const [offerList, setOfferList] = useState([]);

  return (
    <Context.Provider value={{ setOfferList, offerList }}>
      <Container>
        <AddService />
        <ServiceList />
      </Container>
    </Context.Provider>
  );
};

export default customerpayment;
