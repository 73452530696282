import React, { Component } from "react";
import CONFIG from "../component/PaymentConfig";
import { CheckoutProvider, Checkout } from "paytm-blink-checkout-react";
import InjectedCheckout from "../payment/injected-checkout";
import { paymentContext } from "../context/PaymentContext";
import { vasthuUrl } from "../component/Api";
import { useNavigate } from "react-router-dom";

const USE_EXISTING_CHECKOUT_INSTANCE = "Use existing checkout instance : ";
const scriptElement = document.createElement("script");
const accessToken = window.sessionStorage.getItem("token");
class PaymentProcess extends Component {
  textAreaRef = React.createRef();

  static contextType = paymentContext;

  constructor(props) {
    super(props);
    this.state = {
      config: this.appendHandler(CONFIG),
      showCheckout: true,
      openInPopup: true,
      checkoutJsInstance: null,
      token: null,
      offerId: null,
      userId: null,
    };
  }

  componentDidMount = () => {
    CONFIG.data.token = this.props.txnToken;
    CONFIG.data.orderId = this.props.orderId;
    CONFIG.data.amount = this.props.amount;
    const token = window.sessionStorage.getItem("token");
    const offerId = this.props.offerId;
    const userId = this.props.userId;
    this.setState({
      config: this.appendHandler(CONFIG),
      token,
      offerId,
      userId,
    });
  };

  appendHandler(config) {
    const newConfig = { ...config };
    newConfig.handler = {
      notifyMerchant: this.notifyMerchantHandler,
      transactionStatus: this.transactionStatusHandler,
    };

    console.log("NEW CONFIG", newConfig);
    return newConfig;
  }

  notifyMerchantHandler = (eventType, data) => {
    console.log("FROM NOTIFY HANDLER METHOD");
    console.log("MERCHANT NOTIFY LOG", eventType, data);
  };

  transactionStatusHandler = async (paymentStatus, eventType) => {
    window.Paytm.CheckoutJS.close();

    console.log("PAYMENT RESPONSE ", paymentStatus);

    // if (paymentStatus.STATUS === "TXN_SUCCESS") {
    //   console.log("success", paymentStatus);
    // //   this.props.navigate("home");
    // } else {
    //   console.log("failure", paymentStatus);
    // //   this.props.navigate("home");
    // }
    await vasthuUrl
      .post(
        `vasthu/verifyPaymentStatus/`,
        {
          orderId: paymentStatus.ORDERID,
          currency: paymentStatus.CURRENCY,
          userUuid: this.state.userId,
          offerId: this.props.offerId,
        },
        {
          headers: {
            Authorization: `Bearer ${this.state.token}`,
          },
        }
      )
      .then((res) => {
        console.log("Payment db save", res.data);
        console.log("OFFER ID  ", this.props.offerId, this.state.offerId);
        if (res.data.status == "TXN_SUCCESS") {
          vasthuUrl
            .post(
              "subscription/buyVasthuOffer",
              {
                orderId: paymentStatus.ORDERID,
                userUuid: this.state.userId,
                offerId: this.props.offerId,
                paymentStatus: true,
              },
              {
                headers: {
                  Authorization: `Bearer ${this.state.token}`,
                },
              }
            )
            .then((res) => {
              console.log("Payment status changed to true");
              this.props.navigate(this.props.screenName, {
                state: { offerId: res.data.id },
              });
            });
        } else {
          vasthuUrl
            .post(
              "subscription/buyVasthuOffer",
              {
                orderId: paymentStatus.ORDERID,
                userUuid: this.state.userId,
                offerId: this.props.offerId,
                paymentStatus: false,
              },
              {
                headers: {
                  Authorization: `Bearer ${this.state.token}`,
                },
              }
            )
            .then((res) => {
              console.log("Payment status changed to false");
            });
        }
      });

    // this.setState({showCheckout : false});

    // this.setState({showCheckout : false});
  };

  renderUpdateConfig = () => {
    this.setState({
      config: this.getUpdatedConfig(),
    });
  };

  getUpdatedConfig() {
    const config = this.parse(this.textAreaRef.current.value);

    return this.appendHandler(config);
  }

  parse(value) {
    try {
      return JSON.parse(value);
    } catch (err) {
      console.error("Invalid config JSON");
      return {};
    }
  }

  toggleOpenInPopup = () => {
    this.setState((prevState, _) => ({
      openInPopup: !prevState.openInPopup,
    }));
  };

  toggleCheckout = () => {
    this.setState((prevState, _) => ({
      showCheckout: !prevState.showCheckout,
    }));
  };

  loadCheckoutScript = () => {
    console.log("MID", CONFIG.merchant.mid);
    const url =
      "https://pgp-hotfix.paytm.in/merchantpgpui/checkoutjs/merchants/";

    scriptElement.id = "paytmCheckout";
    scriptElement.async = true;
    scriptElement.src = url.concat(CONFIG.merchant.mid);
    scriptElement.type = "application/javascript";
    scriptElement.onload = () => {
      const checkoutJsInstance = this.getCheckoutJsObj();

      console.log("JS OBJ 1", checkoutJsInstance);

      if (checkoutJsInstance && checkoutJsInstance.onLoad) {
        checkoutJsInstance.onLoad(() => {
          this.setState({
            config: this.getUpdatedConfig(),
            checkoutJsInstance,
          });

          console.log("RESPONSE DATA", this.state.config, checkoutJsInstance);
        });
        console.log("JS OBJ 2", checkoutJsInstance);
      } else {
        console.error(USE_EXISTING_CHECKOUT_INSTANCE + "onload not available!");
      }
    };
    scriptElement.onerror = (error) => {
      console.error(USE_EXISTING_CHECKOUT_INSTANCE + "script load fail!");
    };
  };

  getCheckoutJsObj() {
    if (window && window.Paytm && window.Paytm.CheckoutJS) {
      console.log("CHECKOUT JS 2", this.state.checkoutJsInstance);

      return window.Paytm.CheckoutJS.invoke();
    } else {
      console.error(
        USE_EXISTING_CHECKOUT_INSTANCE + "Checkout instance not found!"
      );
    }

    return null;
  }

  render() {
    const { showCheckout, openInPopup, config } = this.state;
    const textAreaVal = JSON.stringify(config, null, 4);
    console.log("CHECKOUT JS", this.state.checkoutJsInstance);
    return (
      <div>
        {/* <textarea cols="50"
          rows="25"
          defaultValue={textAreaVal}
          ref={this.textAreaRef} /> */}
        {/* <div> */}
        {/* <button type="button" onClick={this.toggleCheckout}>
            Toggle Checkout Screen
          </button> */}
        {/* <button type="button"
            onClick={this.renderUpdateConfig}>
            Re-render updated config
          </button>
          <button type="button"
            onClick={this.loadCheckoutScript}>
            Use existing checkout instance
          </button> */}
        {/* <input
            type="checkbox"
            onClick={this.toggleOpenInPopup}
            defaultChecked={openInPopup}
          ></input>{" "}
          Open in popup
        </div> */}
        <br />

        {/* <div>
          <b>CHECKOUT VISIBILITY :</b> {showCheckout.toString()}
        </div> */}
        <CheckoutProvider
          config={this.state.config}
          checkoutJsInstance={this.state.checkoutJsInstance}
          openInPopup={openInPopup}
          env="STAGE"
        >
          <Checkout />
        </CheckoutProvider>
      </div>
    );
  }
}

export default PaymentProcess;
