import React, { createContext, useContext, useState, useMemo } from "react";

export const paymentContext = createContext();

export const PaymentProvider = ({ children }) => {

  const [subId, setSubId] = useState("")


  const getSubId = (val) => {
    setSubId(val)
  }

  const payMemo = useMemo(
    () => ({
      subId,
      getSubId
    }),
    [subId]
  );

  return (
    <paymentContext.Provider value={payMemo}>
      {children}
    </paymentContext.Provider>
  );
};

export const usePay = () => {
  return useContext(paymentContext);
};
