import React, { useState, useEffect } from "react";
import { Form, Card, Button, Spinner, Alert, InputGroup } from "react-bootstrap";
import { UseAdminContext } from "../context/AdminContext";
import validator from "validator";

const AdminAddAdminForm = () => {
  // const { token} = UseAuth();
  const { handleAdminRegister, adminLoading, adminError } = UseAdminContext();
  const [adminName, setAdminName] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [adminPhone, setAdminPhone] = useState("");
  const [adminNewPassword, setAdminNewPassword] = useState("");
  const [adminConfirmPassword, setAdminConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [countryCode, setCountryCode] = useState("0");

  useEffect(() => {
    alert(adminError);
  }, [adminError]);

  const alert = (msg) => {
    setMessage(msg);

    setTimeout(() => {
      setMessage("");
    }, 4000);
  };

  const onSubmit = (event) => {
    const phone_with_code = countryCode.concat(adminPhone);

    event.preventDefault();
    if (
      !validator.isEmpty(adminName) &&
      !validator.isEmpty(adminPhone) &&
      !validator.isEmpty(adminNewPassword) &&
      !validator.isEmpty(adminConfirmPassword)
    ) {
      if (
        validator.isMobilePhone(adminPhone, "en-IN") &&
        validator.isEmail(adminEmail) &&
        validator.equals(adminNewPassword, adminConfirmPassword)
      ) {
        const credentials = {
          user: adminName,
          phone: phone_with_code,
          email: adminEmail,
          pass: adminConfirmPassword,
        };
        handleAdminRegister(credentials, (res) => {
          if (res === "success") {
            alert("Sucessfully Registered admin");
            setAdminName("");
            setAdminEmail("");
            setAdminPhone("");
            setAdminNewPassword("");
            setAdminConfirmPassword("");
          } else {
            alert("Admin Registration Fails");
          }
        });
      } else {
        alert("CREDENTIALS GIVEN IS INVALID");
      }
    } else {
      alert("CREDENTIALS IS EMPTY");
    }
  };

  return (
    <Card className="p-3 all_border">
      <Form>
        {message && (
          <Alert variant="info" className="text-center">
            {message}
          </Alert>
        )}
        <Form.Group className="mb-3" controlId="adminAddadminName">
          <Form.Label>Admin Name* :</Form.Label>
          <Form.Control
            className="form_feild"
            type="text"
            placeholder="Enter Unique User Name"
            value={adminName}
            onChange={(e) => {
              console.log(e.target.value);
              setAdminName(e.target.value);
            }}
            autoComplete="new-off"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="adminAddadminEmail">
          <Form.Label>Email address :</Form.Label>
          <Form.Control
            className="form_feild"
            type="email"
            placeholder="name@example.com"
            value={adminEmail}
            onChange={(e) => {
              console.log(e.target.value);
              setAdminEmail(e.target.value);
            }}
            autoComplete="new-off"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="adminAddadminPhone">
          <Form.Label>Phone No* :</Form.Label>
          <InputGroup>
            <Form.Select
              aria-label="Country Code"
              style={{ maxWidth: "10%" }}
              value={countryCode}
              className="form_feild countrywidth"
              onChange={(e) => {
                setCountryCode(e.target.value);
              }}
            >
              <option defaultChecked key={1} value="0">
                IND
              </option>
              <option key={2} value="+65">
                SGP
              </option>
              <option key={3} value="+60">
                MYS
              </option>
              <option key={4} value="+1">
                USA
              </option>
            </Form.Select>
            <Form.Control
              className="form_feild"
              type="phone"
              placeholder="Enter Mobile No"
              value={adminPhone}
              onChange={(e) => {
                console.log(e.target.value);
                setAdminPhone(e.target.value);
              }}
              autoComplete="new-off"
            />
          </InputGroup>
        </Form.Group>
        <Form.Group className="mb-3" controlId="adminAddadminNewPassword">
          <Form.Label>New Password* :</Form.Label>
          <Form.Control
            className="form_feild"
            type="password"
            placeholder="Enter New Password"
            value={adminNewPassword}
            onChange={(e) => {
              console.log(e.target.value);
              setAdminNewPassword(e.target.value);
            }}
            autoComplete="new-password"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="adminAddadminConfirmPassword">
          <Form.Label>Confirm Password* :</Form.Label>
          <Form.Control
            className="form_feild"
            type="password"
            placeholder="Enter Confirm Password"
            value={adminConfirmPassword}
            onChange={(e) => {
              console.log(e.target.value);
              setAdminConfirmPassword(e.target.value);
            }}
            autoComplete="new-password"
          />
        </Form.Group>
      </Form>
      <Card.Footer className="text-center all_border">
        {!adminLoading ? (
          <Button
            style={{ borderRadius: 50 }}
            className="all_btn"
            type="submit"
            onClick={onSubmit}
          >
            Register Admin
          </Button>
        ) : (
          <Button className="all_btn" style={{ borderRadius: 50 }} disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Loading...
          </Button>
        )}
      </Card.Footer>
    </Card>
  );
};
export default AdminAddAdminForm;
