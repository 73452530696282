import React, { useState, useEffect, Suspense } from "react";
import { UseAuth } from "../context/Auth";
import { useNavigate, Link, Outlet } from "react-router-dom";
import {
  Button,
  Form,
  Container,
  Row,
  Col,
  InputGroup,
  Card,
  Spinner,
  Alert,
  ModalTitle,
  Tab,
  Tabs,
  FloatingLabel,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import validator from "validator";
import Axios from "axios";
import { vasthuUrl } from "../component/Api";
import { UseVasthu } from "../context/vasthu";
import JothiButton from "../component/JothiButton";
import { usePay } from "../context/PaymentContext";
import ObjectEmpty from "../component/ObjectEmptyChecker";
//modal - active list
// import Modal from "react-modal";
import PaymentProcess from "../component/PaymentProcess";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import BillDeskPay from "../component/BillDeskPay";

const customStyles = {
  content: {
    top: "20%",
    left: "20%",
    right: "20%",
    bottom: "20%",
    // marginRight: '-50%',
    // transform: 'translate(-50%, -50%)',
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement("#root");

// -End

const CustomerPlotForm = () => {
  const navigate = useNavigate();
  // const { userId } = UseAuth();
  const userId = window.sessionStorage.getItem("userId");
  console.log("userId" + userId);
  const { addPlotRecId, lagnaList, facingList } = UseVasthu();
  const { t } = useTranslation();
  const token = window.sessionStorage.getItem("token");
  const [pOwner, setPOwner] = useState("");
  const [pArea, setPArea] = useState("");
  const [pfacing, setPfacing] = useState("");
  const [plagna, setPlagna] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const { getPayToken, payloading } = usePay();
  const [activeServiceList, setActiveServiceList] = useState([]);
  const [showPaytm, setShowPaytm] = useState(false);
  const [showPaytm1, setShowPaytm1] = useState(false);
  const [paymentCredentials, setPaymentCredentials] = useState({
    txnToken: "",
    orderId: "",
    amount: 0,
    offerId: 0,
    userId: "",
  });
  const [defaultPlotOffer, setDefaultPlotOffer] = useState({
    offerId: 0,
    offerPrice: 0,
  });
  const lang = localStorage.getItem("i18nextLng");
  const [defaultAudioOffer, setDefaultAudioOffer] = useState({
    offerId: 0,
    offerPrice: 0,
  });
  //Modal - active list
  let subtitle;
  const [modalIsOpen, setIsOpen] = React.useState(false);

  //

  const langCode = window.localStorage.getItem("i18nextLng");
  const { plotRec } = UseVasthu();
  const [plotObj, setPlotObj] = useState({});

  function openModal() {
    setIsOpen(true);
  }

  async function afterOpenModal() {
    try {
      const res = await vasthuUrl.post(
        `saveplot`,
        {
          ownerName: pOwner,
          plotArea: pArea,
          uuid: userId,
          directionId: Number(pfacing),
          lagnaId: Number(plagna),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      alert(res.data.message);
      addPlotRecId(res.data.plotId);
      setLoading(false);
      setPOwner("");
      setPArea("");
      setPfacing("");
      setPlagna("");
    } catch (e) {
      console.log("server error");
      alert("SERVER ERROR");
      addPlotRecId("");
      setLoading(false);
    }
  }

  // const savePayLater = async (e) => {
  //   e.preventDefault();
  //   if (
  //     validator.isNumeric(pArea, { no_symbols: true }) &&
  //     !validator.isEmpty(pArea) &&
  //     !validator.isEmpty(pOwner) &&
  //     !validator.isEmpty(pfacing) &&
  //     !validator.isEmpty(plagna) &&
  //     !validator.isEmpty(token)
  //   ) {
  //     setLoading(true);
  //     const res = await vasthuUrl.post(
  //       `saveplot`,
  //       {
  //         ownerName: pOwner,
  //         plotArea: pArea,
  //         uuid: userId,
  //         directionId: Number(pfacing),
  //         lagnaId: Number(plagna),
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     alert(res.data.message);
  //     setLoading(false);
  //     navigate("/buildingdetails", { state: { key: "payLaterRecords" } });
  //     setPArea("");
  //     setPOwner("");
  //     setPfacing("");
  //     setPlagna("");
  //   } else {
  //     alert("PLEASE IN ALL FIELD");
  //   }
  // };

  function closeModal() {
    setIsOpen(false);
  }

  //End -modal

  useEffect(() => {
    vasthuUrl
      .get(`offer/defaultIndividualOffer/plot`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setDefaultPlotOffer({
          offerId: res.data.id,
          offerPrice: res.data.offerPrice,
        });
      })
      .catch((err) => setDefaultPlotOffer({}));
  }, []);

  const alert = (msg) => {
    setMessage(msg);
    setTimeout(() => {
      setMessage("");
    }, 4000);
  };

  console.log("hello", payloading);

  const BillResponseHandler = (txn) => {
    console.log("plot pay handler", txn, typeof txn.status);
    if (txn.status === 200) {
      try {
        vasthuUrl
          .post(
            "vasthu/user/bdpaymentresponseui",
            { txn: txn.txnResponse.transaction_response },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            console.log("txnres", res.data);
            // alert(res.data)
            if (!ObjectEmpty(res.data)) {
              console.log("authstatus", res.data.auth_status);
              //newly added
              if (res.data.auth_status === "0300") {
                console.log("Saving payment_response ....", txn);

                vasthuUrl
                  .post("vasthu/user/bdpaymentresponsehandler", txn, {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  })
                  .then((res) => console.log("after txnsave", res.data))
                  .catch((err) => console.log("after txnsave try", err));

                //   navigate("/plotvalidate", { state: res.data });

                // } else {
                //   alert("Transaction failed, please go back");
                // }

                console.log("buying .. subsc");

                setTimeout(() => {
                  vasthuUrl
                    .post(
                      "subscription/buyVasthuOffer",
                      {
                        orderId: res.data.orderid,
                        userUuid: res.data.additional_info.additional_info1,
                        offerId: parseInt(
                          res.data.additional_info.additional_info2
                        ),
                        paymentStatus: true,
                      },
                      {
                        headers: {
                          Authorization: `Bearer ${token}`,
                        },
                      }
                    )
                    .then((res) => {
                      console.log("Payment status changed to true");
                      console.log("afterbuysubscr", res.data);
                      navigate("/plotvalidate", {
                        state: { offerId: res.data.id },
                      });
                      //  this.props.navigate(this.props.screenName,{state:{offerId:res.data.id}})
                    });
                }, 5000);
              } else {
                vasthuUrl
                  .post(
                    "subscription/buyVasthuOffer",
                    {
                      orderId: res.data.orderid,
                      userUuid: res.data.additional_info.additional_info1,
                      offerId: parseInt(
                        res.data.additional_info.additional_info2
                      ),
                      paymentStatus: false,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  )
                  .then((res) => {
                    console.log("Payment status changed to false");
                  });
              }
              //end - need to verify
            }
          });
      } catch (err) {
        console.log("fails", err);
      }
    }
  };

  const handleBuy = async (val, price) => {
    try {
      await vasthuUrl
        .post(
          `vasthu/openapi/createorderapi`,
          {
            userUuid: userId,
            offerId: val,
            price: price,
            currency: "356",
            returnUrl: "plotvalidate",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          BillDeskPay(res.data, BillResponseHandler)
            .then((config) => {
              console.log(config);
              window.loadBillDeskSdk(config);
            })
            .catch((err) => console.log("pay fail", err));
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handlePsumbit = async (event) => {
    setLoading(true);
    event.preventDefault();
    try {
      if (
        validator.isNumeric(pArea, { no_symbols: true }) &&
        !validator.isEmpty(pArea) &&
        !validator.isEmpty(pOwner) &&
        !validator.isEmpty(pfacing) &&
        !validator.isEmpty(plagna) &&
        !validator.isEmpty(token)
      ) {
        try {
          vasthuUrl
            .get(`/subscription/userActiveOffers/${userId}/1`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then(async (list) => {
              console.log(
                "plotservice-list-if available " + JSON.stringify(list.data)
              );
              setActiveServiceList(list.data);

              if (JSON.stringify(list.data) !== "[]") {
                try {
                  const res = await vasthuUrl.post(
                    `saveplot`,
                    {
                      ownerName: pOwner,
                      plotArea: pArea,
                      uuid: userId,
                      directionId: Number(pfacing),
                      lagnaId: Number(plagna),
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${token}`,
                      },
                    }
                  );
                  addPlotRecId(res.data.plotId);
                  console.log("after save", res.data);
                  setLoading(false);
                  setPArea("");
                  setPOwner("");
                  setPfacing("");
                  setPlagna("");
                  openModal();
                } catch (error) {
                  console.log("Server error");
                  alert("SERVER ERROR");
                  addPlotRecId("");
                  setLoading(false);
                }
              } else {
                console.log("no subscription - please proceed with pay");
                const res = await vasthuUrl.post(
                  `saveplot`,
                  {
                    ownerName: pOwner,
                    plotArea: pArea,
                    uuid: userId,
                    directionId: Number(pfacing),
                    lagnaId: Number(plagna),
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                );
                addPlotRecId(res.data.plotId);
                console.log("after save", res.data);
                setLoading(false);
                handleBuy(
                  defaultPlotOffer.offerId,
                  defaultPlotOffer.offerPrice
                );
              }
            });
        } catch (error) {
          console.log("error from trying subscription list " + error);
        }
      } else {
        alert("GIVEN INPUT IS INVALID");
        setLoading(false);
      }
    } catch (e) {
      alert("CHECK INTERNET CONNECTION");
      setLoading(false);
      // console.log("SERVER ERROR", e);
    }
  };

  const handlePsumbitFree = async (event) => {
    setLoading(true);
    event.preventDefault();
    try {
      if (
        validator.isNumeric(pArea, { no_symbols: true }) &&
        !validator.isEmpty(pArea) &&
        !validator.isEmpty(pOwner) &&
        !validator.isEmpty(pfacing) &&
        !validator.isEmpty(plagna) &&
        !validator.isEmpty(token)
      ) {
        try {
          console.log("no subscription - please proceed with pay");
          const res = await vasthuUrl.post(
            `saveplot`,
            {
              ownerName: pOwner,
              plotArea: pArea,
              uuid: userId,
              directionId: Number(pfacing),
              lagnaId: Number(plagna),
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          addPlotRecId(res.data.plotId);
          console.log("after save", res.data);
          setLoading(false);
          setPOwner("");
          setPArea("");
          setPfacing("");
          setPlagna("");

          // setLoading(true);
          console.log("plotId", res.data.plotId, typeof res.data.plotId);

          // try{

          // setTimeout(() => {
          vasthuUrl
            .get(`/validateplotresult/${res.data.plotId}/${langCode}`, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => {
              setPlotObj(res.data);
              setLoading(false);
              console.log("Plot record screen", res.data);
              navigate("/plotvalidatefree", {
                state: res.data,
              });
            });
          // }, 3000);
        } catch (error) {
          console.log("error from trying subscription list " + error);
        }
      } else {
        alert("GIVEN INPUT IS INVALID");
        setLoading(false);
      }
    } catch (e) {
      alert("CHECK INTERNET CONNECTION");
      setLoading(false);
      // console.log("SERVER ERROR", e);
    }
  };

  return (
    <div>
      <div>
        <Modal
          size="lg"
          show={modalIsOpen}
          onAfterOpen={afterOpenModal}
          onRequestClose={closeModal}
          onHide={closeModal}
        >
          <Modal.Header className="all_background" closeButton>
            <Modal.Title>{t("USR.BOK.UR.PLS.TL")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              {/* {JSON.stringify(activeServiceList)} */}
              <div className="row text-center ">
                {activeServiceList.length > 0 ? (
                  activeServiceList.map((i, j) => (
                    <div className="col-sm-12 col-md-4 col-lg-3 g-1" key={j}>
                      <div className="card mb-2 all_border">
                        <div className="card-body">
                          <h4 className="card-title">{i.offerName}</h4>
                          <p className="card-title">{i.offerFrequency}</p>
                          <h3 className="card-text">
                            ₹{i.offerPrice} <s>400</s>
                          </h3>
                          <h3 className="card-text">
                            Available Qty {i.availableQty}
                          </h3>
                          {i.offerEndDate ? (
                            <p className="card-title mt-1">
                              valid till {i.offerEndDate}
                            </p>
                          ) : null}
                          {i.offerDetails
                            ? i.offerDetails.map((x, y) => (
                                <li
                                  className="list-group-item d-flex justify-content-between align-items-center"
                                  key={y}
                                >
                                  {x.serviceName}({x.serviceCostType})
                                  <span className="badge bg-primary rounded-pill">
                                    {" "}
                                    {x.count} {x.count > 1 ? "times" : "time"}
                                  </span>
                                </li>
                              ))
                            : null}
                          <Button
                            className="all_btn"
                            onClick={() => {
                              navigate("/plotvalidate", {
                                state: { offerId: i.id },
                              });
                              setLoading(false);
                            }}
                          >
                            Proceed
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <h4>No Plans Available</h4>
                )}
              </div>
              <Modal.Footer className="d-flex justify-content-end all_border">
                <Button className="all_btn" onClick={closeModal}>
                  {t("TXT.BTN.CAN.LO")}
                </Button>
              </Modal.Footer>
            </form>
          </Modal.Body>
        </Modal>
      </div>
      <Row>
        <Col xl="3" lg="3" md="2" sm="1" />
        <Col xl="6" lg="6" md="8" sm="10">
          <Card className="p-2 m-1 mt-3 all_border">
            <Card.Header as="h5" className="all_background">
              <center>{t("USR.HOM.HD.VFP")}</center>
            </Card.Header>
            <Card.Body>
              <Form className="mt-2">
                {/* {message && (
                    <Card.Body className="text-center" style={{ color: "red" }}>
                      {message}
                    </Card.Body>
                  )} */}
                {message && (
                  <Alert variant="info" className="text-center">
                    {/* {message} */}
                    {t("USR.VFP.PLT.INVD.INP")}
                  </Alert>
                )}

                <Form.Floating className="mb-3">
                  <Form.Control
                    id="floatingPlotOwner"
                    type="text"
                    value={pOwner}
                    onChange={(e) => {
                      let input = e.target.value;
                      // Ensure only the first character is a letter
                      input = input.replace(/^([^A-Za-z])/, "");
                      // Allow only alphanumeric characters after the first character
                      input = input.replace(/[^A-Za-z0-9]/g, "");
                      setPOwner(input);
                    }}
                    placeholder={t("USR.VSH.RES.ACR.MSC.TBL.CLMN.NM")}
                    className="form_feild"
                  />
                  <label htmlFor="floatingPlotOwner">
                    {t("USR.VFP.INP.PO")}
                  </label>
                </Form.Floating>

                <Form.Floating className="mb-3">
                  <Form.Control
                    id="floatingPlotArea"
                    type="number"
                    min="0"
                    value={pArea}
                    onChange={(e) => setPArea(e.target.value)}
                    placeholder={t("USR.VFPR.TBL.CLMN.AR")}
                    className="form_feild"
                  />
                  <label htmlFor="floatingPlotArea">
                    {t("USR.VFP.INP.PA")}
                  </label>
                </Form.Floating>

                <FloatingLabel
                  className="mb-2"
                  controlId="formPlotFacing"
                  label={t("USR.VFP.DRP.LBL.PF")}
                >
                  <OverlayTrigger
                    placement="left"
                    overlay={
                      <Tooltip id="button-tooltip">
                        {t("USR.VSH.HFTAB.HF.HTXT")}
                      </Tooltip>
                    }
                  >
                    <Form.Select
                      aria-label={t("USR.VFP.DRP.LBL.PF")}
                      // style={{ fontSize: "1rem" }}
                      value={pfacing}
                      onChange={(e) => {
                        setPfacing(e.target.value);
                      }}
                      className="form_feild"
                    >
                      <option value="" className="opttion_hover">
                        {t("USR.VSH.HFTAB.HF.PH")}
                      </option>
                      {facingList.map((i, j) => (
                        <option key={j} value={i.id} className="opttion_hover">
                          {lang === "ta" ? (
                            <p>{i.ta_name}</p>
                          ) : lang === "hi" ? (
                            <p>{i.hi_name}</p>
                          ) : (
                            <p>{i.name}</p>
                          )}
                        </option>
                      ))}
                    </Form.Select>
                  </OverlayTrigger>
                </FloatingLabel>

                <FloatingLabel
                  className="mb-2"
                  controlId="formPlotLagna"
                  label={t("USR.VFP.DRP.LBL.LG")}
                >
                  <Form.Select
                    aria-label={t("USR.VFP.DRP.LBL.LG")}
                    style={{ fontSize: "1rem" }}
                    value={plagna}
                    onChange={(e) => {
                      setPlagna(e.target.value);
                    }}
                    className="form_feild"
                  >
                    <option value="">{t("USR.VFP.DRP.PH.LG")}</option>
                    {lagnaList.map((i, j) => (
                      <option key={j} value={i.id}>
                        {lang === "ta" ? (
                          <p>{i.ta_name}</p>
                        ) : lang === "hi" ? (
                          <p>{i.hi_name}</p>
                        ) : (
                          <p>{i.name}</p>
                        )}
                      </option>
                    ))}
                  </Form.Select>
                </FloatingLabel>

                <Form.Group
                  className="d-grid gap-2 mt-2"
                  controlId="formSubmitBtn"
                >
                  <center>
                    {" "}
                    <h4 className="card-text">
                      {t("USR.FREE")} <s>99</s>
                    </h4>
                  </center>
                  {!loading ? (
                    <Col className="d-flex justify-content-center">
                      <Button
                        className="plot-submit all_btn mx-2 all_btn"
                        type="submit"
                        onClick={handlePsumbitFree}
                      >
                        {t("USR.VFP.PV.BTN.SMTPY")}
                      </Button>
                    </Col>
                  ) : (
                    <center>
                      <Spinner
                        animation="border"
                        role="status"
                        variant="warning"
                      />
                    </center>
                  )}
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
        </Col>{" "}
        <Col xl="3" lg="3" md="2" sm="1" />
      </Row>

      {showPaytm && (
        <PaymentProcess
          txnToken={paymentCredentials.txnToken}
          orderId={paymentCredentials.orderId}
          amount={paymentCredentials.amount}
          offerId={paymentCredentials.offerId}
          userId={paymentCredentials.userId}
          navigate={navigate}
          screenName={`/plotvalidate`}
        />
      )}
    </div>
  );
};

export default CustomerPlotForm;
