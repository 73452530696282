// import React, { useState, useEffect } from "react";
// import "../App.css";
// import { RegExpRoute } from "workbox-routing";
// import { NavLink, useNavigate } from "react-router-dom";
// import { UseAuth } from "../context/Auth";
// import {
//   Form,
//   Button,
//   Container,
//   Row,
//   Col,
//   Card,
//   Spinner,
//   Modal,
//   Alert,
//   InputGroup,
// } from "react-bootstrap";
// import validator from "validator";
// import { useTranslation } from "react-i18next";
// import { Button as MyButton } from "@material-ui/core";
// import { Send } from "@material-ui/icons";
// //eye pwd
// import { FaEye } from "react-icons/fa";
// import { FaEyeSlash } from "react-icons/fa";

// import ReactGA from "react-ga4";

// const Register = () => {
//   const [t] = useTranslation();
//   const { handleRegister, loading, error } = UseAuth();
//   const lang = window.localStorage.getItem("i18nextLng");
//   console.log("lang", lang);
//   const navigate = useNavigate();
//   const [user, setUser] = useState("");
//   const [phone, setPhone] = useState("");
//   const [email, setEmail] = useState("");
//   const [setspass, setSPass] = useState("");
//   const [setcpass, setCPass] = useState("");
//   const [message, setMessage] = useState("");
//   const [show, setShow] = useState(false);
//   const [countryCode, setCountryCode] = useState("0");

//   const [showPass, setShowPass] = useState(false);

//   //eye pwd
//   const [showPassword, setShowPassword] = useState(false);
//   const [showcPassword, setShowcPassword] = useState(false);

//   //eye pwd
//   const handleTogglePassword = () => {
//     setShowPassword(!showPassword);
//   };
//   const handleTogglecPassword = () => {
//     setShowcPassword(!showcPassword);
//   };

//   const handleShow = () => {
//     // setShow(true)
//     // const credentials = { user, phone, email, pass: setcpass };

//     const phone_with_code = countryCode.concat(phone);
//     if (
//       !validator.isEmpty(user) &&
//       !validator.isEmpty(phone) &&
//       !validator.isEmpty(setcpass) &&
//       !validator.isEmpty(setspass)
//     ) {
//       if (
//         validator.isMobilePhone(phone_with_code, [
//           "en-IN",
//           "en-SG",
//           "ms-MY",
//           "en-US",
//         ]) &&
//         validator.isEmail(email) &&
//         validator.equals(setcpass, setspass)
//       ) {
//         // handleRegister(credentials, (res) => {
//         //   if (res === "success") {
//         //     setUser("");
//         //     setPhone("");
//         //     setEmail("");
//         //     setSPass("");
//         //     setCPass("");
//         //     alert(t("error.success"))
//         //   } else {
//         //     alert(t("error.invalid"));
//         //   }
//         // });
//         setShow(true);
//       } else if (!validator.equals(setcpass, setspass)) {
//         alert(t("Plese Enter Same Password!"));
//       } else {
//         alert(t("USR.HOM.ERR.MSG1"));
//       }
//     } else {
//       alert(t("USR.HOM.ERR.MSG2"));
//     }
//   };

//   const handleClose = () => setShow(false);
//   // const handleshow =()=>{
//   //   if (
//   //     !validator.isEmpty(user) &&
//   //     !validator.isEmpty(phone) &&
//   //     !validator.isEmpty(setcpass) &&
//   //     !validator.isEmpty(setspass)
//   //   ) {
//   //     if (
//   //       validator.isMobilePhone(phone, "en-IN") &&
//   //       validator.isEmail(email) &&
//   //       validator.equals(setcpass, setspass)
//   //     )
//   //     {setShow(true);}

//   //       else {
//   //       alert(t("Please enter valid input"));
//   //     }
//   //   } else {
//   //     alert(t("Please fill all the inputs"));
//   //   }

//   // }
//   useEffect(() => {
//     alert(error);
//   }, [error]);

//   const alert = (msg) => {
//     setMessage(msg);

//     setTimeout(() => {
//       setMessage("");
//     }, 4000);
//   };

//   const onSubmit = (event) => {
//     event.preventDefault();
//     // const credentials = { user, phone, email, pass: setcpass };
//     // if (
//     //   !validator.isEmpty(user) &&
//     //   !validator.isEmpty(phone) &&
//     //   !validator.isEmpty(setcpass) &&
//     //   !validator.isEmpty(setspass)
//     // ) {
//     //   if (
//     //     validator.isMobilePhone(phone, "en-IN") &&
//     //     validator.isEmail(email) &&
//     //     validator.equals(setcpass, setspass)
//     //   ) {
//     //     handleRegister(credentials, (res) => {
//     //       if (res === "success") {
//     //         setUser("");
//     //         setPhone("");
//     //         setEmail("");
//     //         setSPass("");
//     //         setCPass("");
//     //         alert(t("error.success"))
//     //       } else {
//     //         alert(t("error.invalid"));
//     //       }
//     //     });
//     //   } else {
//     //     alert(t("error.invalid"));
//     //   }
//     // } else {
//     //   alert(t("error.empty"));
//     // }
//     const phone_with_code = countryCode.concat(phone);
//     const credentials = {
//       user,
//       phone: phone_with_code,
//       email,
//       pass: setcpass,
//       lng: lang,
//     };
//     handleRegister(credentials, (res) => {
//       if (res === "success") {
//         handleClose();
//         setUser("");
//         setPhone("");
//         setEmail("");
//         setSPass("");
//         setCPass("");
//         alert(t("USR.HOM.SUCC.MSG1"));
//         //GA --
//         ReactGA.event({
//           category: "User",
//           action: `Successfully Sign-Up Event`,
//         });

//         window.dataLayer.push({ event: "my-custom-sighup" });

//         //GA-End
//       } else {
//         handleClose();
//         alert(t("USR.HOM.ERR.MSG1"));
//       }

//       const prefix = "+1";
//     });
//   };
//   return (
//     <Container fluid>
//       <Row className="signuprow2">
//         <Col sm={2} md={3} lg={4} />
//         <Col xs={12} sm={8} md={6} lg={4}>
//           <Card border="warning all_border" className="p-4 mt-3">
//             <Card.Body className="text-center">
//               <Card.Title style={{ color: "#ffbb33" }}>
//                 {t("USR.HOM.HD.TL")}
//               </Card.Title>
//             </Card.Body>
//             <Form>
//               {message && (
//                 <Alert variant="info" className="text-center">
//                   {message}
//                 </Alert>
//               )}
//               <Form.Group className="mb-3" controlId="formBasicUser">
//                 <Form.Label>{t("USR.HOM.HD.UN")}</Form.Label>
//                 <Form.Control
//                   type="text"
//                   value={user}
//                   className="form_feild"
//                   placeholder={t("USR.HOM.HD.UN")}
//                   onChange={(e) => {
//                     setUser(e.target.value);
//                   }}
//                   autoComplete="new-off"
//                 />
//               </Form.Group>
//               <Form.Group className="mb-3" controlId="formBasicPhone">
//                 <Form.Label>{t("USR.HOM.HD.P")}</Form.Label>
//                 <InputGroup className="mb-3">
//                   <Form.Select
//                     aria-label="Country Code"
//                     style={{ maxWidth: "20%" }}
//                     value={countryCode}
//                     className="form_feild countrywidth"
//                     onChange={(e) => {
//                       setCountryCode(e.target.value);
//                     }}
//                   >
//                     <option defaultChecked key={1} value="0">
//                       IND
//                     </option>
//                     <option key={2} value="+65">
//                       SGP
//                     </option>
//                     <option key={3} value="+60">
//                       MYS
//                     </option>
//                     <option key={4} value="+1">
//                       USA
//                     </option>
//                   </Form.Select>
//                   <Form.Control
//                     type="phone"
//                     value={phone}
//                     className="form_feild"
//                     placeholder={t("USR.HOM.HD.P")}
//                     onChange={(e) => {
//                       setPhone(e.target.value);
//                     }}
//                     autoComplete="new-off"
//                   />
//                 </InputGroup>
//                 {/* <Form.Text>
//                   Prefix 0 before your 10 digit phone number - 0xxxxxxxxxx
//                   format{" "}
//                 </Form.Text> */}
//               </Form.Group>
//               <Form.Group className="mb-3" controlId="formBasicEmail">
//                 <Form.Label>{t("USR.HOM.HD.EM")}</Form.Label>
//                 <Form.Control
//                   type="email"
//                   value={email}
//                   className="form_feild"
//                   placeholder={t("USR.HOM.HD.EM")}
//                   onChange={(e) => {
//                     setEmail(e.target.value);
//                   }}
//                   autoComplete="new-off"
//                 />
//                 {/* <Form.Text className="text-muted">
//                   We'll never share your email with anyone else.
//                 </Form.Text> */}
//               </Form.Group>

//               <Form.Group className="mb-3" controlId="formBasicSPassword">
//                 <Form.Label>{t("USR.HOM.HD.CPWD")}</Form.Label>
//                 <InputGroup className="mb-3">
//                   <Form.Control
//                     type={showPassword ? "text" : "password"}
//                     value={setspass}
//                     className="form_feild"
//                     placeholder={t("USR.HOM.HD.CPWD")}
//                     onChange={(e) => {
//                       setSPass(e.target.value);
//                     }}
//                     autoComplete="new-password"
//                   />
//                   <InputGroup.Text
//                     onClick={handleTogglePassword}
//                     className="form_feild"
//                   >
//                     {showPassword ? <FaEye /> : <FaEyeSlash />}
//                   </InputGroup.Text>
//                 </InputGroup>
//               </Form.Group>
//               <Form.Group className="mb-3" controlId="formBasicCPassword">
//                 <Form.Label>{t("USR.HOM.HD.CFPWD")}</Form.Label>
//                 <InputGroup className="mb-3">
//                   <Form.Control
//                     type={showcPassword ? "text" : "password"}
//                     value={setcpass}
//                     className="form_feild"
//                     placeholder={t("USR.HOM.HD.CFPWD")}
//                     onChange={(e) => {
//                       setCPass(e.target.value);
//                     }}
//                     autoComplete="new-password"
//                   />
//                   <InputGroup.Text
//                     onClick={handleTogglecPassword}
//                     className="form_feild"
//                   >
//                     {showcPassword ? <FaEye /> : <FaEyeSlash />}
//                   </InputGroup.Text>
//                 </InputGroup>
//               </Form.Group>
//               {!loading ? (
//                 <MyButton
//                   style={{ backgroundColor: "#ffb33e", color: "white" }}
//                   variant="contained"
//                   fullWidth="true"
//                   // onClick={onSubmit}
//                   onClick={handleShow}
//                   endIcon={<Send />}
//                 >
//                   {t("USR.HOM.HD.REG")}
//                 </MyButton>
//               ) : (
//                 <>
//                   <MyButton
//                     style={{ backgroundColor: "#ffb33e", color: "white" }}
//                     fullWidth="true"
//                     disabled
//                   >
//                     <Spinner
//                       as="span"
//                       animation="border"
//                       size="sm"
//                       role="status"
//                       aria-hidden="true"
//                     />
//                     {t("USR.HOM.BTN.LOD")}
//                   </MyButton>
//                 </>
//               )}
//             </Form>
//             <>
//               <Modal show={show} onHide={handleClose}>
//                 <Modal.Header className="all_background" closeButton>
//                   <Modal.Title>{t("USR.HOM.HD.REG")}</Modal.Title>
//                 </Modal.Header>
//                 <Modal.Body>{t("USR.VSH.HFTAB.MDL.CNF.ALRT1")}</Modal.Body>
//                 <Modal.Footer className="d-flex justify-content-evenly all_border">
//                   <Button
//                     className="all_btn"
//                     style={{
//                       borderRadius: 50,
//                     }}
//                     onClick={handleClose}
//                   >
//                     {t("USR.VSH.HFTAB.MDL.CNF.BTN.CNC")}
//                   </Button>
//                   <Button
//                     className="all_btn"
//                     style={{
//                       borderRadius: 50,
//                     }}
//                     onClick={onSubmit}
//                   >
//                     {t("USR.VSH.HFTAB.MDL.CNF.BTN.CNF")}
//                   </Button>
//                 </Modal.Footer>
//               </Modal>
//             </>
//             <Card.Body className="text-center">
//               <Form.Label>{t("USR.HOM.HD.AA")}</Form.Label>
//               <br />
//               <NavLink to="/login">{t("USR.HOM.HD.LOG")}</NavLink>
//             </Card.Body>
//           </Card>
//         </Col>
//         <Col sm={2} md={3} lg={4} />
//       </Row>
//     </Container>
//   );
// };

// export default Register;
import { Button as MyButton } from "@material-ui/core";
import { Send } from "@material-ui/icons";
import React, { useState } from "react";
import {
  Button,
  Card,
  Form,
  InputGroup,
  Modal,
  Spinner
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import validator from "validator";
import "../App.css";
import { UseAuth } from "../context/Auth";
//eye pwd
import { FaEye, FaEyeSlash } from "react-icons/fa";

import ReactGA from "react-ga4";

const Register = ({ setActiveKey, alertType, alert }) => {
  const [t] = useTranslation();
  const { handleRegister, loading, error } = UseAuth();
  const lang = window.localStorage.getItem("i18nextLng");
  console.log("lang", lang);
  const [user, setUser] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [setspass, setSPass] = useState("");
  const [setcpass, setCPass] = useState("");
  const [show, setShow] = useState(false);
  const [countryCode, setCountryCode] = useState("0");

  //eye pwd
  const [showPassword, setShowPassword] = useState(false);
  const [showcPassword, setShowcPassword] = useState(false);

  //eye pwd
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleTogglecPassword = () => {
    setShowcPassword(!showcPassword);
  };

  const handleShow = () => {
    const phone_with_code = countryCode.concat(phone);
    if (
      !validator.isEmpty(user) &&
      !validator.isEmpty(phone) &&
      !validator.isEmpty(setcpass) &&
      !validator.isEmpty(setspass)
    ) {
      if (
        validator.isMobilePhone(phone_with_code, [
          "en-IN",
          "en-SG",
          "ms-MY",
          "en-US",
        ]) &&
        validator.equals(setcpass, setspass)
      ) {
        if (setcpass.length < 6) {
          alert("Please enter a minimum of 6 characters");
          alertType("error");
        } else if (setcpass !== setspass) {
          alert(t("Please Enter Same Password..............!"));
          alertType("error");
        } else {
          // alert("Passwords match and are valid............!");
          setShow(true);
        }
      } else if (!validator.equals(setcpass, setspass)) {
        alert(t("Plese Enter Same Password!"));
        alertType("error");
      } else {
        alert(t("USR.HOM.ERR.MSG1"));
        alertType("error");
      }
    } else {
      alert(t("USR.HOM.ERR.MSG2"));
      alertType("error");
    }
  };

  const handleClose = () => setShow(false);

  const onSubmit = (event) => {
    event.preventDefault();
    const phone_with_code = countryCode.concat(phone);
    const credentials = {
      user,
      phone: phone_with_code,
      email,
      pass: setcpass,
      lng: lang,
    };
    handleRegister(credentials, (res) => {
      if (res === "success") {
        handleClose();
        setUser("");
        setPhone("");
        setEmail("");
        setSPass("");
        setCPass("");
        alert(t("USR.HOM.SUCC.MSG1"));
        alertType("success");
        setActiveKey("login");
        //GA --
        ReactGA.event({
          category: "User",
          action: `Successfully Sign-Up Event`,
        });

        window.dataLayer.push({ event: "my-custom-sighup" });

        //GA-End
      } else {
        handleClose();
        alert(t("USR.HOM.ERR.MSG1"));
        alertType("error");
      }

      const prefix = "+1";
    });
  };
  return (
    <React.Fragment>
      <Card border="warning all_border" className="p-4 mt-3">
        <Card.Body className="text-center">
          <Card.Title style={{ color: "#ffbb33" }}>
            <u>{t("USR.LOG.PWD.REG.HED")}</u>
          </Card.Title>
        </Card.Body>
        <Form>
          <Form.Group className="mb-3" controlId="formBasicUser">
            <Form.Label>{t("USR.HOM.HD.UN")}</Form.Label>
            <Form.Control
              type="text"
              value={user}
              className="form_feild"
              placeholder={t("USR.HOM.HD.UN")}
              onChange={(e) => {
                setUser(e.target.value);
              }}
              autoComplete="new-off"
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicPhone">
            <Form.Label>{t("USR.HOM.HD.P")}</Form.Label>
            <InputGroup className="mb-3">
              <Form.Select
                aria-label="Country Code"
                style={{ maxWidth: "20%" }}
                value={countryCode}
                className="form_feild countrywidth"
                onChange={(e) => {
                  setCountryCode(e.target.value);
                }}
              >
                <option defaultChecked key={1} value="0">
                  IND
                </option>
                <option key={2} value="+65">
                  SGP
                </option>
                <option key={3} value="+60">
                  MYS
                </option>
                <option key={4} value="+1">
                  USA
                </option>
              </Form.Select>
              <Form.Control
                type="tel"
                value={phone}
                className="form_feild"
                placeholder={t("USR.HOM.HD.P")}
                onChange={(e) => {
                  const value = e.target.value.replace(/\D/g, "");
                  setPhone(value);
                }}
                autoComplete="new-off"
              />
            </InputGroup>
            {/* <Form.Text>
                  Prefix 0 before your 10 digit phone number - 0xxxxxxxxxx
                  format{" "}
                </Form.Text> */}
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>{t("USR.HOM.HD.EM")}</Form.Label>
            <Form.Control
              type="email"
              value={email}
              className="form_feild"
              placeholder={t("USR.HOM.HD.EM")}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              autoComplete="new-off"
            />
            {/* <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text> */}
          </Form.Group>

          <Form.Group className="mb-3" controlId="formBasicSPassword">
            <Form.Label>{t("USR.HOM.HD.CPWD")}</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                type={showPassword ? "text" : "password"}
                value={setspass}
                className="form_feild"
                placeholder={t("USR.HOM.HD.CPWD")}
                onChange={(e) => {
                  setSPass(e.target.value);
                }}
                autoComplete="new-password"
                minLength={6}
              />
              {/* <Form.Control
                type={showPassword ? "text" : "password"}
                value={setspass}
                className="form_feild"
                placeholder={t("USR.HOM.HD.CPWD")}
                onChange={(e) => {
                  if (e.target.value.length <= 6) {
                    setSPass(e.target.value);
                  }
                }}
                autoComplete="new-password"
                maxLength={6} 
              /> */}
              <InputGroup.Text
                onClick={handleTogglePassword}
                className="form_feild"
              >
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicCPassword">
            <Form.Label>{t("USR.HOM.HD.CFPWD")}</Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                type={showcPassword ? "text" : "password"}
                value={setcpass}
                className="form_feild"
                placeholder={t("USR.HOM.HD.CFPWD")}
                onChange={(e) => {
                  setCPass(e.target.value);
                }}
                autoComplete="new-password"
                minLength={6}
              />
              <InputGroup.Text
                onClick={handleTogglecPassword}
                className="form_feild"
              >
                {showcPassword ? <FaEye /> : <FaEyeSlash />}
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          {!loading ? (
            <MyButton
              style={{ backgroundColor: "#ffb33e", color: "white" }}
              variant="contained"
              fullWidth="true"
              // onClick={onSubmit}
              onClick={handleShow}
              endIcon={<Send />}
            >
              {t("USR.HOM.HD.REG")}
            </MyButton>
          ) : (
            <>
              <MyButton
                style={{ backgroundColor: "#ffb33e", color: "white" }}
                fullWidth="true"
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                {t("USR.HOM.BTN.LOD")}
              </MyButton>
            </>
          )}
        </Form>
        <>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header className="all_background" closeButton>
              <Modal.Title>{t("USR.HOM.HD.REG")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{t("USR.VSH.HFTAB.MDL.CNF.ALRT1")}</Modal.Body>
            <Modal.Footer className="d-flex justify-content-evenly all_border">
              <Button
                className="all_btn"
                style={{
                  borderRadius: 50,
                }}
                onClick={handleClose}
              >
                {t("USR.VSH.HFTAB.MDL.CNF.BTN.CNC")}
              </Button>
              <Button
                className="all_btn"
                style={{
                  borderRadius: 50,
                }}
                onClick={onSubmit}
              >
                {t("USR.VSH.HFTAB.MDL.CNF.BTN.CNF")}
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      </Card>
    </React.Fragment>
  );
};

export default Register;
