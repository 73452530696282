import React from "react";
import { Tabs, Tab } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CustomerOneTimePricing from "./CustomerOneTimeService";
import CustomerPeriodicPricing from "./CustomerPeriodicService";
import CustomerSpecialPricing from "./CustomerSpecialPricing";
import { useLocation } from "react-router-dom";

const customerServiceTab = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const selectService = location && location.state;

  return (
    <Tabs
      defaultActiveKey="special"
      id="customerServiceTab"
      className="mb-3"
      justify
    >
      <Tab eventKey="special" title={t("USR.HOM.SPCL.OFF")}>
        <CustomerSpecialPricing selectService={selectService} />
      </Tab>
      <Tab eventKey="onetime" title={t("USR.PR.OTTAB")}>
        <CustomerOneTimePricing selectService={selectService} />
      </Tab>
    </Tabs>
  );
};

export default customerServiceTab;
