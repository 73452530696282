import React, { Component } from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

const Banner = () => {
  const lang = window.localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  console.log("lang", lang);
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 1,
    speed: 500,
  };
  // return (
  //   <Container fluid style={{width:'100vw', height:'30vh', backgroundColor:'#ffb33e'}}>
  //     <Row className="d-flex justify-content-center" style={{position:'relative'}}>
  //       <Col className="d-flex justify-content-center p-4" sm={4}>
  //       <img src='https://www.godpng.com/uploads/png/lord-bhagwan-murugan-transparent-png.png' height={200} width={300} alt='mainPic' />
  //       </Col>
  //       {/* <Col className="d-flex justify-content-center p-4" sm={4}>

  //       </Col> */}
  //     </Row>

  //   </Container>
  //   // <Slider {...settings}>
  //   //   <div class="card text-center">
  //   //     <a href="https://images.astroyogi.com/astroyogi2017/english/images/banner/Webhome-29Nov-1.jpg">
  //   //       <img
  //   //         class="card-img-top"
  //   //         src="https://images.astroyogi.com/astroyogi2017/english/images/banner/Webhome-29Nov-1.jpg"
  //   //         alt="Banner1"
  //   //         width='100vw'
  //   //         height='auto'
  //   //       />
  //   //     </a>
  //   //   </div>
  //   // </Slider>
  // );

  return (
    <Container-fluid>
      {lang == "en" ? (
        <Image
          src="vasthubanneren.png"
          class="img-fluid"
          object="fill"
          alt="mainPic"
          width="100%"
          height="100%"
        />
      ) : lang == "ta" ? (
        <Image
          src="bannertamil.png"
          class="img-fluid"
          object="fill"
          alt="mainPic"
          width="100%"
          height="100%"
        />
      ) : (
        <Image
          src="vasthubannerhi.png"
          class="img-fluid"
          object="fill"
          alt="mainPic"
          width="100%"
          height="100%"
        />
      )}
    </Container-fluid>
  );
};
export default Banner;
