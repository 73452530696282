import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Form, Modal, Spinner, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { vasthuUrl } from "../component/Api";

const AdminInitiateDetails = () => {
  // const { token } = UseAuth();
  const { t } = useTranslation();
  const token = window.sessionStorage.getItem("token");
  const [initiateData, setInitiateData] = useState([]);
  console.log("initiateData", initiateData);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [detail, setDetail] = useState([]);
  const [orderid, setOrderid] = useState("");
  const lang = localStorage.getItem("i18nextLng");


  const [status, setStatus] = useState("");
  const [comment, setComment] = useState("");
  const [reason, setReason] = useState("");
  const [getstatus, setGetStatus] = useState([]);
  const [getreason, setGetReason] = useState([]);
  console.log("getreason", getreason);
  console.log("status", status);
  console.log("comment", comment);

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true);
    setDetail(id);
  };

  const alert = (msg) => {
    setMessage(msg);
    setTimeout(() => {
      setMessage("");
    }, 4000);
  };
  useEffect(() => {
    setLoading(true);
    try {
      vasthuUrl
        .get(`/refund/getAllUserRefundIntiatedList`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("users", res.data);
          setInitiateData(res.data);
          setLoading(false);
        });
    } catch (e) {
      alert("SERVER ERROR");
      setLoading(false);
    }
    try {
      vasthuUrl
        .get(`/refund/getRefundStatus`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("users", res.data);
          setGetStatus(res.data);
        });
    } catch (e) {
      alert("SERVER ERROR");
    }
    try {
      vasthuUrl
        .get(`/refund/getuserreasonsforrefund`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("users", res.data);
          setGetReason(res.data);
        });
    } catch (e) {
      alert("SERVER ERROR");
    }
  }, [show]);

  const updateDetail = async (event) => {
    try {
      console.log("ordercall", orderid);
      const res = await vasthuUrl.post(
        `refund/updateStatusForRefund`,
        {
          status: status,
          admin_comments: comment,
          orderId: orderid,
          admin_reason: reason,
          lng:lang
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log("res", res.data);
      alert(res.data);
      handleClose(true);
    } catch (error) {
      alert("Failed to Update");
    }
  };

  return (
    <React.Fragment>
      <center className="mt-2 mb-2">
        <h4>
          <u>INITIATE DETAILS</u>
        </h4>
      </center>
      {message && (
        <Alert variant="info" className="text-center">
          {message}
        </Alert>
      )}
      {!loading ? (
        <>
          <Table
            striped
            bordered
            hover
            responsive
            className="deskTable all_border text-center"
          // style={{
          //   textAlign:'center'
          // }}
          >
            <thead>
              <tr>
                <th>User Name</th>
                <th>{t("TXT.OFF.NM.LO")}</th>
                <th>User Reason</th>
                <th>Bank</th>
                <th>OrderId</th>
                <th>Amount</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {initiateData.map((i, j) => (
                <tr key={j}>
                  <td>{i.username}</td>
                  <td>{i.offer_name}</td>
                  <td>{i.user_reason}</td>
                  <td>{i.bankname}</td>
                  <td>{i.orderid}</td>
                  <td>{i.totalAmount}</td>
                  {/* <td>{i.status}</td> */}
                  <td className="d-flex">
                    <Button
                      className="mx-1 all_btn"
                      onClick={() => handleShow(i)}
                    >
                      Approve/Reject
                    </Button>

                    {/* <Form>
                      <Form.Select
                        style={{ width: "120px" }}
                        defaultValue={i.status}
                        onChange={(e) => {
                          handleShow(i);
                          setStatus(e.target.value);
                          console.log("i.order"+i.orderid)
                          setOrderid(i.orderid);
                        }}
                      >
                        {getstatus.map((i) => (
                          <option>{i.status}</option>
                        ))}
                      </Form.Select>
                    </Form> */}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header className="all_background" closeButton>
              <Modal.Title>Changing Status</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="feildset">
                <h5>User Name:</h5> <p>{detail.username}</p>
              </div>
              <div className="feildset">
                <h5>{t("TXT.OFF.NM.LO")}:</h5> <p>{detail.offer_name}</p>
              </div>
              <div className="feildset">
                <h5>Order Id:</h5> <p>{detail.orderid}</p>
              </div>
              <div className="feildset">
                <h5>Bank Name:</h5> <p>{detail.bankname}</p>
              </div>
              <div className="feildset">
                <h5>User Reason:</h5> <p>{detail.user_reason}</p>
              </div>
              <div className="feildset">
                <h5>Amount:</h5> <p>{detail.totalAmount}</p>
              </div>
              <Form>
                <div className="feildset mt-2">
                  <Form.Label>
                    <h5>Approver or Rejected :</h5>{" "}
                  </Form.Label>
                  <Form.Select
                    style={{ width: "200px" }}
                    defaultValue={detail.status}
                    onChange={(e) => {
                      setStatus(e.target.value);
                      console.log("i.order" + detail.orderid);
                      setOrderid(detail.orderid);
                    }}
                  >
                    <option>Select Status</option>
                    {getstatus.map((i) => (
                      <>
                        <option>{i.status}</option>
                      </>
                    ))}
                  </Form.Select>
                </div>
                <div className="feildset mt-2">
                  <Form.Label>
                    <h5>Admin Comment:</h5>{" "}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="comment"
                    required
                    maxLength={75}
                    onChange={(e) => {
                      setComment(e.target.value);
                    }}
                    style={{ width: "200px" }}
                  />
                </div>
                <div className="feildset mt-2">
                  <Form.Label>
                    <h5>Admin Reason :</h5>{" "}
                  </Form.Label>
                  <Form.Select
                    style={{ width: "200px" }}
                    onChange={(e) => {
                      setReason(e.target.value);
                    }}
                  >
                    <option>Select Reason</option>
                    {getreason.map((i) => (
                      <option>
                        {lang === "en"
                          ? i.admin_Reasons
                          : lang === "ta"
                            ? i.admin_Reasons_ta
                            : i.admin_Reasons_hi}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button className="all_btn" onClick={handleClose}>
                {t("TXT.CLO.LO")}
              </Button>
              <Button
                className="all_btn"
                onClick={(e) => {
                  updateDetail();
                }}
              >
                Save
              </Button>
            </Modal.Footer>
          </Modal>

          {initiateData.map((i, j) => (
            <div className="justify-content-center mobileCard">
              <Card
                className="mobileCard"
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  backgroundColor: "antiquewhite",
                  border: "1px solid #fbb33e",
                }}
              >
                <Card.Body>
                  <Card.Title>User Name: {i.username}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                    {t("TXT.OFF.NM.LO")}: {i.offer_name}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2 text-muted">
                    User Reason: {i.user_reason}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2 text-muted">
                    Bank: {i.bankname}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2 text-muted">
                    OrderId: {i.orderid}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2 text-muted">
                    Amount:{i.totalAmount}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2 text-muted">

                    <Button
                      className="mx-1 all_btn"
                      onClick={() => handleShow(i)}
                    >
                      Approve/Reject
                    </Button>
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </div>
          ))}
        </>
      ) : (
        <center>
          <Spinner animation="border" variant="warning" />
        </center>
      )}
    </React.Fragment>
  );
};
export default AdminInitiateDetails;
