import React, { useEffect, useState } from "react";
import DatePicker from "react-date-picker";
import "react-datepicker/dist/react-datepicker.css";
import { vasthuUrl } from "../component/Api";
import { DataGrid } from "@mui/x-data-grid";
import { Grid } from "@mui/material";
import NewBootCard from "../component/NewBootCard";
import { Col, Row } from "react-bootstrap";

const PaymentDateHistory = () => {
  const token = window.sessionStorage.getItem("token");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [clientData, setClientData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const today = new Date();

  const formatDate = (date) => {
    const adjustedDate = new Date(date);
    adjustedDate.setHours(12, 0, 0, 0);
    return adjustedDate.toISOString().split("T")[0];
  };

  useEffect(() => {
    vasthuUrl
      .get("/vasthu/getAllPayment", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => setClientData(res.data))
      .catch((err) => {
        setClientData([]);
        alert("No Name");
      });
  }, []);

  const fetchPayments = async (start, end) => {
    setLoading(true);
    setError(null);

    if (start > end) {
      setError("Start date cannot be later than end date.");
      setLoading(false);
      return;
    }

    try {
      const res = await vasthuUrl.get("vasthu/getAllPaymentsByDate", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          startDate: formatDate(start),
          endDate: formatDate(end),
        },
      });
      setClientData(res.data);
    } catch (e) {
      console.error("Error fetching payments:", e.response);
      setError("Unable to fetch payment data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (startDate && endDate && startDate <= endDate) {
      fetchPayments(startDate, endDate);
    }
  }, [startDate, endDate, token]);

  const columns = [
    { field: "offerName", headerName: "Offer Name", width: 150 },
    { field: "txnAmount", headerName: "Transaction Amount", width: 150 },
    { field: "userName", headerName: "User Name", width: 140 },
    { field: "bankName", headerName: "Bank Name", width: 100 },
    { field: "orderId", headerName: "Order ID", width: 150 },
    { field: "status", headerName: "Status", width: 100 },
  ];

  return (
    <div>
      <div className="admindate_history">
        <Row className="mt-2 consul_chat_his" style={{ display: "flex" }}>
          <Col className="mt-2">
            <label className="d-flex flex-column">
              Start Date:{" "}
              <DatePicker
                className="form_feild"
                value={startDate}
                format="dd-MM-yy"
                onChange={(date) => setStartDate(date)}
                placeholderText="Select start date"
                style={{ height: "35px", borderRadius: "7px" }}
                maxDate={endDate ? endDate : today}
              />
            </label>
          </Col>

          <Col className="mt-2">
            <label className="d-flex flex-column">
              End Date:{" "}
              <DatePicker
                className="form_feild"
                value={endDate}
                format="dd-MM-yy"
                onChange={(date) => setEndDate(date)}
                placeholderText="Select end date"
                minDate={startDate}
                maxDate={today}
              />
            </label>
          </Col>
        </Row>
      </div>
      <center className="datagrid mt-3">
        <h2>Payment History</h2>
        <Grid container className="deskTable datagrid">
          {loading ? (
            <p>Loading...</p>
          ) : error ? (
            <p>{error}</p>
          ) : clientData.length > 0 ? (
            <DataGrid
              className="all_border text-center me-3 data_grid"
              rows={clientData}
              columns={columns}
              initialState={{
                pagination: { paginationModel: { page: 0, pageSize: 10 } },
              }}
              pageSizeOptions={[5, 10]}
              getRowId={(row) => row.orderId}
            />
          ) : (
            <p>No payment data available for the selected date range.</p>
          )}
        </Grid>
      </center>
      <div className="mobileCard justify-content-center mx-1">
        {clientData.map((item, index) => (
          <NewBootCard key={index}>
            <h6>Offer Name: {item.offerName}</h6>
            <h6>Transaction Amount: {item.txnAmount}</h6>
            <h6>User Name: {item.userName}</h6>
            <h6>Bank Name: {item.bankName}</h6>
            <h6>Order ID: {item.orderId}</h6>
            <h6>Status: {item.status}</h6>
          </NewBootCard>
        ))}
      </div>
    </div>
  );
};

export default PaymentDateHistory;
