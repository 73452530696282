import React, { useState, useEffect } from "react";
import { Button, Card, Form, Image, Modal, Spinner } from "react-bootstrap";
import { vasthuUrl } from "../../component/Api";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stacks from "@mui/material/Stack";
import { Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { MdDelete } from "react-icons/md";
import { MdEdit } from "react-icons/md";
import { useTranslation } from "react-i18next";
import validator from "validator";

function ProductList() {
  const token = window.sessionStorage.getItem("token");
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  //All Product List & Data
  const [productData, setProductData] = useState([]);
  const [likedList, setLikedList] = useState([]);
  const [bookedList, setBookedList] = useState([]);
  const [status, setStatus] = useState(false);
  //addProduct Payload
  const [productName, setProductName] = useState("");
  const [productType, setProductType] = useState("");
  const [productSubType, setProductSubType] = useState("");
  const [productShortDesc, setProductShortDesc] = useState("");
  const [productFullDesc, setProductFullDesc] = useState("");
  const [basePrice, setBasePrice] = useState("");
  const [uploadedImage, setUploadedImage] = useState();
  console.log("uploadedImage", uploadedImage);
  const filename = uploadedImage ? uploadedImage.name : null;
  console.log("filename", filename);
  const [saveData, setSaveData] = useState([]);
  console.log("saveData", saveData);
  const [deleteId, setDeleteId] = useState();

  //toast aleart
  const [openSnack, setOpenSnack] = React.useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("error");

  //mulitiple img upload
  const [img1, setImag1] = useState();
  const [img2, setImag2] = useState();
  const [img3, setImag3] = useState();
  const [img4, setImag4] = useState();
  const [img5, setImag5] = useState();
  const [img6, setImag6] = useState();
  console.log("img1", img1);
  console.log("img2", img2);
  console.log("img3", img3);
  console.log("img4", img4);
  console.log("img6", img6);

  //
  const [productTypeList, setProductTypeList] = useState([]);

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const alert = (msg) => {
    setMessage(msg);
    setOpenSnack(true);
    setTimeout(() => {
      setMessage("");
    }, 4000);
  };

  //ADD PRODUCT MODAL
  const [addProductModal, setAddProductModal] = useState(false);

  const addProductModalOpen = () => {
    setAddProductModal(true);
  };

  const addProductModalClose = () => {
    setAddProductModal(false);
    setProductName("");
    setProductType("");
    setProductSubType("");
    setProductShortDesc("");
    setProductFullDesc("");
    setBasePrice("");
    setSaveData("");
    setUploadedImage();
    setImag1();
    setImag2();
    setImag3();
    setImag4();
    setImag5();
    setImag6();
  };

  const handleShow = (i) => {
    console.log("i", i);
    // console.log("i.row", i.row);
    addProductModalOpen(true);
    setSaveData(i);
  };

  // DELETE PRODUCT MODAL
  const [deleteModal, setDeleteModel] = useState(false);

  const deleteModalClose = () => {
    setDeleteModel(false);
    setDeleteId("");
  };

  const handleDeleteShow = (i) => {
    setDeleteModel(true);
    setDeleteId(i.id);
  };

  // LIKED PRODUCT MODEL
  const [likedModal, setLikedModel] = useState(false);

  const likedModalOpen = () => {
    setLikedModel(true);
  };
  const likedModalClose = () => {
    setLikedModel(false);
  };

  // BOOKED PRODUCT MODEL
  const [bookedModal, setBookedModel] = useState(false);

  const bookedModalOpen = () => {
    setBookedModel(true);
  };
  const bookedModalClose = () => {
    setBookedModel(false);
  };

  useEffect(() => {
    setLoading(true);
    try {
      vasthuUrl
        .get(`/vasthu/user/products`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setProductData(res.data);
          console.log("service", res.data);
          setLoading(false);
          setStatus(false);
        });
    } catch (e) {
      console.log("service", e);
      setLoading(false);
    }
    try {
      vasthuUrl
        .get(`/vasthu/getAllUserLikedProducts`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setLikedList(res.data);
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
    }
    try {
      vasthuUrl
        .get(`/vasthu/getAllUserBookedProductsbyUserIdForAdmin`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setBookedList(res.data);
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
    }
  }, [addProductModal, deleteModal, status]);

  console.log("productData", productData);
  console.log("columns", columns);

  useEffect(() => {
    try {
      vasthuUrl
        .get(`/vasthu/getproducttype`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setProductTypeList(res.data);
          console.log("ProductTypeList", res.data);
        });
    } catch (e) {
      console.log("ProductTypeList", e);
    }
  }, []);

  // const handleCreateProduct = async () => {
  //   try {
  //     const files = [uploadedImage, img1, img2, img3, img4, img5].filter(
  //       (file) => file != null
  //     );
  //     const allowedImageTypes = ["image/jpeg", "image/png", "image/gif"];
  //     const maxFileSize = 10 * 1024 * 1024;
  //     for (const file of files) {
  //       if (!allowedImageTypes.includes(file.type)) {
  //         alert("Please upload a valid image file (JPEG, PNG, or GIF)");
  //         setMessageType("error");
  //         return;
  //       }
  //       if (file.size > maxFileSize) {
  //         alert("Please upload an image smaller than 10MB");
  //         setMessageType("error");
  //         return;
  //       }
  //     }
  //     console.log("files", files);
  //     const res = await vasthuUrl.post(
  //       `vasthu/user/product/add`,
  //       {
  //         productName: productName,
  //         productType: productType,
  //         productSubType: productSubType,
  //         productShortDescription: productShortDesc,
  //         productFullDescription: productFullDesc,
  //         basePrice: basePrice,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );
  //     console.log("after save", res.data);
  //     alert("Add Product Successfully!!!");
  //     setMessageType("success");
  //     addProductModalClose();
  //     handleImageUpload(files, res.data.id); // Pass the valid files to the upload function
  //   } catch (e) {
  //     alert("Please Check Internet Connection");
  //     setMessageType("error");
  //   }
  // };

  const handleCreateProduct = async () => {
    try {
      if (typeof img1 !== "undefined") {
        if (!validator.isEmpty(productName)) {
          if (!validator.isEmpty(productType)) {
            if (!validator.isEmpty(basePrice)) {
              const res = await vasthuUrl.post(
                `vasthu/addProducts`,
                {
                  productName: productName,
                  productType: productType,
                  productSubType: productSubType,
                  productShortDescription: productShortDesc,
                  productFullDescription: productFullDesc,
                  basePrice: basePrice,
                  productimgUrl1: img1,
                  productimgUrl2: img2,
                  productimgUrl3: img3,
                  productimgUrl4: img4,
                  productimgUrl5: img5,
                  productimgUrl6: img6,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              );
              console.log("after save", res.data);
              alert("Add Product Successfully!!!");
              setMessageType("success");
              addProductModalClose();
            } else {
              alert("Please Fill Base Price");
              setMessageType("error");
            }
          } else {
            alert("Please Select Product Type");
            setMessageType("error");
          }
        } else {
          alert("Please Fill Product Name");
          setMessageType("error");
        }
      } else {
        alert("Please Upload Main Image");
        setMessageType("error");
      }
    } catch (e) {
      alert("Please Check Internet Connection");
      setMessageType("error");
    }
  };

  // const handleUpdateProduct = async () => {
  //   try {
  //     const files = [uploadedImage, img1, img2, img3, img4, img5].filter(
  //       (file) => file != null
  //     );
  //     const allowedImageTypes = ["image/jpeg", "image/png", "image/gif"];
  //     const maxFileSize = 10 * 1024 * 1024;
  //     for (const file of files) {
  //       if (!allowedImageTypes.includes(file.type)) {
  //         alert("Please upload a valid image file (JPEG, PNG, or GIF)");
  //         setMessageType("error");
  //         return;
  //       }
  //       if (file.size > maxFileSize) {
  //         alert("Please upload an image smaller than 10MB");
  //         setMessageType("error");
  //         return;
  //       }
  //     }
  //     console.log("files", files);
  //     const res = await vasthuUrl
  //       .put(
  //         `vasthu/user/product/update`,
  //         {
  //           id: saveData.id,
  //           productName: saveData.productName,
  //           productType: saveData.productType,
  //           productSubType: saveData.productSubType,
  //           productShortDescription: saveData.productShortDescription,
  //           productFullDescription: saveData.productFullDescription,
  //           basePrice: saveData.basePrice,
  //           images: saveData.images,
  //         },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //           },
  //         }
  //       )
  //       .then((res) => {
  //         console.log("after save", res.data);
  //         alert("Update Product Successfully!!!");
  //         setMessageType("success");
  //         // setAddProductModal(false);
  //         addProductModalClose();
  //         // setAddProductModal(false);
  //         // handleImageUpload();
  //       });
  //     handleImageUpload(files, saveData.id);
  //   } catch (error) {
  //     if (error.response && error.response.status === 403) {
  //       alert("Forbidden: You are not authorized to access this resource.");
  //     } else {
  //       alert(
  //         "An error occurred while updating the product. Please try again later."
  //       );
  //     }
  //     setMessageType("error");
  //   }
  // };

  const handleUpdateProduct = async () => {
    try {
      if (typeof img1 !== "undefined" || saveData.productimgUrl1 !== null) {
        console.log("saveData.productimgUrl1", saveData.productimgUrl1);
        if (!validator.isEmpty(saveData.productName)) {
          console.log("saveData.productimgUrl1", saveData.productimgUrl1);
          if (!validator.isEmpty(saveData.productType)) {
            const res = await vasthuUrl
              .put(
                `vasthu/user/product/update`,
                {
                  id: saveData.id,
                  productName: saveData.productName,
                  productType: saveData.productType,
                  productSubType: saveData.productSubType,
                  productShortDescription: saveData.productShortDescription,
                  productFullDescription: saveData.productFullDescription,
                  basePrice: saveData.basePrice,
                  images: saveData.images,
                  productimgUrl1: img1 ? img1 : saveData.productimgUrl1,
                  productimgUrl2: img2 ? img2 : saveData.productimgUrl2,
                  productimgUrl3: img3 ? img3 : saveData.productimgUrl3,
                  productimgUrl4: img4 ? img4 : saveData.productimgUrl4,
                  productimgUrl5: img5 ? img5 : saveData.productimgUrl5,
                  productimgUrl6: img6 ? img6 : saveData.productimgUrl6,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((res) => {
                console.log("after save", res.data);
                alert("Update Product Successfully!!!");
                setMessageType("success");
                addProductModalClose();
              });
          } else {
            alert("Please Select Product Type");
            setMessageType("error");
          }
        } else {
          alert("Please Fill Product Name");
          setMessageType("error");
        }
      } else {
        alert("Please Upload Main Image");
        setMessageType("error");
      }

      console.log("saveData.productimgUrl1", saveData.productimgUrl1);
    } catch (error) {
      if (error.response && error.response.status === 403) {
        alert("Forbidden: You are not authorized to access this resource.");
      } else {
        alert(
          "An error occurred while updating the product. Please try again later."
        );
      }
      setMessageType("error");
      console.log("saveData.productimgUrl1", saveData.productimgUrl1);
    }
  };

  const handleDeleteProduct = async () => {
    try {
      const res = await vasthuUrl
        .delete(`vasthu/user/product/delete/${deleteId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("after save", res.data);
          alert("Delete Product Successfully!!!");
          setMessageType("success");
          deleteModalClose();
        });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        alert("Forbidden: You are not authorized to access this resource.");
      } else {
        alert(
          "An error occurred while updating the product. Please try again later."
        );
      }
      setMessageType("error");
    }
  };

  const handleActivateProduct = async (id) => {
    try {
      const res = await vasthuUrl
        .put(`vasthu/ActivateProductStatusInAdmin/${id}`, null, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("after save", res.data);
          alert("Activated Product Successfully!!!");
          setMessageType("success");
          setStatus(true);
        });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        alert("Forbidden: You are not authorized to access this resource.");
      } else {
        alert(
          "An error occurred while updating the product. Please try again later."
        );
      }
      setMessageType("error");
    }
  };

  const handleInActivateProduct = async (id) => {
    try {
      const res = await vasthuUrl
        .put(`vasthu/InactivateProductStatusInAdmin/${id}`, null, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("after save", res.data);
          alert("InActivated Product Successfully!!!");
          setMessageType("success");
          setStatus(true);
        });
    } catch (error) {
      if (error.response && error.response.status === 403) {
        alert("Forbidden: You are not authorized to access this resource.");
      } else {
        alert(
          "An error occurred while updating the product. Please try again later."
        );
      }
      setMessageType("error");
    }
  };

  const columns = [
    { field: "productName", headerName: "NAME", width: 200 },
    { field: "productType", headerName: "TYPE", width: 200 },
    { field: "productSubType", headerName: "SUBTYPE", width: 200 },
    { field: "productShortDescription", headerName: "SHORT DESC", width: 200 },
    { field: "productFullDescription", headerName: "FULL DESC", width: 200 },
    { field: "basePrice", headerName: "BASE PRICE", width: 100 },
    { field: "status", headerName: "STATUS", width: 100 },
    {
      field: "actions",
      headerName: "ACTION",
      type: "actions",

      renderCell: (i) => (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "0.5rem",
            }}
          >
            <Button
              className="all_btn"
              onClick={() => {
                handleShow(i.row);
              }}
            >
              Edit <MdEdit size={20} />
            </Button>
            {/* <Button
              className="all_btn"
              onClick={() => {
                handleDeleteShow(i.row);
              }}
            >
              Delete <MdDelete size={20} />
            </Button> */}
            {i.row.status !== "ACTIVE" ? (
              <Button
                className="all_btn"
                onClick={() => {
                  handleActivateProduct(i.row.id);
                }}
              >
                Activate
              </Button>
            ) : (
              <Button
                className="all_btn"
                onClick={() => {
                  handleInActivateProduct(i.row.id);
                }}
              >
                Deactivate
              </Button>
            )}
          </div>
        </>
      ),
      width: 400,
    },
  ];
  
  const likedcolumns = [
    { field: "user_name", headerName: " USER NAME", width: 200 },
    { field: "phone_no", headerName: "PHONE NUMBER", width: 200 },
    { field: "product_name", headerName: "PRODUCT NAME", width: 200 },
    { field: "base_price", headerName: "PRICE", width: 100 },
    { field: "product_id", headerName: "PRODUCT ID", width: 200 },
    // { field: "productSubType", headerName: "PRODUCT SUBTYPE", width: 200 },
  ];
  const bookedcolumns = [
    { field: "username", headerName: " USER NAME", width: 160 },
    { field: "phone_no", headerName: "PHONE NUMBER", width: 200 },
    { field: "productName", headerName: "PRODUCT NAME", width: 200 },
    { field: "basePrice", headerName: "PRICE", width: 120 },
    { field: "productType", headerName: "TYPE", width: 200 },
    { field: "productSubType", headerName: "SUBTYPE", width: 200 },
  ];

  //upload product image
  // const handleImageUpload = async (e) => {
  //   if (uploadedImage != null) {
  //     const res = await vasthuUrl.post(
  //       `image/uploadProductImagesss`,
  //       {
  //         files: uploadedImage,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "multipart/form-data",
  //           // contentType: "application/json",
  //         },
  //       }
  //     );
  //     console.log("response for MainImage Upload Api", res.data);
  //     alert(res.data);
  //     setMessageType("success");
  //   }
  //   if (img1 != null) {
  //     const res = await vasthuUrl.post(
  //       `image/uploadProductImagesss`,
  //       {
  //         files: img1,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "multipart/form-data",
  //           // contentType: "application/json",
  //         },
  //       }
  //     );
  //     console.log("response for Image1 Upload Api", res.data);
  //     alert(res.data);
  //     setMessageType("success");
  //   }
  //   if (img2 != null) {
  //     const res = await vasthuUrl.post(
  //       `image/uploadProductImagesss`,
  //       {
  //         files: img2,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "multipart/form-data",
  //           // contentType: "application/json",
  //         },
  //       }
  //     );
  //     console.log("response for Image2 Upload Api", res.data);
  //     alert(res.data);
  //     setMessageType("success");
  //   }
  //   if (img3 != null) {
  //     const res = await vasthuUrl.post(
  //       `image/uploadProductImagesss`,
  //       {
  //         files: img3,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "multipart/form-data",
  //           // contentType: "application/json",
  //         },
  //       }
  //     );
  //     console.log("response for Image3 Upload Api", res.data);
  //     alert(res.data);
  //     setMessageType("success");
  //   }
  //   if (img4 != null) {
  //     const res = await vasthuUrl.post(
  //       `image/uploadProductImagesss`,
  //       {
  //         files: img4,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "multipart/form-data",
  //           // contentType: "application/json",
  //         },
  //       }
  //     );
  //     console.log("response for Image4 Upload Api", res.data);
  //     alert(res.data);
  //     setMessageType("success");
  //   }
  //   if (img5 != null) {
  //     const res = await vasthuUrl.post(
  //       `image/uploadProductImagesss`,
  //       {
  //         files: img5,
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //           "Content-Type": "multipart/form-data",
  //           // contentType: "application/json",
  //         },
  //       }
  //     );
  //     console.log("response for Image5 Upload Api", res.data);
  //     alert(res.data);
  //     setMessageType("success");
  //   }
  // };

  // const handleImageUpload = async (files, productId) => {
  //   console.log("files", files);
  //   console.log("productId", productId);
  //   try {
  //     for (const file of files) {
  //       const res = await vasthuUrl.post(
  //         `image/uploadProductImagesss`,
  //         {
  //           files: file,
  //           productId: productId,
  //         },
  //         {
  //           headers: {
  //             Authorization: `Bearer ${token}`,
  //             "Content-Type": "multipart/form-data",
  //           },
  //         }
  //       );
  //       console.log(`response for ${file.name} Upload Api`, res.data);
  //       alert(res.data);
  //       setMessageType("success");
  //     }
  //   } catch (e) {
  //     console.error("Error uploading image:", e);
  //     alert("Failed to upload image");
  //     setMessageType("error");
  //   }
  // };

  //upload image1
  const handleImageUpload1 = async (e) => {
    if (e.target.files[0]) {
      const files = e.target.files[0];
      const allowedImageTypes = ["image/jpeg", "image/png", "image/jpg"]; // Add more allowed types as needed

      if (!allowedImageTypes.includes(files.type)) {
        alert("Please upload a valid image file JPEG, PNG, or JPG");
        setMessageType("error");
      } else if (files.size > 5 * 1024 * 1024) {
        alert("Please upload an image file less than 5MB in size");
        setMessageType("error");
      } else {
        const file = files;
        const res = await vasthuUrl.post(
          `/image/uploadproductImage`,
          {
            files: file,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
              // contentType: "application/json",
            },
          }
        );
        console.log("response for Image Upload Api", res.data);
        setImag1(res.data.body.fileDownloadUri);
      }
    }
  };
  //upload image2
  const handleImageUpload2 = async (e) => {
    if (e.target.files[0]) {
      const files = e.target.files[0];
      const allowedImageTypes = ["image/jpeg", "image/png", "image/jpg"]; // Add more allowed types as needed

      if (!allowedImageTypes.includes(files.type)) {
        alert("Please upload a valid image file JPEG, PNG, or JPG");
        setMessageType("error");
      } else if (files.size > 5 * 1024 * 1024) {
        alert("Please upload an image file less than 5MB in size");
        setMessageType("error");
      } else {
        const file = files;
        const res = await vasthuUrl.post(
          `/image/uploadproductImage`,
          {
            files: file,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
              // contentType: "application/json",
            },
          }
        );
        console.log("response for Image Upload Api", res.data);
        setImag2(res.data.body.fileDownloadUri);
      }
    }
  };
  //upload image3
  const handleImageUpload3 = async (e) => {
    if (e.target.files[0]) {
      const files = e.target.files[0];
      const allowedImageTypes = ["image/jpeg", "image/png", "image/jpg"]; // Add more allowed types as needed

      if (!allowedImageTypes.includes(files.type)) {
        alert("Please upload a valid image file JPEG, PNG, or JPG");
        setMessageType("error");
      } else if (files.size > 5 * 1024 * 1024) {
        alert("Please upload an image file less than 5MB in size");
        setMessageType("error");
      } else {
        const file = files;
        const res = await vasthuUrl.post(
          `/image/uploadproductImage`,
          {
            files: file,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
              // contentType: "application/json",
            },
          }
        );
        console.log("response for Image Upload Api", res.data);
        setImag3(res.data.body.fileDownloadUri);
      }
    }
  };
  //upload image4
  const handleImageUpload4 = async (e) => {
    if (e.target.files[0]) {
      const files = e.target.files[0];
      const allowedImageTypes = ["image/jpeg", "image/png", "image/jpg"]; // Add more allowed types as needed

      if (!allowedImageTypes.includes(files.type)) {
        alert("Please upload a valid image file JPEG, PNG, or JPG");
        setMessageType("error");
      } else if (files.size > 5 * 1024 * 1024) {
        alert("Please upload an image file less than 5MB in size");
        setMessageType("error");
      } else {
        const file = files;
        const res = await vasthuUrl.post(
          `/image/uploadproductImage`,
          {
            files: file,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
              // contentType: "application/json",
            },
          }
        );
        console.log("response for Image Upload Api", res.data);
        setImag4(res.data.body.fileDownloadUri);
      }
    }
  };
  //upload image5
  const handleImageUpload5 = async (e) => {
    if (e.target.files[0]) {
      const files = e.target.files[0];
      const allowedImageTypes = ["image/jpeg", "image/png", "image/jpg"]; // Add more allowed types as needed

      if (!allowedImageTypes.includes(files.type)) {
        alert("Please upload a valid image file JPEG, PNG, or JPG");
        setMessageType("error");
      } else if (files.size > 5 * 1024 * 1024) {
        alert("Please upload an image file less than 5MB in size");
        setMessageType("error");
      } else {
        const file = files;
        const res = await vasthuUrl.post(
          `/image/uploadproductImage`,
          {
            files: file,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
              // contentType: "application/json",
            },
          }
        );
        console.log("response for Image Upload Api", res.data);
        setImag5(res.data.body.fileDownloadUri);
      }
    }
  };
  //upload image6
  const handleImageUpload6 = async (e) => {
    if (e.target.files[0]) {
      const files = e.target.files[0];
      const allowedImageTypes = ["image/jpeg", "image/png", "image/jpg"]; // Add more allowed types as needed

      if (!allowedImageTypes.includes(files.type)) {
        alert("Please upload a valid image file JPEG, PNG, or JPG");
        setMessageType("error");
      } else if (files.size > 5 * 1024 * 1024) {
        alert("Please upload an image file less than 5MB in size");
        setMessageType("error");
      } else {
        const file = files;
        const res = await vasthuUrl.post(
          `/image/uploadproductImage`,
          {
            files: file,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
              // contentType: "application/json",
            },
          }
        );
        console.log("response for Image Upload Api", res.data);
        setImag6(res.data.body.fileDownloadUri);
      }
    }
  };

  return (
    <div>
      <div className="product_btn">
        <Button
          className="my-2 all_btn"
          onClick={likedModalOpen}
          style={{ float: "right" }}
        >
          LIKED PRODUCT LIST
        </Button>
        <Button
          className="my-2 all_btn"
          onClick={bookedModalOpen}
          style={{ float: "right" }}
        >
          BOOKED PRODUCT LIST
        </Button>
        <Button
          className="my-2 all_btn"
          onClick={addProductModalOpen}
          // style={{ float: "right" }}
        >
          ADD PRODUCT
        </Button>
      </div>

      <center>
        <h2>All Product List</h2>
      </center>

      {!loading ? (
        productData.length > 0 ? (
          <>
            <center className="datagrid mt-2">
              <Grid container className="deskTable datagrid">
                <DataGrid
                  className="all_border - text-center me-3 data_grid "
                  rows={productData}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 10},
                    },
                  }}
                  pageSizeOptions={[5, 10]}
                  // checkboxSelection
                  getRowId={(row) => row.id}
                />
              </Grid>
            </center>

            <div className="justify-content-center mobileCard">
              {productData.map((i, j) => (
                <Card
                  key={j}
                  className="mobileCard all_border"
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    backgroundColor: "antiquewhite",
                  }}
                >
                  <Card.Body>
                    <Card.Subtitle className="mb-2">
                      <b>Product Name:</b> {i.productName}
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2">
                      <b> Product Type:</b> {i.productType}
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2">
                      <b> Product SubType:</b> {i.productSubType}
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2">
                      <b>ShortDescription:</b> {i.productShortDescription}
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2">
                      <b>FullDescription:</b> {i.productFullDescription}
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2">
                      <b>Base Price:</b> {i.basePrice}
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2">
                      <b>Status:</b> {i.status}
                    </Card.Subtitle>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "0.5rem",
                      }}
                    >
                      <Button
                        className="all_btn"
                        onClick={() => {
                          handleShow(i);
                        }}
                      >
                        Edit <MdEdit size={20} />
                      </Button>
                      {/* <Button
                        className="all_btn"
                        onClick={() => {
                          handleDeleteShow(i);
                        }}
                      >
                        Delete <MdDelete size={20} />
                      </Button> */}
                      {i.status !== "ACTIVE" ? (
                        <Button
                          className="all_btn"
                          onClick={() => {
                            handleActivateProduct(i.id);
                          }}
                        >
                          Activate
                        </Button>
                      ) : (
                        <Button
                          className="all_btn"
                          onClick={() => {
                            handleInActivateProduct(i.id);
                          }}
                        >
                          Deactivate
                        </Button>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              ))}
            </div>
          </>
        ) : (
          <center>
            <h5>{t("TXT.NO.REC.FUD.LO")}</h5>
          </center>
        )
      ) : (
        <center>
          <Spinner animation="border" variant="warning" />
        </center>
      )}

      <Modal
        show={addProductModal}
        onHide={addProductModalClose}
        size="lg"
        centered
        fullscreen={true}
      >
        <Card className="p-2">
          <Card.Header className="all_background">
            <center>ADD PRODUCT</center>
          </Card.Header>
          <Card.Body>
            <Form>
              <Form.Group className="mb-3" controlId="productName">
                <Form.Label style={{ fontSize: 20, fontWeight: "bold" }}>
                  Product Name
                </Form.Label>
                <Form.Control
                  type="text"
                  value={saveData ? saveData.productName : productName}
                  className="form_feild"
                  placeholder="Enter Product Name"
                  // onChange={(e) => setProductName(e.target.value)}
                  onChange={(e) =>
                    setSaveData(
                      { ...saveData, productName: e.target.value },
                      setProductName(e.target.value)
                    )
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="productType">
                <Form.Label style={{ fontSize: 20, fontWeight: "bold" }}>
                  Product Type
                </Form.Label>
                <Form.Select
                  aria-label="productType"
                  size="lg"
                  value={saveData ? saveData.productType : productType}
                  className="form_feild"
                  onChange={(e) => {
                    setSaveData(
                      { ...saveData, productType: e.target.value },
                      setProductType(e.target.value)
                    );
                  }}
                >
                  {" "}
                  {/* <option key="" value="">
                    Select Product Type
                  </option>
                  <option key="0" value="pooja">
                    Pooja
                  </option>
                  <option key="1" value="stone">
                    Stone
                  </option>
                  <option key="2" value="vasthu">
                    Vasthu
                  </option> */}
                  <option key="" value="">
                    Select Product Type
                  </option>
                  {productTypeList.map((type) => (
                    <option key={type.id} value={type.name}>
                      {type.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3" controlId="productSubType">
                <Form.Label style={{ fontSize: 20, fontWeight: "bold" }}>
                  Product SubType
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength={200}
                  // value={productSubType}
                  value={saveData ? saveData.productSubType : productSubType}
                  className="form_feild"
                  placeholder="Enter Product SubType"
                  onChange={(e) =>
                    setSaveData(
                      { ...saveData, productSubType: e.target.value },
                      setProductSubType(e.target.value)
                    )
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="productShortDescription">
                <Form.Label style={{ fontSize: 20, fontWeight: "bold" }}>
                  Product Short Description
                </Form.Label>
                <Form.Control
                  type="text"
                  maxLength={300}
                  value={
                    saveData
                      ? saveData.productShortDescription
                      : productShortDesc
                  }
                  className="form_feild"
                  placeholder="Enter  Product Short Description"
                  onChange={(e) =>
                    setSaveData(
                      { ...saveData, productShortDescription: e.target.value },
                      setProductShortDesc(e.target.value)
                    )
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="productFullDescription">
                <Form.Label style={{ fontSize: 20, fontWeight: "bold" }}>
                  Product Full Description
                </Form.Label>
                <Form.Control
                  as="textarea"
                  type="text"
                  maxLength={500}
                  value={
                    saveData ? saveData.productFullDescription : productFullDesc
                  }
                  className="form_feild"
                  placeholder="Enter  Product Full Description"
                  onChange={(e) =>
                    setSaveData(
                      { ...saveData, productFullDescription: e.target.value },
                      setProductFullDesc(e.target.value)
                    )
                  }
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="basePrice">
                <Form.Label style={{ fontSize: 20, fontWeight: "bold" }}>
                  Base Price
                </Form.Label>
                <Form.Control
                  type="number"
                  // value={basePrice}
                  value={saveData ? saveData.basePrice : basePrice}
                  className="form_feild"
                  placeholder="Enter   Base Price"
                  onChange={(e) =>
                    setSaveData(
                      { ...saveData, basePrice: e.target.value },
                      setBasePrice(e.target.value)
                    )
                  }
                />
              </Form.Group>
              <Form.Group controlId="formFile" className="mb-1">
                <Form.Label>Main Image Upload</Form.Label>
                <Form.Control
                  type="file"
                  className="form_feild"
                  onChange={(e) => handleImageUpload1(e)}
                />
              </Form.Group>

              {img1 ? (
                <Image
                  src={img1}
                  fluid
                  thumbnail
                  alt="Upload Image"
                  style={{
                    width: "150px",
                    height: "108px",
                    objectFit: "cover",
                  }}
                />
              ) : saveData && saveData.productimgUrl1 ? (
                <Image
                  src={saveData.productimgUrl1}
                  fluid
                  thumbnail
                  alt="Upload Image"
                  style={{
                    width: "150px",
                    height: "108px",
                    objectFit: "cover",
                  }}
                />
              ) : null}

              <Form.Group controlId="formFile" className="mb-1">
                <Form.Label>Fisrt Image</Form.Label>
                <Form.Control
                  type="file"
                  className="form_feild"
                  onChange={(e) => handleImageUpload2(e)}
                />
              </Form.Group>

              {img2 ? (
                <Image
                  src={img2}
                  fluid
                  thumbnail
                  alt="Upload Image"
                  style={{
                    width: "150px",
                    height: "108px",
                    objectFit: "cover",
                  }}
                />
              ) : saveData && saveData.productimgUrl2 ? (
                <Image
                  src={saveData.productimgUrl2}
                  fluid
                  thumbnail
                  alt="Upload Image"
                  style={{
                    width: "150px",
                    height: "108px",
                    objectFit: "cover",
                  }}
                />
              ) : null}

              <Form.Group controlId="formFile" className="mb-1">
                <Form.Label>Second Image</Form.Label>
                <Form.Control
                  type="file"
                  className="form_feild"
                  onChange={(e) => handleImageUpload3(e)}
                />
              </Form.Group>

              {img3 ? (
                <Image
                  src={img3}
                  fluid
                  thumbnail
                  alt="Upload Image"
                  style={{
                    width: "150px",
                    height: "108px",
                    objectFit: "cover",
                  }}
                />
              ) : saveData && saveData.productimgUrl3 ? (
                <Image
                  src={saveData.productimgUrl3}
                  fluid
                  thumbnail
                  alt="Upload Image"
                  style={{
                    width: "150px",
                    height: "108px",
                    objectFit: "cover",
                  }}
                />
              ) : null}

              <Form.Group controlId="formFile" className="mb-1">
                <Form.Label>Third Image</Form.Label>
                <Form.Control
                  type="file"
                  className="form_feild"
                  onChange={(e) => handleImageUpload4(e)}
                />
              </Form.Group>

              {img4 ? (
                <Image
                  src={img4}
                  fluid
                  thumbnail
                  alt="Upload Image"
                  style={{
                    width: "150px",
                    height: "108px",
                    objectFit: "cover",
                  }}
                />
              ) : saveData && saveData.productimgUrl4 ? (
                <Image
                  src={saveData.productimgUrl4}
                  fluid
                  thumbnail
                  alt="Upload Image"
                  style={{
                    width: "150px",
                    height: "108px",
                    objectFit: "cover",
                  }}
                />
              ) : null}

              <Form.Group controlId="formFile" className="mb-1">
                <Form.Label>Fourth Image</Form.Label>
                <Form.Control
                  type="file"
                  className="form_feild"
                  onChange={(e) => handleImageUpload5(e)}
                />
              </Form.Group>

              {img5 ? (
                <Image
                  src={img5}
                  fluid
                  thumbnail
                  alt="Upload Image"
                  style={{
                    width: "150px",
                    height: "108px",
                    objectFit: "cover",
                  }}
                />
              ) : saveData && saveData.productimgUrl5 ? (
                <Image
                  src={saveData.productimgUrl5}
                  fluid
                  thumbnail
                  alt="Upload Image"
                  style={{
                    width: "150px",
                    height: "108px",
                    objectFit: "cover",
                  }}
                />
              ) : null}

              <Form.Group controlId="formFile" className="mb-1">
                <Form.Label>Fiveth Image</Form.Label>
                <Form.Control
                  type="file"
                  className="form_feild"
                  onChange={(e) => handleImageUpload6(e)}
                />
              </Form.Group>

              {img6 ? (
                <Image
                  src={img6}
                  fluid
                  thumbnail
                  alt="Upload Image"
                  style={{
                    width: "150px",
                    height: "108px",
                    objectFit: "cover",
                  }}
                />
              ) : saveData && saveData.productimgUrl6 ? (
                <Image
                  src={saveData.productimgUrl6}
                  fluid
                  thumbnail
                  alt="Upload Image"
                  style={{
                    width: "150px",
                    height: "108px",
                    objectFit: "cover",
                  }}
                />
              ) : null}

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "2rem",
                }}
              >
                <Button
                  className="mt-3 all_btn"
                  style={{
                    borderRadius: 50,
                  }}
                  onClick={() => {
                    addProductModalClose();
                  }}
                >
                  {t("TXT.BTN.CAN.LO")}
                </Button>
                <div className="d-flex g-2">
                  {saveData && saveData.id ? (
                    <Button
                      className="mt-3 all_btn"
                      style={{
                        borderRadius: 50,
                      }}
                      onClick={() => handleUpdateProduct()}
                    >
                      Update
                    </Button>
                  ) : (
                    <Button
                      className="mt-3 all_btn"
                      style={{
                        borderRadius: 50,
                      }}
                      onClick={() => handleCreateProduct()}
                    >
                      Add Product
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </Modal>

      <Modal show={deleteModal} onHide={deleteModalClose} size="sm" centered>
        <Modal.Header className="all_background">
          <center>
            <b>REMOVE PRODUCT</b>
          </center>
        </Modal.Header>
        <Modal.Body>Are you sure want to delete product..!</Modal.Body>
        <Modal.Footer className="d-flex justify-content-evenly all_border">
          <Button
            className="mt-3 all_btn"
            style={{
              borderRadius: 50,
            }}
            onClick={() => {
              deleteModalClose();
            }}
          >
            {t("TXT.BTN.CAN.UP")}
          </Button>
          <Button
            className="mt-3 all_btn"
            style={{
              borderRadius: 50,
            }}
            onClick={() => {
              handleDeleteProduct();
            }}
          >
            DELETE
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={likedModal}
        onHide={likedModalClose}
        size="lg"
        centered
        fullscreen={true}
      >
        <Modal.Header className="all_background" closeButton>
          <Modal.Title>
            <center>Liked Product List</center>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!loading ? (
            likedList.length > 0 ? (
              <>
                <center className="datagrid mt-2">
                  <Grid container className="deskTable datagrid">
                    <DataGrid
                      className="all_border - text-center me-3 data_grid"
                      rows={likedList}
                      columns={likedcolumns}
                      initialState={{
                        pagination: {
                          paginationModel: { page: 0, pageSize: 5 },
                        },
                      }}
                      pageSizeOptions={[5, 10]}
                      // checkboxSelection
                      getRowId={(row) => row.id}
                    />
                  </Grid>
                </center>

                <div className="justify-content-center mobileCard">
                  {likedList.map((i, j) => (
                    <Card
                      key={j}
                      className="mobileCard all_border"
                      style={{
                        width: "100%",
                        marginBottom: "10px",
                        backgroundColor: "antiquewhite",
                      }}
                    >
                      <Card.Body>
                        <Card.Subtitle className="mb-2">
                          <b>User Name:</b> {i.user_name}
                        </Card.Subtitle>
                        <Card.Subtitle className="mb-2">
                          <b>Phone Number:</b> {i.phone_no}
                        </Card.Subtitle>
                        <Card.Subtitle className="mb-2">
                          <b>Product Name:</b> {i.product_name}
                        </Card.Subtitle>
                        <Card.Subtitle className="mb-2">
                          <b>Product Price:</b> {i.base_price}
                        </Card.Subtitle>
                        <Card.Subtitle className="mb-2">
                          <b>Product ID:</b> {i.product_id}
                        </Card.Subtitle>
                        {/* <Card.Subtitle className="mb-2">
                        <b>Sub Type:</b> {i.productSubType}
                      </Card.Subtitle> */}
                      </Card.Body>
                    </Card>
                  ))}
                </div>
              </>
            ) : (
              <center>
                <h5>{t("TXT.NO.REC.FUD.LO")}</h5>
              </center>
            )
          ) : (
            <center>
              <Spinner animation="border" variant="warning" />
            </center>
          )}
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            className="all_btn"
            onClick={() => {
              likedModalClose();
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={bookedModal}
        onHide={bookedModalClose}
        size="lg"
        centered
        fullscreen={true}
      >
        <Modal.Header className="all_background" closeButton>
          <Modal.Title>
            <center>Booked Product List</center>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!loading ? (
            bookedList.length > 0 ? (
              <>
                <center className="datagrid mt-2">
                  <Grid container className="deskTable datagrid">
                    <DataGrid
                      className="all_border - text-center me-3 data_grid"
                      rows={bookedList}
                      columns={bookedcolumns}
                      initialState={{
                        pagination: {
                          paginationModel: { page: 0, pageSize: 5 },
                        },
                      }}
                      pageSizeOptions={[5, 10]}
                      // checkboxSelection
                      getRowId={(row) => row.id}
                    />
                  </Grid>
                </center>

                <div className="justify-content-center mobileCard">
                  {bookedList.map((i, j) => (
                    <Card
                      key={j}
                      className="mobileCard all_border"
                      style={{
                        width: "100%",
                        marginBottom: "10px",
                        backgroundColor: "antiquewhite",
                      }}
                    >
                      <Card.Body>
                        <Card.Subtitle className="mb-2">
                          <b>User Name:</b> {i.username}
                        </Card.Subtitle>
                        <Card.Subtitle className="mb-2">
                          <b>Phone Number:</b> {i.phone_no}
                        </Card.Subtitle>
                        <Card.Subtitle className="mb-2">
                          <b>Product Name:</b> {i.productName}
                        </Card.Subtitle>
                        <Card.Subtitle className="mb-2">
                          <b>Product Price:</b> {i.basePrice}
                        </Card.Subtitle>
                        <Card.Subtitle className="mb-2">
                          <b>Product Type:</b> {i.productType}
                        </Card.Subtitle>
                        <Card.Subtitle className="mb-2">
                          <b>Product SubType:</b> {i.productSubType}
                        </Card.Subtitle>
                      </Card.Body>
                    </Card>
                  ))}
                </div>
              </>
            ) : (
              <center>
                <h5>{t("TXT.NO.REC.FUD.LO")}</h5>
              </center>
            )
          ) : (
            <center>
              <Spinner animation="border" variant="warning" />
            </center>
          )}
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            className="all_btn"
            onClick={() => {
              bookedModalClose();
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Stacks spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleCloseSnack}
        >
          <MuiAlert
            variant="filled"
            onClose={handleCloseSnack}
            severity={messageType}
            sx={{
              width: "100%",
            }}
          >
            {message}
          </MuiAlert>
        </Snackbar>
      </Stacks>
    </div>
  );
}

export default ProductList;
