import React from "react";
const Appointment = () => {
  return (
    <>
      <h2>Appointment screen (Protected)</h2>
    </>
  );
};
export default Appointment;

// import React, { Component } from 'react';

// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bs-stepper/dist/css/bs-stepper.min.css';
// import Stepper from 'bs-stepper';

// export default class Appointment extends Component {
//   constructor() {
//     super();
//     this.state = {
//       name: 'React',
//     };
//   }

//   componentDidMount() {
//     this.stepper = new Stepper(document.querySelector('#stepper1'), {
//       linear: false,
//       animation: true,
//       selectors: {
//         steps: '.step',
//         trigger: '.step-trigger',
//         stepper: '.bs-stepper'
//       }
//     });
//   }

//   onSubmit(e) {
//     e.preventDefault();
//   }

//   render() {
//     return (
//       <div className='d-flex' >
//         <div id="stepper1" class="bs-stepper">
//           <div class="bs-stepper-header">
//             <div class="step" data-target="#test-l-1">
//               <button class="step-trigger">
//                 <span class="bs-stepper-circle">1</span>
//                 <span class="bs-stepper-label">Home Info</span>
//               </button>
//             </div>
//             <div class="line"></div>
//             <div class="step" data-target="#test-l-2">
//               <button class="step-trigger">
//                 <span class="bs-stepper-circle">2</span>
//                 <span class="bs-stepper-label">floor Info</span>
//               </button>
//             </div>
//             <div class="line"></div>
//             <div class="step" data-target="#test-l-3">
//               <button class="step-trigger">
//                 <span class="bs-stepper-circle">3</span>
//                 <span class="bs-stepper-label">window Info</span>
//               </button>
//             </div>
//             <div class="line"></div>
//             <div class="step" data-target="#test-l-3">
//               <button class="step-trigger">
//                 <span class="bs-stepper-circle">4</span>
//                 <span class="bs-stepper-label">leveling Info</span>
//               </button>
//             </div>
//             <div class="line"></div>
//             <div class="step" data-target="#test-l-3">
//               <button class="step-trigger">
//                 <span class="bs-stepper-circle">4</span>
//                 <span class="bs-stepper-label">home Details Preview</span>
//               </button>
//             </div>
//             <div class="line"></div>
//             <div class="step" data-target="#test-l-3">
//               <button class="step-trigger">
//                 <span class="bs-stepper-circle">5</span>
//                 <span class="bs-stepper-label">Validation success</span>
//               </button>
//             </div>
//           </div>
//           <div class="bs-stepper-content">
//             <form onSubmit={this.onSubmit}>
//               <div id="test-l-1" class="content">
//                 <div class="form-group">
//                   <label for="exampleInputEmail1">Email address</label>
//                   <input
//                     type="email"
//                     class="form-control"
//                     id="exampleInputEmail1"
//                     placeholder="Enter email"
//                   />
//                 </div>
//                 <button
//                   class="btn btn-primary"
//                   onClick={() => this.stepper.next()}
//                 >
//                   Next
//                 </button>
//               </div>
//               <div id="test-l-2" class="content">
//                 <div class="form-group">
//                   <label for="exampleInputPassword1">Password</label>
//                   <input
//                     type="password"
//                     class="form-control"
//                     id="exampleInputPassword1"
//                     placeholder="Password"
//                   />
//                 </div>
//                 <button
//                   class="btn btn-primary"
//                   onClick={() => this.stepper.next()}
//                 >
//                   Next
//                 </button>
//               </div>
//               <div id="test-l-3" class="content text-center">
//                 <button type="submit" class="btn btn-primary mt-5">
//                   Submit
//                 </button>
//               </div>
//             </form>
//           </div>
//         </div>
//       </div>
//     );
//   }
// }
