import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useMemo,
  useEffect,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { vasthuUrl } from "../component/Api";
import { UseAuth } from "./Auth";
const VasthuContext = createContext();

export const VasthuProvider = ({ children }) => {
  const { token } = UseAuth();
  const [error, setError] = useState("");
  const [plotRec, setPlotRec] = useState("");
  const [homeRec, setHomeRec] = useState("");
  const [homeStatus, setHomeStatus] = useState("");
  const homeFormRef = useRef();
  const [lagnaList, setLagnaList] = useState([]);
  const [facingList, setFacingList] = useState([]);
  const [floorList, setFloorList] = useState([]);
  const [miscList, setMiscList] = useState([]);
  console.log('miscList', miscList);
  console.log('setMiscList', setMiscList);
  const [elementList, setElementList] = useState([]);
  const [allSkill, setAllSkill] = useState([]);
  const [allLang, setAllLang] = useState([]);
  useEffect(() => {
    vasthuUrl
      .get(`vasthu/getlagnas`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((lagna) => {
        console.log("lagna context", lagna, token);
        setLagnaList(lagna.data);
      })
      .catch((err) => {
        setLagnaList([]);
        alert("can't get lagna");
      });
    vasthuUrl
      .get(`vasthu/getdirections`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((direction) => {
        console.log("direction context", direction, token);
        setFacingList(direction.data);
      })
      .catch((err) => {
        setFacingList([]);
        alert("can't get direction");
      });
    vasthuUrl
      .get(`vasthu/getfloors`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setFloorList(res.data);
      })
      .catch((err) => {
        setFloorList([]);
        alert("can't get floor");
      });
    vasthuUrl
      .get(`/vasthu/getelements`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => setElementList(res.data))
      .catch((err) => {
        setElementList([]);
        alert("can't get element");
      });
    vasthuUrl
      .get(`vasthu/getmiscellaneous`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setMiscList(res.data);
      })
      .catch((err) => {
        setMiscList([]);
        alert("can't get Miscellaneous");
      });
    vasthuUrl
      .get(`vasthu/openapi/getskills`)
      .then((res) => {
        setAllSkill(res.data);
        console.log("all skills", res.data);
      })
      .catch((err) => {
        setAllSkill([]);
        alert("can't get Skills");
      });
    vasthuUrl
      .get("openapi/lang/allCode")
      .then((res) => {
        console.log("available lang", res.data);
        setAllLang(res.data);
      })
      .catch((err) => {
        setAllLang([]);
        alert("can't get lang");
      });
  }, []);

  const alert = (msg) => {
    setError(msg);

    setTimeout(() => {
      setError("");
    }, 4000);
  };
  const addHomeStatus = (val) => {
    setHomeStatus(val);
  };
  const HomeStep1 = () => {
    homeFormRef.current.childMethod();
  };

  const addPlotRecId = (id) => {
    setPlotRec(id);
  };

  const addHomeRecId = (id) => {
    console.log("global func works", id);
    setHomeRec(id);
  };

  const memoedValue = useMemo(
    () => ({
      addPlotRecId,
      addHomeStatus,
      HomeStep1,
      homeStatus,
      lagnaList,
      facingList,
      floorList,
      elementList,
      miscList,
      homeFormRef,
      plotRec,
      homeRec,
      allLang,
      allSkill,
      addHomeRecId,
    }),
    [
      allSkill,
      allLang,
      plotRec,
      homeRec,
      homeFormRef,
      homeStatus,
      lagnaList,
      facingList,
      floorList,
      elementList,
      miscList,
    ]
  );

  return (
    <VasthuContext.Provider value={memoedValue}>
      {children}
    </VasthuContext.Provider>
  );
};

export const UseVasthu = () => {
  return useContext(VasthuContext);
};
