import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Container,
  Modal,
  Spinner,
  Table,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { vasthuUrl } from "../component/Api";
import PaymentProcess from "../component/PaymentProcess";
import { UseAuth } from "../context/Auth";
import { format } from "date-fns";

const CustomerPayment = () => {
  // const { userId  } = UseAuth();
  const role = window.sessionStorage.getItem("role");
  const token = window.sessionStorage.getItem("token");
  const user = window.sessionStorage.getItem("userId");
  const crmSelectedUser = window.sessionStorage.getItem("crmSelectedUser");
  const userId = token && role == "ROLE_CRM" ? crmSelectedUser : user;
  
  console.log('userId', userId)
  console.log('crmSelectedUser', crmSelectedUser)
 
  const [transactionDetails, setTransactionDetails] = useState([]);
  const [paymentOrderDetails, setPaymentOrderDetails] = useState({});
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    setLoading(true);
    try {
      vasthuUrl
        .get(`vasthu/getUserTransactionHistory/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          setTransactionDetails(res.data);
          setLoading(false);
        });
    } catch (e) {
      console.log("SERVER ERROR");
      setLoading(false);
    }
  }, []);

  const showModal = (obj) => {
    console.log(obj);
    // vasthuUrl
    //   .get(`vasthu/fetchPaymentOrderCurrentStatus/${orderId}`, {
    //     headers: {
    //       Authorization: `Bearer ${token}`,
    //     },
    //   })
    //   .then((res) => {
    //console.log(res.data);
    setPaymentOrderDetails({
      offerName: obj.offerName,
      bankName: obj.bankName,
      bankTxnId: obj.bankTxnId,
      txnAmount: obj.txnAmount,
      refundAmount: obj.refundAmt,
      txnId: obj.txnId,
      txnDate: obj.txnDate,
      orderId: obj.orderId,
    });

    setShowReviewModal(true);
  };

  const txnDate = new Date(
    paymentOrderDetails && paymentOrderDetails.txnDate
      ? paymentOrderDetails.txnDate
      : ""
  );
  const formattedDate = txnDate.toLocaleString("en-US", {
    day: "2-digit",
    month: "short",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  });

  const formatDate = (date) => {
    try {
      return format(date, "d MMM yyyy, HH:mm a"); // Example format string
    } catch (error) {
      console.error("Error formatting date:", error.message);
      return ""; // or return a default value
    }
  };

  return (
    <div className="p-3">
      <div>
        <center>
          <h2>{t("PR.AC.PMTTAB.TL")}</h2>
        </center>
      </div>

      {!loading ? (
        transactionDetails.length > 0 ? (
          <Table
            striped
            bordered
            hover
            responsive
            // className="deskTable border border-warning"
            className="deskTable1 all_border text-center"
          >
            <thead>
              <tr>
                <th>{t("TXT.OFF.NM.LO")}</th>
                <th>{t("USR.PY.HS.TB.COL2")}</th>
                <th>{t("USR.PY.HS.TB.COL3")}</th>
                <th>{t("USR.PY.HS.TB.COL4")}</th>
                <th>{t("USR.PY.HS.TB.COL5")}</th>
                <th>{t("TXT.VIEW.LO")}</th>
              </tr>
            </thead>
            <tbody>
              {transactionDetails.map((i, j) => (
                <tr key={j}>
                  <td>{i.offerName}</td>
                  <td>{i.txnAmount}</td>
                  <td>{formatDate(new Date(i.txnDate))}</td>
                  <td>{i.orderId}</td>
                  <td>{i.status}</td>
                  <td
                    className="text-center d-flex"
                    style={{ flexDirection: "column" }}
                  >
                    <Button
                      onClick={() => showModal(i)}
                      style={{
                        borderRadius: 50,
                      }}
                      className="mt-2 all_btn"
                    >
                      {t("BTN.VIEW.DET.UP")}
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <div>
            <center>
              <h3>{t("USR.PY.HS.NIL.DAT")}</h3>
            </center>
          </div>
        )
      ) : (
        <div className="text-center">
          <Spinner animation="border" variant="warning" />
        </div>
      )}

      <Modal
        show={showReviewModal}
        onHide={() => {
          setShowReviewModal(false);
        }}
        keyboard={false}
      >
        <Modal.Header className="all_background" closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {t("USR.PY.HS.MOD.TL")}
          </Modal.Title>
        </Modal.Header>

        {loading ? (
          <center>
            <Spinner animation="border" variant="warning" />
          </center>
        ) : (
          <Modal.Body>
            <p>
              <b>{t("TXT.OFF.NM.LO")} : </b>
              {paymentOrderDetails.offerName}
            </p>
            <p>
              <p>
                <b>{t("USR.PY.HS.TB.COL3")} : </b>
                {formattedDate}
              </p>
              <b>{t("USR.PY.HS.TB.COL4")} : </b>
              {paymentOrderDetails.orderId}
            </p>
            <p>
              <b>{t("USR.PY.HS.MOD.ROW2")} : </b>
              {paymentOrderDetails.bankName}
            </p>
            {/* <p>
              {t("USR.PY.HS.MOD.ROW3")} : {paymentOrderDetails.bankTxnId}
            </p> */}
            <p>
              <b>{t("USR.PY.HS.MOD.ROW4")} : </b>
              {paymentOrderDetails.txnAmount}
            </p>
            {/* <p>
              {t("USR.PY.HS.MOD.ROW5")} : {paymentOrderDetails.refundAmount}
            </p> */}
            <p>
              <b>{t("USR.PY.HS.MOD.ROW6")} : </b>
              {paymentOrderDetails.txnId}
            </p>
          </Modal.Body>
        )}

        <Modal.Footer
          className="all_border"
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button className="all_btn" onClick={() => setShowReviewModal(false)}>
            {t("TXT.CLO.LO")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
export default CustomerPayment;
