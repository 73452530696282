import React, { useEffect, useState } from "react";
import { Button, Container, Offcanvas, FormLabel } from "react-bootstrap";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { vasthuUrl } from "../component/Api";
import { UseAuth } from "../context/Auth";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import {
  Autocomplete,
  Box,
  Collapse,
  IconButton,
  TablePagination,
  TextField,
  Typography,
} from "@mui/material";
import DatePicker from "react-datepicker";
import { FcFilledFilter, FcBullish, FcBusinessman } from "react-icons/fc";
import { GrMoney } from "react-icons/gr";
import { FaPercentage } from "react-icons/fa";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as DatePickerMui } from "@mui/x-date-pickers/DatePicker";
import { Label } from "@mui/icons-material";

// For Implementing the Date Picker of Material Ui :
//                Packages to Install
//               1. npm install @mui/x-date-pickers
//               2. npm install moment
//               3. npm install @date-io/dayjs dayjs

const ConsultantPayments = () => {
  const { token } = UseAuth();
  const [professionalPaymentDetails, setProfessionalPaymentDetails] = useState(
    []
  );
  const [show, setShow] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [maxDateLimit, setMaxDateLimit] = useState(" ");
  const [profList, setProfList] = useState([
    { label: "raja", phoneNo: 8797879787 },
  ]);
  const [professionalChoosen, setProfessionalChoosen] = useState({
    consultantName: "ALL CONSULTANTS",
  });
  const [datelimit, setdatelimit] = useState(" ");
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [startingDate, setStartDate] = useState();

  const [selectedConsultant, setSelectedConsultant] = useState("ALLPRS");
  const [choosenDate, setChoosenDate] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  useEffect(() => {
    var today = new Date();

    var currentdate = today.getTime();

    setdatelimit(currentdate);

    let todayDate = generateCurrentDate();

    console.log("TODAY DATE", todayDate);

    let lastMonthFromToday = generateDateFormat(subtractMonths(1));

    console.log("Last Month From Today", lastMonthFromToday);

    let intialDate = lastMonthFromToday + " to " + todayDate;

    try {
      vasthuUrl
        .get(`/consultantService/listOfProfessionals`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("RESPONSE", res.data);
          const newObj = {
            consultantName: "ALL CONSULTANTS",
            consultantUuid: "ALLPRS",
          };
          res.data.unshift(newObj);
          setProfList(res.data);
          //setLoading(false);
        });

      vasthuUrl
        .get(`/consultantService/individualConsultantDetailedReports `, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          res.data.map((i) => {
            i.open = false;
            i.earningRecords.currentPage = 0;
            i.earningRecords.rowsPerPage = 5;
          });
          console.log(res.data);
          setProfessionalPaymentDetails(res.data);
          setChoosenDate(intialDate);
        });
    } catch (error) {
      console.log("SERVER ERROR");
    }
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const alert = (msg) => {
    setErrMsg(msg);
    setTimeout(() => {
      setErrMsg("");
    }, 3000);
  };

  function generateCurrentDate() {
    var today = new Date();
    var dd = today.getDate();

    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }
    today = yyyy + "-" + mm + "-" + dd;

    return today;
  }

  function generateDateFormat(date) {
    let formatedDate = "YYYY-MM-DD";

    var dd = date.getDate();

    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }

    if (mm < 10) {
      mm = "0" + mm;
    }
    formatedDate = yyyy + "-" + mm + "-" + dd;

    return formatedDate;
  }

  function subtractMonths(numOfMonths, date = new Date()) {
    date.setMonth(date.getMonth() - numOfMonths);

    return date;
  }

  const defaultProps = {
    options: profList,
    key: profList.consultantUuid,
    getOptionLabel: (option) => option.consultantName,
  };

  const getConsultantCallRecords = (uuid, pageNo) => {
    const recordObj = vasthuUrl
      .get(`/consultantService/individualConsultantReports/${uuid}/${pageNo}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        return res.data;
      });

    return recordObj;
  };

  const filterDate = async () => {
    if (dateRange[0] == null || dateRange[1] == null) {
      return alert("Date is Invalid");
    }

    console.log("SELECTED CONS :" + selectedConsultant);

    let sDate = dateRange[0];
    let eDate = dateRange[1];

    console.log("SDATE : " + sDate + "End :" + eDate);

    let startdateName = sDate.toString().substring(0, 15);
    let endDateName = eDate.toString().substring(0, 15);

    let selectedDateFromFilter = startdateName + " to " + endDateName;

    let selectedStartDate = generateDateFormat(sDate);
    let selectedEndDate = generateDateFormat(eDate);

    try {
      const res = await vasthuUrl.get(
        `/consultantService/earningReportByDate/${selectedConsultant}`,
        {
          params: {
            startDate: selectedStartDate,
            endDate: selectedEndDate,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      res.data.map((i) => {
        i.open = false;
        i.earningRecords.currentPage = 0;
        i.earningRecords.rowsPerPage = 5;
      });

      console.log("SLOT FOR SELECTED DATES" + JSON.stringify(res.data));
      setProfessionalPaymentDetails(res.data);
      setChoosenDate(selectedDateFromFilter);
      setShow(false);
      setDateRange([]);
    } catch (error) {
      alert("Server Error");
    }

    // setShow(false)
  };

  return (
    <Container>
      <div
        style={{
          flexDirection: "row",
          justifyContent: "flex-end",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Button
          style={{ marginBottom: 10 }}
          className="all_btn"
          onClick={handleShow}
        >
          Filter
          <FcFilledFilter size={25} />
        </Button>
      </div>

      {choosenDate != "" ? (
        <center>
          <strong style={{ margin: 8 }}>
            Consultant Payment Reports on {choosenDate}
          </strong>
        </center>
      ) : null}

      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>
                Consultant <FcBusinessman size={20} />
              </TableCell>
              <TableCell>
                Level <FcBullish size={20} />
              </TableCell>
              <TableCell>
                Earnings <GrMoney size={20} />
              </TableCell>
              <TableCell>
                Share <FaPercentage size={20} />
              </TableCell>
            </TableRow>
          </TableHead>
          {professionalPaymentDetails.map((row, k) => (
            <TableBody key={k}>
              <TableRow
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell>
                  <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => {
                      row.open = !row.open;
                      setProfessionalPaymentDetails([
                        ...professionalPaymentDetails,
                      ]);

                      // const callRecordObj =   getConsultantCallRecords(row.consultantUuid,0)
                      // console.log("FROM RETURN OF CALL RECORD OBJ" , callRecordObj)
                    }}
                  >
                    {row.open ? <TiArrowSortedUp /> : <TiArrowSortedDown />}
                  </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                  {row.consultantName}
                </TableCell>
                <TableCell>{row.consultantLevel}</TableCell>
                <TableCell>{row.earningRecords.TotalEarnings}</TableCell>
                <TableCell>{row.sharePercentage}</TableCell>
              </TableRow>
              <TableRow key={k}>
                <TableCell
                  style={{ paddingBottom: 0, paddingTop: 0 }}
                  colSpan={6}
                >
                  <Collapse in={row.open} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                      <Paper sx={{ width: "100%", mb: 2 }}>
                        <Typography variant="h6" gutterBottom component="div">
                          See More
                        </Typography>
                        <Table size="small" aria-label="purchases">
                          <TableHead>
                            <TableRow>
                              <TableCell>Date</TableCell>
                              <TableCell>Offer(Service)</TableCell>
                              <TableCell>Earned</TableCell>
                              <TableCell>Payment</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {(rowsPerPage > 0
                              ? row.earningRecords.EarningsReport.slice(
                                  row.earningRecords.currentPage *
                                    row.earningRecords.rowsPerPage,
                                  row.earningRecords.currentPage *
                                    row.earningRecords.rowsPerPage +
                                    row.earningRecords.rowsPerPage
                                )
                              : row.earningRecords.EarningsReport
                            ).map((result, i) => (
                              <TableRow key={i}>
                                <TableCell component="th" scope="row">
                                  {result.date}
                                </TableCell>
                                <TableCell component="th">
                                  {result.offerDetails}
                                </TableCell>
                                <TableCell component="th">
                                  {result.earnedAmount}
                                </TableCell>
                                <TableCell component="th">
                                  {result.status}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25]}
                          component="div"
                          count={row.earningRecords.EarningsReport.length}
                          rowsPerPage={row.earningRecords.rowsPerPage}
                          page={row.earningRecords.currentPage}
                          onPageChange={(event, newPage) => {
                            row.earningRecords.currentPage = newPage;
                            setProfessionalPaymentDetails([
                              ...professionalPaymentDetails,
                            ]);
                          }}
                          onRowsPerPageChange={(event) => {
                            row.earningRecords.rowsPerPage = parseInt(
                              event.target.value,
                              10
                            );
                            row.earningRecords.currentPage = 0;
                            setProfessionalPaymentDetails([
                              ...professionalPaymentDetails,
                            ]);
                          }}
                        />
                      </Paper>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </TableBody>
          ))}
        </Table>
      </TableContainer>

      <Offcanvas show={show} onHide={handleClose} placement={"end"}>
        <Offcanvas.Header className="all_background" closeButton>
          <Offcanvas.Title>Choose Date Range For Filter</Offcanvas.Title>
        </Offcanvas.Header>

        {errMsg && (
          <center>
            <h6 style={{ color: "red" }}>{errMsg}</h6>
          </center>
        )}

        <Offcanvas.Body>
          <h6>Duration</h6>

          <DatePicker
            selectsRange
            minDate="2020-01-01"
            maxDate={datelimit}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              setDateRange(update);
              console.log("DATE RANGE", update);
            }}
            dateFormat="yyyy-MM-dd"
          />

          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>

      <DatePickerMui
          disableFuture
          label="Responsive"
          openTo="year"
          inputFormat="YYYY-MM-DD"
          views={['day', 'month', 'year']}
          value={startingDate}
          onChange={(newValue) => {
            console.log("DATE SELECTED" , newValue)
            setStartDate(newValue);
          }}
          renderInput={(params) => <TextField {...params} />}
        />

</LocalizationProvider> */}

          <div className="mt-3">
            <h6>Sort By</h6>

            <Autocomplete
              {...defaultProps}
              disablePortal
              id="combo-box-demo"
              value={professionalChoosen}
              onChange={(e, val) => {
                setProfessionalChoosen(val);
                setSelectedConsultant(val.consultantUuid);
              }}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <>
                  <FormLabel>Consultant</FormLabel>
                  <TextField {...params} />
                </>
              )}
            />
          </div>
          <Offcanvas.Body>
            <Button className="all_btn" onClick={filterDate}>
              Apply Filter
            </Button>
          </Offcanvas.Body>
        </Offcanvas.Body>
      </Offcanvas>
    </Container>
  );
};

export default ConsultantPayments;
