import { Button as MyButton } from "@material-ui/core";
import { Send } from "@material-ui/icons";
import React, { Suspense, useEffect, useState } from "react";
import { Button, Card, Form, InputGroup, Spinner } from "react-bootstrap";
import ReactGA from "react-ga4";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import validator from "validator";
import { vasthuUrl } from "../component/Api";
import { UseAuth } from "../context/Auth";

function LoginOTP({ setActiveKey, setPhoneNo, alertType, alert }) {
  const { loading, error, handleSocialLogin } = UseAuth();
  const { t } = useTranslation();
  const location = useLocation();
  const iniLang = localStorage.getItem("i18nextLng");
  const [countryCode, setCountryCode] = useState("0");
  const [phone, setPhone] = useState("");
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [disabled, setDisabled] = useState(false);

  //OTP
  const [loginOtp, setLoginOtp] = useState(["", "", "", ""]);

  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    setLoginOtp([
      ...loginOtp.map((d, idx) => (idx === index ? element.value : d)),
    ]);

    if (element.nextSibling) {
      element.nextSibling.focus();
    }
  };

  const handleKeyUp = (element, index) => {
    if (element.key === "Backspace" && element.target.previousSibling) {
      element.target.previousSibling.focus();
    }
  };
  const loginOTP = loginOtp.join("");

  const referPath = () => {
    if (location.otp) {
      return location.otp.from.pathname;
    } else {
      return "/";
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const path = referPath();
    const phone_with_code = countryCode.concat(phone);
    if (!validator.isEmpty(phone) && !validator.isEmpty(loginOTP)) {
      handleSocialLogin({ phone: phone_with_code, loginOTP, path }, (res) => {
        if (res === "success") {
          setPhone("");
          setLoginOtp("");
          // GA test -- login success
          ReactGA.event({
            category: "User",
            action: `Successfully Logged Event`,
          });
          window.dataLayer.push({ event: "my-custom-login" });
          //
        } else {
          alert(t("USR.HOM.ERR.MSG1"));
          alertType("error");
        }
      });
    } else {
      alert(t("USR.HOM.ERR.MSG1"));
      alertType("error");
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  });

  const sendOTP = async (e) => {
    e.preventDefault();
    if (!validator.isEmpty(phone)) {
      try {
        const phone_code = countryCode.concat(phone);
        await vasthuUrl
          .post(`vasthu/user/requestotptouser`, {
            phone_no: phone_code,
            url: `${process.env.REACT_APP_SMS_DEL}`,
            lng: iniLang,
          })
          .then(async (res) => {
            setMinutes(0);
            setSeconds(59);
            setDisabled(true);
            console.log("res", res);
            if (res.data.message === "Success") {
              alert(res.data.message);
              alertType("success");
            } else {
              alert(res.data);
              alertType("error");
              setActiveKey("register");
              setPhoneNo(phone);
            }
          });
      } catch (err) {
        console.log(err);
      }
    } else {
      alert("Please Enter Your Valid Phone Number!");
      alertType("error");
    }
  };

  return (
    <>
      <Suspense fallBack={<Spinner animation="border" />}>
        <React.Fragment>
          <Card className="p-4 all_border">
            <Card.Body className="text-center">
              <Card.Title name="TEST1" style={{ color: "#ffbb33" }}>
                <u>{t("USR.LOG.OTP.LOG.HED")}</u>
              </Card.Title>
            </Card.Body>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label>{t("USR.HOM.HD.P")}</Form.Label>
                <InputGroup className="mb-3">
                  <Form.Select
                    aria-label="Country Code"
                    value={countryCode}
                    className="form_feild countrywidth"
                    onChange={(e) => {
                      setCountryCode(e.target.value);
                    }}
                  >
                    <option defaultChecked key={1} value="0">
                      IND
                    </option>
                    <option key={2} value="+65">
                      SGP
                    </option>
                    <option key={3} value="+60">
                      MYS
                    </option>
                    <option key={4} value="+1">
                      USA
                    </option>
                  </Form.Select>
                  <Form.Control
                    type="tel"
                    value={phone}
                    className="form_feild"
                    placeholder={t("USR.HOM.HD.P")}
                    onChange={(e) => {
                      const value = e.target.value.replace(/\D/g, "");
                      setPhone(value)
                    }}
                  />
                </InputGroup>
                {/* <Form.Text>
                      (Prefix 0 before your 10 digit phone number - 0xxxxxxxxxx
                      format){" "}
                    </Form.Text> */}
              </Form.Group>

              <Form.Group className="mb-3 d-flex justify-content-end">
                <Button
                  className="all_btn"
                  disabled={disabled}
                  onClick={sendOTP}
                >
                  {t("USR.LOG.OTP.SEND")}
                </Button>
              </Form.Group>

              <div
                className="countdown-text"
                style={{ display: "flex", alignItems: "flex-start" }}
              >
                {seconds > 0 || minutes > 0 ? (
                  <p>
                    {t("FGP.TIREM")} {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </p>
                ) : (
                  <p>{t("FGP.DNT.OTP")}</p>
                )}
                &nbsp;&nbsp;&nbsp;
                <button
                  disabled={seconds > 0 || minutes > 0 || !disabled}
                  style={{
                    color:
                      seconds > 0 || minutes > 0 || !disabled
                        ? "#DFE3E8"
                        : "#FF5630",
                    border: "none",
                  }}
                  onClick={sendOTP}
                >
                  {t("FGP.RESD.OTP")}
                </button>
              </div>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>{t("USR.LOG.OTP.ENT")}</Form.Label>
                {/* <Form.Control
                        type="number"
                        value={password}
                        className="form_feild"
                        onChange={(e) => setPass(e.target.value)}
                        required
                      /> */}
                <div className="otp_input" style={{ gap: "1rem" }}>
                  {loginOtp.map((data, index) => {
                    return (
                      <Form.Control
                        key={index}
                        type="text"
                        maxLength="1"
                        value={data}
                        disabled={seconds > 0 || minutes > 0 ? false : true}
                        className="form_feild"
                        style={{ width: "35px" }}
                        onChange={(e) => handleChange(e.target, index)}
                        onKeyUp={(e) => handleKeyUp(e, index)}
                      />
                    );
                  })}
                </div>
              </Form.Group>

              {!loading ? (
                <MyButton
                  style={{
                    backgroundColor: "#FFBB33",
                    color: "white",
                    marginTop: "10px",
                  }}
                  variant="contained"
                  fullWidth="true"
                  onClick={onSubmit}
                  endIcon={<Send />}
                >
                  {t("USR.HOM.BTN.SUB")}
                </MyButton>
              ) : (
                <>
                  <MyButton
                    style={{ backgroundColor: "#FFBB33", color: "white" }}
                    fullWidth="true"
                    disabled
                  >
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    {t("USR.HOM.BTN.LOD")}
                  </MyButton>
                </>
              )}
            </Form>
          </Card>
        </React.Fragment>
      </Suspense>
    </>
  );
}

export default LoginOTP;
