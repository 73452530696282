
import React from "react";
import { Container, Image } from "react-bootstrap";
import Title from "../component/Title";

const VasthuArticalTamil = () => {
  return (
    <Container>
      <Title title= "ஜோதிட பலன் காணுமுறைகள்"  />
    <div className="m-1 p-3" style={{ border: "1px solid #ffb33e" }}>
    <p><strong>ஆசிரியர்:</strong> ரா.பாலசந்தர், B.TECH (ARCH), MA Astro</p>
    <u>பராசர முறைப்படி கிரகங்களின் பலம், பலவீனம் நிர்ணயித்தல்</u>

    <h3><u>1. கிரகங்கள் பலம் நிர்ணயித்தல்</u></h3>
    <ol>
    <li>கிரகங்கள் ஆட்சி உச்சம் பெறுவது (சுபத்துவம்)</li>
    <li>கிரகங்கள் நட்பு வீடுகளில் அமர்வது மற்றும் நட்பு கிரகத்துடன் இணைவது.</li>
    <li>கிரகங்கள் ஸ்தான பலம் பெறுதல்</li>
    <li>கிரகங்கள் கேந்திர, திரிகோண பலம் பெறுதல்</li>
    <li>சனி, ராகு, கேது மட்டும் 3,6,11 பாவங்களில் அமர்வது</li>
    <li>கிரகங்கள் சுபகிரக பார்வை பெறுதல், சுபகிரகங்களின் சாரத்தில் அமர்வது</li>
    <li>கிரகங்கள் நவாம்சத்தில் பலம் பெறுவது (ஆட்சி, உச்சம், இயற்கை சுபகிரகங்கள் வீடுகளில் அமர்வது)</li>
    </ol>
    

    <h3><u>2. கிரகங்கள் பலவீனம் நிர்ணயித்தல்</u></h3>
    <ul style={{ listStyle: 'circle', paddingLeft: '20px', lineHeight: '1.6' }}>
    <li>கிரகங்கள் ராகு, கேதுவுடன் இணைதல் / நட்சத்திர சாரம் பெறுதல்</li>
    <li>கிரகங்கள் பகை கிரகத்துடன் இணைதல் / நட்சத்திர சாரம் பெறுதல்</li>
    <li>கிரகங்கள் 6,8,12-இல் மறைவது / 6,8,12அதிபதிகளின் தொடர்பு பெறுவது</li>
    <li>கிரகங்கள் பாதக ஸ்தானத்தில் அமர்வது / தொடர்பு பெறுதல்</li>
    <li>கிரகங்கள் நவாம்சத்தில் பலமிழப்பது (நீசம், ராகு, கேது தொடர்பு)</li>
    <li>கிரகங்கள் கிரகணதோஷம் பெறுதல் (ராகு, கேதுவுடன் முன், பின் 3 டிகிரியில் அமர்வது)</li>
    <li>கிரகங்கள் அஸ்தமனம், வக்ரம், கிரகயுத்தம், திதி சூன்யம் அடைதல்</li>
    <li>முடக்கு, அவயோகி ஆக இருத்தல் / தொடர்பு பெறுதல்.</li>
    </ul>
    <h3><u>3. கிரகங்கள் பகை விவரங்கள்:</u></h3>
    <ul>
    <li>சூரியன்- சனி, சுக், ராகு, கேது</li>
    <li>சந்திரன்- சனி, சுக், ராகு, கேது</li>
    <li>செவ்வாய்- சனி, செவ்வாய், ராகு, கேது</li>
    <li>புதன்- செவ், குரு, ராகு, கேது</li>
    <li>குரு- சுக், புதன், ராகு, கேது</li>
    <li>சுக்கிரன்- குரு, சூ, சந், ராகு, கேது</li>
    <li>சனி- சூ, சந், செவ், ராகு, கேது</li>
    <li>ராகு- சூ, சந், செவ்</li>
    <li>கேது- சூ, சந், செவ்</li>
    </ul>
    <h3><u>4. லக்னரீதியாக கிரகங்களின் பகை விவரங்கள்</u></h3>
    <ol>
    <li>மேஷம்- புதன், சனி</li>
    <li>ரிஷபம்- சந், குரு</li>
    <li>மிதுனம்- குரு, செவ்</li>
    <li>கடகம்- சனி, சுக்</li>
    <li>சிம்மம்- சனி, சுக்</li>
    <li>கன்னி- குரு, சுக்</li>
    <li>துலாம்- சூரி, குரு</li>
    <li>விருச்சிகம்- புதன், சனி</li>
    <li>தனுசு- புதன், சுக்</li>
    <li>மகரம்- சூரி, செவ்வாய்</li>
    <li>கும்பம்- சூரி, சந்திரன்</li>
    <li>மீனம்- புதன், சுக்கிரன்</li>
    </ol>
    <h3><u>5. ராகு, கேதுகளின் கிரகணதோஷம் (கிரங்கள் பலம் இழத்தல்)</u></h3>
    <p>ராகு, கேதுவுடன் இணைந்த கிரகங்கள் கிரகணதோஷம் அடைகிறது</p>
    <h3><u>6. சனி, செவ்வாய் தோஷம்</u></h3>
    <p>சனி, செவ்வாய் சேர்க்கை/பார்வை தோஷத்தை தருகிறது. மேலும், ராகு சனியை போல் செயல்படுகிறது. சனி சஞ்சீத கர்மாவையும், ராகு பிராப்தகர்மாவையும் குறிக்கும். சனியின் <b>3,7.10</b> பார்வை படும் இடங்கள் கர்மாவை சுட்டிகாட்டுகிறது. கேது செவ்வாய் போல் செயல்படுகிறது. செவ்வாயின் <b>4,8,12</b> பார்வை படும் பாவத்தின் பிரச்சனையை சுட்டிகாட்டுகிறது.</p>
    <h3><u>7. கிரகங்களின் நிலைகள்</u></h3>
    <p>கிரக பலத்தை தீர்மானிக்க கிழ்கண்ட தன்மைகளிலும் கிரகங்களின் நிலைகளை பார்க்க வேண்டும்.</p>
    <ul>
    <li>கிரகயுத்தம்</li>
    <li>அஸ்தமனம்</li>
    <li>வக்ரம்</li>
    <li>நீசம்</li>
    <li>அஷ்டவர்க்கம்</li>
    </ul>
    <h3><u>8. கிரகங்கள் தொடர்புகொள்ளும் நிலைகள்</u></h3>
    <ul>
    <li>ராசி ரீதியாக</li>
    <li>பாவங்கள் ரீதியாக (1,5,9/ 2,12 / 3,7,11)</li>
    <li>நேரடி சேர்க்கை ரீதியாக</li>
    <li>நட்சத்திர சாரங்கள் ரீதியாக</li>
    <li>பார்வை ரீதியாக</li>
    <li>வார்க்கங்கள்  ரீதியாக</li>
    </ul>
    <h3><u>9. கேள்விகளின் பலனனை ஆராயும் விதம்</u></h3>
    <ul>
    <li>கிரகத்தின் காரகம்</li>
    <li>பாவகம்</li>
    <li>பாவகாதிபதி</li>
    </ul>
    <p>மேற்கண்ட மூன்று நிலைகளில் பலம் பெரும் பொழுது நன்மையையும், பலம் இழக்கும் பொழுது தீமையும் உண்டாகிறது.</p>
    <h3><u>10. ராசி, பாவங்கள், பாவாதிபதி, கிரகங்கள் குறிகாட்டும் விதம்</u></h3>
    <p>ஒரு ஜாதகத்தில்,</p>
    <ul>
    <li><b>ராசி</b>-சூழலை சொல்லுகிறது</li>
    <li><b>பாவம்</b>, பாவாதிபதி-செயலை சொல்லுகிறது.</li>
    <li><b>கிரகங்கள்</b>-எண்ணம் மற்றும் உறவுகளை சொல்லுகிறது.</li>
    </ul>
    <p><u><b>பாவம், பாவத் தொடர்பு/பாவம்ஆரம்ப முனை:</b></u></p>
    <p>ஒரு ஜாதகத்தின் தனித்தன்மையை நிர்ணயிப்பது அந்த ஜாதகத்தின் 12 பாவ ஆரம்பமுனைகள் தான் என்பதை நாம் அறிய வேண்டும். ஒரு பாவத்தின் ஆரம்பமுனை (CUSP) அந்த பாவத்தின் நுழைவு வாயிலாக கருதப்படுகின்றது. அந்த பாவத்தின் நுழைவு வாயில் வழியாக சென்று தான் அந்த பாவத்தின் விதியை (கொடுப்பினை) நிர்ணயம் செய்யவேண்டும். பாவங்கள் என்ற மாய கண்ணாடியில்,கிரகங்கள் பாவக ரீதியாக எந்த பாவத்தில் அமர்ந்துள்ளது என்பதை, பாவ ஆரம்பமுனை கொண்டு, உறுதி செய்ய வேண்டும். தசா,புக்தியை பார்க்க,பாவ கட்டத்தை நாம் பயன்படுத்த வேண்டும்.</p>
    <ul>
      <li>ஒரு பாவத்தின் 12ம் பாவம்,அந்த பாவத்தை அதை முழுமையாக மறுக்கிறது. </li>
      <li>ஒரு பாவத்தின் 8ம் பாவம் முழுமையாக, அந்த பாவத்தை தடை செய்கிறது. </li>
    </ul>
    <h4><u>ஜாதகத்திற்குபலன் எடுக்கும் விதம்:- (PACS)</u></h4>
    <p>ஜாதகத்தில் லக்னம்,சந்திரன்,கால புருஷ தத்துவத்திற்கு, பாவகம்/காரகத்திற்கு பலன் எடுக்க கிழ்கண்ட விதியை பயன்படுத்த வேண்டும்.</p>
    <ul>
      <li>கிரகத்தின் நிலை POSITION</li>
      <li>கிரகத்தின்  பார்வை-ASPECT</li>
      <li>கிரகத்தின்  சேர்க்கை- CONJUCTION</li>
      <li>கிரகத்தின்  சாரம்- STELLER ஆகியவற்றை கணக்கில் கொண்டு பலன் எடுக்க வேண்டும்.</li>
    </ul>
    <h4><u>ஜாதக யோகங்கள்:-</u></h4>
    <h3><u>1.ஒளி கிரகங்கள் கொடுக்ககூடிய யோகங்கள்:- </u></h3>
    <ul>
      <h3>A.சூரியன்</h3>
      <li>சூரி+சந்(0°+180°) =பௌர்ணமி</li>
      <li>சூரியனுக்கு முன்,பின் பாவங்களில் சுபகிரகங்கள் இருந்தால்</li>
      <li>- சுபகத்திரியோகம்.</li>
      <li>சூரியன் 10ஆம் பாவத்தில் இருந்தால்-திக் பலம்</li>
      <li>சூரி+செவ்,சூரி+குரு இணைவு- அரசயோகம்</li>
      <li>சூரி+புதன் -1,4,8இல் இருந்தால்-மன்னவன் யோகம்.</li>
      <li>சூரி+சந்(0°+0°) =அமாவாசை</li>
    </ul>
    <h3>B.சந்திரன்:-</h3>

<Image
          src="Picture3.png"
          class="img-fluid p-5"
          object="fill"
          alt="mainPic"
          width="100%"
          height="100%"
          style={{padding:'50px'}}
        />
    <ul>
      <li>சந்திரனுக்கு முன்,பின் பாவங்களில் சுபகிரகங்கள் இருந்தால்</li>
      <li>- சுபகத்திரியோகம்.</li>
      <li>சந்திரனுக்கு கேந்திரத்தில் குரு இருந்தால்-கஜகேசரி யோகம்.</li>
      <li>சந்திரனுக்கு 6,7,8 இல் சுபகிரகம் இருந்தால் -அதியோகம்.</li>
      <li>குரு+சந் இணைவு= குருசந்திர யோகம்.</li>
      <li>சந்+செவ் இணைவு= சந்திர மங்களயோகம்.</li>
      <li>சந்திரன் 4ம் பாவத்தில் இருப்பது=திக்பலம்.</li>
    </ul>
    <h3><u>2.பஞ்ச மகா புருஷ யோகம்:</u></h3>
    <p>லக்னத்துக்கு(அல்லது)ராசிக்கு 1,4,7,10 இல் குரு,செவ்,சனி,சுக்,புத ஐவரும் ஆட்சி,உச்சம் பெரும் அமைப்பு, பஞ்ச மஹா ;புருஷ யோகம் எனப்படும்.</p>
    <Image
          src="Picture4.png"
          class="img-fluid"
          object="fill"
          alt="mainPic"
          width="100%"
          height="100%"
          style={{padding:'50px'}}
        />
    <h3><u>3.வசுமதி யோகம்:-</u></h3>
    <p>லக்னத்துக்கு 3,6,10,11 -இல் சனி,ராகு,கேது,செவ்வாய் இருந்தால் வசுமதி யோகம்.</p>
    <h3><u>4.அஷ்டலக்ஷ்மி யோகம்; -</u></h3>
    <p>லக்னத்துக்கு 6,12-இல் ராகு,கேது இருந்தால் அஷ்டலக்ஷ்மி யோகம் எனப்படும்.</p>
    <h3><u>5.காலாமிர்த யோகம்</u></h3>
    <p>லக்னத்துக்கு முன்னாடிராகுவும், இடையில் அனைத்து கிரகங்களும் கேதுவுக்குள்(180°க்குள்) அமைந்தால் காலாமிர்த யோகம் எனப்படும்.</p>
    <h3><u>தர்மகர்மாதிபதி யோகம்:-</u></h3>
    <p>தர்ம கர்மாதிபதி யோகம் என்பது, லக்னத்திற்கு பெரும் கோணம் எனப்படும், ஒன்பதாமதிபதியும், லக்னத்திற்கு பெரும் கேந்திரம் என சொல்லப்படும் பத்தாம் அதிபதியும் தங்களுக்குள் தொடர்பு கொள்வதோ, வலுத்திருப்பதோ தர்ம கர்மாதிபதி யோகம் எனப்படும். லக்னத்திற்கு பத்தாமிடத்திலேயே 9,10 ஆம் அதிபதிகள் சேர்ந்தோ 9,10 ஆம் அதிபதிகள் பரிவர்த்தனை அடைவதோ இவர்களது தசா யோகத்தை தரும். இது முதல் தரமான யோகமாகும். திரும்பதிரும்ப லக்னமும் லக்னாதிபதியும் வலுவாக இருக்க வேண்டும் என்பதை நினைவில் கொள்ளுங்கள். இந்த தர்ம கர்மாதிபதிகளின் சேர்க்கை 9,10 ஆம் இடங்களை தவிர மற்ற இடங்களில் இருப்பது அந்த இடத்திற்கேற்ப யோகம் குறைந்துகொண்டே வரும். 6,8,12ல் தர்மகர்மாதிபதி யோகம் மிகவும் பலவீனமாக செயல்படும்</p>
    <h3><u>6.பாதகாதி தோஷம்:-</u></h3>
    <p>ஜாதகத்தில் பாதகாதிபதி கிரகத்துடன், மற்ற கிரகங்கள் இணைந்து/பார்த்து(முன்,பின்8.5 பாகைக்குள்) இருத்தல் பாதகாதி தோஷம் எனப்படும்.</p>
    <h3><u>7.பிரமஹத்தி தோஷம்:-</u></h3>
    <p>ஜாதகத்தில் குரு+ராகு, குரு+சனி இணைந்துமுன்,பின் 8.5 டிகிரிக்குள் இருந்தால், பிரமஹத்தி தோஷம் எனப்படும்.</p>
    <h3><u>8.மறைவிட தோஷம்:-</u></h3>
   <p>லக்னத்துக்கு 6,8,12இல் கிரகங்கள் இருந்தால்,மறைவிட தோஷம் எனப்படும்.</p>
   <h3><u>9.காலசர்ப்ப தோஷம்/யோகம்:-</u></h3>
   <p>காலனான ராகுவுக்கும், சர்ப்பமான கேதுவுக்கும் இடையில் கிரகங்கள் இருப்பது கால சர்ப்ப தோஷம் எனப்படும். கால சர்ப்ப தோஷம் என்பது ராகு, கேது ஆகிய இருபாம்புக்களுக்கிடையே மற்ற ஏழு கிரகங்களும் அடைபட்டு இருப்பதாகும்.</p>
   <h3><u>10.நீச தோஷம்: -</u></h3>
   <p>ஜாதகத்தில் நீசபட்ட கிரகத்துடன், மற்ற கிரகங்கள் இணைந்து/பார்த்து(முன்,பின்8.5°குள்) இருத்தல் நீச தோஷம் எனப்படும்.</p>
   <h3><u>11.கர்மவினை தோஷம்: -</u></h3>
   <p>ஜாதகத்தில் சனி+செவ் ஒருபாவத்தில் இணைந்து(முன், 8.5°குள்) இருத்தல் அல்லது சனி 3,7,10ம்பார்வையாக செவ்வாய் பார்த்தல்  மற்றும்செவ்வாய் சனியை 4,7,8 ம்பார்வையாக பார்த்தல் கர்மவினை தோஷம் எனப்படும்.</p>
   <h3><u>12.பாபகத்திரி தோஷம்: -</u></h3>
   <p>ஜாதகத்தில் ஒரு சுபகிரகத்துக்கு முன்,பின் பாவ கிரகங்கள் அமர்வது பாவகத்திரி தோஷம் எனப்படும்.</p>
   <h3><u>13.கேந்திர ஆதிபத்திய தோஷம்/யோகம்:-</u></h3>
   <p>கேந்திர ஆதிபத்திய தோஷம் என்பது சுபர்களான குரு, சுக்கிரன், புதன் கேந்திரங்களில் அமையப்பெற்றால் ஏற்படும் தோஷம் ஆகும். ஒருவர் ஜாதகத்தில் கேந்திர ஸ்தானங்கள் என்பது 1,4,7,10 ஆகிய ஸ்தானங்களை குறிப்பதாகும். இதில் 1ம் இடம்( லக்கினம் ) கேந்திரம் மற்றும் திரிகோணத்தில் அமைப்பெற்றால் அதிக பாதிப்பு ஏற்படுவதில்லை.
    பஞ்ச மஹா புருஷ யோகம் என்பது செவ்வாய், புதன், குரு, சுக்ரன், சனி ஆகியோர் லக்ன கேந்திரத்தில் ஆட்சியோ அல்லது உச்சமோ பெற்று இருந்தால் ஏற்படுகிறது. அதே வேலையில் இயற்கையில் சுபர்களான புதன், குரு, சுக்ரன் கேந்திரத்திரக்கு அதிபதி அனால் கேந்திர ஆதிபத்திய தோஷம் ஏற்படும்.
    இதில் முக்கியமாக கவனிக்க பட கூடிய விஷயம் என்ன வென்றால் , இயற்கையில் சுபர்களான புதன், குரு, சுக்ரன் கேந்திரங்களில் நிற்கும்பொழுது பஞ்ச மஹா புருஷ யோகம் தந்தாலும் கேந்திர ஆதிபத்திய தோஷம் அமைந்த காரணத்தினால் பஞ்ச மஹா புருஷ யோகம் கொடுக்க வேண்டிய சுப பலன்கள் தடுக்கப்படுகிறது.- தீய பலன்களே ஏற்படுகிறது.
   </p>
   <h2><u>புனர்பூ தோஷம்:-</u></h2>
    <p>ஒரு ஜாதகத்தில் சனியும் சந்திரனும் சேர்ந்து நின்றாலோ, பரிவர்தனை பெற்றாலோ சனியின் வீட்டில் சந்திரன் அல்லது சந்திரன் வீட்டில் சனி நின்றாலோ அல்லது சம சப்தம பார்வை பெற்றாலோ புணர்ப்பு தோஷம் ஏற்படுகிறது.</p>
    <h3><u>ஜாதகத்திற்கு பொதுவான குணங்கள்,தோற்றம்,பாக்கியம் பலன் காணும் விதம்:</u></h3>
    <ul>
       <li>லக்னம்,பாக்கியம்</li>
       <li>லக்னாதிபதி தோற்றம்,குணம்</li>
       <li>குரு-ஜீவகாரகன் ஆண்களுக்கு</li>
       <li>சுக்கிரன்- ஜீவகாரகன் பெண்களுக்கு</li>
       <li>மனம் மற்றும் உடல்-சந்திரன்.</li>
    </ul>
    <h3><u>ஆயுள் பாவம்:-</u></h3>
    <h3><u>ஆய்வுக்காரணிகள்</u></h3>
    <p>ஆயுள் பாவம் பலம் கணிக்கும் பொழுது,கிழ்கண்ட கிரக நிலைகளை நாம் கணக்கில் கொள்ள வேண்டும். </p>
    <ul>
      <li>•	லக்னம்</li>
      <li>•	லக்னாதிபதி</li>
      <li>•	8ஆம்இடம் மற்றும் 8ம் இட அதிபதி</li>
      <li>•	3ஆம்இடம் மற்றும் 3ம் இட அதிபதி</li>
      <li>•	ஆயுள்காரகன் சனி</li>
      <li>•	குரு மற்றும் சுக்கிரன் தொடர்பு</li>
      <li>•	சந்திரன் நிலை - உடல் மற்றும் மனக்காரகன்</li>
      <li>•	ராகு-மரணகாரகன்</li>
      <li>•	கேது-மோட்சகாரகன்</li>
      <li>•	பாதகம் மற்றும்பாதகாதிபதி</li>
      <li>•	மாரகம் மற்றும் மாரகாதிபதி</li>
      <li>•	பாவர்கள் தொடர்பு</li>
      <li>•	பிரசன்னத்தில் மாந்தி</li>
    </ul>
    <h3><u>ஆயுள் கணிக்க முறை:</u></h3>
    <h4><u>கால சக்கர தசை வருடங்கள்</u></h4>
    <ul>
      <li>மேஷம்-7 வருடங்கள்</li>
      <li>ரிஷபம் -16 வருடங்கள்</li>
      <li>மிதுனம்-9 வருடங்கள்</li>
      <li>கடகம்- 21 வருடங்கள்</li>
      <li>சிம்மம்- 5 வருடங்கள்</li>
      <li>கன்னி- 9 வருடங்கள்</li>
      <li>துலாம்-16 வருடங்கள்</li>
      <li>விருச்சிகம்-7 வருடங்கள்</li>
      <li>தனுசு - 10 வருடங்கள்</li>
      <li>மகரம்- 4 வருடங்கள்</li>
      <li>கும்பம்- 4 வருடங்கள்</li>
      <li>மீனம்-10 வருடங்கள்</li>
    </ul>
    <h2>விதிகள்:</h2>
      <ul style={{ listStyleType: 'square'}}>
        <li>லக்னத்தில் இருந்து கிரகங்கள் இருக்கும் ராசிக்குரிய எண்களை கூட்ட வேண்டும்.</li>
        <li>ஒரு ராசியில் இரண்டு கிரகங்கள் இருந்தால், அதே எண்ணை கூட்ட வேண்டும்.மூன்று கிரகங்கள் இருந்தால் அதில் பாதியை கழிக்க வேண்டும்.</li>
        <li>மூன்றுக்கு மேற்பட்ட கிரகங்கள் இருந்தால், அந்த ராசி எண்ணின் பாதியை ஒவ்வொரு கிரகத்துக்கும் அந்த ராசி எண்ணோடு கூட்ட வேண்டும்.</li>
        <li>பாவிகள் 3-11இல் இருந்தால்,அந்த ராசிக்குரிய எண்ணை கழிக்க வேண்டும்.</li>
        <li>லக்னம்,எட்டாம் பாவத்தில் உச்சம் பெற்ற கிரகம் இருந்தால் அந்த கிரகத்தின் ராசிக்குரிய எண்ணை கூட்ட வேண்டும்.</li>
        <li>ஆயுள்காரகன் சனியை பாவிகள் பார்த்தால்,அந்த ராசியில் பாதியை கழிக்க வேண்டும்.</li>
        <li>லக்னத்தில் கிரகம் இருந்தாலும்,இல்லாவிட்டாலும் அந்த எண்ணை கூட்ட வேண்டும்.</li>
        <li>குறைந்த ஆயுள், தற்கொலை,எதிர்பாராத இறப்புக்கு உண்டான பாவங்கள் பிறப்பு ஜாதகத்தில் வலுபெறும் பொழுது,ஆயுள் இந்த கணிதத்தில் இருந்து குறையும் அல்லது மாறுபடும்.</li>
        <li>நீண்ட ஆயுளுக்கு உண்டான பாவங்கள் பிறப்பு ஜாதகத்தில் வலுபெறும் பொழுது,ஆயுள் இந்த கணிதத்தில் இருந்து அதிகரிக்கும் அல்லது மாறுபடும்.</li>
      </ul>
      <h2>பொருளாதார யோகம்:</h2>
      <h3>ஆய்வுக்காரணிகள்</h3>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        <li>• சுக்கிரன்+சனி இணைவு</li>
        <li>• சுக்கிரன்+குரு இணைவு</li>
        <li>• குரு+சனி தொடர்பு</li>
        <li>• குரு+சுக்கிரன் தொடர்பு</li>
        <li>• குரு+சுக்கிரன்+புதன் தொடர்பு</li>
        <li>• சுக்கிரன்+புதன்+சனி தொடர்பு</li>
      </ul>
      <p>
        மேற்கண்ட கிரகங்களின் பாவ தொடர்பு,சேர்க்கை தொடர்பு,பார்வை தொடர்பு, 
        தசா,புக்தி தொடர்பு காலங்களில் பொருளாதாரம் உயரும்.
      </p>
      <h2>கீழ்கண்ட பாவங்களின் இணைவு மற்றும் பலம்</h2>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        <li>லக்னம் பலம் மற்றும் யோகங்களில் தொடர்பு</li>
        <li>2+9+11</li>
        <li>1+9+11</li>
        <li>1+2+11</li>
        <li>1+5+2</li>
        <li>1+5+2+11</li>
        <li>1+5+9+11</li>
        <li>5+8+2+11</li>
        <li>8+11+2</li>
        <li>1+2, 1+11, 2+11</li>
      </ul>
      <p>மேற்கண்ட பாவதொடர்பு காலங்களில் பொருளாதாரம் உயரும்.</p>
      <h2>கிரகங்கள் தரும் யோகங்கள்</h2>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        <li>• சந்திர மங்கள யோகம்</li>
        <li>• நீச பங்க ராஜ யோகம்</li>
        <li>• விபரீத ராஜ யோகம்</li>
        <li>• வசுமதி யோகம்</li>
        <li>• இந்து லக்னம்</li>
        <li>• முத்திரீகா ராஜ யோகம் (இந்து லக்னாதிபதி இந்து லக்னத்தில் அமர்தல் மற்றும் உச்சம், நீச்சம்)</li>
        <li>• குரு மங்கள யோகம்</li>
        <li>• யோக தசா புக்தி காலங்கள்</li>
      </ul>
      <p>மேற்கண்ட கிரக தசாபுக்தி யோக காலங்களில் பொருளாதாரம் உயரும்.</p>
      <h2>ராகு, கேது தரும் பெரும் ராஜ யோகங்கள்</h2>
      <ul style={{ listStyleType: 'none', padding: 0,textIndent:'10px' }}>
        <li>• மகா பத்ம யோகம் (குரு தொடர்பு ராகுவுக்கு 1,5,7,9 மற்றும் நட்சத்திரம்)</li>
        <li>• பத்ம யோகம் (சுக்கிரன் தொடர்பு ராகுவுக்கு 1,5,7,9 மற்றும் நட்சத்திரம்)</li>
        <li>• சங்க யோகம் (புதன் தொடர்பு ராகுவுக்கு 1,5,7,9 மற்றும் நட்சத்திரம்)</li>
        <li>• கால மிருத யோகம் (ராகு, கேதுக்கு இடையில் லக்னம் உள்பட அனைத்து கிரகம் இருத்தல்)</li>
      </ul>
      <h2>கல்விக்கான கிரக அமைப்புகள்</h2>
      <ul style={{ listStyleType: 'none', padding: 0 }}>
        <li>• ஆய்வுக்காரணிகள்</li>
        <li>• காரகம் கிரகம்: புதன், குரு</li>
        <li>• புத்திகூர்மை மற்றும் சிறந்த அறிவாற்றல் கிரகம்: கேது</li>
        <li>• உடல் வலு மற்றும் சூழ்நிலைகளை காரணிகள்: லக்னம்</li>
        <li>• நர்சரி & ஆரம்ப கல்வி: இரண்டாம் பாவம் (L.K.G TO 5TH STD)</li>
        <li>• உயர் & மேல் நிலை பள்ளி கல்வி & இளநிலை கல்லூரி: நான்காம் பாவம் மற்றும் புதன்</li>
        <li>• முதுநிலை படிப்பு: ஒன்பதாம் பாவம் மற்றும் குரு</li>
        <li>• ஆராய்ச்சி படிப்பு: 11 AND 12 TH பாவம், குரு, கேது</li>
        <li>• சிந்தித்தல் மற்றும் நினைவாற்றல்: 1, 5ம் பாவம், சந்திரன்</li>
        <li>• வெளிநாட்டு படிப்பு: 9 & 12 ம் பாவம், ராகு, சநி, குரு, புதன்</li>
        <li>• கல்வியில் தடை: 3, 8ம் பாவம், சனி, ராகு, கோட்சாரத்தில் சனி ஜென்ம ராசி, நான்காம் பாவம் தொடர்பு மற்றும் புனர்பூ தோஷம்.</li>
        <li>• கல்விக்கு கூடுதல் சாதக நிலை: தசா, புக்தி மற்றும் கோட்சார கிரகங்களின் உதவி முக்கியம். (குரு, புதன், கேது)</li>
        <li>• கல்வியின் தடைகள் மற்றும் பலன்கள்</li>
      </ul>
      <h2>யோகங்கள்</h2>
      <p>
        A. நிபுண யோகம்: சூரியன் + புதன் சேர்க்கை
      </p>
      <p>
        B. சரஸ்வதி யோகம்: புதன் + குரு, சுக் + புத, சுக் + குரு, புத + குரு + சுக் சேர்க்கை 1, 2, 4, 5, 7, 9, 10 ம் இருத்தல்
      </p>
      <p>
        C. பாஸ்கரா யோகம்: இது ஒரு நல்ல யோகம். ஆனால் அரிதான யோகம். மூன்று நிலைப்பாடுகள் சம்பந்தப்பட்டுள்ளதால் அரிதானதாகத் தோன்றும்.
      </p>
      <p>
        D. சங்கீத வித்யா யோகம்: 2, 5ம் பாவம் + சுக்கிரன் சேர்க்கை
      </p>
      <p>
        E. கலாநிதி யோகம்: குரு + புதன் சேர்க்கை மற்றும் 2 or 5இல் சுக்கிரன்
      </p>
      <h2>கல்வியில் தடை மற்றும் உயர்கல்வி</h2>
      <p>
        3ம் பாவத்தில் சனி, ராகு மற்றும் 8ம் பாவத்தில் சனி, ராகு.
      </p>
      <p>
        தொழில் முறை கல்வி: 9, 10, 11ம் பாவம் தொடர்பு
      </p>
      <p>
        வெளிநாட்டு கல்வி: 9, 11, 12 பாவம் தொடர்பு
      </p>
      <p>
        இன்ஜினியரிங் கல்வி: 4, 9, 10ம் பாவம் தொடர்பு மற்றும் சனி, செவ்வாய்
      </p>
      <p>
        மருத்துவ கல்வி: சூரி, செவ், கேது 1+6+9+10+12ம் பாவ தொடர்பு
      </p>
      <p>
        சட்ட கல்வி: 4+9+6+2+3ம் பாவங்களுடன் சனி, புதன், கேது தொடர்பு
      </p>
      <p>
        விவசாயம் கல்வி: 4+6+9ம் பாவங்களுடன் சந், சுக்கிரன், செவ் தொடர்பு
      </p>
      <p>
        சினிமா, ஓவியம், கலை கல்வி: 5, 10ம் பாவங்களுடன் சுக்கிரன், ராகு தொடர்பு
      </p>
      <p>
        ஆசிரியர் கல்வி: 2, 9, 10ம் பாவங்களுடன் புதன், குரு தொடர்பு
      </p>
      <p>
        கம்ப்யூட்டர் PROGRAMMER: 2, 3, 11ம் பாவங்களுடன் புதன், கேது, தொடர்பு
      </p>
      <p>
        வர்த்தகம், வணிக மேலாண்மை, கம்பெனியின் செயலர் படிப்பு: 3, 12இல் புதன்
      </p>
      <p>
        தகவல் தொடர்பு, இன்ஜினியரிங், விளம்பர மேலான்மை, பத்திரிகை, மக்கள் தொடர்பு சம்பந்தமான படிப்பு: 3, 9இல் புதன்
      </p>
      <p>
        போட்டி தேர்வு மூலம் கல்வி கிடைத்தல்: 1+6+11ம் பாவங்கள் தொடர்பு
      </p>
      <h2>கல்வி டிப்ஸ்</h2>
      <p>
        சிவில்/ARCHI/INTERIOR இன்ஜினியரிங் படிப்பு: 1, 4ம் பாவம் புதன்+சுக்கிரன், புதன்+செவ்வாய், சுக்+புதன்+செவ் தொடர்பு மற்றும் தசாபுத்தி
      </p>
      <p>
        புத+குரு+ராகு= தனியார் வங்கி பணி (AXIS, ICICI, MUTHOOT, ADITYA BIRLA, TVS FINANCE ETC,,,)
      </p>
      <p>
        புதன்+குரு = வங்கி தொடர்பான படிப்பு
      </p>
      <p>
        புத+குரு+சூரி= வங்கியில் உயர்பதவி (அரசாங்கம், தனியார்)
      </p>
      <p>
        செவ்வாய்= இயற்பியல்
      </p>
      <p>
        4,9ம் பாவங்களுடன் செவ்வாய் தொடர்பு பெற்றால்= No Stage Fear
      </p>
      <p>
        4,9ம் பாவங்களுடன் சூரியன் தொடர்பு பெற்றால்= Class Leader or TEAM Leader
      </p>
      <p>
        4,9ம் பாவங்களுடன் சுக்கிரன் தொடர்பு பெற்றால்= Presentation Skill
      </p>
      <p>
        4,9ம் பாவங்களுடன் சந்திரன் தொடர்பு பெற்றால்= சீரான மனநிலை
      </p>
      <p>
        4,9ம் பாவங்களுடன் புதன் தொடர்பு பெற்றால்= ORGANISER
      </p>
      <p>
        4,9ம் பாவங்களுடன் ராகு தொடர்பு பெற்றால்= Latest Technology, AERONATICAL
      </p>
      <h2>தொழிலுக்கான கிரக அமைப்புகள்</h2>
      <h3>ஆய்வுக்காரணிகள்</h3>
      <p>வேலை(சர்வீஸ்/உத்தியோகம்): பாவங்கள் 2,6,10, சூரியன், செவ்வாய், குரு</p>
      <p>வியாபாரம்/தொழில்: பாவங்கள் 2,3,7,10, சனி, புதன்தொடர்பு + குரு</p>
      <p>அரசாங்க வேலை: சூரி + 10ம் பாவம், சனி + குரு</p>
      <p>வியாபார நஷ்டம்: சனி, 10ம் அதிபதி, காரககிரகம், சந்திரன் பலம் இல்லாமை</p>
      <p>கலைத்துறை(சினிமா/ஆர்ட்): 10, 3, 5ம் பாவம், சுக், புதன் + ராகு தொடர்பு</p>
      <p>ஆசிரியர் வேலை: பாவங்கள் 10, 2, 4, 6, 9, புதன், குரு தொடர்பு</p>
      <p>ரியல் எஸ்டேட் + கமிசன் தொழில்: பாவங்கள் 10, 4, 3, 12, செவ் + புதன்தொடர்பு</p>
      <p>தொழிற்சாலை அதிபதி: பாவங்கள் 10, 1, 7, 11, சனி, குரு தொடர்பு</p>
      <p>வழக்கறிஞர்/நீதிபதி: மிதுனம், துலாம், கும்பம், தனுசு சட்ட துறையைக்குறிம் ராசிகள் ஆகும். மூன்றாம் பாவம் நீதி மன்றத்தை குறிக்கும்.</p>
      <p>பாவங்கள் 10, 4, 6, 9, 2, 3 மற்றும் குரு, சனி, புதன், கேது தொடர்பு</p>
      <h2>விவசாயி ஆகும் அமைப்பு</h2>
      <p>பாவங்கள் 10, 3, 4, 7, 9 மற்றும் செவ், சந், சனி, சுக் தொடர்பு.</p>
      <p>ராசிகள்: ரிஷபம், கடகம், கன்னி, விருச்சிகம், மகரம் அமைவது.</p>
      <h2>இன்ஜினியரிங் ஆகும் அமைப்பு</h2>
      <p>பாவங்கள் 10, 3, 4, 7, 9 மற்றும் செவ்+சனி, சனி+புத, சூரி+புத, செவ்+சனி+புத தொடர்பு.</p>
      <h2>மருத்துவர் ஆகும் அமைப்பு</h2>
      <p>பாவங்கள் 10, 6, 9, 12 மற்றும் சூரி, செவ், கேது/ராகு தொடர்பு.</p>
      <h2>சாப்ட்வேர் Engineer ஆகும் அமைப்பு</h2>
      <p>பாவங்கள் 10, 3, 9 மற்றும்புதன், சந்திரன், ராகு தொடர்பு.</p>
      <h2>கடிதம், தொலை தொடர்பு வேலை</h2>
      <h2>கம்ப்யூட்டர் மென்பொருள் வடிவமைப்பு</h2>
      <p>பாவங்கள் 10, 3, 11, 5 மற்றும்புதன், கேது, சந், ராகு தொடர்பு.</p>
      <h2>வாகன விற்பனையாளர்</h2>
      <p>பாவங்கள் 10, 4, 3, 7, 12 மற்றும்புதன், சுக்கிரன், சந்திரன் தொடர்பு.</p>
      <h2>கட்டிடகலைநிபுணர்/ Engineer</h2>
      <p>பாவங்கள் 10, 4, 7 மற்றும் செவ், புத, சுக்கி தொடர்பு.</p>
      <h2>போக்குவரத்து, கப்பல், விமானம்</h2>
      <p>பாவங்கள் 10, 4, 8, 9, 11, 12 மற்றும்புதன், ராகு, சந்திரன், சனி தொடர்பு.</p>
      <h2>கான்ட்ராக்டர்</h2>
      <p>பாவங்கள் 10, 3, 9 மற்றும் குரு, சனி, புத, செவ் தொடர்பு.</p>
      <h2>வங்கி தொழில்</h2>
      <p>பாவங்கள் 10, 2, 3, 7, 11 மற்றும் புதன்+குரு, சுக்+குரு, புத, குரு, சூரி தொடர்பு.</p>
      <h2>சேல்ஸ்மேன்/ பிரதிநிதி</h2>
      <p>பாவங்கள் 10, 2, 3, 7, 9 மற்றும் புத, சந், ராகு, குரு தொடர்பு.</p>
      <h2>திருடன்</h2>
      <p>பாவங்கள் 10, 1, 8, 12 மற்றும் ராகு, சுக் தொடர்பு.</p>
      <h2>ரகசிய தொழில்/திட்டம்</h2>
      <p>பாவங்கள் 10, 4, 8, 12 மற்றும் சனி, கேது, சனி, ராகு, புத தொடர்பு.</p>
      <h2>வேலையில் இடமாற்றம்</h2>
      <p>பாவங்கள் 10, 3, 8, 12 மற்றும் செவ்+ராகு, சந்+ராகு தொடர்பு.</p>
      <h2>வேலையில் சஸ்பென்ட்/நிறுத்தி வைத்தல்</h2>
      <p>பாவங்கள் 10, 1, 5, 9 மற்றும் செவ்+கேது, சூரி+கேது, சனி+கேது தொடர்பு.</p>
      <h2>வேலையில் பதவி உயர்வு</h2>
      <p>பாவங்கள் 6, 10, 1, 11, 3 மற்றும் செவ் + குரு, சூரி + குரு, சனி + குரு தொடர்பு.</p>
      <h2>அரசியல் வெற்றி</h2>
      <p>பாவங்கள் 10, 5, 1, 6, 9, 11 மற்றும் செவ், சூரி, குரு, சனி, சந் தொடர்பு.</p>
      <h2>விளையாட்டு</h2>
      <p>பாவங்கள் 10, 5, 1, 6, 9, 3, 11 மற்றும் செவ், சூரி, சந், குரு, சனி தொடர்பு.</p>
      <h2>போலீஸ், ராணுவம்</h2>
      <p>பாவங்கள் 10, 1, 6, 3 மற்றும் செவ், சூரி தொடர்பு.</p>
      <h2>ஜவுளி கடை</h2>
      <p>பாவங்கள் 10, 2, 3, சுக்கிரன், புதன் தொடர்பு.</p>
      <h2>ஜவுளி தொழில்</h2>
      <p>பாவங்கள் 10, 2, 3, சுக்கிரன், சனி, கேது தொடர்பு.</p>
      <h2>திருமண பாவம் ஆய்வு</h2>
      <p>காரக கிரகம்: சுக்கிரன், செவ்வாய், புதன், குரு</p>
      <p>காரக பாவம்: 2-குடும்பம், 7-களத்திரம், 8-மாங்கல்யம், சீதனம், 12-படுக்கை சுகம், 3-வீரியம், காமம், 9-பாக்கியம், இரண்டாவது திருமணம், 5-காதல், 11-அதீத காமம், எண்ணங்கள் நிறைவேறுதல்</p>
      <p>மறுக்கும் கிரகங்கள்: ராகு, கேது, சனி, செவ்வாய், நீச்ச மற்றும் உச்ச கிரகங்கள், பாதகாதிபதி தொடர்பு</p>
      <p>4,6ம் பாவம் கெடுதல்,1,10ம் பாவம் கெடுதல்,வக்கிர கிரகங்கள், அஸ்தமனம்,காரக கிரகம் யுத்தங்கள்,பகை,பாவ கிரக இணைவு தொடர்பு பெறுதல்.</p>
      <h2>சூரி + சுக் தொடர்பு</h2>
      <p>0°-3° முதல் 40° வரை இருந்தால் விரைவு திருமணம், இளம் வயதில் திருமணம்/காதல் காணப்படலாம்.</p>
      <h2>திருமணம் தொடர்பான பாவங்கள்</h2>
      <h3>திருமணம்:</h3>
      <p>பாவங்கள் 7,1,2,11 மற்றும் சுக்கிரன், செவ்வாய் (பெண்), குரு தொடர்பு.</p>

      <h3>காதல் திருமணம்:</h3>
      <p>பாவங்கள் 7,1,3,5,11 மற்றும் ராகு, சுக், புத, கேது, மிதுனம், கன்னி, கடகம், துலாம், ரிஷபம், கும்பம் ராசிகள் தொடர்பு.</p>

      <h3>காதல் நிச்சயிக்கபட்ட திருமணம்:</h3>
      <p>பாவங்கள் 7,1,2,5,11 மற்றும் சுபகிரகங்கள் தொடர்பு.</p>

      <h3>திருமணத்தில் மகிழ்ச்சி:</h3>
      <p>பாவங்கள் 7,2,5,11 மற்றும் சுபகிரகங்கள் தொடர்பு.</p>

      <h3>ஒருவருக்குகொருவர் விட்டு கொடுத்து வாழ்தல்:</h3>
      <p>பாவங்கள் 7,5,12 மற்றும் சந்திரன், குரு தொடர்பு.</p>

      <h3>திருமணத்தில் தொந்தரவு:</h3>
      <p>பாவங்கள் 7,1,6,10 மற்றும் சுக், செவ்வாய் உடன் பாவ கிரக தொடர்பு.</p>

      <h3>கணவன், மனைவி பிரிந்து வாழுதல்:</h3>
      <p>பாவங்கள் 7,1,3,9 மற்றும் சுக், செவ்வாய் உடன் பாவ கிரக தொடர்பு.</p>

      <h3>விவாகரத்து:</h3>
      <p>பாவங்கள் 7,3,6,8,12 மற்றும் பாவ கிரக தொடர்பு.</p>

      <h3>தற்காலிக பிரிவு:</h3>
      <p>பாவங்கள் 7,3,9 மற்றும் சுக், செவ்வாய் உடன் பாவ கிரக தொடர்பு.</p>

      <h3>விவாகரத்து பெறுவது:</h3>
      <p>பாவங்கள் 7,3,6,8,12 மற்றும் சுபகிரக தொடர்பு.</p>

      <h3>இரண்டாவது திருமணம்:</h3>
      <p>பாவங்கள் 7,9,2,11 தொடர்பு.</p>

      <h3>இரண்டு மனைவி:</h3>
      <p>பாவங்கள் 7,9,2,5,1,9 மற்றும் சுக்கிரன், செவ்வாய், சுபகிரக தொடர்பு.</p>

      <h3>திரும்ப சேருவது:</h3>
      <p>பாவங்கள் 7,11,2,5,1,9 மற்றும் சுபகிரகதொடர்பு.</p>

      <h3>தவறான உறவு:</h3>
      <p>பாவங்கள் 7,5,1,9 மற்றும் குரு+சுக் இணைவு, பார்வை, சுக்+ராகு, செவ்+ராகு, சந்+குரு 8,12,3 தொடர்பு, செவ்+சுக்+ராகு தொடர்பு.</p>    
    <h3><u>உறவுகளின் பிரச்சினைகள்: </u></h3>
    <p>பாவங்கள் 7,6,1,12 மற்றும்மூன்றாம்மிடஅதிபதி பாவகிரக தொடர்பு.</p>
    <h3><u>கணவன்,மனைவி பிரியம் இல்லாமல் சேர்ந்து வாழுதல்: </u></h3>
    <p>பாவங்கள் 7,4,10மற்றும் சுப கிரக தொடர்பு.</p>
    <h3><u>புத்திர பாக்கியத்திற்காண ஆய்வுக்காரணிகள் :-</u></h3>
    <p>காரக கிரகம்:சூரியன்,சுக்கிரன்,செவ்வாய்,சந்திரன்</p>
    <u>பாவங்கள்:</u>
    <ul>
      <li>ஆண் 1,5 மற்றும் துணை பாவம் 11,2</li>
      <li>பெண் 1,9 மற்றும் துணை பாவம் 11,2</li>
      <li>வீரியம்-பாவங்கள் 3,7,12</li>
      <li>படுக்கை சுகம்-4,12</li>
      <li>மறுக்கும் கிரகங்கள்-சனி,புதன்,ராகு,கேது</li>
      <li>மறுக்கும் பாவங்கள்-4,6,10,8</li>
      <li>மறுக்கும் ஜாதக அமைப்புகள்:
       நீச்சம்,வக்ரம்,அஸ்தமனம்,யுத்ததோல்வி,பித்ருதோஷம்,கிரகங்கள் சனி,புதன்,ராகு,கேது,பாதகாதிபதி நட்சத்திரத்தில் அமர்வது,பாதகாதிபதி இணைவு மற்றும் பார்வை.
      </li>
      <li>இரண்டாவது குழந்தை-பாவங்கள் 5,7,2,11</li>
      <li>மூன்றாவது குழந்தை- பாவங்கள் 5,9,2,11</li>
      <li>குழந்தை பிறப்பு-இயற்கை= பாவங்கள் 5,8,1,11 மற்றும் குரு,5மிட அதிபதி சுபகிரக தொடர்பு</li>
      <li>குழந்தை பிறப்பு-கஷ்டம்= பாவங்கள் 5,8,1,12(செவ்) மற்றும் குரு,5மிட அதிபதி பாவ கிரக தொடர்பு</li>
      <li>இரட்டை குழந்தை- பாவங்கள் 2,5,7,11</li>
      <li>குழந்தையின்மை- பாவங்கள் 5,4,10,8,12</li>
      <li>குழந்தை ஊனம்- பாவங்கள் 5,10,12,4 மற்றும் சனி ,ராகு ,கேது தொடர்பு</li>
      <li>கருகலைதல்- பாவங்கள் 5,4,10,12 சனி ,ராகு ,கேது தொடர்பு </li>
      <li>குழந்தை நோய்வாய்படல்- பாவங்கள் 5,10,12</li>
      <li>ஆரோக்கியமில்லாத குழந்தை- பாவங்கள் 5,10,12</li>
    </ul>
    <h2>மருத்துவ ஜோதிடம்:
      நோய்க்கான காரணங்கள்:
     </h2>
   <h3><u>1.மறைமுக காரணங்கள்:</u></h3>
   <ul>
    <li>ஆரோக்கியமற்ற சூழல்கள்</li>
    <li>வம்சாவழி</li>
    <li>சரியற்ற வாழ்க்கை முறை</li>
    <li>அதிதீதங்கள்</li>
    <li>குறைபாடு உடையசுற்றுபுறங்கள்</li>
   </ul>
   <h3><u>2.நேரடி காரணங்கள்:</u></h3>
   <ul>
    <li>சீதோசனங்கள்</li>
    <li>கடுங்குளிர்/வெப்பம்</li>
    <li>தொற்றுகள்</li>
    <li>விஷங்கள்</li>
    <li>விபத்துகள்</li>
    <li>உணவு முறை </li>
   </ul>
   <h3><u>ஆய்வுக்காரணிகள்</u></h3>
   <ul>
    <li>12 ராசிகள் மற்றும் பாவங்களில் உள்ள உடல் அங்க அவையங்கள்</li>
    <li>நோய்க்கு காரகர்- செவ்வாய்,சனி,ராகு,கேது</li>
    <li>நோய்க்கு உரிய பாவம்-6,8,12 +1ம் பாவம் தொடர்பு</li>
    <li>ஆரோக்கியம் 1,5,9 மற்றும் குரு,சுக்கிரன் தொடர்பு</li>
    <li>மருந்துகள்-9ம்பாவம்,சூரியன்</li>
    <li>மருத்துவர்-7ம் பாவம்</li>
    <li>நோயிலிருந்து விடுதலை-5,11</li>
    <li>தலை நோய்-சூரியன்,செவ்வாய் ,1 + பாபர்கள்    </li>
    <li>சூரியன்+சனி தொடர்பு- எலும்பு முறிவு</li>
    <li>செவ்வாய்+சனி,பாபர்கள் - ரத்த அணுக்கள் குறைபாடு</li>
    <li>குரு,5 +சனி,6,8,12,பாதகாதிபதி-கல்லீரல் நோய் ,மூக்கு   </li>
    <li>செவ்வாய்+ராகு, பாபர்கள் -  பல் நோய் </li>
    <li>சுக்கிரன்+ பாபர்கள் - கனையம்,சிறுநீரகம் நோய்,ஹார்மோன்     </li>
    <li>புதன் 1,3 + பாபர்கள் -தோல் வியாதி,நுரையீரல்,நரம்புகள்,கை விரல்கள் </li>
    <li>இருதயம் நோய் -சந்திரன்,சூரியன்,கடகம்,4ம்பாவம்+ பாபர்கள் </li>
    <li>சனி ,8 + பாபர்கள் - மலக்குடல்,கழிவுகள்,மூட்டு வலி,தாடை </li>
    <li>சந்திரன்+ சனி   + பாபர்கள் – நுரையீரல் நோய் </li>
    <li> ராகு,கேது-காது ,குடல் தொற்று நோய்கள்,குஷ்டம்,புற்று நோய்  </li>
    <li>அவயங்கள் துண்டிக்கபடுதல்-12,6,8ம் பாவங்கள்</li>
    <li>நீண்ட நோய்-1,6,8ம் பாவங்கள்</li>
    <li>வீட்டில் சிகிச்சை-1,6,4ம் பாவம்</li>
    <li>அறுவை சிகிச்சை-6,8,12ம் பாவங்கள்</li>
    <li>மருத்துவ செலவு-12,1,6,12ம் பாவங்கள்</li>
    <li>மருத்துவமனை-1,6,12 தொடர்பு.</li>
   </ul>
   <h3>கிரகங்கள் குறிப்பிடும் உடல் பாகங்கள்:</h3>
      <h3>சூரியன்:</h3>
      <p>ஆண்களுக்கு வலது கண், பெண்களுக்கு இடது கண், இருதயம், சுத்தமான ரத்தம். முதுகு எலும்பு, எலும்பு மஜ்ஜை</p>
      
      <h3>சந்திரன்:</h3>
      <p>பெண்களுக்கு வலது கண், ஆண்களுக்கு இடது கண், நுரையீரல், உடலில் உள்ள திரவ பொருட்கள், மார்பகம்.</p>
      
      <h3>செவ்வாய்:</h3>
      <p>அசுத்தமான ரத்தம், கழிவுப் பொருட்கள், மலத்துவாரம், உடல் வெப்பம், மூளை, இருதயத்தின் இயங்கும் திறன், கருப்பை, பற்கள், நகம்</p>
      
      <h3>புதன்:</h3>
      <p>விலா எலும்பு, நரம்பு, இடுப்பு, உடலின் அமைப்பு</p>
      
      <h3>குரு:</h3>
      <p>ஜீரண உறுப்புகள், புதிய வளர்ச்சி, சதைப்பற்றும் பகுதி, தொடை பகுதி, மூக்கு</p>
      
      <h3>சுக்கிரன்:</h3>
      <p>கருப்பை, சிறுநீரகம், பிறப்பு உறுப்புகள் உள்ள சுரப்பிகள், கன்னம்</p>
      
      <h3>சனி:</h3>
      <p>கழிவு உறுப்புகள், தாடை</p>
      
      <h3>ராகு:</h3>
      <p>காது, குடல், அலர்ஜி, புற்று நோய்</p>
      
      <h3>கேது:</h3>
      <p>குடல், வளர்ச்சியை தடுத்தல், நோய் எதிர்ப்பு சக்தியை குறைத்தல்.</p>
      <Image
          src="col1.png"
          class="img-fluid"
          object="fill"
          alt="mainPic"
          width="100%"
          height="100%"
          style={{padding:'50px'}}
        />
           <Image
          src="col2.png"
          class="img-fluid"
          object="fill"
          alt="mainPic"
          width="100%"
          height="100%"
          style={{padding:'50px'}}
        />
           <Image
          src="col3.png"
          class="img-fluid"
          object="fill"
          alt="mainPic"
          width="100%"
          height="100%"
          style={{padding:'50px'}}
        />
           <Image
          src="col4.png"
          class="img-fluid"
          object="fill"
          alt="mainPic"
          width="100%"
          height="100%"
          style={{padding:'50px'}}
        />
    </div>
    </Container>
  );
};

export default VasthuArticalTamil;
