import React, { useState } from "react";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FaArrowCircleUp } from "react-icons/fa";
// import { Button } from './Styles';

const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 200) {
      setVisible(true);
    } else if (scrolled <= 200) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  window.addEventListener("scroll", toggleVisible);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Scroll to top
    </Tooltip>
  );

  return (
    <>
      {visible ? (
        <OverlayTrigger
          placement="left"
          delay={{ show: 10, hide: 10 }}
          overlay={renderTooltip}
        >
          <Button
            className="all_btn"
            style={{
              marginLeft: "10px",
              boxShadow:
                "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
            }}
          >
            <FaArrowCircleUp
              onClick={scrollToTop}
              style={{ display: visible ? "inline" : "none" }}
            />
          </Button>
        </OverlayTrigger>
      ) : null}
    </>
  );
};

export default ScrollButton;
