import React from "react";
import PropTypes from 'prop-types'
const Title = (props) => {
  return (
    <div className="m-1 py-1 all_background">
      <center>
        <h3>{props.title}</h3>
      </center>
    </div>
  );
};
Title.propTypes = {
  title: PropTypes.string,
};
export default Title;
