import React, { useState, useEffect } from "react";
import { Card, Row, Col, Container, Button } from "react-bootstrap";
import { Tab, Tabs } from "react-bootstrap";
import BarChart from "../component/BarChart";
import PieChart from "../component/PieChart";
import { UseAuth } from "../context/Auth";
import { vasthuUrl } from "../component/Api";
// import ProfessionalVsProfVsClient from "./ProfessionalVsProfVsClient";
import { useTranslation, Trans } from "react-i18next";
import { useNavigate } from "react-router-dom";

const ProfessionalDash = () => {
  const { t } = useTranslation();
  // const { userId } = UseAuth();
  const navigate = useNavigate();
  const userId = window.sessionStorage.getItem("userId");
  const token = window.sessionStorage.getItem("token");
  const [profChartData, setProfChartData] = useState([
    ["Rating", "User Ratings"],
    ["5 STARS", 2000],
    ["4 STARS", 1170],
    ["3 STARS", 660],
    ["2 STARS", 103],
    ["1 STAR", 170],
  ]);
  const [credentialCount, setCredentialCount] = useState({});
  const [star5, setStar5] = useState({});
  const [star4, setStar4] = useState({});
  const [star3, setStar3] = useState({});
  const [star2, setStar2] = useState({});
  const [star1, setStar1] = useState({});
  console.log("credentialCount", credentialCount);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  // const [chatDatas, setChatDatas] = useState([]);
  // // console.log("chatDatas", chatDatas);

  // useEffect(() => {
  //   try {
  //     vasthuUrl
  //       .get(`/initiateChat/getchatdetailsforconsultant/${userId}`, {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       })
  //       .then((res) => {
  //         // console.log("11145", res.data);
  //         setChatDatas(res.data);
  //       });
  //   } catch (e) {
  //     console.log("SERVER ERROR");
  //   }
  // }, []);

  useEffect(() => {
    setLoading(true);
    console.log("prof token", token);
    try {
      vasthuUrl
        .get(`/getmeetingdetailscount/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          setCredentialCount(res.data);
          setLoading(false);
        });
    } catch (e) {
      alert("SERVER ERROR");
      setLoading(false);
    }
    try {
      vasthuUrl
        .get(`/usersreviewforconsultantbyuserrating/${userId}/5`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          setStar5(res.data.length);
          setLoading(false);
        });
    } catch (e) {
      alert("SERVER ERROR");
      setLoading(false);
    }
    try {
      vasthuUrl
        .get(`/usersreviewforconsultantbyuserrating/${userId}/4`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setStar4(res.data.length);
          setLoading(false);
        });
    } catch (e) {
      alert("SERVER ERROR");
      setLoading(false);
    }
    try {
      vasthuUrl
        .get(`/usersreviewforconsultantbyuserrating/${userId}/3`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setStar3(res.data.length);
          setLoading(false);
        });
    } catch (e) {
      alert("SERVER ERROR");
      setLoading(false);
    }
    try {
      vasthuUrl
        .get(`/usersreviewforconsultantbyuserrating/${userId}/2`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setStar2(res.data.length);
          setLoading(false);
        });
    } catch (e) {
      alert("SERVER ERROR");
      setLoading(false);
    }
    try {
      vasthuUrl
        .get(`/usersreviewforconsultantbyuserrating/${userId}/1`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setStar1(res.data.length);
          setLoading(false);
        });
    } catch (e) {
      alert("SERVER ERROR");
      setLoading(false);
    }
  }, []);

  const alert = (msg) => {
    setMessage(msg);
    setTimeout(() => {
      setMessage("");
    }, 4000);
  };

  const barOptions = {
    // chart: {
    //   title: "Your Performance",
    //   subtitle: "Based on User Ratings",
    // },
    bars: "horizontal",
  };

  return (
    <Card
      className="d-flex p-3 all_border mb-5"
      style={{ width: "100%", height: "auto" }}
    >
      <Row
        className="ProfessionalDash  "
        style={{ width: "100%", height: "auto", paddingLeft: 25 }}
      >
        {/* <Col sm={12} md={4}>
          <Card
            bg="light"
            key="light"
            style={{ width: "100%", height: "auto" }}
            className="text-center my-1 mb-2 all_border"
          >
            <Card.Header className="all_background" as="h6">
              Chat
            </Card.Header>
            <Card.Body>
              <Card.Title>
                {chatDatas ? (
                  <>
                    <Card.Title>Customer :{chatDatas.customerName}</Card.Title>
                    <Card.Text>Status:{chatDatas.status}</Card.Text>
                  </>
                ) : (
                  <Card.Text>No chat data available.</Card.Text>
                )}
              </Card.Title>
              <Button
                id="start_call"
                variant="warning"
                onClick={() => navigate("joinchat")}
              >
                Start Chat
              </Button>
            </Card.Body>
          </Card>
        </Col> */}

        {/* <Col sm={12} md={4}>
          <Card>
            <Card.Body>
              <Card.Title>
                {chatDatas ? (
                  <>
                    <Card.Title>Customer :{chatDatas.customerName}</Card.Title>
                    <Card.Text>Meeting Code:{chatDatas.meetingCode}</Card.Text>
                    <Card.Text>Status:{chatDatas.status}</Card.Text>
                    <Card.Text>Start Time:{chatDatas.chatStartTime}</Card.Text>
                    <Card.Text>
                      Proposed Duration:{chatDatas.proposedChatDuration}
                    </Card.Text>
                  </>
                ) : (
                  <Card.Text>No chat data available.</Card.Text>
                )}
              </Card.Title>
              <Button
                id="start_call"
                variant="warning"
                onClick={() => navigate("joinchat")}
              >
                Start Chat
              </Button>
            </Card.Body>
          </Card>
        </Col> */}

        <Col sm={12} md={4}>
          <Card
            bg="light"
            key="light"
            style={{ width: "100%", height: "auto" }}
            className="text-center my-1 mb-2 all_border"
          >
            <Card.Header className="all_background" as="h6">
              {t("PR.DS.UC")}
            </Card.Header>
            <Card.Body>
              <Card.Title>{credentialCount.upcomingMeetings}</Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={4}>
          <Card
            bg="light"
            key="light"
            style={{ width: "100%", height: "auto" }}
            className="text-center my-1 all_border"
          >
            <Card.Header className="all_background" as="h6">
              {t("PR.DS.CMP.CMM")}
            </Card.Header>
            <Card.Body>
              <Card.Title>{credentialCount.completedMeetings}</Card.Title>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={4}>
          <Card
            bg="light"
            key="light"
            style={{ width: "100%", height: "auto" }}
            className="text-center my-1 all_border"
          >
            <Card.Header className="all_background" as="h6">
              {t("PR.DS.IP")}
            </Card.Header>
            <Card.Body>
              <Card.Title> 0 </Card.Title>
            </Card.Body>
          </Card>
        </Col>
        {/* <BarChart data={profChartData} options={barOptions} />
        <PieChart /> */}
      </Row>
      <Card className="all_border">
        <Row>
          <Col sm={12} md={12} lg={6}>
            <PieChart
              pieData={[
                ["Status", "Total Meetings"],
                [t("PR.DS.MA.CMP"), credentialCount.upcomingMeetings],
                [t("PR.DS.MA.CAN"), 2],
                [t("PR.DS.MA.MSD"), 2],
              ]}
              pieOptions={{
                title: t("PR.DS.MA"),
                is3D: true,
              }}
            />
          </Col>
          <Col sm={12} md={12} lg={6}>
            <PieChart
              pieData={[
                ["Ratings", "Users"],
                ["5 " + t("PR.AC.RVWTAB.STRS.CMM"), star5],
                ["4 " + t("PR.AC.RVWTAB.STRS.CMM"), star4],
                ["3 " + t("PR.AC.RVWTAB.STRS.CMM"), star3],
                ["2 " + t("PR.AC.RVWTAB.STRS.CMM"), star2],
                ["1 " + t("PR.AC.RVWTAB.STR.CMM"), star1],
              ]}
              pieOptions={{
                title: t("PR.DS.UF"),
                is3D: true,
              }}
            />
          </Col>
          {/* <Col sm={12} md={12} lg={4}>
            <PieChart
              pieData={[
                ["Payment", "Users"],
                [t("PR.DS.PT.UPI"), 1000],
                [t("PR.DS.PT.BNK"), 600],
                [t("PR.DS.PT.CRD"), 300],
                [t("PR.DS.PT.OTH"), 200],
              ]}
              pieOptions={{
                title: t("PR.DS.PT"),
                is3D: true,
              }}
            />
          </Col> */}
        </Row>
        {/* <BarChart
          data={[
            ["Ratings", "Users"],
            ["5 Stars", 1000],
            ["4 Stars", 600],
            ["3 Stars", 300],
            ["2 Stars", 200],
            ["1 Star", 100],
          ]}
          options={{
            bars: 'horizontal',
          }}
        /> */}
      </Card>
    </Card>
  );
};

export default ProfessionalDash;
