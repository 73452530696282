import React, { useState, useRef } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CustomerPlotForm from "./CustomerPlotForm";
import CustomerHomeForm from "./CustomerHomeForm";

const CustomerDash = () => {
  const navigate = useNavigate();
  const [key, setKey] = useState("plot");
  const tabRef = useRef();
  return (
    <React.Fragment>
      <Tabs
        id="controlled-tab-example customer"
        activeKey={tabRef}
        onSelect={(k) => tabRef.current.focus()}
        className="justify-content-center"
      >
        <Tab eventKey={tabRef} title="PLOT">
          <CustomerPlotForm />
        </Tab>
        <Tab eventKey={tabRef} title="HOME">
          <CustomerHomeForm />
        </Tab>
      </Tabs>
    </React.Fragment>
  );
};

export default CustomerDash;
