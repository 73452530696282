import React, { useState, useEffect, Suspense } from "react";
import { vasthuUrl } from "../component/Api";
import Modal2 from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import Toast from "react-bootstrap/Modal";
import {
  Card,
  Col,
  Container,
  Row,
  Badge,
  Form,
  Button,
  Alert,
  Spinner,
  OverlayTrigger,
  Tooltip,
  ListGroup,
} from "react-bootstrap";
import { AiOutlineStar, AiFillSchedule } from "react-icons/ai";
import { MdOutlineLanguage } from "react-icons/md";
import { GrUserExpert } from "react-icons/gr";
import { FaRupeeSign } from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";
import { BiBadgeCheck, BiBody } from "react-icons/bi";
import { GoPrimitiveDot, GoProject } from "react-icons/go";
import { useLocation, useNavigate } from "react-router-dom";
import { Radio } from "@material-ui/core";
import Title from "../component/Title";
import BillDeskPay from "../component/BillDeskPay";

const VasthuAstrologer = () => {
  const { t } = useTranslation();
  const token = sessionStorage.getItem("token");
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [consultantList, setConsultantList] = useState([]);
  console.log("consultantList", consultantList);
  const [callStatus, setCallStatus] = useState("");
  const todayDate = new Date();
  const year = todayDate.getFullYear();
  const month = todayDate.getMonth();
  const date = todayDate.getDate();
  const [value, setValue] = useState();

  const [bookSlotId, setBookSlotId] = useState(null);
  const [consulName, setConsulName] = useState();
  const [bookServiceId, setBookServiceId] = useState(null);
  const [bookServiceOfferString, setBookOfferString] = useState("vasthu");
  const [consultSlot, setConsultantSlot] = useState([]);
  const [consultSlotAudio, setConsultantSlotAudio] = useState([]);
  const [consultSlotVideo, setConsultantSlotVideo] = useState([]);
  const [slotLoading, setSlotLoading] = useState(false);
  const [profList, setProfList] = useState([]);
  const [defaultAudioOffer, setDefaultAudioOffer] = useState({});
  const [defaultAudioOffer15, setDefaultAudioOffer15] = useState({});
  const [defaultAudioOffer30, setDefaultAudioOffer30] = useState({});

  const navigate = useNavigate();
  const userId = window.sessionStorage.getItem("userId");
  const consultantId = window.sessionStorage.getItem("consultantId");

  //Modal - active list
  let subtitle;

  const [message, setMessage] = useState("");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [modalIsOpen1, setIsOpen1] = React.useState(false);
  const [selectedAudioOffer, setSelectedAudioOffer] = useState(7);
  const [activeServiceList, setActiveServiceList] = useState([]);
  const [showPaytm, setShowPaytm] = useState(false);
  const [showPaytm1, setShowPaytm1] = useState(false);
  const [bookModal, setBookModal] = useState(false);
  const [profObj, setProfObj] = useState({});
  const [serviceData, setServiceData] = useState([]);
  const [defaultHomeOffer, setDefaultHomeOffer] = useState({
    offerId: 0,
    offerPrice: 0,
    serviceId: 0,
  });
  const [curDate, setCurDate] = useState(new Date());
  const [maxDate, setMaxDate] = useState();

  const [paymentCredentials, setPaymentCredentials] = useState({
    txnToken: "",
    orderId: "",
    amount: 0,
    offerId: 0,
    userId: "",
  });

  const [formData, setFormData] = useState({
    duration: 0,
    price: 0,
  });

  // for booking - newly added

  const [activeOffers, setActiveOffers] = useState([]);
  const [toast, setToast] = useState(false);
  const [callStatusPro, setCallStatusPro] = useState();
  const [waitingTime, setWaitingTime] = useState();

  const closeTosastBtn = () => {
    setToast(false);
  };

  useEffect(() => {
    try {
      vasthuUrl
        .get(`subscription/userActiveOffersForConsultation/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setActiveOffers(res.data);
          console.log("active offers", res.data);
        });
    } catch (err) {
      console.log(err);
    }
  }, []);

  // booking - end
  useEffect(() => {
    vasthuUrl
      .get(`offer/defaultIndividualOffer/audio`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("audiores", res);
        setDefaultAudioOffer({
          offerId: res.data.id,
          offerPrice: res.data.offerPrice,
        });
      })
      .catch((err) => setDefaultAudioOffer({}));

    vasthuUrl
      .get(`offer/defaultIndividualOffer/audio15`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("audiores15", res);
        setDefaultAudioOffer15({
          offerId: res.data.id,
          offerPrice: res.data.offerPrice,
        });
      })
      .catch((err) => setDefaultAudioOffer15({}));

    vasthuUrl
      .get(`offer/defaultIndividualOffer/audio30`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("audiores30", res);
        setDefaultAudioOffer30({
          offerId: res.data.id,
          offerPrice: res.data.offerPrice,
        });
      })
      .catch((err) => setDefaultAudioOffer30({}));
  }, []);

  useEffect(() => {
    setLoading(true);
    try {
      vasthuUrl
        .get(`/vasthu/getvasthuservices`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("services_list" + res.data);
          let consultations = res.data.filter(
            (obj) => obj.serviceType == "CONSULTATION"
          );
          console.log(
            "services_list_consultaions" + consultations[0].serviceType
          );
          setServiceData(consultations);
        });

      vasthuUrl
        .get(`/info/professional/home`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          // console.log("consultants"+res[0].consultantName)
          setProfList(res.data);
          setLoading(false);
        });
      vasthuUrl
        .get(`offer/defaultIndividualOffer/${bookServiceOfferString}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setDefaultHomeOffer({
            offerId: res.data.id,
            offerPrice: res.data.offerPrice,
            serviceId: res.data.serviceId,
          });
        });
    } catch (e) {
      alert("SERVER ERROR");
      setLoading(false);
    }
    var maxSlotDate = new Date(curDate);
    maxSlotDate.setDate(maxSlotDate.getDate() + 30);
    setMaxDate(maxSlotDate);
  }, [consultSlot, consultSlotAudio, consultSlotVideo]);

  function openModal() {
    setIsOpen(true);
  }
  function afterBookModal() {
    setBookModal(true);
  }
  function closeBookModal() {
    setProfObj({});
    setBookModal(false);
    setValue();
  }

  function afterOpenModal() {
    console.log("afterOpenModal");
  }

  function closeModal() {
    setIsOpen(false);
  }

  function openModal1() {
    setIsOpen1(true);
  }

  function afterOpenModal1() {
    console.log("afterOpenModal1");
  }

  function closeModal1() {
    setIsOpen1(false);
  }

  //End -modal

  const alert = (msg) => {
    setMessage(msg);
    setTimeout(() => {
      setMessage("");
    }, 4000);
  };

  const payHandler = (txn) => {
    console.log("pay response out", txn);
    vasthuUrl
      .post(
        "vasthu/user/bdpaymentresponseui",
        { txn: txn.txnResponse.transaction_response },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("txnres", res.data);
        if (res.data.transaction_error_type === "success") {
          console.log("Saving payment_response ....", txn);

          vasthuUrl
            .post("vasthu/user/bdpaymentresponsehandler", txn, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res) => console.log("after txnsave", res.data))
            .catch((err) => console.log("after txnsave try", err));

          console.log("buying .. subsc");
          setTimeout(() => {
            vasthuUrl
              .post(
                "subscription/buyVasthuOffer",
                {
                  orderId: res.data.orderid,
                  userUuid: userId,
                  offerId: selectedAudioOffer,
                  paymentStatus: true,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((res) => {
                console.log("Payment status changed to true");
                console.log("call audio");
                console.log("afterbuysubscr", res.data);
                navigate("/audiocallstart", { state: res.data });
              });
          }, 5000);
        } else {
          vasthuUrl
            .post(
              "subscription/buyVasthuOffer",
              {
                orderId: res.data.orderid,
                userUuid: userId,
                offerId: selectedAudioOffer,
                paymentStatus: false,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {
              console.log("Payment status changed to false");
            });
        }
      });
  };

  const handleBuy1 = async (e) => {
    try {
      console.log("handle_buy1");
      await vasthuUrl
        .post(
          `vasthu/openapi/createorderapi`,
          {
            userUuid: userId,
            offerId: selectedAudioOffer,
            price: formData.price,
            currency: "356",
            returnUrl: "paymentstatusbilldesk",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log("billDesk", res.data);
          BillDeskPay(res.data, payHandler)
            .then((config) => {
              console.log("payout", config);
              window.loadBillDeskSdk(config);
            })
            .catch((err) => console.log("payment initialize error", err));
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handlePsumbit1 = async (event) => {
    setLoading(true);
    event.preventDefault();
    try {
      vasthuUrl
        .get(`/subscription/userActiveOffers/${userId}/6`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async (list) => {
          console.log("Audiocall list available " + JSON.stringify(list.data));
          let filteredAudioList = list.data.filter(
            (obj) => obj.offerId == selectedAudioOffer
          );
          console.log("filtered audio list", filteredAudioList);
          // setActiveServiceList(list.data);
          setActiveServiceList(filteredAudioList);

          // if (JSON.stringify(list.data) !== "[]") {
          //   openModal1();
          if (false) {
          } else {
            console.log("no subscription - please proceed with pay");

            setLoading(false);
            handleBuy1();
          }
        });
    } catch (e) {
      alert("CHECK INTERNET CONNECTION");
      console.log("error from trying subscription list " + e);
      setLoading(false);
      // console.log("SERVER ERROR", e);
    }
  };

  const handleDurationRadio = (e) => {
    if (e.target.value === "15") {
      window.sessionStorage.setItem("AudioCallDuration", String(900));
      setSelectedAudioOffer(defaultAudioOffer15.offerId);
      setFormData({
        duration: e.target.value,
        price: defaultAudioOffer15.offerPrice,
      });
    } else if (e.target.value === "30") {
      window.sessionStorage.setItem("AudioCallDuration", String(1800));
      setSelectedAudioOffer(defaultAudioOffer30.offerId);
      setFormData({
        duration: e.target.value,
        price: defaultAudioOffer30.offerPrice,
      });
    } else {
      window.sessionStorage.setItem("AudioCallDuration", String(3600));
      setSelectedAudioOffer(defaultAudioOffer.offerId);
      setFormData({
        duration: e.target.value,
        price: defaultAudioOffer.offerPrice,
      });
    }
  };

  const handleBookDate = async (e, id) => {
    if (e) {
      let date = e.getDate();
      let month = e.getMonth() + 1;
      let year = e.getFullYear();
      setValue(e);
      try {
        const res = await vasthuUrl.get(`/getslotbydate/${id}`, {
          params: {
            choosenDate: `${year}-${month}-${date}`,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("ConsultantSlotbydate" + res.data);
        setConsultantSlot([res.data]);
        setSlotLoading(false);
      } catch (e) {
        alert("SERVER ERROR");
        setSlotLoading(false);
      }
    } else {
      setConsultantSlot([]);
      setConsultantSlotAudio([]);
      setConsultantSlotVideo([]);
      setSlotLoading(false);
    }
  };

  const executePendingAction = () => {
    const pendingAction = window.sessionStorage.getItem("pendingAction");
    if (pendingAction === "GetConsultantCallStatus") {
      const consultantId = window.sessionStorage.getItem("consultantId");
      const consultantName = window.sessionStorage.getItem("consultantName");
      GetConsultantCallStatus(consultantId);
      setConsulName(consultantName);
      // Clear the stored action and data after execution
      window.sessionStorage.removeItem("pendingAction");
    }
  };

  // Call executePendingAction after login
  useEffect(() => {
    executePendingAction();
  }, []);

  const checkLoginAndGetConsultantCallStatus = (id, consultantName) => {
    if (window.sessionStorage.getItem("token") == null) {
      window.sessionStorage.setItem("pendingAction", "GetConsultantCallStatus");
      window.sessionStorage.setItem("consultantId", id);
      window.sessionStorage.setItem("consultantName", consultantName);
      window.sessionStorage.setItem("rerender", location.pathname);
      navigate("/login");
    } else {
      GetConsultantCallStatus(id);
      setConsulName(consultantName);
    }
  };

  const GetConsultantCallStatus = (id) => {
    setLoading(true);
    try {
      vasthuUrl
        .post(`vasthu/user/getprostatusavalaibility`, {
          pro_uuid: id,
          user_uuid: userId,
        })
        .then((res) => {
          setCallStatusPro(res.data);
          setLoading(false);
          openModelCall(res.data);
        });
    } catch (e) {
      openModelCall();
    }
  };

  const openModelCall = (data) => {
    if (data.proCurrentCallStatus === "in-progress") {
      setToast(true);
      // setWaitingTime(Math.round(data.waitingTime / 60));
      setWaitingTime(Math.round(data.waitingTime));
      console.log(data);
    } else {
      openModal();
      console.log(data);
    }
    console.log(data);
  };

  useEffect(() => {
    setLoading(true);
    try {
      vasthuUrl
        .post(`info/professional/byskillorlang`, {
          skill: "Astrology Consultation",
          langCode: "en",
        })
        .then((res) => {
          setConsultantList(res.data);
          setLoading(false);
          console.log("consultant_list_data_auth", res.data, token);
        });
    } catch (e) {
      console.log("server error");
      setLoading(false);
    }
  }, []);

  const CheckConsultant = (value) => {
    let selectedService = 4;
    window.sessionStorage.setItem(
      "checkcon",
      JSON.stringify({ value, selectedService })
    );
    // navigate("/checkconsultant", {
    //   state: { value, selectedService },
    // });
    navigate("/checkconsultant");
  };

  return (
    <Container>
      <Title className="all_background " title={t("USR.VSH.ASTRO.TL")} />
      <Container className="py-1 my-2 ">
        {!loading ? (
          consultantList.length > 0 ? (
            <div>
              <Modal2
                show={modalIsOpen}
                onHide={closeModal}
                className="all_border"
                centered
                // isOpen={modalIsOpen}
                // onAfterOpen={afterOpenModal}
                // onRequestClose={closeModal}
                // // style={customStyles}
                // contentLabel="Audio Call Duration"
              >
                <Modal2.Header closeButton className="all_background">
                  <h3>Audio Call Duration</h3>
                </Modal2.Header>
                <h3
                  className="text-center mt-3"
                  ref={(_subtitle) => (subtitle = _subtitle)}
                >
                  {t("TXT.SEL.AUD.CAL.DURA.LO")}
                </h3>
                <form onSubmit={handlePsumbit1}>
                  <div
                    className="text-center"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      marginBottom: "10px",
                    }}
                  >
                    <div>
                      <label>15 {t("TXT.MIN.LO")}</label>
                      {/*  15 min */}
                      <input
                        type="radio"
                        name="duration"
                        value={15}
                        onChange={handleDurationRadio}
                        style={{ marginLeft: "revert" }}
                      />
                    </div>
                    <div>
                      <label>30 {t("TXT.MIN.LO")}</label>
                      {/*  30 min */}
                      <input
                        type="radio"
                        name="duration"
                        value={30}
                        onChange={handleDurationRadio}
                        style={{ marginLeft: "revert" }}
                      />
                    </div>
                    {/* <input type="submit" /> */}
                  </div>
                  <Modal2.Footer
                    style={{
                      display: "flex",
                      justifyContent: "space-evenly",
                      padding: "5px",
                    }}
                    className="all_border"
                  >
                    <Button size="sm" type="submit" className="all_btn">
                      {t("USR.VFP.DRP.BTN.SMT")}
                    </Button>
                    <Button size="sm" onClick={closeModal} className="all_btn">
                      {t("TXT.BTN.CAN.LO")}
                    </Button>
                  </Modal2.Footer>
                </form>
              </Modal2>

              <Row className="d-flex  g-1">
                {consultantList.map((i, j) => (
                  <>
                    {i.professionalAccountStatus === "ACTIVATE" ? (
                      <Col md={6} lg={3} className="d-flex align-items-stretch">
                        <Card
                          className="d-flex professionalCard all_border"
                          key={i.consultantUuid}
                        >
                          <Card.Header className="d-flex justify-content-between align-items-start mt-2 all_border">
                            <Col className="flex-fill" lg={2}>
                              <img
                                src={i.imgUrl}
                                className="rounded-circle img-fluid profProfile"
                                alt="avatar pic"
                                style={{ width: "100px", height: "60px" }}
                              />
                            </Col>
                            <Col lg={8}>
                              <Row>
                                <strong>{i.consultantName}</strong>
                                <small>
                                  <MdOutlineLanguage />
                                  {i.knownLangugae},{i.extraLang}
                                </small>
                                <small>
                                  <BiBadgeCheck />
                                  {i.skills},{i.extraSkill}
                                </small>
                                <small>
                                  <GrUserExpert />
                                  {i.expYear}
                                  {t("USR.HOM.CL.TEXT1")}
                                </small>
                              </Row>
                            </Col>
                            <Col lg={2} className="align-items-center">
                              <Row className="d-flex g-1">
                                <Badge
                                  className="d-flex"
                                  pill
                                  bg="warning"
                                  text="dark"
                                  size="sm"
                                >
                                  <AiOutlineStar size={15} />
                                  {i.consultantRating}
                                </Badge>
                              </Row>
                            </Col>
                          </Card.Header>
                          <Card.Body className="text-center">
                            <Row>
                              <Col className="d-flex justify-content-evenly">
                                <OverlayTrigger
                                  key="top"
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-top`}>
                                      {t("TXT.STA.CALL.LO")}
                                    </Tooltip>
                                  }
                                >
                                  {/* <Button
                                    className="mt-2 all_btn"
                                    disabled={i.profstatus === 1 ? false : true}
                                    onClick={async () => {
                                      if (
                                        window.sessionStorage.getItem(
                                          "token"
                                        ) == null
                                      ) {
                                        navigate("/login");
                                      } else {
                                        GetConsultantCallStatus(
                                          i.consultantUuid
                                        );
                                        setConsulName(i.consultantName);
                                        // openModal();
                                        window.sessionStorage.setItem(
                                          "consultantId",
                                          i.consultantUuid
                                        );
                                      }
                                    }}
                                  >
                                    <FiPhoneCall size={25} />
                                    {t("USR.HOM.CL.ACTION1")}
                                  </Button> */}
                                  <Button
                                    className="mt-2 all_btn"
                                    size="sm"
                                    disabled={i.profstatus === 1 ? false : true}
                                    onClick={() => {
                                      checkLoginAndGetConsultantCallStatus(
                                        i.consultantUuid,
                                        i.consultantName
                                      );
                                    }}
                                  >
                                    {t("USR.HOM.CL.ACTION1")}
                                  </Button>
                                </OverlayTrigger>
                                <OverlayTrigger
                                  key="top"
                                  placement="top"
                                  overlay={
                                    <Tooltip id={`tooltip-top`}>
                                      {t("TXT.DO.SCH.LO")}
                                    </Tooltip>
                                  }
                                >
                                  <Button
                                    className="mt-2 all_btn"
                                    onClick={() => CheckConsultant(i)}
                                    style={{
                                      color: "black",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    <AiFillSchedule size={25} />
                                    {t("USR.HOM.CL.ACTION2")}
                                  </Button>
                                </OverlayTrigger>
                              </Col>
                            </Row>
                          </Card.Body>
                          {i.profstatus === 1 ? (
                            <Card.Footer
                              className="text-center all_border"
                              style={{ color: "green", height: 45 }}
                            >
                              <strong>{t("USR.HOM.PR.ONL")}</strong>
                              &nbsp;
                              <Spinner
                                animation="grow"
                                variant="success"
                                size="sm"
                              />
                            </Card.Footer>
                          ) : null}
                          {i.profstatus === 2 ? (
                            <Card.Footer
                              className="text-center all_border"
                              style={{ color: "red", height: 45 }}
                            >
                              <strong>{t("USR.HOM.PR.OFF")}</strong>
                              &nbsp;
                              <GoProject color="RED" size={20} />
                            </Card.Footer>
                          ) : null}
                          {i.profstatus === 3 ? (
                            <Card.Footer
                              className="text-center all_border"
                              style={{ color: "green", height: 45 }}
                            >
                              <strong>{t("USR.HOM.PR.ONCA")}</strong>
                              &nbsp;
                              <Spinner
                                animation="grow"
                                variant="success"
                                size="sm"
                              />
                            </Card.Footer>
                          ) : null}
                        </Card>
                      </Col>
                    ) : null}
                  </>
                ))}
              </Row>

              <Toast
                onHide={() => setToast(false)}
                show={toast}
                className="all_border"
              >
                <Toast.Header closeButton className="all_background">
                  {t("TXT.CON.BSY.LO")}!!!
                </Toast.Header>
                <Toast.Body>
                  Hello! <b>{consulName}</b> is currenty busy on another call,
                  please bear with us for {waitingTime} minutes{" "}
                </Toast.Body>
                <Toast.Footer>
                  <Button className="all_btn" onClick={closeTosastBtn}>
                    {t("TXT.CLO.LO")}
                  </Button>
                </Toast.Footer>
              </Toast>
            </div>
          ) : (
            <center>
              <h5>Vasthu Astrologers Not Available</h5>
            </center>
          )
        ) : (
          <div className="text-center">
            <Spinner animation="border" variant="warning" />
          </div>
        )}
      </Container>
    </Container>
  );
};
export default VasthuAstrologer;
