import React from "react";
import {
  Navbar,
  Container,
  Offcanvas,
  Nav,
  NavItem,
  Button,
  NavDropdown,
  Image
} from "react-bootstrap";
import PropTypes from "prop-types";
import { GiLotus } from "react-icons/gi";
import { UseAuth } from "../context/Auth";
import { useTranslation } from "react-i18next";

const UserHeader = (props) => {
  const { t } = useTranslation();
  const { onLogout, authname } = UseAuth();
  const token = window.sessionStorage.getItem("token");
  return (
    <React.Fragment>
      <Navbar bg="light" expand={props.breakpionts} >
        <Container fluid>
          <Navbar.Brand href="#" style={{ fontWeight: "bold" }}>
               <Image
                fluid
                thumbnail
                roundedCircle
                style={{ width: 100, height: 100 }}
                src="https://source.unsplash.com/user/c_v_r/100x100"
                alt="profile"
              />
            <GiLotus
              color="#ffbb33"
              size={30}
              width={30}
              height={30}
              className="d-inline-block align-top"
            />{" "}
            {/* {props.title} */}
            {t('CM_PUB_LOGO_TITLE') == 'CM_PUB_LOGO_TITLE'?props.title:t('CM_PUB_LOGO_TITLE')}
          </Navbar.Brand>

          <Navbar.Toggle
            aria-controls={`offcanvasNavbar-expand-${props.breakpionts}`}
          />
          <Navbar.Offcanvas
            id={`offcanvasNavbar-expand-${props.breakpionts}`}
            aria-labelledby={`offcanvasNavbarLabel-expand-${props.breakpionts}`}
            placement="start"
            className="home-drawer"
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title
                id={`offcanvasNavbarLabel-expand-${props.breakpionts}`}
              >
                MENU
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className="home-drawer">
              <Nav className="justify-content-end flex-grow-1 pe-3">
                <Nav.Link
                  style={{ fontWeight: "bold" }}
                  className="drawer-list"
                >
                  Vasthu for Plots
                </Nav.Link>
                <NavDropdown.Divider />
                <Nav.Link
                  style={{ fontWeight: "bold" }}
                  className="drawer-list"
                >
                  Vasthu for House
                </Nav.Link>
                <NavDropdown.Divider />

                <NavDropdown
                  style={{ fontWeight: "bold" }}
                  title="Consultants"
                  menuVariant="dark"
                >
                  <NavDropdown.Item href="#action3">
                    Astrologer
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action4">
                    Vasthu Expert
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action5">Architech</NavDropdown.Item>
                </NavDropdown>

                <NavDropdown
                  style={{ fontWeight: "bold" }}
                  title="Know More"
                  menuVariant="dark"
                >
                  <NavDropdown.Item href="#action3" style={{}}>
                    Articles
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action4">Blog</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action5">Books</NavDropdown.Item>
                </NavDropdown>

                <NavDropdown.Divider />

                <Button
                  variant="light"
                  style={{ fontWeight: "bold" }}
                  onClick={onLogout}
                >
                  LOG OUT
                </Button>
                <NavDropdown.Divider />
              </Nav>
            </Offcanvas.Body>
          </Navbar.Offcanvas>
        </Container>
      </Navbar>
    </React.Fragment>
  );
};

UserHeader.defaultProps = {
  title: "VasthuParam",
};
UserHeader.propsTypes = {
  title: PropTypes.string.isRequired,
};
export default UserHeader;
