import React, { useEffect, useState } from "react";
import {
  Button,
  Alert,
  Card,
  Modal,
  ListGroup,
  Spinner,
  Form,
  Placeholder,
  Col,
  Row,
  Badge,
  OverlayTrigger,
  Tooltip,
  Container,
  Popover,
} from "react-bootstrap";
import "../App.css";
import DatePicker from "react-date-picker";
import BillDeskPay from "./BillPaySuccess";
import { TiStarFullOutline } from "react-icons/ti";
import { vasthuUrl } from "../component/Api";
import PaymentProcess from "../component/PaymentProcess";
import BookConsultation from "../component/BookConsultation";
import StarRating from "../component/StarRating";
import { UseAuth } from "../context/Auth";
import { usePay } from "../context/PaymentContext";
import { useNavigate, Link, Outlet } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { display } from "@mui/system";
// import DayTimePicker from "@mooncake-dev/react-day-time-picker";
import { AiOutlineStar, AiFillSchedule } from "react-icons/ai";
import { MdOutlineLanguage } from "react-icons/md";
import { GrUserExpert } from "react-icons/gr";
import { FaRupeeSign } from "react-icons/fa";
import { FiPhoneCall } from "react-icons/fi";
import { BiBadgeCheck } from "react-icons/bi";
import { GoPrimitiveDot } from "react-icons/go";
import { useSlider } from "@mui/base";
import { format } from "date-fns";

const CustomerBooking = () => {
  const { t } = useTranslation();
  const token = sessionStorage.getItem("token");
  const userId = window.sessionStorage.getItem("userId");
  const lang1 = window.localStorage.getItem("i18nextLng");
  const [loading, setLoading] = useState(false);
  const [consultantList, setConsultantList] = useState([]);
  const [activeOffers, setActiveOffers] = useState([]);
  const { getSubId } = usePay();
  const [skill, setSkill] = useState("ALL");
  const [lang, setLang] = useState("ALL");
  const [selectedService, setSelectedService] = useState(2);

  useEffect(() => {
    setLoading(true);
    try {
      vasthuUrl
        .get(`info/professional/home`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setConsultantList(res.data);
          setLoading(false);
          console.log("consultant_list_data", res.data, token);
        });
    } catch (e) {
      console.log("server error");
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    try {
      vasthuUrl
        .get(`subscription/userActiveOffersForConsultation/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          // Sort the active array based on the purchasedDate
          // const sortedActive = res.data.sort(
          //   (a, b) => new Date(b.purchasedDate) - new Date(a.purchasedDate)
          // );

          setActiveOffers(res.data);
          console.log("Active offers", res.data);
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    // setLoading(true);
    try {
      vasthuUrl
        .post(`info/professional/byskillorlang`, {
          skill: skill,
          langCode: lang,
        })
        .then((res) => {
          setConsultantList(res.data);
          // setLoading(false);
          console.log("con filter out", res.data);
        });
    } catch (error) {
      setConsultantList((prev) => prev);
      // setLoading(false);
      console.log("consultlist", error);
    }
  }, [skill, lang]);

  const handleUse = (serviceId, subscriptionId) => {
    console.log("Service" + serviceId + " " + "Subscription" + subscriptionId);
    setSelectedService(serviceId);
    switch (serviceId) {
      case 3:
        setSkill("Vasthu Consultation");
        setTimeout(() => {
          handleShow();
        }, 1000);
        break;
      case 4:
        setSkill("Astrology Consultation");
        setTimeout(() => {
          handleShow();
        }, 1000);
        break;
      case 5:
        setSkill("Building Consultation");
        setTimeout(() => {
          handleShow();
        }, 1000);
    }
  };

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();

  return (
    <React.Fragment>
      {!loading ? (
        activeOffers.length > 0 ? (
          <Row className="mx-2 mt-3">
            {activeOffers.map((i, j) => (
              <Col sm={12} md={6} lg={3}>
                <div key={j}>
                  {i.serviceDetails[0].availableQuantity > 0 ? (
                    <Card className="m-1 all_border use_now_card">
                      <Card.Header className="all_background">
                        {/* <div className="fw-bold">
                      {lang == "en"
                        ? i.offerName
                        : lang == "ta"
                        ? i.offerNameTamil
                        : i.offerNameHindi}
                    </div> */}
                        {i.offerName}
                      </Card.Header>
                      <Card.Body>
                        <Card.Subtitle className="mb-2 text-muted">
                          {t("USR.ACT.PLN.PD")}:{" "}
                          {format(
                            new Date(i.purchasedDate),
                            "d MMM yyyy, HH:mm"
                          )}
                        </Card.Subtitle>
                        <Card.Subtitle className="mb-2 text-muted">
                          {t("USR.ACC.ACT.VAL.TL")}:{" "}
                          {format(new Date(i.expiryDate), "d MMM yyyy, HH:mm")}
                        </Card.Subtitle>
                        {i.serviceDetails.map((p, q) => (
                          <Card.Body>
                            {/* <Row>
                              <Col sm={12} md={3} lg={3}> */}
                            <Card.Title>
                              {lang1 == "en"
                                ? p.serviceName
                                : lang1 == "ta"
                                ? p.serviceNameTamil
                                : p.serviceNameHindi}
                            </Card.Title>
                            {/* </Col>
                              <Col sm={12} md={3} lg={2}> */}
                            <Card.Title>
                              {t("USR.ACC.ACT.REM.TL")} {p.availableQuantity}
                            </Card.Title>
                            {/* </Col> */}
                            {/* <Card.Title>
                          {t("USR.ACC.ACT.PGR.TL")} {p.inProgressQty}
                        </Card.Title>
                        <Card.Title>
                          {t("USR.ACC.ACT.USE.TL")} {p.usedQuantity}
                        </Card.Title> */}
                            {/* <Col sm={12} md={2} lg={3}> */}
                            <Card.Title>
                              {t("TXT.CAN.COUT.LO")} {p.cancelledCount}
                            </Card.Title>
                            <Card.Title>
                              {t("PR.APMNT.NATAB.LB.CD")} {p.duration}
                              {t("TXT.MIN.LO")}
                            </Card.Title>
                            {/* </Col> */}
                            {/* <Col sm={12} md={2} lg={2}> */}
                            <Card.Title>
                              {t("USR.ACC.ACT.TOT.TL")} {p.totalQuantity}
                            </Card.Title>
                            {/* </Col>
                              <Col
                                sm={12}
                                md={2}
                                lg={2}
                                style={{ textAlign: "center" }}
                              > */}
                            <OverlayTrigger
                              key="top"
                              placement="top"
                              overlay={
                                <Tooltip id={`tooltip-top`}>
                                  {p.cancelledCount == 0
                                    ? t("TXT.CLK.BOK.CON.LO")
                                    : t("TXT.CLK.BOK.CON.LO") +
                                      " " +
                                      (3 - p.cancelledCount) +
                                      " " +
                                      t("TXT.MOR.TIM.LO")}
                                </Tooltip>
                              }
                            >
                              <Button
                                className="all_btn"
                                size="sm"
                                onClick={() => {
                                  navigate("/consultantbooking", {
                                    state: { p, q: i.offerId },
                                  });
                                }}
                              >
                                {p.cancelledCount > 0
                                  ? t("TXT.RE.SCH.LO")
                                  : t("USR.ACC.USE.BTN")}
                              </Button>
                            </OverlayTrigger>
                            {/* </Col> */}
                            {/* </Row> */}
                          </Card.Body>
                        ))}
                      </Card.Body>
                    </Card>
                  ) : null}
                </div>
              </Col>
            ))}
          </Row>
        ) : (
          <div>
            <>
              <center>
                <h3>{t("USR.ACC.ACT.NO")}</h3>
              </center>
              <center>
                <Button
                  className="all_btn"
                  onClick={() => navigate("/customerpricing")}
                >
                  {t("USR.GT.BUYBTN")}
                </Button>
              </center>
            </>
          </div>
        )
      ) : (
        <div className="text-center">
          <Spinner animation="border" variant="warning" />
        </div>
      )}
    </React.Fragment>
  );
};
export default CustomerBooking;
