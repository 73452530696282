import React from "react";
import { Alert, Button, Col, Container, Row, Table } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { vasthuUrl } from "../component/Api";
import { UseAuth } from "../context/Auth";
import BillDeskPay from "../component/BillDeskPay";

function WalletPaymentProcess() {
  const Navigate = useNavigate();
  const location = useLocation();
  const { userId } = UseAuth();
  const lang = localStorage.getItem("i18nextLng");
  const token = window.sessionStorage.getItem("token");

  const { offerId, offerList = [], amount } = location.state || {};

  const selectedOffer =
    offerList.length > 0 ? offerList.find((item) => item.id === offerId) : null;

  const handleBuy = async (offerId, actualPrice) => {
    try {
      const response = await vasthuUrl.post(
        `/openapi/createwalletorderapi`,
        {
          userUuid: userId,
          walletofferId: offerId,
          price: actualPrice,
          currency: "356",
          returnUrl: "customerpricing",
          lng: lang,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      console.log(response.data);
      // handleClose();

      try {
        const config = await BillDeskPay(response.data);
        console.log("payout", config);
        window.loadBillDeskSdk(config);
      } catch (err) {
        console.error("Payment initialization failed", err);
        Alert("Payment initialization failed: " + err.message);
      }
    } catch (err) {
      console.error("Error in API call", err);
      Alert("Paytm server Error: " + err.message);
    }
  };
  

  return (
    <Container>
      <div style={{ backgroundColor: "#ffc33b", marginTop: "5px" }}>
        <h3>
          <center>Payment Details</center>
        </h3>
      </div>

      <Row className="mt-5">
        <Table bordered striped>
          <thead>
            <tr>
              <th>Recharge Amount</th>
              <td>
                <h5>
                  {amount ? (
                    <>Amount: ₹ {amount}</>
                  ) : selectedOffer ? (
                    <>Amount: ₹ {selectedOffer.actualPrice}</>
                  ) : (
                    <>Amount: ₹ 0</>
                  )}
                </h5>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>Total Amount</th>
              <td>
                <h4>
                  {amount ? (
                    <b>{amount}</b>
                  ) : selectedOffer ? (
                    <b>{selectedOffer.actualPrice}</b>
                  ) : (
                    <b>0</b>
                  )}
                </h4>
              </td>
            </tr>
          </tbody>
        </Table>
      </Row>

      <center>
        <Row style={{ display: "flex" }} className="mt-5">
          <Col>
            <Button
              onClick={() => Navigate("/walletoffer")}
              variant="secondary"
            >
              Back
            </Button>
          </Col>
          <Col>
            <Button
              onClick={() =>
                handleBuy(selectedOffer.actualPrice, selectedOffer.id)
              }
              className="all_btn1"
            >
              Pay
            </Button>
          </Col>
        </Row>
      </center>
    </Container>
  );
}

export default WalletPaymentProcess;
