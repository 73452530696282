import React, { useContext, useEffect, useState } from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { CrmAccountContext } from "../../pages/crm/Accounts";
import { vasthuUrl } from "../Api";
import { useList } from "@mui/base/useList";
import { UseAuth } from "../../context/Auth";
import { Button as MyButton } from "@material-ui/core";
import { NavLink, useNavigate } from "react-router-dom";
import {
  Form,
  // Button,
  // Container,
  Row,
  Col,
  Card,
  Spinner,
  Modal,
  InputGroup,
} from "react-bootstrap";
import validator from "validator";
import { useTranslation } from "react-i18next";
import { Send } from "@material-ui/icons";
//eye pwd
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";

import ReactGA from "react-ga4";

export default function ClientAdd() {
  const [userAdded, setIsUserAdded] = React.useState(false);
  const {
    setUserList,
    token,
    alert,
    setMessageType,
    setUserUpdateList,
  } = useContext(CrmAccountContext);

  const [t] = useTranslation();
  const iniLang = localStorage.getItem("i18nextLng");
  const [user, setUser] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [setspass, setSPass] = useState("");
  const [setcpass, setCPass] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [countryCode, setCountryCode] = useState("0");
  //eye pwd
  const [showPassword, setShowPassword] = useState(false);
  const [showcPassword, setShowcPassword] = useState(false);

  //eye pwd
  const handleTogglePassword = () => {
    setShowPassword(!showPassword);
  };
  const handleTogglecPassword = () => {
    setShowcPassword(!showcPassword);
  };

  const handleShow = () => {
    const phone_with_code = countryCode.concat(phone);
    if (
      !validator.isEmpty(user) &&
      !validator.isEmpty(phone) &&
      !validator.isEmpty(setcpass) &&
      !validator.isEmpty(setspass)
    ) {
      if (
        validator.isMobilePhone(phone_with_code, [
          "en-IN",
          "en-SG",
          "ms-MY",
          "en-US",
        ]) &&
        validator.isEmail(email) &&
        validator.equals(setcpass, setspass)
      ) {
        setShow(true);
      } else if (!validator.equals(setcpass, setspass)) {
        alert(t("Plese Enter Same Password!"));
        setMessageType("error");
      } else {
        alert(t("USR.HOM.ERR.MSG1"));
        setMessageType("error");
      }
    } else {
      alert(t("USR.HOM.ERR.MSG2"));
      setMessageType("error");
    }
  };

  const handleClose = () => setShow(false);

  const onSubmit = async (event) => {
    event.preventDefault();
    const phone_with_code = countryCode.concat(phone);
    try {
      const res = await vasthuUrl.post(`vasthu/user/register`, {
        userName: user,
        phone_no: phone_with_code,
        email: email,
        password: setcpass,
        lng: iniLang,
      });
      if (res.data.message === process.env.REACT_APP_SUCESS_REGISTER) {
        handleClose();
        setUser("");
        setPhone("");
        setEmail("");
        setSPass("");
        setCPass("");
        alert(t("USR.HOM.SUCC.MSG1"));
        setMessageType("success");
        setUserUpdateList(true);
      } else {
        alert(res.data.message);
        setMessageType("error");
        handleClose();
      }
    } catch (err) {
      alert(err);
      setMessageType("error");
      handleClose();
    }
  };

  return (
    <Container>
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Add Customers
        </Typography>
        {/* <Box component="form" onSubmit={handleSubmit} sx={{ mt: 3 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="given-name"
                name="firstName"
                required
                fullWidth
                className="form_feild all_border"
                id="firstName"
                label="First Name"
                autoFocus
                type="text"
                inputProps={{ minLength: 5 }}
                style={{ border: "1px solid #ffb33e" }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                className="form_feild all_border"
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="family-name"
                type="text"
                style={{ border: "1px solid #ffb33e" }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                className="form_feild all_border"
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                type="email"
                style={{ border: "1px solid #ffb33e" }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                className="form_feild all_border"
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                inputProps={{ minLength: 6 }}
                style={{ border: "1px solid #ffb33e" }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                className="form_feild all_border"
                fullWidth
                name="phone"
                label="Phone"
                type="tel"
                id="phone"
                inputProps={{
                  inputMode: "numeric",
                  pattern: "[0-9]*",
                  minLength: 10,
                }}
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            className="all_btn1 all_border"
            sx={{ mt: 3, mb: 2 }}
          >
            Add
          </Button>
        </Box> */}
        <Card border="warning all_border" className="p-4">
          <Form>
            <Form.Group className="mb-3" controlId="formBasicUser">
              <Form.Control
                type="text"
                value={user}
                className="form_feild"
                placeholder={t("USR.HOM.HD.UN")}
                onChange={(e) => {
                  setUser(e.target.value);
                }}
                autoComplete="new-off"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPhone">
              <InputGroup className="mb-3">
                <Form.Select
                  aria-label="Country Code"
                  style={{ maxWidth: "20%" }}
                  value={countryCode}
                  className="form_feild countrywidth"
                  onChange={(e) => {
                    setCountryCode(e.target.value);
                  }}
                >
                  <option defaultChecked key={1} value="0">
                    IND
                  </option>
                  <option key={2} value="+65">
                    SGP
                  </option>
                  <option key={3} value="+60">
                    MYS
                  </option>
                  <option key={4} value="+1">
                    USA
                  </option>
                </Form.Select>
                <Form.Control
                  type="phone"
                  value={phone}
                  className="form_feild"
                  placeholder={t("USR.HOM.HD.P")}
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                  autoComplete="new-off"
                />
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="email"
                value={email}
                className="form_feild"
                placeholder={t("USR.HOM.HD.EM")}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                autoComplete="new-off"
              />
              {/* <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text> */}
            </Form.Group>

            <Form.Group className="mb-3" controlId="formBasicSPassword">
              <InputGroup className="mb-3">
                <Form.Control
                  type={showPassword ? "text" : "password"}
                  value={setspass}
                  className="form_feild"
                  placeholder={t("USR.HOM.HD.CPWD")}
                  onChange={(e) => {
                    setSPass(e.target.value);
                  }}
                  autoComplete="new-password"
                />
                <InputGroup.Text
                  onClick={handleTogglePassword}
                  className="form_feild"
                >
                  {showPassword ? <FaEye /> : <FaEyeSlash />}
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCPassword">
              <InputGroup className="mb-3">
                <Form.Control
                  type={showcPassword ? "text" : "password"}
                  value={setcpass}
                  className="form_feild"
                  placeholder={t("USR.HOM.HD.CFPWD")}
                  onChange={(e) => {
                    setCPass(e.target.value);
                  }}
                  autoComplete="new-password"
                />
                <InputGroup.Text
                  onClick={handleTogglecPassword}
                  className="form_feild"
                >
                  {showcPassword ? <FaEye /> : <FaEyeSlash />}
                </InputGroup.Text>
              </InputGroup>
            </Form.Group>
            {!loading ? (
              <Button
                style={{ backgroundColor: "#ffb33e", color: "white" }}
                variant="contained"
                fullWidth="true"
                onClick={handleShow}
                endIcon={<Send />}
              >
                ADD
              </Button>
            ) : (
              <>
                <Button
                  style={{ backgroundColor: "#ffb33e", color: "white" }}
                  fullWidth="true"
                  disabled
                >
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  {t("USR.HOM.BTN.LOD")}
                </Button>
              </>
            )}
          </Form>
          <>
            <Modal show={show} onHide={handleClose}>
              <Modal.Header className="all_background" closeButton>
                <Modal.Title>{t("USR.HOM.HD.REG")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{t("USR.VSH.HFTAB.MDL.CNF.ALRT1")}</Modal.Body>
              <Modal.Footer className="d-flex justify-content-evenly all_border">
                <Button
                  className="all_btn"
                  style={{
                    borderRadius: 50,
                  }}
                  onClick={handleClose}
                >
                  {t("USR.VSH.HFTAB.MDL.CNF.BTN.CNC")}
                </Button>
                <Button
                  className="all_btn"
                  style={{
                    borderRadius: 50,
                  }}
                  onClick={onSubmit}
                >
                  {t("USR.VSH.HFTAB.MDL.CNF.BTN.CNF")}
                </Button>
              </Modal.Footer>
            </Modal>
          </>
        </Card>
      </Box>
    </Container>
  );
}
