import React, { useEffect, useState } from "react";
import { Container, Table, Spinner, Button } from "react-bootstrap";
import { vasthuUrl } from "../component/Api";
import { UseAuth } from "../context/Auth";
import { FcCheckmark, FcCancel } from "react-icons/fc";
import { UseVasthu } from "../context/vasthu";
import PlotTable from "../component/plotTable";
import PlotMobileCard from "../component/plotMobileCard";
import { Navigate, useNavigate, useLocation } from "react-router-dom";

const PlotScreen = () => {
  // const { token } = UseAuth();
  const token = window.sessionStorage.getItem("token");
  const langCode = window.localStorage.getItem("i18nextLng");
  const { plotRec } = UseVasthu();
  const [plotObj, setPlotObj] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [offerId, setOfferId] = useState();
  const location = useLocation();
  useEffect(() => {
    setLoading(true);
    console.log("plotId", plotRec, typeof plotRec);
    console.log("navigate.params", location);
    // try{
    vasthuUrl
      .get(`/validateplotresult/${plotRec}/${langCode}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setPlotObj(res.data);
        setLoading(false);
        console.log("Plot record screen", res.data);
        vasthuUrl
          .get(
            `/subscription/useVasthuOffer/${parseInt(location.state.offerId)}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((rs) =>
            console.log("offer subscription redeem count used", rs.data.message)
          )
          .catch((er) =>
            console.log("offer subscription redeem count not reduced", er)
          );
      });
    // }
    // catch(e){
    //   console.log("server error")
    // }
  }, []);

  return (
    <Container>
      {!loading ? (
        <>
          <PlotTable data={plotObj} />
          <PlotMobileCard data={plotObj} />
        </>
      ) : (
        <div className="text-center">
          {" "}
          <Spinner animation="border" variant="warning" />{" "}
        </div>
      )}
      {/* <center>
        <Button
          variant="outline-warning"
          style={{ borderRadius: 50 }}
          onClick={() => navigate("/")}
        >
          FINISH
        </Button>
      </center> */}
    </Container>
  );
};

export default PlotScreen;
