import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { Badge, Button, ListGroup, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { vasthuUrl } from "../component/Api";
import BillDeskPay from "./BillDeskPay";

import Slider from "react-slick";
// import CryptoJS from "crypto-js";
// import * as jose from "jose";

const OfferList = (props) => {
  const { t } = useTranslation();
  const userId = window.sessionStorage.getItem("userId");
  const token = window.sessionStorage.getItem("token");
  const [showPaytm, setShowPaytm] = useState(false);
  const [show, setShow] = useState(false);
  const [model, setModel] = useState(false);
  const [userPlan, setUserPlan] = useState([]);
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [timeStampFormat, setTimeStampFormat] = useState("");
  console.log("paymentDetails", paymentDetails);
  const [symbol, setSymbol] = useState(false);
  const navigate = useNavigate();
  const [buyingOfferDetails, setBuyingOfferDetails] = useState([]);
  const [paymentCredentials, setPaymentCredentials] = useState({
    txnToken: "",
    orderId: "",
    amount: 0,
    offerId: 0,
    userId: "",
  });

  const formatDate = (date) => {
    try {
      return format(date, "d MMM yyyy, HH:mm"); // Example format string
    } catch (error) {
      console.error("Error formatting date:", error.message);
      return ""; // or return a default value
    }
  };

  const dateStr =
    paymentDetails && paymentDetails.timestamp ? paymentDetails.timestamp : "";
  useEffect(() => {
    if (dateStr) {
      const [datePart, timePart] = dateStr.split(" "); // Split date and time parts
      const [day, month, year] = datePart.split("-").map(Number);
      const [hours, minutes, seconds] = timePart.split(":").map(Number);
      const dateObj = new Date(year, month - 1, day, hours, minutes, seconds);
      if (!isNaN(dateObj.getTime())) {
        const options = {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: true, // Use 12-hour clock with AM/PM
        };
        const formattedDate = dateObj.toLocaleString("en-US", options);
        console.log(formattedDate); // Output: "04/18/2024, 12:03:59 PM"
        setTimeStampFormat(formattedDate);
      } else {
        console.error("Invalid date format:", dateStr);
      }
    } else {
      console.error("Date string is undefined or null.");
    }
  }, [dateStr]);

  const handleClose = () => setShow(false);
  const handleOpen = (id) => {
    setBuyingOfferDetails(id.offerDetails);
    setShow(true);
    setUserPlan(id);
  };

  const modelClose = () => {
    setModel(false);
    navigate("/customeraccount");
  };
  const modelOpen = (id) => {
    setModel(true);
  };

  console.log("userplan", userPlan);

  // newly added
  const lang = window.localStorage.getItem("i18nextLng");
  //
  const payHandler = (txn) => {
    console.log("pay response out", txn);
    vasthuUrl
      .post(
        "vasthu/user/bdpaymentresponseui",
        { txn: txn.txnResponse.transaction_response },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // console.log("txnres", res.data);
        // // alert(res.data)
        // if (res.data) {
        //   navigate("/customerpricing", { state: res.data });
        // }
        // added 05-06-2023
        console.log("txnres", res.data);
        // if (res.data.transaction_error_type == "success") {
        //   navigate("/customeraccount");
        // }

        if (res.data.transaction_error_type === "success") {
          console.log("Saving payment_response ....", txn);

          console.log("Saving payment_response ....", txn);

          vasthuUrl
            .post("vasthu/user/bdpaymentresponsehandler", txn, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res1) => console.log("after txnsave", res1.data))
            .catch((err) => console.log("after txnsave try", err));

          console.log("buying .. subsc");
          console.log("after paymentsave _ card" + res.data);

          setTimeout(() => {
            vasthuUrl
              .post(
                "subscription/buyVasthuOffer",
                {
                  orderId: res.data.orderid,
                  userUuid: userId,
                  offerId: parseInt(res.data.additional_info.additional_info2),
                  paymentStatus: true,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((res) => {
                console.log("Payment status changed to true");
                console.log("afterbuysubscr", res.data);
                setPaymentDetails(res.data);
                modelOpen();
                // navigate("/customeraccount");
                //  this.props.navigate(this.props.screenName,{state:{offerId:res.data.id}})
              });
          }, 2000); //2000
        } else {
          vasthuUrl
            .post(
              "subscription/buyVasthuOffer",
              {
                orderId: res.data.orderid,
                userUuid: userId,
                offerId: parseInt(res.data.additional_info.additional_info2),
                paymentStatus: false,
              },
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            )
            .then((res) => {
              console.log("Payment status changed to false");
              // console.log("afterbuysubscr", res.data);
              navigate("/customeraccount");
            });
        }

        // added -end - 05-06-2023
      });
  };

  const [orderApiResponse, setOrderApiResponse] = useState({});

  const handleFreeOffer = (id) => {
    vasthuUrl
      .post(
        "subscription/buyVasthuOffer",
        {
          orderId: "FREE",
          userUuid: userId,
          offerId: id,
          paymentStatus: true, // test
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("Payment status changed to trueFREE");
        console.log("afterbuysubscrFREE", res.data);
        // setPaymentDetails(res.data);
        // modelOpen();
        navigate("/customeraccount");
        //  this.props.navigate(this.props.screenName,{state:{offerId:res.data.id}})
      });
  };

  const handleBuy = async (id, price) => {
    if (token) {
      try {
        await vasthuUrl
          .post(
            `vasthu/openapi/createorderapi`,
            {
              userUuid: userId,
              offerId: id,
              price: price,
              currency: "356",
              returnUrl: "customeraccount",
              lng: lang,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            console.log("billDesk", res.data);
            handleClose();
            BillDeskPay(res.data, payHandler)
              .then((config) => {
                console.log("payout", config);
                window.loadBillDeskSdk(config);
              })
              .catch((err) => console.log("payment initialize error", err));
          });
      } catch (err) {
        console.log("offer buy in home screen", err);
      }
    } else {
      navigate("/login");
    }
    // handleClose()
  };

  console.log("userPlan", userPlan);
  console.log("setUserPlan", setUserPlan);

  var settings = {
    className: "slider variable-width",
    // variableWidth: true,
    // dots: true,
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    speed: 1000,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };
  console.log("props.data", props.data);

  return (
    <React.Fragment>
      {props.data.length > 0 ? (
        <>
          <ListGroup>
            <Slider
              arrows={true}
              slidesToShow={1}
              slidesToScroll={1}
              lazyLoad={true}
              {...settings}
            >
              {props.data.map((i, j) => (
                <div style={{}}>
                  <ListGroup.Item
                    style={{
                      padding: "revert-layer",
                      borderRadius: "5px",
                      minHeight: "170px",
                    }}
                    key={j}
                    as="li"
                  >
                    <div
                      style={{
                        height: "155px",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="scroll_bar">
                        <div className="fw-bold">
                          {lang == "en"
                            ? i.offerName
                            : lang == "ta"
                            ? i.offerNameTamil
                            : i.offerNameHindi}
                        </div>
                        <h6 className="card-text gap-2">
                          ₹{i.offerPrice === 0 ? "Free" : i.offerPrice}₹
                          <s>{i.totalBasePrice}</s>
                        </h6>
                        <h4>{i.duration}{t("TXT.MIN.LO")}</h4>
                        {i.offerDetails.map((i, j) => (
                          <h6>
                            {lang == "en"
                              ? i.serviceName
                              : lang == "ta"
                              ? i.serviceNameTamil
                              : i.serviceNameHindi}
                          </h6>
                        ))}

                        {/* <p>
                          {i.offerEndDate ? (
                            "Expires On:" + <p>{formatDate(new Date(i.offerEndDate))}</p>
                          ) : (
                            <></>
                          )
                          }
                        </p> */}
                        {i.offerEndDate ? (
                          <p>
                            {t("USR.OFF.VAD.EXP.D")}:
                            {formatDate(new Date(i.offerEndDate))}
                          </p>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div>
                        <Button
                          onClick={() => handleOpen(i)}
                          size="sd"
                          className="all_btn"
                          active
                        >
                          {i.offerPrice === 0
                            ? t("BTN.USE.NOW.LO")
                            : t("USR.ACC.PEN.BUY.BTN")}
                          {/* {t("USR.ACC.PEN.BUY.BTN")} */}
                        </Button>
                      </div>
                    </div>
                  </ListGroup.Item>
                </div>
              ))}
            </Slider>
          </ListGroup>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header className="modal-header all_background" closeButton>
              <Modal.Title> {t("USR.HOM.PLN.DTL")} </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <p>
                  {t("TXT.OFF.NM.LO")}: {userPlan.offerName}
                </p>
                <p>
                  {" "}
                  {t("USR.HOM.PLN.DTL.OT")}: {userPlan.offerFrequency}{" "}
                </p>
                <p>
                  {t("USR.HOM.PLN.DTL.OP")}: ₹
                  {userPlan.offerPrice === 0 ? "Free" : userPlan.offerPrice}
                </p>
                <p>
                  {t("USR.HOM.PLN.DTL.OBP")} : ₹<s>{userPlan.totalBasePrice}</s>{" "}
                </p>
                <p>
                  {" "}
                  {userPlan.offerFrequency === "PERIOD" ? (
                    <p>
                      {" "}
                      {t("USR.HOM.PLN.DTL.OED")}:
                      {formatDate(new Date(userPlan.offerEndDate))}
                    </p>
                  ) : (
                    <></>
                  )}
                </p>
                {buyingOfferDetails.map((service) => (
                  <p className="d-flex">
                    <div style={{ width: "170px" }}>
                      {t("USR.HOM.SPL.SN")}:&nbsp;
                    </div>
                    <div className="w-100">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        {lang == "en"
                          ? service.serviceName
                          : lang == "ta"
                          ? service.serviceNameTamil
                          : service.serviceNameHindi}{" "}
                        {service.serviceCostType == "FREE"
                          ? ":" + " " + service.serviceCostType
                          : null}
                        {/* (30{t("TXT.MIN.LO")}) */}
                        {/* (15{t("TXT.MIN.LO")}) */}
                        <Badge
                          className="bg-warning"
                          style={{
                            color: "black",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          {service.count} {t("USR.OFF.VAD.TIME")}
                        </Badge>
                      </div>
                    </div>
                  </p>
                ))}
                {/* {buyingOfferDetails.map((service) => (
                  <div>
                    <p
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      {lang == "en"
                        ? service.serviceName
                        : lang == "ta"
                        ? service.serviceNameTamil
                        : service.serviceNameHindi}{" "}
                      {service.serviceCostType == "FREE"
                        ? ":" + " " + service.serviceCostType
                        : null}
                      <Badge bg="warning" text="dark">
                        {" "}
                        {service.count} {t("USR.OFF.VAD.TIME")}
                      </Badge>
                    </p>
                  </div>
                ))} */}
                {userPlan.description === "null" ? null : (
                  <p>
                    {t("TXT.DES.LO")} : {userPlan.description}
                  </p>
                )}
                {console.log("userPlan.offerDetails", buyingOfferDetails)}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                  }}
                >
                  <Button className="all_btn" onClick={handleClose}>
                    {t("USR.HOM.PLN.OF.CN")}
                  </Button>
                  {userPlan.offerPrice === 0 ? (
                    <Button
                      size="md"
                      className="all_btn"
                      onClick={() => handleFreeOffer(userPlan.id)}
                    >
                      {t("BTN.USE.NOW.LO")}
                    </Button>
                  ) : (
                    <Button
                      size="md"
                      className="all_btn"
                      onClick={() =>
                        handleBuy(userPlan.id, userPlan.offerPrice)
                      }
                    >
                      {t("USR.ACC.PEN.BUY.BTN")}
                    </Button>
                  )}
                </div>
              </div>
            </Modal.Body>
          </Modal>

          <Modal show={model} onHide={modelClose}>
            <Modal.Header className="all_background" closeButton>
              <Modal.Title>{t("USR.HOM.PLN.PDT")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <>
                {paymentDetails ? (
                  <>
                    <p>
                      <b>{t("TXT.OFF.NM.LO")}:</b> {userPlan.offerName}
                    </p>
                    <p>
                      <b>{t("USR.HOM.PLN.DTL.OP")}:</b>{" "}
                      <b>₹{userPlan.offerPrice} </b>
                    </p>
                    {buyingOfferDetails.map((service) => (
                      <div>
                        <p>
                          <b>{t("USR.HOM.SPL.SN")}</b>{" "}
                          <li>
                            {lang == "en"
                              ? service.serviceName
                              : lang == "ta"
                              ? service.serviceNameTamil
                              : service.serviceNameHindi}
                          </li>{" "}
                        </p>
                      </div>
                    ))}
                    <p>
                      <b>{t("USR.HOM.PLN.PS")} :</b> {paymentDetails.message}
                    </p>
                    <p>
                      <b>{t("USR.HOM.PLN.OF.PD")} :</b>
                      {timeStampFormat}
                    </p>
                  </>
                ) : null}
                <Button
                  size="md"
                  style={{
                    float: "right",
                  }}
                  className="all_btn"
                  onClick={() => modelClose()}
                  active
                >
                  {t("BTN.USE.NOW.LO")}
                </Button>
              </>
            </Modal.Body>
          </Modal>
        </>
      ) : (
        <center>
          <h3>{t("TXT.COM.SOON.LO")}</h3>
        </center>
      )}
    </React.Fragment>
  );
};

export default OfferList;
