import { Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { vasthuUrl } from "../../component/Api";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";

function ConsultantChatHistory() {
  const token = window.sessionStorage.getItem("token");
  const userId = window.sessionStorage.getItem("userId");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [chatHistory, setChatHistory] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [chatData, setChatData] = useState();
  const uuid = window.sessionStorage.getItem("userId");

  useEffect(() => {
    try {
      vasthuUrl
        .get(`/initiateChat/getChatHistory/${uuid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setRefresh(false);
          setChatHistory(res.data.reverse());
        });
    } catch (e) {
      console.log("setAllOffer", e);
    }
  }, [refresh]);

  useEffect(() => {
    const fetchData = () => {
      vasthuUrl
        .get(`/initiateChat/getchatdetailsforconsultant/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setRefresh(true);
          if (res.data.status == "in-progress") {
            setChatData(res.data);
          } else {
            setChatData();
          }
        })
        .catch((error) => {
          console.log("SERVER ERROR", error);
        });
    };

    fetchData();
    const intervalId = setInterval(fetchData, 30000);
    // const intervalId = setInterval(fetchData, 120000);

    return () => clearInterval(intervalId);
  }, []);

  const columns = [
    { field: "username", headerName: t("USR.HOM.HD.UN"), width: 120 },
    // { field: "consultantname", headerName: "CONSULTANT NAME", width: 150 },
    { field: "status", headerName: t("TXT.STS.LO"), width: 130 },
    { field: "duration", headerName: t("USR.CHAT.MIN"), width: 180 },
    { field: "proposedChatDuration", headerName: t("USR.TOT.DUR"), width: 150 },
    { field: "meetingCode", headerName: t("USR.METNG.CDE"), width: 120 },
    {
      field: "chatDate",
      headerName: t("USR.CHAT.DATE"),
      renderCell: (params) => {
        const date = new Date(params.row.chatDate);
        return format(date, "dd-MM-yyyy");
      },
      width: 120,
    },
    { field: "chatStartTime", headerName: t("USR.CHAT.STA.TIME"), width: 160 },
    {
      field: "connectStartTime",
      headerName: t("USR.CONNECT.STAT.TIME"),
      width: 170,
    },
    { field: "connectEndTime", headerName: t("USR.CONT.END.TIME"), width: 170 },
  ];

  return (
    <div>
      <center className="mt-2">
        {chatData && chatData.status == "in-progress" ? (
          <Card
            className="d-flex p-3 all_border mb-5"
            style={{ width: "70%", height: "auto" }}
          >
            <Row>
              <Col xs={0} sm={0} md={2}></Col>
              <Col xs={6} sm={6} md={4}>
                <Card.Text>
                  <b>
                    <h4>{t("USR.CHAT.CUSTOMER")}</h4>
                  </b>
                </Card.Text>
                <Card.Text>{t("USR.METNG.CDE")}</Card.Text>
                <Card.Text>{t("TXT.STS.LO")}</Card.Text>
                <Card.Text>{t("USR.INTI.TIME")}</Card.Text>
                <Card.Text>{t("USR.PROPO.DUR")}</Card.Text>
              </Col>

              <Col xs={6} sm={6} md={6}>
                {" "}
                <Card.Text>
                  <b>
                    <h4>
                      {" "}
                      <b>:</b>&nbsp;&nbsp;{chatData.customerName}
                    </h4>
                  </b>
                </Card.Text>
                <Card.Text>
                  {" "}
                  <b>:</b>&nbsp;&nbsp;{chatData.meetingCode}
                </Card.Text>
                <Card.Text>
                  {" "}
                  <b>:</b>&nbsp;&nbsp;{chatData.status}
                </Card.Text>
                <Card.Text>
                  {" "}
                  <b>:</b>&nbsp;&nbsp;{chatData.chatStartTime}
                </Card.Text>
                <Card.Text>
                  {" "}
                  <b>:</b>&nbsp;&nbsp;{chatData.proposedChatDuration}
                </Card.Text>
              </Col>
              {/* <Col xs={0} sm={0} md={2}></Col> */}
            </Row>
            <div className="mt-2">
              <Button
                className="all_btn"
                onClick={() => navigate("chatscreen")}
              >
                {t("USR.CNF.CHAT.BTN")}
              </Button>
            </div>
          </Card>
        ) : null}
      </center>

      {!loading ? (
        chatHistory.length > 0 ? (
          <>
            <center>
              <h2>
                <u>{t("USR.CHAT.HIS.HEAD")}</u>
              </h2>
            </center>
            <center className="datagrid">
              <Grid container className="deskTable">
                <DataGrid
                  className="all_border - text-center me-3 data_grid"
                  rows={chatHistory}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 5 },
                    },
                  }}
                  pageSizeOptions={[5, 10]}
                  getRowId={(row) => row.id}
                />
              </Grid>
            </center>

            <div className="mobileCard">
              {chatHistory.map((i, j) => (
                <Card
                  className="mobileCard all_border"
                  key={j}
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    backgroundColor: "antiquewhite",
                  }}
                >
                  <Card.Body>
                    <Card.Subtitle className="mb-2">
                      <b>{t("USR.HOM.HD.UN")}:</b> {i.username}
                    </Card.Subtitle>

                    {/* <Card.Subtitle className="mb-2">
                      <b>Consultant Name:</b> {i.consultantname}
                    </Card.Subtitle> */}

                    <Card.Subtitle className="mb-2">
                      <b>{t("TXT.STS.LO")}:</b> {i.status}
                    </Card.Subtitle>

                    <Card.Subtitle className="mb-2">
                      <b>{t("CON.DURATION")}:</b> {i.duration}
                    </Card.Subtitle>

                    <Card.Subtitle className="mb-2">
                      <b>{t("USR.METNG.CDE")}:</b> {i.meetingCode}
                    </Card.Subtitle>

                    <Card.Subtitle className="mb-2">
                      <b>{t("CON.CHAT.DATE")}:</b> {i.chatDate}
                    </Card.Subtitle>

                    <Card.Subtitle className="mb-2">
                      <b>{t("USR.CHAT.STA.TIME")}:</b> {i.chatStartTime}
                    </Card.Subtitle>

                    <Card.Subtitle className="mb-2">
                      <b>{t("CON.CNT.STA.TIME")}:</b> {i.connectStartTime}
                    </Card.Subtitle>

                    <Card.Subtitle className="mb-2">
                      <b>{t("USR.CONT.END.TIME")}:</b> {i.connectEndTime}
                    </Card.Subtitle>
                  </Card.Body>
                </Card>
              ))}
            </div>
          </>
        ) : (
          <div>
            <center>
              <h5>{t("TXT.NO.REC.FUD.LO")}</h5>
            </center>
          </div>
        )
      ) : (
        <div className="text-center">
          <Spinner animation="border" variant="warning" />
        </div>
      )}
    </div>
  );
}

export default ConsultantChatHistory;
