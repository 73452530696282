import React, { useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import AdminAddAdminForm from "./AdminAddAdminForm";
import AdminAddClientForm from "./AdminAddClientForm";
import AdminAddProfForm from "./AdminAddProfForm";
import AdminAddCrmForm from "./AdminAddCrmForm";

const AdminAddRole = () => {
  const [key, setKey] = useState("client");
  return (
    <React.Fragment>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="justify-content-center"
      >
        <Tab eventKey="client" title="CUSTOMER">
          <center>
            <h4 className="m-1">
              <u>ADD CUSTOMER</u>
            </h4>
          </center>
          <AdminAddClientForm />
        </Tab>
        <Tab eventKey="professional" title="CONSULTANT">
          <center>
            <h4 className="m-1">
              <u>ADD CONSULTANT</u>
            </h4>
          </center>
          <AdminAddProfForm />
        </Tab>
        <Tab eventKey="admin" title="ADMIN">
          <center>
            <h4 className="m-1">
              <u>ADD ADMIN</u>
            </h4>
          </center>
          <AdminAddAdminForm />
        </Tab>
        <Tab eventKey="crm" title="CRM">
          <center>
            <h4 className="m-1">
              <u>ADD CRM</u>
            </h4>
          </center>
          <AdminAddCrmForm />
        </Tab>
      </Tabs>
    </React.Fragment>
  );
};

export default AdminAddRole;
