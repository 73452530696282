import React from "react";
import { Table } from "react-bootstrap";
import PropTypes from "prop-types";

const TableDesk = (props) => {
  return (
    <Table
      striped
      bordered
      hover
      responsive
      className="deskTable  all_border text-center"
    // style={{
    //   textAlign:'center'
    // }}
    >
      <thead>
        <tr>
          <th>Name</th>
          <th>PHONE</th>
          <th>EMAIL</th>
          <th>CATEGORY</th>
        </tr>
      </thead>
      <tbody>
        {props.data.map((i, j) => (
          <tr key={j}>
            <td>{i.name}</td>
            <td>{i.phoneNumber}</td>
            <td>{i.email}</td>
            <td>{i.consultantLevel}</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

TableDesk.propsType = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};
export default TableDesk;
