import React from "react";
import "../NewCard.css";

const NewBootCard = (props) => {
  return (
    <div className="container">
      <div className="row justify-content-center mt-2">
        <div className="col-xl-3 col-sm-6">
          <div className="card" style={{ border:'1px solid #ffb33e'}}>
            <div className="card-body" style={{ backgroundColor: 'antiquewhite' }}>
              {props.children}
              {/* <div className="d-flex align-items-center">
                <div className="flex-1 ms-3">
                  <center>
                    <h5 className="font-size-16 mb-1">Phyllis Gatlin</h5>
                  </center>
                </div>
              </div> */}
              {/* <div className="mt-3 pt-1">
                <p className="text-muted mb-0">
                  <i className="mdi mdi-phone font-size-15 align-middle pe-2 text-primary" />{" "}
                  070 2860 5375
                </p>
                <p className="text-muted mb-0 mt-2">
                  <i className="mdi mdi-email font-size-15 align-middle pe-2 text-primary" />{" "}
                  PhyllisGatlin@spy.com
                </p>
                <p className="text-muted mb-0 mt-2">
                  <i className="mdi mdi-google-maps font-size-15 align-middle pe-2 text-primary" />{" "}
                  52 Ilchester MYBSTER 9WX
                </p>
              </div>
              <div className="d-flex gap-2 pt-4">
                <button
                  type="button"
                  className="btn btn-soft-primary btn-sm w-50"
                >
                  <i className="bx bx-user me-1" /> Profile
                </button>
                <button type="button" className="btn btn-primary btn-sm w-50">
                  <i className="bx bx-message-square-dots me-1" /> Contact
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewBootCard;
