import React, { useEffect, useState } from "react";
import {
  Spinner,
  Container,
  Button,
  Alert,
  Card,
  Modal,
  ListGroup,
  Form,
  Col,
  Row,
  Badge,
} from "react-bootstrap";
import "../App.css";
import { vasthuUrl } from "../component/Api";
import { useNavigate, Link, Outlet, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AiOutlineStar, AiFillSchedule } from "react-icons/ai";
import { MdOutlineLanguage } from "react-icons/md";
import { GrUserExpert } from "react-icons/gr";
import { BiBadgeCheck } from "react-icons/bi";

const BookConsultant = () => {
  const token = window.sessionStorage.getItem("token");
  const location = useLocation();
  const selectService =
    location.state !== null ? location.state.p.serviceId : null;
  console.log("location", location);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [consultantList, setConsultantList] = useState([]);
  const [profList, setProfList] = useState([]);
  const [skill, setSkill] = useState("ALL");
  const [lang, setLang] = useState("ALL");
  const [searchField, setSearchField] = useState("");
  const [selectedService, setSelectedService] = useState(selectService);
  console.log("selectedService", selectedService);

  useEffect(() => {
    const data = window.sessionStorage.getItem("offerDetail");
    if (data) {
      const parsedData = JSON.parse(data);
      setSelectedService(parsedData.freeOffers[0].serviceId);
      console.log("parsedData", parsedData.freeOffers[0].serviceId);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    try {
      vasthuUrl
        .post(`info/professional/byskillorlang`, {
          skill:
            selectedService == 3
              ? "Vasthu Consultation"
              : selectedService == 4
              ? "Astrology Consultation"
              : selectedService == 5
              ? "Building Consultation"
              : skill,
          langCode: lang,
        })
        .then((res) => {
          setConsultantList(res.data);
          setLoading(false);
          console.log("con filter out", res.data);
        });
    } catch (error) {
      setConsultantList((prev) => prev);
      setLoading(false);
      console.log("consultlist", error);
    }
    try {
      vasthuUrl
        .get(`/info/professional/home`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("prolist", res.data);
          setProfList(res.data);
          setLoading(false);
        });
    } catch (e) {
      setLoading(false);
    }
  }, [selectedService]);

  const filteredPersons = consultantList.filter((person) => {
    return person.consultantName
      .toLowerCase()
      .includes(searchField.toLowerCase());
  });

  const handleChange = (e) => {
    setSearchField(e.target.value);
  };

  const CheckConsultant = (id) => {
    let value = profList.find((x) => x.consultantUuid === id.consultantUuid);
    const selectSubscriptionId =
      location.state !== null ? location.state.p.subscriptionId : null;
    const selectofferId = location.state !== null ? location.state.q : null;
    const selectDuration = location.state !== null ? location.state.p.duration : null;
    window.sessionStorage.setItem(
      "checkcon",
      JSON.stringify({
        value,
        selectedService,
        selectSubscriptionId,
        selectofferId,
        selectDuration,
      })
    );
    // navigate("/checkconsultant", {
    //   state: { value, selectedService },
    // });
    navigate("/checkconsultant");
  };

  return (
    <Container className="py-1 my-2">
      {!loading ? (
        consultantList.length > 0 ? (
          <section className="garamond">
            <center>
              <h1>{t("USR.SCH.BO.SEL.CON")}</h1>
            </center>
            <div class="container">
              <div class="row d-flex justify-content-end align-items-center my-2">
                <div class="">
                  <div class="form">
                    <input
                      type="text"
                      class="form-control form-input form_feild"
                      placeholder={t("CM.CNSL.SER.PL")}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
            {!loading ? (
              <div>
                <Row className="d-flex  g-1">
                  {filteredPersons.length > 0 ? (
                    filteredPersons.map((i, j) => (
                      <>
                        {i.professionalAccountStatus === "ACTIVATE" ? (
                          <Col md={6} lg={3}>
                            <Card
                              className="d-flex all_border"
                              key={i.consultantUuid}
                            >
                              <Card.Header className="d-flex justify-content-between align-items-start mt-2 all_border">
                                {i.imgUrl ? (
                                  <Col lg={2}>
                                    {/* <img
                        src={i.imgUrl} // static img
                        className="rounded-circle img-fluid"
                        alt="avatar pic"
                        style={{ width: "80px", height: "60px" }}
                      /> */}
                                    <img
                                      src={i.imgUrl}
                                      style={{
                                        borderRadius: "50%",
                                        height: "50px",
                                        width: "50px",
                                        margin: "-9px",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </Col>
                                ) : (
                                  <Col lg={2}>
                                    <img
                                      src={require("../images/noimage.jpg")}
                                      style={{
                                        borderRadius: "50%",
                                        height: "50px",
                                        width: "50px",
                                        margin: "-9px",
                                        objectFit: "cover",
                                      }}
                                    />
                                  </Col>
                                )}
                                <Col lg={8}>
                                  <Row style={{ whiteSpace: "nowrap" }}>
                                    <strong>{i.consultantName}</strong>
                                    <small>
                                      <MdOutlineLanguage />
                                      {i.knownLangugae},{i.extraLang}
                                    </small>
                                    <small>
                                      <BiBadgeCheck />
                                      {i.skills},{i.extraSkill}
                                    </small>
                                  </Row>
                                </Col>
                                <Col lg={2} className="align-items-center">
                                  <Row className="d-flex g-1">
                                    <Badge
                                      className="d-flex"
                                      pill
                                      bg="warning"
                                      text="dark"
                                      size="sm"
                                    >
                                      <AiOutlineStar size={15} />
                                      {i.consultantRating}
                                    </Badge>
                                  </Row>
                                </Col>
                              </Card.Header>
                              <Card.Body className="text-center">
                                <Row>
                                  <Col className="d-flex justify-content-around">
                                    <h6>
                                      <GrUserExpert /> {i.expYear}Year
                                    </h6>
                                    <Button
                                      className="all_btn"
                                      style={{ borderRadius: 50 }}
                                      onClick={() => {
                                        CheckConsultant(i);
                                      }}
                                    >
                                      {t("USR.BO.NOW")}
                                    </Button>
                                  </Col>
                                </Row>
                              </Card.Body>
                            </Card>
                          </Col>
                        ) : null}
                      </>
                    ))
                  ) : (
                    <div className="mt-5">
                      <center>
                        <h3>Not Available Consultant!!!</h3>
                      </center>
                    </div>
                  )}
                </Row>
              </div>
            ) : (
              <center>
                <Spinner animation="border" />
              </center>
            )}
          </section>
        ) : (
          <center>
            <h1>No Consultation Available</h1>
          </center>
        )
      ) : (
        <center>
          <Spinner animation="border" variant="warning" />
        </center>
      )}
    </Container>
  );
};
export default BookConsultant;
