import React, { useEffect, useState } from "react";
import { vasthuUrl } from "../../component/Api";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Button,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from "react-bootstrap";
import { HeartOutlined, HeartFilled } from "@ant-design/icons";
import { IoCall } from "react-icons/io5";
import { useTranslation } from "react-i18next";

function UserBookedProduct() {
  const { t } = useTranslation();

  const token = window.sessionStorage.getItem("token");
  const userId = window.sessionStorage.getItem("userId");
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  //toast aleart
  const [openSnack, setOpenSnack] = React.useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("error");

  useEffect(() => {
    setLoading(true);
    try {
      vasthuUrl
        .get(`vasthu/getAllUserBookedProductsbyUserId/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setData(res.data);
          console.log("res.data", res.data);
          setLoading(false);
        });
    } catch (error) {
      console.log("error2", error);
      setLoading(false);
    }
  }, []);

  const handleContact = async () => {
    try {
      await vasthuUrl
        .post(
          `vasthu/callFromUserToAdminForProductBooking`,
          {
            from: userId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          alert("Booked Product Successfully!!!");
          setMessageType("success");
          if (res.status == 200) {
            navigate("/audiocallstatus", {
              state: { callStatus: "USR.CAL.PROCED.BTN" },
            });
          } else {
            navigate("/audiocallstatus", {
              state: { callStatus: "USR.CAL.FAILD.BTN" },
            });
          }
        });
    } catch (err) {
      alert(err);
      setMessageType("error");
    }
  };

  return (
    <React.Fragment>
      <center>
        <h2>
          <u>{t("USR.PRO.BOK.TL")}</u>
        </h2>
      </center>
      {!loading ? (
        data.map((i, j) => (
          <Card key={j} className="m-2 p-2 all_border">
            <Row style={{ alignItems: "center" }}>
              <Col
                sm={12}
                md={3}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Card.Img
                  id="mainimg"
                  src={i.productimgUrl1}
                  style={{
                    height: "100px",
                    width: "150px",
                    objectFit: "contain",
                    margin: "5px",
                  }}
                />
              </Col>
              <Col sm={12} md={7}>
                <Row style={{ display: "flex" }}>
                  <Col xs={6} md={4} lg={3}>
                    <Card.Text style={{ fontWeight: "bold" }}>
                      {t("USR.LIK.PRO")}
                    </Card.Text>
                    <Card.Text style={{ fontWeight: "bold" }}>
                      {t("USR.PRO.BOK.TYP")}
                    </Card.Text>
                    <Card.Text style={{ fontWeight: "bold" }}>
                      {t("TXT.DES.LO")}
                    </Card.Text>
                  </Col>
                  <Col xs={6} md={8} lg={9}>
                    <Card.Text>
                      <b>:</b>&nbsp;&nbsp;{i.productName}
                    </Card.Text>
                    <Card.Text>
                      <b>:</b>&nbsp;&nbsp;{i.productType}
                    </Card.Text>
                    <Card.Text>
                      <b>:</b>&nbsp;&nbsp;{i.productShortDescription}
                    </Card.Text>
                  </Col>
                </Row>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <OverlayTrigger
                    key="top"
                    placement="top"
                    overlay={<Tooltip id={`tooltip-top`}>9363919113</Tooltip>}
                  >
                    {/* <Button className="all_btn">
                      {/* <a
                        style={{ textDecoration: "none", color: "black" }}
                        href="tel:+9363919113"
                      >
                       */}

                    {/* {t("TXT.CONCT.US.LO")} <IoCall size={20} />
                     */}

                    {/* </a> */}
                    {/* </Button> */}
                    <Button
                      className="all_btn"
                      onClick={() => {
                        handleContact();
                      }}
                    >
                      {t("TXT.CONCT.US.LO")} <IoCall size={20} />
                    </Button>
                  </OverlayTrigger>
                </div>
              </Col>
              <Col
                sm={12}
                md={2}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <HeartFilled style={{ color: "red" }} />
              </Col>
            </Row>
          </Card>
        ))
      ) : (
        <center>
          <Spinner animation="border" variant="warning" />
        </center>
      )}
    </React.Fragment>
  );
}

export default UserBookedProduct;
