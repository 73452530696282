import React, { useEffect, useState } from "react";
import { Container, Tabs, Tab } from "react-bootstrap";
import OurCallServices from "./OurCallServices";
import { t } from "i18next";

function TalkNow() {
  const [serviceType, setServiceType] = useState("Wallet");
  const [pendingAction, setPendingAction] = useState(null);

  const executePendingAction = () => {
    const pendingAction1 = window.sessionStorage.getItem("pendingAction1");
    const type = window.sessionStorage.getItem("details");
    console.log("pendingAction1", pendingAction1);
    console.log("pendingtype", type);
    setPendingAction(pendingAction1);
    if (type) {
      setServiceType(type);
    }
  };

  // Call executePendingAction after login
  useEffect(() => {
    executePendingAction();
  }, []);

  const handleSelect = (key) => {
    setServiceType(key);
  };

  return (
    <Tabs
      defaultActiveKey={serviceType}
      id="customerServiceTab"
      onSelect={handleSelect}
      className="all_border mt-2 TalkNow"
      justify
    >
      <Tab eventKey="Wallet" title={t("USR.MIN.BASED")}>
        <OurCallServices type={serviceType} action={pendingAction} />
      </Tab>
      <Tab eventKey="Duration" title={t("USR.DUR.BASED")}>
        <OurCallServices type={serviceType} action={pendingAction} />
      </Tab>
    </Tabs>
  );
}

export default TalkNow;
