import { HeartFilled, HeartOutlined } from "@ant-design/icons";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Stacks from "@mui/material/Stack";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Image,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { useTranslation } from "react-i18next";
import { IoCall } from "react-icons/io5";
import InnerImageZoom from "react-inner-image-zoom";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.css";
import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
import { useLocation, useNavigate } from "react-router-dom";
import { vasthuUrl } from "../../component/Api";
import stone from "../../images/stone.jpg";
import vilaku from "../../images/vilaku.jpg";

function UserProductDetail() {
  const location = useLocation();
  console.log("location", location);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const productId = location.state;
  const userId = window.sessionStorage.getItem("userId");
  const token = window.sessionStorage.getItem("token");
  const [productDetail, setProductDetail] = useState([]);
  const [loading, setLoading] = useState(false);
  const [likedProducts, setLikedProducts] = useState([]);
  const [mainImg, setMainImg] = useState();
  const [fullImg, setFullImg] = useState();
  const [firstImg, setFirstImg] = useState();
  const [secondImg, setSecondImg] = useState();
  const [thirdImg, setThirdImg] = useState();
  const [fourthImg, setFourthImg] = useState();
  const [fivethImg, setfivethImg] = useState();
  const [like, setLike] = useState(true);
  console.log("like", like);
  console.log("productId", productId);
  //toast aleart
  const [openSnack, setOpenSnack] = React.useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("error");
  const lang = localStorage.getItem("i18nextLng");

  const images = [mainImg, vilaku, stone];

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const alert = (msg) => {
    setMessage(msg);
    setOpenSnack(true);
    setTimeout(() => {
      setMessage("");
    }, 4000);
  };

  useEffect(() => {
    setLoading(true);
    try {
      vasthuUrl
        .get(`vasthu/user/product/${productId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setProductDetail(res.data);
          if (res.data.productimgUrl1) {
            setMainImg(res.data.productimgUrl1);
            setFullImg(res.data.productimgUrl1);
          }
          if (res.data.productimgUrl2) {
            setFirstImg(res.data.productimgUrl2);
          }
          if (res.data.productimgUrl3) {
            setSecondImg(res.data.productimgUrl3);
          }
          if (res.data.productimgUrl4) {
            setThirdImg(res.data.productimgUrl4);
          }
          if (res.data.productimgUrl5) {
            setFourthImg(res.data.productimgUrl5);
          }
          if (res.data.productimgUrl6) {
            setfivethImg(res.data.productimgUrl6);
          }
          setLoading(false);
          console.log("res.data", res.data);
        });
    } catch (error) {
      console.log("error2", error);
      setLoading(false);
    }
    try {
      vasthuUrl
        .get(`vasthu/getUserLikedProductsbyUserId/${productId}/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data[0]) {
            setLike(res.data[0].productLiked);
          }
          console.log("res.data", res.data);
        });
    } catch (error) {
      console.log("error2", error);
      setLoading(false);
    }
  }, []);

  const handleLIke = async (like) => {
    try {
      await vasthuUrl
        .post(
          `vasthu/updateUserLikedProducts`,
          {
            productId: productDetail.id,
            userId: userId,
            productLiked: like,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setLikedProducts(res.data);
          alert("Liked this Product Successfully!!!");
          setMessageType("success");
          // setTimeout(() => {
          //   navigate("/likedproduct");
          // }, 2000);
        });
    } catch (err) {
      console.log("offer buy in home screen", err);
      alert(err);
      setMessageType("error");
    }
  };

  const handleUnLIke = async (UnLike) => {
    try {
      await vasthuUrl
        .post(
          `vasthu/updateUserLikedProducts`,
          {
            productId: productDetail.id,
            userId: userId,
            productLiked: UnLike,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          setLikedProducts(res.data);
          alert("Unliked this Product Successfully!!!");
          setMessageType("success");
          // setTimeout(() => {
          //   navigate("/likedproduct");
          // }, 2000);
        });
    } catch (err) {
      console.log("offer buy in home screen", err);
      alert(err);
      setMessageType("error");
    }
  };

  const handleBook = async () => {
    try {
      await vasthuUrl
        .post(
          `vasthu/user/product/saveUserBookedProduct`,
          {
            productId: productDetail.id,
            userId: userId,
            isproductbooked: true,
            lng: lang,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          alert("Booked Product Successfully!!!");
          setMessageType("success");
          // setTimeout(() => {
          //   navigate("/bookedproduct");
          // }, 2000);
          bookModalClose();
        });
    } catch (err) {
      alert(err);
      setMessageType("error");
    }
  };

  const handleContact = async () => {
    try {
      await vasthuUrl
        .post(
          `vasthu/callFromUserToAdminForProductBooking`,
          {
            from: userId,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          alert("Booked Product Successfully!!!");
          setMessageType("success");
          if (res.status == 200) {
            navigate("/audiocallstatus", {
              state: { callStatus: "USR.CAL.PROCED.BTN" },
            });
          } else {
            navigate("/audiocallstatus", {
              state: { callStatus: "USR.CAL.FAILD.BTN" },
            });
          }
        });
    } catch (err) {
      alert(err);
      setMessageType("error");
    }
  };

  const details = {
    type: "Reiki Crystal Feng Shui & Vastu Showpiece",
    material: "Pooja",
    suitableFor: "Table",
    dimensions: "6 cm x 6 cm x 6 cm",
    weight: "190 g",
    description:
      "The Rudraksha Dolar hails from Nepal, known for its quality and authenticity in producing Rudraksha beads,Crafted from Rudraksha seeds, revered in Hinduism, it carries spiritual significance and is believed to possess divine properties, Wearing the Nepali Rudraksha Dolar is thought to provide spiritual protection and promote inner peace, according to traditional beliefs.As a pendant worn around the neck, it serves as a tangible connection to one's spiritual beliefs and traditions, symbolizing ancient wisdom and devotion.",
    specification:
      "This Ring is called Shadakshara Ring or Star Of David . It is Symbol of Lord Sri Suberamaniyer & Planet Mars.  and  It helps  for Protection ,Property Prospers  and getting or Promotion of Powerful Jobs like Defence Minister, Army and Police High official rank jobs.",
  };
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  // const lines = details.description.split('\n').slice(0, 3).join('\n');
  // const renderText = () => {
  //   if (showAll) {
  //     return details.description;
  //   } else {
  //     const lines = details.description.split('\n').slice(0, 3).join('\n');
  //     return (
  //       <>
  //         {lines}
  //         {details.description.split('\n').length > 3 && (
  //           <span style={{ cursor: 'pointer', color: 'blue', textIndent: '30%' }} onClick={toggleShowAll}>
  //             ... Show More
  //           </span>
  //         )}
  //       </>
  //     );
  //   }
  // };

  const [showFullText, setShowFullText] = useState(false);
  const [showFullModal, setshowFullModal] = useState(false);

  const toggleShowFullText = () => {
    setShowFullText(!showFullText);
  };

  const [show, setShow] = useState(false);
  const [data, setData] = useState([""]);
  const handleClose = () => {
    setShow(false);
    setshowFullModal(!showFullModal);
  };
  const handleShow = () => setShow(true);
  const handleOpen = (i) => {
    setshowFullModal(!showFullModal);
    setShow(true);
  };

  // BOOK PRODUCT MODAL
  const [bookModal, setBookModel] = useState(false);

  const bookModalClose = () => {
    setBookModel(false);
    // setDeleteId("");
  };

  const bookModalOpen = (i) => {
    setBookModel(true);
    // setDeleteId(i.id);
  };

  return (
    <div>
      {!loading ? (
        <div>
          <Row className="p-1">
            <Col sm={12} md={6}>
              <Card>
                <div>
                  <div
                    style={{
                      color: "red",
                      fontSize: 30,
                      width: "100%",
                      display: "flex",
                      justifyContent: "flex-end",
                      position: "absolute",
                    }}
                  >
                    {like ? (
                      <HeartOutlined
                        size={25}
                        onClick={(i) => {
                          handleLIke(true);
                          setLike(false);
                        }}
                      />
                    ) : (
                      <HeartFilled
                        onClick={(i) => {
                          handleUnLIke(false);
                          setLike(true);
                        }}
                      />
                    )}
                  </div>

                  <div className="zoom_img">
                    <InnerImageZoom
                      style={{
                        height: "300px",
                      }}
                      src={mainImg}
                      zoomSrc={mainImg}
                      zoomType="hover"
                      zoomPreload={true}
                    />
                  </div>

                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    {fullImg ? (
                      <Image
                        src={fullImg}
                        className="product_subImg"
                        onMouseOver={() => setMainImg(fullImg)}
                      />
                    ) : null}
                    {firstImg ? (
                      <Image
                        src={firstImg}
                        className="product_subImg"
                        onMouseOver={() => setMainImg(firstImg)}
                      />
                    ) : null}
                    {secondImg ? (
                      <Image
                        src={secondImg}
                        className="product_subImg"
                        onMouseOver={() => setMainImg(secondImg)}
                      />
                    ) : null}
                    {thirdImg ? (
                      <Image
                        src={thirdImg}
                        className="product_subImg"
                        onMouseOver={() => setMainImg(thirdImg)}
                      />
                    ) : null}
                    {fourthImg ? (
                      <Image
                        src={fourthImg}
                        className="product_subImg"
                        onMouseOver={() => setMainImg(fourthImg)}
                      />
                    ) : null}
                    {fivethImg ? (
                      <Image
                        src={fivethImg}
                        className="product_subImg"
                        onMouseOver={() => setMainImg(fivethImg)}
                      />
                    ) : null}
                  </div>
                </div>
                <Card.Footer className="d-flex justify-content-evenly">
                  <Button
                    className="all_btn mb-2"
                    onClick={() => {
                      bookModalOpen();
                    }}
                  >
                    {t("USR.HOM.CL.ACTION2")}
                  </Button>
                  <OverlayTrigger
                    key="top"
                    placement="top"
                    overlay={
                      <Tooltip id={`tooltip-top`}>+91 9363919113</Tooltip>
                    }
                  >
                    {/* <Button className="all_btn">
                      <a
                        style={{ textDecoration: "none", color: "black" }}
                        href="tel:+9363919113"
                      >
                        {t("TXT.CONCT.US.LO")} <IoCall size={20} />
                      </a>
                    </Button> */}
                    <Button
                      className="all_btn"
                      onClick={() => {
                        handleContact();
                      }}
                    >
                      {t("TXT.CONCT.US.LO")} <IoCall size={20} />
                    </Button>
                  </OverlayTrigger>
                </Card.Footer>
              </Card>
            </Col>
            <Col sm={12} md={6} style={{ overflowY: "scroll", height: "85vh" }}>
              <div className="mt-3">
                <Card.Body className="mobile_card_view">
                  <Card.Text style={{ fontWeight: "500", fontSize: "20px" }}>
                    {productDetail.productName}
                  </Card.Text>
                  {/* <Card.Text>
                    <b>₹ {productDetail.basePrice} </b>{" "}
                  </Card.Text> */}
                  <Card.Text>
                    <b>{t("TXT.TYP.LO")}:</b> {productDetail.productType}
                  </Card.Text>
                  {productDetail.productSubType &&
                  productDetail.productSubType ? (
                    <Card.Text>
                      <b>{t("TXT.SUB.TYP.LO")}:</b>
                      {productDetail.productSubType}
                    </Card.Text>
                  ) : null}
                  {productDetail.productFullDescription &&
                  productDetail.productFullDescription ? (
                    <Card.Text>
                      <b>{t("TXT.DES.LO")}</b> <br />
                      <p style={{ textIndent: "10%" }}>
                        {" "}
                        {productDetail.productFullDescription}
                      </p>
                    </Card.Text>
                  ) : null}
                </Card.Body>
                {/* <Card.Text className="mt-1" style={{ color: "gray" }}>
                  <b>Highlights</b>
                </Card.Text> */}
                {/* <ul className="ms-5">
                  <li style={{ listStyle: "disc" }}>Type: {details.type}</li>
                  <li style={{ listStyle: "disc" }}>
                    Material: {details.material}
                  </li>
                  <li style={{ listStyle: "disc" }}>
                    Suitable For: {details.suitableFor}
                  </li>
                  <li style={{ listStyle: "disc" }}>
                    Dimensions: {details.dimensions}
                  </li>
                  <li style={{ listStyle: "disc" }}>
                    Weight: {details.weight}
                  </li>
                </ul> */}
              </div>
              {/* <Card.Text style={{ color: "gray", fontWeight: "bold" }}>
                Specification
              </Card.Text> */}
              {/* <Card.Text>
                {showFullText ? (
                  <p style={{ textIndent: "15%" }}>
                    {details.description}
                    <span
                      style={{
                        cursor: "pointer",
                        color: "#2874F0",
                        fontWeight: "500",
                      }}
                      onClick={toggleShowFullText}
                    >
                      ...Read Less
                    </span>
                  </p>
                ) : (
                  <p style={{ textIndent: "15%" }}>
                    {`${details.description.slice(0, 200)}`}
                    <span
                      style={{
                        cursor: "pointer",
                        color: "#2874F0",
                        fontWeight: "500",
                      }}
                      onClick={toggleShowFullText}
                    >
                      ....Read more.
                    </span>
                  </p>
                )}
              </Card.Text>
              <Card.Text style={{ color: "gray", fontWeight: "bold" }}>
                Benefits
              </Card.Text>

              <Card.Text>
                {showFullModal ? (
                  <p style={{ textIndent: "15%" }}>{details.specification}</p>
                ) : (
                  <p style={{ textIndent: "15%" }}>
                    {`${details.specification.slice(0, 110)}`}
                    <span
                      style={{
                        cursor: "pointer",
                        color: "#2874F0",
                        fontWeight: "500",
                      }}
                      onClick={() => handleOpen()}
                    >
                      ...View details
                    </span>
                  </p>
                )}
              </Card.Text> */}
            </Col>

            {/* <div classNameName="d-flex justify-content-end">
              <Button
                classNameName="all_btn1"
                onClick={() => navigate("/userproduct")}
              >
               {t("TXT.CLO.LO")}
              </Button>
            </div> */}
          </Row>

          <Modal className="all_border" show={show} onHide={handleClose}>
            <Modal.Header className="all_background" closeButton>
              <Modal.Title>Benefits</Modal.Title>
            </Modal.Header>
            <Modal.Body>{details.specification}</Modal.Body>
          </Modal>
        </div>
      ) : (
        <center>
          <Spinner animation="border" variant="warning" />
        </center>
      )}

      <Modal className="all_border" show={bookModal} onHide={bookModalClose}>
        <Modal.Header className="all_background">
          <center>
            <b>{t("USR.PRO.BK.MDL.HD")}</b>
          </center>
        </Modal.Header>
        <Modal.Body>{t("USR.PRO.BK.MDL.MSG")}</Modal.Body>
        <Modal.Footer className="d-flex justify-content-evenly all_border">
          <Button
            className="all_btn mb-2"
            onClick={() => {
              bookModalClose();
            }}
          >
            {t("USR.VSH.HFTAB.MDL.CNF.BTN.CNC")}
          </Button>
          <Button
            className="all_btn mb-2"
            onClick={() => {
              handleBook();
            }}
          >
            {t("USR.HOM.CL.ACTION2")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Stacks spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleCloseSnack}
        >
          <MuiAlert
            variant="filled"
            onClose={handleCloseSnack}
            severity={messageType}
            sx={{
              width: "100%",
            }}
          >
            {message}
          </MuiAlert>
        </Snackbar>
      </Stacks>
    </div>
    // <div className="container_zoom">
    //   <div className="left_zoom">
    //     <div className="left_1_zoom">
    //       {images.map((image, i) => (
    //         <div
    //           className={i == 0 ? "img_wrap_zoom active" : "img_wrap_zoom"}
    //           key={i}
    //           onMouseOver={() => hoverHandler(image, i)}
    //           ref={addRefs}
    //         >
    //           <img src={image} alt="" />
    //         </div>
    //       ))}
    //     </div>
    //     <div className="left_2_zoom">
    //       <ReactImageMagnify
    //         {...{
    //           smallImage: {
    //             alt: "Wristwatch by Ted Baker London",
    //             isFluidWidth: true,
    //             src: img,
    //           },
    //           largeImage: {
    //             src: img,
    //             width: 1200,
    //             height: 1800,
    //           },
    //           enlargedImageContainerDimensions: {
    //             width: "150%",
    //             height: "150%",
    //           },
    //         }}
    //       />
    //     </div>
    //   </div>
    //   <div className="right_zoom"></div>
    // </div>
  );
}

export default UserProductDetail;
