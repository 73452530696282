import React, { useEffect, useState } from "react";
import {
  Accordion,
  Badge,
  Button,
  Container,
  Table,
  Spinner,
  Modal,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { vasthuUrl } from "../component/Api";
import PaymentProcess from "../component/PaymentProcess";
import { UseAuth } from "../context/Auth";
import { UseVasthu } from "../context/vasthu";
import BillDeskPay from "../component/BillDeskPay";
import ObjectEmpty from "../component/ObjectEmptyChecker";
import NewBootCard from "../component/NewBootCard";

const PendingValidationRecords = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  // const userId = window.sessionStorage.getItem("userId");
  // const token = window.sessionStorage.getItem("token");
  const role = window.sessionStorage.getItem("role");
  const token = window.sessionStorage.getItem("token");
  const user = window.sessionStorage.getItem("userId");
  const crmSelectedUser = window.sessionStorage.getItem("crmSelectedUser");
  const userId = token && role == "ROLE_CRM" ? crmSelectedUser : user;
  const { addPlotRecId } = UseVasthu();
  const { addHomeRecId } = UseVasthu();
  const [loading, setLoading] = useState(true);
  const [payLaterPlotValidation, setPayLaterPlotValidation] = useState([]);
  console.log("payLaterPlotValidation");
  const [payLaterHomeValidation, setPayLaterHomeValidation] = useState([]);
  const [showPlotPaytm, setShowPlotPaytm] = useState(false);
  const [showHomePaytm, setShowHomePaytm] = useState(false);
  const [paymentCredentialsPlot, setPaymentCredentialsPlot] = useState({
    txnToken: "",
    orderId: "",
    amount: 0,
    offerId: 0,
    userId: "",
  });
  const [paymentCredentialsHome, setPaymentCredentialsHome] = useState({
    txnToken: "",
    orderId: "",
    amount: 0,
    offerId: 0,
    userId: "",
  });
  const [defaultHomeOffer, setDefaultHomeOffer] = useState({
    offerId: 0,
    offerPrice: 0,
  });
  const [defaultPlotOffer, setDefaultPlotOffer] = useState({
    offerId: 0,
    offerPrice: 0,
  });
  const [payLaterPlotLen, setPayLaterPlotLen] = useState(0);
  const [payLaterHomeLen, setPayLaterHomeLen] = useState(0);

  const [show, setShow] = useState(false);
  const [activeServiceList, setActiveServiceList] = useState([]);
  const [homeid, setHomeId] = useState();
  console.log("homeid", homeid);

  useEffect(() => {
    try {
      setLoading(true);
      vasthuUrl
        .get(`/pendingPlotValidation/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("LEN OF PLOT" + res.data + "len" + res.data.length);
          setPayLaterPlotValidation(res.data);
          setPayLaterPlotLen(res.data.length);
          setLoading(false);
        });
    } catch (e) {
      alert("SERVER ERROR");
      setLoading(false);
    }

    vasthuUrl
      .get(`offer/defaultIndividualOffer/plot`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setDefaultPlotOffer({
          offerId: res.data.id,
          offerPrice: res.data.offerPrice,
        });
        setLoading(false);
      });

    try {
      setLoading(true);
      vasthuUrl
        .get(`/getPendingHomeValidation/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("LEN OF HOME" + res.data.length);
          console.log("res.data", res.data);
          setPayLaterHomeValidation(res.data);
          setPayLaterHomeLen(res.data.length);
          setLoading(false);
        });
    } catch (e) {
      alert("SERVER ERROR");
      setLoading(false);
    }

    vasthuUrl
      .get(`offer/defaultIndividualOffer/home`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setDefaultHomeOffer({
          offerId: res.data.id,
          offerPrice: res.data.offerPrice,
        });
        setLoading(false);
        console.log("res.data", res.data);
      });
  }, []);

  const submitValidate = (id) => {
    setHomeId(id);
    try {
      vasthuUrl
        .get(`/subscription/userActiveOffers/${userId}/2`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async (list) => {
          console.log(
            "Activeservice-list-if available " + JSON.stringify(list.data)
          );
          setActiveServiceList(list.data);

          if (list.data.length > 0) {
            openModal(id);
          } else {
            handleBuyHome(
              defaultHomeOffer.offerId,
              defaultHomeOffer.offerPrice,
              id
            );
          }
        });
    } catch (e) {
      alert("CHECK INTERNET CONNECTION");
      setLoading(false);
    }
  };

  const handleBuyPlot = async (val, price, plotId) => {
    try {
      await vasthuUrl
        .post(
          `vasthu/openapi/createorderapi`,
          {
            userUuid: userId,
            offerId: val,
            price: price,
            currency: "356",
            returnUrl: "plotvalidate",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(async (res) => {
          addPlotRecId(plotId);
          BillDeskPay(res.data, BillPlotResponseHandler)
            .then((config) => {
              console.log("paylater pending pay", config);
              window.loadBillDeskSdk(config);
            })
            .catch((err) => console.log("paylater pending error", err));
        });
    } catch (err) {
      console.log(err);
    }
  };

  const BillPlotResponseHandler = (txn) => {
    vasthuUrl
      .post(
        "vasthu/user/bdpaymentresponseui",
        { txn: txn.txnResponse.transaction_response },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("txnres", res.data);
        // // alert(res.data)
        // if (res.data) {
        //   navigate("/plotvalidate", { state: res.data });
        // }
        // alert(res.data)

        if (!ObjectEmpty(res.data)) {
          console.log("authstatus", res.data.auth_status);
          //newly added
          if (res.data.auth_status === "0300") {
            console.log("Saving payment_response ....", txn);

            vasthuUrl
              .post("vasthu/user/bdpaymentresponsehandler", txn, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((res) => console.log("after txnsave", res.data))
              .catch((err) => console.log("after txnsave try", err));

            //   navigate("/plotvalidate", { state: res.data });
            // }else{
            //   alert("Transaction failed, please go back")
            // }

            console.log("buying .. subsc");

            setTimeout(() => {
              vasthuUrl
                .post(
                  "subscription/buyVasthuOffer",
                  {
                    orderId: res.data.orderid,
                    userUuid: res.data.additional_info.additional_info1,
                    offerId: parseInt(
                      res.data.additional_info.additional_info2
                    ),
                    paymentStatus: true,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((res) => {
                  console.log("Payment status changed to true");
                  console.log("afterbuysubscr", res.data);
                  navigate("/plotvalidate", {
                    state: { offerId: res.data.id },
                  });
                  //  this.props.navigate(this.props.screenName,{state:{offerId:res.data.id}})
                });
            }, 5000);
          } else {
            vasthuUrl
              .post(
                "subscription/buyVasthuOffer",
                {
                  orderId: res.data.orderid,
                  userUuid: res.data.additional_info.additional_info1,
                  offerId: parseInt(res.data.additional_info.additional_info2),
                  paymentStatus: false,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((res) => {
                console.log("Payment status changed to false");
              });
          }
          //end - need to verify
        }
      });
  };

  const handleBuyHome = async (val, price, houseUuid) => {
    try {
      await vasthuUrl
        .post(
          `vasthu/openapi/createorderapi`,
          {
            userUuid: userId,
            offerId: val,
            price: price,
            currency: "356",
            returnUrl: "homevalidation",
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(async (res) => {
          addHomeRecId(houseUuid);
          BillDeskPay(res.data, BillHomeResponseHandler)
            .then((config) => {
              console.log("paylater pending pay", config);
              window.loadBillDeskSdk(config);
            })
            .catch((err) => console.log("paylater pending error", err));
        });
    } catch (err) {
      console.log(err);
    }
  };

  const BillHomeResponseHandler = (txn) => {
    vasthuUrl
      .post(
        "vasthu/user/bdpaymentresponseui",
        { txn: txn.txnResponse.transaction_response },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("txnres", res.data);
        // // alert(res.data)
        // if (res.data) {
        //   navigate("/homevalidation", { state: res.data });
        // }
        if (!ObjectEmpty(res.data)) {
          console.log("authstatus", res.data.auth_status);
          //newly added
          if (res.data.auth_status === "0300") {
            console.log("Saving payment_response ....", txn);

            vasthuUrl
              .post("vasthu/user/bdpaymentresponsehandler", txn, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((res) => console.log("after txnsave", res.data))
              .catch((err) => console.log("after txnsave try", err));

            //   navigate("/homevalidation", { state: res.data });

            // }else{
            //   alert("Transaction failed, please go back")
            // }

            console.log("buying .. subsc");

            setTimeout(() => {
              vasthuUrl
                .post(
                  "subscription/buyVasthuOffer",
                  {
                    orderId: res.data.orderid,
                    userUuid: res.data.additional_info.additional_info1,
                    offerId: parseInt(
                      res.data.additional_info.additional_info2
                    ),
                    paymentStatus: true,
                  },
                  {
                    headers: {
                      Authorization: `Bearer ${token}`,
                    },
                  }
                )
                .then((res) => {
                  console.log("Payment status changed to true");
                  console.log("afterbuysubscr", res.data);
                  navigate("/homevalidation", {
                    state: { offerId: res.data.id },
                  });
                  //  this.props.navigate(this.props.screenName,{state:{offerId:res.data.id}})
                });
            }, 5000);
          } else {
            vasthuUrl
              .post(
                "subscription/buyVasthuOffer",
                {
                  orderId: res.data.orderid,
                  userUuid: res.data.additional_info.additional_info1,
                  offerId: parseInt(res.data.additional_info.additional_info2),
                  paymentStatus: false,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((res) => {
                console.log("Payment status changed to false");
              });
          }
          //end - need to verify
        }
      });
  };

  const openModal = (id) => {
    setShow(true);
  };

  return (
    <div>
      <Accordion defaultActiveKey={["2"]}>
        {/* <Accordion.Item eventKey="1" className="accordian_style">
          <Accordion.Header>
            {t("USR.BD.PLTAB.ACR.PV")} &nbsp;&nbsp;&nbsp;{" "}
            <Badge bg="dark">{payLaterPlotLen}</Badge>
          </Accordion.Header>
          <Accordion.Body>
            {!loading ? (
              payLaterPlotValidation.length > 0 ? (
                <div>
                  <Table
                    striped
                    bordered
                    hover
                    responsive
                    className="deskTable text-center all_border"
                  >
                    <thead>
                      <tr>
                        <th>{t("USR.VFPR.TBL.CLMN.OWN")}</th>
                        <th>{t("USR.VFPR.TBL.CLMN.AR")}</th>
                        <th>{t("USR.VFP.DRP.LBL.LG")}</th>
                        <th>{t("USR.BD.HMTAB.TBL.CLM.MF")}</th>
                        <th>{t("USR.BD.PLTAB.ACR.TB.PUR")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payLaterPlotValidation.map((i, j) => (
                        <tr style={{ textAlign: "center" }} key={j}>
                          <td>{i.ownerName}</td>
                          <td>{i.plotArea}</td>
                          <td>{i.lagnaName}</td>
                          <td>{i.directionName}</td>
                          <td>
                            <Button
                              className="all_btn"
                              onClick={() =>
                                handleBuyPlot(
                                  defaultPlotOffer.offerId,
                                  defaultPlotOffer.offerPrice,
                                  i.plotId
                                )
                              }
                            >
                              {t("USR.BD.PLTAB.ACR.BTN.PY")}
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <div className=" mobileCard justify-content-center mx-1">
                    {payLaterPlotValidation.map((i, j) => (
                      <NewBootCard key={j}>
                        <h4>
                          {t("USR.VFPR.TBL.CLMN.OWN")}: {i.ownerName}
                        </h4>
                        <h6>
                          {t("USR.VFPR.TBL.CLMN.AR")}: {i.plotArea}
                        </h6>
                        <h6>
                          {t("USR.VFP.DRP.LBL.LG")}: {i.lagnaName}
                        </h6>
                        <h6>
                          {t("USR.BD.HMTAB.TBL.CLM.MF")}: {i.directionName}
                        </h6>

                        <Button
                          className="all_btn"
                          onClick={() => {
                            handleBuyPlot(
                              defaultPlotOffer.offerId,
                              defaultPlotOffer.offerPrice,
                              i.plotId
                            );
                          }}
                        >
                          {t("USR.BD.PLTAB.ACR.BTN.PY")}
                        </Button>
                      </NewBootCard>
                    ))}
                  </div>
                </div>
              ) : (
                <center>
                  <h5>{t("TXT.NO.REC.FUD.LO")}</h5>
                </center>
              )
            ) : (
              <div className="text-center">
                <Spinner animation="border" variant="warning" />
              </div>
            )}
          </Accordion.Body>
        </Accordion.Item> */}
        <Accordion.Item eventKey="2" className="accordian_style">
          <Accordion.Header className="accordian_style">
            {t("USR.BD.PLTAB.ACR.HV")} &nbsp;&nbsp;&nbsp;{" "}
            <Badge bg="dark">{payLaterHomeLen}</Badge>
          </Accordion.Header>
          <Accordion.Body>
            {!loading ? (
              payLaterHomeValidation.length > 0 ? (
                <div>
                  <Table
                    striped
                    bordered
                    hover
                    responsive
                    className="deskTable text-center all_border"
                  >
                    <thead>
                      <tr>
                        <th>{t("USR.VFPR.TBL.CLMN.OWN")}</th>
                        <th>{t("USR.VFP.DRP.LBL.LG")}</th>
                        <th>{t("USR.BD.HMTAB.TBL.CLM.HAR")}</th>
                        <th>{t("USR.BD.HMTAB.TBL.CLM.PR")}</th>
                        <th>{t("USR.BD.HMTAB.TBL.CLM.MF")}</th>
                        <th>{t("USR.BD.PLTAB.ACR.TB.PUR")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {payLaterHomeValidation.map((i, j) => (
                        <tr style={{ textAlign: "center" }} key={j}>
                          <td>{i.ownerName}</td>
                          <td>{i.lagnaName}</td>
                          <td>{i.houseArea}</td>
                          <td>{i.perimeter}</td>
                          <td>{i.mainFacing}</td>
                          <td>
                            <Button
                              className="all_btn"
                              onClick={() => {
                                // handleBuyHome(
                                //   defaultHomeOffer.offerId,
                                //   defaultHomeOffer.offerPrice,
                                //   i.homeUuid
                                // );
                                submitValidate(i.homeUuid);
                              }}
                            >
                              {t("USR.BD.PLTAB.ACR.BTN.PY")}
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <div className=" mobileCard justify-content-center mx-1">
                    {payLaterHomeValidation.map((i, j) => (
                      <NewBootCard key={j}>
                        <h4>
                          {t("USR.VFPR.TBL.CLMN.OWN")}: {i.ownerName}
                        </h4>
                        <h6>
                          {t("USR.VFP.DRP.LBL.LG")}: {i.lagnaName}
                        </h6>
                        <h6>
                          {t("USR.BD.HMTAB.TBL.CLM.HAR")}: {i.houseArea}
                        </h6>
                        <h6>
                          {t("USR.BD.HMTAB.TBL.CLM.PR")}: {i.perimeter}
                        </h6>
                        <h6>
                          {t("USR.BD.HMTAB.TBL.CLM.MF")}: {i.mainFacing}
                        </h6>
                        <Button
                          className="all_btn"
                          onClick={() => {
                            // handleBuyHome(
                            //   defaultHomeOffer.offerId,
                            //   defaultHomeOffer.offerPrice,
                            //   i.homeUuid
                            // );
                            submitValidate(i.homeUuid);
                          }}
                        >
                          {t("USR.BD.PLTAB.ACR.BTN.PY")}
                        </Button>
                      </NewBootCard>
                    ))}
                  </div>
                </div>
              ) : (
                <center>
                  <h5>{t("TXT.NO.REC.FUD.LO")}</h5>
                </center>
              )
            ) : (
              <div className="text-center">
                <Spinner animation="border" variant="warning" />
              </div>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <Modal
        show={show}
        onHide={() => {
          setShow(false);
        }}
        size="lg"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="v_modal"
      >
        <Modal.Header closeButton className="all_background">
          <Modal.Title id="contained-modal-title-vcenter">
            {t("USR.BOK.UR.PLS.TL")}
          </Modal.Title>
        </Modal.Header>

        {loading ? (
          <center>
            <Spinner animation="border" variant="warning" />
          </center>
        ) : (
          <Modal.Body>
            <p>
              {/* Already you have some offer palns. If you want to proceed or Buy
              new offer to click Buy Offer. */}
              {t("USR.BOK.CHK.UR.PLN.TXT")}
            </p>
            <form>
              {/* {JSON.stringify(activeServiceList)} */}
              <div className="row text-center">
                {activeServiceList.length > 0 ? (
                  activeServiceList.map((i, j) => (
                    <div className="col-sm-12 col-md-4 col-lg-3 g-1" key={j}>
                      <div className="card all_border">
                        <div className="card-body">
                          <h4 className="card-title">{i.offerName}</h4>
                          <p className="card-title">{i.offerFrequency}</p>
                          <h3 className="card-text">
                            ₹{i.offerPrice} <s>400</s>
                          </h3>
                          <h3 className="card-text">
                            {t("USR.ACC.ACT.REM.TL")} {i.availableQty}
                          </h3>
                          {i.expiryDate ? (
                            <p className="card-title mt-1">
                              {t("USR.ACC.ACT.VAL.TL")} {i.offerEndDate}
                            </p>
                          ) : null}
                          {i.offerDetails
                            ? i.offerDetails.map((x, y) => (
                                <li
                                  className="list-group-item d-flex justify-content-between align-items-center"
                                  key={y}
                                >
                                  {x.serviceName}({x.serviceCostType})
                                  <span className="badge bg-primary rounded-pill">
                                    {" "}
                                    {x.count} {x.count > 1 ? "times" : "time"}
                                  </span>
                                </li>
                              ))
                            : null}
                          <Button
                            className="all_btn"
                            onClick={() => {
                              // handleBookSlot(i.offerId, i.serviceId, i.id);
                              navigate("/homevalidation", {
                                state: { offerId: i.id },
                              });
                              setShow(false);
                              addHomeRecId(homeid);
                            }}
                          >
                            {t("USR.BOK.UR.PLN.PRCED")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <h4>{t("USR.PR.PRTAB.HTXT")}</h4>
                )}
              </div>
            </form>
          </Modal.Body>
        )}

        <Modal.Footer className="homevasthubtn all_border">
          <Button className="all_btn" onClick={() => setShow(false)}>
            {t("TXT.CLO.LO")}
          </Button>
          <Button
            className="all_btn"
            onClick={() => {
              handleBuyHome(
                defaultHomeOffer.offerId,
                defaultHomeOffer.offerPrice,
                homeid
              );
            }}
          >
            {t("USR.BOK.UR.PLN.BUY")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PendingValidationRecords;
