import React, { useEffect, useState } from "react";
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  ListGroup,
  Offcanvas,
  Row,
  Stack,
} from "react-bootstrap";
import { vasthuUrl } from "../component/Api";
import { UseAuth } from "../context/Auth";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FcFilledFilter } from "react-icons/fc";
import { useTranslation, Trans } from "react-i18next";
import { format } from "date-fns";

const ViewSlot = () => {
  const { t } = useTranslation();

  // const { userId } = UseAuth();
  const userId = window.sessionStorage.getItem("userId");
  const [overallSlotInfo, setOverallSlotInfo] = useState([]);
  const [upcomingScheduleDates, setUpcomingScheduleDates] = useState([]);

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const token = window.sessionStorage.getItem("token");
  const [slotFilter, setSlotFilter] = useState([]);
  const [choosenDate, setChoosenDate] = useState("");
  const [triggerSlotService, setTriggerSlotService] = useState();
  const [show, setShow] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [maxDateLimit, setMaxDateLimit] = useState(" ");
  const [datelimit, setdatelimit] = useState(" ");

  useEffect(() => {
    var today = new Date();

    getUpcomingServiceSchedules()
      .then((date) => {
        let firstDate = date[0];
        console.log("FIRST DATE", firstDate);
      })
      .catch((error) =>
        console.log("NO SCHEDULES CREATED , PLEASE CREATE SLOTS", error)
      );

    //   let slotFilterByDate = overallSlotInfo.filter((s,k) => s.schedule_date == date) ;

    //   setSlotFilter(slotFilterByDate);

    var currentdate = today.getTime();

    setdatelimit(currentdate);

    var maxAllowedWeek = 8;
    var daysCountinWeek = 7;
    var allowedDate = today.setDate(
      today.getDate() + maxAllowedWeek * daysCountinWeek
    );
    setMaxDateLimit(allowedDate);
  }, []);

  const getUpcomingServiceSchedules = async () => {
    let uniqueDate;

    try {
      const res = await vasthuUrl.get(`getupcomingslots/${userId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("OVERALL SLOT INFO", res.data, typeof res.data);
      setOverallSlotInfo(res.data);

      let upcomingDates = res.data.map((i) => i.schedule_date);

      uniqueDate = Array.from(new Set(upcomingDates));

      console.log("UNIQUE DATES", uniqueDate, uniqueDate[0]);

      setUpcomingScheduleDates(uniqueDate);
    } catch (error) {
      console.log("ERROE" + error);
    }

    return uniqueDate;
  };

  const filterOverallSlotByDate = (date) => {
    console.log("date", date, typeof date);

    let slotFilterByDate = overallSlotInfo.filter(
      (s, k) => s.schedule_date == date
    );

    setChoosenDate(date);
    setSlotFilter(slotFilterByDate);

    console.log("DATE SLOT", slotFilterByDate);
  };

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const alert = (msg) => {
    setErrMsg(msg);
    setTimeout(() => {
      setErrMsg("");
    }, 3000);
  };

  const filterDate = async () => {
    if (dateRange[0] == null || dateRange[1] == null) {
      return alert("Date is Invalid");
    }

    let sDate = dateRange[0];
    let eDate = dateRange[1];

    let startdateName = sDate.toString().substring(0, 15);
    let endDateName = eDate.toString().substring(0, 15);

    let selectedDateFromFilter = startdateName + " to " + endDateName;

    let selectedStartDate =
      sDate.getFullYear() +
      "-" +
      (sDate.getMonth() + 1) +
      "-" +
      sDate.getDate();
    let selectedEndDate =
      eDate.getFullYear() +
      "-" +
      (eDate.getMonth() + 1) +
      "-" +
      eDate.getDate();

    try {
      const res = await vasthuUrl.get(`/getslotbydatefilter/${userId}`, {
        params: {
          startDate: selectedStartDate,
          endDate: selectedEndDate,
        },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      console.log("SLOT FOR SELECTED DATES" + JSON.stringify(res.data));
      setSlotFilter(res.data);
      setChoosenDate(selectedDateFromFilter);
      setShow(false);
      setDateRange([]);
    } catch (error) {}

    // setShow(false)
  };

  return (
    <Container>
      {/* <div
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          display: "flex",
          alignItems: "center",
        }}
      >
        <h6>{t("PR.APMNT.ASTAB.STL")}</h6>
        <Button className="mb-2 all_btn" onClick={handleShow}>
          {t("PR.APMNT.ASTAB.BTN.FLTR")}
          <FcFilledFilter size={25} />
        </Button>
      </div> */}

      <div
        className="scroll-button"
        style={{
          overflowX: "auto",
          whiteSpace: "nowrap",
          padding: 8,
          scrollbarWidth: "thin",
        }}
      >
        <Stack gap={5} direction="horizontal">
          {upcomingScheduleDates.map((i, k) => (
            <Button
              className="mb-2 all_btn"
              key={k}
              onClick={() => {
                getUpcomingServiceSchedules();
                filterOverallSlotByDate(i);
              }}
            >
              {i
                .split("-")
                .reverse()
                .join("-")}
            </Button>
          ))}
        </Stack>
      </div>

      {/* 
<div style={{flexDirection:'row',  margin:10 ,justifyContent:'center'}}>

  <h6>Filter your Slots By Date</h6> 

  <DatePicker
      selectsRange
     
      startDate={startDate}
      endDate={endDate}
      onChange={(update) => {
        setDateRange(update);
        console.log("DATE RANGE" , update)
      }}
     
    />

</div> */}

      {/* <Offcanvas show={show} onHide={handleClose} placement={"end"}>
        <Offcanvas.Header className="all_background" closeButton>
          <Offcanvas.Title>Choose Date Range For Filter</Offcanvas.Title>
        </Offcanvas.Header>

        {errMsg && (
          <center>
            <h6 style={{ color: "red" }}>{errMsg}</h6>
          </center>
        )}

        <Offcanvas.Body>
          <h6> Select From and To Date </h6>

          <DatePicker
            selectsRange
            minDate={datelimit}
            maxDate={maxDateLimit}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              setDateRange(update);
              console.log("DATE RANGE", update);
            }}
            dateFormat="yyyy-MM-dd"
          />
        </Offcanvas.Body>

        <Offcanvas.Body>
          <Button className="mb-2 all_btn" onClick={filterDate}>
            Apply Filter
          </Button>
        </Offcanvas.Body>
      </Offcanvas> */}

      <div className="m-3">
        <Row>
          <Col sm={1} md={1} lg={2} />

          <Col sm={10} md={10} lg={8}>
            <ListGroup as="ol">
              {choosenDate != "" ? (
                <center>
                  <strong style={{ margin: 8 }}>
                    {" "}
                    {t("PR.APMNT.ASTAB.LST.TL")}
                    {format(new Date(choosenDate), "dd-MM-yyyy")}
                  </strong>
                </center>
              ) : null}

              {upcomingScheduleDates.length > 0 ? (
                slotFilter.length > 0 ? (
                  slotFilter.map((s, k) => (
                    <ListGroup.Item
                      as="li"
                      variant={
                        s.slot_status === "AVAILABLE"
                          ? "success"
                          : null || s.slot_status === "BLOCKED"
                          ? "warning"
                          : null || s.slot_status === "BOOKED"
                          ? "danger"
                          : null
                      }
                      key={k}
                      className="d-flex justify-content-between align-items-start mb-2"
                    >
                      <div className="ms-2 me-auto">
                        <h6>
                          {t("CON.APMT.STUS.DATE")} : &nbsp;&nbsp;
                          {format(new Date(s.schedule_date), "dd-MM-yyyy")}
                        </h6>
                        <h6>
                          {t("CON.APMT.STUS.SLT.TIME")} : &nbsp;&nbsp;
                          {s.slot_time}
                        </h6>
                        {/* <h6>Consultation:&nbsp;&nbsp;{s.service_schedule.service.serviceName}/{s.service_schedule.consultation_mode}</h6> */}
                        <h6>
                          {t("CON.APMT.APMSTS.CON")}
                          :&nbsp;&nbsp;
                          {s.service_schedule.consultation_mode}
                        </h6>{" "}
                        {/* Any service - No serrvice is bind */}
                      </div>

                      <Badge
                        bg={
                          s.slot_status === "AVAILABLE"
                            ? "success"
                            : null || s.slot_status === "BLOCKED"
                            ? "warning"
                            : null || s.slot_status === "BOOKED"
                            ? "danger"
                            : null
                        }
                      >
                        {s.slot_status}
                      </Badge>
                    </ListGroup.Item>
                  ))
                ) : (
                  <div className="my-4">
                    <center>
                      <h6>{t("PR.APMNT.ASTAB.MSG.CHOTDA")}</h6>
                    </center>
                  </div>
                )
              ) : (
                <div className="my-4">
                  <center>
                    {/* <h6>No Slots Available, Please Create your Slots</h6> */}
                    <h6>{t("PR.APMNT.ASTAB.MSG.CRSLT")}</h6>
                  </center>
                </div>
              )}
            </ListGroup>
          </Col>

          <Col sm={1} md={1} lg={2} />
        </Row>
      </div>
    </Container>
  );
};

export default ViewSlot;
