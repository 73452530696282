import React, { useEffect, useState } from "react";
import TableDesk from "../component/DeskTable";
import MobileCard from "../component/MobileCard";
import { vasthuUrl } from "../component/Api";
import { UseAuth } from "../context/Auth";
import { Alert, Button, Spinner, Modal, Card } from "react-bootstrap";
import { Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Tabs, Tab } from "react-bootstrap";
// import AdminClientDetail from "./AdminClientDetails";
import AdminListDetails from "./AdminListDetails";
import AdminProfDetails from "./AdminProfDetails";
import AdminCrmListDetails from "./AdminCrmListDetails";
import DeactivatedProfessionalList from "./DeactivatedProfessionalList";
import UserPaymentList from "./Admin/UserPaymentList";
import UserAppoinmentList from "./Admin/UserAppiontmentList";
import UserAudiocallList from "./Admin/UserAudiocallList";
import VasthuParamUserRefund from "./VasthuParamUserRefund";
import UserRefundList from "./Admin/UserRefundList";
import { format, isToday } from "date-fns";
import { useTranslation } from "react-i18next";

const AdminClientDetails = () => {
  // const { token } = UseAuth();
  const { t } = useTranslation();
  const [key, setKey] = useState("userpaymentlist");
  const token = window.sessionStorage.getItem("token");
  const [clientData, setClientData] = useState([]);
  const [message, setMessage] = useState("");
  // const userId = window.sessionStorage.getItem("userId");
  const [loading, setLoading] = useState(false);
  const alert = (msg) => {
    setMessage(msg);
    setTimeout(() => {
      setMessage("");
    }, 4000);
  };

  const originalDate = new Date();
  const formattedDate = format(originalDate, "MMM d, yyyy HH:mm");

  const [show, setShow] = useState(false);
  const [userPaymentList, setUserPaymentList] = useState([]);
  const [userAppoinmentList, setUserAppoinmentList] = useState([]);
  const [userAudiocallList, setUserAudiocallList] = useState([]);
  const [userRefundList, setUserRefundList] = useState([]);

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true);
    try {
      vasthuUrl
        .get(`vasthu/getUserTransactionHistory/${id.uuid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          setUserPaymentList(res.data);
        });
    } catch (e) {
      console.log("SERVER ERROR");
      setLoading(false);
    }

    try {
      vasthuUrl
        .get(`/refund/getParticularUserRefundIntiatedList/${id.id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("users", res.data);
          setUserRefundList(res.data);
          setLoading(false);
        });
    } catch (e) {
      alert("SERVER ERROR");
      setLoading(false);
    }

    try {
      vasthuUrl
        .get(`getoveralluserbookinghistory/${id.uuid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setUserAppoinmentList(res.data);
          console.log(res.data);
          // setShow(true);
        });
    } catch (e) {
      console.log("SERVER ERROR");
      setLoading(false);
    }

    try {
      vasthuUrl
        .get(`vasthu/user/getAudioCallUserHistory/${id.uuid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setUserAudiocallList(res.data);
          console.log(res.data);
        });
    } catch (e) {
      console.log("SERVER ERROR");
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    try {
      vasthuUrl
        .get(`/data/getallusers`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("users", res.data.message);
          setClientData(res.data);
          setLoading(false);
        });
    } catch (e) {
      alert("SERVER ERROR");
      setLoading(false);
    }
  }, []);

  const listtemp = clientData.map((obj) => {
    const userAcCreatedDate = new Date(obj.userAcCreatedDate);
    if (!isNaN(userAcCreatedDate)) {
      return {
        ...obj,
        userAcCreatedDate: format(userAcCreatedDate, "d MMM yyyy, HH:mm"),
      };
    } else {
      return {
        ...obj,
        userAcCreatedDate: "Invalid Date",
      };
    }
  });

  const columns = [
    { field: "name", headerName: "NAME", width: 150 },
    { field: "phoneNumber", headerName: "PHONE", width: 150 },
    { field: "email", headerName: "EMAIL", width: 200 },
    // { field: "consultantLevel", headerName: "CATEGORY", width: 130 },
    { field: "userAcCreatedDate", headerName: "CREATED DATE", width: 180 },
    {
      field: "Details",
      headerName: "DETAILS",
      type: "actions",
      renderCell: (i) => (
        <Button
          className="all_btn"
          onClick={() => {
            handleShow(i.row);
            console.log("i", i);
            console.log("i.row", i.row);
          }}
        >
          Details
        </Button>
      ),
    },
  ];

  return (
    <React.Fragment>
      <center>
        <h4>
          <u>CUSTOMER DETAILS</u>
        </h4>
      </center>
      {message && (
        <Alert variant="info" className="text-center">
          {message}
        </Alert>
      )}
      {!loading ? (
        listtemp.length > 0 ? (
          <>
            <center className="datagrid">
              <Grid container className="deskTable datagrid">
                <DataGrid
                  className="all_border - text-center me-3 data_grid"
                  // rows={props.rows}
                  rows={listtemp}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 10 },
                    },
                  }}
                  pageSizeOptions={[5, 10]}
                  // checkboxSelection
                  getRowId={(row) => row.id}
                />
              </Grid>
            </center>
            {/* <TableDesk data={clientData} /> */}
            {/* <MobileCard data={clientData} /> */}
            <div className="justify-content-center mobileCard">
              {listtemp.map((i, j) => (
                <Card
                  key={j}
                  className="mobileCard all_border"
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    backgroundColor: "antiquewhite",
                  }}
                >
                  <Card.Body>
                    <Card.Subtitle className="mb-2">
                      <b>Name:</b> {i.name}
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2">
                      <b> Phone:</b> {i.phoneNumber}
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2">
                      <b>Email:</b> {i.email}
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2">
                      <b>Category:</b> {i.consultantLevel}
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2">
                      <b>Created Date:</b>
                      {i.userAcCreatedDate}
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2">
                      <b>Details:</b>{" "}
                      <Button
                        size="sm"
                        className="all_btn"
                        onClick={() => {
                          handleShow(i);
                          console.log("i", i);
                          console.log("i.row", i);
                        }}
                      >
                        Details
                      </Button>
                    </Card.Subtitle>
                  </Card.Body>
                </Card>
              ))}
            </div>
          </>
        ) : (
          <center>
            <h5>No User Found</h5>
          </center>
        )
      ) : (
        <center>
          <Spinner animation="border" variant="warning" />
        </center>
      )}
      <Modal show={show} onHide={handleClose} fullscreen={true}>
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Body>
          <Card
            className="all_border"
            style={{
              width: "100%",
              marginBottom: "10px",
              backgroundColor: "antiquewhite",
            }}
          >
            <Card.Body>
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="justify-content-center"
              >
                <Tab eventKey="userpaymentlist" title="PAYMENTS">
                  <UserPaymentList {...{ userPaymentList }} />
                </Tab>
                <Tab eventKey="userAppoinmentList" title="SLOTS">
                  <UserAppoinmentList {...{ userAppoinmentList }} />
                </Tab>
                <Tab eventKey="userAudiocallList" title="CALLS">
                  <UserAudiocallList {...{ userAudiocallList }} />
                </Tab>
                <Tab eventKey="userRefundList" title="REFUNDS">
                  <UserRefundList {...{ userRefundList }} />
                </Tab>
              </Tabs>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer className="all_border">
          <Button className="all_btn" onClick={handleClose}>
            {/* {t("TXT.CLO.LO")} */}
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
export default AdminClientDetails;
