import React, { useEffect, useState } from "react";
import { Button, Spinner, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { vasthuUrl } from "../component/Api";
import NewBootCard from "../component/NewBootCard";
import FeedBack from "../component/FeedBack";

function ChatHistory() {
  const token = window.sessionStorage.getItem("token");
  const uuid = window.sessionStorage.getItem("userId");
  const { t } = useTranslation();
  const [ChatHistoryDetails, setChatHistoryDetails] = useState([]);
  const [loading, setLoading] = useState(false);

  //Review Modal
  const [openModal, setOpenModal] = useState(false);
  const [profDetails, setProfDetails] = useState();

  const showModal = (i) => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
    setProfDetails(null); // Reset modal details when closed
  };

  useEffect(() => {
    setLoading(true);
    vasthuUrl
      .get(`/initiateChat/getChatHistory/${uuid}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((rs) => {
        console.log("achistory", rs.data);
        setChatHistoryDetails(rs.data);
        setLoading(false);
      })
      .catch((er) => {
        console.log("call history err", er);
        setLoading(false);
      });
  }, []);

  return (
    <div>
      <center>
        <h2>{t("USR.CHAT.HIS.HEAD")}</h2>
      </center>
      {!loading ? (
        ChatHistoryDetails.length > 0 ? (
          <>
            <Table
              striped
              bordered
              hover
              responsive
              className="deskTable all_border text-center"
            >
              <thead>
                <tr>
                  <th>{t("USR.HOM.HD.UN")}</th>
                  <th>{t("USR.CHAT.CON.NAME")}</th>
                  <th>{t("TXT.STS.LO")}</th>
                  <th>{t("USR.CHAT.MIN")}</th>
                  <th>{t("USR.TOT.DUR")}</th>
                  {/* <th>Meeting Code</th> */}
                  <th>{t("USR.CHAT.DATE")}</th>
                  <th>{t("USR.CHAT.STA.TIME")}</th>
                  <th>{t("USR.CONT.END.TIME")}</th>
                  <th>{t("USR.FD.BCK.BTN")}</th>
                </tr>
              </thead>
              <tbody>
                {ChatHistoryDetails.reverse().map((item, index) => (
                  <tr key={index}>
                    <td>{item.username}</td>
                    <td>{item.conName}</td>
                    <td>{item.status}</td>
                    <td>{item.duration}</td>
                    <td>{item.proposedChatDuration}</td>
                    {/* <td>{item.meetingCode}</td> */}
                    <td>{item.chatDate}</td>
                    <td>{item.chatStartTime.slice(0, 5)}</td>
                    <td>
                      {item.connectEndTime
                        ? item.connectEndTime.slice(0, 5)
                        : "-"}
                    </td>

                    {/* <td>{item.connectStartTime}</td>
                                        <td>{item.connectEndTime}</td> */}
                    <td>
                      {item.status == "DISCONNECTED" ? (
                        <Button
                          variant="outline-warning"
                          onClick={() => {
                            console.log("II", item);
                            showModal();
                            setProfDetails(item);
                          }}
                        >
                          {t("USR.HOM.REV.TEXT4")}
                        </Button>
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>

            {openModal ? (
              <FeedBack
                details={profDetails}
                params={true}
                onClose={closeModal}
              />
            ) : null}

            <div className=" mobileCard justify-content-center mx-1">
              {ChatHistoryDetails.map((item, j) => (
                <NewBootCard key={j}>
                  <h4 className="text-center">
                    <td>
                      {" "}
                      {t("USR.HOM.HD.UN")}:{item.username}
                    </td>
                  </h4>
                  <h6>
                    <td>
                      {t("USR.CHAT.CON.NAME")}:{item.conName}
                    </td>
                  </h6>
                  <h6>
                    <td>
                      {t("TXT.STS.LO")}:{item.status}
                    </td>
                  </h6>
                  <h6>
                    <td>
                      {t("USR.CHAT.MIN")}:{item.duration}
                    </td>
                  </h6>
                  <h6>
                    <td>
                      {t("USR.TOT.DUR")}:{item.proposedChatDuration}
                    </td>
                  </h6>
                  <h6>
                    <td>
                      {t("USR.CHAT.DATE")}:{item.chatDate}
                    </td>
                  </h6>
                  <h6>
                    <td>
                      {t("USR.CHAT.STA.TIME")}:{item.chatStartTime.slice(0, 5)}
                    </td>
                  </h6>
                  <h6>
                    <td>
                      {t("USR.CONT.END.TIME")}{" "}
                      {item.connectEndTime
                        ? item.connectEndTime.slice(0, 5)
                        : "-"}
                    </td>
                  </h6>
                  <h6>
                    <td>
                      {item.status == "DISCONNECTED" ? (
                        <Button
                          variant="outline-warning"
                          onClick={() => {
                            console.log("II", item);
                            showModal();
                            setProfDetails(item);
                          }}
                        >
                          {t("USR.HOM.REV.TEXT4")}
                        </Button>
                      ) : (
                        "-"
                      )}
                    </td>
                  </h6>
                </NewBootCard>
              ))}
            </div>
          </>
        ) : (
          <div>
            <center>
              <h5>{t("TXT.NO.REC.FUD.LO")}</h5>
            </center>
          </div>
        )
      ) : (
        <div className="text-center">
          <Spinner animation="border" variant="warning" />
        </div>
      )}
    </div>
  );
}

export default ChatHistory;
