import React, { useEffect, useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import { vasthuUrl } from "../component/Api";
import { UseAuth } from "../context/Auth";
import { UseVasthu } from "../context/vasthu";
import Accordion from "react-bootstrap/Accordion";
import { FcCancel, FcApproval } from "react-icons/fc";
import { useTranslation } from "react-i18next";
import ObjectEmptyChecker from "./ObjectEmptyChecker";

export const MainDoorReports = (props) => {
  // const { token } = UseAuth();
  // const token = window.localStorage.getItem("token");
  const token = window.sessionStorage.getItem("token");
  //const  homeRec  = homeUuid;
  const { t } = useTranslation();
  const [homeMainDoorObj, setHomeMainDoorObj] = useState({});
  const [loading, setLoading] = useState(false);
  const [eightGridObj, setEightGridObj] = useState({});
  const [nineGridObj, setNineGridObj] = useState({});
  useEffect(() => {
    console.log("I HOME UID" + props.data);

    setLoading(true);
    vasthuUrl
      .get(`getmaindoorreports/${props.data}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("DATA OF MAIN DOOR", res.data);
        setHomeMainDoorObj(res.data);
        setEightGridObj(res.data.eightGridObj);
        setNineGridObj(res.data.nineGridObj);
        setLoading(false);
        console.log("Maindoor Results" + res.data);
        console.log("Maindoor Results" + JSON.stringify(res.data.eightGridObj));
      });
  }, []);

  return (
    <Container>
      <center>
        <h4>{t("USR.VSH.RES.ACR.MD.TL")}</h4>
      </center>
      {!loading ? (
        <>
          <table id="maindoortable" className="reporttable">
            <thead>
              <tr>
                <th>{t("USR.VSH.RES.ACR.MD.TBL.CLMN.BW")}</th>
                <th>{t("USR.VSH.RES.ACR.MD.TBL.CLMN.MF")}</th>
                <th>{t("USR.VSH.RES.ACR.MD.TBL.CLMN.MDP")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{homeMainDoorObj.buildingFrontWidthResult}</td>
                <td>{homeMainDoorObj.mainDoorFacingResult}</td>
                <td>
                  {homeMainDoorObj.mainDoorPlacementResult
                    ? // <FcCheckmark size={25} />
                      "GOOD"
                    : "BAD"
                  // <FcCancel size={25} />
                  }
                </td>
              </tr>
            </tbody>
          </table>

          {/* <Accordion className="accordianpanel maindoor_accordian">
            <Accordion.Item id="manduku_vasthu_item" eventKey="0">
              <Accordion.Header>
                {t("USR.VSH.RES.ACR.MD.ACR.MV")}
              </Accordion.Header>
              <Accordion.Body>
                <table className="reporttable">
                  <thead>
                    <tr>
                      {Object.entries(eightGridObj).map((item) => (
                        <th>{item[0]}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {Object.entries(eightGridObj).map((item) => (
                        <td>
                          {typeof item[1] == "boolean"
                            ? item[1]
                              ? "GOOD"
                              : "BAD"
                            : item[1]}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item id="paramassagu_vasthu_item" eventKey="1">
              <Accordion.Header>
                {t("USR.VSH.RES.ACR.MD.ACR.PV")}
              </Accordion.Header>

              <Accordion.Body>
                <table className="reporttable">
                  <thead>
                    <tr>
                      {Object.entries(nineGridObj).map((item) => (
                        <th>{item[0]}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {Object.entries(nineGridObj).map((item) => (
                        <td>
                          {typeof item[1] == "boolean"
                            ? item[1]
                              ? "GOOD"
                              : "BAD"
                            : item[1]}
                        </td>
                      ))}
                    </tr>
                  </tbody>
                </table>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion> */}
        </>
      ) : (
        <div className="text-center">
          {" "}
          <Spinner animation="border" variant="warning" />{" "}
        </div>
      )}
    </Container>
  );
};

export const ElementReports = (props) => {
  // const { token } = UseAuth();
  // const token = window.localStorage.getItem("token");
  const token = window.sessionStorage.getItem("token");
  const { t } = useTranslation();
  const langCode = window.localStorage.getItem("i18nextLng");
  const { homeRec } = UseVasthu();
  const [homeElementObj, setHomeElementObj] = useState([]);
  const [loading, setLoading] = useState(false);
  const [headings, setHeadings] = useState([]);

  useEffect(() => {
    setLoading(true);
    console.log("elemen validation", homeRec, token);
    vasthuUrl
      .get(`getelementreport/${props.data}/${langCode}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setHomeElementObj(res.data);
        console.log("Object.entries(homeElementObj)", Object.entries(res.data));
        setLoading(false);
        console.log("after api res", res.data);
        if (!ObjectEmptyChecker(res.data)) {
          setHeadings(Object.keys(Object.values(res.data)[0][0]));
          console.log(
            "Object.keys(Object.values(res.data)[0][0])",
            Object.keys(Object.values(res.data)[0][0])
          );
        } else {
          setHeadings([]);
        }
      });
  }, []);

  return (
    <Container>
      <center>
        <h4>{t("USR.VSH.RES.ACR.ELM.TL")}</h4>
      </center>
      {!loading ? (
        <table id="elementtable" className="reporttable">
          <tbody>
            {Object.entries(homeElementObj).map((floor) => (
              <Accordion className="accordianpanel mb-1">
                <Accordion.Item eventKey="0" className="accordian_style">
                  <Accordion.Header className="all_background">
                    {floor[0]}
                  </Accordion.Header>
                  <Accordion.Body>
                    <thead>
                      <tr>
                        {headings
                          .filter(
                            (heading) =>
                              heading !== "floorName" &&
                              heading !== "floorNameta" &&
                              heading !== "floorNamehi" &&
                              heading !== "elementUuid"
                          )
                          .map((head) => (
                            <th>{head}</th>
                          ))}
                      </tr>
                    </thead>

                    {floor[1].map((newObject) => (
                      <tr>
                        {Object.entries(newObject).map((item) => {
                          if (
                            item[0] !== "elementUuid" &&
                            item[0] !== "floorName" &&
                            item[0] !== "floorNameta" &&
                            item[0] !== "floorNamehi"
                          ) {
                            return <td>{item[1]}</td>;
                          }
                        })}
                      </tr>
                    ))}
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ))}
          </tbody>
        </table>
      ) : (
        <div className="text-center">
          {" "}
          <Spinner animation="border" variant="warning" />{" "}
        </div>
      )}
    </Container>
  );
};

export const MiscellaneousReports = (props) => {
  // const { token } = UseAuth();
  const token = window.sessionStorage.getItem("token");
  const { t } = useTranslation();
  //const token = window.localStorage.getItem("token");
  const langCode = window.localStorage.getItem("i18nextLng");
  const [homeMiscellaneousArray, setHomeMiscellaneousArray] = useState([]);
  const [loading, setLoading] = useState(false);

  console.log("homeMiscellaneousArray", homeMiscellaneousArray);

  useEffect(() => {
    setLoading(true);
    vasthuUrl
      .get(`getmiscellaneousreport/${props.data}/${langCode}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setHomeMiscellaneousArray(res.data);
        setLoading(false);
        console.log(res.data);
      });
  }, []);

  console.log("homeMiscellaneousArray", homeMiscellaneousArray);

  return (
    <Container>
      <center>
        <h4>{t("USR.VSH.RES.ACR.MSC.TL")}</h4>
      </center>
      {!loading ? (
        homeMiscellaneousArray.length > 0 ? (
          // <Table striped bordered hover responsive size="lg">
          <table id="misctable" className="reporttable">
            <thead>
              <tr>
                <th>{t("USR.VSH.RES.ACR.MSC.TBL.CLMN.NM")}</th>
                <th>{t("USR.VSH.RES.ACR.MSC.TBL.CLMN.RS")}</th>
              </tr>
            </thead>
            <tbody>
              {homeMiscellaneousArray.map((i, j) => (
                <tr key={j}>
                  <td>{i.miscellaneousName}</td>
                  <td>
                    {i.miscellaneousLocationResult == "GOOD" ? (
                      <FcApproval size={25} />
                    ) : (
                      <FcCancel size={25} />
                    )}
                  </td>
                </tr>
              ))}

              {/* <tr>
              <td>Windows</td>
              <td>
                {homeWindowObj.result ? (
                  <FcCheckmark size={25} />
                ) : (
                  <FcCancel size={25} />
                )}
              </td>
            </tr> */}
            </tbody>
            {/* </Table> */}
          </table>
        ) : (
          <center>
            <h5>No Miscellaneous Entry Found</h5>
          </center>
        )
      ) : (
        <div className="text-center">
          {" "}
          <Spinner animation="border" variant="warning" />{" "}
        </div>
      )}
    </Container>
  );
};

export const WindowReports = (props) => {
  // const { token } = UseAuth();
  const { t } = useTranslation();
  //const token = window.localStorage.getItem("token");
  const token = window.sessionStorage.getItem("token");
  const langCode = window.localStorage.getItem("i18nextLng");
  const [homeWindowObj, setHomeWindowObj] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    vasthuUrl
      .get(`getwindowreport/${props.data}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setHomeWindowObj(res.data);
        setLoading(false);
      });
  }, []);

  return (
    <Container>
      <center>
        <h4>{t("USR.VSH.RES.ACR.ELM.ACR.TBL.CLMN.WR")}</h4>
      </center>
      {!loading ? (
        // <Table striped bordered hover responsive size="lg">
        <table id="windowtable" className="reporttable">
          <thead>
            <tr>
              <th>{t("USR.VSH.RES.ACR.MSC.TBL.CLMN.NM")}</th>
              <th>{t("USR.VSH.RES.ACR.MSC.TBL.CLMN.RS")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {langCode == "ta"
                  ? "ஜன்னல்"
                  : langCode == "hi"
                  ? "खिड़की"
                  : "Windows"}
              </td>
              <td>
                {homeWindowObj.result ? (
                  <FcApproval size={25} />
                ) : (
                  // <FcCheckmark size={25} />
                  <FcCancel size={25} />
                )}
              </td>
            </tr>
          </tbody>
          {/* </Table> */}
        </table>
      ) : (
        <div className="text-center">
          {" "}
          <Spinner animation="border" variant="warning" />{" "}
        </div>
      )}
    </Container>
  );
};

export const LevelingReports = (props) => {
  // const { token } = UseAuth();
  const { t } = useTranslation();
  //const token = window.localStorage.getItem("token");
  const token = window.sessionStorage.getItem("token");
  const langCode = window.localStorage.getItem("i18nextLng");
  const [homeLevelObj, setHomeLevelObj] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    vasthuUrl
      .get(`getlevelreports/${props.data}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setHomeLevelObj(res.data);
        setLoading(false);
      });
  }, []);

  return (
    <Container>
      <center>
        <h4>{t("USR.VSH.RES.ACR.ELM.ACR.TBL.CLMN.LVRS")}</h4>
      </center>
      {!loading ? (
        // <Table striped bordered hover responsive size="lg">
        <table id="leveltable" className="reporttable">
          <thead>
            <tr>
              <th>{t("USR.VSH.RES.ACR.LVL")}</th>
              <th>{t("USR.VSH.RES.ACR.LVL.TBL.CLMN.SWXNE")}</th>
              <th>{t("USR.VSH.RES.ACR.LVL.TBL.CLMN.WXE")}</th>
              <th>{t("USR.VSH.RES.ACR.LVL.TBL.CLMN.SXN")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {langCode == "ta"
                  ? "நிலை"
                  : langCode == "hi"
                  ? "स्तर"
                  : "Levels"}
              </td>
              <td>
                {homeLevelObj.southWest_Vs_northEast ? (
                  <FcApproval size={25} />
                ) : (
                  // <FcCheckmark size={25} />
                  <FcCancel size={25} />
                )
                // <FcCancel size={25} />
                }
              </td>
              <td>
                {homeLevelObj.south_Vs_north ? (
                  <FcApproval size={25} />
                ) : (
                  // <FcCheckmark size={25} />
                  <FcCancel size={25} />
                )
                // <FcCancel size={25} />
                }
              </td>
              <td>
                {homeLevelObj.west_Vs_east ? (
                  <FcApproval size={25} />
                ) : (
                  // <FcCheckmark size={25} />
                  <FcCancel size={25} />
                )
                // <FcCancel size={25} />
                }
              </td>
            </tr>
          </tbody>
          {/* </Table> */}
        </table>
      ) : (
        <div className="text-center">
          {" "}
          <Spinner animation="border" variant="warning" />{" "}
        </div>
      )}
    </Container>
  );
};
