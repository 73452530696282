import React, { useEffect, useState, Suspense } from "react";
import { Tabs, Tab } from "react-bootstrap";

import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Card,
  Spinner,
  Alert,
  InputGroup,
  Modal,
} from "react-bootstrap";
import Login from "./Sigin";
import Register from "./Signup";
import LoginOTP from "./SigninOTP";
import RegisterOTP from "./SignupOTP";
import { useTranslation } from "react-i18next";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";

const Logins = () => {
  const { t } = useTranslation();
  const [loginType, setLoginType] = useState("1");
  const [activeKey, setActiveKey] = useState("login");
  const [phoneNo, setPhoneNo] = useState("");
  console.log("activeKey", activeKey);
  console.log("phoneNo", phoneNo);

  //Toast alert MODAL
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");

  const alert = (msg) => {
    setMessage(msg);
    setOpen(true);
    setTimeout(() => {
      setMessage("");
      setMessageType("");
      setOpen(false);
    }, 4000);
  };

  const alertType = (msg) => {
    setMessageType(msg);
  };

  const handleClosess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      <Suspense fallBack={<Spinner animation="border" />}>
        <Container fluid>
          <Row className="signuprow2 py-3">
            <Col sm={2} md={3} lg={4} />
            <Col xs={12} sm={8} md={6} lg={4}>
              <Card className="p-2 all_border">
                <Card.Body className="text-center">
                  <Card.Title name="TEST1" style={{ color: "#ffbb33" }}>
                    {t("USR.HOM.HD.TL")}
                  </Card.Title>
                </Card.Body>
                <Form>
                  <Form.Group className="mb-3">
                    <Form.Check
                      inline
                      label="OTP"
                      name="group1"
                      type="radio"
                      id={`inline-1`}
                      className="slot_check"
                      checked={loginType === "1"}
                      onChange={() => {
                        setLoginType("1");
                      }}
                    />
                    <Form.Check
                      inline
                      label={t("USR.HOM.HD.PWD")}
                      name="group1"
                      type="radio"
                      id={`inline-2`}
                      className="slot_check"
                      checked={loginType === "2"}
                      onChange={() => {
                        setLoginType("2");
                      }}
                    />
                  </Form.Group>
                  {loginType == "1" ? (
                    <Tabs
                      activeKey={activeKey}
                      onSelect={(k) => setActiveKey(k)}
                      id="loginTab"
                      className="mb-3 navTab"
                      justify
                    >
                      <Tab eventKey="login" title={t("USR.LOG.EX.USR")}>
                        <LoginOTP
                          setActiveKey={setActiveKey}
                          setPhoneNo={setPhoneNo}
                          alertType={alertType}
                          alert={alert}
                        />
                      </Tab>
                      <Tab eventKey="register" title={t("USR.LOG.NEW.USR")}>
                        <RegisterOTP
                          setActiveKey={setActiveKey}
                          phoneNo={phoneNo}
                          alertType={alertType}
                          alert={alert}
                        />
                      </Tab>
                    </Tabs>
                  ) : loginType == "2" ? (
                    <Tabs
                      activeKey={activeKey}
                      onSelect={(k) => setActiveKey(k)}
                      id="loginTab"
                      className="mb-3 navTab"
                      justify
                    >
                      <Tab eventKey="login" title={t("USR.LOG.EX.USR")}>
                        <Login
                          setActiveKey={setActiveKey}
                          alertType={alertType}
                          alert={alert}
                        />
                      </Tab>
                      <Tab eventKey="register" title={t("USR.LOG.NEW.USR")}>
                        <Register
                          setActiveKey={setActiveKey}
                          alertType={alertType}
                          alert={alert}
                        />
                      </Tab>
                    </Tabs>
                  ) : null}
                </Form>
              </Card>
            </Col>
            <Col sm={2} md={3} lg={4} />
          </Row>
        </Container>
        
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            open={open}
            autoHideDuration={3000}
            onClose={handleClosess}
          >
            <MuiAlert
              variant="filled"
              onClose={handleClosess}
              severity={messageType}
              sx={{
                width: "100%",
              }}
            >
              {message}
            </MuiAlert>
          </Snackbar>
        </Stack>
      </Suspense>
    </>
  );
};

export default Logins;
