import React, { useEffect, useState } from "react";
import { Button, Card, Form, Image, Modal } from "react-bootstrap";
import { Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { vasthuUrl } from "../../component/Api";
import { useTranslation } from "react-i18next";
import Toast from "../../component/Toast";
import validator from "validator";

function BannerList() {
  const token = window.sessionStorage.getItem("token");
  const { t } = useTranslation();
  const [reLoad, setReLoad] = useState(false);
  const [bannerList, setBannerList] = useState([]);
  const [saveData, setSaveData] = useState([]);
  const [offerName, setOfferName] = useState("");
  const [path, setPath] = useState("");
  const [title, setTitle] = useState("");
  const [desktop, setDesktop] = useState();
  const [mobile, setMobile] = useState();
  const [desktopPreview, setDesktopPreview] = useState(null);
  const [mobilePreview, setMobilePreview] = useState(null);
  console.log("offerName", offerName);
  console.log("path", path);
  console.log("title", title);
  console.log("image", desktop + mobile);
  console.log("imagePreview", desktopPreview + mobilePreview);

  useEffect(() => {
    vasthuUrl.get("image/getallbannerimages").then((res) => {
      console.log("banner List", res.data);
      setReLoad(false);
      setBannerList(res.data);
    });
  }, [reLoad]);

  // ALERT MODAL
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [toastTrigger, setToastTrigger] = useState(0);

  const alert = (msg) => {
    setMessage(msg);
    setToastTrigger((prev) => prev + 1);
  };

  // Create or Update Minute MODAL
  const [model, setModel] = useState(false);
  const ModalOpen = () => {
    setModel(true);
  };

  const ModalClose = () => {
    setModel(false);
    setOfferName("");
    setPath("");
    setTitle("");
    setSaveData("");
    setDesktop();
    setMobile();
    setDesktopPreview();
    setMobilePreview();
  };

  const handleShow = (i) => {
    console.log("i", i);
    ModalOpen();
    setSaveData(i);
  };

  const handleDesktopImageChange = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      const allowedImageTypes = ["image/jpeg", "image/png", "image/jpg"]; // Add more allowed types as needed

      if (!allowedImageTypes.includes(file.type)) {
        alert("Please upload a valid image file JPEG, PNG, or JPG");
        setMessageType("error");
      } else if (file.size > 5 * 1024 * 1024) {
        alert("Please upload an image file less than 5MB in size");
        setMessageType("error");
      } else {
        setDesktop(file);
        const previewUrl = URL.createObjectURL(file);
        setDesktopPreview(previewUrl);
      }
    }
  };

  const handleMobileImageChange = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      const allowedImageTypes = ["image/jpeg", "image/png", "image/jpg"]; // Add more allowed types as needed

      if (!allowedImageTypes.includes(file.type)) {
        alert("Please upload a valid image file JPEG, PNG, or JPG");
        setMessageType("error");
      } else if (file.size > 5 * 1024 * 1024) {
        alert("Please upload an image file less than 5MB in size");
        setMessageType("error");
      } else {
        setMobile(file);
        const previewUrl = URL.createObjectURL(file);
        setMobilePreview(previewUrl);
      }
    }
  };

  const handleCreateBanner = async () => {
    if (!validator.isEmpty(offerName)) {
      const res = await vasthuUrl
        .post(
          `image/uploadofferbannerimage`,
          {
            offerName: offerName,
            pathName: path,
            title: title,
            desktopFile: desktop,
            mobileFile: mobile,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          console.log("after save", res.data);
          alert(`Successfully Created ${offerName} Minute ...!`);
          setMessageType("success");
          ModalClose();
          setReLoad(true);
        });
    } else {
      alert("Please Fill Enter Minute");
      setMessageType("error");
    }
  };

  const handleUpdateBanner = async (id) => {
    const bannerId = saveData.id;
    // if (!validator.isEmpty(saveData.id)) {
    const res = await vasthuUrl
      .put(
        `image/updatevasthubannerimage/${bannerId}`,
        {
          offerName: offerName ? offerName : saveData.offerName,
          pathName: path ? path : saveData.pathName,
          title: title ? title : saveData.title,
          desktopFile: desktop ? desktop : null,
          mobileFile: mobile ? mobile : null,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        console.log("after update", res.data);
        alert(`Successfully Update Id ${id} to ${offerName} ...!`);
        setMessageType("success");
        ModalClose();
        setReLoad(true);
      });
    // } else {
    //   alert("Error");
    //   setMessageType("error");
    //   ModalClose();
    // }
  };

  const handleUpdateStatus = async (id, status) => {
    try {
      const res = await vasthuUrl.post(
        `image/updateBannerStatus`,
        {
          id: id,
          status: status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(`Updating status of ${id} to ${status}`);
      alert(`Updating status of ${id} to ${status}`);
      setMessageType("success");
      setReLoad(true);
    } catch (error) {
      alert("Failed to Update");
      setMessageType("error");
    }
  };

  const columns = [
    { field: "id", headerName: "S.NO", width: 70 },
    { field: "offerName", headerName: "Offer NAME", width: 150 },
    { field: "pathName", headerName: "PATH NAME", width: 150 },
    { field: "title", headerName: "TITLE", width: 100 },
    {
      field: "actions",
      headerName: "ACTION",
      renderCell: (params) => (
        <div
          style={{ display: "flex", justifyContent: "center", gap: "0.5rem" }}
        >
          <Button
            className="all_btn"
            onClick={() => handleShow(params.row)}
            disabled={params.row.status === "INACTIVE"}
          >
            Update
          </Button>

          {params.row.status === "INACTIVE" ? (
            <Button
              className="mx-1 all_btn"
              onClick={() => handleUpdateStatus(params.row.id, "ACTIVE")}
            >
              Activate
            </Button>
          ) : (
            <Button
              className="mx-1 all_btn"
              onClick={() => handleUpdateStatus(params.row.id, "INACTIVE")}
            >
              Deactivate
            </Button>
          )}
        </div>
      ),
      width: 250,
    },
  ];

  return (
    <div>
      <Toast msg={message} type={messageType} trigger={toastTrigger} />

      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>
          <Button
            className="my-2 all_btn"
            style={{ float: "right" }}
            onClick={ModalOpen}
          >
            Add Banner
          </Button>
        </div>
        <center>
          <h2>Banner List</h2>
        </center>
        {bannerList.length > 0 ? (
          <>
            <center className="datagrid mt-2">
              <Grid container className="deskTable">
                <DataGrid
                  className="all_border text-center me-3 data_grid"
                  rows={bannerList}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 10 },
                    },
                  }}
                  pageSizeOptions={[5, 10]}
                  getRowId={(row) => row.id}
                />
              </Grid>
            </center>
          </>
        ) : (
          <center>
            <h5>{t("TXT.NO.REC.FUD.LO")}</h5>
          </center>
        )}
      </div>

      <div>
        <Modal
          show={model}
          onHide={ModalClose}
          // size="lg"
          centered
          fullscreen={true}
        >
          <Card className="p-2">
            <Card.Header className="all_background">
              <center>
                <b>Create Banner</b>
              </center>
            </Card.Header>
            <Card.Body>
              <Form>
                <Form.Group className="mb-3" controlId="OfferName">
                  <Form.Label style={{ fontSize: 20, fontWeight: "bold" }}>
                    Offer Name
                  </Form.Label>
                  <Form.Control
                    type="text"
                    className="form_feild"
                    placeholder="Enter Name"
                    value={saveData ? saveData.offerName : offerName}
                    onChange={(e) =>
                      setSaveData(
                        { ...saveData, offerName: e.target.value },
                        setOfferName(e.target.value)
                      )
                    }
                  />
                </Form.Group>

                <Form.Group controlId="formFile" className="mb-1">
                  <Form.Label>Desktop Image</Form.Label>
                  <Form.Control
                    type="file"
                    className="form_feild"
                    onChange={(e) => handleDesktopImageChange(e)}
                  />
                </Form.Group>

                {desktopPreview ? (
                  <Image
                    src={desktopPreview}
                    fluid
                    thumbnail
                    alt="Upload Image"
                    style={{
                      width: "150px",
                      height: "108px",
                      objectFit: "cover",
                    }}
                  />
                ) : saveData && saveData.desktopimageUrl ? (
                  <Image
                    src={saveData.desktopimageUrl}
                    fluid
                    thumbnail
                    alt="Upload Image"
                    style={{
                      width: "150px",
                      height: "108px",
                      objectFit: "cover",
                    }}
                  />
                ) : null}

                <Form.Group controlId="formFile" className="mb-1">
                  <Form.Label>Mobile Image</Form.Label>
                  <Form.Control
                    type="file"
                    className="form_feild"
                    onChange={(e) => handleMobileImageChange(e)}
                  />
                </Form.Group>

                {mobilePreview ? (
                  <Image
                    src={mobilePreview}
                    fluid
                    thumbnail
                    alt="Upload Image"
                    style={{
                      width: "150px",
                      height: "108px",
                      objectFit: "cover",
                    }}
                  />
                ) : saveData && saveData.mobileimageUrl ? (
                  <Image
                    src={saveData.mobileimageUrl}
                    fluid
                    thumbnail
                    alt="Upload Image"
                    style={{
                      width: "150px",
                      height: "108px",
                      objectFit: "cover",
                    }}
                  />
                ) : null}

                <Form.Group className="mb-3" controlId="screenPath">
                  <Form.Label style={{ fontSize: 20, fontWeight: "bold" }}>
                    Screen Path
                  </Form.Label>
                  <Form.Control
                    type="text"
                    className="form_feild"
                    placeholder="Enter path"
                    value={saveData ? saveData.pathName : path}
                    onChange={(e) =>
                      setSaveData(
                        { ...saveData, pathName: e.target.value },
                        setPath(e.target.value)
                      )
                    }
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="title">
                  <Form.Label style={{ fontSize: 20, fontWeight: "bold" }}>
                    Title
                  </Form.Label>
                  <Form.Control
                    type="text"
                    className="form_feild"
                    placeholder="Enter title"
                    value={saveData ? saveData.title : title}
                    onChange={(e) =>
                      setSaveData(
                        { ...saveData, title: e.target.value },
                        setTitle(e.target.value)
                      )
                    }
                  />
                </Form.Group>
              </Form>
            </Card.Body>
          </Card>
          <Modal.Footer
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "white",
            }}
          >
            <Button className="all_btn" onClick={ModalClose}>
              Close
            </Button>
            {saveData && saveData.id ? (
              <Button className="all_btn" onClick={() => handleUpdateBanner()}>
                Update
              </Button>
            ) : (
              <Button className="all_btn" onClick={() => handleCreateBanner()}>
                Add Banner
              </Button>
            )}
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default BannerList;
