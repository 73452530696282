import React, { useState, useEffect } from "react";
import {
  Form,
  Card,
  Button,
  Spinner,
  Alert,
  InputGroup,
} from "react-bootstrap";
import validator from "validator";
import { UseAdminContext } from "../context/AdminContext";

const AdminAddClientForm = () => {
  // const { token } = UseAuth();
  const {
    handleClientRegister,
    clientLoading,
    clientError,
  } = UseAdminContext();
  const [clientName, setClientName] = useState("");
  const [clientEmail, setClientEmail] = useState("");
  const [clientPhone, setClientPhone] = useState("");
  const [clientNewPassword, setClientNewPassword] = useState("");
  const [clientConfirmPassword, setClientConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [countryCode, setCountryCode] = useState("0");

  useEffect(() => {
    alert(clientError);
  }, [clientError]);

  const alert = (msg) => {
    setMessage(msg);

    setTimeout(() => {
      setMessage("");
    }, 4000);
  };

  const onSubmit = (event) => {
    const phone_with_code = countryCode.concat(clientPhone);
    event.preventDefault();
    if (
      !validator.isEmpty(clientName) &&
      !validator.isEmpty(clientPhone) &&
      !validator.isEmpty(clientNewPassword) &&
      !validator.isEmpty(clientConfirmPassword)
    ) {
      if (
        validator.isMobilePhone(clientPhone, "en-IN") &&
        validator.isEmail(clientEmail) &&
        validator.equals(clientNewPassword, clientConfirmPassword)
      ) {
        const credentials = {
          user: clientName,
          phone: phone_with_code,
          email: clientEmail,
          pass: clientConfirmPassword,
        };
        handleClientRegister(credentials, (res) => {
          if (res === "success") {
            alert("Successfully Registered User");
            setClientName("");
            setClientEmail("");
            setClientPhone("");
            setClientNewPassword("");
            setClientConfirmPassword("");
          } else {
            alert("User Registration Fails");
          }
        });
      } else {
        alert("CREDENTIALS GIVEN IS INVALID");
      }
    } else {
      alert("CREDENTIALS IS EMPTY");
    }
  };

  return (
    <Card className="p-3 all_border">
      <Form>
        {message && (
          <Alert variant="danger" className="text-center">
            {message}
          </Alert>
        )}
        <Form.Group className="mb-3" controlId="adminAddClientName">
          <Form.Label>Customer Name* :</Form.Label>
          {/* <Form.Control
            className="form_feild"
            type="text"
            placeholder="Enter Unique User Name"
            value={clientName}
            onChange={(e) => {
              console.log(e.target.value);
              setClientName(e.target.value);
            }}
            autoComplete="off"
          /> */}
          <Form.Control
            className="form_feild"
            type="text"
            placeholder="Enter Unique User Name"
            value={clientName}
            onChange={(e) => {
              let input = e.target.value;
              // Ensure only the first character is a letter
              input = input.replace(/^([^A-Za-z])/, ""); // Remove non-alphabetic characters from the start
              // Allow only alphanumeric characters after the first character
              input = input.replace(/[^A-Za-z0-9]/g, ""); // Remove non-alphanumeric characters
              console.log(input);
              setClientName(input);
            }}
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group className="mb-3 " controlId="adminAddClientEmail">
          <Form.Label>Email address :</Form.Label>
          <Form.Control
            className="form_feild"
            type="email"
            placeholder="name@example.com"
            value={clientEmail}
            onChange={(e) => {
              console.log(e.target.value);
              setClientEmail(e.target.value);
            }}
            autoComplete="off"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="adminAddClientPhone">
          <Form.Label>Phone No* :</Form.Label>
          <InputGroup className="mb-3">
            <Form.Select
              aria-label="Country Code"
              style={{ maxWidth: "10%" }}
              value={countryCode}
              className="form_feild countrywidth"
              onChange={(e) => {
                setCountryCode(e.target.value);
              }}
            >
              <option defaultChecked key={1} value="0">
                IND
              </option>
              <option key={2} value="+65">
                SGP
              </option>
              <option key={3} value="+60">
                MYS
              </option>
              <option key={4} value="+1">
                USA
              </option>
            </Form.Select>
            <Form.Control
              className="form_feild"
              type="phone"
              placeholder="Enter Mobile No"
              value={clientPhone}
              onChange={(e) => {
                console.log(e.target.value);
                setClientPhone(e.target.value);
              }}
              autoComplete="new-off"
            />
          </InputGroup>
        </Form.Group>
        <Form.Group className="mb-3" controlId="adminAddClientNewPassword">
          <Form.Label>New Password* :</Form.Label>
          <Form.Control
            className="form_feild"
            type="password"
            placeholder="Enter New Password"
            value={clientNewPassword}
            onChange={(e) => {
              console.log(e.target.value);
              setClientNewPassword(e.target.value);
            }}
            autoComplete="new-password"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="adminAddClientConfirmPassword">
          <Form.Label>Confirm Password* :</Form.Label>
          <Form.Control
            className="form_feild"
            type="password"
            placeholder="Enter Confirm Password"
            value={clientConfirmPassword}
            onChange={(e) => {
              console.log(e.target.value);
              setClientConfirmPassword(e.target.value);
            }}
            autoComplete="new-password"
          />
        </Form.Group>
      </Form>
      <Card.Footer className="text-center all_border">
        {!clientLoading ? (
          <Button
            style={{ borderRadius: 50 }}
            className="all_btn"
            type="submit"
            onClick={onSubmit}
          >
            Register Customer
          </Button>
        ) : (
          <Button className="all_btn" style={{ borderRadius: 50 }} disabled>
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
            Loading...
          </Button>
        )}
      </Card.Footer>
    </Card>
  );
};
export default AdminAddClientForm;
