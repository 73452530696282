import React, { useState } from "react";
import { useEffect } from "react";
import {
  Button,
  Container,
  FloatingLabel,
  Form,
  Modal,
  Table,
  Alert,
  Spinner,
} from "react-bootstrap";
import { vasthuUrl } from "../component/Api";
import { v4 as uuidv4 } from "uuid";
import { UseVasthu } from "../context/vasthu";
import { useNavigate } from "react-router-dom";

const HomeWindow = () => {
  const { homeRec } = UseVasthu();
  const navigate = useNavigate();
  const token = window.sessionStorage.getItem("token");
  const [showWindowForm, setshowWindowForm] = useState(false);
  const [facingList, setFacingList] = useState([]);
  const [windowDirectionId, setwindowDirectionId] = useState();
  const [windowDirectionName, setwindowDirectionName] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [windowName, setWindowName] = useState("");
  const [loading, setLoading] = useState(false);
  const [windowArray, setWindowArray] = useState([]);

  useEffect(() => {
    vasthuUrl.get(`vasthu/getdirections`).then((direction) => {
      setFacingList(direction.data);
    });
  }, []);

  const handleWindowShow = () => {
    setshowWindowForm(true);
  };

  const handleWindowClose = () => {
    setshowWindowForm(false);
    setwindowDirectionId("");
    console.log("WINDOW OBH", windowArray);
  };

  const handleWindowSubmit = () => {
    let windowCheckArr = windowArray.map((i) => i.windowName);
    console.log(
      windowCheckArr,
      windowName,
      !windowCheckArr.includes(windowName)
    );
    if (windowDirectionId && windowDirectionName != " " && windowName !=='') {
      if (!windowCheckArr.includes(windowName)) {
        let windowDirectionObj = facingList.find(
          (i) => i.id == windowDirectionId
        );
        windowArray.push({
          windowUuid: uuidv4(),
          windowDirectionName: windowDirectionObj.name,
          windowName,
          windowDirectionId,
        });
        setWindowArray([...windowArray]);
        setwindowDirectionId("");
        setWindowName(" ");
        setshowWindowForm(false);
      } else {
        alert("Window name alredy exists");
      }
    } else {
      alert("PLEASE FILL THE DETAILS");
    }
  };

  const handleElemDelete = (wUuid) => {
    let windowArr = windowArray.filter((i) => i.windowUuid !== wUuid);

    console.log("DELETED ARR", windowArr);
    setWindowArray(windowArr);
  };

  const alert = (msg) => {
    setErrMsg(msg);
    setTimeout(() => {
      setErrMsg("");
    }, 3000);
  };

  const windowAllSubmit = async () => {
    setLoading(true);
    try {
      const res = await vasthuUrl.post(
        `savewindowdetails`,
        {
          homeUuid: homeRec,
          windows: windowArray,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(res.data);
      alert(res.data.message);
      navigate("/user/homelevel");
      setLoading(false);
    } catch (error) {
      alert("SERVER ERROR");
      setLoading(false);
    }

    console.log(
      "save details",
      JSON.stringify({
        houseUuid: homeRec,
        windows: windowArray,
      })
    );
  };

  return (
    <Container>
      <center>
        <Button
          variant="outline-primary"
          style={{ borderRadius: 50 }}
          onClick={handleWindowShow}
          className="mt-2"
        >
          ADD WINDOWS
        </Button>
      </center>

      <Modal show={showWindowForm} onHide={handleWindowClose}>
        <Modal.Header closeButton>
          <Modal.Title>ADD WINDOW</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {errMsg && (
              <Alert variant="info" className="text-center">
                {errMsg}
              </Alert>
            )}
            <FloatingLabel
              className="mb-1"
              controlId="window"
              label="Window Name"
            >
              <Form.Control
                type="text"
                placeholder="name"
                value={windowName}
                onChange={(e) => {
                  setWindowName(e.target.value.trim());
                }}
              />
            </FloatingLabel>

            <Form.Group className="mb-1" controlId="element facing">
              <Form.Select
                aria-label="window facing"
                size="lg"
                value={windowDirectionId}
                onChange={(e) => {
                  console.log(e.target.value);
                  if (e.target.value) {
                    let obj = facingList.find((i) => i.id == e.target.value);
                    console.log("VALUE NAME" + e.target.value);
                    setwindowDirectionId(e.target.value);
                    setwindowDirectionName(obj.name);
                  }
                }}
              >
                <option key={0} value="">
                  Select Location
                </option>
                {facingList.map((a, b) => (
                  <option key={b} value={a.id}>
                    {a.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Form>
          <div className="text-center mt-3">
            <Button
              variant="outline-primary"
              style={{ borderRadius: 50 }}
              onClick={handleWindowSubmit}
            >
              SUBMIT
            </Button>
            {"  "}
            <Button
              variant="outline-primary"
              style={{ borderRadius: 50 }}
              onClick={handleWindowClose}
            >
              CANCEL
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {windowArray.length > 0 ? (
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>Window Name</th>
              <th>Location</th>
              <th>Action</th>
            </tr>
          </thead>

          {windowArray.map((i, j) => (
            <tbody key={j}>
              <tr key={j}>
                <td>{i.windowName}</td>
                <td>{i.windowDirectionName}</td>
                <td>
                  <Button
                    variant="outline-primary"
                    style={{ borderRadius: 50 }}
                    onClick={() => handleElemDelete(i.windowUuid)}
                    className="mt-2"
                  >
                    DELETE
                  </Button>
                </td>
              </tr>
            </tbody>
          ))}
        </Table>
      ) : (
        <div className="my-4">
          <center>
            <h4>PLEASE ADD WINDOWS</h4>
          </center>
        </div>
      )}

      {windowArray.length > 0 ? (
        <div className="my-4">
          <center>
            {!loading ? (
              <Button
                variant="outline-warning"
                style={{ borderRadius: 50 }}
                onClick={windowAllSubmit}
              >
                SUBMIT
              </Button>
            ) : (
              <Button
                variant="outline-warning"
                style={{ borderRadius: 50 }}
                disabled
              >
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Loading...
              </Button>
            )}
          </center>
        </div>
      ) : null}
    </Container>
  );
};

export default HomeWindow;
