import React from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function WRTCHomePage() {
  const navigate = useNavigate();
  return (
    <div className="mt-5">
      <center>
        <h4>
          <u>Welcome To Aawsoft WebRTC</u>
        </h4>
      </center>
      <Row className="justify-content-center m-5">
      <Col sm={6} md={3} className="mt-2">
          <Card className="p-3" style={{ cursor: "pointer" }}>
            <Card.Body>
              <Card.Title className="text-center">Start Video Call</Card.Title>
              <Button
                variant="primary"
                className="d-block mx-auto mt-2"
                onClick={() => navigate("startcall")}
              >
                Start Video Call
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={6} md={3} className="mt-2">
          <Card className="p-3" style={{ cursor: "pointer" }}>
            <Card.Body>
              <Card.Title className="text-center">Join Video Call</Card.Title>
              <Button
                variant="primary"
                className="d-block mx-auto mt-2"
                onClick={() => navigate("joincall")}
              >
                Join Video Call
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={6} md={3} className="mt-2">
          <Card className="p-3" style={{ cursor: "pointer" }}>
            <Card.Body>
              <Card.Title className="text-center">Start Call</Card.Title>
              <Button
                variant="primary"
                className="d-block mx-auto mt-2"
                onClick={() => navigate("startaudiocall")}
              >
                Start Call
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={6} md={3} className="mt-2">
          <Card className="p-3" style={{ cursor: "pointer" }}>
            <Card.Body>
              <Card.Title className="text-center">Join Call</Card.Title>
              <Button
                variant="primary"
                className="d-block mx-auto mt-2"
                onClick={() => navigate("joinaudiocall")}
              >
                Join Call
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={6} md={3} className="mt-2">
          <Card className="p-3" style={{ cursor: "pointer" }}>
            <Card.Body>
              <Card.Title className="text-center">Start Chat</Card.Title>
              <Button
                variant="primary"
                className="d-block mx-auto mt-2"
                onClick={() => navigate("startchat")}
              >
                Start Chat
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={6} md={3} className="mt-2">
          <Card className="p-3" style={{ cursor: "pointer" }}>
            <Card.Body>
              <Card.Title className="text-center">Join Chat</Card.Title>
              <Button
                variant="primary"
                className="d-block mx-auto mt-2"
                onClick={() => navigate("joinchat")}
              >
                Join Chat
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default WRTCHomePage;
