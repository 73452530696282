import React, { useEffect, useState, useContext } from "react";
import {
  Container,
  Button,
  Form,
  Modal,
  Table,
  Col,
  Card,
} from "react-bootstrap";
import { vasthuUrl } from "../component/Api";
import { Context } from "./Service";
import validator from "validator";
import { clippingParents } from "@popperjs/core";
import { useTranslation, Trans } from "react-i18next";
import { Alert, Spinner } from "react-bootstrap";
import { format, isToday } from "date-fns";
import { DataGrid } from "@mui/x-data-grid";
import { Grid } from "@mui/material";

function ServiceList() {
  const { t } = useTranslation();
  const token = window.sessionStorage.getItem("token");
  const [temp, setTemp] = useState([]);
  console.log("temp", temp);
  const [listModal, setListModal] = useState(false);

  //adding for active and inactive offers
  const [status, setStatus] = useState("");
  const [getstatus, setGetStatus] = useState([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [detail, setDetail] = useState([]);
  const [sDate, setSDate] = useState("");
  const [eDate, setEDate] = useState("");

  const alert = (msg) => {
    setMessage(msg);
    setTimeout(() => {
      setMessage("");
    }, 4000);
  };

  const { setOfferList, offerList } = useContext(Context);
  console.log("offerList", offerList);

  const handleClose = () => setShow(false);
  const handleShow = (id) => {
    setShow(true);
    setDetail(id);
  };

  useEffect(() => {
    try {
      vasthuUrl
        .get(`/offer/getofferstatus`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("Offer_status--> ", res.data);
          setGetStatus(res.data);
        });
    } catch (e) {
      alert("SERVER ERROR");
    }

    try {
      vasthuUrl
        .get(`/offer/getVasthuAllCreatedOffersList`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          if (res.data.length > 0) {
            res.data.map((i, j) => (i.edit = true));
            setOfferList(res.data);
            console.log("created offers", res.data);
          } else {
            setOfferList([]);
            console.log("created offers", []);
          }
        });
    } catch (e) {
      console.log("getAllCreatedOffers", e);
    }
  }, [setOfferList]);

  const updateDetail = async (e) => {
    if (!validator.isEmpty(status)) {
      try {
        const res = await vasthuUrl.post(
          `offer/updateCreatedofferInAdmin`,
          {
            id: detail,
            status: status,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        console.log("res", res.data);
        alert("Update Successfully!!!");
        setStatus([]);
        handleClose(true);
      } catch (error) {
        alert("Failed to Update");
      }
    } else {
      alert("Please Select Value");
    }
  };

  const handleView = (val) => {
    setTemp(val);
    setListModal(true);
  };
  // const handleEdit = async (id) => {
  //   const tempObj = offerList.find((i) => i.id === id);
  //   tempObj.edit = !tempObj.edit;
  //   setOfferList((prev) => [...prev]);
  // };
  const formatDate = (date) => {
    try {
      return format(date, "d MMM yyyy, HH:mm a"); // Example format string
    } catch (error) {
      console.error("Error formatting date:", error.message);
      return ""; // or return a default value
    }
  };

  const column = [
    { field: "offerName", headerName: "OfferName", width: 200 },
    { field: "offerNameTamil", headerName: "Offer-Tamil", width: 150 },
    { field: "offerNameHindi", headerName: "Offer-Hindi", width: 200 },
    { field: "offerFrequency", headerName: "Frequency", width: 150 },
    { field: "validity", headerName: "Validity", width: 150 },
    { field: "offerPrice", headerName: "Offer Price", width: 150 },
    { field: "status", headerName: "Offer Status", width: 150 },
    {
      field: "offerDetails",
      headerName: "Action",
      type: "offerDetails",
      width: 200,
      renderCell: (i) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "0.5rem",
          }}
        >
          <Button
            className="mx-1 all_btn"
            onClick={() => {
              handleView(i.row.offerDetails);
              setSDate(i.row.startDate);
              setEDate(i.row.endDate);
            }}
          >
            View
          </Button>
          <Button className="mx-1 all_btn" onClick={() => handleShow(i.row.id)}>
            Activate/Deactivate
          </Button>
        </div>
      ),
    },
    // {
    //   field: "editprofdata",
    //   headerName: "Activate/Deactivate",
    //   type: "Activate/Deactivate",
    //   width: 150,
    //   renderCell: (i) => (

    //   ),
    // },
  ];

  return (
    <Container>
      <Modal
        className="p-1"
        show={listModal}
        onHide={() => {
          setListModal(false);
          setTemp([]);
          setEDate();
          setSDate();
        }}
        size="lg"
        centered
      >
        <Modal.Header style={{ backgroundColor: "#ffbb33" }} closeButton>
          <h3>More Details</h3>
        </Modal.Header>
        <Modal.Body>
          <div>
            {sDate ? (
              <>
                <b>Start Date :</b>
                <span>{formatDate(new Date(sDate))}</span>
              </>
            ) : (
              <></>
            )}
          </div>
          <div className="my-2">
            {eDate ? (
              <>
                <b>End Date :</b>
                <span>{formatDate(new Date(eDate))}</span>
              </>
            ) : (
              <></>
            )}
          </div>
          {temp ? (
            <Table striped bordered hover responsive className="all_border">
              <thead>
                <tr>
                  <th>serviceName</th>
                  <th>serviceCostType</th>
                  <th>Count</th>
                  <th>BasePrice</th>
                  <th>Discount%</th>
                  <th>DiscountPrice</th>
                  <th>ActualPrice</th>
                </tr>
              </thead>
              <tbody>
                {temp.map((i, j) => (
                  <tr key={j}>
                    <td>{i.serviceName}</td>
                    <td>{i.serviceCostType}</td>
                    <td>{i.count}</td>
                    <td>{i.basePrice}</td>
                    <td>{i.discountPercentage}</td>
                    <td>{i.discountedPrice}</td>
                    <td>{i.actualPrice}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <center>
              <h3>No service Available</h3>
            </center>
          )}
        </Modal.Body>
      </Modal>

      {message && (
        <Alert variant="info" className="text-center">
          {message}
        </Alert>
      )}

      {offerList.length > 0 ? (
        <>
          <Grid container className="deskTable">
            <DataGrid
              className="all_border - text-center me-3 data_grid"
              rows={offerList}
              columns={column}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 5 },
                },
              }}
              pageSizeOptions={[5, 10]}
              // checkboxSelection
              getRowId={(row) => row.id}
            />
          </Grid>
          {offerList.map((i, j) => (
            <div className="justify-content-center mobileCard">
              <Card
                className="mobileCard"
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  backgroundColor: "#fff7c3",
                  border: "1px solid #ffb33e",
                }}
              >
                <Card.Body>
                  <Card.Subtitle className="mb-2">
                    Name: {i.offerName}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2 text-muted">
                    Offer-Tamil: {i.offerNameTamil}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2 text-muted">
                    Offer-Hindi: {i.offerNameHindi}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2 text-muted">
                    Frequency: {i.offerFrequency}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2 text-muted">
                    validity: {i.validity}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2 text-muted">
                    offerPrice: {i.offerPrice}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2 text-muted">
                    status: {i.status}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2 text-muted d-flex justify-content-evenly g-2">
                    <Button
                      className="mx-1 all_btn mb-1"
                      size="sm"
                      onClick={() => {
                        handleView(i.offerDetails);
                        setSDate(i.startDate);
                        setEDate(i.endDate);
                      }}
                    >
                      View
                    </Button>
                    <Button
                      size="sm"
                      className="mx-1 all_btn"
                      onClick={() => handleShow(i.id)}
                    >
                      Activate/Deactivate
                    </Button>
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </div>
          ))}
        </>
      ) : (
        <center>
          <h2>No Offer Created</h2>
        </center>
      )}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="all_background" closeButton>
          <Modal.Title>Changing Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Label>Activate or Deactivate : </Form.Label>
            <Form.Select
              // style={{ width: "120px" }}
              defaultValue={detail.status}
              onChange={(e) => {
                setStatus(e.target.value);
              }}
            >
              <option>select status</option>
              {getstatus.map((i) => (
                <>
                  <option>{i.status}</option>
                </>
              ))}
            </Form.Select>
          </Form>
        </Modal.Body>
        <Modal.Footer className="columreversebtn d-flex justify-content-evenly">
          <Button className="btnfullwidth all_btn" onClick={handleClose}>
            Close
          </Button>
          <Button
            className="btnfullwidth all_btn"
            onClick={(e) => {
              updateDetail();
            }}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default ServiceList;
