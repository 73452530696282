import React, { useEffect, useState } from "react";
import { vasthuUrl } from "../component/Api";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Card,
  Image,
  Navbar,
  Nav,
  Tab,
  Modal,
} from "react-bootstrap";
import ProfDash from "./ProfDash";
import ProfAppointment from "./ProfAppointment";
import ProfProfile from "./ProfProfile";
import { UseAuth } from "../context/Auth";
import { ProfessionalProvider } from "../context/ProfContext";
import ProfPayment from "./ProfPayment";
import LangChanger from "../component/LangChanger";
import ProfUserMeeting from "./profUserMeeing";
import { useTranslation } from "react-i18next";
import AudioCallProfessional from "./AudioCallProfessional";
import ConsultantReview from "./Consultant/ConsultantReview";
import {
  FcHome,
  FcCalendar,
  FcComments,
  FcSms,
  FcDoughnutChart,
  FcViewDetails,
  FcBusinessContact,
  FcCellPhone,
} from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import ConsultantChatHistory from "./Consultant/ConsultantChatHistory";

const ProfessionalTabs = () => {
  const token = window.sessionStorage.getItem("token");
  const userId = window.sessionStorage.getItem("userId");
  const AuthName = window.sessionStorage.getItem("authname");
  const lang = window.localStorage.getItem("i18nextLng");
  const { t } = useTranslation();
  const { onLogout } = UseAuth();
  const navigate = useNavigate();

  const [imgUrl, setImgUrl] = useState();
  const [status, setStatus] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState({});
  const [getDetails, setGetDetails] = useState({});
  console.log("getDetails", getDetails);
  const [statusList, setStatusList] = useState([]);
  const [chatDatas, setChatDatas] = useState([]);
  // console.log("chatDatas", chatDatas);

  //conmsultant ONLINE/OFFLINE status
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (statusId) => {
    setShow(true);
    const currentStatus = statusList.find((stat) => stat.id == statusId);
    setSelectedStatus(currentStatus || {});
  };

  //Chat Alert
  const [chatAlert, setChatAlert] = useState(false);

  const handleChatClose = () => setChatAlert(false);
  const handleChatShow = () => setChatAlert(true);

  useEffect(() => {
    const fetchData = () => {
      vasthuUrl
        .get(`/initiateChat/getchatdetailsforconsultant/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setChatDatas(res.data);
          if (res.data.status === "in-progress") {
            handleChatShow();
            console.log("handleChatShow()");
          }
        })
        .catch((error) => {
          console.log("SERVER ERROR", error);
        });
    };

    fetchData();
    const intervalId = setInterval(fetchData, 30000);
    // const intervalId = setInterval(fetchData, 120000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const fetchStatusList = async () => {
      try {
        const res = await vasthuUrl.get(`info/getprofcallstatus`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setStatusList(res.data);
      } catch (error) {
        console.error("Failed to fetch status list", error);
      }
    };

    const fetchUserStatus = async () => {
      try {
        const res = await vasthuUrl.get(`info/getProStatus/${userId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setGetDetails(res.data[0]);
      } catch (error) {
        console.error("Failed to fetch user status", error);
      }
    };

    fetchStatusList();
    fetchUserStatus();
  }, [token, userId]);

  const updateDetail = async () => {
    try {
      await vasthuUrl.post(
        `info/professional/updateStatusForProfessional/${userId}`,
        { profstatus: status },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setGetDetails(statusList.find((stat) => stat.id == status));
    } catch (error) {
      console.error("Failed to update status", error);
    }
  };

  const updateOnLogout = () => {
    onLogout();
  };

  useEffect(() => {
    const fetchProfileImage = async () => {
      try {
        const res = await vasthuUrl.get(`getuserprofiledetail/${userId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setImgUrl(res.data.profileUrl);
      } catch (error) {
        console.error("Failed to fetch profile image", error);
      }
    };

    fetchProfileImage();
  }, [token, userId]);

  return (
    <ProfessionalProvider>
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey="Professionaldash"
        justify
        unmountOnExit
        fill
        mountOnEnter
      >
        <Row>
          <Col className="adminLeft" sm={12} md={3}>
            <Card
              className="text-center border-warning m-1"
              style={{ width: "100%" }}
            >
              <Card.Body className="all_border">
                {imgUrl ? (
                  <Image
                    fluid
                    thumbnail
                    roundedCircle
                    style={{ width: 100, height: 100 }}
                    src={imgUrl}
                    alt="profile"
                  />
                ) : (
                  <Image
                    fluid
                    thumbnail
                    roundedCircle
                    style={{ width: 100, height: 100 }}
                    src={require("../images/noimage.jpg")}
                  />
                )}
                <Card.Header className="all_border">
                  <Card.Title>{t("TXT.CON.LO")}</Card.Title>
                </Card.Header>
              </Card.Body>
              <Nav
                variant="pills"
                bg="warning"
                className="flex-column"
                style={{ cursor: "pointer" }}
              >
                <Nav.Item>
                  <Nav.Link eventKey="Professionaldash">
                    {t("PR.LNK.DS")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="ProfessionalAppoint">
                    {t("PR.LNK.APMNT")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="ProfessionalUserMeeting">
                    {t("PR.APMNT.MTNGTAB")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Professionalprofile">
                    {t("PR.LNK.AC")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="audiocallprofessional">
                    {t("CON.DAS.AUD.CAL")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="consultantChatHistory">
                    {t("USR.CHAT.HIS.HEAD.UP")}
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="consultantreview">
                    {t("CON.DAS.REVI")}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </Card>
          </Col>
          <Col sm={12} md={9}>
            <Navbar expand={"md"} className="professionalHeader all_background">
              <Container fluid>
                <Navbar.Brand href="#" className="prof_user">
                  <div>{t("CM_PUB_LOGO_TITLE")}</div>
                  {AuthName && (
                    <h3 style={{ marginRight: "1rem" }}>{AuthName}</h3>
                  )}
                </Navbar.Brand>
                <Nav>
                  <div style={{ display: "ruby" }}>
                    <div className="px-1 my-1">
                      <Form>
                        <Form.Select
                          style={{ width: "120px" }}
                          value={status || getDetails.id}
                          onChange={(e) => {
                            setStatus(e.target.value);
                            handleShow(e.target.value);
                          }}
                        >
                          {statusList
                            .filter((i) => i.id == getDetails.id)
                            .map((i) => (
                              <option key={i.id} value={i.id}>
                                {lang === "en"
                                  ? i.status
                                  : lang === "ta"
                                  ? i.status_ta
                                  : i.status_hi}
                              </option>
                            ))}
                          {statusList
                            .filter((i) => i.id !== getDetails.id)
                            .map((i) => (
                              <option key={i.id} value={i.id}>
                                {lang === "en"
                                  ? i.status
                                  : lang === "ta"
                                  ? i.status_ta
                                  : i.status_hi}
                              </option>
                            ))}
                        </Form.Select>
                      </Form>
                    </div>
                    <div className="mt-2">
                      <LangChanger />
                    </div>
                    <div className="px-1 my-1">
                      <Button
                        variant="light"
                        size="md"
                        style={{ fontWeight: "bold" }}
                        onClick={updateOnLogout}
                      >
                        {t("PR.LOUT")}
                      </Button>
                    </div>
                  </div>
                </Nav>
              </Container>
            </Navbar>
            <Row>
              <Col className="adminTabMobile">
                <Card className="m-1">
                  <Nav
                    variant="pills"
                    className="justify-content-center text-center py-1"
                  >
                    <Nav.Item>
                      <Nav.Link
                        style={{ fontSize: 10 }}
                        eventKey="Professionaldash"
                        href="#"
                      >
                        {t("PR.LNK.DS")}
                        <br />
                        <FcDoughnutChart size={30} />
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        style={{ fontSize: 10 }}
                        eventKey="ProfessionalAppoint"
                        href="#"
                      >
                        {t("PR.LNK.APMNT")}
                        <br />
                        <FcCalendar size={30} />
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        style={{ fontSize: 10 }}
                        eventKey="ProfessionalUserMeeting"
                        href="#"
                      >
                        {t("PR.APMNT.MTNGTAB")}
                        <br />
                        <FcViewDetails size={30} />
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        style={{ fontSize: 10 }}
                        eventKey="Professionalprofile"
                        href="#"
                      >
                        {t("PR.LNK.AC")}
                        <br />
                        <FcBusinessContact size={30} />
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        style={{ fontSize: 10 }}
                        eventKey="audiocallprofessional"
                        href="#"
                      >
                        {t("CON.DAS.AUD.CAL")}
                        <br />
                        <FcCellPhone size={30} />
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        style={{ fontSize: 10 }}
                        eventKey="consultantChatHistory"
                        href="#"
                      >
                        {t("USR.CHAT.HIS.HEAD.UP")}
                        <br />
                        <FcCellPhone size={30} />
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        style={{ fontSize: 10 }}
                        eventKey="consultantreview"
                        href="#"
                      >
                        {t("CON.DAS.REVI")}
                        <br />
                        <FcCellPhone size={30} />
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Card>
              </Col>
            </Row>
            <Tab.Content className="adminTab">
              <Tab.Pane eventKey="Professionaldash">
                <ProfDash />
              </Tab.Pane>
              <Tab.Pane eventKey="ProfessionalAppoint">
                <ProfAppointment />
              </Tab.Pane>
              <Tab.Pane eventKey="Professionalprofile">
                <ProfProfile />
              </Tab.Pane>
              <Tab.Pane eventKey="ProfessionalUserMeeting">
                <ProfUserMeeting />
              </Tab.Pane>
              <Tab.Pane eventKey="audiocallprofessional">
                <AudioCallProfessional />
              </Tab.Pane>
              <Tab.Pane eventKey="consultantChatHistory">
                <ConsultantChatHistory />
              </Tab.Pane>
              <Tab.Pane eventKey="consultantreview">
                <ConsultantReview />
              </Tab.Pane>
              <Tab.Pane eventKey="ProfPayment">
                <ProfPayment />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>

      <Modal show={show} onHide={handleClose} animation={false}>
        <Modal.Header className="modal-header all_background" closeButton>
          <Modal.Title>{t("CON.MDL.STS.HEAD")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {statusList
              .filter((i) => i.id == getDetails.id)
              .map((i) => (
                <p key={i.id} value={i.id}>
                  {t("CON.MDL.CURNT.STS")}:
                  {lang === "en"
                    ? i.status
                    : lang === "ta"
                    ? i.status_ta
                    : i.status_hi}
                </p>
              ))}
            <p>
              {t("CON.MDL.SELCT.STS")}:{" "}
              {lang === "en"
                ? selectedStatus.status
                : lang === "ta"
                ? selectedStatus.status_ta
                : selectedStatus.status_hi}
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="all_btn"
            onClick={() => {
              setStatus(selectedStatus.id);
              updateDetail();
              handleClose();
            }}
          >
            {t("CON.MDL.CNF.BTN")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={chatAlert} onHide={handleChatClose} animation={false}>
        <Modal.Header className="modal-header all_background" closeButton>
          <Modal.Title className="w-100 text-center">
            {t("USR.CHAT.ALT.MDL.HEAD")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {chatDatas ? (
            <Row style={{ alignItems: "center" }}>
              <Col xs={0} sm={0} md={2}></Col>
              <Col xs={6} sm={6} md={4}>
                <Card.Text>
                  <b>
                    <h4>{t("USR.CHAT.CUSTOMER")}</h4>
                  </b>
                </Card.Text>
                <Card.Text>{t("USR.METNG.CDE")}</Card.Text>
                <Card.Text>{t("TXT.STS.LO")}</Card.Text>
                <Card.Text>{t("USR.INTI.TIME")}</Card.Text>
                <Card.Text>{t("USR.PROPO.DUR")}</Card.Text>
              </Col>

              <Col xs={6} sm={6} md={4}>
                {" "}
                <Card.Text>
                  <b>
                    <h4>
                      {" "}
                      <b>:</b>&nbsp;&nbsp;{chatDatas.customerName}
                    </h4>
                  </b>
                </Card.Text>
                <Card.Text>
                  {" "}
                  <b>:</b>&nbsp;&nbsp;{chatDatas.meetingCode}
                </Card.Text>
                <Card.Text>
                  {" "}
                  <b>:</b>&nbsp;&nbsp;{chatDatas.status}
                </Card.Text>
                <Card.Text>
                  {" "}
                  <b>:</b>&nbsp;&nbsp;{chatDatas.chatStartTime}
                </Card.Text>
                <Card.Text>
                  {" "}
                  <b>:</b>&nbsp;&nbsp;{chatDatas.proposedChatDuration}
                </Card.Text>
              </Col>
              <Col xs={0} sm={0} md={2}></Col>
            </Row>
          ) : (
            <Card.Text>{t("USR.NO.DATA")}</Card.Text>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button className="all_btn" onClick={() => navigate("/chatscreen")}>
            {/* {t("CON.MDL.CNF.BTN")} */}
            {t("USR.CNF.CHAT.BTN")}
          </Button>
        </Modal.Footer>
      </Modal>
    </ProfessionalProvider>
  );
};

export default ProfessionalTabs;
