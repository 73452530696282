import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Button, Form, Image } from "react-bootstrap";
import { vasthuUrl } from "./Api";
import Toast from "./Toast";
import { useNavigate } from "react-router-dom";

function FeedBack({ details, params,onClose  }) {
  console.log("details", details);
  const conid = details.chatToId;
  console.log("conid", conid);
  const name = details;
  const conUuid = details.conUuid;
  const consulName = details.conName;
  console.log("consulName", consulName);
  const Id = details.uuid || name.call_from_id || conUuid || conid;
  console.log("name", name.callFrom);
  const token = window.sessionStorage.getItem("token");
  const user = window.sessionStorage.getItem("userId");
  const crmSelectedUser = window.sessionStorage.getItem("crmSelectedUser");
  const role = window.sessionStorage.getItem("role");
  const { t } = useTranslation();
  const [showReviewModal, setShowReviewModal] = useState(params);
  const [rating, setRating] = useState(3);
  const [reviewDesc, setReviewDesc] = useState("");
  const userId = token && role == "ROLE_CRM" ? crmSelectedUser : user;
  const navigate = useNavigate();
  const [isImage, setIsImage] = useState(false);

  // ALERT MODAL
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [toastTrigger, setToastTrigger] = useState(0);

  const alert = (msg) => {
    setMessage(msg);
    setToastTrigger((prev) => prev + 1);
  };

  const ReviewForProfessional = async () => {
    try {
      if (rating == 0) {
        return alert(
          `Please Rate  ${
            details.name
              ? details.name
              : name.callFrom
              ? name.callFrom
              : consulName
          }`
        );
      }

      const res = await vasthuUrl.post(
        "saveconsultantreview",
        {
          user_uuid: userId,
          // consultant_uuid: details.uuid ? details.uuid : name.call_from_id ? name.call_from_id : Id,
          consultant_uuid: Id,
          user_rating: rating,
          review_description: reviewDesc,
          slot_id: details.slotId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(res.data);
      // alert(res.data.message);
      alert(t("USR.REV.SUC.ALT"));
      setMessageType("success");
      setReviewDesc("");
      setRating(0);
      setTimeout(() => {
        setShowReviewModal(false);
        // window.location.reload();
        navigate("/");
      }, 2000);
    } catch (error) {
      alert("SERVER ERROR");
      setMessageType("error");
    }
  };

  return (
    <Modal
      show={showReviewModal}
      onHide={() => {
        setShowReviewModal(false);
        setReviewDesc("");
        setRating(3);
        onClose();
      }}
      size="lg"
      keyboard={false}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="all_background" closeButton>
        <Toast msg={message} type={messageType} trigger={toastTrigger} />
        <Modal.Title id="contained-modal-title-vcenter">
          <Image
            fluid
            thumbnail
            roundedCircle
            style={{ width: 70, height: 70, marginRight: "20px" }}
            src="https://source.unsplash.com/user/c_v_r/100x100"
            alt="profile"
          />
          {details.name
            ? details.name
            : name.callFrom
            ? name.callFrom
            : consulName}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <h6>{t("USR.BOK.HIS.RAT")}</h6>

          <div
            className="star-rating"
            style={{ display: "flex", justifyContent: "center" }}
          >
            {[...Array(5)].map((star, index) => {
              index += 1;
              return (
                <button
                  type="button"
                  style={{
                    backgroundColor: "transparent",
                    outline: "none",
                    cursor: "pointer",
                    border: "none",
                    position: "relative",
                  }}
                  key={index}
                  className={index <= rating ? "on" : "off"}
                  onClick={() => {
                    console.log("STAR RATING", index);
                    setRating(index);
                  }}
                >
                  <span
                    className="star"
                    style={{ fontSize: "30px", textAlign: "center" }}
                  >
                    &#9733;
                  </span>
                </button>
              );
            })}
          </div>

          <Form.Group controlId="exampleForm.ControlTextarea1">
            <h6>
              {/* {details.name} &nbsp; */}
              {/* <b>
                {details.name
                  ? details.name
                  : name.callFrom
                  ? name.callFrom
                  : consulName}
                &nbsp;
              </b> */}
              {t("USR.BOK.HIS.RAT.CON")}
            </h6>
            <Form.Control
              as="textarea"
              className="all_border"
              rows={5}
              size="sm"
              maxLength={250}
              onChange={(e) => {
                setReviewDesc(e.target.value);
              }}
              placeholder={t("USR.BOK.HIS.RAT.REV")}
              value={reviewDesc}
            />
          </Form.Group>
        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-evenly all_border review_btn">
        <Button
          className="all_btn"
          onClick={() => {
            setShowReviewModal(false);
            setReviewDesc("");
            setRating(3);
            onClose();
          }}
        >
          {t("TXT.CLO.LO")}
        </Button>
        <Button className="all_btn" onClick={ReviewForProfessional}>
          {t("USR.HOM.BTN.SUB")}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default FeedBack;
