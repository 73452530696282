import React, { useState } from "react";
import TableDeskPay from "../component/DeskPayTable";
import MobileCardPay from "../component/MobilePayCard";
import { useTranslation, Trans } from "react-i18next";

const ProfPayment = () => {
  const { t } = useTranslation();

  const [profPay, setProfPay] = useState([
    {
      tid: "8yujh8uju8",
      name: "Awwsoft",
      amount: "2000",
      ptype: "UPI",
    },
    {
      tid: "8yujh825435",
      name: "Canada",
      amount: "1000",
      ptype: "CARD",
    },
    {
      tid: "8yujh1234",
      name: "INDIA",
      amount: "3000",
      ptype: "BANK",
    },
  ]);

  return (
    <React.Fragment>
      {/* <center>
        <h4>
          <u>{t("PR.AC.PMTTAB.STL")}</u>
        </h4>
      </center> */}
      <TableDeskPay data={profPay} />
      <MobileCardPay data={profPay} />
    </React.Fragment>
  );
};

export default ProfPayment;
