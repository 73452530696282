import * as React from "react";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import { RiCustomerService2Fill } from "react-icons/ri";
import { IoCall } from "react-icons/io5";
import { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default function BasicSpeedDial() {
  const { t } = useTranslation();
  const actions = [
    // {
    //   icon: (
    //     <a
    //       style={{ textDecoration: "none", color: "black" }}
    //       href="tel:CM.FT.COL5.CNT3"
    //     >
    //       <RiCustomerService2Fill />
    //     </a>
    //   ),
    //   name: "CM.FT.COL5.CNT3",
    // },
    {
      icon: (
        <a
          style={{ textDecoration: "none", color: "black" }}
          href={`tel:${t("CM.FT.COL5.CNT3")}`}
        >
          <RiCustomerService2Fill />
        </a>
      ),
      name: t("CM.FT.COL5.CNT3"),
    },
    {
      icon: (
        <a
          style={{ textDecoration: "none", color: "black" }}
          href={`tel:${t("CM.FT.COL5.CNT4")}`}
        >
          <RiCustomerService2Fill />
        </a>
      ),
      name: t("CM.FT.COL5.CNT4"),
    },
  ];

  const renderTooltip = (props) => (
    <Tooltip className="call_help" {...props}>
      Call To Help!
    </Tooltip>
  );

  return (
    <div className="speeddial">
      <Box
        sx={{
          height: 80,
          transform: "translateZ(0px)",
          flexGrow: 1,
          marginLeft: "10px",
        }}
      >
        <OverlayTrigger
          placement="left"
          delay={{ show: 10, hide: 10 }}
          overlay={renderTooltip}
        >
          <SpeedDial
            className="speed_dial"
            ariaLabel="SpeedDial basic example"
            sx={{ position: "absolute", bottom: 14, right: 14 }}
            icon={<IoCall />}
          >
            {actions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                className="all_btn"
              />
            ))}
          </SpeedDial>
        </OverlayTrigger>
      </Box>
    </div>
  );
}
