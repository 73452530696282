import axios from "axios";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  Spinner,
  Table
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { vasthuUrl } from "../component/Api";

const VasthuParamUserRefund = () => {
  const token = window.sessionStorage.getItem("token");
  const userId = window.sessionStorage.getItem("userId");
  const orderId = window.sessionStorage.getItem("orderId");
  const lang = localStorage.getItem("i18nextLng");
  const [transactionDetails, setTransactionDetails] = useState([]);
  const { t } = useTranslation();
  const [paymentOrderDetails, setPaymentOrderDetails] = useState({});
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [showRefundIniatedModal, setShowRefundInitatedModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState("");
  const [transresp, setTransrep] = useState(" ");
  const originalDate = new Date();
  const formattedDate = format(originalDate, "MMM d, yyyy HH:mm");

  //refund alert box
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const Navigate = useNavigate();
  const { state } = useLocation();
  // const orderId = location.state.orderId

  const [userorder, SetUserOrder] = useState({});

  const [selectedOption, setSelectedOption] = useState("");

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleViewDetails = (obj) => {
    setPaymentOrderDetails({
      offer: obj.offerName,
      bankName: obj.bankName,
      bankTxnId: obj.bankTxnId,
      txnAmount: obj.txnAmount,
      refundAmount: obj.refundAmt,
      txnId: obj.txnId,
      orderId: obj.orderId,
    });
    setShowReviewModal(true);
  };

  const handleViewDetailsclose = () => setShowReviewModal(false);
  const handleGetRefundDetails = () => {
    setShowRefundInitatedModal(true);
  };

  //reson new
  const [reasonList, setReasonList] = useState([]);
  const [selectedValue, setSelectedValue] = useState("");

  useEffect(() => {
    // Fetch data from your database API
    axios
      .get(`/refund/getuserreasonsforrefund`)
      .then((response) => {
        // Assuming your API returns an array of objects
        setReasonList(response.reasonList);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []); // Empty dependency array to fetch data once when the component mounts

  const handleSelectChange = (e) => {
    setSelectedValue(e.target.value);
  };

  ///for comment box
  const [comment, setComment] = useState("");

  //for reason
  // const [reasonList, setReasonList] = useState([]);

  console.log("selectedValue", selectedValue);
  console.log("reasonList", reasonList);

  const handleInputChange = (event) => {
    setComment(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Perform any necessary actions with the comment data, such as sending it to a server or updating state in a parent component
    console.log(comment);
    setComment("");
    // setReasonList('');
    alert("hello ");
  };
  useEffect(() => {
    try {
      vasthuUrl
        .get(`vasthu/getUserTransactionHistory/${userId}`, {
          // .get(`vasthu/getUserTransactionHistoryRefund/${orderId}`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res.data);

          setTransactionDetails(res.data);
          console.log("transcation details", res.data);
        });
    } catch (err) {
      console.log(err);
    }
    try {
      vasthuUrl
        .get(`vasthu/refund/getUserIniatedConfrmation/${orderId}`, {
          // .get(`vasthu/getUserTransactionHistoryRefund/${orderId}`,{
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res.data);

          setTransactionDetails(res.data);
          console.log("transcation details", res.data);
        });
    } catch (err) {
      console.log(err);
    }

    try {
      vasthuUrl
        .get(`refund/getuserreasonsforrefund`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("reason", res.data);

          setReasonList(res.data);
          console.log("reason Details", res.data);
        });
    } catch (err) {
      setReasonList([]);
      alert("can't get Reason");
      console.log(err);
    }

    console.log(reasonList.userReason);

    //     .get(`vasthu/getlagnas`, {
    //         headers: {
    //             Authorization: `Bearer ${token}`,
    //         },
    //     })
    // .then((lagna) => {
    //     console.log("lagna context", lagna, token);
    //     setLagnaList(lagna.data);
    // })
    // .catch((err) => {
    //     setLagnaList([]);
    //     alert("can't get lagna");
    // });
  }, []);
  console.log("SubmitRefund", SubmitRefund);
  console.log("handleGetRefundDetails", handleGetRefundDetails);
  console.log(("comment", comment));
  const SubmitRefund = async () => {
    setLoading(true);
    try {
      const res = await vasthuUrl.post(
        `/refund/userRefundInitiation`,
        {
          txnId: paymentOrderDetails.txnId,
          userReason: selectedValue,
          comments: comment,
          orderId: paymentOrderDetails.orderId,
          lng :lang
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // alert("Initated Successfully");
      console.log("save", res.data, paymentOrderDetails, {});
      handleViewDetailsclose();
    } catch (e) {
      alert("SERVER ERROR");
      setLoading(false);
    }
  };
  return (
    <div>
      <div>
        <Modal
          show={showReviewModal}
          onHide={handleViewDetailsclose}
          size="lg"
          keyboard={false}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="v_modal"
        >
          <Modal.Header closeButton className="all_background">
            <Modal.Title>{t("USR.AC.INP.REF")}</Modal.Title>
          </Modal.Header>

          {loading ? (
            <center>
              <Spinner animation="border" variant="warning" />
            </center>
          ) : (
            <Modal.Body>
              <p>
                <b>{t("USR.PY.HS.MOD.ROW2")} : </b>
                {paymentOrderDetails.bankName}
              </p>

              <p>
                <b>{t("USR.PY.HS.TB.COL2")} : </b>
                {paymentOrderDetails.txnAmount}
              </p>

              <p>
                <b>{t("USR.AC.INP.REF.ID")} : </b>
                {paymentOrderDetails.orderId}
              </p>

              <div>
                {/* <label htmlFor="dropdown">Drop Your Issue :</label><p></p>
                                <select id="dropdown" value={selectedOption} onChange={handleOptionChange}>
                                    <option value="">-- Choose a refund request   --</option>
                                    <option value="option1">Money paid but not attended call</option>
                                    <option value="option2">Call terminated and offer got utilized</option>
                                    <option value="option3">Professional did not attend the call and offer used</option>
                                </select> */}
                {/* {selectedOption && <p>You selected: {selectedOption}</p>}   the option which we selected */}
              </div>
              <div
                className="d-flex"
                style={{ marginBottom: "10px", width: "100%" }}
              >
                <p>
                  <b>{t("USR.AC.INP.REF.RSN")} : </b>
                </p>
                <select
                  value={selectedValue}
                  onChange={handleSelectChange}
                  className="form_feild"
                  style={{ outline: "none", borderRadius: "5px" }}
                >
                  <option value="">{t("USR.AC.INP.REF.SLOP")}</option>
                  {reasonList.map((item, k) => (
                    <option key={k} value={item.user_Reasons}>
                      {/* {item.user_Reasons} */}
                      {lang === "ta"
                        ? item.user_Reasons_ta
                        : lang === "hi"
                        ? item.user_Reasons_hi
                        : item.user_Reasons}
                      {/* Replace with the property from your data */}
                    </option>
                  ))}
                </select>
              </div>
              <form onSubmit={handleSubmit} className="d-flex">
                <p>
                  <b>{t("USR.AC.INP.REF.CMT")} : </b>
                </p>
                <textarea
                  value={comment}
                  size="lg"
                  onChange={handleInputChange}
                  placeholder={t("USR.AC.INP.REF.CMT.PH")}
                  className="form_feild"
                  style={{ outline: "none", borderRadius: "5px" }}
                />
              </form>
            </Modal.Body>
          )}
          <Modal.Footer className="all_border d-flex justify-content-evenly">
            <Button
              className="all_btn"
              onClick={() => setShowReviewModal(false)}
            >
              {t("TXT.CLO.LO")}
            </Button>
            <Button
              className="all_btn"
              onClick={() => {
                // SubmitRefund();
                handleGetRefundDetails();
                // Navigate(-1)
                handleShow();
                setShowReviewModal(false);
                // alert('Do you want to initiate');
              }}
            >
              {t("USR.AC.INP.REF.BTN")}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>

      <Modal show={show} onHide={handleClose} className="v_modal">
        <Modal.Header closeButton className="all_background">
          <Modal.Title>{t("USR.AC.INP.REF")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{t("USR.AC.INP.REF.CNF")}</Modal.Body>
        <Modal.Footer className="all_border d-flex justify-content-evenly">
          <Button className="all_btn" onClick={handleClose}>
            {t("USR.VSH.HFTAB.MDL.CNF.BTN.CNC")}
          </Button>
          <Button
            className="all_btn"
            onClick={() => {
              SubmitRefund(); // Navigate(-1)
              Navigate(-1);
              handleClose();
            }}
          >
            {t("USR.VSH.HFTAB.MDL.CNF.BTN.CNF")}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* modal to show the refund initated */}
      {/* <div>
                <Modal


                    size="lg"
                    keyboard={false}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <ModalHeader> initated Refund </ModalHeader>
                    <ModalBody>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            variant="outline-warning"
                            style={{
                                color: "black",
                                fontWeight: "bold",
                            }}
                            onClick={() => null}
                        >
                            {t("TXT.CLO.LO")}
                        </Button>

                        <Button>

                        </Button>
                    </ModalFooter>
                </Modal>

            </div> */}
      {transactionDetails.filter((it) => it.orderId == state.order).length >
      0 ? (
        <Table
          striped
          bordered
          hover
          responsive
          // className="deskTable border border-warning"
          className="deskTable1 border border-warning"
        >
          <thead>
            <tr>
              <th>{t("TXT.OFF.NM.LO")}</th>
              <th>{t("USR.PY.HS.TB.COL2")}</th>
              <th>{t("USR.PY.HS.TB.COL3")}</th>
              <th>{t("USR.PY.HS.TB.COL4")}</th>
              <th>{t("USR.AC.INP.REF.OF.DET")}</th>
            </tr>
          </thead>
          <tbody>
            {// transactionDetails.filter(obj => (obj.orderId == selectedOrderId)).map((i, j) => (
            transactionDetails
              .filter((it) => it.orderId == state.order)
              .map((i, j) => (
                <tr key={j}>
                  <td>{i.offerName}</td>
                  <td>{i.txnAmount}</td>
                  <td>{format(new Date(i.txnDate), "d MMM yyyy, HH:mm")}</td>
                  <td>{i.orderId}</td>
                  <td
                    className="text-center d-flex"
                    style={{ flexDirection: "column" }}
                  >
                    <Button
                      onClick={() => handleViewDetails(i)}
                      style={{
                        borderRadius: 50,
                      }}
                      className="mt-2 all_btn"
                    >
                      {t("BTN.VIEW.DET.UP")}
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      ) : (
        <center>
          <h3>{t("USR.PY.HS.NIL.DAT")}</h3>
        </center>
      )}
    </div>
  );
};
export default VasthuParamUserRefund;
