import React, { useEffect, useState } from "react";
import { Container, Table, Spinner, Button } from "react-bootstrap";
import { vasthuUrl } from "../component/Api";
import { UseAuth } from "../context/Auth";
import { FcCheckmark, FcCancel } from "react-icons/fc";
import { UseVasthu } from "../context/vasthu";
import PlotTable from "../component/plotTable";
import PlotMobileCard from "../component/plotMobileCard";
import { Navigate, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PlotScreenFree = () => {
  // const { token } = UseAuth();
  const token = window.sessionStorage.getItem("token");
  const langCode = window.localStorage.getItem("i18nextLng");
  const { plotRec } = UseVasthu();
  const [plotObj, setPlotObj] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [offerId, setOfferId] = useState();
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => console.log("Plotvalidate inputs ", location.state), []);

  return (
    <Container>
      <center>
        <h4 className="m-2">
          <u>{t("USR.PLT.VAL.PLT.DET.TL")}</u>
        </h4>
      </center>
      {!loading ? (
        <>
          <PlotTable data={location.state} />
          <PlotMobileCard data={location.state} />
        </>
      ) : (
        <div className="text-center">
          {" "}
          <Spinner animation="border" variant="danger" />{" "}
        </div>
      )}
    </Container>
  );
};

export default PlotScreenFree;
