import React, { useEffect, useState } from "react";
import { Button, Card, Form, Modal } from "react-bootstrap";
import { Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { vasthuUrl } from "../../component/Api";
import { useTranslation } from "react-i18next";
import Toast from "../../component/Toast";
import validator from "validator";

function MinutesMaster() {
  const token = window.sessionStorage.getItem("token");
  const { t } = useTranslation();
  const [reLoad, setReLoad] = useState(false);
  const [minList, setMinList] = useState([]);
  const [saveData, setSaveData] = useState([]);
  const [newMinute, setNewMinute] = useState([]);
  // console.log("saveData", saveData);
  console.log("newMinute", newMinute);

  useEffect(() => {
    vasthuUrl.get("offer/getAllCallMinutesOffer").then((res) => {
      // console.log("available times", res.data);
      setReLoad(false);
      setMinList(res.data);
    });
  }, [reLoad]);

  // ALERT MODAL
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [toastTrigger, setToastTrigger] = useState(0);

  const alert = (msg) => {
    setMessage(msg);
    setToastTrigger((prev) => prev + 1);
  };

  // Create or Update Minute MODAL
  const [model, setModel] = useState(false);
  const ModalOpen = () => {
    setModel(true);
  };

  const ModalClose = () => {
    setModel(false);
    setNewMinute();
    setSaveData("");
  };

  const handleShow = (i) => {
    // console.log("i", i);
    ModalOpen(i);
    setSaveData(i);
  };

  const handleCreateMinute = async () => {
    if (!validator.isEmpty(newMinute)) {
      const res = await vasthuUrl
        .post(
          `offer/createMinutesOffer`,
          {
            offerCallMins: newMinute,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          // console.log("after save", res.data);
          alert(`Successfully Created ${newMinute} Minute ...!`);
          setMessageType("success");
          ModalClose();
          setReLoad(true);
        });
    } else {
      alert("Please Fill Enter Minute");
      setMessageType("error");
    }
  };

  const handleUpdateMinute = async () => {
    const minuteId = saveData.id;
    // if (!validator.isEmpty(saveData.id)) {
    const res = await vasthuUrl
      .put(
        `offer/updateMinutes/${minuteId}`,
        {
          // offerCallMins: parseInt(saveData.offerCallMins, 10),
          offerCallMins: saveData.offerCallMins,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        // console.log("after update", res.data);
        alert(`Successfully Update Id ${minuteId} to ${newMinute} Minute...!`);
        setMessageType("success");
        ModalClose();
        setReLoad(true);
      });
    // } else {
    //   alert("Error");
    //   setMessageType("error");
    //   ModalClose();
    // }
  };

  const handleUpdateStatus = async (id, status) => {
    try {
      const res = await vasthuUrl.post(
        `offer/updateMinsOfferStatus`,
        {
          id: id,
          status: status,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      // console.log(`Updating status of ${id} to ${status}`);
      alert(`Updating status of ${id} to ${status}`);
      setMessageType("success");
      setReLoad(true);
    } catch (error) {
      alert("Failed to Update");
      setMessageType("error");
    }
  };

  const columns = [
    { field: "id", headerName: "S.NO", width: 70 },
    { field: "offerCallMins", headerName: "MINUTE", width: 100 },
    {
      field: "actions",
      headerName: "ACTION",
      renderCell: (params) => (
        <div
          style={{ display: "flex", justifyContent: "center", gap: "0.5rem" }}
        >
          <Button
            className="all_btn"
            onClick={() => handleShow(params.row)}
            disabled={params.row.status === "INACTIVE"}
          >
            Update
          </Button>

          {params.row.status === "INACTIVE" ? (
            <Button
              className="mx-1 all_btn"
              onClick={() => handleUpdateStatus(params.row.id, "ACTIVE")}
            >
              Activate
            </Button>
          ) : (
            <Button
              className="mx-1 all_btn"
              onClick={() => handleUpdateStatus(params.row.id, "INACTIVE")}
            >
              Deactivate
            </Button>
          )}
        </div>
      ),
      width: 250,
    },
  ];

  return (
    <div>
      <Toast msg={message} type={messageType} trigger={toastTrigger} />

      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>
          <Button
            className="my-2 all_btn"
            style={{ float: "right" }}
            onClick={ModalOpen}
          >
            Add Minute
          </Button>
        </div>
        <center>
          <h2>Minute Master List</h2>
        </center>
        {minList.length > 0 ? (
          <>
            <center className="datagrid mt-2">
              <Grid container className="deskTable">
                <DataGrid
                  className="all_border text-center me-3 data_grid"
                  rows={minList}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 10 },
                    },
                  }}
                  pageSizeOptions={[5, 10]}
                  getRowId={(row) => row.id}
                />
              </Grid>
            </center>
            <div className="mobileCard">
              {minList.map((i, j) => (
                <Card
                  className="mobileCard all_border"
                  key={j}
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    backgroundColor: "antiquewhite",
                  }}
                >
                  <Card.Body>
                    <Card.Subtitle className="mb-2">
                      <b>S.NO:</b> {i.id}
                    </Card.Subtitle>

                    <Card.Subtitle className="mb-2">
                      <b>OFFER NAME:</b> {i.offerCallMins}
                    </Card.Subtitle>

                    <Card.Subtitle className="mb-2">
                      <b>STATUS:</b> {i.status}
                    </Card.Subtitle>
                  </Card.Body>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      gap: "0.5rem",
                    }}
                  >
                    <Button
                      className="all_btn"
                      onClick={() => handleShow(i)}
                      disabled={i.status === "INACTIVE"}
                    >
                      Update
                    </Button>

                    {i.status === "INACTIVE" ? (
                      <Button
                        className="mx-1 all_btn"
                        onClick={() => handleUpdateStatus(i.id, "ACTIVE")}
                      >
                        Activate
                      </Button>
                    ) : (
                      <Button
                        className="mx-1 all_btn"
                        onClick={() => handleUpdateStatus(i.id, "INACTIVE")}
                      >
                        Deactivate
                      </Button>
                    )}
                  </div>
                </Card>
              ))}
            </div>
          </>
        ) : (
          <center>
            <h5>{t("TXT.NO.REC.FUD.LO")}</h5>
          </center>
        )}
      </div>

      <Modal
        show={model}
        onHide={ModalClose}
        size="lg"
        centered
        fullscreen={true}
      >
        <Card className="p-2">
          <Card.Header className="all_background">
            <center>
              <b>Add Minute</b>
            </center>
          </Card.Header>
          <Card.Body>
            <Form>
              <Form.Group className="mb-3" controlId="walletOffername">
                <Form.Label style={{ fontSize: 20, fontWeight: "bold" }}>
                  Enter Minute
                </Form.Label>
                <Form.Control
                  type="number"
                  className="form_feild"
                  placeholder="Enter minute"
                  value={saveData ? saveData.offerCallMins : newMinute}
                  onChange={(e) => {
                    // const minuteValue = parseInt(e.target.value, 10);
                    setSaveData({ ...saveData, offerCallMins: e.target.value });
                    setNewMinute(e.target.value);
                  }}
                />
              </Form.Group>
            </Form>
          </Card.Body>
        </Card>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button className="all_btn" onClick={ModalClose}>
            Close
          </Button>
          {saveData && saveData.id ? (
            <Button className="all_btn" onClick={() => handleUpdateMinute()}>
              Update
            </Button>
          ) : (
            <Button className="all_btn" onClick={() => handleCreateMinute()}>
              Add Minute
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default MinutesMaster;
