import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import HomeForm from "../pages/CustomerHomeForm";
import PlotForm from "../pages/CustomerPlotForm";
import ProtectedRoute from "../component/ProtectedRoute";
import Home from "../pages/Home";

import Login from "../pages/Sigin";
import Register from "../pages/Signup";
import UserProtectedRoute from "../component/UserProtectedRoute";
import Aboutus from "../pages/Aboutus";
import VasthuParamReview from "../pages/VasthuParamReview";
import Disclaimer from "../pages/Disclaimer";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import TermConditions from "../pages/TermsConditions";
import CopyRights from "../pages/CopyRights";
import AstroContent from "../pages/AstroContent";
import VasthuContent from "../pages/VasthuContent";
import VasthuArticle from "../pages/VasthuArticle";
import VasthuBooks from "../pages/VasthuBooks";
import VasthuAstrolgers from "../pages/VasthuAstrolgers";
import VasthuExperts from "../pages/VasthuExperts";
import VasthuArchitects from "../pages/VasthuArchitects";
import Header from "../component/Header";
import ErrorPage from "../ErrorPage";
import VasthuArticalTamil from "../pages/VasthuArticalTamil";
import SocialHome from "../pages/SocialHome";
import SocialOffer from "../pages/SocialOffer";
import Logins from "../pages/Signins";
import WalletBooking from "../pages/WalletBooking";
import ScheduleBooking from "../pages/ScheduleBooking";
import OurCallServices from "../pages/OurCallServices";
import DirectCallHome from "../pages/DirectCallHome";
import TalkNow from "../pages/TalkNow";
import UserProduct from "../pages/User/UserProduct";
import AudioCallSender from "../videocall/sender/AudioCallSender";
import AudioCallReceiver from "../videocall/receiver/AudioCallReceiver";
import ChatSender from "../videocall/sender/ChatSender";
import ChatReceiver from "../videocall/receiver/ChatReceiver";
import VideoCallSender from "../videocall/sender/VideoCallSender";
import VideoCallReceiver from "../videocall/receiver/VideoCallReceiver";
import ChatRoom from "../pages/ChatRoom";
// import Otp from "../pages/Otp";

const AuthRoute = () => {
  return (
    <Routes>
      <Route exact path="/" element={<Header breakpionts="lg" />}>
        <Route index element={<Home />} />
        <Route path="chatscreen" element={<ChatRoom />} />
        <Route path="/talktoconsultant" element={<TalkNow />} />
        <Route path="/ourcallservices" element={<OurCallServices />} />
        <Route path="/book" element={<SocialHome />} />
        <Route path="/call" element={<SocialHome />} />
        <Route path="/book/:id" element={<SocialHome />} />
        <Route path="/call/:id" element={<SocialHome />} />
        <Route path="/ourcallservices" element={<OurCallServices />} />
        <Route path="/directcallhome" element={<DirectCallHome />} />
        <Route path="/schedulebooking" element={<ScheduleBooking />} />
        <Route path="/walletbooking" element={<WalletBooking />} />
        {/* <Route path="/:id" element={<SocialOffer />} /> */}
        <Route path="login" element={<Logins />} />
        <Route path="register" element={<Register />} />
        <Route path="aboutus" element={<Aboutus />} />
        <Route path="vasthureview" element={<VasthuParamReview />} />
        <Route path="disclaimer" element={<Disclaimer />} />
        <Route path="privacypolicy" element={<PrivacyPolicy />} />
        <Route path="termcondition" element={<TermConditions />} />
        <Route path="copyrights" element={<CopyRights />} />
        <Route path="astrocontent" element={<AstroContent />} />
        <Route path="vasthucontent" element={<VasthuContent />} />
        <Route path="vasthuarticle" element={<VasthuArticle />} />
        <Route path="vasthuarticletamil" element={<VasthuArticalTamil />} />
        <Route path="vasthubooks" element={<VasthuBooks />} />
        {/* <Route path="vasthuastrloger" element={<VasthuAstrologer />} />
        <Route path="vasthuarchitects" element={<VasthuArchitects />} />
        <Route path="vasthuexperts" element={<VasthuExperts />} /> */}
        <Route path="vasthuexpert" element={<VasthuExperts />} />
        <Route path="vasthuarchitects" element={<VasthuArchitects />} />
        <Route path="vasthuastrolgers" element={<VasthuAstrolgers />} />
        <Route path="product" element={<UserProduct />} />
        {/* <Route path="startcall" element={<VideoCallSender />} />
        <Route path="joincall" element={<VideoCallReceiver />} />
        <Route path="startaudiocall" element={<AudioCallSender />} />
        <Route path="joinaudiocall" element={<AudioCallReceiver />} />
        <Route path="startchat" element={<ChatSender />} />
        <Route path="joinchat" element={<ChatReceiver />} /> */}
        {/* <Route path="sentOtp" element={<Otp />} /> */}
        <Route
          path="*"
          element={
            <UserProtectedRoute>
              <Login />
            </UserProtectedRoute>
          }
        />
      </Route>
      <Route path="errorpage" element={<ErrorPage />} />
    </Routes>
  );
};

export default AuthRoute;
