import React from "react";
import { Button, Card, ListGroup } from "react-bootstrap";
import PropsType from "prop-types";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const PlotMobileCard = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    // <div className="justify-content-center">
    <div className="mobileCard">
      <Card className="mobileCard" style={{ width: "100%" }}>
        {/* <Card.Title className='text-center p-1'>{t("USR.VSH.PLT.TTL")}</Card.Title> */}
        <Card.Title className='text-center p-1'></Card.Title>
        <Card.Body>
          <ListGroup>
            <ListGroup.Item>{t("USR.VFPR.TBL.CLMN.OWN")} :{props.data["ownerName"]}</ListGroup.Item>
            <ListGroup.Item>{t("USR.VFP.DRP.LBL.LG")} :{props.data["lagnaName"]}</ListGroup.Item>
            <ListGroup.Item>{t("USR.VFPR.TBL.CLMN.AR")} :{props.data["plotArea"]}</ListGroup.Item>
            <ListGroup.Item>
              {t("USR.BD.PLTAB.TBL.CLM.DR")} :{props.data["directionName"]}
            </ListGroup.Item>
            <ListGroup.Item>
              {t("USR.VFPR.TBL.CLMN.RS")}{""}:{props.data["plotResultValue"]}
            </ListGroup.Item>
          </ListGroup>
        </Card.Body>
      </Card>
      <div>
        <center>
          <Button
            className="all_btn mt-3 w-100"
            style={{ borderRadius: 50 ,  }}
            onClick={() => navigate("/")}
          >
            {t("BTN.BACK.TO.HOME.LO")}
          </Button>
        </center>
      </div>
    </div>
  );
};
PlotMobileCard.propsType = {
  data: PropsType.object.isRequired,
};
export default PlotMobileCard;
