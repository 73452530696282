import React from "react";
const Profile = () => {
  return (
    <div>
      <text>profile page</text>
    </div>
  );
};
export default Profile;

// import React from "react";
// import {
//   MainDoorValidation,
//   ElementValidation,
//   WindowValidation,
//   LevelingValidation,
// } from "../component/MainDoor";
// const Profile = () => {
//   return (
//     <div>
//       <MainDoorValidation />
//       <ElementValidation />
//       <WindowValidation />
//       <LevelingValidation />
//     </div>
//   );
// };
// export default Profile;
