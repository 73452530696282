import React, { useEffect, useState } from "react";
import {
  Card,
  Spinner,
  Alert,
  ProgressBar,
  ListGroup,
  Badge,
  Image,
  Container,
} from "react-bootstrap";
import { vasthuUrl } from "../../component/Api";
import { useTranslation } from "react-i18next";

function ConsultantReview() {
  const { t } = useTranslation();
  const token = window.sessionStorage.getItem("token");
  const uuid = window.sessionStorage.getItem("userId");
  const [loading, setLoading] = useState(false);
  const [conName, setConName] = useState();
  const [conImgUrl, setConImgUrl] = useState();
  const [consultantStats, setConsultantStats] = useState({});
  const [userReview, setUserReview] = useState([]);
  const [fiveStars, setFiveStars] = useState(0);
  const [fourStars, setFourStars] = useState(0);
  const [threeStars, setThreeStars] = useState(0);
  const [twoStars, setTwoStars] = useState(0);
  const [oneStar, setOneStar] = useState(0);

  useEffect(() => {
    setLoading(true);
    try {
      vasthuUrl
        .get(`usersreviewforconsultant/${uuid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("user reviews", res.data);
          setUserReview(res.data);
        });
    } catch (error) {
      console.log("ERROE" + error);
      setLoading(false);
    }
    try {
      vasthuUrl
        .get(`usersreviewcountforconsultant/${uuid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setConsultantStats(res.data);
          console.log("user count", res.data);
          if (res.data) {
            setFiveStars(
              (res.data.rating5count / res.data.total_review_count) * 100
            );
            setFourStars(
              (res.data.rating4count / res.data.total_review_count) * 100
            );
            setThreeStars(
              (res.data.rating3count / res.data.total_review_count) * 100
            );
            setTwoStars(
              (res.data.rating2count / res.data.total_review_count) * 100
            );
            setOneStar(
              (res.data.rating1count / res.data.total_review_count) * 100
            );
          }
        });
    } catch (error) {
      console.log("ERROE" + error);
      setLoading(false);
    }
  }, []);
  return (
    <React.Fragment>
      <div>
        <center>
          <h4 className="m-1">
            <u>{t("USR.HOM.REV.TEXT4")}</u>
          </h4>
        </center>
      </div>
      {loading ? (
        <Container>
          <Card className="text-center all_border mt-1">
            {consultantStats && (
              <>
                <Card.Header className="d-flex all_background justify-content-center align-items-center ">
                  {conImgUrl ? (
                    <Image
                      fluid
                      thumbnail
                      roundedCircle
                      style={{ width: 60, height: 60, margin: 5 }}
                      src={conImgUrl}
                      alt="profile"
                    />
                  ) : (
                    <Image
                      fluid
                      thumbnail
                      roundedCircle
                      style={{ width: 60, height: 60, margin: 5 }}
                      src={require("../../images/noimage.jpg")}
                      alt="profile"
                    />
                  )}
                  <h5 className="pt-2">
                    {conName} <br />
                    <Badge bg="danger" pill>
                      {consultantStats.average_rating}/5.0
                    </Badge>{" "}
                    ({consultantStats.total_review_count})
                  </h5>
                </Card.Header>
                <Card.Body>
                  <ListGroup style={{ border: "1px solid #ffb33e" }}>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-center "
                    >
                      <h6 className="mt-2" style={{ color: "grey" }}>
                        5 - {t("PR.AC.RVWTAB.STRS.CMM")}
                      </h6>
                      <ProgressBar
                        className="d-flex"
                        style={{ width: "70%" }}
                        variant="success"
                        now={fiveStars}
                        // label={`${fiveStars}%`}
                      />
                      <h6 className="mt-2" style={{ color: "grey" }}>
                        {consultantStats.rating5count}
                      </h6>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-center "
                    >
                      <h6 className="mt-2" style={{ color: "grey" }}>
                        4 - {t("PR.AC.RVWTAB.STRS.CMM")}
                      </h6>
                      <ProgressBar
                        style={{ width: "70%" }}
                        variant="info"
                        now={fourStars}
                        // label={`${fourStars}%`}
                      />
                      <h6 className="mt-2" style={{ color: "grey" }}>
                        {consultantStats.rating4count}
                      </h6>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-center "
                    >
                      {" "}
                      <h6 className="mt-2" style={{ color: "grey" }}>
                        3 - {t("PR.AC.RVWTAB.STRS.CMM")}
                      </h6>
                      <ProgressBar
                        style={{ width: "70%" }}
                        variant="warning"
                        now={threeStars}
                        // label={`${threeStars}%`}
                      />
                      <h6 className="mt-2" style={{ color: "grey" }}>
                        {consultantStats.rating3count}
                      </h6>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-center "
                    >
                      <h6 className="mt-2" style={{ color: "grey" }}>
                        2 - {t("PR.AC.RVWTAB.STRS.CMM")}
                      </h6>
                      <ProgressBar
                        style={{ width: "70%" }}
                        variant="danger"
                        now={twoStars}
                        // label={`${twoStars}%`}
                      />
                      <h6 className="mt-2" style={{ color: "grey" }}>
                        {consultantStats.rating2count}{" "}
                      </h6>
                    </ListGroup.Item>
                    <ListGroup.Item
                      as="li"
                      className="d-flex justify-content-between align-items-center "
                    >
                      <h6 className="mt-2" style={{ color: "grey" }}>
                        1 - {t("PR.AC.RVWTAB.STR.CMM")}
                      </h6>
                      <ProgressBar
                        style={{ width: "70%" }}
                        variant="primary"
                        // label={`${oneStar}%`}
                        now={oneStar}
                      />
                      <h6 className="mt-2" style={{ color: "grey" }}>
                        {consultantStats.rating1count}
                      </h6>
                    </ListGroup.Item>
                  </ListGroup>
                </Card.Body>
              </>
            )}
          </Card>
          {userReview.length > 0 ? (
            <Card className="mt-2 all_border">
              <Card.Header className="text-center all_border" as="h3">
                {t("PR.AC.RVWTAB")}
              </Card.Header>
              {userReview.map((i, j) => (
                <Card.Body key={j}>
                  <div className="review-list">
                    <ul>
                      <li>
                        <div className="d-flex">
                          <div className="left">
                            <span>
                              <img
                                src="https://bootdey.com/img/Content/avatar/avatar1.png"
                                className="profile-pict-img img-fluid"
                                alt="userProfile"
                              />
                            </span>
                          </div>
                          <div className="right">
                            <h4>
                              {i.user.userName}
                              <span className="gig-rating text-body-2">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 1792 1792"
                                  width="15"
                                  height="15"
                                >
                                  <path
                                    fill="currentColor"
                                    d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                                  ></path>
                                </svg>
                                {i.user_rating}
                              </span>
                            </h4>
                            <div className="country d-flex align-items-center">
                              <div className="country-name font-accent">
                                {i.user.email}
                              </div>
                            </div>
                            <div className="review-description">
                              <p>{i.review_description}</p>
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Card.Body>
              ))}
            </Card>
          ) : (
            <center>
              <h5 className="mt-3">{t("PR.AC.RVWTAB.MSG.NORVW")}</h5>
            </center>
          )}
        </Container>
      ) : (
        <center>
          <Spinner animation="border" variant="warning" />
        </center>
      )}
    </React.Fragment>
  );
}

export default ConsultantReview;
