import React from "react";
import { Row, Card, Container } from "react-bootstrap";
import StarRating from "./StarRating";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";

const HomeTestimonials = () => {
  const { t } = useTranslation();

  const testimonials = [
    {
      customerName: "Mr.Kumar  Sri Pandy Restaurant Owner, Malaysia",
      profImgUrl: "https://bootdey.com/img/Content/avatar/avatar2.png",
      profImgUrl1: require("../images/kumar.jpg"),
      reviewContent: `Architect  Mr.R.Balachandar is eminent person in three field such as Architecture, Vasthu and Astrology.He has designed my office comply with Architecture and Vasthu based on my horoscope. It is unique designed and also appreciated by all in my friends’s circle as well kuala lumpur. I am feeling positive energy in inside building and Prosperous in my life as well.`,
    },
    {
      customerName: "Mr.Challa Reddy Contractor, Hyderabad, India.",
      profImgUrl: "https://bootdey.com/img/Content/avatar/avatar3.png",
      profImgUrl1: require("../images/chella.jpg"),
      reviewContent: `Ar.R.Balachandar is vast knowledge in Astrology. He has good in remedies and spiritual solution for life oriented problem and I have seen in my life and come out of hurdles through his remedies and advise. He has many clients in various sectors in my friend’s Circle in Hyderabad.`,
    },
    {
      customerName: "Mr.Dakshnamoorthy Real Estate, Chennai, India.",
      profImgUrl: "https://bootdey.com/img/Content/avatar/avatar3.png",
      profImgUrl1: require("../images/dakshnamoorthy.jpg"),
      reviewContent: `Architect Mr.R.Balachandar is the one man expert in three field such as Architecture, Astrology and Vasthu. His remedies and spiritual solutions are invaluable and extremely useful in life oriented issues and feel in my life by come out of struggles as well.`,
    },
  ];
  return (
    <Container>
      <center>
        <h1>{t("CM.CL.TSTM.TL")}</h1>
      </center>
      <Row className="rounded hometestimonials">
        <Slider
          arrows={true}
          slidesToShow={1}
          slidesToScroll={1}
          lazyLoad={true}
          dots={true}
          responsive={[
            {
              breakpoint: 1024,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: true,
                dots: true,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ]}
        >
          {testimonials.map((i, j) => (
            <div class="member-card p-1 justify-content-start">
              <div class="thumb-lg member-thumb ">
                <img
                  src={i.profImgUrl1}
                  class="rounded-circle img-thumbnail"
                  alt="profile-image"
                  style={{ height: 60, width: 60 }}
                />
                <h5>{i.customerName}</h5>
              </div>
              <div class="card-body p-2">
                <blockquote class="blockquote mb-0">
                  <p> {i.reviewContent}</p>
                  <footer class="blockquote-footer">{i.customerName} </footer>
                </blockquote>
              </div>
            </div>
          ))}
        </Slider>
      </Row>
    </Container>
  );
};
export default HomeTestimonials;
