import React, { useState } from "react";
import { useEffect } from "react";
import {
  Button,
  Container,
  FloatingLabel,
  Form,
  Modal,
  Table,
  Alert,
  Spinner,
} from "react-bootstrap";
import { vasthuUrl } from "../component/Api";
import { v4 as uuidv4 } from "uuid";
import { UseVasthu } from "../context/vasthu";
import { useNavigate } from "react-router-dom";

const HomeMiscellaneous = () => {
  const { homeRec } = UseVasthu();
  const navigate = useNavigate();
  const token = window.sessionStorage.getItem("token");
  const [showmiscForm, setshowmiscForm] = useState(false);
  const [facingList, setFacingList] = useState([]);
  const [miscList, setMiscList] = useState([]);
  const [miscDirectionId, setmiscDirectionId] = useState();
  const [miscDirectionName, setmiscDirectionName] = useState("");
  const [miscNameId, setmiscNameId] = useState();
  const [miscName, setmiscName] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [miscArray, setmiscArray] = useState([]);

  useEffect(() => {
    vasthuUrl
      .get(`vasthu/getdirections`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((direction) => {
        setFacingList(direction.data);
      });
    vasthuUrl
      .get(`vasthu/getmiscellaneous`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("miscellaneous out", res.data);
        setMiscList(res.data);
      });
  }, []);

  const handlemiscShow = () => {
    setshowmiscForm(true);
  };

  const handlemiscClose = () => {
    setmiscDirectionId("");
    setmiscNameId("");
    setmiscDirectionName("");
    setmiscName("");
    setshowmiscForm(false);
    console.log("misc OBH", miscArray);
  };

  const handlemiscSubmit = () => {
    let miscCheckArr = miscArray.map((i) => i.miscName);
    console.log(miscCheckArr, miscName, !miscCheckArr.includes(miscName));
    if (miscDirectionId && miscDirectionName != " ") {
      // if (!miscCheckArr.includes(miscName)) {
      let miscDirectionObj = facingList.find((i) => i.id == miscDirectionId);
      miscArray.push({
        miscUuid: uuidv4(),
        miscDirectionName: miscDirectionObj.name,
        miscName,
        miscellaneousLocationId: miscDirectionId,
        miscellaneousId: miscNameId,
      });
      setmiscArray([...miscArray]);
      setmiscDirectionId("");
      setmiscNameId("");
      setmiscDirectionName("");
      setmiscName("");
      setshowmiscForm(false);
      console.log("miscellaneous array", miscArray);
    }
    //  else {
    //   modalAlert("MISCELLANEOUS NAME ALREADY EXISTS");
    // }
    // }
    else {
      modalAlert("PLEASE FILL THE DETAILS");
    }
  };

  const handleElemDelete = (mUuid) => {
    let miscArr = miscArray.filter((i) => i.miscUuid !== mUuid);

    console.log("DELETED ARR", miscArr);
    setmiscArray(miscArr);
  };

  const modalAlert = (msg) => {
    setErrMsg(msg);
    setTimeout(() => {
      setErrMsg("");
    }, 3000);
  };
  const alert = (msg) => {
    setMessage(msg);
    setTimeout(() => {
      setMessage("");
    }, 3000);
  };

  const miscAllSubmit = async () => {
    setLoading(true);
    console.log({ homeRec });
    try {
      const res = await vasthuUrl.post(
        `savemiscellaneousdetails`,
        {
          homeUuid: homeRec,
          miscellaneous: miscArray,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log({
        homeUuid: homeRec,
        miscellaneous: miscArray,
      });
      alert(res.data.message);
      setmiscArray([]);
      navigate("/user/homewindow");
      setLoading(false);
    } catch (error) {
      alert("SERVER ERROR");
      setLoading(false);
    }

    console.log(
      "save details",
      JSON.stringify({
        houseUuid: homeRec,
        misc: miscArray,
      })
    );
  };

  return (
    <Container>
      <center>
        <Button variant="outline-primary" style={{ borderRadius: 50 }} onClick={handlemiscShow} className="mt-2">
          ADD MISCELLANEOUS
        </Button>
      </center>

      <Modal show={showmiscForm} onHide={handlemiscClose}>
        <Modal.Header closeButton>
          <Modal.Title>ADD MISCELLANEOUS</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {errMsg && (
              <Alert variant="info" className="text-center">
                {errMsg}
              </Alert>
            )}
            <Form.Group className="mb-1" controlId="miscellaneous name">
              <Form.Select
                aria-label="miscellaneous name"
                size="lg"
                value={miscNameId}
                onChange={(e) => {
                  if (e.target.value) {
                    let obj = miscList.find((i) => i.id == e.target.value);
                    console.log("VALUE NAME" + e.target.value);
                    setmiscNameId(e.target.value);
                    setmiscName(obj.name);
                  }
                }}
              >
                <option key={0} value="">
                  Select Miscellaneous Name
                </option>
                {miscList.map((a, b) => (
                  <option key={b} value={a.id}>
                    {a.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-1" controlId="element facing">
              <Form.Select
                aria-label="miscellaneous facing"
                size="lg"
                value={miscDirectionId}
                onChange={(e) => {
                  console.log(e.target.value);
                  if (e.target.value) {
                    let obj = facingList.find((i) => i.id == e.target.value);
                    console.log("VALUE NAME" + e.target.value);
                    setmiscDirectionId(e.target.value);
                    setmiscDirectionName(obj.name);
                  }
                }}
              >
                <option key={0} value="">
                  Select Facing Location
                </option>
                {facingList.map((a, b) => (
                  <option key={b} value={a.id}>
                    {a.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Form>
          <div className="text-center mt-3">
            <Button variant="outline-primary" style={{ borderRadius: 50 }} onClick={handlemiscSubmit}>
              SUBMIT
            </Button>
            {"  "}
            <Button variant="outline-primary" style={{ borderRadius: 50 }} onClick={handlemiscClose}>
              CANCEL
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      {message && (
        <Alert variant="info" className="text-center mt-2">
          {message}
        </Alert>
      )}
      {miscArray.length > 0 ? (
        <Table className="mt-2" striped bordered hover responsive>
          <thead>
            <tr>
              <th>Miscellaneous Name</th>
              <th>Location</th>
              <th>Action</th>
            </tr>
          </thead>

          {miscArray.map((i, j) => (
            <tbody key={j}>
              <tr key={j}>
                <td>{i.miscName}</td>
                <td>{i.miscDirectionName}</td>
                <td>
                  <Button
                    variant="outline-primary"
                    style={{ borderRadius: 50 }}
                    onClick={() => handleElemDelete(i.miscUuid)}
                    className="mt-2"
                  >
                    DELETE
                  </Button>
                </td>
              </tr>
            </tbody>
          ))}
        </Table>
      ) : (
        <div className="my-4">
          <center>
            <h4>PLEASE ADD MISCELLANEOUS</h4>
          </center>
        </div>
      )}

      {miscArray.length > 0 ? (
        <div className="my-4">
          <center>
            {!loading ? (
              <Button variant="outline-warning" style={{ borderRadius: 50 }} onClick={miscAllSubmit}>
                SUBMIT
              </Button>
            ) : (
              <Button variant="outline-warning" style={{ borderRadius: 50 }} disabled>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                Loading...
              </Button>
            )}
          </center>
        </div>
      ) : null}
    </Container>
  );
};

export default HomeMiscellaneous;
