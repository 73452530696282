import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CustomerHomeRecords from "./CustomerHomeRecords";
import CustomerPlotRecords from "./CustomerPlotRecords";
import PendingValidationRecords from "./PendingValidationRecords";

const CustomerRecord = () => {
  const [key, setKey] = useState("plotRecords");
  const {t} = useTranslation();

  return (
    <React.Fragment>
      <Tabs
        id="user-validation-report"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="justify-content-center"
      >
        <Tab eventKey="plotRecords" title={t("USR.BD.PLTAB")}>
          <center>
            <h4 className="m-1">
              <u>{t("USR.BD.PLTAB.TL")}</u>
            </h4>
          </center>
          <CustomerPlotRecords />
        </Tab>
        <Tab eventKey="homeRecords" title={t("USR.VSH.STP.1")}>
          <center>
            <h4 className="m-1">
              <u>{t("USR.BD.HMTAB.TL")}</u>
            </h4>
          </center>
          <CustomerHomeRecords />
        </Tab>
        <Tab eventKey="payLaterRecords" title={t("USR.BD.PLTAB.ACR.BTN.PY")}>
          <PendingValidationRecords />
        </Tab>
      </Tabs>
    </React.Fragment>
  );
};
export default CustomerRecord;
