import React, { useEffect, useState } from "react";
import { vasthuUrl } from "../../component/Api";
import { Alert, Button, Spinner, Form } from "react-bootstrap";
import { Table, Card } from "react-bootstrap";
import { Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Modal } from "react-bootstrap";
import { format, isToday } from 'date-fns';
import { useTranslation } from "react-i18next";


function UserRefundList(params) {
    console.log("params", params.userRefundList);
    const refundList = params.userRefundList;
    console.log("refundList", refundList);
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);


    const originalDate = new Date();
    const formattedDate = format(originalDate, 'MMM d, yyyy HH:mm');

    const columns = [
        { field: "offer_name", headerName: t("TXT.OFF.NM.UP"), width: 200 },
        { field: "user_reason", headerName: "USER REASON", width: 200 },
        { field: "bankname", headerName: "BANK", width: 110 },
        { field: "created_date", headerName: "CREATED DATE", width: 190 },
        { field: "updated_date", headerName: "UPDATED DATE", width: 190 },
        { field: "orderid", headerName: "ORDER ID", width: 200, },
        { field: "status", headerName: "STATUS", width: 180 },
    ];

    // const listtemp = [];
    // refundList.map(obj => {
    //   // console.log('first', format( new Date(obj.txnDate),"d MMM yyyy, (HH:mm)"))
    //   obj.created_date = format( new Date(obj.created_date),"d MMM yyyy, (HH:mm)")
    //   obj.updated_date = format( new Date(obj.updated_date),"d MMM yyyy, (HH:mm)")
    //   listtemp.push(obj);
    // })

    const listtemp = refundList.map(obj => {
        return {
            ...obj,
            created_date: format(new Date(obj.created_date), "d MMM yyyy, HH:mm"),
            updated_date: format(new Date(obj.updated_date), "d MMM yyyy, HH:mm")
        };
    });



    return (
        <React.Fragment>
            <center>
                <h4>
                    <u>User Refund List</u>
                </h4>
            </center>
            {listtemp.length > 0 ? (
                <>
                    <Grid container className="deskTable">
                        <DataGrid
                            className="all_border - text-center me-3 data_grid"
                            rows={listtemp}
                            columns={columns}
                            initialState={{
                                pagination: {
                                    paginationModel: { page: 0, pageSize: 5 },
                                },
                            }}
                            pageSizeOptions={[5, 10]}
                            getRowId={(row) => row.id}
                        />
                    </Grid>

                    <div className="mobileCard">
                        {refundList.map((i, j) => (
                            <Card
                                className="mobileCard all_border"
                                key={j}
                                style={{
                                    width: "100%",
                                    marginBottom: "10px",
                                    // backgroundColor:"#ffbb33",
                                    backgroundColor: "antiquewhite",
                                }}
                            >
                                <Card.Body>
                                    <Card.Subtitle className="mb-2">
                                        <b>{t("TXT.OFF.NM.LO")}:</b> {i.offer_name}
                                    </Card.Subtitle>
                                    <Card.Subtitle className="mb-2">
                                        <b>User Reason:</b>    {i.user_reason}
                                    </Card.Subtitle>
                                    <Card.Subtitle className="mb-2">
                                        <b>Bank:</b> {i.bankname}
                                    </Card.Subtitle>
                                    <Card.Subtitle className="mb-2">
                                        <b>Created Date: </b>
                                        {format(
                                            new Date(i.created_date),
                                            "d MMM yyyy, (HH:mm)"
                                        )}
                                    </Card.Subtitle>
                                    <Card.Subtitle className="mb-2">
                                        <b> Updated Date: </b>
                                        {format(
                                            new Date(i.updated_date),
                                            "d MMM yyyy, (HH:mm)"
                                        )}
                                    </Card.Subtitle>
                                    <Card.Subtitle className="mb-2">
                                        <b>Order Id:</b> {i.orderid}
                                    </Card.Subtitle>
                                    <Card.Subtitle className="mb-2">
                                        <b>Status:</b> {i.status}
                                    </Card.Subtitle>
                                </Card.Body>
                            </Card>
                        ))}
                    </div>
                </>
            ) : (
                <center>
                    <h5>{t("TXT.NO.REC.FUD.LO")}</h5>
                </center>
            )}

        </React.Fragment>
    )
}

export default UserRefundList