import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Container,
  Form,
  Button,
  Badge,
  Image,
  ProgressBar,
  ListGroup,
  ListGroupItem,
} from "react-bootstrap";
import { vasthuUrl } from "../component/Api";
import { UseAuth } from "../context/Auth";
import { useLocation } from "react-router-dom";

const VasthuParamReview = () => {
  // const { userId } = UseAuth();
  const userId = window.sessionStorage.getItem("userId");
  const token = window.sessionStorage.getItem("token");
  const [consultantStats, setConsultantStats] = useState({});
  const [userReview, setUserReview] = useState([]);
  const [fiveStars, setFiveStars] = useState(0);
  const [fourStars, setFourStars] = useState(0);
  const [threeStars, setThreeStars] = useState(0);
  const [twoStars, setTwoStars] = useState(0);
  const [oneStar, setOneStar] = useState(0);
  const location = useLocation();
  const [totalUserReviews, setTotalUserReviews] = useState();

  useEffect(() => {
    try {
      if (location.state) {
        vasthuUrl
          .get(`usersreviewforappbyuserrating/${location.state}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          .then((res) => {
            setUserReview(res.data);
          });
      } else {
        vasthuUrl.get(`/openapi/allusersreviewforapp`).then((res) => {
          console.log("user reviews", res.data);
          setUserReview(res.data);
        });
      }
    } catch (error) {
      console.log("ERROE" + error);
    }
    try {
      if (location.state) {
        setConsultantStats({});
        vasthuUrl.get(`/openapi/allusersreviewcountforapp/`).then((res) => {
          setTotalUserReviews(res.data.total_review_count);
        });
      } else {
        vasthuUrl.get(`/openapi/allusersreviewcountforapp/`).then((res) => {
          setConsultantStats(res.data);
          console.log("user count", res.data);
          if (res.data) {
            setFiveStars(
              (res.data.rating5count / res.data.total_review_count) * 100
            );
            setFourStars(
              (res.data.rating4count / res.data.total_review_count) * 100
            );
            setThreeStars(
              (res.data.rating3count / res.data.total_review_count) * 100
            );
            setTwoStars(
              (res.data.rating2count / res.data.total_review_count) * 100
            );
            setOneStar(
              (res.data.rating1count / res.data.total_review_count) * 100
            );
          }
        });
      }
    } catch (error) {
      console.log("ERROE" + error);
    }
  }, []);

  return (
    <Container>
      <Card className="text-center all_border">
        {Object.keys(consultantStats).length > 0 ? (
          <>
            <Card.Header className="d-flex justify-content-center all_background align-items-center ">
              {/* <ListGroup.Item
                  as="li"
                  className="d-flex justify-content-space-evenly align-items-center "
                > */}{" "}
              <Image
                fluid
                thumbnail
                roundedCircle
                style={{ width: 60, height: 60, margin: 5 }}
                src="https://source.unsplash.com/user/c_v_r/100x100"
                alt="profile"
              />
              <h5 className="pt-2">
                VASTHU PARAM <br />
                <Badge  bg="danger" pill>
                  {consultantStats.average_rating}/5.0
                </Badge>{" "}
                ({consultantStats.total_review_count})
              </h5>
            </Card.Header>
            <Card.Body className="all_border">
              <ListGroup style={{border:'1px solid #fbb33e'}}>
                <ListGroup.Item
                  as="li"
                  className="d-flex justify-content-between align-items-center "
                >
                  <h6 className="mt-2" style={{ color: "grey" }}>
                    {/* 5 STAR */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1792 1792"
                      width="15"
                      height="15"
                      color="#ed9039"
                    >
                      <path
                        fill="currentColor"
                        d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      ></path>
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1792 1792"
                      width="15"
                      height="15"
                      color="#ed9039"
                    >
                      <path
                        fill="currentColor"
                        d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      ></path>
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1792 1792"
                      width="15"
                      height="15"
                      color="#ed9039"
                    >
                      <path
                        fill="currentColor"
                        d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      ></path>
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1792 1792"
                      width="15"
                      height="15"
                      color="#ed9039"
                    >
                      <path
                        fill="currentColor"
                        d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      ></path>
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1792 1792"
                      width="15"
                      height="15"
                      color="#ed9039"
                    >
                      <path
                        fill="currentColor"
                        d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      ></path>
                    </svg>
                  </h6>
                  <ProgressBar
                    className="d-flex"
                    style={{ width: "70%" }}
                    variant="success"
                    now={fiveStars}
                  // label={`${fiveStars}%`}
                  />
                  <h6 className="mt-2" style={{ color: "grey" }}>
                    {consultantStats.rating5count}
                  </h6>
                </ListGroup.Item>
                <ListGroup.Item
                  as="li"
                  className="d-flex justify-content-between align-items-center "
                >
                  <h6 className="mt-2" style={{ color: "grey" }}>
                    {/* 4 STAR */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1792 1792"
                      width="15"
                      height="15"
                      color="#ed9039"
                    >
                      <path
                        fill="currentColor"
                        d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      ></path>
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1792 1792"
                      width="15"
                      height="15"
                      color="#ed9039"
                    >
                      <path
                        fill="currentColor"
                        d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      ></path>
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1792 1792"
                      width="15"
                      height="15"
                      color="#ed9039"
                    >
                      <path
                        fill="currentColor"
                        d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      ></path>
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1792 1792"
                      width="15"
                      height="15"
                      color="#ed9039"
                    >
                      <path
                        fill="currentColor"
                        d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      ></path>
                    </svg>
                  </h6>

                  <ProgressBar
                    style={{ width: "70%" }}
                    variant="info"
                    now={fourStars}
                  // label={`${fourStars}%`}
                  />
                  <h6 className="mt-2" style={{ color: "grey" }}>
                    {consultantStats.rating4count}
                  </h6>
                </ListGroup.Item>
                <ListGroup.Item
                  as="li"
                  className="d-flex justify-content-between align-items-center "
                >
                  {" "}
                  <h6 className="mt-2" style={{ color: "grey" }}>
                    {/* 3 STAR */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1792 1792"
                      width="15"
                      height="15"
                      color="#ed9039"
                    >
                      <path
                        fill="currentColor"
                        d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      ></path>
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1792 1792"
                      width="15"
                      height="15"
                      color="#ed9039"
                    >
                      <path
                        fill="currentColor"
                        d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      ></path>
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1792 1792"
                      width="15"
                      height="15"
                      color="#ed9039"
                    >
                      <path
                        fill="currentColor"
                        d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      ></path>
                    </svg>

                  </h6>

                  <ProgressBar
                    style={{ width: "70%" }}
                    variant="warning"
                    now={threeStars}
                  // label={`${threeStars}%`}
                  />
                  <h6 className="mt-2" style={{ color: "grey" }}>
                    {consultantStats.rating3count}
                  </h6>
                </ListGroup.Item>
                <ListGroup.Item
                  as="li"
                  className="d-flex justify-content-between align-items-center "
                >
                  <h6 className="mt-2" style={{ color: "grey" }}>
                    {/* 2 STAR */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1792 1792"
                      width="15"
                      height="15"
                      color="#ed9039"
                    >
                      <path
                        fill="currentColor"
                        d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      ></path>
                    </svg>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1792 1792"
                      width="15"
                      height="15"
                      color="#ed9039"
                    >
                      <path
                        fill="currentColor"
                        d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      ></path>
                    </svg>
                  </h6>
                  <ProgressBar
                    style={{ width: "70%" }}
                    variant="danger"
                    now={twoStars}
                  // label={`${twoStars}%`}
                  />
                  <h6 className="mt-2" style={{ color: "grey" }}>
                    {consultantStats.rating2count}{" "}
                  </h6>
                </ListGroup.Item>
                <ListGroup.Item
                  as="li"
                  className="d-flex justify-content-between align-items-center "
                >
                  <h6 className="mt-2" style={{ color: "grey" }}>
                    {/* 1 STAR */}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 1792 1792"
                      width="15"
                      height="15"
                      color="#ed9039"
                    >
                      <path
                        fill="currentColor"
                        d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                      ></path>
                    </svg>
                  </h6>
                  <ProgressBar
                    style={{ width: "70%" }}
                    variant="primary"
                    // label={`${oneStar}%`}
                    now={oneStar}
                  />
                  <h6 className="mt-2" style={{ color: "grey" }}>
                    {consultantStats.rating1count}
                  </h6>
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </>
        ) : (
          <Card.Body>
            <ListGroup>
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-center "
              >
                <h6 className="mt-2" style={{ color: "grey" }}>
                  {location.state} STAR
                </h6>
                <ProgressBar
                  className="d-flex"
                  style={{ width: "70%" }}
                  variant="success"
                  now={(userReview.length / totalUserReviews) * 100}
                />
                <h6 className="mt-2" style={{ color: "grey" }}>
                  {userReview.length}
                </h6>
              </ListGroup.Item>
            </ListGroup>
          </Card.Body>
        )}
      </Card>
      {userReview.length > 0 ? (
        <Card className="mt-1 all_border">
          <Card.Header  className="text-center  all_border all_background" as="h3">
            ALL REVIEWS
          </Card.Header>
          {userReview.map((i, j) => (
            <Card.Body style={{ margin: '0', padding: '5px' }} key={j}>
              <div className="review-list">
                <ul>
                  <li className="m-0 p-0">
                    <div className="d-flex">
                      <div className="left">
                        <span>
                          <img
                            src="https://bootdey.com/img/Content/avatar/avatar1.png"
                            className="profile-pict-img img-fluid"
                            alt="userProfile"
                          />
                        </span>
                      </div>
                      <div className="right">
                        <h4>
                          {i.user.userName}
                          <span className="gig-rating text-body-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 1792 1792"
                              width="15"
                              height="15"
                            >
                              <path
                                fill="currentColor"
                                d="M1728 647q0 22-26 48l-363 354 86 500q1 7 1 20 0 21-10.5 35.5t-30.5 14.5q-19 0-40-12l-449-236-449 236q-22 12-40 12-21 0-31.5-14.5t-10.5-35.5q0-6 2-20l86-500-364-354q-25-27-25-48 0-37 56-46l502-73 225-455q19-41 49-41t49 41l225 455 502 73q56 9 56 46z"
                              ></path>
                            </svg>
                            {i.user_rating}

                          </span>
                        </h4>
                        <div className="country d-flex align-items-center">
                          {/* <span>
                        <img
                          className="country-flag img-fluid"
                          src="https://bootdey.com/img/Content/avatar/avatar6.png"
                        />
                      </span> */}
                          <div className="country-name font-accent">
                            {i.user.email}
                          </div>
                        </div>
                        <div className="review-description m-1">
                          <p>{i.review_description}</p>
                        </div>
                        {/* <span className="publish py-3 d-inline-block w-100">
                      Published 4 weeks ago
                    </span> */}
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </Card.Body>
          ))}
        </Card>
      ) : (
        <center>
          <h5 className="mt-3">No Reviews Found</h5>
        </center>
      )}
    </Container>
  );
};

export default VasthuParamReview;
