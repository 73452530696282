import React from "react";
import { useTranslation } from "react-i18next";

const HomeMarquee = () => {
  const { t } = useTranslation();
  
  return (
    <div>
      <marquee bgcolor="#fffaf0">
        <span style={{ color: "black", fontFamily: "cursive" }}>
          {/* special offer please hurry..!! */}
          {t("USR.SPL.OFR.MQ")}
        </span>
      </marquee>
    </div>
  );
};

export default HomeMarquee;
