// import React, { useEffect, useState } from 'react'
// import { Card, Col, Container, OverlayTrigger, Row, Tooltip } from 'react-bootstrap'
// import Button from 'react-bootstrap/Button';
// import { useNavigate } from 'react-router-dom';
// import { IoIosEye } from "react-icons/io";
// import { vasthuUrl } from "../component/Api";
// import { CardMedia, Divider } from '@mui/material';
// import vilaku from '../images/vilaku.jpg';
// import stone from '../images/stone.jpg';
// import bagavan from '../images/bagavan.jpg';
// import { FaGreaterThan } from "react-icons/fa";
// import { MdKeyboardArrowRight } from 'react-icons/md';

// // const YourComponent = ({ poojaidetail }) => {

// function UserProduct() {
//     const navigate = useNavigate()
//     // const [showAll, setShowAll] = useState(false);
// const [poojaList, setPoojaList] = useState();
// const [stoneList, setStoneList] = useState();
// const [vasthuList, setVasthuList] = useState();
// const token = window.sessionStorage.getItem("token");

//     useEffect(() => {
//         try {
//             vasthuUrl
//                 .get(`vasthu/user/products/Pooja`, {
//                     headers: {
//                         Authorization: `Bearer ${token}`,
//                     },
//                 })
//                 .then(res => {
//                     setPoojaList(res.data)
//                 })
//                 .catch(error => {
//                     console.log("error1", error);
//                 });
//         } catch (error) {
//             console.log('error2', error)
//         }
//         try {
//             vasthuUrl
//                 .get(`vasthu/user/products/stone`, {
//                     headers: {
//                         Authorization: `Bearer ${token}`,
//                     },
//                 })
//                 .then(res => {
//                     setStoneList(res.data)
//                 })
//                 .catch(error => {
//                     console.log("error1", error);
//                 });
//         } catch (error) {
//             console.log('error2', error)
//         }
//         try {
//             vasthuUrl
//                 .get(`vasthu/user/products/vasthu`, {
//                     headers: {
//                         Authorization: `Bearer ${token}`,
//                     },
//                 })
//                 .then(res => {
//                     setVasthuList(res.data)
//                 })
//                 .catch(error => {
//                     console.log("error1", error);
//                 });
//         } catch (error) {
//             console.log('error2', error)
//         }
//     }, []);

//     return (
//         <div class="container-fluid">
//              <h2 className='text-center mt-2'>Our Products</h2>

//             <div className='d-flex justify-content-between flex-row'>
//                 <h2 className='ms-1'>Poojai</h2>
//                 <OverlayTrigger
//                     key="top"
//                     placement="top"
//                     overlay={
//                         <Tooltip id={`tooltip-top`}>
//                             View Product List!
//                         </Tooltip>
//                     }
//                     className='me-1'
//                 >
//                     <Button className='mt-2 all_btn w-max-content' style={{ float: "right", borderRadius: 50 }} onClick={() => navigate("/userproductlistdetails", { state: poojaList })}><MdKeyboardArrowRight /></Button>
//                 </OverlayTrigger>
//             </div>
//             <Row>
//                 {poojaList && poojaList.slice(0, 4).map((i, j) =>
//                     <Col sm={10} md={3} lg={3} className="mt-2" key={j}>
//                         <Card className='all_background product_card' onClick={() => navigate('/userproductdetails', { state: i.id })}>
//                             <Card.Img variant="top" src={vilaku} />
//                             <Card.Body>
//                                 <Card.Title>{i.productSubType}</Card.Title>
//                                 <Card.Title>
//                                     ₹ {i.basePrice}
//                                 </Card.Title>
//                                 <Card.Title>{i.productShortDescription}</Card.Title>
//                             </Card.Body>
//                         </Card>
//                     </Col>
//                 )}
//             </Row>
//             <Divider />

//             <div className='d-flex justify-content-between flex-row'>
//                 <h2 className='ms-1'>Stone</h2>
//                 <OverlayTrigger
//                     key="top"
//                     placement="top"
//                     overlay={
//                         <Tooltip id={`tooltip-top`}>
//                             View Product List!
//                         </Tooltip>
//                     }
//                     className='me-1'
//                 >
//                     <Button className='mt-2 all_btn w-max-content' style={{ float: "right", borderRadius: 50 }} onClick={() => navigate("/userproductlistdetails", { state: stoneList })}><FaGreaterThan /></Button>
//                 </OverlayTrigger>
//             </div>
//             <Row className='p-2'>
//                 {stoneList && stoneList.slice(0, 4).map((i, j) => (
//                     <Col sm={12} md={4} lg={3} className="mt-2" key={j}>
//                         <Card className='all_background product_card' onClick={() => navigate('/userproductdetails', { state: i.id })}>
//                             <Card.Img variant="top" src={stone} />
//                             <Card.Body>
//                                 <Card.Title>{i.productSubType}</Card.Title>
//                                 <Card.Title>
//                                     ₹ {i.basePrice}
//                                 </Card.Title>
//                                 <Card.Title>{i.productShortDescription}</Card.Title>
//                             </Card.Body>
//                         </Card>
//                     </Col>
//                 ))}
//                 <div className='d-flex justify-content-end'>
//                     <Button className='mt-2 all_btn' style={{ float: "right" }} onClick={() => navigate("/userproductlistdetails", { state: stoneList })}>view more <IoIosEye /></Button>
//                 </div>
//             </Row>

//             <div className='d-flex justify-content-between flex-row'>
//                 <h2 className='ms-1'>Vasthu</h2>
//                 <OverlayTrigger
//                     key="top"
//                     placement="top"
//                     overlay={
//                         <Tooltip id={`tooltip-top`}>
//                             View Product List!
//                         </Tooltip>
//                     }
//                     className='me-1'
//                 >
//                     <Button className='mt-2 all_btn w-max-content' style={{ float: "right", borderRadius: 50 }} onClick={() => navigate("/userproductlistdetails", { state: vasthuList })}><FaGreaterThan /></Button>
//                 </OverlayTrigger>
//             </div>
//             <Row className='p-2'>
//                 {vasthuList && vasthuList.slice(0, 4).map((i, j) => (
//                     <Col sm={12} md={4} lg={3} className="mt-2" key={j}>
//                         <Card className='all_background product_card' onClick={() => navigate('/userproductdetails', { state: i.id })}>
//                             <Card.Img variant="top" src={bagavan} />
//                             <Card.Body>
//                                 <Card.Title>{i.productSubType}</Card.Title>
//                                 <Card.Title>
//                                     ₹ {i.basePrice}
//                                 </Card.Title>
//                                 <Card.Title>{i.productShortDescription}</Card.Title>
//                             </Card.Body>
//                         </Card>
//                     </Col>
//                 ))}
//                 <div className='d-flex justify-content-end'>
//                     <Button className='mt-2 all_btn' style={{ float: "right" }} onClick={() => navigate("/userproductlistdetails", { state: vasthuList })}>view more <IoIosEye /></Button>
//                 </div>
//             </Row>
//         </div>
//     )
// }

// export default UserProduct

import React, { useEffect, useState } from "react";
import Box from "@material-ui/core/Box";
import { Link, useNavigate } from "react-router-dom";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import MobileStepper from "@material-ui/core/MobileStepper";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import { HeartOutlined, HeartFilled } from "@ant-design/icons";
// import Paper from '@material-ui/core/Paper';
import Grid from "@material-ui/core/Grid";
import { vasthuUrl } from "../../component/Api";
import { Col, Image, Row } from "react-bootstrap";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  media: {
    width: "200px",
    height: "100px",
    cursor: "pointer",
    border: "1px solid #ffc33b",
    margin: theme.spacing(2),
    padding: theme.spacing(10),
    [theme.breakpoints.down(1050)]: {
      padding: theme.spacing(6.5),
    },
    [theme.breakpoints.down(800)]: {
      padding: theme.spacing(5.2),
    },
    [theme.breakpoints.down(500)]: {
      padding: theme.spacing(5),
    },
    paddingTop: "60.25%", // 16:9
    borderRadius: 20,
    opacity: 0.8,
    "&:hover": {
      opacity: 1,
    },
  },

  //Image style
  img: {
    width: "170px",
    height: "200px",
    objectFit: "cover",
    border: "1px solid #ffb33c",
    borderRadius: 20,
    opacity: 0.8,
    "&:hover": {
      opacity: 1,
    },
  },

  CardTitle: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    fontSize: "2.5vmin",
    [theme.breakpoints.down(1300)]: {
      fontSize: "2.0vmin",
    },
    [theme.breakpoints.down(500)]: {
      fontSize: "3vmin",
    },
  },
}));

export default function UserProduct(homePage) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:768px)");
  const responsive = useMediaQuery("(max-width:500px)");
  const [productData, setProductData] = useState([]);
  const [activeSteps, setActiveSteps] = useState({});
  const token = window.sessionStorage.getItem("token");
  const lang1 = localStorage.getItem("i18nextLng");

  useEffect(() => {
    try {
      vasthuUrl
        .get(`/vasthu/user/getallproductswithtype`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setProductData(res.data);
          console.log("service", res.data);
        });
    } catch (e) {
      console.log("service", e);
    }
  }, []);

  const sideScroll = (element, direction, speed, distance, step) => {
    var scrollAmount = 0;
    var slideTimer = setInterval(function() {
      if (direction === "left") {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  };

  const theme = useTheme();

  const handleNext = (productType, totalSteps) => {
    setActiveSteps((prevSteps) => ({
      ...prevSteps,
      [productType]: Math.min(
        (prevSteps[productType] || 0) + 1,
        totalSteps - 1
      ),
    }));
    var container = document.getElementById(`demoids-${productType}`);
    sideScroll(container, "right", 0, 1250, 10);
  };

  const handleBack = (productType) => {
    setActiveSteps((prevSteps) => ({
      ...prevSteps,
      [productType]: Math.max((prevSteps[productType] || 0) - 1, 0),
    }));
    var container = document.getElementById(`demoids-${productType}`);
    sideScroll(container, "left", 0, 1240, 10);
  };
  return (
    <div>
      <center>
        <h2>
          <u>{t("TXT.OUR.PRO.LO")}</u>
        </h2>
      </center>
      {productData && productData.length > 0 ? (
        productData.map((i, j) => (
          <Box>
            <h3 className="text-center mt-2">
              {lang1 == "en"
                ? i.productType
                : lang1 == "ta"
                ? i.products[0].name_ta
                : i.products[0].name_hi}
            </h3>
            <Box
              style={{ whiteSpace: "nowrap" }}
              mx={5}
              border={2}
              borderColor="#ffbb33"
              borderRadius={10}
              bgcolor="#fafafa"
            >
              {matches && i.products.length > 0 && (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs>
                      <Button
                        size="small"
                        onClick={() => handleBack(i.productType)}
                        disabled={(activeSteps[i.productType] || 0) === 0}
                      >
                        {theme.direction === "rtl" ? (
                          <KeyboardArrowRight />
                        ) : (
                          <KeyboardArrowLeft />
                        )}
                        {t("TXT.BCK.UP")}
                      </Button>
                    </Grid>
                    {/* <Grid item>
                      <MobileStepper
                        variant="dots"
                        steps={i.products.length}
                        position="static"
                        activeStep={activeSteps[i.productType] || 0}
                        className={classes.root}
                      />
                    </Grid> */}
                    <Grid item xs>
                      <Button
                        style={{ float: "right" }}
                        size="small"
                        onClick={() =>
                          handleNext(i.productType, i.products.length)
                        }
                        disabled={
                          (activeSteps[i.productType] || 0) ===
                          i.products.length - 1
                        }
                      >
                        {t("TXT.NXT.UP")}
                        {theme.direction === "rtl" ? (
                          <KeyboardArrowLeft />
                        ) : (
                          <KeyboardArrowRight />
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </>
              )}
              <Box
                id={`demoids-${i.productType}`}
                component="div"
                overflow={matches ? "hidden" : "scroll"}
              >
                <Box display="flex" flexDirection="row" m={responsive ? 0 : 2}>
                  {i.products.map((i, j) => (
                    <Box
                      key={j}
                      m={1}
                      mx={responsive ? 1.5 : 1}
                      align="center"
                      style={{ textWrap: "wrap" }}
                    >
                      <Image
                        className={classes.img}
                        src={i.productimgUrl1}
                        title={i.productName}
                        onClick={() => {
                          if (token) {
                            navigate("/productdetails", {
                              state: i.productId,
                            });
                          } else {
                            navigate("/product");
                          }
                        }}
                      />
                      <Typography variant="h6" className={classes.CardTitle}>
                        {i.productName}
                      </Typography>
                      {/* <Typography className={classes.CardTitle}>
                        ₹ &nbsp;{i.basePrice}
                      </Typography> */}
                      {!homePage.data ? (
                        <Typography className={classes.CardTitle} color="grey">
                          {i.productShortDescription}
                        </Typography>
                      ) : null}
                    </Box>
                  ))}
                </Box>
              </Box>
            </Box>
          </Box>
        ))
      ) : (
        <center>
          <h5>{t("TXT.COM.SOON.LO")}...</h5>
        </center>
      )}
    </div>
  );
}
