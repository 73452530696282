import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  InputGroup,
  Row,
  Form,
  Card,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { vasthuUrl } from "../component/Api";
import BillDeskPay from "../component/BillDeskPay";
import { UseAuth } from "../context/Auth";
import Toast from "../component/Toast";
import { useTranslation } from "react-i18next";

function WalletOffer() {
  const Navigate = useNavigate();
  const { t } = useTranslation();
  const { userId } = UseAuth();
  const lang = localStorage.getItem("i18nextLng");
  const token = window.sessionStorage.getItem("token");
  const [savemoney, setSaveMoney] = useState([]);
  console.log("savemoney", savemoney);

  const [offers, setOffers] = useState([]);
  console.log("offers", offers);

  // ALERT MODAL
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [toastTrigger, setToastTrigger] = useState(0);

  const alert = (msg) => {
    setMessage(msg);
    setToastTrigger((prev) => prev + 1);
  };

  useEffect(() => {
    try {
      vasthuUrl
        .get("/getallactivewalletoffers", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setOffers(res.data);
          console.log("res.data", res.data);
        });
    } catch (e) {
      console.log("SERVER ERROR");
      setOffers([]);
    }

    try {
      vasthuUrl
        .get(`/getuserbalance/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setSaveMoney(res.data);
          console.log("service", res.data);
        });
    } catch (e) {
      console.log("service", e);
    }
  }, []);

  const BillDeskPayHandler = (txn) => {
    console.log(txn);
    vasthuUrl
      .post(
        "/user/bdpaymentresponseuiwallet",
        { txn: txn.txnResponse.transaction_response },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("txnres", res.data);
        // if (res.data.transaction_error_type == "success") {
        //   navigate("/customerpricing");
        // }

        if (res.data.transaction_error_type === "success") {
          console.log("Saving payment_response ....", txn);

          vasthuUrl
            .post("/user/bdpaymentresponsehandlerwallet", txn, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res1) => console.log("after txnsave", res1.data))
            .catch((err) => console.log("after txnsave try", err));
          alert(t("USR.PAYMENT.MSG"));
          setMessageType("success");
          const returnurl = window.sessionStorage.getItem("returnurl");
          setTimeout(() => {
            Navigate(returnurl);
            window.sessionStorage.setItem("WalletAmount", 0);
          }, 2000);
          console.log("buying .. subsc");
        } else {
          console.log("Payment status changed to false");
        }
      });
  };

  const handleBuy = async (actualPrice, offerId) => {
    try {
      const response = await vasthuUrl
        .post(
          `/openapi/createwalletorderapi`,
          {
            userUuid: userId,
            walletofferId: offerId,
            price: actualPrice,
            currency: "356",
            returnUrl: "customerpricing",
            lng: lang,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          BillDeskPay(res.data, BillDeskPayHandler)
            .then((config) => {
              console.log("payout", config);
              window.loadBillDeskSdk(config);
            })
            .catch((err) => console.log("payment initialization fails", err));
        });
    } catch (err) {
      console.log(err);
      alert("Paytm server Error" + err);
      setMessageType("error");
    }
  };

  return (
    <>
      <Toast msg={message} type={messageType} trigger={toastTrigger} />
      <Container>
        <div style={{ backgroundColor: "#ffc33b", marginTop: "5px" }}>
          <h3>
            <center>{t("USR.ADD.MONEY.WALT")}</center>
          </h3>
        </div>

        <Row
          style={{ display: "flex", alignItems: "center", marginTop: "15px" }}
        >
          <Col>
            <h2>
              <div>
                <b>
                  {t("USR.AVABLE.BALCE")}: ₹ {savemoney.balance}
                </b>
              </div>
            </h2>
          </Col>
        </Row>

        <h4>
          <u>{t("USR.POPLR.RECHARGE")}</u>
        </h4>

        <Row>
          {offers.map((offer, index) => (
            <Col xs={4} sm={4} md={3} lg={2} key={index}>
              <Card
                style={{
                  // width: "150px",
                  padding: "0",
                  borderRadius: "21px",
                  margin: "10px 0px",
                  border: "1px solid #ffc33b",
                  cursor: "pointer",
                  boxShadow:
                    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 2px 0 rgba(0, 0, 0, 0.19)",
                }}
                onClick={
                  () => handleBuy(offer.actualPrice, offer.id)
                  // Navigate("/walletpaymentprocess", {
                  //   state: { offerId: offer.id, offerList: offers },
                  // })
                }
                className="card-hover"
              >
                <Card.Body>
                  <Card.Text>
                    <center>₹ {offer.actualPrice}</center>
                  </Card.Text>
                </Card.Body>
                <div
                  style={{
                    backgroundColor: index === 0 ? "#3ee46d" : "#ffc33b",
                    padding: "5px",
                    borderEndStartRadius: "20px",
                    borderEndEndRadius: "20px",
                  }}
                >
                  {/* <center>{offer.walletOffername}</center> */}
                  <center>
                    ₹&nbsp;{offer.extraAmount}&nbsp;{t("USR.RECHARGE.EXTRA")}
                  </center>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
    </>
  );
}

export default WalletOffer;
