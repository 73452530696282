import React, { useEffect, useState } from "react";
import { UseAuth } from "./context/Auth";
import { VasthuProvider } from "./context/vasthu";
import AuthRoute from "./Routes.js/AuthRoute";
import UserRoute from "./Routes.js/UserRoute";
import AdminRoute from "./Routes.js/AdminRoute";
import ProfRoute from "./Routes.js/ProfRoute";
import ProtectedRoute from "./component/ProtectedRoute";
import CrmRoute from "./Routes.js/CrmRoute";

import ReactGA from "react-ga4";
// import ReactGA from "react-ga4";
import TagManager from "react-gtm-module";
import { vasthuUrl } from "./component/Api";
import { useNavigate } from "react-router-dom";

ReactGA.initialize("G-V7ZKTRPGFN");

//GA

const tagManagerArgs = {
  gtmId: "GTM-NFS7R6KQ",
};
TagManager.initialize(tagManagerArgs);
// GA -END

const App = () => {
  const { token, role, setToken, onLogout } = UseAuth();
  //  const token = window.sessionStorage.getItem("token");
  //  const role = window.sessionStorage.getItem("role")
  console.log("LOG FROM APP JS", token, role);

  const refreshToken = async () => {
    try {
      // Check if user is null before attempting to refresh token
      if (token) {
        const res = await vasthuUrl.get("api/auth/refresh", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        console.log("res.data", res.data);
        if (res.data.access_token) {
          setToken(res.data.access_token);
          window.sessionStorage.setItem("token", res.data.access_token);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      refreshToken();
    }, 30 * 60 * 1000); // Refresh every 30 minutes

    // Clear interval and stop refreshing when user is no longer available
    return () => clearInterval(interval);
  }, [token]);

  //LogOut Timer
  const [isActive, setIsActive] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const navigate = useNavigate();
  console.log("isActive", isActive);

  useEffect(() => {
    let timeoutId;

    const resetTimer = () => {
      if (token) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => {
          setIsActive(false);
          setShowAlert(true);
          onLogout();
        }, 10 * 60 * 1000); // 10 minutes
      }
    };

    const clearTimer = () => {
      clearTimeout(timeoutId);
    };

    const handleActivity = () => {
      setIsActive(true);
      resetTimer();
    };

    // Set up event listeners for activity
    document.addEventListener("mousemove", handleActivity);
    document.addEventListener("keydown", handleActivity);

    // Start initial timer
    resetTimer();

    return () => {
      // Clean up event listeners
      document.removeEventListener("mousemove", handleActivity);
      document.removeEventListener("keydown", handleActivity);

      // Clear the timeout when the component unmounts
      clearTimer();
    };
  }, [onLogout]);

  useEffect(() => {
    if (!isActive && showAlert) {
      // Display alert and redirect to login page after user clicks "OK"
      const confirmation = window.confirm(
        "Your session has timed out. Click OK to log in again."
      );
      if (confirmation) {
        navigate("/login");
      }
    }
  }, [isActive, showAlert, navigate]);
   //LogOut Timer --END

  // GOOGLE Analytics

  window.dataLayer.push({
    event: "pageview",
  });

  ///  // GOOGLE Analytics --END

  if (token === "" && role === "") {
    return <AuthRoute />;
  } else if (window.sessionStorage.getItem("token") == null) {
    return <AuthRoute />;
  } else {
    if (role === process.env.REACT_APP_VASTHU_PROFESSIONAL) {
      return <ProfRoute />;
    } else if (role === process.env.REACT_APP_VASTHU_ADMIN) {
      return <AdminRoute />;
    } else if (role === process.env.REACT_APP_VASTHU_CRM) {
      return <CrmRoute />;
    } else {
      return <UserRoute />;
    }
  }
};

export default App;
