import React, { useContext } from "react";
import { AppBar, Button, Link, Toolbar, Typography } from "@mui/material";
import { UseAuth } from "../../context/Auth";

import { HeaderUserContext } from "../../pages/crm/Layout";

const MyHeader = () => {
  const { onLogout } = UseAuth();

  const { crmUser } = useContext(HeaderUserContext);
  return (
    <AppBar
      position="static"
      color="primary"
      elevation={0}
      sx={{ borderBottom: (theme) => `1px solid ${theme.palette.divider}` }}
    >
      <Toolbar sx={{ flexWrap: "wrap", justifyContent: "space-between" }}>
        {/* <Typography variant="h6" color="secondary" noWrap sx={{ flexGrow: 1 }}>
            Vasthuparam CRM
          </Typography> */}
        {/* <nav> */}
        <Link
          variant="button"
          underline="none"
          color="secondary"
          href="/"
          sx={{ my: 1, mx: 1.5 }}
        >
          <Typography variant="h6" color="black" noWrap sx={{ flexGrow: 1 }}>
            Vasthuparam CRM
          </Typography>
        </Link>

        {crmUser == null ? (
          <></>
        ) : (
          <Typography
            variant="h6"
            color="secondary"
            noWrap
            sx={{ flexGrow: 1 }}
          >
            Logged in As {crmUser}
          </Typography>
        )}
        <Button
          className="all_btn"
          variant="outlined"
          sx={{ my: 1, mx: 1.5 }}
          onClick={onLogout}
        >
          Logout
        </Button>
      </Toolbar>
    </AppBar>
  );
};
export default MyHeader;
