import React from "react";
import PropTypes from "prop-types";
import { Chart } from "react-google-charts";

export default function PieChart(props) {
  return (
    <Chart
      chartType="PieChart"
      width="100%"
      height="400px"
      data={props.pieData}
      options={props.pieOptions}
    />
  );
}

PieChart.propTypes = {
  pieData: PropTypes.arrayOf(PropTypes.array).isRequired,
  pieOptions: PropTypes.object,
};
PieChart.defaultProps = {
  pieOptions: {
    title: "Chart Result",
    pieHole: 0.2,
    is3D: false,
  },
};
