import React, { useEffect, useState } from "react";
import {
  Alert,
  Badge,
  Button,
  Col,
  Form,
  Modal,
  Row,
  Spinner,
} from "react-bootstrap";
import DatePicker from "react-date-picker";
import { useTranslation } from "react-i18next";
import { AiOutlineStar } from "react-icons/ai";
import { BiBadgeCheck } from "react-icons/bi";
import { MdOutlineLanguage } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import "../App.css";
import { vasthuUrl } from "../component/Api";
import BillDeskPay from "../component/BillDeskPay";
import BookConsultation from "../component/BookConsultation";
import { usePay } from "../context/PaymentContext";

const CheckConsultant = () => {
  const token = window.sessionStorage.getItem("token");
  const role = window.sessionStorage.getItem("role");
  const crmSelectedUser = window.sessionStorage.getItem("crmSelectedUser");
  const user = window.sessionStorage.getItem("userId");
  const userId = token && role == "ROLE_CRM" ? crmSelectedUser : user;

  const location = useLocation();
  // const serviceName = location.state.selectedService;
  console.log("location", location);
  const { subId } = usePay();
  const [bookModal, setBookModal] = useState(true);
  const [profObj, setProfObj] = useState({});
  console.log("profOOOOOObj", profObj);
  const [curDate, setCurDate] = useState(new Date());
  const [value, setValue] = useState(null);
  const [maxDate, setMaxDate] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const { t } = useTranslation();
  const [bookSlotId, setBookSlotId] = useState(null);
  const [bookServiceId, setBookServiceId] = useState(null);
  const [bookServiceOfferString, setBookOfferString] = useState("vasthu");
  const [consultSlot, setConsultantSlot] = useState([]);
  const [consultSlotAudio, setConsultantSlotAudio] = useState([]);
  const [consultSlotVideo, setConsultantSlotVideo] = useState([]);
  const [slotLoading, setSlotLoading] = useState(false);
  const [profList, setProfList] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const [selectSubscriptionId, setSelectSubscriptionId] = useState(null);
  const [selectofferId, setSelectofferId] = useState(null);
  const [selectDuration, setSelectDuration] = useState(null);
  const [bookDuration, setBookDuration] = useState(null);
  console.log("selectedService", selectedService);
  console.log("selectSubscriptionId", selectSubscriptionId);
  console.log("selectofferId", selectofferId);
  const lang = localStorage.getItem("i18nextLng");
  const navigate = useNavigate();
  const [showPaytm, setShowPaytm] = useState(false);
  const [paymentCredentials, setPaymentCredentials] = useState({
    txnToken: "",
    orderId: "",
    amount: 0,
    offerId: 0,
    userId: "",
    consultantId: "",
    slotId: 0,
  });
  const [defaultHomeOffer, setDefaultHomeOffer] = useState({
    offerId: 0,
    offerPrice: 0,
    serviceId: 0,
  });
  console.log("defaultHomeOffer", defaultHomeOffer);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [activeServiceList, setActiveServiceList] = useState([]);

  const [serviceData, setServiceData] = useState([]);
  const [callMin, setCallMin] = useState([]);

  useEffect(() => {
    vasthuUrl.get("offer/getAllActiveMinutesOffer").then((res) => {
      console.log("available times", res.data);
      setCallMin(res.data);
    });
  }, []);

  console.log(subId);

  const alert = (msg) => {
    setMessage(msg);

    setTimeout(() => {
      setMessage("");
    }, 4000);
  };

  const modalAlert = (msg) => {
    setMessage(msg);

    setTimeout(() => {
      setMessage("");
      handleClose();
    }, 1000);
  };

  useEffect(() => {
    const data = window.sessionStorage.getItem("checkcon");
    console.log("dataaaaa", data);
    if (data) {
      const parsedData = JSON.parse(data);
      setProfObj(parsedData.value);
      setSelectedService(parsedData.selectedService);
      setSelectSubscriptionId(parsedData.selectSubscriptionId);
      setSelectofferId(parsedData.selectofferId);
      setSelectDuration(parsedData.selectDuration);
      setBookDuration(parsedData.selectDuration);
    }
    // else {
    //   setSelectSubscriptionId(null);
    //   setSelectofferId(null);
    // }
  }, []);

  // useEffect(() => {
  //   console.log("props", location);
  //   setProfObj(location.state.value);
  //   setSelectedService(location.state.selectedService);
  // }, []);

  useEffect(() => {
    setLoading(true);
    try {
      vasthuUrl
        .get(`/vasthu/getvasthuservices`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("services_list" + res.data);
          let consultations = res.data.filter(
            (obj) => obj.serviceType == "CONSULTATION"
          );
          console.log(
            "services_list_consultaions" + consultations[0].serviceType
          );
          setServiceData(consultations);
        });

      vasthuUrl
        .get(`/info/professional/home`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          // console.log("consultants"+res[0].consultantName)
          console.log("prolist", res.data);
          console.log();
          setProfList(res.data);
          setLoading(false);
        });
      vasthuUrl
        .get(`offer/defaultIndividualOffer/${bookServiceOfferString}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setDefaultHomeOffer({
            offerId: res.data.id,
            offerPrice: res.data.offerPrice,
            serviceId: res.data.serviceId,
          });
        });
    } catch (e) {
      alert("SERVER ERROR");
      setLoading(false);
    }
    var maxSlotDate = new Date(curDate);
    maxSlotDate.setDate(maxSlotDate.getDate() + 30);
    setMaxDate(maxSlotDate);
  }, [consultSlot, consultSlotAudio, consultSlotVideo]);

  useEffect(() => {
    try {
      vasthuUrl
        .get(`offer/defaultIndividualOffer/${bookServiceOfferString}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setDefaultHomeOffer({
            offerId: res.data.id,
            offerPrice: res.data.offerPrice,
            serviceId: res.data.serviceId,
          });
          console.log(
            "slotServiceOfferChanged" +
              res.data.id +
              "for service" +
              res.data.serviceId
          );
        });
    } catch (e) {
      alert("SERVER ERROR");
      setLoading(false);
    }
  }, [bookServiceId]);

  const [bookDate, setBookDate] = useState(null);
  const [availSlot, setAvailSlot] = useState([]);
  const [bookTime, setBookTime] = useState(null);

  const [serviceScheduleId, setServiceScheduleId] = useState(null);
  console.log("bookTime", bookTime);
  console.log("bookDate", bookDate);
  console.log("bookDuration", bookDuration);
  console.log("ConsultantSlotbydate112222", availSlot);

  const handleBookDate = async (e, id) => {
    if (e) {
      let date = e
        .getDate()
        .toString()
        .padStart(2, "0");
      let month = (e.getMonth() + 1).toString().padStart(2, "0");
      let year = e.getFullYear();

      setValue(e);
      setBookDate(`${year}-${month}-${date}`);
    }
  };

  const handleBookDateDuration = async (min, consultantUuid) => {
    // if (e) {
  };

  useEffect(() => {
    const fetchSlots = async () => {
      try {
        const statusRes = await vasthuUrl.post(
          "/vasthu/user/getprobookingstatusavalaibility",
          { pro_uuid: profObj.consultantUuid },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (bookDate && bookDuration) {
          setSlotLoading(true);
          try {
            const slotRes = await vasthuUrl.get(
              `/availableslots/${profObj.consultantUuid}/${bookDate}/${bookDuration}`,
              {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              }
            );

            if (
              slotRes.data ===
              "No available slots found for the specified date."
            ) {
              alert(slotRes.data);
              setAvailSlot([]);
            } else {
              console.log("ConsultantSlotbydate", slotRes.data);
              setAvailSlot(slotRes.data);
            }
          } catch (e) {
            alert("SERVER ERROR");
          } finally {
            setSlotLoading(false);
          }
        } else {
          setAvailSlot([]);
          setSlotLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchSlots();
  }, [bookDate, bookDuration]);

  const openModal = () => {
    setShowReviewModal(true);
  };
  const handleClose = () => {
    setConsultantSlot([]);
    setConsultantSlotAudio([]);
    setConsultantSlotVideo([]);
    setProfObj({});
    setBookSlotId(null);
    setValue(null);
    Navigation();
  };
  const Navigation = () => {
    navigate(-1);
  };

  const handleBookingAfterPayment = async (
    offer,
    serviceId,
    subscriptionId
  ) => {
    try {
      if (token && profObj.consultantUuid && bookSlotId) {
        console.log("CHECKED OK FOR BOOKING");
        setShowPaytm(true);
      } else {
        alert("Please Choose Slot Timing");
      }
    } catch (e) {
      alert("SERVER ERROR in booking");
    }
  };

  const submitBooking = () => {
    try {
      console.log("booking service-on submit" + bookServiceId);
      vasthuUrl
        .get(
          `/subscription/userActiveOfferswithduration/${userId}/${bookServiceId}/${bookDuration}`,
          {
            //instead of userActiveOffers to
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(async (list) => {
          console.log(
            "Activeservice-list-if available " + JSON.stringify(list.data)
          );
          setActiveServiceList(list.data);
          if (
            selectSubscriptionId !== undefined &&
            selectofferId !== undefined &&
            selectedService !== undefined
          ) {
            console.log("handleBookSlot");
            handleBookSlot(
              selectofferId,
              selectedService,
              selectSubscriptionId
            );
          } else if (list.data.length > 0) {
            console.log("openModal");
            openModal();
          } else {
            console.log("handleBuy");
            handleBuy(
              defaultHomeOffer.offerId,
              defaultHomeOffer.offerPrice,
              defaultHomeOffer.serviceId
            );
          }
          // if (list.data.length > 0) {
          //   console.log("openModal");
          //   openModal();
          // } else {
          //   console.log("handleBuy");
          //   handleBuy(
          //     defaultHomeOffer.offerId,
          //     defaultHomeOffer.offerPrice,
          //     defaultHomeOffer.serviceId
          //   );
          // }
        });
    } catch (e) {
      alert("CHECK INTERNET CONNECTION");
      setLoading(false);
      // console.log("SERVER ERROR", e);
    }
  };

  const handleBookSlot = async (offer, serviceId, subscriptionId) => {
    try {
      console.log("token", token);
      console.log(" profObj.consultantUuid", profObj.consultantUuid);
      if (token && profObj.consultantUuid) {
        const res = await vasthuUrl
          .post(
            `saveslotforofferpurchased`,
            {
              downloadUri: "https://example.com/file.pdf",
              scheduleDate: bookDate,
              slotTime: bookTime,
              serviceScheduleId: serviceScheduleId,
              scheduleDurationInMinutes: bookDuration,
              subscriptionId: subscriptionId,
              userUuid: user,
              consultantUuid: profObj.consultantUuid,
              offerId: offer,
              serviceId: serviceId,
              booking_status: "CONFIRMED",
              service_status: "ACTIVE",
              cancelledByUuid: null,
              bookedByUuid: userId,
              lng: lang,
              slotBookedToTime: bookTime,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          // const res = await vasthuUrl.post(
          //   `blockslot`,
          //   {
          //     userUuid: userId,
          //     consultantUuid: profObj.consultantUuid,
          //     slotId: bookSlotId,
          //     subscriptionId: subscriptionId,
          //   },
          //   {
          //     headers: {
          //       Authorization: `Bearer ${token}`,
          //     },
          //   }
          // );
          // console.log(res.data.message);
          // if (res.data.message === "SUCCESS") {
          //   // const res1 = await vasthuUrl
          //   await vasthuUrl
          //     .post(
          //       `bookslot`,
          //       {
          //         userUuid: userId,
          //         consultantUuid: profObj.consultantUuid,
          //         slotId: bookSlotId,
          //         offerId: offer,
          //         serviceId: serviceId,
          //         subscriptionId: subscriptionId,
          //         bookedByUuid: token && role == "ROLE_CRM" ? user : userId, // newly added
          //         lng: lang,
          //       },
          //       {
          //         headers: {
          //           Authorization: `Bearer ${token}`,
          //         },
          //       }
          //     )
          .then((result) => {
            console.log("MSG", result.data.message);
            console.log("RES", result.data);
            if (result.data.message == "SUCCESS") {
              vasthuUrl
                .get(`/subscription/useVasthuOffer/${subscriptionId}`, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })
                .then((rs) => {
                  console.log(
                    "offer subscription redeem count used",
                    rs.data.message
                  );
                  navigate("/consultantbooklist");
                });
            }
          });
        // modalAlert(res1.data.message);
      } else {
        alert("Book Failed");
      }
    } catch (e) {
      alert("SERVER ERROR in booking area");
    }
  };

  // const BillDeskPayHandler = (txn) => {
  //   console.log("booking list pay handler", txn);
  // };
  const BillDeskPayHandler = (txn) => {
    console.log(txn);
    // if (
    //   txn.transaction_error_desc.toUpperCase() !== "TRANSACTION NOT AUTHORIZED" && tx.transaction
    // ) {
    try {
      vasthuUrl
        .post(
          "vasthu/user/bdpaymentresponseui",
          { txn: txn.txnResponse.transaction_response },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log("txnres", res.data);
          // if (res.data.transaction_error_type == "success") {
          //   console.log("home validation");
          //   navigate("/homevalidation", {
          //     state: { offerId: res.data.id },
          //   });
          // }

          if (res.data.transaction_error_type === "success") {
            console.log("Saving payment_response ....", txn);

            vasthuUrl
              .post("vasthu/user/bdpaymentresponsehandler", txn, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((res) => console.log("after txnsave", res.data))
              .catch((err) => console.log("after txnsave try", err));

            console.log("buying .. subscription");
            vasthuUrl
              .post(
                "subscription/buyVasthuOffer",
                {
                  orderId: res.data.orderid,
                  userUuid: res.data.additional_info.additional_info1,
                  offerId: parseInt(res.data.additional_info.additional_info2),
                  paymentStatus: true,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((res) => {
                console.log("Payment status changed to true");
                console.log("afterbuysubscr", res.data);
                handleBookSlot(
                  defaultHomeOffer.offerId,
                  defaultHomeOffer.serviceId,
                  res.data.id
                );
              });
          } else {
            vasthuUrl
              .post(
                "subscription/buyVasthuOffer",
                {
                  orderId: res.data.orderid,
                  userUuid: res.data.additional_info.additional_info1,
                  offerId: parseInt(res.data.additional_info.additional_info2),
                  paymentStatus: false,
                },
                {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                }
              )
              .then((res) => {
                console.log("Payment status changed to false");
                // setOpen(true);
              });
          }
        });
    } catch (err) {
      console.log("pay handler", err);
    }
    // }
  };

  const handleBuy = async (val, price, serviceId) => {
    if (token && profObj.consultantUuid && bookSlotId) {
      try {
        await vasthuUrl
          .post(
            `vasthu/openapi/createorderapi`,
            {
              userUuid: userId,
              offerId: val,
              price: price,
              currency: "356",
              returnUrl: "consultantbooklist",
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(async (res) => {
            console.log(
              "booking handleBuy respone after billdesk create order api",
              res.data
            );
            BillDeskPay(res.data, BillDeskPayHandler)
              .then((config) => {
                console.log("booking pay initiate bill desk", config);
                window.loadBillDeskSdk(config);
              })
              .catch((err) => console.log("booking handle buy"));
          });
      } catch (err) {
        console.log(err);
      }
    } else {
      alert("Please Choose Slot Timing");
    }
  };
  return (
    <div>
      <div>
        <Modal
          show={bookModal}
          size="lg"
          centered
          fullscreen={true}
          style={{ border: "1px solid #ffb33e" }}
        >
          <Modal.Header
            style={{
              borderBottom: "1px solid #ffb33e",
              backgroundColor: "#ffbb33",
            }}
          >
            <Modal.Title>
              <Row>
                <img
                  src={profObj.imgUrl} // static img
                  className="rounded-circle img-fluid"
                  alt="avatar pic"
                  style={{ width: "100px", height: "100px" }}
                />
                <Col>
                  {profObj.consultantName}{" "}
                  <Badge pill bg="warning" text="dark" size="sm">
                    <AiOutlineStar size={15} />
                    {profObj.consultantRating}
                  </Badge>
                  <div>
                    <MdOutlineLanguage />
                    {profObj.knownLangugae},{profObj.extraLang}
                  </div>
                  <div>
                    <BiBadgeCheck />
                    {profObj.skills},{profObj.extraSkill}
                  </div>
                </Col>
              </Row>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="justify-content-center align-center text-center">
            {message && (
              <Alert variant="info" className="text-center">
                {message}
              </Alert>
            )}
            <center>
              <u>
                {/* <h3>Choose Your Available Date</h3> */}
                <h3>
                  {/* {t("USR.BOK.CHE.CON.TL")} - &nbsp; */}
                  {selectedService == 3
                    ? t("USR.SER.NAME.VAS")
                    : selectedService == 4
                    ? t("USR.SER.NAME.AST")
                    : selectedService == 5
                    ? t("USR.SER.NAME.BUL")
                    : t("CON.APMT.APMSTS.CON")}
                </h3>
              </u>
            </center>
            <Row>
              <Col sm={12} lg={4}>
                <h3>{t("USR.BOK.CHE.CON.TL")}</h3>
                <DatePicker
                  value={value}
                  onChange={(e) => {
                    // setSlotLoading(true);
                    handleBookDate(e, profObj.consultantUuid);
                    setValue(e);
                  }}
                  format="dd-MM-y"
                  maxDate={maxDate}
                  minDate={new Date()}
                  required
                  className="form_feild"
                  style={{ backgroundColor: "#ffb33e" }}
                />
              </Col>
              <Col sm={12} lg={4}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <h3>{t("USR.PRFRD.TIME")}</h3>
                  {selectDuration !== null && selectDuration !== undefined ? (
                    <Form.Control
                      aria-label="min"
                      size="sm"
                      style={{ width: "150px" }}
                      value={bookDuration !== null ? bookDuration + "Mins" : ""}
                      disabled
                    />
                  ) : (
                    <Form.Select
                      aria-label="min"
                      size="sm"
                      // defaultValue={selectDuration}
                      style={{ width: "200px", alignItems: "center" }}
                      onChange={(e) => {
                        console.log("MINS", e.target.value);
                        // handleBookDateDuration(
                        //   e.target.value,
                        //   profObj.consultantUuid
                        // );
                        setBookDuration(e.target.value);
                      }}
                      value={bookDuration !== null ? bookDuration : ""}
                    >
                      {/* <option key={0} value={""}>
                        {t("PR.APMNT.NATAB.LB.CD.TA")}
                      </option>
                      <option key={1} value="15">
                        15 {t("USR.MIN.TEXT")}
                      </option>
                      <option key={2} value="30">
                        30 {t("USR.MIN.TEXT")}
                      </option>
                      <option key={3} value="45">
                        45 {t("USR.MIN.TEXT")}
                      </option> */}
                      <option key={0} value={""}>
                        {t("PR.APMNT.NATAB.LB.CD.TA")}
                      </option>
                      {callMin.map((i) => (
                        <option key={i.id}>{i.offerCallMins}</option>
                      ))}
                    </Form.Select>
                  )}
                </div>
              </Col>
              <Col sm={12} lg={4}>
                <h3>{t("USR.AVAL.SLT.TIME")}</h3>
                <div
                  id="slots"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {!slotLoading ? (
                    availSlot.length > 0 ? (
                      availSlot.map((slotObj, i) => (
                        <div key={i} style={{ marginBottom: "10px" }}>
                          {/* <h4>
                        Schedule ID: {slotObj.scheduleId} -{" "}
                        {slotObj.consultationMode}
                      </h4> */}
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            {slotObj.slots.map((time, j) => (
                              <div
                                id="slot"
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  marginBottom: "5px",
                                }}
                                key={j}
                              >
                                <Form.Check
                                  inline
                                  label={time}
                                  onChange={() => {
                                    console.log(
                                      "scheduleId",
                                      slotObj.scheduleId
                                    );
                                    setBookTime(time);
                                    setBookSlotId(slotObj.scheduleId);
                                    setServiceScheduleId(slotObj.scheduleId);
                                    setBookServiceId(selectedService);
                                    console.log(
                                      "booking service " + selectedService
                                    );
                                    if (selectedService == 3) {
                                      setBookOfferString("vasthu");
                                    } else if (selectedService == 4) {
                                      setBookOfferString("astrology");
                                    } else if (selectedService == 5) {
                                      setBookOfferString("building");
                                    } else {
                                      setBookOfferString("audio");
                                    }
                                  }}
                                  name={profObj.consultantUuid}
                                  type="radio"
                                  id={`inline-radio-${i}-${j}`}
                                  className="slot_check"
                                />
                              </div>
                            ))}
                          </div>
                        </div>
                      ))
                    ) : null
                  ) : (
                    <p>Loading...</p>
                  )}
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer
            style={{
              borderTop: "1px solid #ffb33e",
              justifyContent: "space-evenly",
            }}
          >
            <Button
              // variant="outline-warning"
              // style={{ borderRadius: 50 }}
              className="all_btn"
              onClick={async () => {
                navigate(-1);
              }}
            >
              {t("TXT.BTN.CAN.LO")}
            </Button>
            <Button
              // variant="outline-warning"
              // style={{ borderRadius: 50}}
              className="all_btn"
              onClick={async () => {
                submitBooking();
              }}
            >
              {/* Confirm Booking */}
              {t("USR.BO.CON.BO")}
            </Button>
          </Modal.Footer>

          <Modal
            show={showReviewModal}
            onHide={() => {
              setShowReviewModal(false);
            }}
            size="lg"
            keyboard={false}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="v_modal"
          >
            <Modal.Header closeButton className="all_background">
              <Modal.Title id="contained-modal-title-vcenter">
                {t("USR.BOK.UR.PLS.TL")}
              </Modal.Title>
            </Modal.Header>

            {loading ? (
              <center>
                <Spinner animation="border" variant="warning" />
              </center>
            ) : (
              <Modal.Body>
                <p>
                  {/* Already you have some offer palns. If you want to proceed or
                  Buy new offer to click Buy Offer. */}
                  {t("USR.BOK.CHK.UR.PLN.TXT")}
                </p>
                <form>
                  {/* {JSON.stringify(activeServiceList)} */}
                  <div className="row text-center">
                    {activeServiceList.length > 0 ? (
                      activeServiceList.map((i, j) => (
                        <div
                          className="col-sm-12 col-md-4 col-lg-3 g-1"
                          key={j}
                        >
                          <div className="card all_border">
                            <div className="card-body">
                              <h4 className="card-title">{i.offerName}</h4>
                              <p className="card-title">{i.offerFrequency}</p>
                              <h3 className="card-text">
                                ₹{i.offerPrice} <s>400</s>
                              </h3>
                              <h3 className="card-text">
                                {i.duration} {t("TXT.MIN.LO")}
                              </h3>
                              <h3 className="card-text">
                                {t("USR.ACC.ACT.REM.TL")} {i.availableQty}
                              </h3>
                              {i.expiryDate ? (
                                <p className="card-title mt-1">
                                  {t("USR.ACC.ACT.VAL.TL")} {i.offerEndDate}
                                </p>
                              ) : null}
                              {i.offerDetails
                                ? i.offerDetails.map((x, y) => (
                                    <li
                                      className="list-group-item d-flex justify-content-between align-items-center"
                                      key={y}
                                    >
                                      {x.serviceName}({x.serviceCostType})
                                      <span className="badge bg-primary rounded-pill">
                                        {" "}
                                        {x.count}{" "}
                                        {x.count > 1 ? "times" : "time"}
                                      </span>
                                    </li>
                                  ))
                                : null}
                              <Button
                                className="all_btn"
                                onClick={() => {
                                  handleBookSlot(
                                    i.offerId,
                                    i.serviceId,
                                    i.id,
                                    i.cancelled_count
                                  );
                                  window.sessionStorage.setItem(
                                    "cancelcount",
                                    i.cancelled_count
                                  );
                                }}
                              >
                                {t("USR.BOK.UR.PLN.PRCED")}
                              </Button>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <h4>{t("USR.PR.PRTAB.HTXT")}</h4>
                    )}
                  </div>
                </form>
              </Modal.Body>
            )}

            <Modal.Footer className="homevasthubtn">
              <Button
                className="all_btn"
                onClick={() => setShowReviewModal(false)}
              >
                {t("TXT.CLO.LO")}
              </Button>
              <Button
                className="all_btn"
                onClick={() =>
                  handleBuy(
                    defaultHomeOffer.offerId,
                    defaultHomeOffer.offerPrice,
                    defaultHomeOffer.serviceId
                  )
                }
              >
                {t("USR.BOK.UR.PLN.BUY")}
              </Button>
            </Modal.Footer>
          </Modal>
        </Modal>

        {showPaytm && (
          <BookConsultation
            txnToken={paymentCredentials.txnToken}
            orderId={paymentCredentials.orderId}
            amount={paymentCredentials.amount}
            offerId={paymentCredentials.offerId}
            userId={paymentCredentials.userId}
            navigate={navigate}
            screenName={`/consultantbooklist`}
            consultantId={profObj.consultantUuid}
            slotId={bookSlotId}
            serviceId={defaultHomeOffer.serviceId}
          />
        )}
      </div>
    </div>
  );
};

export default CheckConsultant;
