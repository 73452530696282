import React, { useEffect, useState } from "react";
import { Table, Card, Spinner } from "react-bootstrap";
import NewBootCard from "../component/NewBootCard";
import { vasthuUrl } from "../component/Api";
import { UseAuth } from "../context/Auth";
import { useTranslation } from "react-i18next";

const CustomerPlotRecords = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [plotRecordsList, setplotRecordsList] = useState([
    {
      ownerName: "",
      plotArea: "",
      lagnaName: "",
      directionName: "",
      plotResultValue: "",
    },
  ]);

  // const { userId, token } = UseAuth();
  // const { userId } = UseAuth();
  const langCode = window.localStorage.getItem("i18nextLng");
  const role = window.sessionStorage.getItem("role");
  const token = window.sessionStorage.getItem("token");
  const user = window.sessionStorage.getItem("userId");
  const crmSelectedUser = window.sessionStorage.getItem("crmSelectedUser");
  const userId = token && role == "ROLE_CRM" ? crmSelectedUser : user;

  useEffect(() => {
    try {
      setLoading(true);
      vasthuUrl
        .get(`/getuserallplotreports/${userId}/${langCode}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("RESULTS OF PLOTS", res.data);
          setplotRecordsList(res.data);
          setLoading(false);
        });
    } catch (e) {
      alert("SERVER ERROR");
      setLoading(false);
    }
  }, []);

  return (
    <React.Fragment>
      {!loading ? (
        plotRecordsList.length > 0 ? (
          <>
            <Table
              striped
              bordered
              hover
              responsive
              className="deskTable  text-center all_border"
            >
              <thead>
                <tr>
                  <th>{t("USR.VFPR.TBL.CLMN.OWN")}</th>
                  <th>{t("USR.BD.PLTAB.TBL.CLM.AR")}</th>
                  <th>{t("USR.VFP.DRP.LBL.LG")}</th>
                  <th>{t("USR.BD.PLTAB.TBL.CLM.DR")}</th>
                  <th>{t("USR.VFPR.TBL.CLMN.RS")}</th>
                </tr>
              </thead>
              <tbody>
                {plotRecordsList.map((i, j) => (
                  <tr style={{ textAlign: "center" }} key={j}>
                    <td>{i.ownerName}</td>
                    <td>{i.plotArea}</td>
                    <td>{i.lagnaName}</td>
                    <td>{i.directionName}</td>
                    <td>{i.plotResultValue}</td>
                  </tr>
                ))}
              </tbody>
            </Table>

            <div className=" mobileCard justify-content-center mx-1">
              {plotRecordsList.map((i, j) => (
                <NewBootCard key={j}>
                  <h4>
                    {t("USR.VFPR.TBL.CLMN.OWN")}: {i.ownerName}
                  </h4>
                  <h6>
                    {t("USR.BD.PLTAB.TBL.CLM.AR")}: {i.plotArea}
                  </h6>
                  <h6>
                    {t("USR.VFP.DRP.LBL.LG")}: {i.lagnaName}
                  </h6>
                  <h6>
                    {t("USR.BD.PLTAB.TBL.CLM.DR")}: {i.directionName}
                  </h6>
                  <h6>
                    {t("USR.VFPR.TBL.CLMN.RS")}: {i.plotResultValue}
                  </h6>
                </NewBootCard>
              ))}
            </div>
          </>
        ) : (
          <center>
            <h5>{t("TXT.NO.REC.FUD.LO")}</h5>
          </center>
        )
      ) : (
        <div className="text-center">
          <Spinner animation="border" variant="warning" />
        </div>
      )}
    </React.Fragment>
  );
};

export default CustomerPlotRecords;
