import React from "react";
import { Table } from "react-bootstrap";
import PropTypes from 'prop-types';
import { useTranslation, Trans } from "react-i18next";

const TableDeskPay = (props) => {
  const { t } = useTranslation();
  return (
    <Table striped bordered hover responsive className="deskTable  all_border">
      <thead>
        <tr>
          <th>{t("PR.AC.PMTTAB.TBL.CLMN.TID")}</th>
          <th>{t("TXT.NM.LO")}</th>
          <th>{t("USR.PY.HS.TB.COL2")}</th>
          <th>{t("PR.AC.PMTTAB.TBL.CLMN.TYP")}</th>
        </tr>
      </thead>
      <tbody>
        {props.data.map((i, j) =>
          <tr key={j}>
            <td>{i.tid}</td>
            <td>{i.name}</td>
            <td>{i.amount}</td>
            <td>{i.ptype}</td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

TableDeskPay.propsType = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired
}
export default TableDeskPay;
