import React, { useEffect, useMemo, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { vasthuUrl } from "../component/Api";
import { UseAuth } from "../context/Auth";
import SearchBar from "material-ui-search-bar";
import { Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { format, isToday } from "date-fns";
import {
  Alert,
  Button,
  Card,
  Container,
  Dropdown,
  DropdownButton,
  Form,
  ListGroup,
  Modal,
  Row,
  Spinner,
  Tab,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { Box, Collapse, IconButton, Typography } from "@mui/material";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AdminRefund = () => {
  const Navigate = useNavigate();
  const { t } = useTranslation();
  const { token } = UseAuth();
  const [adminPayData, setAdminPayData] = useState([]);
  const [originalRows, setOriginalRows] = useState([]);
  const [searched, setSearched] = useState("");
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refundObj, setRefundObj] = useState({ txnAmt: 0, orderId: "1" });
  const [refundReason, setRefundReason] = useState("");
  const [refundAmount, setRefundAmount] = useState(0);
  const [message, setMessage] = useState("");
  const [selectDate, setSelectDate] = useState("");
  const [dateLimit, setDateLimit] = useState();
  const [open, setOpen] = useState(false);
  const [refundInfo, setRefundInfo] = useState({
    orderId: "",
    refundId: "",
    referenceId: "",
    refundAmount: 0,
    message: "",
    refundResult: "",
  });
  const [showServiceFilter, setShowServiceFilter] = useState(false);
  const [transData, setTransData] = useState([]);
  console.log("transData", transData);

  useEffect(() => {
    var today = new Date();
    var currentdate = today.getTime();
    console.log(currentdate);
    setSelectDate(currentdate);
    setDateLimit(currentdate);
    let todayDate =
      today.getFullYear() +
      "-" +
      (today.getMonth() + 1) +
      "-" +
      today.getDate();

    try {
      vasthuUrl
        .get(`vasthu/getTransactionByDate/${todayDate}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          res.data.map((i) => (i.open = false));
          console.log(res.data);
          setAdminPayData(res.data);
          setOriginalRows(res.data);
        });
    } catch (error) {
      console.log("SERVER ERROR");
    }

    try {
      vasthuUrl
        .get(`getcurrentpaymenthistory`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setTransData(res.data);
        });
    } catch (error) {
      console.log("SERVER ERROR");
    }
  }, [open]);

  const alert = (msg) => {
    setMessage(msg);
    setTimeout(() => {
      setMessage("");
    }, 4000);
  };

  const getPaymentByDate = async (date) => {
    try {
      vasthuUrl
        .get(`vasthu/getTransactionByDate/${date}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          res.data.map((i) => (i.open = false));
          console.log(res.data);
          setAdminPayData(res.data);
          setOriginalRows(res.data);
        });
    } catch (error) {
      console.log("SERVER ERROR");
    }
  };

  const showRefundDetails = async (orderId) => {
    try {
      await vasthuUrl
        .get(`vasthu/getRefundInfo/${orderId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("REFUND DETAILS", res.data);
          setRefundInfo({
            orderId: res.data.orderId,
            refundId: res.data.refundId,
            referenceId: res.data.referenceId,
            refundAmount: res.data.refundAmount,
            message: res.data.message,
            refundResult: res.data.refundResultStatus,
          });
        });
    } catch (error) {
      console.log("SERVER ERROR");
    }
    setShowRefundModal(true);
    setLoading(false);
  };

  const initiateRefund = (amount, orderNo) => {
    setLoading(true);
    setRefundObj({ txnAmt: amount, orderId: orderNo });
    setShowReviewModal(true);
    setLoading(false);
  };

  const makeRefund = async (orderId, amt, reason) => {
    try {
      await vasthuUrl
        .post(
          `vasthu/requestForRefund`,
          {
            orderId: orderId,
            refundAmount: amt,
            refundReason: reason,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(async (res) => {
          alert(res.data.message);

          setShowReviewModal(false);
          setOpen(true);
          Navigate("/");
        });
    } catch (error) {
      alert("SERVER ERROR");
    }
  };

  const columns = [
    { field: "offerName", headerName: "OFFERNAME", width: 150 },
    { field: "txnAmount", headerName: "AMOUNT", width: 100 },
    { field: "bankName", headerName: "BANK NAME", width: 150 },
    { field: "txnDate", headerName: "PURCHASED DATE", width: 200 },
    { field: "orderId", headerName: "ORDER ID", width: 180 },
    { field: "status", headerName: "TRANSACTION STATUS", width: 100 },
  ];

  // const transData = [
  //   {
  //     id: 1,
  //     offerName: "Vasthu",
  //     txnAmount: "100",
  //     bankName: "IC5",
  //     txnDate: "18-09-2024",
  //     orderId: "123456",
  //     status: "success",
  //   },
  //   {
  //     id: 2,
  //     offerName: "Festive Offer",
  //     txnAmount: "200",
  //     bankName: "SBI",
  //     txnDate: "19-09-2024",
  //     orderId: "123457",
  //     status: "failed",
  //   },
  //   {
  //     id: 3,
  //     offerName: "Cashback",
  //     txnAmount: "150",
  //     bankName: "HDFC",
  //     txnDate: "20-09-2024",
  //     orderId: "123458",
  //     status: "success",
  //   },
  //   {
  //     id: 4,
  //     offerName: "Discount",
  //     txnAmount: "75",
  //     bankName: "AXIS",
  //     txnDate: "21-09-2024",
  //     orderId: "123459",
  //     status: "pending",
  //   },
  //   {
  //     id: 5,
  //     offerName: "Festival Deal",
  //     txnAmount: "500",
  //     bankName: "ICICI",
  //     txnDate: "22-09-2024",
  //     orderId: "123460",
  //     status: "success",
  //   },
  // ];

  return (
    // <Container>
    //   {message && (
    //     <Alert variant="info" className="text-center">
    //       {message}
    //     </Alert>
    //   )}
    //   <div style={{ display: "flex", flexDirection: "row" }}>
    //     <p style={{ paddingRight: "10px" }}>Date:</p>
    //     <DatePicker
    //       selected={selectDate}
    //       className="pay_date_picker"
    //       onChange={(date) => {
    //         console.log("DATE SELECTED" + date);
    //         let todayDate =
    //           date.getFullYear() +
    //           "-" +
    //           (date.getMonth() + 1) +
    //           "-" +
    //           date.getDate();
    //         setSelectDate(date);
    //         getPaymentByDate(todayDate);
    //         setShowServiceFilter(false);
    //       }}
    //       dateFormat="yyyy-MM-dd"
    //       minDate="2020-01-01"
    //       maxDate={dateLimit}
    //       placeholderText="I have been cleared!"
    //     />

    //     {/* <Dropdown>
    // <DropdownButton id="dropdown-basic-button" title="Sort By Service" onClick={()=> setShowServiceFilter(true)}>
    //   <Dropdown.Item href="#/action-1">Plot Vasthu</Dropdown.Item>
    //   <Dropdown.Item href="#/action-2">Home Vasthu</Dropdown.Item>
    //   <Dropdown.Item href="#/action-3">Vasthu Consultation</Dropdown.Item>
    //   <Dropdown.Item href="#/action-3">Astrologer Consultation</Dropdown.Item>
    //   <Dropdown.Item href="#/action-3">Building Consultation</Dropdown.Item>
    // </DropdownButton>
    // </Dropdown> */}
    //   </div>
    //   {!showServiceFilter ? (
    //     <div className="all_border_pay">
    //       <SearchBar
    //         className="mt-3 border mb-3"
    //         placeholder="Search with OrderId only"
    //         value={searched}
    //         onChange={(searchVal) => {
    //           if (searchVal.length > 0) {
    //             setAdminPayData(
    //               adminPayData.filter((row) =>
    //                 row.orderId.toLowerCase().includes(searchVal.toLowerCase())
    //               )
    //             );
    //           } else {
    //             setAdminPayData(originalRows);
    //           }
    //         }}
    //       />
    //       <TableContainer component={Paper}>
    //         <Table className="all_border" sx={{ minWidth: 650 }}>
    //           <TableHead>
    //             <TableRow>
    //               <TableCell></TableCell>
    //               <TableCell>OrderId</TableCell>
    //               <TableCell align="right">User Name</TableCell>
    //               <TableCell align="right">{t("TXT.OFF.NM.LO")}</TableCell>
    //               <TableCell align="right">Paid Amount</TableCell>
    //               <TableCell align="right">Payment Mode</TableCell>
    //             </TableRow>
    //           </TableHead>
    //           {adminPayData.map((row, k) => (
    //             <TableBody key={k}>
    //               <TableRow
    //                 sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    //               >
    //                 <TableCell>
    //                   <IconButton
    //                     aria-label="expand row"
    //                     size="small"
    //                     onClick={() => {
    //                       row.open = !row.open;
    //                       setAdminPayData([...adminPayData]);
    //                     }}
    //                   >
    //                     {row.open ? <TiArrowSortedUp /> : <TiArrowSortedDown />}
    //                   </IconButton>
    //                 </TableCell>
    //                 <TableCell component="th" scope="row">
    //                   {row.orderId}
    //                 </TableCell>
    //                 <TableCell align="right">{row.userName}</TableCell>
    //                 <TableCell align="right">{row.offerName}</TableCell>
    //                 <TableCell align="right">{row.txnAmount}</TableCell>
    //                 <TableCell align="right">{row.paymentMode}</TableCell>
    //               </TableRow>
    //               <TableRow key={k}>
    //                 <TableCell
    //                   style={{ paddingBottom: 0, paddingTop: 0 }}
    //                   colSpan={6}
    //                 >
    //                   <Collapse in={row.open} timeout="auto" unmountOnExit>
    //                     <Box sx={{ margin: 1 }}>
    //                       <Typography variant="h6" gutterBottom component="div">
    //                         Details
    //                       </Typography>
    //                       <Table size="small" aria-label="purchases">
    //                         <TableHead>
    //                           <TableRow>
    //                             <TableCell>TxnDate</TableCell>
    //                             <TableCell>Bank</TableCell>
    //                             <TableCell align="right">TxnId</TableCell>
    //                             <TableCell align="right">
    //                               Refund Amount
    //                             </TableCell>
    //                           </TableRow>
    //                         </TableHead>
    //                         <TableBody>
    //                           {/* {row.history.map((historyRow) => ( */}
    //                           <TableRow>
    //                             <TableCell component="th" scope="row">
    //                               {row.txnDate}
    //                             </TableCell>
    //                             <TableCell>{row.bankName}</TableCell>
    //                             <TableCell align="right">{row.txnId}</TableCell>
    //                             <TableCell align="right">
    //                               {row.refundAmt}
    //                             </TableCell>
    //                             {/* <TableCell align="right">
    //                               {row.refundRequested ? (
    //                                 <Button
    //                                   variant="info"
    //                                   size="sm"
    //                                   onClick={() =>
    //                                     showRefundDetails(row.orderId)
    //                                   }
    //                                 >
    //                                   ShowRefund
    //                                 </Button>
    //                               ) : (
    //                                 <Button
    //                                   variant="primary"
    //                                   size="sm"
    //                                   onClick={() =>
    //                                     initiateRefund(
    //                                       row.txnAmount,
    //                                       row.orderId
    //                                     )
    //                                   }
    //                                 >
    //                                   Make Refund
    //                                 </Button>
    //                               )}
    //                             </TableCell> */}
    //                           </TableRow>
    //                           {/* ))} */}
    //                         </TableBody>
    //                       </Table>
    //                     </Box>
    //                   </Collapse>
    //                 </TableCell>
    //               </TableRow>
    //             </TableBody>
    //           ))}
    //         </Table>
    //       </TableContainer>
    //     </div>
    //   ) : (
    //     <TableContainer component={Paper}>
    //       <Table sx={{ minWidth: 650 }} aria-label="simple table">
    //         <TableHead>
    //           <TableRow>
    //             <TableCell>ORDER ID</TableCell>
    //             <TableCell align="right">USER NAME</TableCell>
    //             <TableCell align="right">AMOUNT</TableCell>
    //             <TableCell align="right">{t("TXT.OFF.NM.UP")}</TableCell>
    //           </TableRow>
    //         </TableHead>
    //         <TableBody>
    //           {/* {rows.map((row) => ( */}
    //           <TableRow
    //             key={0}
    //             sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    //           >
    //             <TableCell component="th" scope="row">
    //               f4f4ORDER$%
    //             </TableCell>

    //             <TableCell align="right">aawsoft</TableCell>
    //             <TableCell align="right">58</TableCell>
    //             <TableCell align="right">Vasthu Basic Combo</TableCell>
    //           </TableRow>
    //           {/* ))} */}
    //         </TableBody>
    //       </Table>
    //     </TableContainer>
    //   )}

    //   <Modal
    //     show={showReviewModal}
    //     onHide={() => {
    //       setShowReviewModal(false);
    //       setRefundAmount(0);
    //       setRefundReason("");
    //       setRefundObj({ txnAmt: 0, orderId: "1" });
    //     }}
    //     size="lg"
    //     keyboard={false}
    //     aria-labelledby="contained-modal-title-vcenter"
    //     centered
    //   >
    //     <Modal.Header closeButton>
    //       <Modal.Title id="contained-modal-title-vcenter">
    //         Refund Form for {refundObj.orderId}
    //       </Modal.Title>
    //     </Modal.Header>

    //     {loading ? (
    //       <center>
    //         <Spinner animation="border" variant="primary" />
    //       </center>
    //     ) : (
    //       <Modal.Body>
    //         <Form>
    //           <Form.Group className="mb-3">
    //             <Form.Label>Transaction Amount</Form.Label>
    //             <Form.Control
    //               placeholder="Disabled input"
    //               disabled
    //               value={refundObj.txnAmt}
    //             />
    //           </Form.Group>

    //           <Form.Group className="mb-3" controlId="formBasicEmail">
    //             <Form.Label>Reason</Form.Label>
    //             <Form.Control
    //               type="text"
    //               placeholder="...."
    //               value={refundReason}
    //               onChange={(e) => setRefundReason(e.target.value)}
    //             />
    //             <Form.Text className="text-muted">
    //               Reason for Making this Refund.
    //             </Form.Text>
    //           </Form.Group>

    //           <Form.Group className="mb-3" controlId="formBasicPassword">
    //             <Form.Label>Refund Amount</Form.Label>
    //             <Form.Control
    //               type="number"
    //               placeholder="Amount"
    //               value={refundAmount}
    //               onChange={(e) => setRefundAmount(e.target.value)}
    //             />
    //             <Form.Text className="text-muted">
    //               Amount should be lesser than Transaction Amount.
    //             </Form.Text>
    //           </Form.Group>

    //           <Button
    //             className="all_btn"
    //             type="submit"
    //             onClick={(e) => {
    //               e.preventDefault();
    //               makeRefund(refundObj.orderId, refundAmount, refundReason);
    //               setShowReviewModal(false);
    //               setRefundAmount(0);
    //               setRefundReason("");
    //               setRefundObj({ txnAmt: 0, orderId: "1" });
    //             }}
    //           >
    //             Submit
    //           </Button>

    //           <Button
    //             className="all_btn"
    //             onClick={() => {
    //               setShowReviewModal(false);
    //               setRefundAmount(0);
    //               setRefundReason("");
    //               setRefundObj({ txnAmt: 0, orderId: "1" });
    //             }}
    //           >
    //             Close
    //           </Button>
    //         </Form>
    //       </Modal.Body>
    //     )}
    //   </Modal>
    //   <Modal
    //     show={showRefundModal}
    //     onHide={() => {
    //       setShowRefundModal(false);
    //     }}
    //     size="lg"
    //     keyboard={false}
    //     aria-labelledby="contained-modal-title-vcenter"
    //     centered
    //   >
    //     <Modal.Header closeButton>
    //       <Modal.Title id="contained-modal-title-vcenter">
    //         Refund Information of {refundInfo.orderId}
    //       </Modal.Title>
    //     </Modal.Header>

    //     {loading ? (
    //       <center>
    //         <Spinner animation="border" variant="warning" />
    //       </center>
    //     ) : (
    //       <Modal.Body>
    //         <ListGroup>
    //           <ListGroup.Item>
    //             {" "}
    //             <strong>Reference Id&nbsp;:</strong>&nbsp;
    //             {refundInfo.referenceId}
    //           </ListGroup.Item>
    //           <ListGroup.Item>
    //             {" "}
    //             <strong>Refund Id&nbsp;:</strong>&nbsp;{refundInfo.refundId}
    //           </ListGroup.Item>
    //           <ListGroup.Item>
    //             {" "}
    //             <strong>Refund Amount&nbsp;:</strong>&nbsp;
    //             {refundInfo.refundAmount}
    //           </ListGroup.Item>
    //           <ListGroup.Item>
    //             {" "}
    //             <strong>Message&nbsp;:</strong>&nbsp;{refundInfo.message}
    //           </ListGroup.Item>
    //           <ListGroup.Item>
    //             {" "}
    //             <strong>Result Status&nbsp;:</strong>&nbsp;
    //             {refundInfo.refundResult}
    //           </ListGroup.Item>
    //         </ListGroup>
    //         <Button
    //           className="all_btn"
    //           onClick={() => setShowRefundModal(false)}
    //         >
    //           Close
    //         </Button>
    //       </Modal.Body>
    //     )}
    //   </Modal>
    // </Container>
    <Container>
      <div>
        {transData.length > 0 ? (
          <>
            <center className="datagrid">
              <Grid container className="deskTable">
                <DataGrid
                  className="all_border - text-center me-3 data_grid"
                  rows={transData}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: 10 },
                    },
                  }}
                  pageSizeOptions={[5, 10]}
                  getRowId={(row) => row.orderId}
                />
              </Grid>
            </center>

            <div className="mobileCard">
              {transData.map((i, j) => (
                <Card
                  className="mobileCard all_border"
                  key={j}
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    backgroundColor: "antiquewhite",
                  }}
                >
                  <Card.Body>
                    <Card.Subtitle className="mb-2">
                      <b>{t("TXT.OFF.NM.LO")}:</b> {i.offerName}
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2">
                      <b>Amount:</b> {i.txnAmount}
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2">
                      <b>Bank Name:</b> {i.bankName}
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2">
                      <b>Purchased Date:</b>
                      {i.txnDate}
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2">
                      <b>Order Id:</b> {i.orderId}
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2">
                      <b> Transaction Status:</b> {i.status}
                    </Card.Subtitle>
                  </Card.Body>
                </Card>
              ))}
            </div>
          </>
        ) : (
          <center>
            <h5>{t("TXT.NO.REC.FUD.LO")}</h5>
          </center>
        )}
      </div>
    </Container>
  );
};

export default AdminRefund;
