import React, { useState, useEffect } from "react";
import { vasthuUrl } from "../component/Api";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import NewBootCard from "../component/NewBootCard";
import { Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { format } from 'date-fns';

const AudioCallAdmin = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [audiocallHistoryList, setAudiocallHistoryList] = useState([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [activeStatusId, setActiveStatusId] = useState(null);
  const [changingStatus, setChangingStatus] = useState("");
  const [changedStatus, setChangedStatus] = useState("");
  const token = window.sessionStorage.getItem("token");

  // Fetch audio call history
  useEffect(() => {
    const fetchAudioCallHistory = async () => {
      try {
        const response = await vasthuUrl.get(
          `vasthu/user/getAudioCallHistory`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setAudiocallHistoryList(response.data);
      } catch (error) {
        console.error("Error fetching call history:", error);
      }
    };

    fetchAudioCallHistory();
  }, [changedStatus, token]);

  const openModal = (id) => {
    setIsOpen(true);
    setActiveStatusId(id);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleStatusChange = async (event) => {
    event.preventDefault();
    closeModal();
    try {
      const response = await vasthuUrl.post(
        `vasthu/user/updateAudioCallStatus`,
        { activeStatusId, changingStatus },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      if (response.status === 200) {
        alert(`Status successfully changed to ${response.data.status}`);
        setChangedStatus(response.data.status);
      }
    } catch (error) {
      alert("Error updating status. Please check your internet connection.");
    }
  };

  const column = [
    // { field: "id", headerName: "ID", width: 90 },
    { field: "callTo", headerName: "Client", width: 150 },
    { field: "callFrom", headerName: "Consultant", width: 130 },
    { field: "duration", headerName: "Duration", width: 100 },
    {
      field: "start_time",
      headerName: "Date",
      width: 150,
      renderCell: (params) => {
        const date = new Date(params.row.start_time);
        return format(date, "dd-MM-yyyy HH:mm");
      },
    },

    {
      field: "proposedCallDuration",
      headerName: "Proposed Duration",
      width: 150,
    },
    // { field: "offerName" ?offerName:"walletcall" , headerName: "offer", width: 150 },
    {
      field: "offerName",
      headerName: "Offer",
      width: 150,
      renderCell: (params) =>
        params.row.offerName ? params.row.offerName : "Wallet Call",
    },
    { field: "status", headerName: "Status", width: 100 },
    // {
    //   field: "actions",
    //   headerName: "CHANGE STATUS",
    //   type: "actions",
    //   renderCell: (params) => (
    //     <Button
    //       className="mx-1 all_btn"
    //       onClick={() => openModal(params.row.id)}
    //     >
    //       Change
    //     </Button>
    //   ),
    //   width: 150,
    // },
  ];

  return (
    <React.Fragment>
      <div>
        <center>
          <u>
            <h2>{t("USR.AUDCH.TEXT1")}</h2>
          </u>
        </center>
      </div>
      <center className="datagrid">
        <Grid container className="deskTable">
          <DataGrid
            className="all_border text-center me-3 data_grid"
            rows={[...audiocallHistoryList].reverse()}
            columns={column}
            initialState={{
              pagination: { paginationModel: { page: 0, pageSize: 5 } },
            }}
            pageSizeOptions={[5, 10]}
            getRowId={(row) => row.id}
          />
        </Grid>
      </center>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Change Status"
      >
        <h2 className="text-center all_background">Change Status</h2>
        <form onSubmit={handleStatusChange}>
          <div
            className="text-center"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <select
              onChange={(e) => setChangingStatus(e.target.value)}
              defaultValue=""
            >
              <option value="" disabled>
                Select Status
              </option>
              <option value="Started">Started</option>
              <option value="inprogress">In Progress</option>
              <option value="onhold">On Hold</option>
              <option value="cancelled">Cancelled</option>
              <option value="completed">Completed</option>
            </select>

            <input
              className="all_btn"
              style={{ marginTop: "10px" }}
              type="submit"
              value="Submit"
            />
          </div>
          <center>
            <Button
              className="all_btn"
              size="sm"
              style={{ width: "100%", marginTop: "8px" }}
              onClick={closeModal}
            >
              {t("TXT.BTN.CAN.LO")}
            </Button>
          </center>
        </form>
      </Modal>

      <div className="mobileCard justify-content-center mx-1">
        {audiocallHistoryList.map((item, index) => (
          <NewBootCard className="p-3 mb-2 bg-secondary text-white" key={index}>
            <h4 className="text-center">
              {"Client"}: {item.callTo}
            </h4>
            <hr />
            <h6>
              {"Consultant"}: {item.callFrom}
            </h6>
            <h6>
              {"Duration"}: {item.duration}
            </h6>
            <h6>
              {"Proposed Duration"}: {item.proposedCallDuration}
            </h6>
            <h6>
              {"Offer"}: {item.offerName}
            </h6>
            <h6>
              {"Status"}: {item.status}
            </h6>
            {/* <Button className="mx-1 all_btn" onClick={() => openModal(item.id)}>
              Change
            </Button> */}
          </NewBootCard>
        ))}
      </div>
    </React.Fragment>
  );
};

export default AudioCallAdmin;
