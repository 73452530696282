import React from "react";
import { Route, Routes } from "react-router-dom";
import { VasthuProvider } from "../context/vasthu";
import NoMatch from "../pages/Nopage";
import UserHome from "../pages/userHome";


import Appointment from "../pages/Appointment";
import HomeForm from "../pages/CustomerHomeForm";
import PlotForm from "../pages/CustomerPlotForm";
import HomeFloor from "../pages/HomeFormScreen";
import HomeLevel from "../pages/HomeLevel";
import HomeValidation from "../pages/HomeValidation";
import HomeWindow from "../pages/HomeWindow";
import PlotScreen from "../pages/PlotScreen";
import Profile from "../pages/Profile";
import Settings from "../pages/Settings";
import VasthuParamReview from "../pages/VasthuParamReview";

import CustomerDash from "../pages/CustomerDash";
import CustomerPricing from "../pages/CustomerPricing";
import HomeMiscellaneous from "../pages/HomeMiscellaneous";

import AudioCallStatus from "../component/AudioCallStatus";
import DirectCall from "../component/DirectCall";
import { PaymentProvider } from "../context/PaymentContext";
import AppointTableDesk from "../pages/AppointTableDesk";
import AudioCall from "../pages/AudioCall";
import AudioCallStart from "../pages/AudioCallStart";
import BillPaySuccess from "../pages/BillPaySuccess";
import CheckConsultant from "../pages/CheckConsultant";
import CopyRights from "../pages/CopyRights";
import CustomerAccount from "../pages/CustomerAccount";
import CustomerBooking from "../pages/CustomerBooking";
import CustomerOneTimePricing from "../pages/CustomerOneTimeService";
import CustomerPayment from "../pages/CustomerPayment";
import CustomerPeriodicPricing from "../pages/CustomerPeriodicService";
import CustomerRecord from "../pages/CustomerRecords";
import Disclaimer from "../pages/Disclaimer";
import MyRefunds from "../pages/MyRefunds";
import PaymentResponse from "../pages/paymentresponse";
import PaymentStatusBilldesk from "../pages/PaymentStatusBilldesk";
import PlotScreenFree from "../pages/PlotScreenFree";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import ProfileForm from "../pages/ProfileForm";
import TermConditions from "../pages/TermsConditions";
import AstroUserDetails from "../pages/User/AstroUserDetails";
import UserAppointHistory from "../pages/UserAppointHistory";
import CustomerReview from "../pages/UserAppReview";
import UserMain from "../pages/UserMain";
import VasthuArchitects from "../pages/VasthuArchitects";
import VasthuAstrolgers from "../pages/VasthuAstrolgers";
import VasthuExperts from "../pages/VasthuExperts";
import VasthuParamUserRefund from "../pages/VasthuParamUserRefund";
import VideoCallReceiver from "../videocall/receiver/VideoCallReceiver";
// import UserProduct from "../pages/UserProduct";
import BookConsultant from "../pages/BookConsultant";
import ChatHistory from "../pages/ChatHistory";
import DirectCallHome from "../pages/DirectCallHome";
import OurCallServices from "../pages/OurCallServices";
import ScheduleBooking from "../pages/ScheduleBooking";
import SocialHome from "../pages/SocialHome";
import SocialOffer from "../pages/SocialOffer";
import TalkNow from "../pages/TalkNow";
import UserBookedProduct from "../pages/User/UserBookedProduct";
import UserLikedProduct from "../pages/User/UserLikedProduct";
import UserProduct from "../pages/User/UserProduct";
import UserProductDetail from "../pages/User/UserProductDetail";
import WalletBooking from "../pages/WalletBooking";
import WalletOffer from "../pages/WalletOffer";
import WalletPaymentProcess from "../pages/WalletPaymentProcess";
import ChatSender from "../videocall/sender/ChatSender";
import OurServicesConsultant from "../pages/OurServicesConsultant";
import ChatRoom from "../pages/ChatRoom";

const UserRoute = () => {
  return (
    <VasthuProvider>
      <PaymentProvider>
        <Routes>
          <Route exact path="/" element={<UserMain />}>
            <Route exact index element={<UserHome />} />
            <Route path="/book" element={<SocialHome />} />
            <Route path="/call" element={<SocialHome />} />
            <Route path="/book/:id" element={<SocialHome />} />
            <Route path="/call/:id" element={<SocialHome />} />
            <Route path="/talktoconsultant" element={<TalkNow />} />
            <Route path="/ourcallservices" element={<OurCallServices />} />
            <Route path="/directcallhome" element={<DirectCallHome />} />
            <Route path="/schedulebooking" element={<ScheduleBooking />} />
            <Route path="/walletbooking" element={<WalletBooking />} />
            <Route path="/walletoffer" element={<WalletOffer />} />
            <Route path="/walletpaymentprocess" element={<WalletPaymentProcess />} />
            <Route path="/:id" element={<SocialOffer />} />
            <Route path="dashboard" element={<CustomerDash />} />
            <Route path="profile" element={<Profile />} />
            <Route path="settings" element={<Settings />} />
            <Route path="buildingdetails" element={<CustomerRecord />} />
            <Route path="customerpricing" element={<CustomerPricing />} />
            <Route path="appointment" element={<Appointment />} />
            <Route path="customeraccount" element={<CustomerAccount />} />
            <Route path="plotvasthu" element={<PlotForm />} />
            <Route path="homevasthu" element={<HomeForm />} />
            <Route path="homefloor" element={<HomeFloor />} />
            <Route path="homemiscellaneous" element={<HomeMiscellaneous />} />
            <Route path="homewindow" element={<HomeWindow />} />
            <Route path="homelevel" element={<HomeLevel />} />
            <Route path="consultantbook" element={<CustomerBooking />} />
            <Route path="astroUserDetails" element={<AstroUserDetails />} />
            <Route path="consultantbooking" element={<BookConsultant />} />
            <Route path="checkconsultant" element={<CheckConsultant />} />
            <Route path="consultantbooklist" element={<AppointTableDesk />} />
            <Route path="appointmenthistory" element={<UserAppointHistory />} />
            <Route path="customerpayment" element={<CustomerPayment />} />
            <Route path="myrefunds" element={<MyRefunds />} />
            <Route path="paymentresponse" element={<PaymentResponse />} />
            <Route path="vasthuexpert" element={<VasthuExperts />} />
            <Route path="vasthuarchitects" element={<VasthuArchitects />} />
            <Route path="vasthuastrolgers" element={<VasthuAstrolgers />} />
            
            <Route
              path="customerPeriodicPricing"
              element={<CustomerPeriodicPricing />}
            />
            <Route
              path="customerOneTimePricing"
              element={<CustomerOneTimePricing />}
            />
            <Route
              path="paymentstatusbilldesk"
              element={<PaymentStatusBilldesk />}
            />
            <Route path="customerreview" element={<CustomerReview />} />
            <Route path="customerprofile" element={<ProfileForm />} />
            <Route path="consultantList" element={<CustomerBooking />} />
            <Route path="plotvalidate" element={<PlotScreen />} />
            <Route path="plotvalidatefree" element={<PlotScreenFree />} />
            <Route path="homevalidation" element={<HomeValidation />} />
            <Route path="audiocall" element={<AudioCall />} />
            <Route path="chathistory" element={<ChatHistory />} />
            <Route path="audiocallstart" element={<AudioCallStart />} />
            <Route path="audiocallstatus" element={<AudioCallStatus />} />
            <Route path="product" element={<UserProduct />} />
            <Route path="likedproduct" element={<UserLikedProduct />} />
            <Route path="productdetails" element={<UserProductDetail />} />
            <Route path="bookedproduct" element={<UserBookedProduct />} />
            <Route path="directcall" element={<DirectCall />} />
            <Route path="vasthureview" element={<VasthuParamReview />} />
            <Route path="ourservicesconsultant" element={<OurServicesConsultant />} />
            <Route
              path="vasthuparamuserrefund"
              element={<VasthuParamUserRefund />}
            />
          </Route>
          <Route path="chatscreen" element={<ChatRoom />} />
          <Route path="receivecall" element={<VideoCallReceiver />} />
          <Route path="startchat" element={<ChatSender />} />
          <Route path="disclaimer" element={<Disclaimer />} />
          <Route path="privacypolicy" element={<PrivacyPolicy />} />
          <Route path="termcondition" element={<TermConditions />} />
          <Route path="paymentstatusbilldesk" element={<BillPaySuccess />} />
          <Route path="copyrights" element={<CopyRights />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </PaymentProvider>
    </VasthuProvider>
  );
};

export default UserRoute;
