import React, { useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import {useLocation} from 'react-router-dom';

const PaymentStatusBilldesk = () => {
    const location = useLocation();

    // useEffect(()=>{window.close()},[])



console.log(location)
    return(
        // <div onLoad={document.getElementById("SubmitForm").onLoad=window.close()}>
        // <div onLoad={window.close()}>
        <div> 
            <h3>{location.state}</h3>           
        </div>
    )
} 
export default PaymentStatusBilldesk;
