import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Container,
  Spinner,
  Table,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { vasthuUrl } from "../component/Api";
import FeedBack from "../component/FeedBack";

const UserAppointHistory = () => {
  const token = window.sessionStorage.getItem("token");
  const role = window.sessionStorage.getItem("role");
  const user = window.sessionStorage.getItem("userId");
  const { t } = useTranslation();
  const crmSelectedUser = window.sessionStorage.getItem("crmSelectedUser");
  const [userMeetingHistoryList, setUserMeetingHistoryList] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [profDetails, setProfDetails] = useState({
    profName: "Professional",
    profUuid: "abc12",
    profSlotId: 123,
  });
  const userId = token && role == "ROLE_CRM" ? crmSelectedUser : user;

  useEffect(() => {
    setLoading(true);
    try {
      vasthuUrl
        .get(`getoveralluserbookinghistory/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          setUserMeetingHistoryList(res.data);
          setLoading(false);
        });
    } catch (e) {
      console.log("SERVER ERROR");
      setLoading(false);
    }
  }, []);

  const showModal = (i) => {
    setOpenModal(true);
  };

  return (
    <Container>
      <div>
        <center>
          <h2>{t("USR.HOM.HD.BOOKH")}</h2>
        </center>
      </div>
      {!loading ? (
        userMeetingHistoryList.length > 0 ? (
          <>
            <Table
              striped
              bordered
              hover
              responsive
              className="text-center all_border deskTable"
            >
              <thead>
                <tr>
                  <th>{t("TXT.CON.LOCA")}</th>
                  <th>{t("TXT.CAL.DAT.UP")}</th>
                  <th>{t("PR.USRMTNG.TBL.CLMN.CT.CMM")}</th>
                  {/* <th>{t("PR.APMNT.MTNGTAB.TBL.CLMN.BS")}</th> */}
                  <th>{t("TXT.CAL.STS.UP")}</th>
                  <th>{t("USR.CALL.CCL.RS")}</th>
                  <th>{t("USR.CAL.BK.BY")}</th>
                  <th>{t("USR.CAL.CCL.BY")}</th>
                  <th>{t("USR.CON.COL.FB")}</th>
                  {/* <th>{t("TXT.RE.SCH.UP")}</th> */}
                </tr>
              </thead>
              <tbody>
                {userMeetingHistoryList.map((i, j) => (
                  <tr key={j}>
                    <td>{i.name}</td>
                    <td>{i.callDate}</td>
                    <td>{i.callTime}</td>
                    <td>
                      {i.serviceStatus == "ACTIVE"
                        ? "UPCOMING"
                        : i.serviceStatus}
                    </td>
                    <td>{i.cancelReason !== null ? i.cancelReason : "NA"}</td>
                    <td>{i.bookedBy !== null ? i.bookedBy : "-"}</td>
                    <td>{i.cancelledBy !== null ? i.cancelledBy : "-"}</td>
                    <td>
                      {i.serviceStatus == "COMPLETED" ||
                      i.serviceStatus == "PARTIALLY_COMPLETED" ? (
                        <Button
                          variant={
                            i.serviceStatus == "UPCOMING"
                              ? "outline-secondary"
                              : "outline-warning"
                          }
                          onClick={() => {
                            console.log("II108", i);
                            showModal();
                            setProfDetails(i);
                          }}
                          className="mt-2"
                          disabled={
                            i.serviceStatus == "COMPLETED"
                              ? false
                              : i.serviceStatus == "PARTIALLY_COMPLETED"
                              ? false
                              : true
                          }
                        >
                          {t("USR.HOM.REV.TEXT4")}
                        </Button>
                      ) : (
                        "-"
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <div className="justify-content-center mobileCard">
              {userMeetingHistoryList.map((i, j) => (
                <Card
                  key={j}
                  className="mobileCard all_border"
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    backgroundColor: "antiquewhite",
                  }}
                >
                  <Card.Body>
                    <Card.Subtitle className="mb-2">
                      <b>{t("TXT.CAL.HIS.PF")}:</b> {i.name}
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2">
                      <b>{t("TXT.CAL.DAT.UP")}:</b> {i.callDate}
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2">
                      <b> {t("PR.USRMTNG.TBL.CLMN.CT.CMM")}:</b> {i.callTime}
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2">
                      <b>{t("TXT.CAL.STS.UP")}:</b> {i.serviceStatus}
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2">
                      <b>{t("USR.CALL.CCL.RS")}:</b>{" "}
                      {i.cancelReason !== null ? i.cancelReason : "NA"}
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2">
                      <b>{t("USR.CAL.BK.BY")}:</b>
                      {i.bookedBy !== null ? i.bookedBy : "-"}
                    </Card.Subtitle>
                    <Card.Subtitle className="mb-2">
                      <b>{t("USR.CAL.CCL.BY")}:</b>{" "}
                      {i.cancelledBy !== null ? i.cancelledBy : "-"}
                    </Card.Subtitle>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "0.5rem",
                      }}
                    >
                      {i.serviceStatus == "COMPLETED" ||
                      i.serviceStatus == "PARTIALLY_COMPLETED" ? (
                        <Button
                          variant={
                            i.serviceStatus == "UPCOMING"
                              ? "outline-secondary"
                              : "outline-warning"
                          }
                          onClick={() => {
                            console.log("II", i);
                            showModal();
                            setProfDetails(i);
                          }}
                          className="mt-2"
                          disabled={
                            i.serviceStatus == "COMPLETED"
                              ? false
                              : i.serviceStatus == "PARTIALLY_COMPLETED"
                              ? false
                              : true
                          }
                        >
                          {t("USR.HOM.REV.TEXT4")}
                        </Button>
                      ) : null}
                    </div>
                  </Card.Body>
                </Card>
              ))}
            </div>
          </>
        ) : (
          <div>
            <center>
              <h5>{t("USR.COM.ACK.TEXT1")}</h5>
            </center>
          </div>
        )
      ) : (
        <div className="text-center">
          <Spinner animation="border" variant="warning" />
        </div>
      )}

      {openModal ? <FeedBack details={profDetails} params={true} /> : null}
    </Container>
  );
};

export default UserAppointHistory;
