import React, { StrictMode, Suspense } from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";
import Spinner from "react-bootstrap/Spinner";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import "react-datepicker/dist/react-datepicker.css";
// Import FilePond styles
import "filepond/dist/filepond.min.css";
import LangContext from "./context/LangContext";

import "bootstrap/dist/css/bootstrap.min.css";
import '@fortawesome/fontawesome-free/css/all.min.css';

import { AuthProvider } from "./context/Auth";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <Suspense fallback={<h1>Loading..</h1>}>
  <Suspense
    fallback={
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "20%"
        }}
      >
        <Spinner animation="border" variant="warning" />
      </div>
    }
  >
    <BrowserRouter>
      <AuthProvider>
        <LangContext>
          <App />
        </LangContext>
      </AuthProvider>
    </BrowserRouter>
  </Suspense>
);

// // If you want your app to work offline and load faster, you can change
// // unregister() to register() below. Note this comes with some pitfalls.
// // Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register();

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
