import React from "react";
import Title from "../component/Title";
import { Row, Container } from "react-bootstrap"

const Disclaimer = () => {
  return (
    <Container>
      <Title title="Disclaimer" />
      <Row className="m-1 p-2 border  border-warning" style={{ height: "auto", minHeight: 200 }}>
        <p>• Vasthu and Astrology guidelines are only view of consultant based on
          rules of the subjects so we are not responsible for final result and
          accuracy at any cost. All critical risks and output Results belong to
          you and your own effort .</p>
        <p>
          • The information and data contained on <strong>Vasthuparam</strong> website is to be treated purely for your analysis purposes only. Any prediction or other message that you receive is not a substitute for advice, programs, or treatment that you would normally receive from the website and our consultant that is only opinion such as other professional fields.  Accordingly, Vasthuparam.com provides no guarantees, implied warranties, or assurances of any kind, and will not be responsible for any interpretation made or use by the recipient of the information and data mentioned above.</p>
        {/* <p> • Moreover, Vasthuparam.com is not a registered firm. It is a product of  <span style={{ color: 'blue' }}> Aawsoft Solutions Pvt Ltd. </span>All the transaction and gathered data is / will be accessed by <strong>Jothiparam </strong>.</p> */}
        <p>  • All the data source and financial transaction is authorized by <b>Jothiparam</b>.</p>
      </Row>
    </Container>
  );
};
export default Disclaimer;
