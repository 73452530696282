import React, { createContext, useState } from "react";

//custom - Vasthuparam

import { Grid } from "@mui/material";
import { vasthuUrl } from "../../component/Api";
import UsersListTable from "../../component/crm/UsersListTable";
import Offer from "./Offer";

export const CrmUserListContext = createContext({});

export default function UsersList() {
  const token = window.sessionStorage.getItem("token");

  const [userList, setUserList] = useState([]);
  const [message, setMessage] = useState("");

  const fetchInfo = async () => {
    vasthuUrl
      .get("/data/getallusers", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("RESULT LIST OF USERS", res.data);
        setUserList(res.data);
      })
      .catch((err) => {
        // setMessage(err)
        setMessage("Network error - UserList Not fetched properly");
      });
  };

  React.useEffect(() => {
    fetchInfo();
  }, [setUserList]);

  return (
    <CrmUserListContext.Provider
      value={{ userList, message, setUserList, setMessage, token }}
    >
      {/* <Grid container spacing={2} alignItems='baseline'>
        <Grid item xs={12} sm={8}>
          <UsersListTable title="USERS FOR BOOKING" />
        </Grid>
       
      </Grid> */}
      <UsersListTable title="USERS FOR BOOKING" />
    </CrmUserListContext.Provider>
  );
}
