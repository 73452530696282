import React, { useEffect, useState } from "react";
import { Button, Modal, Table, Spinner } from "react-bootstrap";
import { vasthuUrl } from "../component/Api";
import {
  ElementReports,
  LevelingReports,
  MainDoorReports,
  MiscellaneousReports,
  WindowReports,
} from "../component/HomeReportResult";
import { UseAuth } from "../context/Auth";
import Accordion from "react-bootstrap/Accordion";
import MyAccordian from "../component/MyAccordian";
import NewBootCard from "../component/NewBootCard";
import { useTranslation } from "react-i18next";
const CustomerHomeRecords = () => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  // const { userId, token } = UseAuth();
  // const { userId } = UseAuth();
  const role = window.sessionStorage.getItem("role");
  const token = window.sessionStorage.getItem("token");
  const user = window.sessionStorage.getItem("userId");
  const crmSelectedUser = window.sessionStorage.getItem("crmSelectedUser");
  const userId = token && role == "ROLE_CRM" ? crmSelectedUser : user;
  const langCode = window.localStorage.getItem("i18nextLng");
  const [homeUuid, setHomeUuid] = useState("");
  const [homeRecordList, setHomeRecordList] = useState([
    {
      ownerName: "",
      lagnaName: "",
      houseArea: 0,
      mainFacing: "",
    },
  ]);
  console.log("homeRecordList", homeRecordList);
  const [reportHeader, setReportHeader] = useState({
    owner: "",
    area: 0,
    perimeter: 0,
  });

  const [myAccordian, setMyAccordian] = useState([
    { label: "USR.VSH.RES.ACR.MD", content: MainDoorReports },
    { label: "USR.VSH.RES.ACR.ELM", content: ElementReports },
    { label: "USR.VSH.RES.ACR.MSC", content: MiscellaneousReports },
    { label: "USR.VSH.RES.ACR.WIN", content: WindowReports },
    { label: "USR.VSH.RES.ACR.LVL", content: LevelingReports },
  ]);
  console.log('myAccordian', myAccordian)


  //newly added
  const [homeMainDoorObj, setHomeMainDoorObj] = useState({});
  const [loading, setLoading] = useState(false);
  const [eightGridObj, setEightGridObj] = useState({});
  const [nineGridObj, setNineGridObj] = useState({});

  useEffect(() => {
    setLoading(true);
    try {
      vasthuUrl
        .get(`/gethousereportlist/${userId}/${langCode}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("RESULT LIST OF HOME", res.data);
          setHomeRecordList(res.data);
          setLoading(false);
        });

      //newly added for ayadi outside maindoor result
      vasthuUrl
        .get(`getmaindoorreports/${homeUuid}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("DATA OF MAIN DOOR_CHR", res.data);
          setHomeMainDoorObj(res.data);
          setEightGridObj(res.data.eightGridObj);
          setNineGridObj(res.data.nineGridObj);
          setLoading(false);
          console.log("Maindoor Results_CHR" + res.data);
          console.log(
            "Maindoor Results_CHR" + JSON.stringify(res.data.eightGridObj)
          );
        });
    } catch (e) {
      alert("SERVER ERROR");
      setLoading(false);
    }
  }, [homeUuid]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <React.Fragment>
      {!loading ? (
        homeRecordList.length > 0 ? (
          <>
            <Table
              striped
              bordered
              hover
              responsive
              className="deskTable text-center all_border"
            >
              <thead>
                <tr>
                  <th>{t("USR.VFPR.TBL.CLMN.OWN")}</th>
                  <th>{t("USR.VFP.DRP.LBL.LG")}</th>
                  <th>{t("USR.BD.HMTAB.TBL.CLM.HAR")}</th>
                  <th>{t("USR.BD.HMTAB.TBL.CLM.MF")}</th>
                  <th>{t("USR.VFPR.TBL.CLMN.PRT")}</th>{" "}
                  {/*perimeter newly added*/}
                  <th>{t("USR.BD.HMTAB.TBL.CLM.RP")}</th>
                </tr>
              </thead>
              <tbody>
                {homeRecordList.map((i, j) => (
                  <tr style={{ textAlign: "center" }} key={j}>
                    <td>{i.ownerName}</td>
                    <td>{i.lagnaName}</td>
                    <td>{i.houseArea}</td>
                    <td>{i.mainFacing}</td>
                    <td>{i.perimeter}</td>
                    <td>
                      <Button
                        className="all_btn"
                        onClick={() => {
                          handleShow();
                          setReportHeader({
                            owner: i.ownerName,
                            area: i.houseArea,
                            perimeter: i.perimeter,
                          });
                          setHomeUuid(i.homeUuid);
                        }}
                      >
                        {t("TXT.VIEW.LO")}
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Modal
              show={show}
              onHide={handleClose}
              backdrop="static"
              keyboard={false}
              size="lg"
              centered
              fullscreen={true}
            >
              <Modal.Header className="all_background " closeButton>
                <div className="home_rec_modal">
                  <Modal.Title>
                    {/* {t("USR.BD.HMTAB.TBL.CLM.OWN")}:&nbsp;{reportHeader.owner} */}
                    {t("USR.VSH.RES.ACR.MSC.TBL.CLMN.NM")}:&nbsp;
                    {reportHeader.owner}
                    &nbsp;&nbsp;
                  </Modal.Title>
                  <Modal.Title>
                    {t("USR.VSH.HFTAB.LBL.BA")}:&nbsp;{reportHeader.area}
                    &nbsp;&nbsp;
                  </Modal.Title>
                  <Modal.Title>
                    {t("USR.VFPR.TBL.CLMN.PR")}:&nbsp;{reportHeader.perimeter}
                  </Modal.Title>
                </div>
              </Modal.Header>
              <Modal.Body>
                <Accordion className="accordianpanel maindoor_accordian">
                  <Accordion.Item
                    id="manduku_vasthu_item"
                    className="accordian_style"
                    eventKey="0"
                  >
                    <Accordion.Header>
                      {t("USR.VSH.RES.ACR.MD.ACR.MV")}
                    </Accordion.Header>
                    <Accordion.Body>
                      <table className="reporttable">
                        <thead>
                          <tr>
                            {Object.entries(eightGridObj).map((item) => (
                              <th>{item[0]}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {Object.entries(eightGridObj).map((item) => (
                              <td>
                                {typeof item[1] == "boolean"
                                  ? item[1]
                                    ? "GOOD"
                                    : "BAD"
                                  : item[1]}
                              </td>
                            ))}
                          </tr>
                        </tbody>
                      </table>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item
                    id="paramassagu_vasthu_item"
                    className="accordian_style"
                    eventKey="1"
                  >
                    <Accordion.Header>
                      {t("USR.VSH.RES.ACR.MD.ACR.PV")}
                    </Accordion.Header>

                    <Accordion.Body>
                      <table className="reporttable">
                        <thead>
                          <tr>
                            {Object.entries(nineGridObj).map((item) => (
                              <th>{item[0]}</th>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            {Object.entries(nineGridObj).map((item) => (
                              <td>
                                {typeof item[1] == "boolean"
                                  ? item[1]
                                    ? "GOOD"
                                    : "BAD"
                                  : item[1]}
                              </td>
                            ))}
                          </tr>
                        </tbody>
                      </table>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                {myAccordian.map((item) => {
                  return (
                    <MyAccordian
                      className="myAccordian"
                      label={t(item.label)}
                      content={<item.content data={homeUuid} />}
                    />
                  );
                })}
              </Modal.Body>
              <Modal.Footer className="all_border">
                <Button className="all_btn" onClick={handleClose}>
                  {t("USR.VSH.RES.BTN.LV")}
                </Button>
              </Modal.Footer>
            </Modal>
            <div className=" mobileCard justify-content-center mx-1">
              {homeRecordList.map((i, j) => (
                <NewBootCard>
                  <h4>
                    {t("USR.VFPR.TBL.CLMN.OWN")}: {i.ownerName}
                  </h4>
                  <h6>
                    {t("USR.VFP.DRP.LBL.LG")}: {i.lagnaName}
                  </h6>
                  <h6>
                    {t("USR.BD.HMTAB.TBL.CLM.HAR")}: {i.houseArea}
                  </h6>
                  <h6>
                    {t("USR.BD.HMTAB.TBL.CLM.MF")}:{i.mainFacing}
                  </h6>
                  <h6>
                    {t("USR.VFPR.TBL.CLMN.PRT")}:{i.perimeter}
                  </h6>
                  <center>
                    <Button
                      variant="outline-warning"
                      style={{
                        color: "black",
                        fontWeight: "bold",
                      }}
                      onClick={() => {
                        handleShow();
                        setReportHeader({
                          owner: i.ownerName,
                          area: i.houseArea,
                          perimeter: i.perimeter,
                        });
                        setHomeUuid(i.homeUuid);
                      }}
                    >
                      {t("TXT.VIEW.LO")}
                    </Button>
                  </center>
                </NewBootCard>
              ))}
            </div>{" "}
          </>
        ) : (
          <center>
            <h5>{t("TXT.NO.REC.FUD.LO")}</h5>
          </center>
        )
      ) : (
        <div className="text-center">
          <Spinner animation="border" variant="warning" />
        </div>
      )}
    </React.Fragment>
  );
};

export default CustomerHomeRecords;
