import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Form,
  Container,
  Modal,
  Image,
  Alert,
  Card,
} from "react-bootstrap";
import { Navigate, NavLink } from "react-router-dom";
import { vasthuUrl } from "../component/Api";
import { UseAuth } from "../context/Auth";
import { useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import { Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const ProfAppointHistory = () => {
  const { t } = useTranslation();
  // const { userId } = UseAuth();
  const userId = window.sessionStorage.getItem("userId");
  const token = window.sessionStorage.getItem("token");
  const navigate = useNavigate();
  const [userMeetingHistoryList, setUserMeetingHistoryList] = useState([]);
  // console.log('userMeetingHistoryList', userMeetingHistoryList);
  const [rating, setRating] = useState(3);
  const [reviewDesc, setReviewDesc] = useState("");
  const [msg, setMsg] = useState("");
  const [show, setShow] = useState(false);
  const [profDetails, setProfDetails] = useState({
    profName: "Professional",
    profUuid: "abc12",
    profSlotId: 123,
  });
  const [showReviewModal, setShowReviewModal] = useState(false);

  useEffect(() => {
    try {
      vasthuUrl
        .get(`getoverallprofessionalbookinghistory/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          setUserMeetingHistoryList(res.data);
        });
    } catch (e) {
      console.log("SERVER ERROR");
    }
  }, []);

  const listtemp = userMeetingHistoryList.map((obj, index) => {
    return {
      ...obj,
      id: index + 1,
    };
  });

  const columns = [
    { field: "id", headerName: t("CMN.S.NO"), width: 100 },
    { field: "name", headerName: t("PR.USRMTNG.TBL.CLMN.CNT.CMM"), width: 150 },
    { field: "callDate", headerName: t("TXT.CAL.DAT.UP"), width: 150 },
    {
      field: "callTime",
      headerName: t("PR.USRMTNG.TBL.CLMN.CT.CMM"),
      width: 120,
    },
    { field: "serviceStatus", headerName: t("TXT.CAL.STS.UP"), width: 120 },
    { field: "cancelReason", headerName: t("USR.CALL.CCL.RS"), width: 180 },
    { field: "bookedBy", headerName: t("USR.CAL.BK.BY"), width: 150 },
    { field: "cancelledBy", headerName: t("USR.CAL.CCL.BY"), width: 150 },
    { field: "serviceName", headerName: t("CON.APMT.ME.CONSU"), width: 150 },
  ];

  return (
    <Container>
      {userMeetingHistoryList.length > 0 ? (
        <>
          <center className="datagrid mt-2">
            <Grid container className="deskTable datagrid">
              <DataGrid
                className="all_border - text-center me-3 data_grid"
                rows={listtemp}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                // checkboxSelection
                getRowId={(row) => row.id}
              />
            </Grid>
          </center>

          <div className="justify-content-center mobileCard">
            {userMeetingHistoryList.map((i, j) => (
              <Card
                key={j}
                className="mobileCard all_border"
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  backgroundColor: "antiquewhite",
                }}
              >
                <Card.Body>
                  <Card.Subtitle className="mb-2">
                    <b>{t("PR.USRMTNG.TBL.CLMN.CNT.CMM")}:</b> {i.name}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2">
                    <b>{t("TXT.CAL.DAT.UP")}:</b> {i.callDate}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2">
                    <b> {t("PR.USRMTNG.TBL.CLMN.CT.CMM")}:</b> {i.callTime}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2">
                    <b>{t("TXT.CAL.STS.UP")}:</b> {i.serviceStatus}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2">
                    <b>{t("USR.CALL.CCL.RS")}</b>{" "}
                    {i.cancelReason !== null ? i.cancelReason : "NA"}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2">
                    <b>{t("USR.CAL.BK.BY")}:</b>{" "}
                    {i.bookedBy !== null ? i.bookedBy : "-"}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2">
                    <b>{t("USR.CAL.CCL.BY")}:</b>{" "}
                    {i.cancelledBy !== null ? i.cancelledBy : "-"}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2">
                    <b>{t("CON.APMT.ME.CONSU")}:</b> {i.serviceName}
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            ))}
          </div>
        </>
      ) : (
        <center>
          <h5>{t("TXT.NO.REC.FUD.LO")}</h5>
        </center>
      )}
    </Container>
  );
};

export default ProfAppointHistory;
