import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Card } from "react-bootstrap";
import { format, isToday } from "date-fns";
import { useTranslation } from "react-i18next";

function UserPaymentList(params) {
  console.log("params", params.userPaymentList);
  const paymentList = params.userPaymentList;
  console.log("paymentList", paymentList);
  const originalDate = new Date();
  const formattedDate = format(originalDate, "MMM d, yyyy HH:mm");
  const {t} = useTranslation();

  const columns = [
    { field: "offerName", headerName: t("TXT.OFF.NM.UP"), width: 200 },
    { field: "txnAmount", headerName: "AMOUNT", width: 130 },
    { field: "bankName", headerName: "BANK NAME", width: 150 },
    { field: "txnDate", headerName: "PURCHASED DATE", width: 200 },
    { field: "orderId", headerName: "ORDER ID", width: 200 },
    { field: "status", headerName: "TRANSACTION STATUS", width: 200 },
  ];

  const listtemp = paymentList.map((obj) => {
    return {
      ...obj,
      txnDate: format(new Date(obj.txnDate), "d MMM yyyy, HH:mm"),
    };
  });

  console.log("FDATE[]", listtemp);

  return (
    <div>
      <center>
        <h4>
          <u>Payment Details</u>
        </h4>
      </center>
      {paymentList.length > 0 ? (
        <>
          <center className="datagrid">
            <Grid container className="deskTable">
              <DataGrid
                className="all_border - text-center me-3 data_grid"
                // rows={paymentList}
                rows={listtemp}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                getRowId={(row) => row.orderId}
              />
            </Grid>
          </center>

          <div className="mobileCard">
            {paymentList.map((i, j) => (
              <Card
                className="mobileCard all_border"
                key={j}
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  backgroundColor: "antiquewhite",
                }}
              >
                <Card.Body>
                  <Card.Subtitle className="mb-2">
                    <b>{t("TXT.OFF.NM.LO")}:</b> {i.offerName}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2">
                    <b>Amount:</b> {i.txnAmount}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2">
                    <b>Bank Name:</b> {i.bankName}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2">
                    <b>Purchased Date:</b>
                    {format(new Date(i.txnDate), "d MMM yyyy, (HH:mm)")}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2">
                    <b>Order Id:</b> {i.orderId}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2">
                    <b> Transaction Status:</b> {i.status}
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            ))}
          </div>
        </>
      ) : (
        <center>
          <h5>{t("TXT.NO.REC.FUD.LO")}</h5>
        </center>
      )}
    </div>
  );
}

export default UserPaymentList;
