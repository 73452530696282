import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../style.css";
import { Button } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { vasthuUrl, videoServerUrl } from "../../component/Api";
// import { useTranslation } from "react-i18next";
import Astrologyimg from "../../images/astrology.png";
import { BiSolidPhoneCall } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import { MdSend } from "react-icons/md";

const ChatSender = () => {
  const { t } = useTranslation();
  const [chatData, setChatData] = useState("");
  const [meeting_code, setMeeting_code] = useState("");
  const [disConnect, setDisConnect] = useState();
  const token = window.sessionStorage.getItem("token");
  const userId = window.sessionStorage.getItem("userId");
  const navigate = useNavigate();
  // const webSocket = new WebSocket("ws://192.168.1.34:3001");
  const webSocket = new WebSocket(videoServerUrl);
  let peerConn;
  let dataChannel;
  let username;
  console.log("dataChannel111111", dataChannel);

  useEffect(() => {
    try {
      vasthuUrl
        .get(`/initiateChat/getchatdetails/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          setChatData(res.data);
          setMeeting_code(res.data.meetingCode);
          setDisConnect(res.data.proposedChatDuration * 1000);
        });
    } catch (e) {
      console.log("SERVER ERROR");
    }
  }, []);

  if (window.sessionStorage) {
    console.log("session storage supported");
  } else {
    console.log("session storage not supported");
  }

  webSocket.onmessage = (event) => {
    try {
      handleSignallingData(JSON.parse(event.data));
    } catch (error) {
      alert("Please ensure the remote peer connection");
    }
  };

  function handleSignallingData(data) {
    switch (data.type) {
      case "answer":
        try {
          peerConn.setRemoteDescription(data.answer);
          break;
        } catch (error) {
          console.log(
            "setRemoteDescription failed because of not finding currect remote connection"
          );
        }
      case "candidate":
        try {
          peerConn.addIceCandidate(data.candidate);
        } catch (error) {
          console.log(
            "setRemoteDescription failed because of not finding currect remote connection"
          );
        }
    }
  }

  function sendUsername() {
    username = meeting_code;
    console.log("usernameinput" + username);
    sendData({
      type: "store_user",
    });
  }

  function sendData(data) {
    data.username = username;
    if (webSocket.readyState === 1) {
      webSocket.send(JSON.stringify(data));
    } else {
      console.log("socket not ready");
    }
  }

  function startChat() {
    console.log("chatData -----", chatData);
    try {
      sendUsername();
    } catch (error) {
      console.log("not able to send username");
    }
    // document.getElementById("chat-div").style.display = "inline";
    document.getElementById("chat-animate-div").style.display = "flex";
    const joinChatDiv = document.getElementById("join-chat-div");
    joinChatDiv.style.display = "none";
    joinChatDiv.style.backgroundImage = "";
    joinChatDiv.style.height = "";

    const configuration = {
      iceServers: [
        {
          urls: "stun:stun.aawsoft.com:3478",
        },
        {
          urls: "turn:turn.aawsoft.com:3478",
          username: "guest",
          credential: "somepassword",
        },

        {
          urls: "stun:stun.aawsoft.com:5349",
        },
        {
          urls: "turn:turn.aawsoft.com:5349",
          username: "guest",
          credential: "somepassword",
        },

        {
          urls: [
            "stun:stun.l.google.com:19302",
            "stun:stun1.l.google.com:19302",
            "stun:stun2.l.google.com:19302",
          ],
        },

        {
          urls: "stun:openrelay.metered.ca:80",
        },
        {
          urls: "turn:openrelay.metered.ca:80",
          username: "openrelayproject",
          credential: "openrelayproject",
        },
        {
          urls: "turn:openrelay.metered.ca:443",
          username: "openrelayproject",
          credential: "openrelayproject",
        },
        {
          urls: "turn:openrelay.metered.ca:443?transport=tcp",
          username: "openrelayproject",
          credential: "openrelayproject",
        },
      ],
      optional: [
        {
          RtpDataChannels: true,
        },
      ],
    };

    peerConn = new RTCPeerConnection(configuration);

    dataChannel = peerConn.createDataChannel("channel1", {
      reliable: true,
    });

    try {
      if (dataChannel.readyState !== "open") {
        alert("Please Wait 2 Minutes For Consultant To Join");
      }
    } catch (error) {
      console.log("connection is open");
    }

    // Set a 2-minute timer to check if dataChannel is open
    const connectionTimeout = setTimeout(() => {
      if (dataChannel.readyState !== "open") {
        console.log("not connect to both");
        alert("Connection timed out. Please try again later.");
        hangUp();
      }
    }, 2 * 60 * 1000); // 2 minutes in milliseconds

    peerConn.addEventListener("datachannel", (event) => {
      dataChannel = event.channel;
      console.log("dataChannel", dataChannel);
    });

    dataChannel.onopen = function(success) {
      document.getElementById("chat-div").style.display = "inline";
      document.getElementById("chat-animate-div").style.display = "none";
      console.log("Both are connected", success);
      clearTimeout(connectionTimeout);
      console.log(
        "target.readystate+channel",
        success.target.readyState,
        "+",
        success.target.label
      );
      if (
        success.target.readyState == "open" &&
        success.target.label == "channel1"
      ) {
        console.log("calling  api");
      }
      vasthuUrl.put(
        `/initiateChat/updateChatStatus/${chatData.id}`,
        {
          chatFromId: chatData.chatFromId,
          chatToId: chatData.chatToId,
          status: "CONNECTED",
        },
        { params: { isConnected: true } }
      );
    };

    dataChannel.onerror = function(error) {
      console.log("Ooops...error:", error);
    };

    dataChannel.onmessage = function(event) {
      var chatArea = document.querySelector("#chatarea");
      var messageDiv = document.createElement("div");
      messageDiv.classList.add("message", "remote-message");
      messageDiv.innerText = chatData.clientName + ": " + event.data;
      chatArea.appendChild(messageDiv);
      chatArea.innerHTML += "<br />";
      console.log("event.data", event.data);
    };

    dataChannel.onclose = function() {
      vasthuUrl.put(`/initiateChat/disconnectChat/${chatData.id}`);
      console.log("data channel is closed");
      alert("Chat Over ..!");
      navigate("/chathistory");
      // window.location.reload();
    };

    peerConn.ontrack = (event) => {
      const video = document.getElementById("remote-chat");
      video.srcObject = event.streams[0];
    };

    peerConn.onicecandidate = (e) => {
      if (e.candidate == null) return;
      sendData({
        type: "store_candidate",
        candidate: e.candidate,
      });
    };

    createAndSendOffer();
    setTimeout(() => {
      hangUp();
    }, disConnect); // 3 minutes
  }

  function createAndSendOffer() {
    peerConn.createOffer(
      (offer) => {
        sendData({
          type: "store_offer",
          offer: offer,
        });

        peerConn.setLocalDescription(offer);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  function hangUp(e) {
    if (dataChannel) {
      dataChannel.close();
    }
    navigate("/chathistory");
    vasthuUrl.put(`/initiateChat/disconnectChat/${chatData.id}`);
  }

  //when user clicks the "send message" button
  const sendMsg = () => {
    var msgInput = document.getElementById("msgInput");
    var chatArea = document.querySelector("#chatarea");
    console.log("messageInput", msgInput);
    console.log("chatArea", chatArea);

    var val = msgInput.value;
    console.log("message" + val);

    if (!val) {
      return;
    }

    try {
      dataChannel.send(val);
      var messageDiv = document.createElement("div");
      messageDiv.classList.add("message", "my-message"); // Add a class for styling
      messageDiv.innerText = val;
      chatArea.appendChild(messageDiv);
      chatArea.innerHTML += "<br />";
      msgInput.value = "";
    } catch (error) {
      alert("Please ensure the consultant connection ");
      msgInput.value = "";
    }
  };

  return (
    <div>
      <div
        id="join-chat-div"
        className="d-flex justify-content-center"
        style={{
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // backgroundColor: "blue",
          backgroundImage: "radial-gradient(circle, #00b5ff, yellow, green)",
        }}
      >
        <div>
          <OverlayTrigger
            key="top"
            placement="top"
            overlay={
              <Tooltip id={`tooltip-top`}>{t("USR.PLS.JOIN.ALT")}</Tooltip>
            }
          >
            <Button id="start-chat" variant="warning" onClick={startChat}>
              {t("USR.JOIN.CHAT")}
            </Button>
          </OverlayTrigger>
        </div>
      </div>
      <div id="chat-div" style={{ display: "none" }}>
        <video muted id="local-chat" autoPlay></video>
        <video id="remote-chat" autoPlay></video>
        <div className="chat-action-div">
          <div style={{ display: "flex", gap: "1rem" }}>
            <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip">{t("CON.HANGUP.BTN")}</Tooltip>}
            >
              <div>
                <Button variant="warning" onClick={() => hangUp()}>
                  {t("CON.HANGUP.BTN")}
                </Button>
              </div>
            </OverlayTrigger>
          </div>

          <div className="chatBox">
            <div id="chatarea"></div>
            <div class="main__message_container">
              <textarea
                id="msgInput"
                style={{
                  backgroundColor: "#e9e7da",
                  border: "1px solid black",
                  padding: "4px",
                  height:"40px"
                }}
                placeholder={t("USR.TYPE.MSG.HERE")}
                rows="1"
              />
              <button class="sendMsgBtn" id="sendMsgBtn" onClick={sendMsg}>
                {/* <i class="fa fa-paper-plane"></i> */}
                <MdSend size={30}/>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="chat-animate-div" style={{ display: "none" }}>
        <div className="call_proceed">
          <BiSolidPhoneCall size={50} />
        </div>
        <div className="connecting_div">
          <h4>{t("USR.CHAT.CONNETING")}</h4>
        </div>
      </div>
    </div>
  );
};

export default ChatSender;
