import { List, isWidthDown, withWidth } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {
  Accordion,
  ListGroup,
  Card,
  Button,
  Modal,
  Table,
  ModalBody,
  Spinner,
  OverlayTrigger,
  Tooltip,
  Row,
  Col,
} from "react-bootstrap";
import { vasthuUrl } from "../component/Api";
import { UseAuth } from "../context/Auth";
import { useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { usePay } from "../context/PaymentContext";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import PendingValidationRecords from "./PendingValidationRecords";

const CustomerAccount = () => {
  const token = window.sessionStorage.getItem("token");
  const userId = window.sessionStorage.getItem("userId");

  const { t } = useTranslation();
  // const { userId } = UseAuth();
  const { getSubId } = usePay();
  const Navigate = useNavigate();
  const [active, setActive] = useState([]);
  const [pending, setPending] = useState([]);
  const [completed, setCompleted] = useState([]);
  const [inprogress, setInprogress] = useState([]);
  const [cancelList, setCancelList] = useState([]);
  const [order, setOrder] = useState([]);
  const [refundList, setRefundList] = useState([]);
  const [loading, setLoading] = useState(true);
  console.log("active", active);
  console.log("refundList", refundList);
  const lang = window.localStorage.getItem("i18nextLng");

  const originalDate = new Date();
  const formattedDate = format(originalDate, "MMM d, yyyy HH:mm");

  useEffect(() => {
    setLoading(true);
    try {
      vasthuUrl
        .get(`subscription/userActiveOffers/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          // Sort the active array based on the purchasedDate
          const sortedActive = res.data.sort(
            (a, b) => new Date(b.purchasedDate) - new Date(a.purchasedDate)
          );

          setActive(sortedActive);
          console.log("Active offers", sortedActive);
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }

    try {
      setLoading(true);
      vasthuUrl
        .get(`subscription/userInprogressOffers/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          // Sort the active array based on the purchasedDate
          const sortedActive = res.data.sort(
            (a, b) => new Date(b.purchasedDate) - new Date(a.purchasedDate)
          );
          setInprogress(sortedActive);
          // setOrder(res.data[0].orderid);
          console.log(" inActive Offers", res.data);
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }

    try {
      setLoading(true);
      vasthuUrl
        .get(`subscription/userPendingOfferPayments/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          // Sort the active array based on the purchasedDate
          const sortedActive = res.data.sort(
            (a, b) => new Date(b.purchasedDate) - new Date(a.purchasedDate)
          );
          setPending(sortedActive);
          console.log("pending offers", res.data);
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }

    try {
      setLoading(true);
      vasthuUrl
        .get(`subscription/userCompletedService/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setCompleted(res.data);
          console.log("completed offers", res.data);
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }

    try {
      setLoading(true);
      vasthuUrl
        .get(`refund/getAllUserRefundIntiatedList`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setRefundList(res.data);
          console.log("RefundList", res.data);
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }

    try {
      setLoading(true);
      vasthuUrl
        .get(`/getCancelledCountsForUser/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setCancelList(res.data);
          console.log("cancelList offers", res.data);
          setLoading(false);
        });
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  }, []);

  const handleUse = (p, q) => {
    console.log("forrrrrrr", p);
    console.log("forrrrrrrrrrrr", q);
    switch (p.serviceId) {
      case 1:
        return Navigate("/plotvasthu");
        break;
      case 2:
        return Navigate("/homevasthu");
        break;
      case 3:
        return Navigate("/consultantbooking", {
          state: { p, q },
        });
        break;
      case 4:
        return Navigate("/consultantbooking", {
          state: { p, q },
        });
        break;
      case 5:
        return Navigate("/consultantbooking", {
          state: { p, q },
        });
        break;
    }
  };

  // const available = active.map((active) => <li>{active.serviceDetails} </li>);
  // console.log("available", available);
  // const avai = available.map((available) => (
  //   <li>{available.props.children} </li>
  // ));
  // console.log("avai", avai);

  return (
    <div>
      <div>
        <React.Fragment>
          <Accordion defaultActiveKey={["0"]} alwaysOpen>
            <Accordion.Item eventKey="0" className="accordian_style">
              <Accordion.Header className="all_background">
                {t("USR.ACC.ACT")}
              </Accordion.Header>
              <Accordion.Body>
                {!loading ? (
                  active.length > 0 ? (
                    <Row className="mx-2 mt-3">
                      {active.map((i, j) => (
                        <Col sm={12} md={6} lg={3}>
                          <div key={j}>
                            {i.serviceDetails[0].availableQuantity > 0 ? (
                              <Card className="m-1 all_border use_now_card">
                                <Card.Header className="all_background">
                                  <div className="fw-bold">
                                    {lang == "en"
                                      ? i.offerName
                                      : lang == "ta"
                                      ? i.offerNameTamil
                                      : i.offerNameHindi}
                                  </div>
                                </Card.Header>
                                <Card.Body>
                                  <Card.Subtitle className="mb-2 text-muted">
                                    {t("USR.ACT.PLN.PD")}:{" "}
                                    {format(
                                      new Date(i.purchasedDate),
                                      "d MMM yyyy, HH:mm"
                                    )}
                                  </Card.Subtitle>
                                  <Card.Subtitle className="mb-2 text-muted">
                                    {t("USR.ACC.ACT.VAL.TL")}:{" "}
                                    {format(
                                      new Date(i.expiryDate),
                                      "d MMM yyyy, HH:mm"
                                    )}
                                  </Card.Subtitle>
                                  {i.serviceDetails.map((p, q) => (
                                    <div key={q}>
                                      {p.availableQuantity > 0 ? (
                                        <Card.Body>
                                          {/* <Row>
                                            <Col sm={12} md={3} lg={2}> */}
                                          <Card.Title>
                                            {lang == "en"
                                              ? p.serviceName
                                              : lang == "ta"
                                              ? p.serviceNameTamil
                                              : p.serviceNameHindi}
                                          </Card.Title>
                                          {/* </Col>
                                            <Col sm={12} md={3} lg={2}> */}
                                          <Card.Title>
                                            {t("USR.ACC.ACT.REM.TL")}{" "}
                                            {p.availableQuantity}
                                          </Card.Title>
                                          {/* </Col> */}
                                          {/* <Col sm={12} md={2}>
                                          <Card.Title>
                                            {t("USR.ACC.ACT.USE.TL")}{" "}
                                            {p.usedQuantity}
                                          </Card.Title>
                                        </Col> */}
                                          {/* <Col sm={12} md={2} lg={2}> */}
                                          <Card.Title>
                                            {t("TXT.CAN.COUT.LO")}{" "}
                                            {p.cancelledCount}
                                          </Card.Title>
                                          {/* </Col>
                                            <Col sm={12} md={2} lg={2}> */}
                                          <Card.Title>
                                            {t("PR.APMNT.NATAB.LB.CD")}{" "}
                                            {p.duration}
                                            {t("TXT.MIN.LO")}
                                          </Card.Title>
                                          {/* </Col>
                                            <Col sm={12} md={2} lg={2}> */}
                                          <Card.Title>
                                            {t("USR.ACC.ACT.TOT.TL")}{" "}
                                            {p.totalQuantity}
                                          </Card.Title>
                                          {/* </Col>
                                            <Col
                                              sm={12}
                                              md={2}
                                              lg={2}
                                              style={{ textAlign: "center" }}
                                            > */}
                                          <OverlayTrigger
                                            key="top"
                                            placement="top"
                                            overlay={
                                              <Tooltip id={`tooltip-top`}>
                                                {p.serviceId == 1
                                                  ? t("TXT.CLK.VAS.POT.LO")
                                                  : p.serviceId == 2
                                                  ? t("TXT.CLK.VAS.HOM.LO")
                                                  : p.cancelledCount > 0
                                                  ? t("TXT.CLK.BOK.CON.LO") +
                                                    " " +
                                                    (3 - p.cancelledCount) +
                                                    " " +
                                                    t("TXT.MOR.TIM.LO")
                                                  : t("TXT.CLK.BOK.CON.LO")}
                                              </Tooltip>
                                            }
                                          >
                                            <Button
                                              className="all_btn"
                                              disabled={
                                                p.availableQuantity
                                                  ? false
                                                  : true
                                              }
                                              size="sm"
                                              onClick={() => {
                                                handleUse(p, i.offerId);
                                                getSubId(p.subscriptionId);
                                              }}
                                            >
                                              {p.cancelledCount > 0
                                                ? t("TXT.RE.SCH.LO")
                                                : t("USR.ACC.USE.BTN")}
                                            </Button>
                                          </OverlayTrigger>
                                          {/* </Col> */}
                                          {/* </Row> */}
                                        </Card.Body>
                                      ) : null}
                                    </div>
                                  ))}
                                </Card.Body>
                              </Card>
                            ) : null}
                          </div>
                        </Col>
                      ))}
                    </Row>
                  ) : (
                    <div>
                      <h3>{t("USR.ACC.ACT.NO")}</h3>
                    </div>
                  )
                ) : (
                  <div className="text-center">
                    <Spinner animation="border" variant="warning" />
                  </div>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3" className="accordian_style">
              <Accordion.Header className="all_background">
                {t("USR.ACC.ACT.IP")}
              </Accordion.Header>
              <Accordion.Body>
                {!loading ? (
                  active.length >= 0 ? (
                    <Row className="mx-2 mt-3">
                      {inprogress.map((i, j) => (
                        <Col sm={12} md={6} lg={3}>
                          <Card className="m-1 all_border use_now_card" key={j}>
                            <Card.Header className="all_background">
                              {/* {i.offerName} */}
                              {lang == "en"
                                ? i.offerName
                                : lang == "ta"
                                ? i.offerNameTamil
                                : i.offerNameHindi}
                            </Card.Header>
                            <Card.Body>
                              <Card.Subtitle className="mb-2 text-muted">
                                {t("USR.ACT.PLN.PD")}:-{" "}
                                {format(
                                  new Date(i.purchasedDate),
                                  "d MMM yyyy, HH:mm"
                                )}
                              </Card.Subtitle>
                              <Card.Subtitle className="mb-2 text-muted">
                                {t("USR.ACC.ACT.VAL.TL")}:
                                {format(
                                  new Date(i.expiryDate),
                                  "d MMM yyyy, HH:mm"
                                )}
                              </Card.Subtitle>
                              {i.serviceDetails.map((p, q) => (
                                <Card.Body
                                  // className="d-flex"
                                  key={q}
                                  // style={{
                                  //   // flexDirection: "row",
                                  //   justifyContent: "space-evenly",
                                  // }}
                                >
                                  <Card.Title>
                                    {" "}
                                    {lang == "en"
                                      ? p.serviceName
                                      : lang == "ta"
                                      ? p.serviceNameTamil
                                      : p.serviceNameHindi}
                                  </Card.Title>
                                  <Card.Title>
                                    {t("USR.ACC.ACT.PGR.TL")} {p.inProgressQty}
                                  </Card.Title>

                                  <Card.Title>
                                    {t("PR.APMNT.NATAB.LB.CD")} {p.duration}
                                    {t("TXT.MIN.LO")}
                                  </Card.Title>

                                  {p.refund_status ? (
                                    <Card.Title>
                                      {t("USR.ACC.ACT.RFD.BTN")} -
                                      {lang == "en"
                                        ? p.refund_status
                                        : lang == "ta"
                                        ? p.refund_status_ta
                                        : p.refund_status_hi}
                                      {/* {p.refund_status} */}
                                    </Card.Title>
                                  ) : (
                                    <Button
                                      className="all_btn"
                                      disabled={p.inProgressQty ? false : true}
                                      size="sm"
                                      onClick={() =>
                                        Navigate("/vasthuparamuserrefund", {
                                          state: { order: i.orderid, id: i.id },
                                        })
                                      }
                                      // onClick={() => Navigate("/vasthuparamuserrefund")}
                                    >
                                      {t("USR.ACC.ACT.RFD.BTN")}
                                    </Button>
                                  )}
                                </Card.Body>
                              ))}
                            </Card.Body>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  ) : (
                    <h3>{t("USR.ACC.ACT.NO")}</h3>
                  )
                ) : (
                  <div className="text-center">
                    <Spinner animation="border" variant="warning" />
                  </div>
                )}
              </Accordion.Body>
            </Accordion.Item>

            {/* <Accordion.Item eventKey="1" className="accordian_style">
              <Accordion.Header className="all_background">{t("USR.ACC.PEN")}</Accordion.Header>
              <Accordion.Body>
                {pending.length > 0 ? (
                  pending.map((i, j) => (
                    <Card className="m-1 text-center all_border" key={j}>
                      <Card.Header
                        className="d-flex all_background"
                        style={{
                          justifyContent: "space-between",
                        }}
                      >
                        <h5>  {lang == "en"
                          ? i.offerName
                          : lang == "ta"
                            ? i.offerNameTamil
                            : i.offerNameHindi}</h5>
                        <h5>
                          <Card.Subtitle className="mb-2 text-muted">
                            {t("USR.ACT.PLN.PD")}: {i.purchasedDate}
                          </Card.Subtitle>
                          <Card.Subtitle className="mb-2 text-muted">
                            {t("USR.ACC.ACT.VAL.TL")}: {i.expiryDate}
                          </Card.Subtitle>
                          {t("USR.ACC.PEN.PRI")} {i.offerPrice}
                        </h5>
                      </Card.Header>
                      <div
                        className="d-flex"
                        style={{
                          flexDirection: "row",
                          justifyContent: "space-evenly",
                        }}
                      >
                        {i.serviceDetails.map((p, q) => (
                          <Card.Body key={q}>
                            <Card.Title> {lang == "en"
                              ? p.serviceName
                              : lang == "ta"
                                ? p.serviceNameTamil
                                : p.serviceNameHindi}</Card.Title>
                            <Card.Text>
                              {t("USR.ACC.PEN.QTY")} {p.totalQuantity}
                            </Card.Text>
                          </Card.Body>
                        ))}
                      </div>
                      <Card.Footer></Card.Footer>
                    </Card>
                  ))
                ) : (
                 null
                )}
                <PendingValidationRecords />
              </Accordion.Body>
            </Accordion.Item> */}

            <Accordion.Item eventKey="2" className="accordian_style">
              <Accordion.Header>{t("USR.ACC.CMP")}</Accordion.Header>
              <Accordion.Body>
                {!loading ? (
                  completed.length > 0 ? (
                    <ListGroup>
                      {completed.map((i, j) => (
                        <ListGroup.Item
                          key={j}
                          className="d-flex"
                          style={{ justifyContent: "space-between" }}
                          variant="warning"
                        >
                          {lang == "en"
                            ? i.offerName
                            : lang == "ta"
                            ? i.offerNameTamil
                            : i.offerNameHindi}{" "}
                          &#40;
                          {lang == "en"
                            ? i.serviceName
                            : lang == "ta"
                            ? i.serviceNameTamil
                            : i.serviceNameHindi}
                          &#41;
                          <footer>
                            {t("USR.ACC.CMP.USE.TIM")}{" "}
                            <cite title="Source Title">
                              {i.expiryDate.replace("T", " ")}
                            </cite>
                          </footer>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  ) : (
                    <h3>{t("USR.ACC.CMP.NO")}</h3>
                  )
                ) : (
                  <div className="text-center">
                    <Spinner animation="border" variant="warning" />
                  </div>
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="4" className="accordian_style">
              <Accordion.Header>{t("USR.MY.ACC.CAN.PLN")}</Accordion.Header>
              <Accordion.Body>
                {!loading ? (
                  cancelList.length > 0 ? (
                    <ListGroup>
                      {cancelList.map((i, j) => (
                        <ListGroup.Item
                          key={j}
                          className="d-flex"
                          style={{ justifyContent: "space-between" }}
                          variant="warning"
                        >
                          <span>
                            {t("TXT.OFF.NM.LO")} : &#40;
                            {lang == "en"
                              ? i.offerName
                              : lang == "ta"
                              ? i.offer_name_tamil
                              : i.offer_name_hindi}
                            &#41;
                          </span>
                          <span>
                            {t("USR.MY.ACC.CAN.REA")} : &#40;
                            {i.usercancellationreason}
                            &#41;
                          </span>
                          <span>
                            {t("TXT.CAN.COUT.LO")} : &#40;
                            {i.cancelledCount}
                            &#41;
                          </span>
                          <footer>
                            {t("USR.MY.ACC.CAN.DT")} :
                            <cite title="Source Title">
                              {i.cancellationTime.replace("T", " ")}
                            </cite>
                          </footer>
                        </ListGroup.Item>
                      ))}
                    </ListGroup>
                  ) : (
                    <h3>{t("USR.MY.ACC.NO.CAN.PLN")}</h3>
                  )
                ) : (
                  <div className="text-center">
                    <Spinner animation="border" variant="warning" />
                  </div>
                )}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </React.Fragment>
      </div>
    </div>
  );
};
export default CustomerAccount;
