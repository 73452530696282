import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import AdminClientDetails from "./AdminClientDetails";
import AdminListDetails from "./AdminListDetails";
import AdminProfDetails from "./AdminProfDetails";
import AdminCrmListDetails from "./AdminCrmListDetails";
import DeactivatedProfessionalList from "./DeactivatedProfessionalList";

const AdminUserList = () => {
  const [key, setKey] = useState("clientlist");
  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="justify-content-center"
    >
      <Tab eventKey="clientlist" title="CUSTOMER">
        <AdminClientDetails />
      </Tab>
      <Tab eventKey="professionallist" title="CONSULTANT">
        <AdminProfDetails />
      </Tab>
      <Tab eventKey="adminlist" title="ADMIN">
        <AdminListDetails />
      </Tab>
      <Tab eventKey="crmlist" title="CRM">
        <AdminCrmListDetails />
      </Tab>
      <Tab
        eventKey="deactivatedprofessionallist"
        title=" DEACTIVATED CONSULTANT"
      >
        <DeactivatedProfessionalList />
      </Tab>
    </Tabs>
  );
};

export default AdminUserList;
