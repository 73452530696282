import React, { useEffect, useState } from "react";
import { Container, Table, Spinner } from "react-bootstrap";
import { vasthuUrl } from "../component/Api";
import { UseAuth } from "../context/Auth";
import { UseVasthu } from "../context/vasthu";
import Accordion from "react-bootstrap/Accordion";
import ObjectEmpty from "./ObjectEmptyChecker";
import { useTranslation } from "react-i18next";
// import { useTranslation } from "react-i18next";
// import { t } from "i18next";

export const MainDoorValidation = () => {
  // const { token } = UseAuth();
  const token = window.sessionStorage.getItem("token");
  const  {t}  = useTranslation();
  //const token = window.localStorage.getItem("token");
  const { homeRec } = UseVasthu();
  const [homeMainDoorObj, setHomeMainDoorObj] = useState({});
  const [loading, setLoading] = useState(false);
  const [eightGridObj, setEightGridObj] = useState({});
  const [nineGridObj, setNineGridObj] = useState({});
  useEffect(() => {
    setLoading(true);
    vasthuUrl
      .get(`validatehousedetails/${homeRec}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setHomeMainDoorObj(res.data);
        setEightGridObj(res.data.eightGridObj);
        setNineGridObj(res.data.nineGridObj);
        setLoading(false);
        console.log("main door - 1", res.data);
      });
  }, []);

  return (
    // new Result as per Report screen
    <Container>
      <center>
        <h4>{t("USR.VSH.RES.ACR.MD.TL")}</h4>
      </center>
      {!loading ? (
        <>
          <table id="maindoortable" className="reporttable">
            <thead>
              <tr>
                <th>{t("USR.VSH.RES.ACR.MD.TBL.CLMN.BW")}</th>
                <th>{t("USR.VSH.RES.ACR.MD.TBL.CLMN.MF")}</th>
                <th>{t("USR.VSH.RES.ACR.MD.TBL.CLMN.MDP")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{homeMainDoorObj.buildingFrontWidthResult}</td>
                <td>
                  {homeMainDoorObj.mainDoorFacingResult
                    ? t("USR.HOM.RES.GOOD")
                    : t("USR.HOM.RES.BAD")
                  // <FcCancel size={25} />
                  }
                </td>
                <td>
                  {homeMainDoorObj.mainDoorPlacementResult
                    ? t("USR.HOM.RES.GOOD")
                    : t("USR.HOM.RES.BAD")
                  // <FcCancel size={25} />
                  }
                </td>
              </tr>
            </tbody>
          </table>

          <Accordion defaultActiveKey={["0"]} alwaysOpen className="mb-2">
            <Accordion.Item
              className="accordian_style maindoor_accordian"
              eventKey="0"
            >
              <Accordion.Header className="all_background">
                {t("USR.VSH.RES.ACR.ELM.ACR.TBL.MV")}
              </Accordion.Header>
              <Accordion.Body>
                <table className="reporttable">
                  <thead>
                    <tr>
                      {!ObjectEmpty(eightGridObj) ? (
                        Object.entries(eightGridObj).map((item) => (
                          <th>{t(item[0])}</th>
                        ))
                      ) : (
                        <h2>{t("TXT.NO.REC.FUD.LO")}</h2>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {!ObjectEmpty(eightGridObj) ? (
                        Object.entries(eightGridObj).map((item) => (
                          <td>
                            {typeof item[1] == "boolean"
                              ? item[1]
                                ? t("USR.HOM.RES.GOOD")
                                : t("USR.HOM.RES.BAD")
                              : item[1]}
                          </td>
                        ))
                      ) : (
                        <h2>{t("TXT.NO.REC.FUD.LO")}</h2>
                      )}
                    </tr>
                  </tbody>
                </table>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item
              className="accordian_style maindoor_accordian"
              eventKey="1"
            >
              <Accordion.Header className="all_background">
                {t("USR.VSH.RES.ACR.ELM.ACR.TBL.PV")}
              </Accordion.Header>

              <Accordion.Body>
                <table className="reporttable">
                  {!ObjectEmpty(eightGridObj) ? (
                    <thead>
                      <tr>
                        {Object.entries(nineGridObj).map((item) => (
                          <th>{item[0]}</th>
                        ))}
                      </tr>
                    </thead>
                  ) : (
                    <h3>{t("TXT.NO.REC.FUD.LO")}</h3>
                  )}
                  <tbody>
                    <tr>
                      {!ObjectEmpty(eightGridObj) ? (
                        Object.entries(nineGridObj).map((item) => (
                          <td>
                            {typeof item[1] == "boolean"
                              ? item[1]
                              ? t("USR.HOM.RES.GOOD")
                              : t("USR.HOM.RES.BAD")
                              : item[1]}
                          </td>
                        ))
                      ) : (
                        <h3>{t("TXT.NO.REC.FUD.LO")}</h3>
                      )}
                    </tr>
                  </tbody>
                </table>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </>
      ) : (
        <div className="text-center">
          {" "}
          <Spinner animation="border" variant="warning" />{" "}
        </div>
      )}
    </Container>
  );
};

export const ElementValidation = () => {
  // const { token } = UseAuth();
  const token = window.sessionStorage.getItem("token");
  //const token = window.localStorage.getItem("token");
  const langCode = window.localStorage.getItem("i18nextLng");
  const { homeRec } = UseVasthu();
  const [homeElementArr, setHomeElementArr] = useState([]);
  const [loading, setLoading] = useState(false);
  const [headings, setHeadings] = useState([]);
  const  {t}  = useTranslation();

  console.log("ele val - 2", homeElementArr);

  useEffect(() => {
    setLoading(true);
    console.log("elemen validation", homeRec, token);
    vasthuUrl
      .get(`validateelementdetails/${homeRec}/${langCode}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setHomeElementArr(res.data);
        setLoading(false);
        console.log("ele val - 2", res.data);
        setHeadings(Object.keys(Object.values(res.data)[0][0]));
      });
  }, []);

  return (
    //new Element Result as per Report Screen

    <Container>
      <center>
        <h4>{t("USR.VSH.RES.ACR.ELM.TL")}</h4>
      </center>
      {!loading ? (
        <table id="elementtable" className="reporttable">
          <tbody>
            {!ObjectEmpty(homeElementArr) ? (
              Object.entries(homeElementArr).map((floor) => (
                <Accordion>
                  <Accordion.Item eventKey="0" className="accordian_style mb-2">
                    <Accordion.Header className="all_background">
                      {floor[0]}
                    </Accordion.Header>
                    <Accordion.Body>
                      <thead>
                        <tr>
                          {headings ? (
                            headings
                              .filter(
                                (heading) =>
                                  heading !== "floorName" &&
                                  heading !== "elementUuid"
                              )
                              .map((head) => <th>{head}</th>)
                          ) : (
                            <h4>Headings</h4>
                          )}
                        </tr>
                      </thead>

                      {floor[1].map((newObject) => (
                        <tr>
                          {Object.entries(newObject).map((item) => {
                            if (
                              item[0] !== "elementUuid" &&
                              item[0] !== "floorName"
                            ) {
                              return <td>{item[1]}</td>;
                            }
                          })}
                        </tr>
                      ))}
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ))
            ) : (
              <h3>{t("TXT.NO.REC.FUD.LO")}</h3>
            )}
          </tbody>
        </table>
      ) : (
        <div className="text-center">
          {" "}
          <Spinner animation="border" variant="warning" />{" "}
        </div>
      )}
    </Container>
  );
};

export const MiscellaneousValidation = () => {
  // const { token } = UseAuth();
  const token = window.sessionStorage.getItem("token");
  //const token = window.localStorage.getItem("token");
  const langCode = window.localStorage.getItem("i18nextLng");
  const { homeRec } = UseVasthu();
  const [homeMiscellaneousArray, setHomeMiscellaneousArray] = useState([]);
  console.log(homeMiscellaneousArray, "homeMiscellaneousArray");
  const [loading, setLoading] = useState(false);
  const  {t}  = useTranslation();

  useEffect(() => {
    setLoading(true);
    vasthuUrl
      .get(`validatemiscellaneousdetails/${homeRec}/${langCode}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setHomeMiscellaneousArray(res.data);
        setLoading(false);
        console.log("misc-3", res.data);
      });
  }, []);

  return (
    // new Misc Result as per Report Screen

    <Container>
      <center>
        <h4>{t("USR.VSH.RES.ACR.MSC.TL")}</h4>
      </center>
      {!loading ? (
        homeMiscellaneousArray.length > 0 ? (
          // <Table striped bordered hover responsive size="lg">
          <table id="misctable" className="reporttable">
            <thead>
              <tr>
                <th>{t("USR.VSH.RES.ACR.MSC.TBL.CLMN.NM")}</th>
                <th>Direction Name</th>
                <th>{t("USR.VFPR.TBL.CLMN.RS")}</th>
              </tr>
            </thead>
            <tbody>
              {homeMiscellaneousArray.map((i, j) => (
                <tr key={j}>
                  <td>{i.miscellaneousName}</td>
                  <td>{i.miscellaneousLocation}</td>
                  <td>{i.miscellaneousLocationResult}</td>
                </tr>
              ))}

              {/* <tr>
              <td>Windows</td>
              <td>
                {homeWindowObj.result ? (
                  <FcCheckmark size={25} />
                ) : (
                  <FcCancel size={25} />
                )}
              </td>
            </tr> */}
            </tbody>
            {/* </Table> */}
          </table>
        ) : (
          <center>
            <h5>{t("USR.VSH.RES.ACR.ELM.ACR.TBL.MNF")}</h5>
          </center>
        )
      ) : (
        <div className="text-center">
          {" "}
          <Spinner animation="border" variant="warning" />{" "}
        </div>
      )}
    </Container>
  );
};

export const WindowValidation = () => {
  // const { token } = UseAuth();
  const token = window.sessionStorage.getItem("token");
  //const token = window.localStorage.getItem("token");
  const { homeRec } = UseVasthu();
  const [homeWindowObj, setHomeWindowObj] = useState({});
  const [loading, setLoading] = useState(false);
  const  {t}  = useTranslation();

  useEffect(() => {
    setLoading(true);
    vasthuUrl
      .get(`validatewindowdetails/${homeRec}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setHomeWindowObj(res.data);

        console.log("windows-4", res.data);
        setLoading(false);
      });
  }, []);

  console.log("homeWindowObj", homeWindowObj);

  return (
    // new window result as per Report Screen

    <Container>
      <center>
        <h4>{t("USR.VSH.RES.ACR.ELM.ACR.TBL.CLMN.WR")}</h4>
      </center>
      {!loading ? (
        homeWindowObj ? (
          // <Table striped bordered hover responsive size="lg">
          <table id="windowtable" className="reporttable">
            <thead>
              <tr>
                <th>{t("USR.VSH.RES.ACR.MSC.TBL.CLMN.NM")}</th>
                <th>{t("USR.VSH.RES.ACR.WIN.TBL.CLMN.FS")}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{t("USR.VSH.RES.ACR.ELM.ACR.TBL.CLMN.W")}</td>
                <td>
                  {homeWindowObj.result
                   ? t("USR.HOM.RES.GOOD")
                   : t("USR.HOM.RES.BAD")
                  // <FcCancel size={25} />
                  }
                </td>
              </tr>
            </tbody>
            {/* </Table> */}
          </table>
        ) : (
          <center>
            <h5>{t("USR.VSH.RES.ACR.ELM.ACR.TBL.NWE")}</h5>
          </center>
        )
      ) : (
        <div className="text-center">
          {" "}
          <Spinner animation="border" variant="warning" />{" "}
        </div>
      )}
    </Container>
  );
};

export const LevelingValidation = () => {
  // const { token } = UseAuth();
  const token = window.sessionStorage.getItem("token");
  //const token = window.localStorage.getItem("token");
  const { homeRec } = UseVasthu();
  const [homeLevelObj, setHomeLevelObj] = useState({});
  const [loading, setLoading] = useState(false);
  console.log("homeLevelObj", homeLevelObj);
  const  {t}  = useTranslation();

  useEffect(() => {
    setLoading(true);
    vasthuUrl
      .get(`validateleveldetails/${homeRec}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        setHomeLevelObj(res.data);
        setLoading(false);
        console.log("level-5", res.data);
      });
  }, []);

  return (
    // new Level result for Report Screen

    <Container>
      <center>
        <h4>{t("USR.VSH.RES.ACR.ELM.ACR.TBL.CLMN.LVRS")}</h4>
      </center>
      {!loading ? (
        // <Table striped bordered hover responsive size="lg">
        <table id="leveltable" className="reporttable">
          <thead>
            <tr>
              <th>{t("USR.VSH.RES.ACR.LVL")}</th>
              <th>{t("USR.VSH.RES.ACR.LVL.TBL.CLMN.SWXNE")}</th>
              <th>{t("USR.VSH.RES.ACR.LVL.TBL.CLMN.WXE")}</th>
              <th>{t("USR.VSH.RES.ACR.LVL.TBL.CLMN.SXN")}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{t("USR.VSH.RES.ACR.ELM.ACR.TBL.CLMN.LV")}</td>
              <td>
                {homeLevelObj.southWest_Vs_northEast
                  ? t("USR.HOM.RES.GOOD")
                  : t("USR.HOM.RES.BAD")
                // <FcCancel size={25} />
                }
              </td>
              <td>
                {homeLevelObj.south_Vs_north
                  ? t("USR.HOM.RES.GOOD")
                  : t("USR.HOM.RES.BAD")
                // <FcCancel size={25} />
                }
              </td>
              <td>
                {homeLevelObj.west_Vs_east
                  ? t("USR.HOM.RES.GOOD")
                  : t("USR.HOM.RES.BAD")
                // <FcCancel size={25} />
                }
              </td>
            </tr>
          </tbody>
          {/* </Table> */}
        </table>
      ) : (
        <div className="text-center">
          {" "}
          <Spinner animation="border" variant="warning" />{" "}
        </div>
      )}
    </Container>
  );
};
