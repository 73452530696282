import React, { useState } from "react";
import { Tabs, Tab } from "react-bootstrap";
import AdminRefundDetails from "./AdminRefundDetails";
import AdminInitiateDetails from "./AdminInitiateDetails";

const AdminRefundList = () => {
  const [key, setKey] = useState("initiatelist");
  return (
    <Tabs
      id="controlled-tab-example"
      activeKey={key}
      onSelect={(k) => setKey(k)}
      className="justify-content-center"
    >
      <Tab eventKey="initiatelist" title="USER INITIATE">
        <AdminInitiateDetails />
      </Tab>
      <Tab eventKey="refundlist" title="REFUND">
        <AdminRefundDetails />
      </Tab>
    </Tabs>
  );
};

export default AdminRefundList;
