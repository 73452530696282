import React, { useEffect, useState } from "react";
import {
  Table,
  Button,
  Card,
  Modal,
  Spinner,
  OverlayTrigger,
  Popover,
  Form,
} from "react-bootstrap";
import { vasthuUrl } from "../component/Api";
import { UseAuth } from "../context/Auth";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { width } from "@mui/system";
import Toast from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/js/dist/modal";
import { idID } from "@mui/material/locale";
import validator from "validator";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stacks from "@mui/material/Stack";
import { format } from "date-fns";

const ProfUserMeeting = () => {
  const { token, userId, role } = UseAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [profMeetingList, setProfMeetingList] = useState([]);
  const [userDetails, setUserDetails] = useState([]);
  const [callStatus, setCallStatus] = useState("Failed");
  const [show, setShow] = useState(false);
  const [href, setHref] = useState("");

  const [showModal, setShowModal] = useState(false);
  const [imageData, setImageData] = useState(null);

  const [callStatusPro, setCallStatusPro] = useState();
  const [waitingTime, setWaitingTime] = useState();
  const [totalDurationSpoken, setTotalDurationSpoken] = useState(0);
  console.log("waitingTime", waitingTime);
  const [consulName, setConsulName] = useState();
  const [toast, setToast] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [p, setP] = useState();
  const [slotList, setSlotList] = useState(false);
  const [reasonList, setReasonList] = useState([]);
  const [cancelReason, setCancelReason] = useState("");
  //toast aleart
  const [openSnack, setOpenSnack] = React.useState(false);
  const [messageType, setMessageType] = useState("error");

  const handleClosess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const alert = (msg) => {
    setMessage(msg);
    setOpenSnack(true);
    setTimeout(() => {
      setMessage("");
    }, 4000);
  };

  const ReviewModal = (i) => {
    setShowReviewModal(true);
    setP(i);
    console.log("p", p);
  };

  const handleViewDetailsclose = () => {
    setShowReviewModal(false);
    setP();
    setCancelReason("");
  };

  const closeTosastBtn = () => {
    setToast(false);
  };

  const closeModalBtn = () => {
    setModal(false);
  };

  // const handleShow = () => {
  //   setShow(true);
  // }

  const handleShow = (uri) => {
    setHref(uri);
    setShow(true);
  };
  useEffect(() => {
    try {
      setLoading(true);
      vasthuUrl
        // .get(`getprofessionalbookinglist/${userId}`, {
        .get(`getprofessionalbookinglistwithmode/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          setProfMeetingList(res.data);
          setLoading(false);
        });
    } catch (e) {
      console.log("SERVER ERROR");
      setLoading(false);
    }
    try {
      vasthuUrl
        .get(`cancel/getUserCancelReasons`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          setReasonList(res.data);
        });
    } catch (e) {
      console.log("SERVER ERROR");
      setLoading(false);
    }
  }, [slotList]);

  const fetchCall = () => {
    setLoading(true);
    try {
      setLoading(true);
      vasthuUrl
        .get(`getprofessionalbookinglistwithmode/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          setProfMeetingList(res.data);
          setLoading(false);
        });
    } catch (e) {
      console.log("SERVER ERROR");
      setLoading(false);
    }
  };

  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const updateTime = () => {
      setTime(new Date());
      fetchCall();
    };

    const calculateNextRefreshTime = () => {
      const now = new Date();
      let nextRefreshTime = null;

      profMeetingList.forEach((call) => {
        const callDateTime = new Date(`${call.callDate}T${call.callTime}`);
        if (
          callDateTime > now &&
          (!nextRefreshTime || callDateTime < nextRefreshTime)
        ) {
          nextRefreshTime = callDateTime;
        }
        console.log("callDateTime", callDateTime);
      });

      if (nextRefreshTime) {
        const msUntilNextRefresh = nextRefreshTime - now;
        return msUntilNextRefresh;
      }
      return null;
    };

    const setNextInterval = () => {
      const msUntilNextRefresh = calculateNextRefreshTime();
      if (msUntilNextRefresh !== null) {
        const timeout = setTimeout(() => {
          updateTime();
          setNextInterval(); // Set the next interval after updating
        }, msUntilNextRefresh);

        return timeout;
      }
      return null;
    };

    const initialTimeout = setNextInterval();

    return () => clearTimeout(initialTimeout); // Cleanup on unmount
  }, [profMeetingList]);

  const cancelbookedslot = () => {
    if (!validator.isEmpty(cancelReason)) {
      vasthuUrl
        .post(
          `cancelbookedslot`,
          {
            slot: p.slotId,
            subscription: p.subscriptionId,
            booking: p.bookingid,
            reason: cancelReason,
            role: role,
          },

          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log("slot cancel response", res.data);
          if (res.data) {
            alert(res.data.message);
            setMessageType("success");
          }
          setSlotList(true);
          handleViewDetailsclose();
        })
        .catch((e) => {
          console.log("Slot cancel_catch_block" + e);
        });
    } else {
      alert("Please Select Reason");
      setMessageType("error");
    }
  };

  const ViewUserDetails = (slotId) => {
    try {
      vasthuUrl
        .get(`/getAstroUserDetails/${slotId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log(res.data);
          setUserDetails(res.data);
          setModal(true);
        });
    } catch (e) {
      console.log("SERVER ERROR");
    }
  };

  const downloadFile = async (uri) => {
    try {
      vasthuUrl
        .get(`${uri}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        })
        .then((response) => {
          // Create a data URL from the blob
          const dataUrl = URL.createObjectURL(response.data);
          setImageData(dataUrl);
          setShowModal(true);
        });
    } catch (error) {
      console.log("URI NOT FOUND");
    }
  };

  console.log("profMeetingList", profMeetingList);
  // console.log("i.downloadUri",i.downloadUri);
  // console.log("i",i);
  const openLinkInNewTab = () => {
    window.open(href);
  };

  const handleClose = () => {
    setShowModal(false);
    // Clean up the data URL when the modal is closed
    URL.revokeObjectURL(imageData);
  };

  const GetConsultantCallStatus = (id) => {
    setConsulName(id.name);
    try {
      vasthuUrl
        .post(`vasthu/user/getprostatusavalaibility`, {
          pro_uuid: userId,
          user_uuid: id.uuid,
        })
        .then((res) => {
          setCallStatusPro(res.data);
          console.log("res.data . call consultan tstatus", res.data);
          if (res.data.proCurrentCallStatus == "in-progress") {
            setToast(true);
            // alert(`Consultant Already on a call please wait ${(Math.round(res.data.waitingTime / 60))} mins!!!`);
            // setWaitingTime(Math.round(res.data.waitingTime / 60));
            setWaitingTime(Math.round(res.data.waitingTime));
            setTotalDurationSpoken(res.data.totalDurationSpoken);
            console.log(res.data);
          } else {
            console.log(
              "res.data.totalDurationSpoken" + res.data.totalDurationSpoken
            );
            setTotalDurationSpoken(res.data.totalDurationSpoken);
            openModelCall(id);
          }
        });
    } catch (err) {
      console.log("SERVER ERROR", err);
    }
  };

  const openModelCall = (i) => {
    if (i.consultationMode == "Video") navigate("/startcall", { state: { i } });
    else if (i.consultationMode == "Audio") {
      try {
        vasthuUrl
          .post(`vasthu/user/clicktocall`, {
            from: userId, //"+919790580304",
            to: i.uuid, //"07092758264",
            duration: (i.callDuration - totalDurationSpoken) * 60, //30min
            subscriptionId: i.subscriptionId,
          })
          .then((res) => {
            console.log("postresponse", res);
            console.log("postresponsedata", res.data);
            if (res.data == 200) {
              setCallStatus(t("USR.CAL.PROCED.BTN"));
              console.log(
                "offerId - on 200 [already register offer] status",
                i.subscriptionId
              );
              vasthuUrl
                .get(`/subscription/useVasthuOffer/${i.subscriptionId}`, {
                  headers: {
                    Authorization: `Bearer ${token}`,
                  },
                })
                .then((rs) =>
                  console.log(
                    "offer subscription redeem count used",
                    rs.data.message
                  )
                )
                .catch((er) =>
                  console.log("offer subscription redeem count not reduced", er)
                );
              navigate("/audiocallstatus", {
                state: { callStatus: "USR.CAL.PROCED.BTN" },
              });
            } else {
              setCallStatus(t("USR.CAL.FAILD.BTN"));
              navigate("/audiocallstatus", {
                state: { callStatus: "USR.CAL.FAILD.BTN" },
              });
            }
          })
          .catch(console.log("Click to call Failed"));
      } catch (err) {
        console.log("SERVER ERROR", err);
      }
    }
  };

  const formatDate = (date) => {
    try {
      return format(date, "d MMM yyyy"); // Example format string
    } catch (error) {
      console.error("Error formatting date:", error.message);
      return ""; // or return a default value
    }
  };

  const formatTime = (timeString) => {
    try {
      const time = new Date(`2000-01-01T${timeString}`);
      return format(time, "h:mm a");
    } catch (error) {
      console.error("Error formatting time:", error.message);
      return ""; // or return a default value
    }
  };

  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">{t("CON.MET.TL.TIP.DOB")}</Popover.Header>
    </Popover>
  );

  return (
    <React.Fragment>
      <center>
        <h4 className="m-1" style={{ fontWeight: "400" }}>
          {t("CON.MEET.HEAD")}
        </h4>
      </center>
      {!loading ? (
        profMeetingList.length > 0 ? (
          <>
            <Table
              striped
              bordered
              hover
              responsive
              className="deskTable  all_border"
            >
              <thead>
                <tr className="text-center">
                  <th>{t("PR.USRMTNG.TBL.CLMN.CNT.CMM")}</th>
                  <th>{t("USR.SCH.BO.SER")}</th>
                  <th>{t("TXT.CAL.DAT.UP")}</th>
                  <th>{t("PR.USRMTNG.TBL.CLMN.CT.CMM")}</th>
                  <th>{t("PR.USRMTNG.TBL.CLMN.DR")}</th>
                  <th>{t("PR.USRMTNG.TBL.CLMN.UP")}</th>
                  <th>{t("PR.USRMTNG.TBL.CLMN.CNT")}</th>

                  {/* <th>{t("USR.VSH.WINTAB.TBL.CLMN.ACT")}</th> */}
                </tr>
              </thead>
              <tbody>
                {profMeetingList.map((i, j) => (
                  <tr key={j}>
                    <td style={{ verticalAlign: "middle" }}>{i.name}</td>
                    <td style={{ verticalAlign: "middle" }}>
                      {i.service == 3
                        ? t("USR.SER.NAME.VAS")
                        : i.service == 4
                        ? t("USR.SER.NAME.AST")
                        : i.service == 5
                        ? t("USR.SER.NAME.BUL")
                        : i.consultationMode}
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      {format(new Date(i.callDate), "d MMM yyyy")}
                    </td>
                    <td style={{ verticalAlign: "middle" }}>{i.callTime}</td>
                    <td style={{ verticalAlign: "middle" }}>
                      {i.callDuration + " " + "mins"}
                    </td>
                    <td style={{ verticalAlign: "middle" }}>
                      {i.downloadUri ? (
                        <center>
                          <Button
                            size="sm"
                            style={{
                              borderRadius: 50,
                            }}
                            onClick={() => downloadFile(i.downloadUri)}
                            className="mt-2 all_btn"
                          >
                            {t("CON.MET.VIEW.FILE")}
                          </Button>
                        </center>
                      ) : (
                        <center>
                          <h6 className="mt-2">
                            {t("PR.USRMTNG.TBL.CLMN.MSG.NOUP")}
                          </h6>
                        </center>
                      )}
                      {i.service == 4 ? (
                        <center>
                          <OverlayTrigger
                            trigger="hover"
                            placement="top"
                            overlay={popover}
                          >
                            <Button
                              className="mt-2 all_btn1"
                              onClick={() => ViewUserDetails(i.slotId)}
                              style={{ width: "100%", borderRadius: 50 }}
                            >
                              <Spinner
                                animation="grow"
                                style={{
                                  animationDuration: "1.5s",
                                  color: "transparent",
                                  width: "auto",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                                className="animated_text"
                              >
                                <span
                                  style={{ color: "black", fontSize: "16px" }}
                                >
                                  {t("CON.MET.VIEW.DOB")}
                                </span>
                              </Spinner>
                            </Button>
                          </OverlayTrigger>
                        </center>
                      ) : null}
                    </td>
                    <td
                      className="text-center"
                      style={{ verticalAlign: "middle" }}
                    >
                      {Date.now() >= new Date(i.callDate + " " + i.callTime) ? (
                        <Button
                          style={{
                            borderRadius: 50,
                          }}
                          onClick={async () => {
                            GetConsultantCallStatus(i);
                          }}
                          className="mt-2 all_btn"
                        >
                          {t("USR.SCH.BO.JOIN")}
                        </Button>
                      ) : (
                        <h6>{t("USR.SCH.BO.NOT.STAT")}</h6>
                      )}
                    </td>
                    {/* <td className="text-center" style={{ verticalAlign: "middle" }}>
                      <Button
                        style={{ borderRadius: 50 }}
                        className="mt-2 all_btn"
                        onClick={() => ReviewModal(i)}
                        // disabled={
                        //   Date.now() >=
                        //   new Date(i.callDate + " " + i.callTime) - 3600000 // 1 hour in milliseconds
                        //     ? true
                        //     : false
                        // }
                      >
                        {t("TXT.BTN.CAN.LO")}
                      </Button>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </Table>

            <Modal
              show={showModal}
              fullscreen={true}
              centered
              onHide={handleClose}
            >
              <Modal.Header className="all_background" closeButton>
                <Modal.Title>{t("USR.BOK.SCH.DOC.VIW.TL")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {/* Display the image in the modal */}
                {imageData && (
                  <img
                    src={imageData}
                    alt="File"
                    style={{ width: "100%", height: "auto" }}
                  />
                )}
              </Modal.Body>
              <Modal.Footer className="all_border">
                <Button
                  className="all_btn"
                  variant="secondary"
                  onClick={handleClose}
                >
                  {t("TXT.CLO.LO")}
                </Button>
              </Modal.Footer>
            </Modal>

            <div className="mobileCard">
              {profMeetingList.map((i, j) => (
                <Card
                  key={j}
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    backgroundColor: "antiquewhite",
                  }}
                  className="all_border"
                >
                  <Card.Body>
                    <Card.Title>
                      {t("PR.USRMTNG.TBL.CLMN.CNT.CMM")}: {i.name}
                    </Card.Title>
                    <Card.Title>
                      {t("USR.SCH.BO.SER")}:{" "}
                      {i.service == 3
                        ? t("USR.SER.NAME.VAS")
                        : i.service == 4
                        ? t("USR.SER.NAME.AST")
                        : i.service == 5
                        ? t("USR.SER.NAME.BUL")
                        : i.consultationMode}
                    </Card.Title>
                    <Card.Title>
                      {t("TXT.CAL.DAT.UP")}:{" "}
                      {format(new Date(i.callDate), "d MMM yyyy")}
                    </Card.Title>
                    <Card.Title>
                      {t("PR.USRMTNG.TBL.CLMN.CT.CMM")}: {i.callTime}
                    </Card.Title>
                    <Card.Title>
                      {t("PR.USRMTNG.TBL.CLMN.DR")}:{" "}
                      {i.callDuration + " " + "mins"}
                    </Card.Title>
                  </Card.Body>
                  <Card.Footer className="d-flex text-center justify-content-end">
                    {/* <Button
                      size="sm"
                      style={{
                        borderRadius: 50,
                      }}
                      onClick={() => downloadFile(i.downloadUri)}
                      className="mt-2 all_btn"
                    >
                      {t("CON.MET.VIEW.FILE")}
                    </Button> */}
                    {i.downloadUri ? (
                      <center>
                        <Button
                          size="sm"
                          style={{
                            borderRadius: 50,
                          }}
                          onClick={() => downloadFile(i.downloadUri)}
                          className="mt-2 all_btn"
                        >
                          {t("CON.MET.VIEW.FILE")}
                        </Button>
                      </center>
                    ) : null}
                    {Date.now() >= new Date(i.callDate + " " + i.callTime) ? (
                      <Button
                        style={{
                          borderRadius: 50,
                        }}
                        onClick={async () => {
                          GetConsultantCallStatus(i);
                        }}
                        className="mt-2 all_btn"
                      >
                        {t("USR.SCH.BO.JOIN")}
                      </Button>
                    ) : (
                      <h6 className="mt-2">{t("USR.SCH.BO.NOT.STAT")}</h6>
                    )}
                    {i.service == 4 ? (
                      <OverlayTrigger
                        trigger="hover"
                        placement="top"
                        overlay={popover}
                      >
                        <Button
                          className="mt-2 all_btn1"
                          onClick={() => ViewUserDetails(i.slotId)}
                          style={{ width: "100%", borderRadius: 50 }}
                        >
                          <Spinner
                            animation="grow"
                            style={{
                              animationDuration: "1.5s",
                              color: "transparent",
                              width: "auto",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            className="animated_text"
                          >
                            <span style={{ color: "black", fontSize: "16px" }}>
                              {t("CON.MET.VIEW.DOB")}
                            </span>
                          </Spinner>
                        </Button>
                      </OverlayTrigger>
                    ) : null}

                    {/* <Button
                      style={{ borderRadius: 50 }}
                      className="mt-2 all_btn"
                      onClick={() => ReviewModal(i)}
                      // disabled={
                      //   Date.now() >=
                      //   new Date(i.callDate + " " + i.callTime) - 3600000 // 1 hour in milliseconds
                      //     ? true
                      //     : false
                      // }
                    >
                      {t("TXT.BTN.CAN.LO")}
                    </Button> */}
                  </Card.Footer>
                </Card>
              ))}
            </div>
          </>
        ) : (
          <center>
            <h5>{t("TXT.NO.REC.FUD.LO")}</h5>
          </center>
        )
      ) : (
        <div className="text-center">
          <Spinner animation="border" variant="warning" />
        </div>
      )}

      <Toast onHide={() => setToast(false)} show={toast} className="all_border">
        <Toast.Header closeButton className="all_background">
          {t("TXT.CON.BSY.LO")}!!!
        </Toast.Header>
        <Toast.Body>
          {t("TXT.HELO.LO")}! <b>{consulName}</b> {t("TXT.CON.BSY.ANT.PLS.LO")}{" "}
          {waitingTime}
          {t("USR.BOK.SCH.CON.BSY.MIN")}
        </Toast.Body>
        <Toast.Footer>
          <Button className="all_btn" onClick={closeTosastBtn}>
            {t("TXT.CLO.LO")}
          </Button>
        </Toast.Footer>
      </Toast>

      <Modal onHide={() => setModal(false)} show={modal} className="all_border">
        <Modal.Header closeButton className="all_background">
          <Modal.Title>{t("USR.CON.SCH.USR.DET.TL")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {userDetails.length > 0 ? (
            userDetails.map((i, j) => (
              <Card
                key={j}
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  backgroundColor: "antiquewhite",
                }}
                className="all_border"
              >
                <Card.Body>
                  <Card.Title>
                    {t("TXT.NM.LO")}: {i.userName}
                  </Card.Title>
                  <Card.Title>
                    {t("USR.CON.SCH.USR.BIR.DAT")}:{" "}
                    {formatDate(new Date(i.birthDate))}
                  </Card.Title>
                  <Card.Title>
                    {t("USR.CON.SCH.USR.BIR.TIM")}: {formatTime(i.birthTime)}
                  </Card.Title>
                  <Card.Title>
                    {t("USR.CON.SCH.USR.BIR.PLA")}: {i.userBirthstate}
                  </Card.Title>
                </Card.Body>
              </Card>
            ))
          ) : (
            <center>
              <h5>{t("TXT.NO.REC.FUD.LO")}</h5>
            </center>
          )}
        </Modal.Body>
        <Modal.Footer className="all_border">
          <Button className="all_btn" onClick={closeModalBtn}>
            {t("CON.MET.MOD.CLS")}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showReviewModal}
        onHide={handleViewDetailsclose}
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="v_modal"
      >
        <Modal.Header closeButton className="all_background">
          <Modal.Title>Cancel or Reschedule Booking</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Select
              // style={{ width: "70%" }}
              className="form_feild"
              onChange={(e) => {
                setCancelReason(e.target.value);
              }}
            >
              <option value="">Select Cancel or Reschedule Reason</option>
              {reasonList.map((i, j) => (
                <option key={j} value={i.user_Reasons}>
                  {i.user_Reasons}
                </option>
              ))}
            </Form.Select>
          </Form>
        </Modal.Body>
        <Modal.Footer className="consultantbtn all_border d-flex justify-content-evenly">
          <Button className="all_btn" onClick={handleViewDetailsclose}>
            {t("TXT.BTN.CAN.LO")}
          </Button>
          <Button className="all_btn" onClick={(i) => cancelbookedslot()}>
            Proceed
          </Button>
        </Modal.Footer>
      </Modal>

      <Stacks spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleClosess}
        >
          <MuiAlert
            variant="filled"
            onClose={handleClosess}
            severity={messageType}
            sx={{
              width: "100%",
            }}
          >
            {message}
          </MuiAlert>
        </Snackbar>
      </Stacks>
    </React.Fragment>
  );
};

export default ProfUserMeeting;
