import React from "react";
import { Container, Row } from "react-bootstrap";
import Title from "../component/Title";
import { useTranslation } from "react-i18next";

const VasthuContent = () => {
  const { t } = useTranslation();

  return (
    <Container>
      <Title title={t("CM.HD.CLT.MEN.VS")} />
      <Row className="m-1" style={{ border: "1px solid #ffb33e" }}>
        <p>
          Vasthu is derived from vastu that means Para Bramam (God Partical). If
          House is comply or hormony with para bramam ,Nature or vastu which
          gives happy ,prosperous and peaceful life to human being.
        </p>
        <h6>Branches of Vasthu</h6>
        <span>1. Dimentional Vasthu.</span>
        <br />
        <span>2. Functional Vasthu ( Locations).</span>
        <p>
          Dimentional Vasthu is having ayadi calculations ,auspicious building
          width and room dimensions ,building heights and levels which makes
          building space energy cube and space harmony with sublte energy and
          receiving positive energy Functional Vasthu or Lacation is all
          activities comply with panchabootha. Rooms are located based on
          panchabootha nature . Ultimate Aim of Vasthu is to provide sun and
          moon light and air ,water sufficiently to building habitable space.
          Follow the vasthu guidelines we can achieve above things.
        </p>
        <h6>Architecture and Engineering</h6>
        <p>
          It is unavoidable to implement of Modern building designing , new
          construction technology and advanced building materials in new
          buildings but we can comply with our vasthu guidelines blends with
          current architecture and engineering technology . sothat we can get
          complete habitable and energized building space including current life
          style.
        </p>
      </Row>
    </Container>
  );
};
export default VasthuContent;
