import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Form, Card, Col, Row, Alert } from "react-bootstrap";
import DatePicker from "react-date-picker";
import { Space, TimePicker } from "antd";
import { useTranslation } from "react-i18next";
import { vasthuUrl } from "../../component/Api";
import validator from "validator";
import moment from "moment";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stacks from "@mui/material/Stack";

function AstroUserDetails() {
  const { t } = useTranslation();
  const location = useLocation();
  const slotId = location.state.value;
  const navigate = useNavigate();
  const token = window.sessionStorage.getItem("token");
  const [astroUserName, setAstroUserName] = useState();
  const [astroUserPlace, setAstroUserPlace] = useState();
  console.log("astroUserPlace", astroUserPlace);
  const [dateValue, setDateValue] = useState();
  const [timeValue, setTimeValue] = useState();
  console.log("timeValue", timeValue);

  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [openSnack, setOpenSnack] = React.useState(false);

  const alert = (msg) => {
    setMessage(msg);
    setOpenSnack(true);
    setTimeout(() => {
      setMessage("");
      setMessageType("");
    }, 4000);
  };

  const handleClosess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const AddAstroDetails = async () => {
    try {
      if (!validator.isEmpty(astroUserName)) {
        const formattedTime = timeValue.format("HH:mm:ss") || "00:00:00";
        const res = await vasthuUrl
          .post(
            `astroCustomerDetails`,
            {
              userName: astroUserName,
              userBirthstate: astroUserPlace,
              birthTime: formattedTime,
              birthDate: dateValue,
              slotId: slotId,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((res) => {
            console.log("after save", res.data);
            alert(res.data.message);
            alert("Successfully Upload Your Details!");
            setMessageType("success");
            console.log("afteraleart");
            setAstroUserName("");
            setAstroUserPlace("");
            setDateValue("");
            setTimeValue("");
            setTimeout(() => {
              navigate(-1);
            }, 2000);
          });
      } else {
        alert("Please enter details");
        setMessageType("error");
      }
    } catch (e) {
      alert("Please enter details");
      setMessageType("error");
    }
  };

  const handleEpochChange = (time) => {
    setTimeValue(time);
  };

  const handleDateChange = (date) => {
    setDateValue(date);
    // Reset time when the date changes
    setTimeValue(null);
  };

  const handleTimeChange = (time) => {
    setTimeValue(time);
  };

  const getDisabledHours = () => {
    if (dateValue && isToday(dateValue)) {
      const currentHour = new Date().getHours();
      // Disable hours after the current hour
      return Array.from({ length: 24 }, (_, i) =>
        i > currentHour ? i : null
      ).filter(Number.isInteger);
    }
    return [];
  };

  const getDisabledMinutes = (selectedHour) => {
    if (dateValue && isToday(dateValue)) {
      const currentDate = new Date();
      const currentHour = currentDate.getHours();
      if (selectedHour === currentHour) {
        const currentMinute = currentDate.getMinutes();
        // Disable minutes after the current minute for the current hour
        return Array.from({ length: 60 }, (_, i) =>
          i > currentMinute ? i : null
        ).filter(Number.isInteger);
      }
    }
    return [];
  };

  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    );
  };

  return (
    <div>
      <Stacks spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleClosess}
        >
          <MuiAlert
            variant="filled"
            onClose={handleClosess}
            severity={messageType}
            sx={{
              width: "100%",
            }}
          >
            {message}
          </MuiAlert>
        </Snackbar>
      </Stacks>

      <Row className="d-flex justify-content-center all_border">
        <Col sm={0} md={3}>
          {" "}
        </Col>
        <Col sm={12} md={6}>
          <Card className="d-flex justify-content-center all_border">
            <Card.Header className="all_background" as="h5">
              <center>{t("USR.CON.SCH.USR.DET.TL")}</center>
            </Card.Header>
            <Card.Body>
              <Form className="mt-2">
                <Form.Group className="mb-2" style={{ textAlign: "start" }}>
                  <Form.Label style={{ fontSize: 20, fontWeight: "bold" }}>
                    {t("TXT.NM.LO")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={astroUserName}
                    required
                    className="form_feild"
                    placeholder={t("USR.VSH.HFTAB.HO.PH")}
                    onChange={(e) => {
                      let input = e.target.value;
                      // Ensure only the first character is a letter
                      input = input.replace(/^([^A-Za-z])/, ""); // Remove non-alphabetic characters from the start
                      // Allow only alphanumeric characters after the first character
                      input = input.replace(/[^A-Za-z0-9]/g, ""); // Remove non-alphanumeric characters
                      console.log(input);
                      setAstroUserName(input);
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-2" style={{ textAlign: "start" }}>
                  <Form.Label style={{ fontSize: 20, fontWeight: "bold" }}>
                    {t("USR.CON.SCH.USR.BIR.DAT")}
                  </Form.Label>
                  <br />
                  <div className="datePicker_booking">
                    {/* <DatePicker
                      value={dateValue}
                      maxDate={new Date()}
                      onChange={(e) => {
                        setDateValue(e);
                      }}
                      format="dd-MM-yy"
                      // required
                      className="form_feild"
                      style={{ backgroundColor: "#ffb33e" }}
                    /> */}
                    <DatePicker
                      value={dateValue}
                      maxDate={new Date()}
                      onChange={handleDateChange}
                      format="dd-MM-yy"
                      className="form_feild"
                      style={{ backgroundColor: "#ffb33e" }}
                    />
                  </div>
                </Form.Group>
                <Form.Group className="mb-2" style={{ textAlign: "start" }}>
                  <Form.Label style={{ fontSize: 20, fontWeight: "bold" }}>
                    {t("USR.CON.SCH.USR.BIR.TIM")}
                  </Form.Label>
                  <br />
                  {/* <TimePicker
                    onChange={handleEpochChange}
                    format="hh:mm a"
                    use12Hours
                    showNow={false}
                    status="warning"
                    style={{
                      width: "100%",
                      height: "38px",
                      borderRadius: "5px",
                    }}
                  /> */}
                  <TimePicker
                    value={timeValue}
                    onChange={handleTimeChange}
                    format="hh:mm a"
                    use12Hours
                    showNow={false}
                    disabledHours={getDisabledHours}
                    disabledMinutes={getDisabledMinutes}
                    status="warning"
                    style={{
                      width: "100%",
                      height: "38px",
                      borderRadius: "5px",
                    }}
                  />
                </Form.Group>
                <Form.Group className="mb-2" style={{ textAlign: "start" }}>
                  <Form.Label style={{ fontSize: 20, fontWeight: "bold" }}>
                    {t("USR.CON.SCH.USR.BIR.PLA")}
                  </Form.Label>
                  <Form.Control
                    type="text"
                    value={astroUserPlace}
                    className="form_feild"
                    placeholder="Enter Birth Place"
                    onChange={(e) => {
                      let input = e.target.value;
                      // Ensure only the first character is a letter
                      input = input.replace(/^([^A-Za-z])/, ""); // Remove non-alphabetic characters from the start
                      // Allow only alphanumeric characters after the first character
                      input = input.replace(/[^A-Za-z0-9]/g, ""); // Remove non-alphanumeric characters
                      console.log(input);
                      setAstroUserPlace(input);
                    }}
                  />
                </Form.Group>
              </Form>
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                <Button
                  className="mt-2 all_btn"
                  onClick={() => AddAstroDetails()}
                >
                  {t("USR.BOK.UR.PLN.PRCED")}
                </Button>
                <Button className="mt-2 all_btn" onClick={() => navigate(-1)}>
                  {t("TXT.CLO.LO")}
                </Button>
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={0} md={3}>
          {" "}
        </Col>
      </Row>
    </div>
  );
}

export default AstroUserDetails;
