import React, { useEffect, useState } from "react";
import { vasthuUrl } from "../component/Api";
import { Alert, Button, Spinner, Form } from "react-bootstrap";
import { Table, Card } from "react-bootstrap";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { useTranslation } from "react-i18next";

const AdminRefundDetails = () => {
  const token = window.sessionStorage.getItem("token");
  const {t} = useTranslation();
  const [refundList, setRefundList] = useState([]);
  console.log("refundList", refundList);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const alert = (msg) => {
    setMessage(msg);
    setTimeout(() => {
      setMessage("");
    }, 4000);
  };
  useEffect(() => {
    setLoading(true);
    try {
      vasthuUrl
        .get(`/refund/getAllRefundIntiatedList`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          console.log("users", res.data);
          setRefundList(res.data);
          setLoading(false);
        });
    } catch (e) {
      alert("SERVER ERROR");
      setLoading(false);
    }
  }, []);

  return (
    <React.Fragment>
      <center className="mt-2 mb-2" >
        <h4>
          <u>Refund List Details</u>
        </h4>
      </center>
      {message && (
        <Alert variant="info" className="text-center">
          {message}
        </Alert>
      )}
      {!loading ? (
        <>
          <Table
            striped
            bordered
            hover
            responsive
            className="deskTable all_border text-center mr-5"
          >
            <thead>
              <tr>
                <th>User Name</th>
                <th>{t("TXT.OFF.NM.LO")}</th>
                <th>User Reason</th>
                <th>Bank</th>
                <th>OrderId</th>
                <th>Amount</th>
                <th>status</th>
              </tr>
            </thead>
            <tbody>
              {refundList.map((i, j) => (
                <tr key={j}>
                  <td>{i.username}</td>
                  <td>{i.offer_name}</td>
                  <td>{i.user_reason}</td>
                  <td>{i.bankname}</td>
                  <td>{i.orderid}</td>
                  <td>{i.totalamount}</td>
                  <td>{i.status}</td>
                </tr>
              ))}
            </tbody>
          </Table>

          {refundList.map((i, j) => (
            <div className="justify-content-center mobileCard">
              <Card
                className="mobileCard"
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  backgroundColor: "antiquewhite",
                  border:'1px solid #fbb33e'
                }}
              >
                <Card.Body>
                  <Card.Title>User Name: {i.username}</Card.Title>
                  <Card.Subtitle className="mb-2 text-muted">
                  {t("TXT.OFF.NM.LO")}: {i.offer_name}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2 text-muted">
                    User Reason: {i.user_reason}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2 text-muted">
                    Bank: {i.bankname}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2 text-muted">
                    OrderId: {i.orderid}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2 text-muted">
                    Amount: {i.totalamount}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2 text-muted">
                    Status: {i.status}
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </div>
          ))}
        </>
      ) : (
        <center>
          <Spinner animation="border" variant="warning" />
        </center>
      )}
    </React.Fragment>
  );
};
export default AdminRefundDetails;
