import React, { useEffect, useState } from "react";
import { Alert, Container, Snackbar } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Grid } from "@mui/material";
import Title from "../../component/crm/Title";
import { useContext } from "react";
import { CrmUserListContext } from "./Report";
import { Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import SearchBar from "material-ui-search-bar";
import { vasthuUrl } from "../../component/Api";
import { HeaderUserContext } from "../../pages/crm/Layout";

const ReportListTable = (props) => {
  const navigate = useNavigate();
  const { userList, message, setMessage } = useContext(CrmUserListContext);
  const { setCrmUser } = useContext(HeaderUserContext);
  const token = sessionStorage.getItem("token");
  window.sessionStorage.removeItem("crmSelectedUser");

  const columns = [
    { field: "name", headerName: "Name", width: 200 },
    { field: "phoneNumber", headerName: "Phone", width: 200 },
    { field: "email", headerName: "Email", width: 200 },
    {
      field: "actions",
      headerName: "Action",
      type: "actions",
      renderCell: (params) => (
        <Button
          size="sm"
          className="all_btn1"
          {...{ params }}
          onClick={() => {
            console.log("navigate to List user bookig ", params.row.name);
            window.sessionStorage.setItem("crmSelectedUser", params.row.uuid);
            setCrmUser(params.row.name);
            navigate("/buildingdetails")
            // navigate("/plotvasthu");
          }}
        >
          View
        </Button>
      ),
      width: 200,
    },
  ];

  return (
    <Container>
      <center>
        <Title>{props.title}</Title>
      </center>
      <Snackbar
        open={message}
        autoHideDuration={6000}
        onClose={() => {
          setMessage("");
        }}
        message={message}
        // action={action}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success" sx={{ width: "100%" }}>
          {message}
        </Alert>
      </Snackbar>
      {userList.length > 0 ? (
        <>
          <center className="datagrid">
            <Grid container className="deskTable datagrid">
              <DataGrid
                className="all_border - text-center me-3 data_grid"
                // rows={props.rows}
                rows={userList}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                // checkboxSelection
                getRowId={(row) => row.id}
              />
            </Grid>
          </center>

          <div className="justify-content-center mobileCard">
            {userList.map((params, j) => (
              <Card
                key={j}
                className="mobileCard all_border"
                style={{
                  width: "100%",
                  marginBottom: "10px",
                  backgroundColor: "antiquewhite",
                }}
              >
                <Card.Body>
                  <Card.Subtitle className="mb-2">
                    <b>Name:</b> {params.name}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2">
                    <b> Phone:</b> {params.phoneNumber}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2">
                    <b>Email:</b> {params.email}
                  </Card.Subtitle>
                  <Card.Subtitle className="mb-2 d-flex justify-content-evenly">
                    <Button
                      className="all_btn mb-1"
                      {...{ params }}
                      onClick={() => {
                        console.log("navigate to book slot for ", params.name);
                        console.log("navigate to book slot for ", params.uuid);
                        window.sessionStorage.setItem(
                          "crmSelectedUser",
                          params.uuid
                        );
                        window.sessionStorage.setItem(
                          "LoggedInUser",
                          params.name
                        );
                        setCrmUser(params.name);
                        navigate("/homeconsultant");
                      }}
                      size="sm"
                    >
                      Book
                    </Button>
                    <Button
                      className="all_btn"
                      {...{ params }}
                      onClick={() => {
                        console.log(
                          "navigate to List user bookig ",
                          params.name
                        );
                        window.sessionStorage.setItem(
                          "crmSelectedUser",
                          params.uuid
                        );
                        setCrmUser(params.name);
                        navigate("/consultantbooklist");
                      }}
                      size="sm"
                    >
                      SCHEDULED
                    </Button>
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            ))}
          </div>
        </>
      ) : (
        <>
          <Title>No UserList</Title>
        </>
      )}
    </Container>
  );
};
export default ReportListTable;
