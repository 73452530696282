import React, { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import SearchBar from "../component/Search";
import { useTranslation } from "react-i18next";
import { vasthuUrl } from "./Api";
import BillDeskPay from "./BillDeskPay";

const HomeConsultant = () => {
  // const paymentObj = BillDeskPay();
  const { t } = useTranslation();
  const token = sessionStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [consultantList, setConsultantList] = useState([]);

  useEffect(() => {
    setLoading(true);
    try {
      vasthuUrl
        .get(`info/professional/home`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setConsultantList(res.data);
          setLoading(false);
          console.log("consultant_list_data", res.data, token);
        });
    } catch (e) {
      console.log("server error");
      setLoading(false);
    }
  }, []);

  return (
    <Container className="py-1 my-2">
      {consultantList.length > 0 ? (
        <SearchBar details={consultantList} />
      ) : null}
    </Container>
  );
};
export default HomeConsultant;
