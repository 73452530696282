import React, { useState, useEffect } from "react";
import DatePicker from "react-date-picker";
import { DataGrid } from "@mui/x-data-grid";
import { vasthuUrl } from "../../component/Api";
import { useTranslation } from "react-i18next";
import { Accordion, Col, Form, Row } from "react-bootstrap";
import { Grid } from "@mui/material";
import NewBootCard from "../../component/NewBootCard";
import dayjs from "dayjs";

function AdminAudioCallHistory() {
  const token = window.sessionStorage.getItem("token");
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [clientData, setClientData] = useState([]);
  const [searchField, setSearchField] = useState("");
  const [totalAmount, setTotalAmount] = useState("");
  const [totalDuration, setTotalDuration] = useState("");
  const today = new Date();

  useEffect(() => {
    try {
      vasthuUrl
        .get(`vasthu/user/getallcallhistoryofconsultant`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          const dataWithId = res.data.map((item, index) => ({
            ...item,
            id: item.orderId || index,
          }));
          // Calculate total duration and total amount charged
          const totalDuration = res.data.reduce(
            (sum, item) => sum + item.totalDuration,
            0
          );
          const totalAmountCharged = res.data.reduce(
            (sum, item) => sum + item.totalAmountCharged,
            0
          );
          setClientData(dataWithId);
          setTotalAmount(totalAmountCharged);
          setTotalDuration(totalDuration);
        });
    } catch (e) {
      console.log("setAllOffer", e);
    }
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      const formattedStartDate = dayjs(startDate).format("YYYY-MM-DD");
      const formattedEndDate = dayjs(endDate).format("YYYY-MM-DD");

      vasthuUrl
        .get("vasthu/user/getallcallHistoryofconsultantBydate", {
          params: {
            fromDate: formattedStartDate,
            toDate: formattedEndDate,
          },
        })
        .then((res) => {
          if (res.data.message) {
            setClientData("");
            setTotalAmount("0");
            setTotalDuration("0");
          }
          const dataWithId = res.data.map((item, index) => ({
            ...item,
            id: item.orderId || index,
          }));
          // Calculate total duration and total amount charged
          const totalDuration = res.data.reduce(
            (sum, item) => sum + item.totalDuration,
            0
          );
          const totalAmountCharged = res.data.reduce(
            (sum, item) => sum + item.totalAmountCharged,
            0
          );
          setClientData(dataWithId);
          setTotalAmount(totalAmountCharged);
          setTotalDuration(totalDuration);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [startDate, endDate]);

  const columns = [
    { field: "call_to", headerName: "User", width: 130 },
    { field: "duration", headerName: "Duration", width: 100 },
    {
      field: "start_time",
      headerName: "Date",
      renderCell: (params) =>
        dayjs(params.row.start_time).format("DD-MM-YYYY HH:MM"),
      width: 150,
    },
    {
      field: "proposed_call_duration",
      headerName: "Proposed Duration",
      width: 130,
    },
    { field: "amount_charged", headerName: "Amount Charged", width: 130 },
    { field: "con_rate", headerName: "Consultant Rate", width: 130 },
    { field: "status", headerName: "Status", width: 130 },
  ];

  const ConsultantCallList =
    clientData &&
    clientData.filter((person) => {
      return person.callFromId
        .toLowerCase()
        .includes(searchField.toLowerCase());
    });

  useEffect(() => {
    const totalDuration =
      ConsultantCallList &&
      ConsultantCallList.reduce((sum, item) => sum + item.totalDuration, 0);
    const totalAmount =
      ConsultantCallList &&
      ConsultantCallList.reduce(
        (sum, item) => sum + item.totalAmountCharged,
        0
      );
    setTotalDuration(totalDuration);
    setTotalAmount(totalAmount);
  }, [totalDuration, totalAmount, ConsultantCallList]);

  return (
    <div>
      <Row className="mt-2 consul_chat_his" style={{ alignItems: "flex-end" }}>
        <Col sm={12} md={4} className="mt-2">
          <input
            type="text"
            className="form-control form-input form_feild"
            placeholder={t("CM.CNSL.SER.PL")}
            onChange={(e) => setSearchField(e.target.value)}
          />
        </Col>
        <Col sm={12} md={4} className="mt-2">
          <label>
            Start Date:
            <DatePicker
              className="form_feild"
              value={startDate}
              format="dd-MM-yy"
              onChange={(date) => setStartDate(date)}
              placeholderText="Select start date"
              style={{ height: "35px", borderRadius: "7px" }}
              maxDate={endDate ? endDate : today}
            />
          </label>
        </Col>
        <Col sm={12} md={4} className="mt-2">
          <label>
            End Date:
            <DatePicker
              className="form_feild"
              value={endDate}
              format="dd-MM-yy"
              onChange={(date) => setEndDate(date)}
              placeholderText="Select end date"
              minDate={startDate}
              maxDate={today}
            />
          </label>
        </Col>
      </Row>
      {ConsultantCallList ? (
        <div>
          <center>
            <u>
              <h2 className="mt-2">Consultant Call History</h2>
            </u>
          </center>
          <div className="d-flex justify-content-around">
            {startDate && endDate ? (
              <h3 className="mt-2">
                {dayjs(startDate).format("DD-MM-YYYY")}&nbsp;to&nbsp;
                {dayjs(endDate).format("DD-MM-YYYY")}
              </h3>
            ) : (
              // <h3 className="mt-2">Date</h3>
              <h3 className="mt-2">OverAll List</h3>
            )}
            <u>
              <h3 className="mt-2">Total Earnings: {totalAmount}</h3>
            </u>
            <u>
              <h3 className="mt-2">Total Durations: {totalDuration}</h3>
            </u>
          </div>

          {ConsultantCallList.map((i, j) => (
            <Accordion
              className="chat_accordian mt-2"
              // defaultActiveKey="0"
              key={j}
            >
              <Accordion.Item
                className="accordian_style"
                eventKey={j.toString()}
              >
                <Accordion.Header>
                  <Row style={{ width: "inherit" }}>
                    <Col sm={12} md={4}>
                      <h5>Consultant: {i.callFromId}</h5>
                    </Col>
                    <Col sm={12} md={4}>
                      <h5>Duration: {i.totalDuration}</h5>
                    </Col>
                    <Col sm={12} md={4}>
                      <h5>Amount: ₹{i.totalAmountCharged}</h5>
                    </Col>
                  </Row>
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <center className="datagrid mt-3">
                      <Grid container className="deskTable datagrid">
                        <DataGrid
                          className="all_border text-center me-3 data_grid"
                          rows={i.AllCallDetails}
                          columns={columns}
                          initialState={{
                            pagination: {
                              paginationModel: { page: 0, pageSize: 10 },
                            },
                          }}
                          pageSizeOptions={[5, 10]}
                          getRowId={(row) => row.id}
                        />
                      </Grid>
                    </center>
                    <div className="mobileCard justify-content-center mx-1">
                      {i.AllCallDetails.map((item, index) => (
                        <NewBootCard key={index}>
                          <h6>User: {item.call_to}</h6>
                          <h6>Duration: {item.duration}</h6>
                          <h6>
                            Date:{" "}
                            {dayjs(item.start_time).format("DD-MM-YYYY HH:MM")}
                          </h6>
                          <h6>
                            Proposed Duration: {item.proposed_call_duration}
                          </h6>
                          <h6>Amount Charged: {item.amount_charged}</h6>
                          <h6>Consultant Rate: {item.con_rate}</h6>
                          <h6>Status: {item.status}</h6>
                        </NewBootCard>
                      ))}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          ))}
        </div>
      ) : (
        <center>
          <h2 className="mt-2">No Record Found!</h2>
        </center>
      )}
    </div>
  );
}

export default AdminAudioCallHistory;
