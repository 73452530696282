import React, { useState, useEffect, Suspense } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { BiSolidPhoneCall } from "react-icons/bi";
import { Button } from "react-bootstrap";
import { color } from "@mui/system";
import "../App.css";
import { useTranslation } from "react-i18next";

const DirectCall = () => {
  const location = useLocation();
  const {t} = useTranslation();
  useEffect(() => {
    console.log("props", location);
    setCallStatus(location.state.callStatus);
  }, [callStatus]);
  const [callStatus, setCallStatus] = useState("");

  console.log("callStatus",callStatus);

  const navigate = useNavigate();

  return (
    <div>
      <div className="contnent">
        <div className="hlo">
          <h3>{callStatus}...</h3>{" "}
        </div>
        <div class="pluse">
          <BiSolidPhoneCall className="i" size={50} />
        </div>
        <div>
          <Button variant="outline-warning" onClick={() => navigate("/")}>
          {t("TXT.CLO.LO")}
          </Button>
        </div>
      </div>
    </div>
  );
};
export default DirectCall;