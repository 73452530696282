import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Button,
  Col,
  Container,
  Image,
  ListGroup,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import {
  FcSms,
  FcPhone,
  FcVoicePresentation,
  FcKindle,
  FcHome,
} from "react-icons/fc";

import { GiFlatPlatform } from "react-icons/gi";
import { BiArea } from "react-icons/bi";
import { MdOutlineEngineering } from "react-icons/md";
import Slider from "react-slick";
import { vasthuUrl } from "./Api";

const HomeServices = (props) => {
  const { t } = useTranslation();
  const Navigate = useNavigate();
  const token = window.sessionStorage.getItem("token");
  const [bannerList, setBannerList] = useState([]);
  const [bgImages, setBgImages] = useState([]);

  console.log("bgImages", bgImages);

  useEffect(() => {
    vasthuUrl.get("image/getallactivebanner").then((res) => {
      console.log("banner List", res.data);
      setBannerList(res.data);
    });
  }, []);

  useEffect(() => {
    const updateBackgroundImages = () => {
      if (window.innerWidth <= 475) {
        setBgImages(
          bannerList.map((image) => ({ ...image, url: image.mobileimageUrl }))
        );
      } else {
        setBgImages(
          bannerList.map((image) => ({ ...image, url: image.desktopimageUrl }))
        );
      }
    };

    updateBackgroundImages();
    window.addEventListener("resize", updateBackgroundImages);

    return () => {
      window.removeEventListener("resize", updateBackgroundImages);
    };
  }, [bannerList]);

  var settings = {
    className: "slider variable-width",
    // variableWidth: true,
    // dots: true,
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    speed: 1000,
    slidesToScroll: 1,
    adaptiveHeight: true,
  };

  return (
    <Container className=" my-3">
      <div style={{ border: "1px solid #ffbb33" }} className="p-4">
        <em style={{ fontWeight: "bold" }}>
          <b style={{ fontWeight: "bolder" }}>Vasthuparam.com</b>&nbsp;
          {t("USR.VAS.DESC.CNT")}
          {/* wishes everyone of us for a flourishing, prosperous
          and peaceful life.Self check Vasthu at your palm and book our best
          consultant on demand, online flexibly as needed for
          Vasthu/Astrology/Building plan or construction/precious material
          suggestions. */}
        </em>
      </div>

      <div
        style={{ display: "flex", justifyContent: "center" }}
        className="mt-4"
      >
        <ListGroup
          style={{ display: "flex", justifyContent: "center", width: "90%" }}
        >
          <Slider
            arrows={true}
            slidesToShow={1}
            slidesToScroll={1}
            lazyLoad={true}
            {...settings}
            className="slick_icon"
          >
            {bgImages.map((bgImage, index) => (
              <div key={index}>
                <div
                className="first_img"
                  style={{
                    backgroundImage: `url(${bgImage.url})`,
                    backgroundSize: "100% 100%",
                    backgroundPosition: "center",
                    backgroundRepeat:"no-repeat",
                    display:"flex",
                    justifyContent:"center",
                    alignItems:"flex-end",
                    height:"250px",
                    marginBottom:"15px",
                    borderRadius:"15px",
                  }}
                >
                    <Button
                      size="md"
                      className="special_btn first_btn m-2"
                      onClick={() => Navigate(bgImage.pathName)}
                    >
                      {!token ? "Register" : bgImage.title}
                    </Button>
                </div>
              </div>
            ))}
          </Slider>
        </ListGroup>
      </div>
      {/* <center>
        <h1>{t("CM.SER.TL")}</h1>
      </center> */}
      {/* <Row className="d-flex g-1 justify-content-center align-items-center">
        <Col sm={12} md={12} lg={6}>
          <Card
            className="text-center all_border"
            style={{ cursor: "pointer" }}
          >
            <Card.Header className="all_border all_background" as="h3">
              {t("CM.SER.TL1")}
            </Card.Header>
            <Card.Body>
              <Row className="d-flex g-1 justify-content-center align-items-center">
                <Col sm={12} md={6} lg={6}>
                  <Card
                    className="text-center all_border"
                    style={{ cursor: "pointer" }}
                    onClick={() => Navigate("plotvasthu")}
                  >
                    <Card.Body>
                      <Image
                        src="plot.png"
                        class="img-fluid"
                        object="fill"
                        alt=""
                        width="50px"
                        height="50px"
                      />
                      <Card.Text>{t("CM.SER.VFP")}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={12} md={6} lg={6}>
                  <Card
                    className="text-center all_border"
                    style={{ cursor: "pointer" }}
                    onClick={() => Navigate("homevasthu")}
                  >
                    <Card.Body>
                      <Image
                        src="home.png"
                        class="img-fluid"
                        object="fill"
                        alt=""
                        width="50px"
                        height="50px"
                      />
                      <Card.Text>{t("CM.SER.VFH")}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col sm={12} md={12} lg={6}>
          <Card
            className="text-center all_border"
            style={{ cursor: "pointer" }}
          >
            <Card.Header className="all_border all_background" as="h3">
              {t("CM.SER.TL2")}
            </Card.Header>
            <Card.Body>
              <Row className="d-flex g-1 justify-content-center align-items-center">
                <Col sm={12} md={4} lg={4}>
                  <Card
                    className="text-center all_border"
                    style={{ cursor: "pointer" }}
                    onClick={() => Navigate("/vasthuexpert")}
                  >
                    <Card.Body>
                      <Image
                        src="expert.png"
                        class="img-fluid"
                        object="fill"
                        alt=""
                        width="50px"
                        height="50px"
                      />
                      <Card.Text>{t("CM.HD.CLT.MEN.VE")}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={12} md={4} lg={4}>
                  <Card
                    className="text-center all_border"
                    style={{ cursor: "pointer" }}
                    onClick={() => Navigate("/vasthuarchitects")}
                  >
                    <Card.Body>
                      <Image
                        src="architech.png"
                        class="img-fluid"
                        object="fill"
                        alt=""
                        width="50px"
                        height="50px"
                      />
                      <Card.Text>{t("CM.HD.CLT.MEN.AR")}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={12} md={4} lg={4}>
                  <Card
                    className="text-center all_border"
                    style={{ cursor: "pointer" }}
                    onClick={() => Navigate("vasthuastrolgers")}
                  >
                    <Card.Body>
                      <Image
                        src="austrologer.png"
                        class="img-fluid"
                        object="fill"
                        alt=""
                        width="50px"
                        height="50px"
                      />
                      <Card.Text>{t("CM.HD.CLT.MEN.ASTR")}</Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row> */}
    </Container>
  );
};

export default HomeServices;
