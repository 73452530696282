import Multiselect from "multiselect-react-dropdown";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Button,
  Card,
  Container,
  Form,
  InputGroup,
  Modal,
  Spinner,
  Table,
} from "react-bootstrap";
import validator from "validator";
import { vasthuUrl } from "../component/Api";
import { UseAdminContext } from "../context/AdminContext";
import { UseAuth } from "../context/Auth";

const AdminAddProfForm = () => {
  const { token } = UseAuth();
  const { handleProfRegister, profLoading, profError } = UseAdminContext();
  const [profName, setProfName] = useState("");
  const [profTaName, setProfTaName] = useState("");
  const [profEmail, setProfEmail] = useState("");
  const [profPhone, setProfPhone] = useState("");
  const [searchPhoneNo, setSearchPhoneNo] = useState("");
  const [profNewPassword, setProfNewPassword] = useState("");
  const [profConfirmPassword, setProfConfirmPassword] = useState("");
  const [amount, setAmount] = useState("");
  const [message, setMessage] = useState("");
  const [level, setLevel] = useState([]);
  const [consultantLevel, setConsultantLevel] = useState("");
  const [skillList, setSkillList] = useState([]);
  const [extraSkill, setExtraSkill] = useState("");
  const [extraLang, setExtraLang] = useState("");
  const [langList, setLangList] = useState([]);
  const [monthOfExp, setMonthOfExp] = useState("");
  const [skillMaster, setSkillMaster] = useState([]);
  const [langMaster, setLangMaster] = useState([]);
  // const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countryCode, setCountryCode] = useState("0");
  const [show, setShow] = useState(false);
  const [maxRate, setMaxRate] = useState("");
  console.log("maxRate", maxRate);

  useEffect(() => {
    alert(profError);
    try {
      vasthuUrl
        .get(`/vasthu/openapi/getskills`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((skill) => {
          setSkillMaster(skill.data);
        });
    } catch (e) {
      console.log("server error", e);
      setSkillMaster([]);
    }
    try {
      vasthuUrl
        .get(`consultant/getCategoryLevel`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((level) => {
          setLevel(level.data);
        });
    } catch (e) {
      console.log("server error", e);
      setLevel([]);
    }
    try {
      vasthuUrl
        .get(`openapi/lang/allCode`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((lang) => {
          setLangMaster(lang.data);
        });
    } catch (e) {}
  }, [profError]);

  const handleOpen = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const datas = [
    {
      id: 1,
      startTime: "2024-09-11",
      duration: 1,
      endTime: "2024-09-11",
    },
    {
      id: 2,
      startTime: "2024-09-11",
      duration: 12,
      endTime: "2024-09-11",
    },
    {
      id: 3,
      startTime: "2024-09-11",
      duration: 15,
      endTime: "2024-09-11",
    },
    {
      id: 4,
      startTime: "2024-09-11",
      duration: 20,
      endTime: "2024-09-11",
    },
    {
      id: 5,
      startTime: "2024-09-11",
      duration: 22,
      endTime: "2024-09-11",
    },
  ];

  // const openModal = () => {
  //   setShowModal(true);
  // };

  // const fetchProfessionalDetails = () => {
  //   vasthuUrl
  //     .get(`professionalBriefDetails/${searchPhoneNo}`, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     })
  //     .then((res) => {
  //       console.log("PROF Details", res.data);
  //     });
  // };

  const alert = (msg) => {
    setMessage(msg);

    setTimeout(() => {
      setMessage("");
    }, 4000);
  };

  const onSubmit = (event) => {
    const phone_with_code = countryCode.concat(profPhone);
    event.preventDefault();
    console.log("monthOfExp" + monthOfExp);
    if (
      !validator.isEmpty(profName) &&
      !validator.isEmpty(profPhone) &&
      !validator.isEmpty(profNewPassword) &&
      !validator.isEmpty(profConfirmPassword) &&
      !validator.isEmpty(consultantLevel) &&
      skillList.length > 0 &&
      langList.length > 0 &&
      !validator.isEmpty(monthOfExp)
    ) {
      if (
        validator.isMobilePhone(profPhone, "en-IN") &&
        validator.isEmail(profEmail) &&
        validator.equals(profNewPassword, profConfirmPassword)
      ) {
        const profSkill = skillList.map((i) => ({ aid: i.id }));
        const profLang = langList.map((i) => ({ aid: i.id }));
        const credentials = {
          user: profName,
          tamilName: profTaName,
          phone: phone_with_code,
          email: profEmail,
          pass: profConfirmPassword,
          level: consultantLevel,
          exp: monthOfExp,
          profLang,
          profSkill,
          extraSkill:extraSkill,
          extraLang:extraLang,
          amount,
          maxRate: maxRate,
        };
        console.log("professional submit", credentials);
        handleProfRegister(credentials, (res) => {
          if (res === "success") {
            alert("Sucessfully Registered prof");
            setProfName("");
            setProfTaName("");
            setProfEmail("");
            setProfPhone("");
            setProfNewPassword("");
            setProfConfirmPassword("");
            setConsultantLevel("");
            setMonthOfExp("");
            setSkillList([]);
            setLangList([]);
            setExtraLang("");
            setExtraSkill("");
            setAmount("");
            setMaxRate("");
          } else {
            alert("Professional Registration Fails");
          }
        });
      } else {
        alert("CREDENTIALS GIVEN IS INVALID");
      }
    } else {
      alert("CREDENTIALS IS EMPTY");
    }
  };

  return (
    <Container>
      {/* <div className="mb-2 d-flex justify-content-end">
        <Button
          variant="outline-warning"
          style={{ color: "black", fontWeight: "bold" }}
          size="sm"
          onClick={() => openModal()}
        >
          Update Professional
        </Button>
      </div> */}

      <Card className="p-3 all_border">
        <Form>
          {message && (
            <Alert variant="info" className="text-center">
              {message}
            </Alert>
          )}
          <Form.Group className="mb-3" controlId="adminAddprofName">
            <Form.Label>Consultant Name* :</Form.Label>
            <Form.Control
              className="form_feild"
              type="text"
              placeholder="Enter User Name"
              value={profName}
              onChange={(e) => {
                console.log(e.target.value);
                setProfName(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="adminAddprofTaName">
            <Form.Label>Consultant Tamil Name* :</Form.Label>
            <Form.Control
              className="form_feild"
              type="text"
              placeholder="Enter Tamil User Name"
              value={profTaName}
              onChange={(e) => {
                console.log(e.target.value);
                setProfTaName(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="adminAddprofExp">
            <Form.Label>Month of Exp : </Form.Label>
            <Form.Control
              className="form_feild"
              type="number"
              placeholder="Total Experience in Month"
              value={monthOfExp}
              min="0"
              onChange={(e) => {
                console.log(e.target.value);
                setMonthOfExp(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-2" controlId="formSkill">
            <Form.Label>Skill :</Form.Label>
            <Multiselect
              className="form_feild"
              id={"profSkill"}
              selectedValues={skillList}
              displayValue="skillName"
              placeholder="Select Skill"
              onRemove={(list, value) => {
                setSkillList([...list]);
              }}
              onSearch={function noRefCheck() {}}
              onSelect={(list, value) => {
                console.log("multi select", [...list]);
                setSkillList([...list]);
              }}
              options={skillMaster}
              showCheckbox
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="extraSkill">
            <Form.Label>Extra Skill: </Form.Label>
            <Form.Control
              className="form_feild"
              type="text"
              placeholder="Enter the extra skill..."
              value={extraSkill}
              onChange={(e) => {
                setExtraSkill(e.target.value);
              }}
            />
          </Form.Group>

          <Form.Group className="mb-2" controlId="formLang">
            <Form.Label>Language Known :</Form.Label>
            <Multiselect
              className="form_feild"
              id={"profLang"}
              selectedValues={langList}
              displayValue="language"
              placeholder="Select Known Language"
              onRemove={(list, value) => {
                setLangList([...list]);
              }}
              onSearch={function noRefCheck() {}}
              onSelect={(list, value) => {
                console.log("multi select", [...list]);
                setLangList([...list]);
              }}
              options={langMaster}
              showCheckbox
            />
          </Form.Group>

          <Form.Group className="mb-3" controlId="extraLang">
            <Form.Label>Extra Language: </Form.Label>
            <Form.Control
              className="form_feild"
              type="text"
              placeholder="Enter the extra lang..."
              value={extraLang}
              onChange={(e) => {
                setExtraLang(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="adminAddprofEmail">
            <Form.Label>Email address :</Form.Label>
            <Form.Control
              className="form_feild"
              type="email"
              placeholder="name@example.com"
              value={profEmail}
              onChange={(e) => {
                console.log(e.target.value);
                setProfEmail(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="adminAddprofPhone">
            <Form.Label>Phone No* :</Form.Label>
            <InputGroup className="mb-3">
              <Form.Select
                aria-label="Country Code"
                style={{ maxWidth: "10%" }}
                value={countryCode}
                className="form_feild countrywidth"
                onChange={(e) => {
                  setCountryCode(e.target.value);
                }}
              >
                <option defaultChecked key={1} value="0">
                  IND
                </option>
                <option key={2} value="+65">
                  SGP
                </option>
                <option key={3} value="+60">
                  MYS
                </option>
                <option key={4} value="+1">
                  USA
                </option>
              </Form.Select>
              <Form.Control
                className="form_feild"
                type="phone"
                placeholder="Enter Mobile No"
                value={profPhone}
                onChange={(e) => {
                  console.log(e.target.value);
                  setProfPhone(e.target.value);
                }}
              />
            </InputGroup>
          </Form.Group>

          <Form.Group className="mb-3" controlId="adminAddprofNewPassword">
            <Form.Label>New Password* :</Form.Label>
            <Form.Control
              className="form_feild"
              type="password"
              placeholder="Enter New Password"
              value={profNewPassword}
              onChange={(e) => {
                console.log(e.target.value);
                setProfNewPassword(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-3" controlId="adminAddprofConfirmPassword">
            <Form.Label>Confirm Password* :</Form.Label>
            <Form.Control
              className="form_feild"
              type="password"
              placeholder="Enter Confirm Password"
              value={profConfirmPassword}
              onChange={(e) => {
                console.log(e.target.value);
                setProfConfirmPassword(e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group className="mb-2" controlId="formPlotLagna">
            <Form.Label>Consultant Category :</Form.Label>
            <Form.Select
              className="form_feild"
              aria-label="Level"
              style={{ fontSize: "1rem" }}
              value={consultantLevel}
              id="myDropdown"
              onChange={(e) => {
                setConsultantLevel(e.target.value);
              }}
            >
              <option className="john" value="">
                Consultant Category{" "}
              </option>
              {level.map((i, j) => (
                <option className="son" key={j} value={i.id}>
                  {i.level}
                  {`(${i.sharePercent}%)`}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="adminAddprofConfirmPassword">
            <Form.Label>Rate Per Minute :</Form.Label>
            <Form.Control
              className="form_feild"
              type="text"
              placeholder="Enter Rate Per Minute"
              value={amount}
              onChange={(e) => {
                console.log(e.target.value);
                setAmount(e.target.value);
              }}
            />
            <Form.Group className="mb-3" controlId="maxRate">
              <Form.Label>Maximum Rate :</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter Maximum Rate"
                value={maxRate}
                onChange={(e) => {
                  console.log(e.target.value);
                  setMaxRate(e.target.value);
                }}
              />
            </Form.Group>
          </Form.Group>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "8px",
            }}
          >
            {/* <Button onClick={handleOpen} className="all_btn">
              History
            </Button> */}
          </div>
        </Form>
        <Card.Footer className="text-center all_border">
          {!profLoading ? (
            <Button
              style={{ borderRadius: 50 }}
              className="all_btn"
              type="submit"
              onClick={onSubmit}
            >
              Register Consultant
            </Button>
          ) : (
            <Button className="all_btn" style={{ borderRadius: 50 }} disabled>
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Loading...
            </Button>
          )}
        </Card.Footer>
      </Card>

      {/* <Modal
        show={showModal}
        onHide={() => {
          setShowModal(false);
        }}
        size="md"
        keyboard={false}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header style={{ paddingLeft: '' }} closeButton>
          <Modal.Title >
            Update Professional Details
          </Modal.Title>
        </Modal.Header >

        {loading ? (
          <center>
            <Spinner animation="border" variant="primary" />
          </center>
        ) : (
          <Modal.Body >
            <Form.Group className="mb-3 " controlId="adminAddprofPhone">
              <Form.Label className="d-flex">Phone No* :</Form.Label>
              <Form.Control
                type="phone"
                style={{
                  margin: 'inherit'
                }}
                placeholder="Enter Consultant Phone Number"
                value={searchPhoneNo}
                onChange={(e) => {
                  console.log(e.target.value);
                  setSearchPhoneNo(e.target.value);
                }}
              />
              <Button
                variant="outline-warning"
                style={{ color: "black", fontWeight: "bold" }}
                size="sm"
                onClick={() => fetchProfessionalDetails()}
              >
                Get Details
              </Button>
            </Form.Group>
            <Button
              variant="outline-warning"
              style={{ color: "black", fontWeight: "bold", borderRadius: 50 }}
              onClick={() => setShowModal(false)}
            >
               {t("TXT.CLO.LO")}
            </Button>
          </Modal.Body>
        )}
      </Modal> */}

      <Modal show={show} onHide={handleClose}>
        <Modal.Header className="modal-header all_background" closeButton>
          <Modal.Title>Previous History</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped>
            <thead>
              <tr>
                <th>ID</th>
                <th>Created Time</th>
                <th>Rate</th>
                <th>Updated Time</th>
              </tr>
            </thead>
            <tbody>
              {datas.map((item) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.startTime}</td>
                  <td>{item.duration}</td>
                  <td>{item.endTime}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>
    </Container>
  );
};
export default AdminAddProfForm;
