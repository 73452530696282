import React, { createContext, useEffect, useState } from "react";

//custom - Vasthuparam

import ClientAdd from "../../component/crm/ClientAdd";
import { Grid } from "@mui/material";
import TableVasthu from "../../component/crm/TableVasthu";
import { vasthuUrl } from "../../component/Api";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import Stacks from "@mui/material/Stack";

export const CrmAccountContext = createContext({});

export default function Accounts() {
  const token = window.sessionStorage.getItem("token");
  window.sessionStorage.removeItem("crmSelectedUser");

  const [userList, setUserList] = useState([]);
  const [userUpdateList, setUserUpdateList] = useState(false);
  console.log("userUpdateList", userUpdateList);

  //toast aleart
  const [openSnack, setOpenSnack] = React.useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("error");

  const handleCloseSnack = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnack(false);
  };

  const alert = (msg) => {
    setMessage(msg);
    setOpenSnack(true);
    setTimeout(() => {
      setMessage("");
    }, 4000);
  };

  useEffect(() => {
    vasthuUrl
      .get("/data/getallusers", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log("RESULT LIST OF USERS", res.data);
        setUserList(res.data);
      })
      .catch((err) => {
        // setMessage(err)
        alert("Network error - UserList Not fetched properly");
        setMessageType("error");
      });
  }, [setUserList, userUpdateList]);

  return (
    <CrmAccountContext.Provider
      value={{
        userList,
        message,
        setUserList,
        alert,
        token,
        setMessageType,
        setUserUpdateList,
      }}
    >
      <Grid container spacing={2} alignItems="baseline">
        <Grid item xs={12} sm={8}>
          <TableVasthu title="User List" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <ClientAdd />
        </Grid>
      </Grid>

      <Stacks spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={openSnack}
          autoHideDuration={3000}
          onClose={handleCloseSnack}
        >
          <MuiAlert
            variant="filled"
            onClose={handleCloseSnack}
            severity={messageType}
            sx={{
              width: "100%",
            }}
          >
            {message}
          </MuiAlert>
        </Snackbar>
      </Stacks>
    </CrmAccountContext.Provider>
  );
}
