import React, { useState } from "react";
import { Table, Button, Modal } from "react-bootstrap";
import PropTypes from "prop-types";

const AdminPayList = (props) => {
  const [show, setShow] = useState(false);
  const [tempObj, setTempObj] = useState({});
  const handleView = (val) => {
    const tempObj = props.data.find((i) => i.id === val);
    setTempObj(tempObj);
    setShow(true);
  };


  const handleClose = () => {
    setTempObj({});
    setShow(false);
  };

  return (

    <Table striped bordered hover responsive className="border border-warning">
      <thead>
        <tr>
          <th>Name</th>
          <th>Offer</th>
          <th>Amount</th>
          <th>Details</th>
        </tr>
      </thead>
      <tbody>
        {props.data.map((i, j) => (
          <tr key={j}>
            <td>{i.userName}</td>
            <td>{i.offerName}</td>
            <td>{i.txnAmount}</td>
            <td>
              <Button
                variant="outline-warning"
                onClick={() => handleView(i.id)}
              >
                View More
              </Button>
            </td>
          </tr>
        ))}
      </tbody>
      <Modal
        size="lg"
        centered
        show={show}
        onHide={handleClose}
        onClose={handleClose}
      >
        <Modal.Header>
          <h5>{tempObj.txnId}</h5>
        </Modal.Header>
        <Modal.Body>
          <h5>ORDER ID :{tempObj.orderId}</h5>
          <h5>STATUS: {tempObj.status}</h5>
        </Modal.Body>
      </Modal>
    </Table>
  );
};

AdminPayList.propsType = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  show: PropTypes.bool.isRequired,
  tempObj: PropTypes.object.isRequired,
};
export default AdminPayList;
